
import {Component } from 'react';

//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
//const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class ManageInvoiceListService extends Component {

    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
        this.axiosObj = null;
        
        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/invoice';
    }

    async getList(paramObj){
      this.axiosObj = axiosCustomObj({});

      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            const defaultPageSize = getMaxPageSize();

            let urldata =this.apiEndPoint;
            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;

            let data  = paramObj.data;
            let search = data.searchText?data.searchText:"";
            let view = data.view?data.view:"";
            let sortOn = data.sortOn?data.sortOn:"";
            let sortDir = data.sortDir?data.sortDir:"";
            let paidStatus = data.paidStatus?data.paidStatus:"";

            let pageNum = data.pageNum?data.pageNum:1;
            let pageSize = data.pageSize?data.pageSize:defaultPageSize;
            
            if(search){
              urldata  = urldata + "/search/"+search+"/";
            }
            else{
              urldata  = urldata + "/history/";
            }

            urldata = urldata+"?view="+view;

            if(paidStatus){
              urldata = urldata+"&paid_status="+paidStatus;
            }

            if(sortOn && sortDir){
              urldata = urldata+"&sort_on="+sortOn+"&sort_dir="+sortDir;
            }
            

            urldata = urldata+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize;

            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
       // console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default ManageInvoiceListService;