import axios from 'axios';

import {notifytoapiresptrack} from "../error/ApiResponseStatusTrackCustom";




export const axiosCustomObj = (param) => {

    axios.interceptors.request.use(function (config) {
        // Do something before request is sent        
        return config;
      }, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });
    
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        
        // Do something with response data
        return response;
      }, function (err) {

        let errStatusCodeAr = [401]; // error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                let errStatusCode = erRespData.status;
                let notiParam = {
                  "errMsg" : errMsg,
                  "errStatusCode" : errStatusCode,
                }
                notifytoapiresptrack(errStatusCode,notiParam);
            }
        } 
        // Do something with response error
        return Promise.reject(err);
      });

      return axios;


}

