/**
 * AcceptTaskOfferButton
 * Page/Class name   : AcceptTaskOfferButton
 * Author            :
 * Created Date      : 6-4-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import AcceptTaskOfferService from '../../service/AcceptTaskOfferService';
import DeclineTaskOfferService from '../../service/DeclineTaskOfferService';
//import TaskDetailsService from '../../service/TaskDetailsService';
// for context
import CommonContext from '../../context/CommonContext';


import {notify} from '../../messagebar/CustomMessageBar';
import  CustDate  from '../../../common_utilities/CustDate';



import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;
const getBaseUrlOfLp = commonJsObj.getBaseUrlOfLp;
const getSiteName = commonJsObj.getSiteName;
const findElement = commonJsObj.findElement;


class AcceptTaskOfferButton extends Component {
    /**
     * AcceptTaskOfferButton
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {

            showConfirmForDeclineOfferModal: false,
            showEngageAgreeModal: false,
            acceptOfferBtnClkFlag: false,
            assignRel: null,
           

            
        }    

        this.modalforconflmessgRef =  React.createRef();
        this.modalforengagreeRef = React.createRef();
        this.accptoffrbuttonforpopupcompo = React.createRef();
        this.modalfordeclineConfirmRef = React.createRef();
    }

    /**
     * AcceptTaskOfferButton
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * AcceptTaskOfferButton
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    forcefullyTriggerMainButtonClick = () =>{
      this.accptoffrbuttonforpopupcompo.current.click();
    }

    callAcceptOfferTask = async (paramToPost,otherData) =>{        
        

        let stcservice = new AcceptTaskOfferService();
        stcservice.acceptTaskOffer(paramToPost).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){                   
                        setTimeout(()=>{
                        // call api to send offer
                        this.engagementConfiModalClose();
                        let anyDataToPass =  {"activity":"SINGLE_TASK_OFFER_ACCEPTED_SUCCESS"};
                        this.props.callbackfunc(anyDataToPass);

                        },1500);
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);
                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass alert_section_top_zero",
                            "msgKeyData": "acceptOfferModalNotiK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            }); 
                                           
                  }
                  } 

                setTimeout(()=>{
                    this.getEngagementConfiModalContent();
                },1800);
                
          }).finally(()=>{  
            this.isComponentLoaded && this.setState({"acceptOfferBtnClkFlag":false},()=>{
              
            });
                       
              
          });
        
    }

    callDeclineTaskOfferService = async (paramToPost,otherData) =>{        
        

        let stcservice = new DeclineTaskOfferService();
        stcservice.declineTaskOffer(paramToPost).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){                   
                        setTimeout(()=>{
                        
                          this.declineConfirmAcceptOfferModalClose();  
                          this.engagementConfiModalClose();

                          setTimeout(()=>{
                            let anyDataToPass =  {"activity":"SINGLE_TASK_OFFER_DECLINE_SUCCESS"};
                            this.props.callbackfunc(anyDataToPass);
                          },100);
                        
                        

                        },1500);
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);
                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass alert_section_top_zero",
                            "msgKeyData": "acceptOfferModalNotiK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            }); 
                                           
                  }
                  } 

                setTimeout(()=>{
                    this.getEngagementConfiModalContent();
                },1800);
                
          }).finally(()=>{  
            this.isComponentLoaded && this.setState({"acceptOfferBtnClkFlag":false},()=>{
              
            });
                       
              
          });
        
    }

     

    openPopupBasedOnCondition = (dataObj)=> {

     
      console.log("--- from openPopupBasedOnCondition this.state.assignRel==>>>>",this.state.assignRel);

      this.confidentialityEngagementModalOpen({});

      
     

    }



    /*** Engagement Modal  starts  */
    /**
     * AcceptTaskOfferButton
     * Function name : confidentialityEngagementModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   confidentialityEngagementModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showEngageAgreeModal:true},()=>{
      this.modalforengagreeRef.current.showModal();
      this.getEngagementConfiModalContent();
    });    
      
  }

  /**
     * AcceptTaskOfferButton
     * Function name : engagementConfiModalClose
     * Author        :
     * Created Date  : 6-4-2021
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   engagementConfiModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showEngageAgreeModal:false},()=>{
      this.modalforengagreeRef.current.hideModal();
    });
    }
    

     /**
   * AcceptTaskOfferButton
   * Function name : getEngagementConfiModalContent
   * Author        :
   * Created Date  : 6-4-2021
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getEngagementConfiModalContent() { 
     
        let viewData = <div className="dash_modal newTab_engagementLetter_modal_sec">
        {/* ===   new engagementLetter_modal modal section start ===  */}
        <div className="modal-header">
        {this.loadTabs()}
        </div>        
        <CustomMessageBar keydata={"acceptOfferModalNotiK1"} />
        <div className="modal-body engmLetter_modalBody">
          {this.getTabContent()}
        </div>
        {/* ===   new engagementLetter_modal modal section end ===  */}
      </div>
      ;

        this.modalforengagreeRef.current.updateStateContent(viewData);
        this.modalforengagreeRef.current.showContent();
        return viewData;
    }    

    /*** Engagement status Modal ends  */


    loadTabs = () =>{
     
      let structureToDisplay = [];

      structureToDisplay.push(
        <>  <h4 class="modal-title modal_title_tool">Engagement Letter</h4>
        <button onClick={(evn)=>{ evn.preventDefault(); this.engagementConfiModalClose(); }} type="button" class="close modal_close" data-dismiss="modal">×</button></>
        );
      


    return (<>{structureToDisplay}</>);

    }

    

    loadTabContent3 = () => {

      let taskResp = null;
        
      let ownerName = "";
      let organisationName = (this.state.assignRel.organisationName!==undefined && this.state.assignRel.organisationName!==null)?this.state.assignRel.organisationName: "";
      let lpUserName = (this.state.assignRel.lpUserName!==undefined && this.state.assignRel.lpUserName!==null)?this.state.assignRel.lpUserName: "";
      let taskName = this.state.assignRel.taskOrSubTaskName!==undefined?this.state.assignRel.taskOrSubTaskName: "";
      let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
      let taskIdOrSubTaskId = this.state.assignRel.taskIdOrSubTaskId!==undefined?this.state.assignRel.taskIdOrSubTaskId: "";
      let taskDesc = this.state.assignRel.taskDesc!==undefined?this.state.assignRel.taskDesc: "";
      

      let agreedBudget = (this.state.assignRel.agreedBudget!==undefined && this.state.assignRel.agreedBudget!==null)?this.state.assignRel.agreedBudget: "";
      let agreedBudgetCurrency =  (this.state.assignRel.agreedBudgetCurrency!==undefined && this.state.assignRel.agreedBudgetCurrency!==null)?this.state.assignRel.agreedBudgetCurrency: "";
      let agreedDueDate = (this.state.assignRel.agreedDueDate!==undefined && this.state.assignRel.agreedDueDate!==null)?CustDate.custFormatDate((new Date(this.state.assignRel.agreedDueDate)),3):"No date set";
     

      let ccSignatureDate = (this.state.assignRel.taskOfferedAt!==undefined && this.state.assignRel.taskOfferedAt!==null)?this.state.assignRel.taskOfferedAt:"";
      let lpSignatureDate = CustDate.custFormatDate((new Date()),9);
      let currentTodaysDate = CustDate.custFormatDate((new Date()),3);
      

      return (
        <div className="engmLetter_modalBody_heightAdjust">
        {/* ===  optional alert message start ===  */}
        {/* <div style={{display: 'none'}} className="alert_secction  ">
          <div className="alert alert-dismissible alert_custom alert-success">
            <p>  Alert Meassage</p>
          </div>
        </div> */}
        {/* ===  optional alert message end ===  */}
        {/* engmLetter_contBody_bg body start  */}
        <div className="engmLetter_contBody_bg">
          <div className="engmLetter_contBody_scrollAdjust_bg">
            {/* main Engagement Letter TAB content body start */}
            <div className="popup_engagement_tabblockBg popup_tabBlockBg_common">
              <div className="popup_engagement_topTile_block">
              <h3>A Professional Engagement Powered By {getSiteName()}</h3>
              </div>
              <div className="popup_engagement_midContBlock_bg">
                

                <div className="engagementPop_p">
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_full">
                      <p>This Engagement Letter finalizes the terms and conditions entered 
      into on <i>{currentTodaysDate}</i> (the “Effective Date”), by and between 
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>Organisation: <i>{organisationName}</i>; and  
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>Professional: <i>{lpUserName}</i>
                      </p>
                    </div>
                  </div>
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_half">
                      <p> Task Name: <i>{taskName}</i></p>
                    </div>
                    <div className="engaLtr_half">
                      <p>Task No. <i>{taskIdOrSubTaskId}</i> </p>
                    </div>
                  </div>
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_full">
                      <p>The Scope of Task shall be: <i>{taskDesc}</i>
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>The Professional shall complete the Task by <i>{agreedDueDate}</i> (the “ Due Date”).
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>The Organisation shall pay the invoice amount <i>{agreedBudgetCurrency}&nbsp;{agreedBudget}</i> (the “Invoice Amount”).
                      </p>
                    </div>
                  </div>
                  <div className="engaLtr_full">
                    <p>
                      The Organisation acknowledges and agrees that subject to the scope and complexity of the Task the Professional 
                      may renegotiate the Due Date, the Scope and the Invoice Amount.Such renegotiated terms after being agreed to by 
                      both the parties shall be incorporated herein by reference.
                    </p>
                    <p>
                      The Professional hereby grants a non-exclusive, perpetual, world-wide license to the Organisation to use the intellectual property 
                      created in the deliverable of the Task.The Professional shall be liable under applicable laws for the deliverable under the Task or 
                      the part thereof including but not limited to professional liability.
                      The Professional shall ensure and maintain the confidentiality of all and any information, written or oral, 
                      including but not limited to documents, images, representations or Intellectual Property among others, that the Professional may receive, 
                      through the Platform or otherwise, from the Organisation for the purpose of Task.The Professional shall 
                      communicate the progress of the Task on the Platform to the Organisation, as and when required.
                    </p>
                    <p>
                      The Organisation shall share all such data or extend any such assistance as required by the Professional for timely completion of the Task.
                      The Organisation shall pay the Invoice Amount and applicable taxes on completion of the Task and raising of invoice by the Professional on the Platform.
                    </p>
                    <p>
                      This Engagement Letter shall constitute all the terms and conditions agreed between the Parties and sets forth the entire understanding and agreement of the 
                      Parties as to the subject matter of this Agreement and supersedes all prior discussions and understandings in respect to the subject of this 
                      Agreement, whether written or oral.
                    </p>
                    <p>
                      Any dispute arising out of this Engagement Letter shall be referred to the independent grievance redressal body 
                      of Flywork Innovations Private Limited. If the dispute fails to be resolved within 30 (thirty) days, then the Organisation and the Professional 
                      may resolve it in accordance with the laws of Republic of India.
                    </p>
                  </div>
                  <div className="clearboth" />
                </div>

              
              </div>
              {/* ===  Popup Engagement Letter Addclose Section start ===  */}
              {/* ===  Popup Engagement Letter Addclose Section end ===  */}
              {/* ===  Popup Engagement Letter Bottom Signature Section start ===  */}
              <div className="popup_engagement_bottomContBlock_signature_sec">
                <div className="pop_engm_bottomSignature_left">
                  <div className="pop_engm_bottomSignature_1">
                    <h5>Signed on behalf of the organization</h5>
                  </div>
                  <div className="pop_engm_bottomSignature_2 pop_engm_bottomSignature_Font">
                    <h4>{organisationName}</h4>
                  </div>
                  <div className="pop_engm_bottomSignature_3  pop_engm_bottomSignature_dateTime">
                    <span>{ccSignatureDate}</span>
                   
                  </div>
                </div>
                                                        
                <div class="pop_engm_bottomSignature_right">
                    <div class="pop_engm_bottomSignature_1">
                        <h5>Signed by</h5>
                    </div>
                    <div class="pop_engm_bottomSignature_2 pop_engm_bottomSignature_Font">
                        <h5>Your digitally generated signature will appear</h5>
                    </div>
                    <div class="pop_engm_bottomSignature_3  pop_engm_bottomSignature_dateTime">
                        
                        <span>{lpSignatureDate}</span>
                    </div>
                </div> 
                                       
                <div className="clearboth" />
              </div>
              {/* ===  Popup Engagement Letter Bottom Signature Section start ===  */}
            </div>
            {/* main Engagement Letter TAB content body end */}
          </div>                            
        </div>
        {/* engmLetter_contBody_bg body end  */}
        {/* engmLetter_contBody_bg footer start  */}
        <div className="engmLetter_modalFooter">
          <div className="engmLetter_modalFooter_sec">
            <button disabled={this.state.acceptOfferBtnClkFlag}  onClick={(evn)=>{ evn.preventDefault(); 
                  evn.preventDefault();
                  //this.declineTaskOffer();
                  //this.engagementConfiModalClose();
                  setTimeout(()=>{
                    this.declineConfirmAcceptOfferModalOpen();
                  },250);
                  
               }}  className="btn_appearance_none sub_grd_btn red_btn"><span>Decline</span></button>
            <button disabled={this.state.acceptOfferBtnClkFlag} onClick={(evn)=>{
              evn.preventDefault();
              this.acceptTaskOffer();

            }} className="btn_appearance_none green_btn"><span>Accept Offer</span></button>
          </div>
        </div>
        {/* engmLetter_contBody_bg footer end  */}
      </div>
      

      );
    }


    continueClicked = (paramObj) =>{
        let clickedFrom = paramObj.clickedFrom!==undefined?paramObj.clickedFrom:"";
      
    }

    switchToConfidentialityTab = () =>{
      this.isComponentLoaded && this.setState({"tabContentNumber":1});

    }

    switchTab = (paramObj) =>{

      let tabContentNumber= paramObj.tabContentNumber?paramObj.tabContentNumber:0; 
      let reloadTheContent= paramObj.reloadTheContent?paramObj.reloadTheContent:false;      

      
      switch(tabContentNumber){
        case 1:{
            this.isComponentLoaded && this.setState({"tabContentNumber":1},()=>{
              if(reloadTheContent){
                this.getEngagementConfiModalContent();
              }
              
            });
            break;
          }
        case 2:{
          this.isComponentLoaded && this.setState({"tabContentNumber":2},()=>{
            if(reloadTheContent){
              this.getEngagementConfiModalContent();
            }
          });
          break;
        }
        case 3:{
          this.isComponentLoaded && this.setState({"tabContentNumber":3},()=>{
            if(reloadTheContent){
              this.getEngagementConfiModalContent();
            }
          });
          break;
        }
        default:{
            this.isComponentLoaded && this.setState({"tabContentNumber":0},()=>{
              if(reloadTheContent){
                this.getEngagementConfiModalContent();
              }
            });
            break;
          } 


      }

    }

    getTabContent = () =>{

      return this.loadTabContent3();

    }



    acceptTaskOffer = () => {   

      this.isComponentLoaded && this.setState({"acceptOfferBtnClkFlag":true},()=>{
        this.getEngagementConfiModalContent();

        let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
        let taskIdOrSubTaskId = this.state.assignRel.taskIdOrSubTaskId!==undefined?this.state.assignRel.taskIdOrSubTaskId: 0;
        let lpUserId = this.state.assignRel.lpUserId!==undefined?this.state.assignRel.lpUserId: 0;
        let ccUserId = this.state.assignRel.ccUserId!==undefined?this.state.assignRel.ccUserId: 0;



        let paramToPass = {"data":{
          "taskId":taskIdOrSubTaskId,
          "assigneeId":lpUserId,
          "taskOwnerId":ccUserId
        }};
        this.callAcceptOfferTask(paramToPass);

        
         
      });
     
        
    }

    declineTaskOffer = () => {   

      this.isComponentLoaded && this.setState({"acceptOfferBtnClkFlag":true},()=>{
        this.getDeclineConfirmAcceptOfferModalContent();
        this.getEngagementConfiModalContent();

        let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
        let taskIdOrSubTaskId = this.state.assignRel.taskIdOrSubTaskId!==undefined?this.state.assignRel.taskIdOrSubTaskId: 0;
        let lpUserId = this.state.assignRel.lpUserId!==undefined?this.state.assignRel.lpUserId: 0;
        let ccUserId = this.state.assignRel.ccUserId!==undefined?this.state.assignRel.ccUserId: 0;



        let paramToPass = {"data":{
          "taskId":taskIdOrSubTaskId,
          "assigneeId":lpUserId,
          "taskOwnerId":ccUserId
        }};
        this.callDeclineTaskOfferService(paramToPass);

        
         
      });
     
        
    }

    /*** Offer task decline confirm Modal  starts  */
    /**
     * AcceptTaskOfferButton
     * Function name : confidentialityEngagementModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   declineConfirmAcceptOfferModalOpen = ()=>{    
    this.isComponentLoaded &&  this.setState({showConfirmForDeclineOfferModal:true},()=>{
      this.modalfordeclineConfirmRef.current.showModal();
      this.getDeclineConfirmAcceptOfferModalContent();
    });    
      
  }

  /**
     * AcceptTaskOfferButton
     * Function name : engagementConfiModalClose
     * Author        :
     * Created Date  : 6-4-2021
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   declineConfirmAcceptOfferModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showConfirmForDeclineOfferModal:false},()=>{
      this.modalfordeclineConfirmRef.current.hideModal();
    });
    }
    

     /**
   * AcceptTaskOfferButton
   * Function name : getEngagementConfiModalContent
   * Author        :
   * Created Date  : 6-4-2021
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getDeclineConfirmAcceptOfferModalContent() { 
     
        let viewData = <div className="dash_modal ">
        {/* ===   new engagementLetter_modal modal section start ===  */}
              
        
        <div className="modal-header">
        <h4 className="modal-title modal_title_tool"> Task Offer Decline
        </h4>
        <button type="button" onClick={(evn)=>{ 
            evn.preventDefault(); 
            this.declineConfirmAcceptOfferModalClose();            
            setTimeout(()=>{
              //this.forcefullyTriggerMainButtonClick();
            },250);
            
            }}  className="close modal_close" data-dismiss="modal">&times;</button>
    </div>
    <CustomMessageBar keydata={"declineTaskOfferModalNotiK1"} />
    <div className="modal-body padding_top">
    
      
      {/* "Notify all professionals about change in task details?" */}
      <div class="popUP_text_p">
            <p>Are you sure you want to Decline. If you Decline this task, you cannot accept it in future.</p></div>            
                        

      
    </div>
        <div className="modal-footer">
          <button disabled={this.state.acceptOfferBtnClkFlag}  onClick={(evn)=>{ evn.preventDefault(); 
                  evn.preventDefault();
                  this.declineTaskOffer();
               }} type="button" className="btn btn-primary modal_btn_save">
            <span>Ok</span>
          </button>
        </div>
        {/* ===   new engagementLetter_modal modal section end ===  */}
      </div>
      ;

        this.modalfordeclineConfirmRef.current.updateStateContent(viewData);
        this.modalfordeclineConfirmRef.current.showContent();
        return viewData;
    }    

    /*** Offer task decline confirm Modal ends  */


   

    /**
     * AcceptTaskOfferButton
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <> 
             {this.props.controltype=="BUTTON" && (
                  <button ref={this.accptoffrbuttonforpopupcompo} onClick={(evn)=>{
                    evn.preventDefault();
                    this.isComponentLoaded && this.setState({"assignRel":this.props.datas, },()=>{
                        this.openPopupBasedOnCondition();
                    });
                    
                    

                  }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                  <span>{this.props.controlnametext}</span>
            </button>

             )}               
                
               

                

                {/* Engagement Agreement Modal start */}
                <CustReactBootstrapModal ref={this.modalforengagreeRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showEngageAgreeModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="modal-dialog custom_modal_lg modal-lg modal-dialog-centered newTab_engagementLetter_modalBg newTab_engagementLetter_modalBg_lp"  loaderbodytitle="Organisation"  />
                {/* Engagement Agreement Modal end */}

                 {/* Decline Confirm Modal start */}
                 <CustReactBootstrapModal ref={this.modalfordeclineConfirmRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showConfirmForDeclineOfferModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="modal-dialog custom_modal_lg modal-lg modal-dialog-centered"  loaderbodytitle="Organisation"  />
                {/* Decline Confirm Modal end */}








             </>
            
        );
    }

  }
  export default AcceptTaskOfferButton;