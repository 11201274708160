import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;
export class FetchNotificationService extends Component {
    constructor() {
        super();
        this.state = {
        }
        //this.axiosObj = axios;
        this.axiosObj = null;
        
        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/user/notifications';
    }
    async getNotifications(paramObj){
        this.axiosObj = axiosCustomObj({});

        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;
        const defaultPageSize = getMaxPageSize();
        try{
              let urldata =this.apiEndPoint+'?offset_hour='+offsetHr +'&offset_minute='+offsetMin+'&page_number='+paramObj.pageNum+'&page_size='+defaultPageSize;
              let headerOptn = {
                                  "Content-Type": "application/json",
                                  "Authorization": accToken
                                };
  
              let respObj = this.axiosObj.get(urldata, {
                headers: headerOptn
              });
  
              return respObj;
      }
      catch(err){
         // console.log("Some error occured===>",err); // TypeError: failed to fetch
      }
  
  
      }
}

export default FetchNotificationService
