
import React, {Component} from 'react';

//import packageJson from '../package.json';
import buildcomparenumberJson from './buildcomparenumber.json';



//global.versionNumberForBuildCompare = packageJson.versionNumberForBuildCompare;
global.versionNumberForBuildCompare = buildcomparenumberJson.versionNumberForBuildCompare;



const checkLatestVersionAvailability = (versionA, versionB) => {
    // code from above snippet goes here
    versionA = versionA? parseFloat(versionA):0;
    versionB = versionB? parseFloat(versionB):0;
    let isNewVersion = versionA > versionB ? true: false;
    return isNewVersion;
}

class ClearCache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,      
      refreshCacheAndReload: () => {

        console.log('Clearing cache and hard reloading...')
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
          // delete browser cache and hard reload
          window.location.reload(true);
        
      },
      returnCurrentBuildVersionText: () => {
        return global.versionNumberTextToDisplay;
      },
    };
  }

  componentDidMount() {
    
    let duration = this.props.duration!==undefined && this.props.duration? this.props.duration: 0;
    this.triggerReadMetaAndCompare(duration); 
  }

  triggerReadMetaAndCompare = (setTime) =>{

    if(setTime){
        setInterval(()=>{
             this.readMetaAndCompare();
        },setTime);
    }else{
         this.readMetaAndCompare();
    }
     
  }

  async readMetaAndCompare(){
    fetch('/meta.json')
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.versionNumberForBuildCompare;
      const currentVersion = global.versionNumberForBuildCompare;
      
      global.versionNumberTextToDisplay = meta.versionNumberTextToDisplay;

      const shouldForceRefresh = checkLatestVersionAvailability(latestVersion, currentVersion);
      if (shouldForceRefresh) {
        console.log(`We have a new version - ${latestVersion}. Should force refresh`);
        setTimeout(()=>{
          this.setState({ loading: false, isLatestVersion: false });
        },240000);
        
      } else {
        console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        this.setState({ loading: false, isLatestVersion: true });
      }
    });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload, returnCurrentBuildVersionText } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload, returnCurrentBuildVersionText});
  }
}

export default ClearCache;