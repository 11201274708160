import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;

export class TaskRequestService extends Component {
    /**
     * TaskListForSingleTaskService
     * Function name : constructor
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : initializing state
     * Params        :
    **/
    constructor() {
        super();
        this.state = {
            
        }

        //this.axiosObj = axios;
       this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/dashboard_tasks';
    }

    /**
     * TaskListForSingleTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : life cycle method
     * Params        :
     **/
    componentDidMount() {
    }

    /**
     * TypeOfProfessionalsService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 4-3-2020
     * Purpose       : life cycle method
     * Params        :
     **/
    componentWillUnmount() {
    }



    /**
     * TaskListForSingleTaskService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : Login check to provide access token
     * Params        : paramObj
     **/
    async getTaskList(paramObj){
            this.axiosObj = axiosCustomObj({});
        
            let accToken = getTokenFromLocStorage();
            //console.log(accToken,"accToken")
            let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

            try{
                let urldata =this.apiEndPoint;

                const defaultPageSize = getMaxPageSize();
                let pageNum = paramObj.pageNum?paramObj.pageNum:1;
                let pageSize = paramObj.pageSize?paramObj.pageSize:defaultPageSize;

                let offsetHr = offsetOfLocalDateTime.hr;
                let offsetMin = offsetOfLocalDateTime.min;

                let kind = paramObj.kind;
                let filterType = paramObj.filterType;
                let sortOn = paramObj.sortOn ? paramObj.sortOn:'';
                let sortDir = paramObj.sortDir? paramObj.sortDir: '';

                urldata = urldata+"/page_number/"+pageNum+"/page_size/"+pageSize;
                urldata = urldata+"?kind="+kind+"&sort_on="+sortOn+"&sort_dir="+sortDir+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&filter_type="+filterType;

                //console.log("task single list api hitted with =>>",urldata);


                let headerOptn = {
                    "Content-Type": "application/json",
                    "Authorization": accToken
                };

                let respObj = this.axiosObj.get(urldata,{
                headers: headerOptn
                });

                return respObj;
        }
        catch(err){
           // console.log("Some error occured===>",err); // TypeError: failed to fetch
        }
    }
}

export default TaskRequestService
