import React, {Component} from 'react';
import ResetPasswordService from '../../service/ResetPasswordService';
// import {custEncode} from  '../../../common_utilities/commonjsfunc';
import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';

import {notify} from '../../messagebar/CustomMessageBar';

  
const initialSate = {

    loaderFlag: false,
    password: '',
    confirmPassword:'',
    code:'',
    passwordErMsg: '',
    confirmPasswordErMsg:'',

};
  

export class ResetPassword extends Component {
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state = initialSate;
        this.resetPasswordButtonInput = React.createRef();
    }
    componentWillMount() {
        //LoadGoogleMap.loadGoogleMapLibrary();
  
  
    }
    componentDidMount() {
        this.isComponentLoaded = true;
       //console.log(this.props.match.params.resetCode);
       this.setState({code:this.props.match.params.resetCode});
    }
    componentWillUnmount() {
        this.isComponentLoaded = false;
    }
    showLoader(){
        this.isComponentLoaded && this.setState({loaderFlag: true});
    }
    

    hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
    }
    

    loadLoader(){
        return (
                <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
        );
    }

    render() {
        return (
            <>
                <div className="signup_form_area lp_signup_form_area">
                    <div className="sign_up_top">
                        <h1>Enter new password</h1>
                        <div className="subheading">Set a new password for your account</div>
                    </div>
                    <div className="form_area">

                        <form>

                            <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingleftright15">
                                    <div className={`form__group field  ${ this.state.passwordErMsg?'form_group_error':''}`}>
                                        <input type="password" class="form__field" placeholder="Enter password"  name="password" id='password'  onChange={ this.handleChange } />
                                        <label htmlFor="name" className="form__label">Enter password</label>
                                        <span className="error_span">{this.state.passwordErMsg}</span>
                                    </div>
                                    <div className="input_tooltip_bg">
                                        <span className="input_tooltip"
                                            aria-label="Passwords must be include atleast 1 capital letter, 1 small letter,1 number, 1 special character => !@#$%^&* and atleast 8 characters long
                                            "
                                            data-balloon-pos="down">
                                            <i className="fa fa-question-circle"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="panel50 panel_half paddingleftright15">
                                    <div className={`form__group field  ${ this.state.confirmPasswordErMsg?'form_group_error':''}`}>
                                        <input type="password" className="form__field" placeholder="Confirm password" name="confirmPassword"
                                    id='confirmPassword' onChange={ this.handleChange }/>
                                        <label htmlFor="name" className="form__label">Confirm password</label>
                                        <span className="error_span">{this.state.confirmPasswordErMsg}</span>
                                    </div>
                                </div>
                                <div className="clearboth"></div>
                            </div>

                            <div className="form_block">
                                <div className="panel100 paddingleftright15 button_ip_panel">
                                    <button type="button" className="btn btn-primary sub_grd_btn" ref={this.resetPasswordButtonInput} onClick={this.callResetPassword}><span>Continue</span></button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </>
        )
    }


    custValidate = ()=>{
        let passwordErMsg = '';
        let confirmPasswordErMsg = '';
        let regexBlankSpace = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
        let totErrCountTrack = [];

        if(!(this.state.password)||(this.state.password.length==0)){
        passwordErMsg = "Password is required.";
        this.isComponentLoaded && this.setState({ "passwordErMsg": passwordErMsg});
        totErrCountTrack.push(passwordErMsg);
        } else if((regexBlankSpace.test(this.state.password)==false)){
            passwordErMsg = "Passwords must be include at least 1 capital letter,  1 small letter, 1 number, 1 special character => !@#$%^&* ant at least 8 characters long";
            this.isComponentLoaded && this.setState({ "passwordErMsg": passwordErMsg});
            totErrCountTrack.push(passwordErMsg);
        } else{
        this.isComponentLoaded &&  this.setState({ "passwordErMsg":''});
        }
      
        if(!(this.state.confirmPassword)||(this.state.confirmPassword.length==0) || (this.state.password!=this.state.confirmPassword)){
            confirmPasswordErMsg = "Confirm password need to same with password.";
            this.isComponentLoaded && this.setState({ "confirmPasswordErMsg": confirmPasswordErMsg});
            totErrCountTrack.push(confirmPasswordErMsg);
          } else{
            this.isComponentLoaded &&  this.setState({ "confirmPasswordErMsg":''});
        }

        if(totErrCountTrack.length>0){
            return false;
        }
      
        return true;
    }
      

    callResetPassword = (e) => {
        e.preventDefault();
        this.isComponentLoaded && this.setState({loaderFlag:true});
        //console.log(this.state)
        const isValid = this.custValidate();

        if(isValid){
      
          const params = {
            "password": this.state.password,
            "confirmPassword": this.state.confirmPassword,
            "code":this.state.code
          }
            let lserv = new ResetPasswordService();
            lserv.resetPassword(params).then(data =>{
                if(data.status==200){
                    //set emailId to ls for otp


                    let succMsg = "Success";
                    notify(succMsg,true,{
                      'successMsg':1,
                      'customWrapClass':'customsuccclass'});
                      setTimeout(()=>{
                          this.props.history.push("/sign-in");
                      },900);
                }
            }).catch(err=>{
              let errStatusCodeAr = [400,500,401]; // allowed error status code
              if(err.response && err.response.data){
                  let erRespData = err.response.data;
                  if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                      let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                      notify(errMsg,true,{
                      'successMsg':0,
                      'customWrapClass':'customerrclass'});
                }
              }
        
            }).finally(()=>{
              setTimeout(()=>{
                this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
                  });
              },1500);

            });
      
        }else{
          this.isComponentLoaded && this.setState({loaderFlag:false});
        }
      
    }
      

    handleChange = (e) => {
    this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value});
    }
      


}

export default ResetPassword
