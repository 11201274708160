/** 
 * CustomAutoComplete
 * Page/Class name   : CustomAutoComplete
 * Author            :
 * Created Date      : 11-9-2020
 * Functionality     : CustomAutoComplete
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import { throttle, debounce } from "throttle-debounce";




class CustomAutoComplete extends Component {

  isComponentLoaded = false; 

  custElemKeyData = "cuselemtkeydata";

  /**
   * CustomAutoComplete
   * Function name : constructor
   * Author        :
   * Created Date  : 11-9-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props); 
      
      
      this.state = {       
        loaderFlag: false,
        suggestions: [],
        selectedText: '',
        selectedAr: [],
        ulListShow: false
        
      }
      this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
 
    
  }

  autocompleteSearch = (value) =>{
    this.props.callservicefordata(value);
  }

  /**
     * CustomAutoComplete
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 11-9-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/
  
  componentDidMount() {
      this.isComponentLoaded = true; 

      let defaultTextBoxValue = this.props.defaulttext!==undefined?this.props.defaulttext:"";
      this.setDefaultTextBoxValue(defaultTextBoxValue);
        
  }

  // onTextChanged = (e)=>{
  //   const value = e.target.value;
  //   let suggestions = [];
  //   if(value.length>0){
  //       // show ul li
  //       this.setState({"ulListShow":true});
  //       const regex = new RegExp(`^${value}`,'i');
  //       suggestions = this.items.sort().filter(v=>regex.test(v));
  //       //console.log("1suggestions=>",suggestions);
  //       this.isComponentLoaded && this.setState({"suggestions":suggestions},()=>{
  //         this.setState({"selectedText":value});
  //         //console.log(" 2 suggestions======>",this.state.suggestions);
  //       });

  //   }else{
  //     this.setState({"selectedText":""});
  //   }    
  // }

  onTextChanged = (e)=>{
    const value = e.target.value;
    if(value.length>0){
      this.setState({"selectedText":value},()=>{
        if(value.length>4){
          this.autocompleteSearchDebounced(value);
        }else{
          this.isComponentLoaded && this.setState({"suggestions":[]});
        }
        
 
        
      });
        

    }else{
      this.isComponentLoaded && this.setState({"selectedText":""});
    }    
  }

  updateSuggestedData = (suggestedData) => {
    this.isComponentLoaded && this.setState({"suggestions":suggestedData},()=>{
          // show ul li
          this.showUl();
    });
  }
 

  renderSuggestions(){
    const {suggestions,selectedAr} =  this.state;

    let keyOfElem =  this.props.cuselemtkeydata!==undefined?this.props.cuselemtkeydata:(this.custElemKeyData+"ulli");
    
    if(suggestions.length==0 && selectedAr.length==0){
        return null;
    }
   

    let ulListShow = this.state.ulListShow?"block":"none";
    //console.log("ulListShow=>",ulListShow);
    return (
          <ul style={{"display":ulListShow}}>
              
              {Object.keys(suggestions).map((item,i)=> <li  className="customrnd2clsnonselected" key={(keyOfElem+suggestions[item].id)} onClick={(evn)=>{ evn.preventDefault(); this.suggestionSelected(suggestions[item])}}>
              <span className="custrnd3spannonseltxt">{suggestions[item].name}</span></li> )}
          </ul>
        );     
    

  }
  
  suggestionSelected = (optionData) => {
    //console.log("delete =>",value);   
    
    let showSelectedOnTextBx = this.props.showselectedontextbox!==undefined?this.props.showselectedontextbox:"";
    if(showSelectedOnTextBx=="YES"){
        this.isComponentLoaded && this.setState({"selectedText":optionData.name});
    }

    this.props.optionselected(optionData);


  }

  suggestionPrevSelectedDelete(value){
       //console.log("delete =>",value);
    }
    
  /**
     * CustomAutoComplete
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 11-9-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

  /**
   * CustomAutoComplete
   * Function name : showLoader
   * Author        :
   * Created Date  : 11-9-2020
   * Purpose       : Used to show loader
   * Params        : 
  **/
  showLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: true});
  }

  /**
   * CustomAutoComplete
   * Function name : hideLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to hide loader
   * Params        : 
  **/
  hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
  }

  
  hideUl = ()=>{
    this.isComponentLoaded && this.setState({ulListShow: false});    
  }

  showUl = ()=>{
    this.isComponentLoaded && this.setState({ulListShow: true});    
  }
  
  setDefaultTextBoxValue = (defaultTextBoxValue)=>{ 
    this.isComponentLoaded && this.setState({"selectedText": defaultTextBoxValue});    
  }

  onTextBlur = () =>{
    setTimeout(()=>{
      this.hideUl();
    },1000);
    
  }
  

  /**
   * CustomAutoComplete
   * Function name : render
   * Author        :
   * Created Date  : 11-9-2020
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){   
    const {selectedText}= this.state;
    
      return (
       <>
          <div class={this.props.customclass!==undefined?this.props.customclass:"genericcustautocompparentclass"}>
              <input autoComplete="off" key={this.props.cuselemtkeydata!==undefined?this.props.cuselemtkeydata:(this.custElemKeyData+"txtbx")}  onChange={this.onTextChanged} type="text" name="autocomp" value={this.state.selectedText} onBlur={this.onTextBlur} placeholder={this.props.placeholdertext!==undefined?this.props.placeholdertext:""} />
              {this.renderSuggestions()} 
          </div>      
       </>
      
      );  
  }

  

 

}

export default CustomAutoComplete;