const customimages = {
  markers: {
    blue: require('./markers/blue-dot.png'),
    green: require('./markers/green-dot.png'),
    yellow: require('./markers/yellow-dot.png'),
    orange: require('./markers/orange-dot.png'),
    red: require('./markers/red-dot.png'),
  },
  noimagemale: require('./no-image-male.png'),
};

export default customimages;
