/** 
 * SpecialDevToReset
 * Page/Class name   : SpecialDevToReset
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : SpecialDevToReset
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';

import { withRouter } from "react-router-dom";


import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';


const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;

class SpecialDevToReset extends Component {

  isComponentLoaded = false;

  

  /**
   * SpecialDevToReset
   * Function name : constructor
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);  
      
      this.state = {
      }
    
  }

  /**
     * SpecialDevToReset
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/
  
  componentDidMount() {
      this.isComponentLoaded = true; 
      removeTokenFromLocStorage();
      this.props.history.push("/sign-in");
      
  }


  
  /**
     * SpecialDevToReset
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

 
  

  /**
   * SpecialDevToReset
   * Function name : render
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){   
    
      return (
       <> Proud To Be An Indian
          </>
      
      );  
  }




 

}

export default withRouter(SpecialDevToReset);