/**
 * CustReactBootstrapModal
 * Page/Class name   : CustReactBootstrapModal
 * Author            :
 * Created Date      : 12-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy, useState } from 'react';
// import '../../../custom_resources/css/custom.css';

import { Link, NavLink } from 'react-router-dom';
import CustLoader from '../after_login/CustLoader';
// import UserPasswordChangeService from '../../service/UserPasswordChangeService';
import { withRouter } from "react-router-dom";
// import { getTokenFromLocStorage, removeTokenFromLocStorage, getAccessInfoFromLS, removeAccessInfoFromLS } from '../../../common_utilities/commonjsfunc';
// for notification

import {Modal} from 'react-bootstrap';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import '../../../custom_resources/css/custom.css';
import '../../../custom_resources/css/custom_responsive.css';


const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;
const removeAccessInfoFromLS = commonJsObj.removeAccessInfoFromLS;



class CustReactBootstrapModal extends Component {

  isComponentLoaded = false;
  /**
   * CustReactBootstrapModal
   * Function name : constructor
   * Author        :
   * Created Date  : 12-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
    super(props);
    this.state = {
        modalContent: '',
        loaderFlag: false,
        loadContentFlag: null,
        showFlag: false

    };

    this.refOuterDivCustPopup = React.createRef();
    
  }

  componentDidMount(){
    this.isComponentLoaded = true;
  }

  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
     * CustReactBootstrapModal
     * Function name : showLoader
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Show loader
     * Params        : 
     **/
    showLoader(){
      
      this.isComponentLoaded &&  this.setState({loaderFlag: true});
  }

  /**
   * CustReactBootstrapModal
   * Function name : hideLoader
   * Author        :
   * Created Date  : 12-2-2020
   * Purpose       : Hide loader
   * Params        : 
   **/
  hideLoader(){
      this.isComponentLoaded &&  this.setState({loaderFlag: false});
  }

  showContent(){
    this.isComponentLoaded &&  this.setState({loadContentFlag: true});
  }

  hideContent(){
    this.isComponentLoaded &&  this.setState({loadContentFlag: false});
  }



 
  /**
   * CustReactBootstrapModal
   * Function name : loadLoader
   * Author        :
   * Created Date  : 12-2-2020
   * Purpose       : Load loader
   * Params        : 
  **/
  loadLoader() {
    //console.log("Modal loader....");
    return (
      <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
    );
  }

  updateStateContent(content) {
    this.setState({modalContent:content})
  }
  hideModal = () => {
    this.setState({showFlag:false});
  }
  showModal = () => {
    this.setState({showFlag:true});
  }
  
  render(){    
    
    return (
      <Modal
        {...this.props}
        show={this.state.showFlag}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${this.props.modalcustdialog?this.props.modalcustdialog:''}`}
       
        centered      
          
      >
       
            {/* {this.state.loaderFlag && 
            <div>
              <div className="modal-header">
                <h4 className="modal-title">{this.props.loaderbodytitle&&this.props.loaderbodytitle}</h4>
                <button onClick={()=>{this.hideModal();}} type="button" className="close modal_close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body"> 
                 <div className="min_height_50 justify_content_center">
                   {this.loadLoader()}
                 </div>
              </div>
              <div className="modal-footer">

              </div>
            </div>} */}
            <div ref={this.refOuterDivCustPopup} >
            {this.state.loadContentFlag && this.state.modalContent}
            </div>
            {/* {this.state.loaderFlag && this.loadLoader()}
            {this.state.loadContentFlag && this.state.modalContent} */}
        
      </Modal>
    );
  }
  

   
}
export default CustReactBootstrapModal;