/**
 * BeforeLoginLayout
 * Page/Class name   : BeforeLoginLayout
 * Author            :
 * Created Date      : 6-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render before login component
**/
import React, { Component } from 'react';


import CustomMessageBar from '../messagebar/CustomMessageBar';
import { commonJsFuncModule as commonJsObj } from '../../common_utilities/commonjsfunc';
// hot jar
import { hotjar } from 'react-hotjar';
// google analytics
//import ReactGA from 'react-ga';


const getBaseUrl = commonJsObj.getBaseUrl;

const hjLoadFlag = commonJsObj.hjLoadFlag;
const getHjIdSv = commonJsObj.getHjIdSv;


class BeforeLoginLayout extends Component {
    /**  
     * BeforeLoginLayout
     * Function name : constructor
     * Author        :
     * Created Date  : 6-2-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.children = props.children;
    }

    /**  
     * BeforeLoginLayout
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 6-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
      this.isComponentLoaded = true;     
      
      // load hotjar
      if(hjLoadFlag()=="YES"){
        let hjObj= getHjIdSv();
        let hjid =  hjObj.hjid;
        let hjsv =  hjObj.hjsv;
        hotjar.initialize(hjid, hjsv);

      }    
      
     
    }

    /**  
     * BeforeLoginLayout
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 6-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;     
    }

    /**  
     * TodaysTweets
     * Function name : render
     * Author        :
     * Created Date  : 6-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      return (
          <>
            <div>
              <CustomMessageBar />
              <div className="cutom_container">
              <div className="signup_area">
                <div className="panel50 signup_bg">
                  <div>
                    <a onClick={(evn)=>{ evn.preventDefault();}} href="">
                      <img src={getBaseUrl()+"assets/images/flywork-for-professionals-logo.svg"}  />
                    </a>
                  </div>
                </div>
                <div className="panel50">
                      {this.children}              
                </div>
                <div className="clearboth" />
                </div>
              </div>            
            </div>                                     
          </>
      );
    } 
}
export default BeforeLoginLayout;
