import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;
export class InvoiceGeneratorPreviewService extends Component {
    constructor() {
        super();
        this.state = {
        }
        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/invoice/invoice_final';
    }
    async callPreview(paramObj){
        this.axiosObj = axiosCustomObj({});
        
        let accToken = getTokenFromLocStorage();     
        try{
            let urldata =this.apiEndPoint;            
            
            let postData = paramObj.data;        
            
            
            //console.log(" ==dataObj==>",dataObj);
  
  
            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          
  
            let respObj = this.axiosObj.post(urldata,postData, {
            headers: headerOptn
            });
  
            return respObj;
        }
        catch(err){
          console.log("Some error occured===>",err); // TypeError: failed to fetch
        }
        
  
      }
}

export default InvoiceGeneratorPreviewService
