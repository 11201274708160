import React, { Component } from "react";
import CommonContext from "../context/CommonContext";
import { commonJsFuncModule as commonJsObj } from "../../common_utilities/commonjsfunc";
//Services
import TaskDetailsService from "../../components/service/TaskDetailsService";
import TaskInvoiceHistoryService from "../../components/service/TaskInvoiceHistoryService";
const getBaseUrl = commonJsObj.getBaseUrl;
const getCurrentUserId = commonJsObj.getCurrentUserId;
export class SplitScreenLeftMenu extends Component {
  // for context
  static contextType = CommonContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  async getTaskDetailData(param) {
    let respData = null;
    let lservdrf = new TaskDetailsService();
    await lservdrf
      .getTaskDetail(param)
      .then((data) => {
        respData = data.data.entity;
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(" errMsg on task fetch info==>",errMsg);
            // close split screen
            //this.closeSplit();
          }
        }
      })
      .finally(() => {});

    return respData;
  }
  async callServiceToFetchTaskInvoiceHistory(paramObj) {
    let paramObjData = {};
    paramObjData.pageNum = 1;
    paramObjData.taskId = paramObj.taskId;
    let respData = null;
    let stcservice = new TaskInvoiceHistoryService();
    await stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.data.entity.invoices.length > 0) {
          respData = data.data.entity.invoices[0].isPaid;
        } else {
          respData = false;
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            // console.log(errMsg);
          }
        }
      })
      .finally(() => {});
    // console.log(respData,"respData")
    return respData;
  }

  goToSplitScreenTab = async (keyname, otherParam) => {
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    switch (keyname) {
      case "TASKDETAILS":
        if (setShare && sharedData) {
          //setShare(sharedData);

          sharedData.leftTabToActive = "TASKDETAILS";
          sharedData.purpose = "VIEW";

          let paramObj1 = {
            data: {
              taskId: sharedData.taskId,
            },
          };
          sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);

          setShare(sharedData);
        }

        break;
      case "PROFESSIONALSINTERESTED":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
          sharedData.purpose = "VIEW_RESPONSE_LIST";
          setShare(sharedData);
        }
        break;
      case "MONITORTASK":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "MONITORTASK";
          sharedData.purpose = "VIEW_RESPONSE_LIST";
          sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
          setShare(sharedData);
        }
        break;
      case "DOCUMENTS":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "DOCUMENTS";
          sharedData.purpose = "VIEW_DOC_LIST";
          setShare(sharedData);
        }
        break;
      case "TIMELOGS":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "TIMELOGS";
          sharedData.purpose = "VIEW_TIMELOG_LIST";
          sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
          setShare(sharedData);
        }
        break;
      case "INVOICE":
        let paramObj = {
          taskId: sharedData.taskId,
        };
        let isPaid = await this.callServiceToFetchTaskInvoiceHistory(paramObj);
        //console.log(isPaid,"isPaid");
        if (setShare && sharedData) {
          if (!isPaid) {
            sharedData.leftTabToActive = "INVOICE";
            sharedData.purpose = "VIEW_INVOICE";
            sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
            setShare(sharedData);
          } else {
            sharedData.leftTabToActive = "INVOICE";
            sharedData.purpose = "VIEW_INVOICE_LIST";
            sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
            setShare(sharedData);
          }
        }
        break;
    }
  };
  render() {
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    let leftTabToActive =
      sharedData && sharedData.leftTabToActive
        ? sharedData.leftTabToActive
        : "";

    let assignedTo = null;
    let taskResp = null;
    if (
      sharedData &&
      sharedData.other !== undefined &&
      sharedData.other !== null
    ) {
      let otherData = sharedData.other;

      if (otherData) {
        taskResp =
          otherData.taskResp !== undefined && otherData.taskResp !== null
            ? otherData.taskResp
            : null;

        assignedTo =
          taskResp.assignedTo !== undefined && taskResp.assignedTo !== null
            ? taskResp.assignedTo
            : null;
      }
    }

    return (
      <React.Fragment>
        {/* right slide - Left SideBar start */}
        <div className="slideScreen_leftbg">
          <div className="SlideS_leftul">
            <ul>
              <li>
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("TASKDETAILS", {});
                  }}
                  className={`${
                    leftTabToActive == "TASKDETAILS" ? "active" : ""
                  }`}
                >
                  <div className="sidebar_img">
                    <img
                      src={getBaseUrl() + "assets/images/task_details.png"}
                      alt=""
                    />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Task Details</h4>
                    <p>Show task details</p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("PROFESSIONALSINTERESTED", {});
                  }}
                  className={`${
                    leftTabToActive == "PROFESSIONALSINTERESTED" ||
                    leftTabToActive == "PROFESSIONALTASKNEGOTIATIONCHAT"
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="sidebar_img">
                    <img
                      src={getBaseUrl() + "assets/images/negotiations.png"}
                      alt=""
                    />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Professionals Interested</h4>
                    <p>
                      View all the responses / negotiations for a task
                      pre-assignment in here
                    </p>
                  </div>
                </a>
              </li>

              {assignedTo !== null && assignedTo.userId == getCurrentUserId() && (
                <>
                  <li>
                    <a
                      href=""
                      onClick={(ev) => {
                        ev.preventDefault();
                        this.goToSplitScreenTab("MONITORTASK", {});
                      }}
                      className={`${
                        leftTabToActive == "MONITORTASK"
                          ? "top_sug_left active"
                          : "top_sug_left"
                      }`}
                    >
                      <div className="sidebar_img">
                        <img src="assets/images/chat.png" />
                      </div>
                      <div className="hover_tooltip">
                        {/* Newest1 - Start */}
                        <h4>Task Mailbox</h4>
                        {/* Newest1 - End */}
                        <p>
                          You can chat here with the lawyer to get a quick
                          update on the task
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        this.goToSplitScreenTab("DOCUMENTS", {});
                      }}
                      className={`${
                        leftTabToActive == "DOCUMENTS"
                          ? "top_sug_left active"
                          : "top_sug_left"
                      }`}
                    >
                      <div className="sidebar_img">
                        <img
                          src={getBaseUrl() + "assets/images/documents.png"}
                          alt=""
                        />
                      </div>
                      <div className="hover_tooltip">
                        {/* <h4>Document Manager</h4> */}
                        <h4>Task Documents</h4>
                        <p>
                          Where task-related documents can be viewed, uploaded
                          and downloaded.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        this.goToSplitScreenTab("TIMELOGS", {});
                      }}
                      className={`${
                        leftTabToActive == "TIMELOGS"
                          ? "top_sug_left active"
                          : "top_sug_left"
                      }`}
                    >
                      <div className="sidebar_img">
                        <img
                          src={getBaseUrl() + "assets/images/timelog.png"}
                          alt=""
                        />
                      </div>
                      <div className="hover_tooltip">
                        <h4>Activity Log </h4>
                        <p>Activity Log </p>
                      </div>
                    </a>
                  </li>

                  {/* <li>
                                    <a href="#">
                                        <div className="sidebar_img">
                                            <img src={getBaseUrl()+"assets/images/assigned_history.png"} alt=""/>
                                        </div>
                                        <div className="hover_tooltip">
                                            <h4>simply dummy text</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                        </div>
                                    </a>
                                </li> */}
                  <li>
                    <a
                      href=""
                      onClick={(ev) => {
                        ev.preventDefault();
                        this.goToSplitScreenTab("INVOICE", {});
                      }}
                      className={`${
                        leftTabToActive == "INVOICE"
                          ? "top_sug_left active"
                          : "top_sug_left"
                      }`}
                    >
                      <div className="sidebar_img">
                        <img
                          src={getBaseUrl() + "assets/images/invoice.png"}
                          alt=""
                        />
                      </div>
                      <div className="hover_tooltip">
                        <h4>Invoice</h4>
                        <p>Generate and show invoice</p>
                      </div>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        {/* right slide - Left SideBar end */}
      </React.Fragment>
    );
  }
}

export default SplitScreenLeftMenu;
