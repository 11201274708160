import React, { Component } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// for context
import CommonContext from "../../context/CommonContext";
// scroll
// import { Scrollbars } from 'react-custom-scrollbars';
import CustDate from "../../../common_utilities/CustDate";
// For loading CustLoader
import CustLoader from "./CustLoader";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";

//services
import TaskTimeLogListService from "../../service/TaskTimeLogListService";
import AddLogTimeService from "../../service/AddLogTimeService";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// new scroll
import InfiniteScroll from "react-infinite-scroll-component";

//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
// const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
// const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getBaseUrl = commonJsObj.getBaseUrl;
const getCurrentUserId = commonJsObj.getCurrentUserId;
const convertMinutueToHour = commonJsObj.convertMinutueToHour;
const getStringInitials = commonJsObj.getStringInitials;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;

export class SplitScreenTimeLogs extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenTimeLogs
   * Function name : constructor
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = {
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      lpUserId: 0,
      ccUserId: 0,
      taskId: 0,
      totalMinutesLogged: 0,
      lpUserName: "",
      sortDir: "",
      sortOn: "",
      dateSortDirClass: "",
      timeSortDirClass: "",
      dateFrom: null,
      dateTo: null,
      addTimeLog: false,
      dateForWhichTheTimeHasBeenLogged: null,
      dateForWhichTheTimeHasBeenLoggedErrMsg: "",
      loggedHour: "",
      loggedHourErrMsg: "",
      loggedMin: "",
      loggedMinErrMsg: "",
      description: "",
      descriptionErrMsg: "",
      blankTimeErrMsg: "",
      totalTimeErrMsg: "",
      saveTimeLog: true,
      taskType: "",
      organisationDetails: null,
      assignedTo: null,
    };
    this.scrollerRef = React.createRef();
  }
  componentDidMount() {
    this.isComponentLoaded = true;
    let taskId = 0;
    let lpUserId = 0;
    let ccUserId = 0;
    let taskType = "";
    let organisationDetails = null;
    let assignedTo = null;

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        if (sharedData.other !== undefined && sharedData.other !== null) {
          //console.log(" sharedData.other==>",sharedData.other);
          if (
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            let taskResp = sharedData.other.taskResp;
            console.log(" 1111 taskResp=>>>>", taskResp);
            organisationDetails =
              taskResp !== null &&
              taskResp.organisationDetails !== undefined &&
              taskResp.organisationDetails !== null
                ? taskResp.organisationDetails
                : null;
            assignedTo =
              taskResp !== null &&
              taskResp.assignedTo !== undefined &&
              taskResp.assignedTo !== null
                ? taskResp.assignedTo
                : null;

            taskId =
              taskResp.id !== undefined && taskResp.id !== null
                ? taskResp.id
                : 0;

            taskType =
              taskResp.taskType !== undefined && taskResp.taskType !== null
                ? taskResp.taskType
                : "";

            lpUserId = getCurrentUserId();
          }
          ccUserId = sharedData.other.taskResp.owner.userId;
        }
      }
    }

    // set list data to blank state
    this.showLoader();
    setTimeout(() => {
      // call list api
      let paramObj = {
        pageNum: 1,
        pageNumArTrack: [],
        listData: [],
        totalNumberOfPages: 1,
        taskId: taskId,
        lpUserId: lpUserId,
        ccUserId: ccUserId,
        taskType: taskType,
        organisationDetails: organisationDetails,
        assignedTo: assignedTo,
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.callServiceToFetchTaskTimeLogList(
            paramObj,
            "TASKTIMELOGLISTFROMCOMPONENTLOADED"
          );
        });
    }, 1005);
  }

  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  componentDidUpdate(prevProps) {}
  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
    //     if(sharedData.actionType=="SPLITSCREEN" ){
    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }
    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "TASK_TIMELOG_SCREEN",
    });
  };

  // trackScroll = ()=>{

  //     let scobj = this.scrollerRef.current;
  //     let scrollTop = scobj.getValues().scrollTop;
  //     let scrollHeight = scobj.getScrollHeight();
  //     let clientHeight = scobj.getClientHeight();

  //     let prevPageNum = this.state.pageNum;
  //     let totalNumberOfPages = this.state.totalNumberOfPages;

  //     //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

  //     if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){

  //       this.showLoader();

  //         setTimeout(()=>{
  //             // call list api
  //             let pageNumData = prevPageNum + 1;
  //             let paramObj = {"pageNum":pageNumData,"taskId":this.state.taskId,"lpUserId":this.state.lpUserId,"ccUserId":this.state.ccUserId};

  //             this.isComponentLoaded && this.setState(paramObj,()=>{
  //             this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMSCROLLER");
  //             });
  //         },900);
  //     }

  // }

  fetchMoreData = () => {
    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    if (prevPageNum < totalNumberOfPages) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = {
          pageNum: pageNumData,
          taskId: this.state.taskId,
          lpUserId: this.state.lpUserId,
          ccUserId: this.state.ccUserId,
        };

        this.isComponentLoaded &&
          this.setState(paramObj, () => {
            this.callServiceToFetchTaskTimeLogList(
              paramObj,
              "TASKTIMELOGLISTFROMSCROLLER"
            );
          });
      }, 900);
    }
  };

  callServiceToFetchTaskTimeLogList = (paramObj, trackcalledfrom) => {
    //console.log(" trackcalledfrom=>",trackcalledfrom," paramObj=>",paramObj);
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.taskId = paramObj.taskId;
    paramObjData.lpUserId = paramObj.lpUserId;
    paramObjData.sortOn = paramObj.sortOn;
    paramObjData.sortDir = paramObj.sortDir;
    paramObjData.dateFrom = paramObj.dateFrom;
    paramObjData.dateTo = paramObj.dateTo;

    let stcservice = new TaskTimeLogListService();
    stcservice
      .getTimeLogList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          let totalMinutesLogged = 0;

          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            //console.log(respData,"respData")
            totalMinutesLogged =
              respData.totalMinutesLogged !== undefined &&
              respData.totalMinutesLogged !== null
                ? respData.totalMinutesLogged
                : null;
            if (respData.timeReportsForUser !== undefined) {
              if (respData.timeReportsForUser.length > 0) {
                // set list data to state
                let prevRecords = [...this.state.listData];
                let currRecords = respData.timeReportsForUser;
                let concatenateRecords = prevRecords.concat(currRecords);
                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: concatenateRecords,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      totalMinutesLogged: totalMinutesLogged,
                    },
                    () => {}
                  );
              } else {
                if (this.state.listData.length == 0) {
                  this.isComponentLoaded &&
                    this.setState({
                      listData: [],
                      totalNumberOfPages: respData.totalNumberOfPages,
                      pageNum: 1,
                      totalMinutesLogged: totalMinutesLogged,
                    });
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }
  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }
  setCalDate = (dt) => {
    dt = getTimestampInMillisecondsFromDateObj(dt);
    this.isComponentLoaded &&
      this.setState({ dateForWhichTheTimeHasBeenLogged: dt }, () => {
        //console.log(" after dateFrom set this.state=>",this.state);
      });
  };
  customDate = (defaultDate, placeholdertxt) => {
    return (
      <DatePicker
        placeholderText="Date"
        selected={defaultDate}
        onChange={(date) => {
          this.setCalDate(date);
        }}
        calendarClassName="customtaskaddclassstep1calcls"
        className="form__field customdatefld"
        onKeyDown={(event) => {
          event.preventDefault();
        }}
        maxDate={new Date()}
        dateFormat={getDateFormatForCal({ countryCode: "IN" })}
      />
    );
  };
  triggerDateSort = (other) => {
    let sortDir = this.state.sortDir;
    let sortOn = other.sortOn;
    let dateSortDirClass = "";
    let timeSortDirClass = "";
    if (this.state.sortOn != sortOn) {
      sortDir = "DESC";
      if (sortOn == "DATE") {
        dateSortDirClass = " th_des ";
        timeSortDirClass = "";
      } else if (sortOn == "LOG_HOUR") {
        dateSortDirClass = "";
        timeSortDirClass = " th_des ";
      }
    } else {
      if (sortDir == "" || sortDir == "ASC") {
        sortDir = "DESC";
        if (sortOn == "DATE") {
          dateSortDirClass = " th_des ";
          timeSortDirClass = "";
        } else if (sortOn == "LOG_HOUR") {
          dateSortDirClass = "";
          timeSortDirClass = " th_des ";
        }
      } else {
        sortDir = "ASC";
        if (sortOn == "DATE") {
          dateSortDirClass = " th_ase ";
          timeSortDirClass = "";
        } else if (sortOn == "LOG_HOUR") {
          dateSortDirClass = "";
          timeSortDirClass = " th_ase ";
        }
      }
    }

    let paramObj = {
      sortOn: sortOn,
      sortDir: sortDir,
      dateSortDirClass: dateSortDirClass,
      timeSortDirClass: timeSortDirClass,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      pageNum: 1,
      listData: [],
      pageNumArTrack: [],
      taskId: this.state.taskId,
      lpUserId: this.state.lpUserId,
      ccUserId: this.state.ccUserId,
    };

    let scobj = this.scrollerRef.current;
    scobj.scrollToTop();

    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        // call list api
        this.showLoader();
        setTimeout(() => {
          this.callServiceToFetchTaskTimeLogList(
            paramObj,
            "TASKTIMELOGLISTFROMSORT"
          );
        }, 500);
      });
  };
  triggerAddtime = () => {
    this.setState({ saveTimeLog: false });
    //console.log("tt")
    //this.isComponentLoaded && this.setState({loaderFlag:true});
    const isValid = this.custValidate();
    if (isValid) {
      //console.log(this.state)
      let service = new AddLogTimeService();
      let minutes =
        parseInt(
          (this.state.loggedHour != "" ? this.state.loggedHour : 0) * 60
        ) + parseInt(this.state.loggedMin != "" ? this.state.loggedMin : 0);
      let paramObjData = {
        taskId: this.state.taskId,
        minutes: minutes,
        dateForWhichTheTimeHasBeenLogged:
          this.state.dateForWhichTheTimeHasBeenLogged,
        description: this.state.description,
      };

      service
        .addTimeLog({ data: paramObjData })
        .then((data) => {
          if (data.status == 200) {
            let respData = {};
            if (data.data.entity !== undefined) {
              respData = data.data.entity;
              //console.log(respData,"respData")
            }
            this.setState({ addTimeLog: false });
          }
        })
        .catch((err) => {
          let errStatusCodeAr = [400, 500]; // allowed error status code
          if (err.response && err.response.data) {
            let erRespData = err.response.data;
            if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
              let errMsg = erRespData.message
                ? erRespData.message
                : "Some technical problem occurred.";
              //console.log(errMsg);
              let notiMsg = errMsg;
              notify(notiMsg, true, {
                successMsg: 0,
                customWrapClass: "customerrmidlass",
                showCloseButton: false,
                dismiss: {
                  duration: 2500,
                },
              });
            }
          }
        })
        .finally(() => {
          this.setState({ saveTimeLog: true });
          //this.hideLoader();
          this.showLoader();
          let paramObj = {
            pageNum: 1,
            pageNumArTrack: [],
            listData: [],
            totalNumberOfPages: 1,
            taskId: this.state.taskId,
            lpUserId: this.state.lpUserId,
            ccUserId: this.state.ccUserId,
            dateForWhichTheTimeHasBeenLogged: null,
            loggedHour: "",
            loggedMin: "",
            description: "",
            descriptionErrMsg: "",
            dateForWhichTheTimeHasBeenLoggedErrMsg: "",
            loggedMinErrMsg: "",
            loggedHourErrMsg: "",
            blankTimeErrMsg: "",
            totalTimeErrMsg: "",
          };
          this.isComponentLoaded &&
            this.setState(paramObj, () => {
              this.callServiceToFetchTaskTimeLogList(paramObj, "AFTERTIMEADD");
            });
        });
    } else {
      //this.isComponentLoaded && this.setState({loaderFlag:false});
      this.isComponentLoaded && this.setState({ saveTimeLog: true });
    }
  };
  custValidate = () => {
    let dateForWhichTheTimeHasBeenLoggedErrMsg = "";
    let loggedHourErrMsg = "";
    let loggedMinErrMsg = "";
    let descriptionErrMsg = "";
    let blankTimeErrMsg = "";
    let totalTimeErrMsg = "";

    let regexBlankSpace = /^ *$/;
    let regexOnlyNumbers = /^[0-9]*$/;
    let totErrCountTrack = [];

    if (
      !this.state.description ||
      this.state.description.length == 0 ||
      regexBlankSpace.test(this.state.description) == true
    ) {
      descriptionErrMsg = "Description is required.";
      this.isComponentLoaded &&
        this.setState({ descriptionErrMsg: descriptionErrMsg });
      totErrCountTrack.push(descriptionErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ descriptionErrMsg: "" });
    }
    if (this.state.dateForWhichTheTimeHasBeenLogged === null) {
      dateForWhichTheTimeHasBeenLoggedErrMsg = "Date is required";
      this.isComponentLoaded &&
        this.setState({
          dateForWhichTheTimeHasBeenLoggedErrMsg:
            dateForWhichTheTimeHasBeenLoggedErrMsg,
        });
      totErrCountTrack.push(dateForWhichTheTimeHasBeenLoggedErrMsg);
    } else {
      this.isComponentLoaded &&
        this.setState({ dateForWhichTheTimeHasBeenLoggedErrMsg: "" });
    }

    if (regexOnlyNumbers.test(this.state.loggedHour) == false) {
      loggedHourErrMsg = "Please enter numeric value";
      this.isComponentLoaded &&
        this.setState({ loggedHourErrMsg: loggedHourErrMsg });
      totErrCountTrack.push(loggedHourErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ loggedHourErrMsg: "" });
    }
    if (this.state.loggedHour > 24 && this.state.loggedHourErrMsg == "") {
      loggedHourErrMsg = "Hours should be less than 24";
      this.isComponentLoaded &&
        this.setState({ loggedHourErrMsg: loggedHourErrMsg });
      totErrCountTrack.push(loggedHourErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ loggedHourErrMsg: "" });
    }

    if (regexOnlyNumbers.test(this.state.loggedMin) == false) {
      loggedMinErrMsg = "Please enter numeric value";
      this.isComponentLoaded &&
        this.setState({ loggedMinErrMsg: loggedMinErrMsg });
      totErrCountTrack.push(loggedMinErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ loggedMinErrMsg: "" });
    }
    if (this.state.loggedMin > 60 && this.state.loggedMinErrMsg == "") {
      loggedMinErrMsg = "Minute should be less than 60";
      this.isComponentLoaded &&
        this.setState({ loggedMinErrMsg: loggedMinErrMsg });
      totErrCountTrack.push(loggedMinErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ loggedMinErrMsg: "" });
    }
    if (this.state.loggedHour == "" && this.state.loggedMin == "") {
      blankTimeErrMsg = "Please enter time.";
      this.isComponentLoaded &&
        this.setState({ blankTimeErrMsg: blankTimeErrMsg });
      totErrCountTrack.push(blankTimeErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ blankTimeErrMsg: "" });
    }
    if (
      parseInt((this.state.loggedHour != "" ? this.state.loggedHour : 0) * 60) +
        parseInt(this.state.loggedMin != "" ? this.state.loggedMin : 0) >
      1440
    ) {
      totalTimeErrMsg = "Total time should be less than 24 hrs";
      this.isComponentLoaded &&
        this.setState({ totalTimeErrMsg: totalTimeErrMsg });
      totErrCountTrack.push(totalTimeErrMsg);
    } else {
      this.isComponentLoaded && this.setState({ totalTimeErrMsg: "" });
    }
    //console.log(totErrCountTrack)
    if (totErrCountTrack.length > 0) {
      return false;
    }

    return true;
  };
  handleChange = (e) => {
    this.isComponentLoaded &&
      this.setState({ [e.target.name]: e.target.value });
  };
  loadfooterOfDocument = () => {
    let downLoadUrl = commonJsObj.getEndPoint() + "rest/time/report/pdf/";
    let offsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime(
      new Date()
    );
    let offsetHr = offsetOfLocalDateTime.hr;
    let offsetMin = offsetOfLocalDateTime.min;
    let accToken = getTokenFromLocStorage();

    downLoadUrl =
      downLoadUrl +
      "task/" +
      this.state.taskId +
      "?offset_hour=" +
      offsetHr +
      "&offset_minute=" +
      offsetMin +
      "&auth=" +
      accToken;

    if (this.state.sortOn != "" && this.state.sortDir != "") {
      downLoadUrl =
        downLoadUrl +
        "&sort_on=" +
        this.state.sortOn +
        "&sort_dir=" +
        this.state.sortDir;
    }

    // if(this.state.dateFrom!==null && this.state.dateTo!==null){
    //     downLoadUrl = downLoadUrl+"&from="+this.state.dateFrom+"&to="+this.state.dateTo;
    // }

    return (
      <>
        {/* === right slide section Footer  start ===  */}
        <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray invoiceList_bottomBg SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_timeSheet">
          <div className="chat_bottom_left">
            <a
              href={downLoadUrl}
              download={true}
              className="btn_appearance_none timesheet_downloadBtn"
            >
              <span>Download Activity Log</span>
            </a>
          </div>
          <div className="s_footer_btnbg">
            <div className="timeSt_Footer_right">
              <p className="timeSt_Footer_totlaTime">
                <b>Total hours </b>{" "}
                <span>
                  {convertMinutueToHour(this.state.totalMinutesLogged) !== null
                    ? convertMinutueToHour(this.state.totalMinutesLogged)
                        .hoursDisplayText2
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* === right slide section Footer  end ===  */}
      </>
    );
  };

  loadTimeLogContent = () => {
    // let dateFrom = this.state.dateFrom;
    // let defaultDateFrom = dateFrom!==null?getDateObjFromMilliseconds(dateFrom):null;

    // let dateTo = this.state.dateTo;
    // let defaultDateTo = dateTo!==null?getDateObjFromMilliseconds(dateTo):null;

    let dateForWhichTheTimeHasBeenLogged =
      this.state.dateForWhichTheTimeHasBeenLogged;
    let defaultDateForWhichTheTimeHasBeenLogged =
      dateForWhichTheTimeHasBeenLogged !== null
        ? getDateObjFromMilliseconds(dateForWhichTheTimeHasBeenLogged)
        : null;

    //console.log(" ****** this.state==>",this.state);
    return (
      <>
        {/* === right slide section Middlie  start ===  */}
        <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_timesheet SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_timeSheet_lp">
          <div
            ref={this.scrollerRef}
            id="scrollableDivTimelg"
            style={{
              flexDirection: "column",
            }}
            className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_timeSheet_lp"
          >
            {/* <Scrollbars 
                                  ref={this.scrollerRef}
                                  style={{ height: 900 }}                  
                                  autoHeightMin={900}
                                  renderView={props => <div {...props} className="view timeloglistscrollcustomclass"/>}
  
                                  
                > */}
            <div className="s_midbox_section s_midbox_section_chat">
              {/* <CustomMessageBar /> */}
              {/* ====   sprint - 5 chat structure start ====  */}
              {/* Document list start  */}
              <div className="dataTable_bg dataTable_bg_lp">
                {this.state.taskType != "CLOSED_TASK" &&
                  !this.state.addTimeLog && (
                    <div className="timesheet_addTime_btnBg">
                      <div className="add_time_row">
                        <button
                          className="btn_appearance_none sub_grd_btn"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ addTimeLog: true });
                          }}
                        >
                          <span>Add Time</span>
                        </button>
                      </div>
                    </div>
                  )}
                {this.state.addTimeLog && (
                  <div className="timesheet_filterBg">
                    <div className="timesheet_filterBg_left timesheet_filterBg_left_lp">
                      <form>
                        <div className="timesheet_filter_box">
                          <div className="timest_date_box">
                            <div className="timest_inputGrp timest_date_grp">
                              <div
                                className={`form__group field ${
                                  this.state
                                    .dateForWhichTheTimeHasBeenLoggedErrMsg
                                    ? "form_group_error"
                                    : ""
                                }`}
                              >
                                <div
                                  className="input-group date"
                                  id="sandbox-container"
                                >
                                  {/* <input type="text" className="form__field customdatefld" placeholder="Date"/> */}
                                  {this.customDate(
                                    defaultDateForWhichTheTimeHasBeenLogged,
                                    "Date"
                                  )}
                                </div>
                                <span className="error_span">
                                  {
                                    this.state
                                      .dateForWhichTheTimeHasBeenLoggedErrMsg
                                  }
                                </span>
                              </div>
                              <div className="clearboth"></div>
                            </div>

                            <div className="timest_hr_grp">
                              <div
                                className={`form__group field ${
                                  this.state.loggedHourErrMsg ||
                                  this.state.blankTimeErrMsg ||
                                  this.state.totalTimeErrMsg
                                    ? "form_group_error"
                                    : ""
                                }`}
                              >
                                <div className="input-group input_hr">
                                  <input
                                    type="text"
                                    className="form__field"
                                    placeholder="0"
                                    name="loggedHour"
                                    id="loggedHour"
                                    onChange={this.handleChange}
                                    value={this.state.loggedHour}
                                  />
                                  <span className="hr_lable">hrs</span>
                                </div>
                                {/* <span className="error_span">{this.state.loggedHourErrMsg}</span> */}
                                <span className="error_span">
                                  {this.state.blankTimeErrMsg}
                                </span>
                                <span className="error_span">
                                  {this.state.totalTimeErrMsg}
                                </span>
                              </div>
                              <div className="clearboth"></div>
                            </div>

                            <div className="timest_hr_grp">
                              <div
                                className={`form__group field ${
                                  this.state.loggedMinErrMsg ||
                                  this.state.blankTimeErrMsg ||
                                  this.state.totalTimeErrMsg
                                    ? "form_group_error"
                                    : ""
                                }`}
                              >
                                <div className="input-group input_hr">
                                  <input
                                    type="text"
                                    className="form__field"
                                    placeholder="0"
                                    name="loggedMin"
                                    id="loggedMin"
                                    onChange={this.handleChange}
                                    value={this.state.loggedMin}
                                  />
                                  <span className="hr_lable">mins</span>
                                </div>
                                <span className="error_span">
                                  {this.state.loggedMinErrMsg}
                                </span>
                              </div>
                              <div className="clearboth"></div>
                            </div>
                          </div>

                          <div className="timest_desc_box">
                            <div
                              className={`form__group field ${
                                this.state.descriptionErrMsg
                                  ? "form_group_error"
                                  : ""
                              }`}
                            >
                              <textarea
                                placeholder="Description"
                                name="description"
                                id="description"
                                onChange={this.handleChange}
                                value={this.state.description}
                              ></textarea>
                              <span className="error_span">
                                {this.state.descriptionErrMsg}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="timest_btn_box timest_btn_box_lp">
                          <div className="timest_filterBtn">
                            <button
                              className="btn_appearance_none blank_btn"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ addTimeLog: false });
                              }}
                            >
                              <span>Cancel</span>
                            </button>
                            <button
                              className="btn_appearance_none sub_grd_btn chatHeadAssign "
                              onClick={(e) => {
                                e.preventDefault();
                                this.triggerAddtime();
                              }}
                              disabled={!this.state.saveTimeLog}
                            >
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {/* == white table timeSheet box bg start ==  */}

                {/* ==== timesheet ====  */}
                <div className="timesheet_block">
                  <div className="table_boxBg">
                    <div className="reactTable_scroll mCustomAdjustmentForStickFooter_timeSheetTable timeSheetTableScrollAdjust timeSheetTable_lp">
                      {/* <Scrollbars 
                                  ref={this.scrollerRef}
                                  style={{ height: 450 }}                  
                                  autoHeightMin={450}
                                  renderView={props => <div {...props} className="view timeloglistscrollcustom2class"/>}
  
                                  onScrollStop={this.trackScroll}
                        > */}
                      <InfiniteScroll
                        dataLength={this.state.listData.length} //This is important field to render the next data
                        next={this.fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDivTimelg"
                        inverse={false}
                      >
                        <div className="table-responsive">
                          {/* ====   Table start  ====   */}
                          <table
                            id="example"
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <div className="tabeTh_data">Date</div>
                                  <div className="th_btn">
                                    <button
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        this.triggerDateSort({
                                          sortOn: "DATE",
                                        });
                                      }}
                                      className={`btn_appearance_none th_normal ${this.state.dateSortDirClass}`}
                                    >
                                      <span className="uparrow">
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="downarrow">
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </th>
                                <th>
                                  <div className="tabeTh_data">Logged</div>
                                  <div className="th_btn">
                                    <button
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        this.triggerDateSort({
                                          sortOn: "LOG_HOUR",
                                        });
                                      }}
                                      className={`btn_appearance_none th_normal ${this.state.timeSortDirClass}`}
                                    >
                                      <span className="uparrow">
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="downarrow">
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                                  <td>16 Sep 2019</td>
                                  <td>1hr 0min</td>
                                  <td>
                                    <p>
                                      Started working on the file &amp; filled the report.
                                    </p>
                                  </td>
                                </tr>
                                  */}
                              {this.state.loaderFlag == false &&
                                this.state.listData.length == 0 && (
                                  <tr key={"kndf1"}>
                                    <td colSpan="3">
                                      <div className=" text-center">
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              {this.state.listData.length > 0 &&
                                Object.keys(this.state.listData).map((e, i) => (
                                  <tr key={i}>
                                    <td>
                                      {this.state.listData[e].loggedAtInMillis
                                        ? CustDate.custFormatDate(
                                            new Date(
                                              this.state.listData[
                                                e
                                              ].loggedAtInMillis
                                            ),
                                            3
                                          )
                                        : ""}
                                    </td>
                                    <td>
                                      {convertMinutueToHour(
                                        this.state.listData[i].loggedMinutes
                                      ) !== null
                                        ? convertMinutueToHour(
                                            this.state.listData[i].loggedMinutes
                                          ).hoursDisplayText1
                                        : ""}
                                    </td>
                                    <td>
                                      <p>
                                        {this.state.listData[i].logDescription
                                          ? this.state.listData[i]
                                              .logDescription
                                          : ""}
                                      </p>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="3">
                                  {this.state.loaderFlag
                                    ? this.loadLoader()
                                    : null}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          {/* ====   Table end  ====   */}
                        </div>
                      </InfiniteScroll>
                      {/* </Scrollbars> */}
                    </div>
                  </div>
                </div>
                {/* ==== timesheet ====  */}
                {/* ==== timesheet ====  */}
                {/* ==== timesheet ====  */}
                {/* ==== timesheet ====  */}
                {/* ==== timesheet ====  */}
                {/* == white table timeSheet box bg end ==  */}
              </div>
              {/* Document list end  */}
              {/* ====   sprint - 5 chat structure end ====  */}
            </div>

            {/* </Scrollbars> */}
          </div>
        </div>
        {/* === right slide section Middlie  end ===  */}
      </>
    );
  };

  loadTheContent = () => {
    let forSplitScrnCls2Inject = "";
    let datasPassForAssignObj = {};
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    let nameToDisplay = "";
    let firstName = "";
    let lastName = "";
    let profilePictureURL = "";
    let assignedTo = this.state.assignedTo;
    firstName =
      assignedTo && assignedTo.firstName !== undefined && assignedTo.firstName
        ? assignedTo.firstName
        : "";
    lastName =
      assignedTo && assignedTo.lastName !== undefined && assignedTo.lastName
        ? assignedTo.lastName
        : "";
    nameToDisplay = firstName + " " + lastName;
    profilePictureURL =
      assignedTo &&
      assignedTo.profilePictureURL !== undefined &&
      assignedTo.profilePictureURL
        ? assignedTo.profilePictureURL
        : "";

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area
                ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item">
                <div className="slide_top_titlebg">
                  {/* <h4>Timelog</h4> */}
                  <div className="titleText_withImageDropdown">
                    <div className="titleText_withImgDropBlock titleText_withImgDropBlock_staticText">
                      <span className="titleText_withImageDropdown_textSpan">
                        Activity Log entered by
                      </span>
                    </div>
                    <div className="titleText_withImgDropBlock titleText_withImgDropBlock_imgName">
                      <img
                        className="titleText_withImage"
                        src={
                          profilePictureURL
                            ? profilePictureURL
                            : getBaseUrl() +
                              "assets/images/user-avatar-thumb.png"
                        }
                      />
                      <button
                        className="btn_appearance_none titleText_withImageDropdown_btn pointer_none"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {nameToDisplay}
                      </button>
                      {/* --- optional dropdown for title image text start ---     */}
                      {/* 
                                                        <div class="dropdown-menu user_popDetails_block">
                                                            <div class="user_popBox">
                                                                <div class="u_popBox_sec user_popBox_top">
                                                                    <div class="user_popBox_topLeft">
                                                                        <h4>Condition -1  Sourav Roy</h4>
                                                                        <div class="userRating_block">
                                                                            <div class="ass_userRating">
                                                                                <i class="fa fa-star" aria-hidden="true"></i>  4.5 
                                                                            </div>
                                                                            <div class="ass_userExp">
                                                                                Experience - 5 years
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="user_popBox_topRight">
                                                                        <div class="user_name_text"><span>AM</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="u_popBox_sec user_popBox_location">
                                                                    <h5>Locations</h5>
                                                                    <ul>
                                                                        <li>Lorem ipsum dolor sit</li>
                                                                        <li>Loremipsumdolorsit</li>
                                                                        <li>Loremipsum dolorsit</li>
                                                                        <li>Loremipsum dolorsit</li>
                                                                    </ul>
                                                                </div>
                                                                <div class="u_popBox_sec user_popBox_pracArea">
                                                                    <h5>Practice areas</h5>
                                                                    <ul>
                                                                        <li>Lorem ipsum dolor sit</li>
                                                                        <li>Loremipsumdolorsit</li>
                                                                        <li>Loremipsum dolorsit</li>
                                                                        <li>Loremipsum dolorsit</li>
                                                                    </ul>
                                                                </div>
                                                                <div class="u_popBox_sec user_popBox_about">
                                                                    <h5>About</h5>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        */}
                      {/* --- optional dropdown for title image text end ---     */}
                    </div>
                  </div>

                  <button
                    className="btn_appearance_none slideTop_cross_btn cross"
                    onClick={(evn) => {
                      evn.preventDefault();
                      //
                      this.closeSplit({ trigerredFrom: "TASK_TIMELOG_SCREEN" });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img
                      src={getBaseUrl() + "assets/images/red_cross.png"}
                      alt=""
                    />
                  </button>
                  <div className="clearboth" />
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              <CustomMessageBar />
              {this.loadTimeLogContent()}

              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}

              {this.loadfooterOfDocument()}

              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  render() {
    return <React.Fragment>{this.loadTheContent()}</React.Fragment>;
  }
}

export default SplitScreenTimeLogs;
