import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import CommonContext from '../../context/CommonContext';
import FetchKycService from '../../service/FetchKycService';
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
import FetchProfileInfoService from '../../service/FetchProfileInfoService';
import CustLoader from './CustLoader';
import { Scrollbars } from 'react-custom-scrollbars';
const getBaseUrl = commonJsObj.getBaseUrl;
//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;

export class Profile extends Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);
        this.state ={
            kycVerifiedStatus:true,
            profileDetails:'',
            practiceAreas:'',
            locations:''
        };
        this.scrollerRef = React.createRef();
    }
    componentDidMount() {
        //console.log(getKycVerifiedStatus());
        this.fetchLoggedinUserInfoDetails();
        // this.setState({kycVerifiedStatus:getKycVerifiedStatus()})

    }
    fetchLoggedinUserInfoDetails=()=>{
        let loginuserserv = new LoggedinUserInfoService();
        loginuserserv.getLoggedInUserInfo({}).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                   // console.log(data.data.entity.isKycVerified)
                    this.setState({kycVerifiedStatus:data.data.entity.isKycVerified},()=>{
                        //console.log(this.state)
                        if(this.state.kycVerifiedStatus){
                            console.log(this.state)
                            this.fetchProfileDetails();
                        }
                    })
                }
            }
         }).catch(err=>{
            //console.log("Some server related tech problem occured",err);

        });
    }

    fetchProfileDetails= () =>{
        let proserv = new FetchProfileInfoService();
        proserv.getProfileDetails().then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.setState({profileDetails:data.data.entity},()=>{
                    console.log(this.state)
                })
                let practiceAreas = [];
                let locations = [];
                data.data.entity.practiceAreas.map((el)=>{
                    practiceAreas.push(el.practiceArea);

                })
                data.data.entity.locationsActive.map((el)=>{
                    locations.push(el.city);
                })
                this.setState({practiceAreas:practiceAreas.join(), locations: locations.join()})
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }
    componentWillUnmount() {}
    render() {
        return (
            <>
            {/* Dashboard Main Screen content area 01 start */}
                <div className="kyc_section_part_01">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section lp_settingContAreaBg">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting">
                            <div className="create_task_area userSetting_area">

                                {/* ===  task Listing filter start === */}
                                <div className="task_area_filterbg task_area_filterbg_lp">
                                    <div className="filter_area ">
                                        {/* ===  task top filter start ===  */}
                                        {/* --- body top filter tab left start ---  */}
                                        <div className="top_filter_left top_filter_left_lp">
                                            <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                                                {/* == lp task ul start ==   */}
                                                {/* == lp task ul end ==   */}
                                                {/* == profile setting ul start ==   */}
                                                <div className="profile_setting_ulbg pr_set_ulbg">
                                                    <ul>
                                                        <li >
                                                            <NavLink to="/kyc" className="btn_appearance_none task_ass_tabBtn">
                                                                <span className="task_ass_span">KYC Details</span>
                                                            </NavLink >
                                                        </li>
                                                        <li className="active">
                                                            <NavLink  to="/profile" className="btn_appearance_none task_ass_tabBtn">
                                                                <span className="task_ass_span">Profile</span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* == profile setting ul end ==   */}
                                                {/* == application setting ul start ==   */}
                                                {/* == application setting ul end ==   */}
                                                {/* == profile setting ul start ==   */}
                                                {/* == profile setting ul end ==   */}
                                                <div className="clearboth"></div>
                                            </div>
                                        </div>
                                        {/* --- body top filter tab left end ---  */}
                                        {/* --- body top filter right start ---  */}
                                        {/* --- body top filter right end ---  */}
                                        {/* ===  task top filter end === */}
                                        <div className="clearboth"></div>
                                    </div>
                                </div>
                                {/* ===  task Listing filter end === */}

                                {/* ===========  Setting Page main body start =============   */}
                                {/* ===  task Listing start start === */}
                                {/* ===  task Listing start end === */}
                                {/* ===========  Setting Page main body end =============   */}

                            </div>
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                </div>
                {/* Dashboard Main Screen content area 01 end */}
                {/* Dashboard Main Screen content area 02 start */}
                <div className="kyc_section_part_02">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting">
                            <div className="create_task_area userSetting_area">
                                {/* ===  task Listing filter start === */}
                                {/* ===  task Listing filter end === */}
                                {/* ===========  Setting Page main body start =============   */}
                                {/* ===  task Listing start start === */}
                                <div className="task_area userSetting_task_area">
                                    {/* === User Setting Section / Task Listing Section Start === */}
                                    {/* ============== Start =================   */}
                                    <div className="userSetting_section">
                                        {/* -----  scroll adjust for settting screens start -----   */}
                                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                                            {/* ==============   User Setting Section Start =================   */}
                                            <div className="userSetting_area_bg">

                                                {/* ==--== Kyc Top Notification section start ==--== */}
                                                {!this.state.kycVerifiedStatus && <div className="kyc_top_noti_bg">
                                                    <div className="kyc_top_noti_box">
                                                        <p><b>Verification Pending.</b> You will only be able to view open tasks that you may be eligible for, once your account verification is complete</p>
                                                    </div>
                                                </div>}
                                                {/* ==--== Kyc Top Notification section end ==--== */}

                                                {/* ===== ** LP User KYC page body start =====  */}
                                                {this.state.kycVerifiedStatus && <div className="userSetting_area user_page_area">
                                                    <div className="userSetting_scroll">
                                                        {/* ====  User KYC start ===  */}
                                                        <div className="user_kyc_bg">
                                                            {/* ==== KYC accordian block start ===  */}

                                                            <div className="accordian_container">
                                                                {/* <div className="react_use_set_scroll user_scroll mCustomScrollbar height_550">
                                                                <Scrollbars
                                                                        ref={this.scrollerRef}
                                                                        style={{ height: 520 }}
                                                                        //autoHeightMin={520}
                                                                        renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                                                        > */}
                                                                    {/* ====  kyc accordian scroll start ====  */}
                                                                    <div id="accordion" className="accordion kyc_accordion_sec">
                                                                        <div className="card mb-0 ">

                                                                            {/* accordion block - 01 start */}
                                                                            {/* accordion block - 01 end */}
                                                                            {/* accordion block - 02 start */}
                                                                            {/* accordion block - 02 end */}

                                                                            {/* No accordion block - 03 start */}
                                                                            <div className="kyc_accor_block kyc_non_accor_block">
                                                                                <div className="card-body  kyc_non_accor_fromBg" >
                                                                                    {/* ============== */}
                                                                                    <div className="s_midbox s_midbox_kyc">
                                                                                        <div className="main_form_box">
                                                                                            {/* ======= kyc form field start ======= */}

                                                                                            <div className="form_block form_block_half">
                                                                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Practice / Law Firm Name</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.lawFirmName}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Name of Professional</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.practiceOwnerName}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_half">
                                                                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Type of practice</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.practiceType}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                <label htmlFor="name" className="form__label"><b>Practice Areas</b></label>
                                                                                                <div className="form__group field">
                                                                                                    <div className="input_datashow">
                                                                                                        <p>{this.state.practiceAreas}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Locations Active (upto 5)</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                        <p>{this.state.locations}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_half">
                                                                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Registered with any Bar?</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.isBarRegistered==true? 'Yes' : 'No'}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                <label htmlFor="name" className="form__label"><b>Bar Registration Number</b></label>
                                                                                                <div className="form__group field">
                                                                                                    <div className="input_datashow">
                                                                                                        <p>{this.state.profileDetails.barRegistrationNumber?this.state.profileDetails.barRegistrationNumber : '-'}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Registration State</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.barRegisteredState?this.state.profileDetails.barRegisteredState.name : '-'}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>About Your Practice (50 words)</b></label>
                                                                                                    <div className="form__group field">
                                                                                                        <div className="input_datashow">
                                                                                                            <p>{this.state.profileDetails.aboutThePractice}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>
                
                                                                                            {/* ======= kyc form field end ======= */}
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* ============== */}
                                                                                </div>
                                                                            </div>
                                                                            {/* No accordion block - 03 end */}

                                                                            {/* No accordion message block - 04 start */}
                                                                            <div className="no_acco_msgBlock_bg">
                                                                                <div className="no_acco_msgBlock">
                                                                                    <p>Please fill the following optional details for your profile. The details you fill here are visible to professionals and organisations searching for professional to give tasks.
                                                                                        <br/>
                                                                                        <br/>
                                                                                        All fields below are optional</p>
                                                                                </div>
                                                                            </div>
                                                                            {/* No accordion message block - 04 end */}

                                                                            {/* No accordion block - 05 start */}
                                                                            <div className="kyc_accor_block kyc_non_accor_block">
                                                                                <div className="card-header kyc_accor_title pointer_none">
                                                                                    <a className="card-title">
                                                                                    About Your Practice
                                                                                    &nbsp;  <span class="comming_soon ">Coming soon</span>
                                                                                    </a>
                                                                                </div>
                                                                                <div className="card-body ">

                                                                                    <div className="s_midbox s_midbox_kyc disabled_section">
                                                                                        <div className="main_form_box">


                                                                                            <div className="form_block form_block_half">
                                                                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Practice size</b></label>
                                                                                                    <div className="form__group field ">
                                                                                                        <select className="selectpicker dropup form__field">
                                                                                                            <option>Select</option>
                                                                                                            <option>Select 01</option>
                                                                                                            <option>Select 02</option>
                                                                                                        </select>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Hourly rate</b>&nbsp;
                                                                                                        <span className="top_sug_left kyc_label_tooltip">
                                                                                                            <span className="input_tooltip btn_tooltip" aria-label="Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text" data-balloon-pos="down">
                                                                                                                <i className="fa fa-question-circle"></i>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div className="clearboth"></div>
                                                                                                    </label>
                                                                                                    <div className="form__group field mobileNo_iput">
                                                                                                        <div className="multi_input_block">
                                                                                                            <span className="multi_input_span">INR</span>
                                                                                                            <input className="multi_form__field" placeholder="" type="text"/>
                                                                                                        </div>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">

                                                                                                    <div className="form__group field form_fileUpload_group ">
                                                                                                        <div className="kyc_file_up">
                                                                                                            <div className="kyc_file_up_left">
                                                                                                                <p>Certificates or Courses</p>
                                                                                                            </div>
                                                                                                            <div className="kyc_file_up_right">
                                                                                                                <div className="kyc_file_up_btn">
                                                                                                                    <div className="upload-btn-wrapper">
                                                                                                                        <label className="new_upbutton" htmlFor="upload">
                                                                                                                            <button className="btn_appearance_none green_btn"><span>Upload</span></button>
                                                                                                                        </label>
                                                                                                                        <input type="file" name="myfile"/>
                                                                                                                        <div className="clearboth"></div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                        {/* <div className="kyc_upFile_listblock kyc_upFile_listblock_fullFileName">
                                                                                                            <div className="custom_fileup_name">
                                                                                                                <div className="upfile_name_box">
                                                                                                                    <span className="upfile_name">Sample-File-Name dfdfd</span>
                                                                                                                    <button className="upfile_name_cross btn_appearance_none">
                                                                                                                        <img src="assets/images/error.png" className="mCS_img_loaded"/>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                                <div className="upfile_name_box">
                                                                                                                    <span className="upfile_name">Sample-File-Name dfdf dfgdfdf</span>
                                                                                                                    <button className="upfile_name_cross btn_appearance_none">
                                                                                                                        <img src="assets/images/error.png" className="mCS_img_loaded"/>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Client testimonials (Upto 3)</b></label>
                                                                                                    <div className="form__group field ">
                                                                                                        <textarea placeholder="Enter description"></textarea>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <div className="form__group field form_fileUpload_group ">
                                                                                                        <div className="kyc_add_field_btn">
                                                                                                            <div className="kyc_file_up_full">
                                                                                                                <div className="kyc_file_addField_btn">
                                                                                                                    <button className="btn_appearance_none green_btn"><span>Add another</span></button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>

                                                                                            <div className="form_block form_block_full">
                                                                                                <div className="panel100 form__group_normar">
                                                                                                    <label htmlFor="name" className="form__label"><b>Previous employer / Peer testimonials</b></label>
                                                                                                    <div className="form__group field ">
                                                                                                        <textarea placeholder="Enter description"></textarea>
                                                                                                        {/* <span className="error_span">An error occured!</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="clearboth"></div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                            {/* No accordion block - 05 end */}

                                                                        </div>
                                                                    </div>
                                                                    {/* ====  kyc accordian scroll start ====  */}
                                                                {/* </Scrollbars>
                                                                </div> */}
                                                            </div>

                                                            {/* ==== KYC accordian block end ===  */}

                                                        </div>
                                                        {/* ====  User KYC end ===  */}
                                                    </div>
                                                </div>}

                                                {/* ===== ** LP User KYC page body end =====  */}

                                            </div>
                                            {/* ==============   User Setting Section end =================   */}
                                        </div>
                                        {/* -----  scroll adjust for settting screens end -----   */}
                                    </div>
                                    {/* ============== end =================   */}
                                    {/* === User Setting Section / Task Listing Section end === */}
                                </div>
                                {/* ===  task Listing start end === */}
                                {/* ===========  Setting Page main body end =============   */}
                            </div>
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                    {/* ===  DashBoard Main Body Footer Start ===  */}
                    {/* User Setting footer strat */}
                    {/* *****  Put as Dispaly: None ***** */}
                    {/* <div className="task_details_btn_area invoPay_footer kyc_footer">
                        <div className="btn_fld">
                            <div className="panel50">
                                <button className="btn_appearance_none green_btn"><span>Save as draft</span></button>
                            </div>
                            <div className="panel50">
                                <div className="cancel_continue_btn">
                                    <button type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                                    <button className="btn_appearance_none sub_grd_btn"><span>Submit</span></button>
                                </div>
                            </div>
                                <div className="clearboth"></div>
                        </div>
                    </div> */}
                    {/* *****  Put as Dispaly: None ***** */}
                    {/* User Setting footer end */}
                    {/* ===  DashBoard Main Body Footer end ===  */}
                </div>
                {/* Dashboard Main Screen content area 02 end */}
            </>
        )
    }
}

export default Profile
