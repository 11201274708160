import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import CommonContext from '../../context/CommonContext';
import  CustDate  from '../../../common_utilities/CustDate';


import { Button } from 'react-bootstrap';



const getBaseUrl = commonJsObj.getBaseUrl;
//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;

//window.JitsiMeetExternalAPI = window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;



export class JitsiRnd extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state ={
            "buttonVisibleFlag":true,
            "roomName": "avengers00716122020",
            "userFullName": "Captain America",
            "forTrackClose":0,
            "jitsiObj":null


        };
        this.scrollerRef = React.createRef();
    }
    componentDidMount() {
       this.isComponentLoaded =  true;
       

    }
    
    componentWillUnmount() {
        this.isComponentLoaded =  false;
    }

    onloadtrack = (obj) => {
        console.log(" *****  RELOADED********");
        let forTrackClose = 0; 
        if(this.state.forTrackClose==0){
            forTrackClose = 1;

        }
        else if(this.state.forTrackClose==1){
            forTrackClose = 2;
        }
        let buttonVisibleFlag = forTrackClose==1?false:true;
        this.isComponentLoaded && this.setState({"forTrackClose":(forTrackClose==2?0:forTrackClose),"buttonVisibleFlag":buttonVisibleFlag},()=>{
            //(this.state.jitsiObj) && forTrackClose==2 &&  (this.state.jitsiObj).dispose();

        });
    }

    readyToClosetrack = (evn) => {
        console.log(" *****  readyToClosetrack********");
        (this.state.jitsiObj) &&  (this.state.jitsiObj).dispose();
    }

    tiggerOpen = () =>{

        try{
           
       
        this.isComponentLoaded && this.setState({"buttonVisibleFlag":false},()=>{

            if(window.JitsiMeetExternalAPI!==undefined && window.JitsiMeetExternalAPI){
                const domain = 'meet.jit.si';
                //const domain = 'jitsi-stg.flywork.io';
                
       
                const options = {
                    roomName: 'avengers00716122021',
                    width: 700,
                    height: 700,
                    parentNode: document.querySelector('#jitsiparentdivid'),
                    userInfo: {
                        email: 'aaaa@aaaaaa.com',
                        displayName: 'Captain America'
                    },
                    onload: this.onloadtrack,
                    // suspendDetected: this.readyToClosetrack,
                    configOverwrite: { startWithAudioMuted: true, requireDisplayName: true, prejoinPageEnabled:true },
                    interfaceConfigOverwrite: { 
                        TOOLBAR_BUTTONS: [
                                            'microphone', 'camera',  'desktop', 'fullscreen',
                                            'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
                                            'raisehand',
                                            'videoquality', 'filmstrip', 'invite', 'feedback', 'stats',
                                            'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 
                                        ], 
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        DEFAULT_BACKGROUND: '#d93025',
                        
                    },
    
                    
                    
        
                };
                const refObj = this;
                this.state.jitsiObj = new window.JitsiMeetExternalAPI(domain, options);
                if( (this.state.jitsiObj)){
                    (this.state.jitsiObj).on('videoQualityChanged', () => {
                        console.log('******* videoQualityChanged');
                });
            
                (this.state.jitsiObj).on('readyToClose',this.readyToClosetrack);

                // set new password for channel
                (this.state.jitsiObj).addEventListener('participantRoleChanged', function(event) {
                    if (event.role === "moderator") {
                        (refObj.state.jitsiObj).executeCommand('password', '123456');
                    }
                });

                (this.state.jitsiObj).on('passwordRequired', function () {
                    (refObj.state.jitsiObj).executeCommand('password', "123456");
                });

                // (this.state.jitsiObj).executeCommand('avatarUrl', 'https://avatars0.githubusercontent.com/u/3671647');

                
    
    
                }
          
            }
            
        });


        }
        catch(e){
            console.log("++++++ error name=>>>", e.name,'=======An error has occurred: '+e.message)
        }
        

    }



    render() {
        
        return (
            <>              
             {(this.state.buttonVisibleFlag) && (
             <button name="startMeet" onClick={(evn)=>{
                 evn.preventDefault();
                 this.tiggerOpen();
             }} >Start Meet</button>
             )}

            { (
                    <div id="jitsiparentdivid"></div>
            )}
             
            {/* <div>
             <iframe allow="camera; microphone; fullscreen; display-capture" src="https://meet.jit.si/avengers00716122021" style={{height: '700px', width: '700px', border: 0}} />
             </div> */}
           


            </>
        )
    }
}

export default JitsiRnd
