/**  
 * MonitorFreeFlowFileUploadService
 * Page/Class name   : MonitorFreeFlowFileUploadService
 * Author            :
 * Created Date      : 15-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class MonitorFreeFlowFileUploadService extends Component {

    
    /**  
     * MonitorFreeFlowFileUploadService
     * Function name : constructor
     * Author        :
     * Created Date  : 15-5-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/files/file';
    }

    /**  
     * MonitorFreeFlowFileUploadService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 15-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * MonitorFreeFlowFileUploadService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 15-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * MonitorFreeFlowFileUploadService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 15-5-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async uploadFiletoServer(paramObj){
      this.axiosObj = axiosCustomObj({});
    
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;  
            
            let dataObj = paramObj.data;
           // let taskOrSubTask = paramObj.taskOrSubTask;
            let taskId = paramObj.taskId;
            
            // if(taskOrSubTask=="STASK"){

            //   urldata = urldata+ "?file_upload_type=4&upload_type_id="+taskIdOrSubTaskId;

            // }else if(taskOrSubTask=="SWSTASK"){
            //   urldata = urldata+ "?file_upload_type=4&upload_type_id="+taskIdOrSubTaskId;

            // }
            urldata = urldata+ "?file_upload_type=4&upload_type_id="+taskId;

            // let headerOptn = {  
            //   "Content-Type": "application/json",
            //   "Authorization": accToken
            // };          

            let headerOptn = {  
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": accToken
            };   
            var formData = new FormData();           
            Object.keys(dataObj).map(function(key, index) {              
              formData.append("files", dataObj[key]);
            });
            //formData.append("files", paramObj[0]);           
            let respObj = this.axiosObj.post(urldata, formData, {
              headers: headerOptn
          });

          return respObj;
    }
    catch(err){
       // console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default MonitorFreeFlowFileUploadService;