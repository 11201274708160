import React, { Component } from "react";
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";
import CustReactBootstrapModal from "./CustReactBootstrapModal";
// for context
import CommonContext from "../../context/CommonContext";

//services
import TaskInvoiceHistoryService from "../../service/TaskInvoiceHistoryService";
import GenerateInvoiceService from "../../service/GenerateInvoiceService";
import RequestPaymentService from "../../service/RequestPaymentService";
import RequestTdsCertificateService from "../../service/RequestTdsCertificateService";

// common noti track
//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

import TaskInvoiceShare from "./TaskInvoiceShare";
// Newest1 - Start
import { Scrollbars } from "react-custom-scrollbars";
// Newest1 - End
// For loading CustLoader
import CustLoader from "./CustLoader";
import CustDate from "../../../common_utilities/CustDate";

const getBaseUrl = commonJsObj.getBaseUrl;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getCurrentUserId = commonJsObj.getCurrentUserId;

export class SplitScreenTaskInvoiceHistory extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;
  constructor(props) {
    super(props);
    this.children = props.children;
    this.state = {
      loaderFlag: true,
      taskId: "",
      invoiceInfo: [],
      isPaid: false,
      showGstModal: false,
      taskOrSubTask: "",
      taskIdOrSubTaskId: 0,
      lpUserId: 0,
      ccUserId: 0,
      isTaskCompleted: false,
    };
    this.scrollerRef = React.createRef();
    this.modalforGstRef = React.createRef();
  }
  componentDidMount() {
    this.isComponentLoaded = true;
    let taskId = 0;
    let taskOrSubTask = "";
    let taskIdOrSubTaskId = 0;
    let isTaskCompleted = false;
    let lpUserId = 0;
    let ccUserId = 0;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        taskId = sharedData.taskId;

        taskOrSubTask =
          sharedData.other.subTasksCount !== undefined &&
          sharedData.other.subTasksCount !== null &&
          sharedData.other.subTasksCount > 0
            ? "SWSTASK"
            : "STASK";

        if (taskOrSubTask == "STASK") {
          if (
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            let taskResp = sharedData.other.taskResp;

            taskIdOrSubTaskId =
              taskResp.id !== undefined && taskResp.id !== null
                ? taskResp.id
                : 0;

            isTaskCompleted =
              taskResp.isTaskCompleted !== undefined &&
              taskResp.isTaskCompleted !== null
                ? taskResp.isTaskCompleted
                : false;

            ccUserId =
              taskResp.owner !== undefined &&
              taskResp.owner !== null &&
              taskResp.owner.userId !== undefined &&
              taskResp.owner.userId !== null
                ? taskResp.owner.userId
                : 0;
          }
        } else if (taskOrSubTask == "SWSTASK") {
          // this scenario i.e task with sub task is not in sprint 5
        }

        lpUserId = getCurrentUserId();

        this.isComponentLoaded &&
          this.setState(
            {
              taskId: taskId,
              taskIdOrSubTaskId: taskIdOrSubTaskId,
              taskOrSubTask: taskOrSubTask,
              lpUserId: lpUserId,
              ccUserId: ccUserId,
              isTaskCompleted: isTaskCompleted,
            },
            () => {
              this.callServiceToFetchTaskInvoiceHistory(
                { taskId: this.state.taskId },
                "INVOICEVIEWCOMPONENTLOADED"
              );
            }
          );
      }
    }
  }
  callServiceToFetchTaskInvoiceHistory = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = 1;
    paramObjData.taskId = paramObj.taskId;
    //console.log(" trackcalledfrom=>",trackcalledfrom," paramObjData=>",paramObjData);

    let stcservice = new TaskInvoiceHistoryService();
    stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            //console.log(respData,"respData");
            if (respData.invoices !== undefined && respData.invoices !== null) {
              if (respData.invoices.length > 0) {
                let invoiceInfo = respData.invoices;
                let downloadUrl = null;
                let isPaid = false;

                if (
                  invoiceInfo &&
                  invoiceInfo[0].isPaid !== undefined &&
                  invoiceInfo[0].isPaid !== null
                ) {
                  isPaid = invoiceInfo[0].isPaid;
                }

                this.isComponentLoaded &&
                  this.setState(
                    {
                      invoiceInfo: invoiceInfo,
                      downloadUrl: downloadUrl,
                      isPaid: isPaid,
                    },
                    () => {
                      //console.log(" state invoiceInfo=>",this.state);
                    }
                  );
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }
  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }
  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }
  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //     let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //     if(setShare){
    //         let paramsCntxt = {
    //         "taskId":"",
    //         "actionType":"",
    //         "leftTabToActive":"",
    //         "purpose":"",
    //         "other":{}
    //         }
    //         setShare(paramsCntxt);
    //     }
    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "TASK_INVOICE_HISTORY_SCREEN",
    });
  };
  gstModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showGstModal: true }, () => {
        this.modalforGstRef.current.showModal();
        this.getGstModalContent();
      });
  };
  gstModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showGstModal: false }, () => {
        this.modalforGstRef.current.hideModal();
      });
  };
  generateInvoice = (paramObjData) => {
    let service = new GenerateInvoiceService();
    service
      .generateInvoice({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let invoiceInfo = [];
          invoiceInfo.push(data.data.entity);
          this.setState({ invoiceInfo: invoiceInfo }, () => {
            // console.log(this.state,"after create invoice")
          });
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500, 401]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,

              dismiss: {
                duration: 2500,
              },
            });
          }
        }
      })
      .finally(() => {
        this.gstModalClose();
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        let sharedData =
          this.context !== undefined && this.context.share !== undefined
            ? this.context.share
            : null;
        sharedData.leftTabToActive = "INVOICE";
        sharedData.purpose = "VIEW_INVOICE";
        sharedData.other.triggeredFrom = "TASK_INVOICE_HISTORY";
        setShare(sharedData);
      });
  };

  triggerRequestForPayment = (paramObjData) => {
    let paramObj = { data: paramObjData };

    let stcservice = new RequestPaymentService();
    stcservice
      .sendReqForPayment({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          notify("Your request has been sent", true, {
            successMsg: 1,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            msgKeyData: "invhistmsgkey001",
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,
              msgKeyData: "invhistmsgkey001",
              dismiss: {
                duration: 2000,
              },
            });
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  triggerRequestForTdsCertificate = (paramObjData) => {
    let paramObj = { data: paramObjData };

    let stcservice = new RequestTdsCertificateService();
    stcservice
      .sendReqForTdsCertificate({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          notify("Your request has been sent", true, {
            successMsg: 1,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            msgKeyData: "invhistmsgkey001",
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,
              msgKeyData: "invhistmsgkey001",
              dismiss: {
                duration: 2000,
              },
            });
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  getGstModalContent() {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">Reverse GST</h4>
          <button
            type="button"
            className="close modal_close"
            data-dismiss="modal"
            onClick={() => {
              this.gstModalClose();
            }}
          >
            ×
          </button>
        </div>
        <div style={{ display: "none" }} className="alert_secction  ">
          <div className="alert alert-dismissible alert_custom alert-success">
            <p></p>
          </div>
        </div>
        <div className="modal-body padding_top">
          <div className="popUP_text_p">
            <p>Will the reverse GST be applicable for this on Invoice?</p>
            <p className="popUp_yellow_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              let paramObj = {
                taskId: this.state.taskId,
                is_reverse_gst_applicable: true,
              };
              this.generateInvoice(paramObj);
            }}
          >
            <span>Yes</span>
          </button>
          <button
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              let paramObj = {
                taskId: this.state.taskId,
                is_reverse_gst_applicable: false,
              };
              this.generateInvoice(paramObj);
            }}
          >
            <span>No</span>
          </button>
        </div>
      </div>
    );

    this.modalforGstRef.current.updateStateContent(viewData);
    this.modalforGstRef.current.showContent();
    return viewData;
  }
  loadTheContent = () => {
    const { pageNumber, numPages, pdfLoadErr } = this.state;
    let downloadUrl = this.state.downloadUrl;
    let forSplitScrnCls2Inject = "";
    let showBackButton = false;
    //********* for context starts *****
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
      //console.log("Checking sharedData=>>",sharedData);
      showBackButton =
        sharedData.other !== null &&
        sharedData.other.invoiceViewedPaidStatus !== undefined &&
        sharedData.other.invoiceViewedPaidStatus !== null &&
        sharedData.other.invoiceViewedPaidStatus == false
          ? true
          : false;
    }
    //********* for context ends *****
    let listObjAr = [];

    //console.log(" sharedData=>>",sharedData);

    return (
      <>
        {/**** Dashboard Slide Split Right - screen star  ***/}
        <div
          className={`splitscreen_area ${
            forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""
          }`}
        >
          {/* Newest1 - Start */}
          {/* <div className="slideScreen_bg slideScreen_Chatbg"> */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* right slide section top start */}
              <div className="SlideS_topbg flexbox-item slide_chat_topbg splitInvoiceTop">
                <div className="slide_top_titlebg">
                  <div className="slideChat_head">
                    <div className="slideChat_panel">
                      {showBackButton == true && (
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();
                            if (setShare && sharedData) {
                              sharedData.leftTabToActive = "INVOICE";
                              sharedData.purpose = "VIEW_INVOICE";
                              sharedData.other.triggeredFrom =
                                "TASK_INVOICE_HISTORY";
                              setShare(sharedData);
                            }
                          }}
                          className="btn_appearance_none chatHeadBack"
                        >
                          <span>Back</span>
                        </button>
                      )}
                    </div>
                    <div className="slideChat_panel">
                      <h5>Invoice history</h5>
                    </div>

                    <div className="clearboth" />
                  </div>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "TASK_INVOICE_HISTORY_SCREEN",
                      });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src={getBaseUrl() + "assets/images/red_cross.png"} />
                  </button>
                </div>
              </div>

              {/* right slide section top end */}

              {/* right slide section Middlie  start */}
              {this.state.invoiceInfo.length == 0 && (
                <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice Pdf__invoice_list_bg">
                  <div className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView">
                    <div className="s_midbox_section s_midbox_section_inv">
                      {/*   sprint - 6 invoice main structure start =*/}
                      {/* invoice import body start  */}
                      <div className="invoice_importbg">
                        <div className="invo_listBox_bg invo_listBox_bg_nodata">
                          <div className="invo_list_whiteBox invo_list_grayBox">
                            {/*=   invoice list No Data start =*/}
                            <div className=" text-center ">
                              {this.state.loaderFlag ? this.loadLoader() : null}
                            </div>
                            <div className="invoice_no_data_bg">
                              <h3>
                                Click on “Generate Invoice” button below to
                                generate an invoice.
                              </h3>
                            </div>

                            {/*=   invoice list No Data  end =*/}
                          </div>
                        </div>
                      </div>
                      {/* invoice import body end  */}
                      {/*=   sprint - 6 invoice main structure end =*/}
                    </div>
                  </div>
                </div>
              )}

              {this.state.invoiceInfo.length > 0 && (
                <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice Pdf__invoice_list_bg">
                  <div
                    ref={this.scrollerRef}
                    className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView"
                  >
                    {/* <Scrollbars
                      ref={this.scrollerRef}
                      style={{ height: 400 }}
                      autoHeightMin={400}
                      renderView={(props) => (
                        <div
                          {...props}
                          className="view invoiceviewscreenscrollclass"
                        />
                      )}
                      onScrollStop={this.trackScroll}
                    > */}
                    <div className="s_midbox_section s_midbox_section_inv">
                      {/* ====   sprint - 6 invoice main structure start ====  */}
                      {/* invoice import body start  */}
                      <div className="invoice_importbg">
                        <CustomMessageBar keydata="invhistmsgkey001" />
                        {/* invoice top filter start */}
                        <div className="invoice_top_filter">
                          <div className="row">
                            <div className="col-md-6 col-sm-12 col-12">
                              {/* <div className="custom_select_bg">
                                                <select className="customSelect form__field">
                                                    <option>Unpaid invoices</option>
                                                    <option>Paid invoices</option>

                                                </select>
                                                </div> */}
                            </div>
                          </div>
                        </div>
                        {/* invoice top filter end */}
                        {/* invoice listing start */}
                        <div className="invo_listBox_bg">
                          <div className="invo_list_whiteBox">
                            {/* ====   invoice list Table structure start ====  */}
                            <div className="invo_list_table_bg invo_list_table_bg_lp">
                              <div className="table-responsive">
                                {/* ====   Table start  ====   */}
                                <table
                                  id="example"
                                  className="table table-bordered"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>Name</label>
                                      </th>
                                      <th>
                                        <label>Invoice no.</label>
                                      </th>
                                      <th>
                                        <label>Payment date</label>
                                      </th>
                                      <th>
                                        <label>Status</label>
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.invoiceInfo.length > 0 &&
                                      Object.keys(this.state.invoiceInfo).map(
                                        (eob, i) => {
                                          let lpUserName = "";
                                          let lpFName = "";
                                          let lpLName = "";
                                          if (
                                            this.state.invoiceInfo[i]
                                              .invoiceGenerator !== undefined
                                          ) {
                                            lpFName =
                                              this.state.invoiceInfo[i]
                                                .invoiceGenerator.firstName !==
                                                undefined &&
                                              this.state.invoiceInfo[i]
                                                .invoiceGenerator.firstName !==
                                                null
                                                ? this.state.invoiceInfo[i]
                                                    .invoiceGenerator.firstName
                                                : "";

                                            lpLName =
                                              this.state.invoiceInfo[i]
                                                .invoiceGenerator.lastName !==
                                                undefined &&
                                              this.state.invoiceInfo[i]
                                                .invoiceGenerator.lastName !==
                                                null
                                                ? this.state.invoiceInfo[i]
                                                    .invoiceGenerator.lastName
                                                : "";
                                          }

                                          lpUserName = lpFName + " " + lpLName;

                                          // previous logic
                                          // let paidAt = this.state.invoiceInfo[i].dueDate?CustDate.custFormatDate((new Date(this.state.invoiceInfo[i].dueDate)),5):"---";

                                          let paidAt = this.state.invoiceInfo[i]
                                            .paidAt
                                            ? CustDate.custFormatDate(
                                                new Date(
                                                  this.state.invoiceInfo[
                                                    i
                                                  ].paidAt
                                                ),
                                                5
                                              )
                                            : "Due on receipt";

                                          let invoiceInfo =
                                            this.state.invoiceInfo[i];

                                          let downloadUrl = null;
                                          let invoiceId = 0;
                                          if (invoiceInfo) {
                                            if (
                                              invoiceInfo.downloadUrl !==
                                                undefined &&
                                              invoiceInfo.downloadUrl !== null
                                            ) {
                                              downloadUrl =
                                                invoiceInfo.downloadUrl +
                                                "&auth=" +
                                                getTokenFromLocStorage();
                                            }
                                            if (
                                              invoiceInfo.invoiceId !==
                                                undefined &&
                                              invoiceInfo.invoiceId !== null
                                            ) {
                                              invoiceId = invoiceInfo.invoiceId;
                                            }
                                          }

                                          listObjAr.push(
                                            <tr key={i + 1}>
                                              <td>
                                                <p>{lpUserName}</p>
                                              </td>
                                              <td>
                                                <p>
                                                  {this.state.invoiceInfo[i]
                                                    .invoiceNumber !== undefined
                                                    ? this.state.invoiceInfo[i]
                                                        .invoiceNumber
                                                    : ""}
                                                </p>
                                              </td>
                                              <td>
                                                <p>{paidAt}</p>
                                              </td>
                                              <td>
                                                <p>
                                                  {this.state.invoiceInfo[i]
                                                    .isPaid == false
                                                    ? "Due"
                                                    : "Paid"}
                                                </p>
                                              </td>
                                              <td>
                                                {/* <p className="invo_part_btn">
                                                            <button onClick={(evn)=>{
                                                                evn.preventDefault();
                                                                if(setShare && sharedData){
                                                                sharedData.leftTabToActive = "INVOICE";
                                                                sharedData.purpose = "VIEW_INVOICE";
                                                                sharedData.other.triggeredFrom = "TASK_INVOICE_HISTORY";
                                                                setShare(sharedData);
                                                                
                                                                }
                                                            }} className="btn_appearance_none greenText_btn">
                                                            <span>View</span>
                                                            </button>
                                                            
                                                        </p> */}
                                                <div class="moreAction_tableSec">
                                                  <button
                                                    class="btn_appearance_none moreAction_dropBtn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    More actions
                                                  </button>
                                                  <div class="dropdown-menu dropdown_menu_tableTds">
                                                    <ul>
                                                      <li>
                                                        <a
                                                          href=""
                                                          onClick={(evn) => {
                                                            evn.preventDefault();
                                                            if (downloadUrl) {
                                                              let winDwnObj =
                                                                window.open(
                                                                  downloadUrl,
                                                                  "_blank"
                                                                );
                                                              winDwnObj.focus();
                                                              //window.location.href = downloadUrl;
                                                            }
                                                          }}
                                                          className="dropdown-item"
                                                        >
                                                          Download invoice
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <TaskInvoiceShare
                                                          custkeyid={i}
                                                          custclassname="dropdown-item "
                                                          datas={{
                                                            invoiceInfo:
                                                              invoiceInfo,
                                                            taskOrSubTask:
                                                              this.state
                                                                .taskOrSubTask,
                                                            taskIdOrSubTaskId:
                                                              this.state
                                                                .taskIdOrSubTaskId,
                                                            lpUserId:
                                                              this.state
                                                                .lpUserId,
                                                            ccUserId:
                                                              this.state
                                                                .ccUserId,
                                                          }}
                                                          buttontextname={
                                                            "Share invoice"
                                                          }
                                                          dispelementype={
                                                            "anchor"
                                                          }
                                                        />
                                                        {/* <a href="" onClick={(evn)=>{evn.preventDefault();}} className="dropdown-item">Share invoice</a> */}
                                                      </li>
                                                      <li>
                                                        <a
                                                          href=""
                                                          onClick={(evn) => {
                                                            evn.preventDefault();
                                                            this.triggerRequestForPayment(
                                                              {
                                                                invoiceId:
                                                                  invoiceId,
                                                              }
                                                            );
                                                          }}
                                                          className="dropdown-item"
                                                        >
                                                          Request payment
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          href=""
                                                          onClick={(evn) => {
                                                            evn.preventDefault();
                                                            this.triggerRequestForTdsCertificate(
                                                              {
                                                                invoiceId:
                                                                  invoiceId,
                                                              }
                                                            );
                                                          }}
                                                          className="dropdown-item"
                                                        >
                                                          Request TDS
                                                          certificate
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                  <div class="clearboth"></div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    {this.state.invoiceInfo.length > 0 && (
                                      <>{listObjAr}</>
                                    )}
                                  </tbody>
                                  <tfoot>
                                    {this.state.loaderFlag == false &&
                                      this.state.invoiceInfo.length == 0 && (
                                        <tr key={"001"}>
                                          <td colSpan={5}>
                                            {this.state.invoiceInfo.length ==
                                              0 && (
                                              <div className="table_foot_nodata">
                                                <p> No Data Found </p>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      )}

                                    <tr key={"002"}>
                                      <td colSpan={5}>
                                        <div className="table_foot_loading">
                                          {this.state.loaderFlag
                                            ? this.loadLoader()
                                            : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                                {/* ====   Table end  ====   */}
                              </div>
                            </div>
                            {/* ====   invoice list Table structure end ====  */}
                          </div>
                        </div>
                        {/* invoice listing end */}
                      </div>
                      {/* invoice import body end  */}

                      {/* ====   sprint - 6 invoice main structure end ====  */}
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                </div>
              )}
              {/* right slide section Middlie  end */}

              {/* right slide section Footer  start */}
              {this.state.invoiceInfo.length == 0 && (
                <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer">
                  <div className="chat_bottom_left inv_bottom_left">
                    <button
                      className="btn_appearance_none sub_grd_btn"
                      onClick={(evnt) => {
                        evnt.preventDefault();
                        this.gstModalOpen();
                      }}
                    >
                      <span>Generate Invoice</span>
                    </button>
                  </div>
                </div>
              )}

              {this.state.invoiceInfo.length > 0 && (
                <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer"></div>
              )}
              {/* right slide section Footer  end */}
            </div>
          </div>
          {/* Newest1 - End */}
        </div>
        {/* ==== ********* Dashboard Slide Split Right - screen end ==== ********* */}
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.loadTheContent()}
        <CustReactBootstrapModal
          ref={this.modalforGstRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showGstModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg  "
          loaderbodytitle="Organisation"
        />
      </React.Fragment>
    );
  }
}

export default SplitScreenTaskInvoiceHistory;
