/**
 * Header
 * Page/className name   : Header
 * Author            :
 * Created Date      : 6-2-2020
 * Functionality     : Header
 * Purpose           : load Header component
**/
import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';

import customimages from '../../custom_resources/images';
import '../../custom_resources/css/custom.css';

import {LoadGoogleMap} from '../customisedthirdparty/LoadGoogleMap';



import FeedbackCommon from '../../components/content/after_login/FeedbackCommon.js';
import LogoutCommon from '../../components/content/after_login/LogoutCommon.js';
import RequestDemoCommon from '../../components/content/after_login/RequestDemoCommon.js';
import BellNotificationList from '../../components/content/after_login/BellNotificationList.js';

//import ee from 'event-emitter';

//Newly added
// common noti track
import NotiEmitTrack from "../../components/content/after_login/NotiEmitTrack";
import {commonnotitrack} from "../../components/content/after_login/NotiEmitTrack";

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;
const getUserEmail = commonJsObj.getUserEmail;
const getUserName = commonJsObj.getUserName;
//const getOrgName = commonJsObj.getOrgName;
// const getKycVerified = commonJsObj.getKycVerified;
 const getUserLawFirmName = commonJsObj.getUserLawFirmName;
const getProfilePicture =commonJsObj.getProfilePicture;
const getBaseUrl = commonJsObj.getBaseUrl;
const getSubscriptionActiveStatus = commonJsObj.getSubscriptionActiveStatus;
const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;



// const emitter = new ee();

// export const notifyheaderpopup = (optionName,otherParam) => {
//     emitter.emit('customnotiforheaderpopup', optionName, otherParam);
// }




class Header extends Component {

    isComponentLoaded = false;
    manageDropDownLinks = ["invoice-generator", "invoice-preview"];

    /**
        * Header
        * Function name : constructor
        * Author        :
        * Created Date  : 12-2-2020
        * Purpose       : initializing state
        * Params        : props
       **/
    constructor(props) {
        super(props);
        this.state = {
            u_email: '',
            // u_name: '',
            // org_name:'',
            url:'',
            headerBar:1, //0/1/2 0=> no link on header
            defaultHeader:true,
            profileImage:'',
            isSubscriptionActive:getSubscriptionActiveStatus(),
            isKycVerified:getKycVerifiedStatus(),
            activeLinkName:''


          }
          //console.log(getKycVerified())
          if(getKycVerifiedStatus()){
                this.state.profileImage =getProfilePicture();
          } else {
              this.state.profileImage = getBaseUrl() + 'assets/images/user-avatar-thumb.png'
          }

          this.refparentheaderdiv =  React.createRef();
    }

    /**
     * Header
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentDidMount(){
        
        LoadGoogleMap.loadGoogleMapLibrary();
        this.isComponentLoaded = true;
        this.isComponentLoaded && this.setState({'u_email':getUserEmail()});
        // this.isComponentLoaded && this.setState({'u_name':getUserName()});
        // this.isComponentLoaded && this.setState({'org_name':getOrgName()}
        // ,()=>{

        // });

        
        if(this.props.location.pathname.slice(1)==="kyc" || this.props.location.pathname.slice(1)==="profile" || this.props.location.pathname.slice(1)==="subscription"){
            this.isComponentLoaded &&  this.setState({headerBar:2,url:this.props.location.pathname.slice(1)})
        }
        if(this.props.location.pathname.slice(1)==="subscription-payment"){
            //this.setState({defaultHeader:false})
            //this.isComponentLoaded && this.setState({headerBar:0});

        }

         if(this.props.location.pathname.slice(1)==="get-task"){
            
            this.isComponentLoaded && this.setState({"activeLinkName":"get-task"});

         }
         else  if(this.manageDropDownLinks.indexOf(this.props.location.pathname.slice(1)!=-1)){
            this.isComponentLoaded && this.setState({"activeLinkName":"manageinv"});
         }

        //console.log(this.props.location.pathname.slice(1)," headerBar=>>", this.state.headerBar);

    }

    /**
     * Header
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentWillUnmount(){
        this.isComponentLoaded = false;
     }

    /**
         * Header
         * Function name : callSignOut
         * Author        :
         * Created Date  : 12-2-2020
         * Purpose       : simple log out functionality
         * Params        : 
        **/

    callSignOut = () => {
        removeTokenFromLocStorage();      
    }


    closeSplit = (objParam) =>{
        commonnotitrack("CLOSE_SPLITSCREEN",{
            "notiType": "CLOSE_SPLITSCREEN",
            "triggerCallback":false,
            "trigerredFrom":"AFTER_LOGIN_HEADER_MENU"
            });
    }

    trackNotiForCloseSplit = (otherParam) =>{
        let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
        if(notiType=="SCROLL_TO_MAIN_HEADER"){
        
            if(this.refparentheaderdiv!==null && this.refparentheaderdiv.current!==undefined && this.refparentheaderdiv.current!==null){
              window.scrollTo(0,  this.refparentheaderdiv.current.offsetTop);
            }
            
             
          }
    }

    
    


    /**
     * Header
     * Function name : render
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    render() {

        

      // let locPath  = this.props.location.pathname;
      // let hideDefaultHeader  = false;
      // if(/task-add/.test(locPath)){
      //   hideDefaultHeader = true;
      // }

      // if (hideDefaultHeader){
      //   return (<></>)
      // }else{
        if(this.state.defaultHeader){
            return (
                <>
                    <NotiEmitTrack callbackofbellnotimsgclick={this.trackNotiForCloseSplit} /> 
                    {/* header and nav area start */}
                    <div ref={this.refparentheaderdiv} className="header_nav_area header_nav_area_lp" className={`header_nav_area header_nav_area_lp ${(this.state.headerBar==0)?"header_nav_area_newadjust":""}`}>
                        {/* logo and nav area start */}
                            <div className="logo_nav_area">
                                <div className="panel30 logo_panel">
                                    <div>
                                        {/* <NavLink to="/get-task"><img src={getBaseUrl()+"assets/images/flywork-for-professionals-logo.svg"} alt=""/>
                                        </NavLink> */}
                                        <a onClick={(evn)=>{ evn.preventDefault();
                                        this.closeSplit({"trigerredFrom":"AFTER_LOGIN_HEADER_LOGO"});
                                        this.props.history.push("/get-task");
                                        }} href="">
                    <img src={getBaseUrl()+"assets/images/flywork-for-professionals-logo.svg"}  />
                  </a>
                                    </div>
                                </div>
                                <div className="panel70 head_menu_panel">
                                    <div className="nav_block">
                                        <ul>
                                            <li> <NavLink to="/get-task"><span className="left_mainHeader_navImg"><img src={getBaseUrl()+"assets/images/home.png"} alt=""/></span> <div className="nav_txt">Home</div></NavLink></li>
                                            <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="pointer_none"><span className="left_mainHeader_navImg "><img src={getBaseUrl()+"assets/images/knowledge-centre.png"} alt=""/></span> <div className="nav_txt">Knowledge Centre</div> <span className="comming_soon">Coming soon</span></a></li>
                                            <li><a className="pointer_none" href="" onClick={(evn)=>{ evn.preventDefault();}}><span className="left_mainHeader_navImg"><img src={getBaseUrl()+"assets/images/getting-started.png"} alt=""/></span> <div className="nav_txt">Getting Started</div>
                                            <span class="comming_soon">Coming soon</span>
                                            </a>
                                            
                                            </li>
        
                                            <li className="bell_li">
                                                    <BellNotificationList />
                                            </li>
                                            <li className="user_Name_li">
                                                <a href="" onClick={(evn)=>{ evn.preventDefault();}}>
                                                    <div className="profile_name">
                                                            <div className="name">{getUserName()} </div>
                                                            <div className="com_name">{ this.state.isKycVerified? (getUserLawFirmName()=="null"?'':getUserLawFirmName()):''}</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="user_li">
                                                    <div className="profile_img">
                                                        {/* <div><img src={getBaseUrl()+"assets/images/pic.png"} alt=""/> <i className="fa fa-caret-down" aria-hidden="true"></i></div> */}
                                                        <button className="btn btn-secondary dropdown-toggle btn_appearance_none user_drop_btn" type="button" data-toggle="dropdown">

                                                                <img src={ this.state.profileImage} alt=""/>
                                                        </button>
                                                            <div className="dropdown-menu dropdown-menu-right user_drop_menu">
                                                                <ul>
                                                                    <li>
                                                                        {/* <NavLink to="/kyc"  className={`user_drop_a ${(this.url==='profile' || this.url==='kyc')? 'active': ''}`} >
                                                                            Profile settings
                                                                        </NavLink> */}
                                                                        <a href="" className={`user_drop_a ${(this.url==='profile' || this.url==='kyc')? 'active': ''}`} onClick={(evn)=>{ evn.preventDefault();
                                                                        this.closeSplit({"trigerredFrom":"AFTER_LOGIN_HEADER_MENU"});
                                                                        
                                                                            this.props.history.push("/kyc");
                                                                            }} className="user_drop_a active">
                                                                            Profile settings
                                                                            </a>
                                                                        {/* <a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a active">Profile settings</a> */}
                                                                    </li>
                                                                    <li>
                                                                        {/* <NavLink to="/subscription"  className={`user_drop_a ${(this.url==='subscription')? 'active': ''}`}>Application settings</NavLink> */}
                                                                        
                                                                        <a href="" className={`user_drop_a ${(this.url==='subscription')? 'active': ''}`} onClick={(evn)=>{ evn.preventDefault();
                                                                        this.closeSplit({"trigerredFrom":"AFTER_LOGIN_HEADER_MENU"});
                                                                        
                                                                            this.props.history.push("/subscription");
                                                                            }} className="user_drop_a active">
                                                                            Application settings
                                                                            </a>
                                                                        
                                                                    </li>
                                                                    {/* <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a">Virtual bank account</a></li> */}
                                                                    
                                                                    
                                                                    <li>
                                                                        <FeedbackCommon custclassname="user_drop_a" textname="Feedback" />
                                                                        {/* <a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a">Feedback</a> */}
                                                                    </li>
                                                                    <li>
                                                                        <RequestDemoCommon custclassname="user_drop_a" textname="Request a demo" />
                                                                    </li>
                                                                    <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a pointer_none">Policies
                                                                    <span className="comming_soon">Coming soon</span>
                                                                    </a></li>
                                                                    <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a pointer_none">Help & Support
                                                                    <span className="comming_soon">Coming soon</span>
                                                                    </a></li>
                                                                    <li>
                                                                        {/* <a href="" onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a">Logout</a> */}
                                                                        <LogoutCommon custclassname="user_drop_a" textname="Logout" />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                    </div>
                                            </li>
                                        </ul>
                        
                                    </div>
                                </div>
                                <div className="clearboth"></div>
                        </div>
                        {/* logo and nav area end */}
      
                        {/* tab nav area start */}
                        <div className="tabs_block tabs_block_lp">
                              <ul className="nav">
                                      {this.state.headerBar==1 &&
                                      <>
                                          <li>
                                                      <a href="" onClick={(evn)=>{ evn.preventDefault();
                                                        this.props.history.push("/get-task");
                                                    }} className={` ${this.state.activeLinkName=="get-task"?"active":""} `}>Grow</a>
                                         </li>
                                          <li>
                                              <a data-toggle="tab" href="" onClick={(evn)=>{ evn.preventDefault();}} className="pointer_none">Collaborate <span className="comming_soon">Coming soon</span></a>
                                          </li>
                                          <li>
                                              <a href="" onClick={(evn)=>{ evn.preventDefault();}} className={`dropdown-toggle btn_appearance_none ${!(this.state.isSubscriptionActive) || !(this.state.isKycVerified)?"pointer_none":""} ${(this.state.activeLinkName=="manageinv"?"active":"") }`} data-toggle="dropdown" >Manage 
                                              <span className="comming_soon">Beta</span>
                                              </a>
                                              <div className="dropdown-menu dropdown-menu-right tab_drop_menu_lp ">
                                                  <ul>
                                                  <li>
                                                      <a href="" onClick={(evn)=>{ evn.preventDefault();
                                                        this.props.history.push("/invoice-generator");
                                                    }} className="active">Invoice Generator</a>
                                                  </li>
                                                  <li>
                                                      <a href="" onClick={(evn)=>{ evn.preventDefault();
                                                        this.props.history.push("/invoice-history");
                                                    }} className="active">Invoice Dashboard</a>
                                                  </li>
                                                  {/* <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="">Manage Practice 2</a></li>
                                                  <li><a href="" onClick={(evn)=>{ evn.preventDefault();}}className="">Manage Practice 3</a></li>
                                                  <li><a href="" onClick={(evn)=>{ evn.preventDefault();}} className="">Manage Practice 4</a></li> */}
                                                  </ul>
                                              </div>
                                          </li>
                                      </>}
                                      {this.state.headerBar==2 && <>
                                          <li><NavLink to="/kyc"  className={`${(this.state.url==='profile' || this.state.url==='kyc')? 'active': ''}`}>Profile Settings</NavLink></li>
                                          <li><NavLink to="/subscription"   className={`${(this.state.url==='subscription')? 'active': ''}`}>Application Settings</NavLink></li>
                                          {/* <li><a data-toggle="tab" href="" className="">Virtual Bank Account</a></li> */}
                                      </>}
                              </ul>
                        </div>
                        {/* tab nav area end */}
                    </div>
                    {/* header and nav area start */}
      
                </>
            )
        } else {
            return (
                <></>
            )
        }


    //  }


        
    }

}

export default withRouter(Header);