// Newest1 - Start

import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomMessageBar from "../messagebar/CustomMessageBar";

export default function DateRangeTodayOnwardsMod(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedAtleastOnce, setSelectedAtleastOnce] = useState(false);
  const [isStartDate, setIsStartDate] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);
  const [isLastTimeSlotCrossed, setIsLastTimeSlotCrossed] = useState(false);
  const [defEndDate, setDefEndDate] = useState(null);
  const [runILTSCUEAgainSwitch, setRunILTSCUEAgainSwitch] = useState(false);

  const sDateErrMsg = "Start date is required.";
  const eDateErrMsg = "End date is required.";

  const lastTimeSlotCrossedErrMsg = (
    <React.Fragment>
      <br />
      You have missed the last time slot of the day (10:30 PM - 11:30 PM).
      <br />
      Please wait until tomorrow (12:00 AM onwards).
      <br />
      Or else, select a time slot on any day in the future.
    </React.Fragment>
  );

  var moment = require("moment");

  const {
    modalHeaderTitle,
    modalBodyInfo,
    submitButtonText,
    getDateRange,
    closingFunction,
  } = props;

  useEffect(() => {
    if (startDate) {
      setIsLastTimeSlotCrossed(false);
      setIsStartDate(true);

      if (isSelectedStartDateToday && checkIfLastTimeSlotCrossed()) {
        setIsLastTimeSlotCrossed(true);
        setRunILTSCUEAgainSwitch(!runILTSCUEAgainSwitch);
      }
    } else setIsStartDate(false);
  }, [startDate]);

  useEffect(() => {
    if (isLastTimeSlotCrossed) {
      setStartDate(null);
      setEndDate(null);
      setIsStartDate(false);
      setIsEndDate(false);
    }
  }, [isLastTimeSlotCrossed, runILTSCUEAgainSwitch]);

  useEffect(() => {
    if (endDate) {
      setIsLastTimeSlotCrossed(false);
      setIsEndDate(true);

      if (isSelectedEndDateToday && checkIfLastTimeSlotCrossed()) {
        setIsLastTimeSlotCrossed(true);
        setRunILTSCUEAgainSwitch(!runILTSCUEAgainSwitch);
      }
    } else setIsEndDate(false);

    if (
      endDate &&
      !isSelectedEndDateToday &&
      new Date(endDate).getHours() === 0
    ) {
      setDefEndDate(new Date(endDate).setHours(1, 0, 0, 0));
    } else {
      setDefEndDate(null);
    }
  }, [endDate]);

  useEffect(() => {
    if (defEndDate !== null) {
      setEndDate(new Date(defEndDate).getTime());
    }
  }, [defEndDate]);

  const formatStartDate = (sdate) => {
    setSelectedAtleastOnce(true);
    if (sdate) sdate = sdate.getTime();
    setStartDate(sdate);
  };

  const formatEndDate = (edate) => {
    setSelectedAtleastOnce(true);
    if (edate) edate = edate.getTime();
    setEndDate(edate);
  };

  const finaliseDateRange = () => {
    if (isStartDate && isEndDate && !isLastTimeSlotCrossed)
      getDateRange(startDate, endDate);
  };

  const closePicker = () => {
    closingFunction();
  };

  const syncedMinTimeWithRange = (jsDate) => {
    return moment(
      Math["ceil"](+moment(jsDate) / +moment.duration(30, "minutes")) *
        +moment.duration(30, "minutes")
    ).toDate();
  };

  const isSelectedStartDateToday =
    new Date().setHours(0, 0, 0, 0) ===
    new Date(startDate).setHours(0, 0, 0, 0);

  const isSelectedEndDateToday =
    new Date().setHours(0, 0, 0, 0) === new Date(endDate).setHours(0, 0, 0, 0);

  const areBothDatesNull = !startDate && !endDate;

  const checkIfLastTimeSlotCrossed = () => {
    const currentMoment = moment(),
      momentFormat = "hh:mm:ss",
      lastTimeSlotStart = moment("22:30:00", momentFormat),
      lastTimeSlotEnd = moment("23:59:00", momentFormat);

    if (currentMoment.isBetween(lastTimeSlotStart, lastTimeSlotEnd)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">{modalHeaderTitle}</h4>
          <button
            type="button"
            onClick={() => {
              closePicker();
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <CustomMessageBar />
        <div className="modal-body padding_top">
          <div className="modal_body_chatDateTime">
            <h3>{modalBodyInfo}</h3>
            <div className="div-datepicker-container react-datepicker-wrapper react-datepicker__input-container modal_datePicker_inputStartEnd">
              {/*  */}
              <div className="modal_dateTime_row form__group field">
                <div
                  className={`react-datepicker-wrapper ${
                    (selectedAtleastOnce && !isStartDate) ||
                    isLastTimeSlotCrossed
                      ? "form_group_error"
                      : ""
                  }`}
                >
                  <div
                    className="input-group date videoChatPopup_inputGroupDate"
                    id="sandbox-container"
                  >
                    <DatePicker
                      isClearable
                      filterDate={(d) => {
                        var td = new Date();
                        td.setDate(td.getDate() - 1);
                        return td < d;
                      }}
                      placeholderText="Select Start Date"
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mmaa"
                      selected={startDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={endDate ? endDate : null}
                      maxDate={endDate ? endDate : null}
                      // minTime={
                      //   endDate
                      //     ? new Date(endDate).setHours(
                      //         new Date(endDate).getHours() - 1
                      //       )
                      //     : null
                      // }
                      minTime={
                        (!endDate && isSelectedStartDateToday) ||
                        areBothDatesNull
                          ? new Date().setHours(
                              syncedMinTimeWithRange(new Date()).getHours(),
                              syncedMinTimeWithRange(new Date()).getMinutes()
                            )
                          : endDate &&
                            isSelectedEndDateToday &&
                            new Date().setHours(
                              syncedMinTimeWithRange(new Date()).getHours(),
                              syncedMinTimeWithRange(new Date()).getMinutes()
                            ) >
                              new Date(endDate).setHours(
                                new Date(endDate).getHours(),
                                new Date(endDate).getMinutes()
                              )
                          ? new Date(endDate).setHours(
                              new Date(endDate).getHours(),
                              new Date(endDate).getMinutes()
                            )
                          : endDate &&
                            isSelectedEndDateToday &&
                            new Date().setHours(
                              syncedMinTimeWithRange(new Date()).getHours(),
                              syncedMinTimeWithRange(new Date()).getMinutes()
                            ) >
                              new Date(endDate).setHours(
                                new Date(endDate).getHours() - 1,
                                new Date(endDate).getMinutes()
                              )
                          ? new Date(endDate).setHours(
                              new Date(endDate).getHours(),
                              new Date(endDate).getMinutes() - 30
                            )
                          : endDate
                          ? new Date(endDate).setHours(
                              new Date(endDate).getHours() - 1,
                              new Date(endDate).getMinutes()
                            )
                          : null
                      }
                      // maxTime={
                      //   endDate
                      //     ? new Date(endDate).setHours(
                      //         new Date(endDate).getHours()
                      //       )
                      //     : null
                      // }
                      maxTime={
                        (!endDate && isSelectedStartDateToday) ||
                        areBothDatesNull
                          ? new Date().setHours(22, 30, 0, 0)
                          : endDate
                          ? new Date(endDate).setHours(
                              new Date(endDate).getHours(),
                              new Date(endDate).getMinutes()
                            )
                          : null
                      }
                      excludeTimes={[
                        new Date(endDate).setHours(23, 0, 0, 0),
                        new Date(endDate).setHours(23, 30, 0, 0),
                      ]}
                      onChange={(date) => formatStartDate(date)}
                      className="form__field customdatefld"
                    />
                  </div>
                  {selectedAtleastOnce && !isStartDate ? (
                    <span class="error_span">{sDateErrMsg}</span>
                  ) : (
                    ""
                  )}
                  {isLastTimeSlotCrossed ? (
                    <span class="error_span">{lastTimeSlotCrossedErrMsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*  */}
              <div className="modal_dateTime_row form__group field">
                <div
                  className={`react-datepicker-wrapper ${
                    (selectedAtleastOnce && !isEndDate) || isLastTimeSlotCrossed
                      ? "form_group_error"
                      : ""
                  }`}
                >
                  <div
                    className="input-group date videoChatPopup_inputGroupDate"
                    id="sandbox-container"
                  >
                    <DatePicker
                      isClearable
                      filterDate={(d) => {
                        var td = new Date();
                        td.setDate(td.getDate() - 1);
                        return td < d;
                      }}
                      placeholderText="Select End Date"
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mmaa"
                      selected={endDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate ? startDate : null}
                      maxDate={startDate ? startDate : null}
                      // minTime={
                      //   startDate
                      //     ? new Date(startDate).setHours(
                      //         new Date(startDate).getHours()
                      //       )
                      //     : null
                      // }
                      minTime={
                        (!startDate && isSelectedEndDateToday) ||
                        areBothDatesNull
                          ? new Date().setHours(
                              syncedMinTimeWithRange(new Date()).getHours(),
                              syncedMinTimeWithRange(new Date()).getMinutes()
                            )
                          : startDate
                          ? new Date(startDate).setHours(
                              new Date(startDate).getHours(),
                              new Date(startDate).getMinutes()
                            )
                          : null
                      }
                      // maxTime={
                      //   startDate
                      //     ? new Date(startDate).setHours(
                      //         new Date(startDate).getHours() + 1
                      //       )
                      //     : null
                      // }
                      maxTime={
                        (!startDate && isSelectedEndDateToday) ||
                        areBothDatesNull
                          ? new Date().setHours(23, 30, 0, 0)
                          : startDate
                          ? new Date(startDate).setHours(
                              new Date(startDate).getHours() + 1,
                              new Date(startDate).getMinutes()
                            )
                          : null
                      }
                      excludeTimes={[
                        new Date(startDate).setHours(0, 0, 0, 0),
                        new Date(startDate).setHours(0, 30, 0, 0),
                      ]}
                      onChange={(date) => formatEndDate(date)}
                      className="form__field customdatefld"
                    />
                  </div>
                  {selectedAtleastOnce && !isEndDate ? (
                    <span class="error_span">{eDateErrMsg}</span>
                  ) : (
                    ""
                  )}
                  {isLastTimeSlotCrossed ? (
                    <span class="error_span">{lastTimeSlotCrossedErrMsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              closePicker();
            }}
            data-dismiss="modal"
            className="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn btn-primary modal_btn_save"
            onClick={(e) => {
              e.preventDefault();
              finaliseDateRange();
            }}
            data-dismiss="modal"
          >
            <span>{submitButtonText}</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

// Newest1 - End
