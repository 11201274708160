import React, { Component } from 'react';
import {Link, NavLink, withRouter, Prompt} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import CommonContext from '../../context/CommonContext';

import FetchClientsService from '../../service/FetchClientsService';
import FetchMatterNameListService from '../../service/FetchMatterNameListService';
import FetchLatestInvoiceDataService from '../../service/FetchLatestInvoiceDataService';
import FileUploadService from '../../service/FileUploadService';
import InvoiceGeneratorSaveProgressService from '../../service/InvoiceGeneratorSaveProgressService';
import InvoiceGeneratorPreviewService from '../../service/InvoiceGeneratorPreviewService';

import CustLoader from './CustLoader';
import { Scrollbars } from 'react-custom-scrollbars';

import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';


import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";


import ManageInvoiceGeneratorGenericHeader from './ManageInvoiceGeneratorGenericHeader';

import CustomMessageBar from '../../messagebar/CustomMessageBar';
import {notify} from '../../messagebar/CustomMessageBar';
import {commonnotitrack} from "./NotiEmitTrack";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



import { throttle, debounce } from "throttle-debounce";
const numberWords = require('number-words');
//import { ToWords } from 'to-words';




const getBaseUrl = commonJsObj.getBaseUrl;
const validateBlankSpace = commonJsObj.validateBlankSpace;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;
const getDateFormatForInvGenCal = commonJsObj.getDateFormatForInvGenCal;
const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;
const findElement = commonJsObj.findElement;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;
const validatePercentInputNumber = commonJsObj.validatePercentInputNumber;
const validateSpecialCharacterPresence = commonJsObj.validateSpecialCharacterPresence;
const validateEmail = commonJsObj.validateEmail;

const getStaticCurrencies = commonStaticJsObj.getStaticCurrencies;
const currencyStaticData = getStaticCurrencies();
const getSubscriptionActiveStatus = commonJsObj.getSubscriptionActiveStatus;
const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;
const validateSpecialCharButNotHyphenPresence = commonJsObj.validateSpecialCharButNotHyphenPresence;


const getGstVatStaticOptions = commonStaticJsObj.getGstVatStaticOptions;

const ExampleCustomInput = ({ value, onClick, keydata, placeholdertxt }) => (    
    <input
    type="text"
    className="form__field customdatefld"
    placeholder={placeholdertxt}
    defaultValue={value}
    onClick={onClick}
    key={keydata}
    readOnly={true}
    />
  );


  

export class ManageInvoiceGenerator extends Component {
    static contextType = CommonContext;

    
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state ={
           
            "advancePaidAmount": "",
            "balanceDueDetails": {
                "dueAmount": "",
                "dueAmountInWords": ""
            },
            "currencyType": "INR",
            "descriptions": [{
                "amount": "",
                "code": "",
                "description": "",
                "tax": ""
                }
            
            ],
            "discountAmount": "",
            "discountPercentage": "",
            "dueDate": null,
            "indirectTaxAmount": "",
            // if modifification for client is done then update receiverDataChangeTrack commonReset 
            "client": {
                        "id":"",
                        "address": "",
                        "clientName": "",
                        "contactName": "",
                        "emailAddress": "",
                        "gstInNumber": "",
                        "panNumber": "",
                        "phoneNumber": "",
                        "placeOfSupply":"",
                        "countryCode": "in",
                        "countryDialCode": "91",
                        //other
                        "phonePlugin" : "91", // for plugin internal
                        
                        
                       
                    },
            "invoiceDate": null,
            "invoiceTitle": "INVOICE",
            "matterName": "",
            "notes": "",
            "outOfPocketAmount": "",
            "tdsAmount": "",
            "tdsPercentage": "",
            "totalAmount": "",
            "logoId": 0,
            // if modification of userPracticeDetails is needed also  update in setDataToState  
            "userPracticeDetails": {
                "id":"",
                "address": "",
                "emailAddress": "",
                "gstInNumber": "",
                "lawyerName": "",
                "phoneNumber": "",
                "panNumber": "",
                "practiceName": "",
                "countryCode": "in",
                "countryDialCode": "91",
                //other
                "phonePlugin" : "91", // for plugin internal
                
                
            },
            

            //**** other *****************************************
            "filePath": null, 
            "currSmallUnit": "Paisa",
            "curUnitSingular": "Rupee",
            "curUnitPlural": "Rupees",
            "invoiceNumber": null,
            "clientList":[],
            "matterNameList":[],
            "clientAddNewButtonClickFlag":false,
            "matterNameAddNewButtonClickFlag":false,
            "matter": { // will be used on assignment in fetching 
                "id": 0,
                "name": ""
              },
            "dueOnRceipt": false,
            "saveProgressButtonClickFlag": false,
            "invPreviewButtonClickFlag": false,
            "logoBase64":"",
            "logoMimeType":"",
            "invoiceId":0,
            "invoiceStatus":"", //DRAFT, PREVIEW , GENERATED
            "navigateDisAllowFlag":false,
            "specialNote": "The Professional services are the subjects to Reverse GST at the prevailing rate of 18%. This tax needs to be paid by the organization to the government directly.",

            // error message related
            "invoiceTitleErMsg":"",
            "invoiceDateErMsg":"",
            "fileUplErMsg":"",
            "descriptionsErMsg":[{
                "amount": "",
                "code": "",
                "description": "",
                "tax": ""
                }],
            "matterNameErMsg":"",
            "notesErMsg":"",
            "balanceDueErMsg":"",
            // if modifification for clientErMsg is done then update receiverDataChangeTrack commonReset 
            "clientErMsg": {
                "address": "",
                "clientName": "",
                "contactName": "",
                "emailAddress": "",
                "gstInNumber": "",
                "panNumber": "",
                "phoneNumber": "",
                "placeOfSupply":"",
            },
            "userPracticeDetailsErMsg": {
                "address": "",
                "emailAddress": "",
                "gstInNumber": "",
                "lawyerName": "",
                "phoneNumber": "",
                "panNumber": "",
                "practiceName": ""           
            }

            


            
        };
        this.scrollerRef = React.createRef();
        this.uploadedFileRef = React.createRef();
        this.fileUpl = React.createRef();
        this.invoiceTitleRef = React.createRef();

        //this.descBlockDataChangeTrack = debounce(250, this.descBlockDataChangeTrackDebounced);
    }

   

    componentDidMount() {
       this.isComponentLoaded =  true;   
       let kycVerifiedStatus = getKycVerifiedStatus();
       let subscriptionActiveStatus = getSubscriptionActiveStatus();
       
       if(!(subscriptionActiveStatus) || !(kycVerifiedStatus)){
            this.props.history.push("/get-task");
       }
       else{
        //this.disAllowPageQuit();
        this.fetchClients();
        this.isComponentLoaded && this.setState({"navigateDisAllowFlag":true});
 
        // ********* for context starts *****
           let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;   
           let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
 
           let invoiceGeneratedPreviewGenState = null;
           let actionType = "";
           //console.log("HAAAAAA=>>>",sharedData);
 
           if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){   
             
               actionType =  sharedData.actionType;     
             // If user clicked on edit button of invoice generate preview page and then came here
               if(actionType=="INVOICE_GENERATOR_PREVIEW_EDIT" ){
 
                  invoiceGeneratedPreviewGenState = sharedData.other && sharedData.other.invoiceGeneratedPreviewGenState?sharedData.other.invoiceGeneratedPreviewGenState:null;
                 
               }
           }
         //  ********* for context ends *****
 
         if(actionType=="INVOICE_GENERATOR_PREVIEW_EDIT" && invoiceGeneratedPreviewGenState){
 
             let respObj =  invoiceGeneratedPreviewGenState;
             
             this.setDataToState({"calledFrom":"componentDidMount","respObj":respObj,"forceSaveProgress":true});
             setTimeout(()=>{
                 // Now unset key invoiceGeneratedPreviewGenState from context
                     if(setShare && sharedData){
 
                         let sharedDataNewObj = {...sharedData}; 
                         let otherDataNewObj = sharedDataNewObj.other?{...sharedDataNewObj.other}:{};
                         if(otherDataNewObj && otherDataNewObj.invoiceGeneratedPreviewGenState){
                                 delete(otherDataNewObj.invoiceGeneratedPreviewGenState);
                         }
                         
                         sharedDataNewObj["actionType"]   = "";
                         sharedDataNewObj["purpose"]   = "";
                         sharedDataNewObj["other"]   = otherDataNewObj;                   
                         setShare(sharedDataNewObj);                              
                 }
             },1700);
 
         }else{
             this.fetchLatestInvoiceData();
         }
       }

    }
     
    
    componentWillUnmount() {
          this.isComponentLoaded = false; 
          window.removeEventListener("beforeunload", this.onUnloadPrompt);
         

 
    }

    onUnloadPrompt = e => { // the method that will be used for both add and remove event
        e.preventDefault();
        let message = "Leave site ? \n Changes that you made may not be saved.";
        (e || window.event).returnValue = message; //Gecko + IE
        return message;
     }

     disAllowPageQuit = () =>{
        window.addEventListener("beforeunload", this.onUnloadPrompt);
     }

   
    

    callSaveProgress = (otherParam) =>{        
        let passParam = {"data":otherParam.data};

        let userv = new InvoiceGeneratorSaveProgressService();
        userv.saveProgress(passParam).then(dataObj =>{
        if(dataObj.status==200){
            if(dataObj.data.entity!==undefined){
                //console.log("fetched=>>>>>>>",data.data.entity);     

                
                
                let respObj =  dataObj.data.entity;
                this.setDataToState({"calledFrom":"callSaveProgress","respObj":respObj});

                // commonnotitrack("SCROLL_TO_MAIN_HEADER",{
                //     "notiType": "SCROLL_TO_MAIN_HEADER",
                //     "triggerCallback":true,
                //     "trigerredFrom":"INVOICE_GENERATOR_1ST_SCREEN"
                //    });

                let notiMsg = "Your Invoice details have been saved. Please complete all fields and click on ‘Preview’ to proceed.";           
      
                notify(notiMsg,true,{
                    "successMsg":1,
                    "customWrapClass":"customsuccclass text-center",
                    "showCloseButton": false,                     
                    "dismiss": {
                    "duration": 1800,
                    }
          
                });


                
            }
        }
        }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    console.log(errMsg);

                    notify(errMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customsuccclass text-center",
                        "showCloseButton": false,                     
                        "dismiss": {
                        "duration": 1800,
                        }
              
                    });       
                                     
            }
            } 
            
        }).finally(()=>{
            this.isComponentLoaded && this.setState({"saveProgressButtonClickFlag": false});
        });
    }

    callPreview = (otherParam) =>{        
        let passParam = {"data":otherParam.data};

        let userv = new InvoiceGeneratorPreviewService();
        userv.callPreview(passParam).then(dataObj =>{
        if(dataObj.status==200){
            if(dataObj.data.entity!==undefined){
                //console.log("fetched=>>>>>>>",data.data.entity);     
                
                let respObj =  dataObj.data.entity;
                let invoiceId = (respObj && respObj.id!==undefined)?respObj.id:0;  
                let  invoiceStatus = (respObj && respObj.invoiceStatus!==undefined)?respObj.invoiceStatus:""; 
                if(invoiceId && invoiceStatus=="PREVIEW"){
                    this.props.history.push("/invoice-preview/?inv="+invoiceId);
                }            
                
               


                
            }
        }
        }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    console.log(errMsg);

                    notify(errMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customsuccclass text-center",
                        "showCloseButton": false,                     
                        "dismiss": {
                        "duration": 1800,
                        }
              
                    });       
                                     
            }
            } 
            
        }).finally(()=>{
            this.isComponentLoaded && this.setState({"invPreviewButtonClickFlag": false});
        });
    }


    validateBeforeApiHit = (otherParam) =>{

        let calledFrom  = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";

        let stateData = {...this.state};
        //console.log(" HHHH stateData=>>>>",stateData," this.state=>>>>",this.state );
        let clientObj = {...stateData.client};
        if(clientObj && clientObj.phonePlugin!==undefined){
            delete(clientObj.phonePlugin);
        }  
        if(clientObj && clientObj.id!==undefined){
            delete(clientObj.id);
        }      

        let userPracticeDetailsObj = {...stateData.userPracticeDetails};
        if(userPracticeDetailsObj && userPracticeDetailsObj.phonePlugin!==undefined){
            delete(userPracticeDetailsObj.phonePlugin);
        } 
        if(userPracticeDetailsObj && userPracticeDetailsObj.id!==undefined){
           delete(userPracticeDetailsObj.id);
        }          

        
        let dataObj = {};
        dataObj["advancePaidAmount"] = stateData.advancePaidAmount;
        dataObj["balanceDueDetails"] = stateData.balanceDueDetails;
        dataObj["currencyType"] = stateData.currencyType;
        dataObj["currencyType"] = stateData.currencyType;
        dataObj["descriptions"] = stateData.descriptions;
        
        dataObj["discountAmount"] = stateData.discountAmount;
        dataObj["discountPercentage"] = stateData.discountPercentage;
        dataObj["dueDate"] = stateData.dueDate;
        dataObj["indirectTaxAmount"] = stateData.indirectTaxAmount;
        dataObj["client"] = clientObj;
        dataObj["invoiceDate"] = stateData.invoiceDate;
        dataObj["invoiceTitle"] = stateData.invoiceTitle;
        dataObj["invoiceNumber"] = stateData.invoiceNumber;
        dataObj["matterName"] = stateData.matterName;
        dataObj["notes"] = stateData.notes;
        dataObj["outOfPocketAmount"] = stateData.outOfPocketAmount;
        dataObj["tdsAmount"] = stateData.tdsAmount;
        dataObj["tdsPercentage"] = stateData.tdsPercentage;
        dataObj["totalAmount"] = stateData.totalAmount;
        dataObj["logoId"] = stateData.logoId;
        dataObj["userPracticeDetails"] = userPracticeDetailsObj;
        
        //console.log(" otherParam=->>>",otherParam," HHHH dataObj=>>>>",dataObj);

        if(calledFrom=="saveProgressButton"){
            this.isComponentLoaded && this.setState({"saveProgressButtonClickFlag":true},()=>{
                setTimeout(()=>{
                    this.callSaveProgress({"data":dataObj});
                },1000);
                
            });
        }
        else if(calledFrom=="previewButton"){

            let chkValidPreview = this.custValidate();
            if(chkValidPreview){
                this.isComponentLoaded && this.setState({"invPreviewButtonClickFlag":true,"navigateDisAllowFlag":false},()=>{
                    setTimeout(()=>{
                        this.callPreview({"data":dataObj});
                    },1000);
                    
                });
            }else{

                this.isComponentLoaded && this.setState({"navigateDisAllowFlag":false},()=>{

                    let notiMsg = "Either mandatory fields are empty or invalid data has been provided.";           
        
                    notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customsuccclass text-center",
                        "showCloseButton": false,                     
                        "dismiss": {
                        "duration": 1800,
                        }
                
                    });

                });
        
                
            }
            
        }

        
        


    }

    fetchLatestInvoiceData = (otherParam) =>{
        

        let userv = new FetchLatestInvoiceDataService();
        userv.fetchData().then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                //console.log("fetched=>>>>>>>",data.data.entity);
                let respObj =  data.data.entity;
                this.setDataToState({"calledFrom":"fetchLatestInvoiceData","respObj":respObj});
                
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }

    setDataToState = (otherParam) => {

        let calledFrom = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";
        let forceSaveProgress = otherParam.forceSaveProgress!==undefined?otherParam.forceSaveProgress:false;
        let respObj = otherParam.respObj!==undefined?otherParam.respObj:null;

        if(calledFrom=="fetchLatestInvoiceData" || calledFrom=="callSaveProgress" ||calledFrom=="resetClick"||calledFrom=="componentDidMount"){

           

            let userPracticeDetailsObj = respObj!==null && respObj.userPracticeDetails!==undefined?respObj.userPracticeDetails:null;            
            
            let userPracticeDetailsData = {};
            if(!userPracticeDetailsObj){
                userPracticeDetailsData =   {
                        "id":"",
                        "address": "",
                        "emailAddress": "",
                        "gstInNumber": "",
                        "lawyerName": "",
                        "phoneNumber": "",
                        "panNumber": "",
                        "practiceName": "",
                        //other
                        "phonePlugin" : "91", // for plugin internal
                        "countryCode": "in",
                        "countryDialCode": "91",
                        
                    };
            }else{
                let countryCodeData = (userPracticeDetailsObj.countryCode!==undefined && userPracticeDetailsObj.countryCode!==null ?userPracticeDetailsObj.countryCode:"in");

                let countryDialCodeData = (userPracticeDetailsObj.countryDialCode!==undefined && userPracticeDetailsObj.countryDialCode!==null?userPracticeDetailsObj.countryDialCode:"91");

                let phoneNumberData = (userPracticeDetailsObj.phoneNumber!==undefined  && userPracticeDetailsObj.phoneNumber!==null?userPracticeDetailsObj.phoneNumber:"");

                let phonePluginData = countryDialCodeData+phoneNumberData;

                userPracticeDetailsData =   {
                    "id":(userPracticeDetailsObj.id!==undefined && userPracticeDetailsObj.id!==null?userPracticeDetailsObj.id:0),
                    "address": (userPracticeDetailsObj.address!==undefined && userPracticeDetailsObj.address!==null?userPracticeDetailsObj.address:""),
                    "emailAddress": (userPracticeDetailsObj.emailAddress!==undefined && userPracticeDetailsObj.emailAddress!==null?userPracticeDetailsObj.emailAddress:""),
                    "gstInNumber":(userPracticeDetailsObj.gstInNumber!==undefined && userPracticeDetailsObj.gstInNumber!==null?userPracticeDetailsObj.gstInNumber:""),
                    "lawyerName": (userPracticeDetailsObj.lawyerName!==undefined && userPracticeDetailsObj.lawyerName!==null?userPracticeDetailsObj.lawyerName:""),
                    "phoneNumber": phoneNumberData,
                    "panNumber": (userPracticeDetailsObj.panNumber!==undefined && userPracticeDetailsObj.panNumber!==null?userPracticeDetailsObj.panNumber:""),
                    "practiceName": (userPracticeDetailsObj.practiceName!==undefined && userPracticeDetailsObj.practiceName!==null?userPracticeDetailsObj.practiceName:""),
                    //other
                    "phonePlugin" : phonePluginData, // for plugin internal
                    "countryCode": countryCodeData,
                    "countryDialCode":  countryDialCodeData,
                    
                };
                
            }

            
    
           let userPracticeDetailsErMsg = {
                "address": "",
                "emailAddress": "", //Optional
                "gstInNumber": "", //Optional
                "lawyerName": "",
                "phoneNumber": "", //Optional
                "panNumber": "", 
                "practiceName": ""           
            };

            

            let clientObj = respObj!==null && respObj.client!==undefined?respObj.client:null;
            let clientData = {};
            if(!clientObj){
                clientData =   {
                    "id":"",
                    "address": "",
                    "clientName": "",
                    "contactName": "",
                    "emailAddress": "",
                    "gstInNumber": "",
                    "panNumber": "",
                    "phoneNumber": "",
                    "placeOfSupply":"",
                    //other
                    "phonePlugin" : "91", // for plugin internal
                    "countryCode": "in",
                    "countryDialCode": "91",
                    
                   
                };
            }else{
                let countryCodeDataC = (clientObj.countryCode!==undefined && clientObj.countryCode!==null ?clientObj.countryCode:"in");

                let countryDialCodeDataC = (clientObj.countryDialCode!==undefined && clientObj.countryDialCode!==null?clientObj.countryDialCode:"91");

                let phoneNumberDataC = (clientObj.phoneNumber!==undefined  && clientObj.phoneNumber!==null?clientObj.phoneNumber:"");

                let phonePluginDataC = countryDialCodeDataC+phoneNumberDataC;

                clientData =   {
                    "id":(clientObj.id!==undefined && clientObj.id!==null?clientObj.id:0),
                    "address": (clientObj.address!==undefined && clientObj.address!==null?clientObj.address:""),
                    "clientName": (clientObj.clientName!==undefined && clientObj.clientName!==null?clientObj.clientName:""),
                    "contactName": (clientObj.contactName!==undefined && clientObj.contactName!==null?clientObj.contactName:""),
                    "emailAddress": (clientObj.emailAddress!==undefined && clientObj.emailAddress!==null?clientObj.emailAddress:""),
                    "gstInNumber":(clientObj.gstInNumber!==undefined && clientObj.gstInNumber!==null?clientObj.gstInNumber:""),
                    "panNumber": (clientObj.panNumber!==undefined && clientObj.panNumber!==null?clientObj.panNumber:""),
                    "phoneNumber": phoneNumberDataC,
                    "placeOfSupply": (clientObj.placeOfSupply!==undefined && clientObj.placeOfSupply!==null?clientObj.placeOfSupply:""),
                   
                   
                    //other
                    "phonePlugin" : phonePluginDataC, // for plugin internal
                    "countryCode": countryCodeDataC,
                    "countryDialCode":  countryDialCodeDataC,
                    
                };
                
            }

            let clientErMsg  ={
                "address": "",
                "clientName": "",
                "contactName": "",
                "emailAddress": "",//Optional
                "gstInNumber": "", //Optional
                "panNumber": "", //Optional
                "phoneNumber": "", //Optional
                "placeOfSupply":"",
            };

            

            let dueDate = respObj!==null && respObj.dueDate!==undefined?respObj.dueDate:null;
            let dueOnRceipt = !(dueDate)?true:false;

            let addNewDescRowEmptyObj = {
                "amount": "",
                "code": "",
                "description": "",
                "tax": ""
                };
            let descriptions = respObj!==null && respObj.descriptions!==undefined && respObj.descriptions?respObj.descriptions:[addNewDescRowEmptyObj];

            let descriptionsErMsg = [];
           if(descriptions && descriptions.length>0){
            descriptions.map((e,i)=>{
                descriptionsErMsg.push({
                    "amount": "",
                    "code": "",
                    "description": "",
                    "tax": ""
                    });
            })
           }else{
            descriptionsErMsg = descriptionsErMsg.push({
                "amount": "",
                "code": "",
                "description": "",
                "tax": ""
                });
           }
            

            

                    
            let totalAmount = respObj!==null && respObj.totalAmount!==undefined?respObj.totalAmount:"";
            let advancePaidAmount = respObj!==null && respObj.advancePaidAmount!==undefined && respObj.advancePaidAmount?respObj.advancePaidAmount:"";
            let discountAmount = respObj!==null && respObj.discountAmount!==undefined && respObj.discountAmount?respObj.discountAmount:"";
            let discountPercentage = respObj!==null && respObj.discountPercentage!==undefined  && respObj.discountPercentage?respObj.discountPercentage:"";
            let indirectTaxAmount = respObj!==null && respObj.indirectTaxAmount!==undefined ?respObj.indirectTaxAmount:"";
            let tdsAmount = respObj!==null && respObj.tdsAmount!==undefined && respObj.tdsAmount?respObj.tdsAmount:"";
            let tdsPercentage = respObj!==null && respObj.tdsPercentage!==undefined && respObj.tdsPercentage?respObj.tdsPercentage:"";
            let outOfPocketAmount = respObj!==null && respObj.outOfPocketAmount!==undefined  && respObj.outOfPocketAmount?respObj.outOfPocketAmount:"";
            let balanceDueDetails = respObj!==null && respObj.balanceDueDetails!==undefined && respObj.balanceDueDetails?respObj.balanceDueDetails:{ "dueAmount": "",
            "dueAmountInWords": ""};
            let notes = respObj!==null && respObj.notes!==undefined?respObj.notes:"";
            let invoiceDate = respObj!==null && respObj.invoiceDate!==undefined && respObj.invoiceDate?respObj.invoiceDate:(getTimestampInMillisecondsFromDateObj(new Date()));
            let matterName = respObj!==null && respObj.matterName!==undefined?respObj.matterName:"";
            let matterNameAddNewButtonClickFlag = (matterName)?true:false;
            let clientAddNewButtonClickFlag = (clientData.clientName)?true:false;
            let invoiceStatus = respObj!==null && respObj.invoiceStatus!==undefined?respObj.invoiceStatus:"";
            let logoBase64 = respObj!==null && respObj.logoBase64!==undefined?respObj.logoBase64:"";
            let logoMimeType = respObj!==null && respObj.logoMimeType!==undefined?respObj.logoMimeType:"";
            let filePath = (logoBase64 && logoMimeType)?null:this.state.filePath;
            let logoId = respObj!==null && respObj.logoId!==undefined?respObj.logoId:0;
            let invoiceId = (respObj && respObj.id!==undefined)?respObj.id:0;  
            let invoiceTitle = (respObj && respObj.invoiceTitle!==undefined  && respObj.invoiceTitle)?respObj.invoiceTitle:"INVOICE";
            let invoiceNumber = (respObj && respObj.invoiceNumber!==undefined  && respObj.invoiceNumber)?respObj.invoiceNumber:"";


            //console.log("=======UPDATE this userPracticeDetailsData=>",userPracticeDetailsData);

            this.isComponentLoaded && this.setState(
                {
                    "userPracticeDetails":userPracticeDetailsData,
                    "client":clientData,
                    "dueOnRceipt":dueOnRceipt,
                    "dueDate":dueDate,
                    "descriptions":descriptions,
                    "totalAmount":totalAmount,
                    "advancePaidAmount":advancePaidAmount,
                    "discountAmount":discountAmount,
                    "discountPercentage":discountPercentage,
                    "indirectTaxAmount":indirectTaxAmount,
                    "tdsAmount":tdsAmount,
                    "tdsPercentage":tdsPercentage,
                    "outOfPocketAmount":outOfPocketAmount,
                    "balanceDueDetails":balanceDueDetails,
                    "notes":notes,
                    "invoiceDate":invoiceDate,
                    "matterName":matterName,
                    "matterNameAddNewButtonClickFlag":matterNameAddNewButtonClickFlag,
                    "clientAddNewButtonClickFlag":clientAddNewButtonClickFlag,
                    "logoBase64":logoBase64,
                    "logoMimeType":logoMimeType,
                    "invoiceStatus":invoiceStatus,
                    "filePath":filePath,
                    "logoId":logoId,
                    "invoiceId":invoiceId,
                    "invoiceTitle":invoiceTitle,
                    "invoiceNumber":invoiceNumber,
                    "descriptionsErMsg":descriptionsErMsg,
                    "userPracticeDetailsErMsg":userPracticeDetailsErMsg,
                    "clientErMsg":clientErMsg,
                    "fileUplErMsg":"",
                    "invoiceTitleErMsg":"",
                    "invoiceNumberErMsg":"",
                    "notesErMsg":"",
                    "balanceDueErMsg":""
                    
                    
            



                    
                }, ()=>{

                    if(calledFrom=="componentDidMount" && forceSaveProgress===true){
                        this.validateBeforeApiHit({"calledFrom":"saveProgressButton"});

                    }
                });


        }

        

    }


    fetchClients = (otherParam) =>{
        let userv = new FetchClientsService();
        userv.getClients({}).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"clientList":data.data.entity},()=>{ console.log(this.state.clientList)});
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }

    

    clientListDropDownChangeTrack = (selectedData) =>{
        const clientId = selectedData.target.value;
        const clientList = this.state.clientList;
        if(clientId){
            
            
            let clientObj = findElement(clientList,"id",clientId);
            //console.log("clientObj=>>>",clientObj);

            if(clientObj){
                clientObj = clientObj.objData;
            }           

            let countryCodeDataC = (clientObj.countryCode!==undefined && clientObj.countryCode!==null ?clientObj.countryCode:"in");

            let countryDialCodeDataC = (clientObj.countryDialCode!==undefined && clientObj.countryDialCode!==null?clientObj.countryDialCode:"91");

            let phoneNumberDataC = (clientObj.phoneNumber!==undefined  && clientObj.phoneNumber!==null?clientObj.phoneNumber:"");

            let phonePluginDataC = countryDialCodeDataC+phoneNumberDataC;

            let clientData =   {
                "id":(clientObj.id!==undefined && clientObj.id!==null?clientObj.id:0),
                "address": (clientObj.address!==undefined && clientObj.address!==null?clientObj.address:""),
                "clientName": (clientObj.clientName!==undefined && clientObj.clientName!==null?clientObj.clientName:""),
                "contactName": (clientObj.contactName!==undefined && clientObj.contactName!==null?clientObj.contactName:""),
                "emailAddress": (clientObj.emailAddress!==undefined && clientObj.emailAddress!==null?clientObj.emailAddress:""),
                "gstInNumber":(clientObj.gstInNumber!==undefined && clientObj.gstInNumber!==null?clientObj.gstInNumber:""),
                "panNumber": (clientObj.panNumber!==undefined && clientObj.panNumber!==null?clientObj.panNumber:""),
                "phoneNumber": phoneNumberDataC,
                "placeOfSupply": (clientObj.placeOfSupply!==undefined && clientObj.placeOfSupply!==null?clientObj.placeOfSupply:""),
               
               
                //other
                "phonePlugin" : phonePluginDataC, // for plugin internal
                "countryCode": countryCodeDataC,
                "countryDialCode":  countryDialCodeDataC,
                
            };
            this.isComponentLoaded && this.setState({
                "client": clientData,
               
    
            },()=>{
                // call fetchMatterNameList
                //console.log(" Now client =>>",this.state.client);

                this.handleChange("matterName","",{});
                setTimeout(()=>{
                    this.fetchMatterNameList({"clientId":clientId});
                },1500);

                
            });
        }
        
 
    }

    fetchMatterNameList = (otherParam) =>{
        let clientId = otherParam.clientId!==undefined?otherParam.clientId:0; 

        let userv = new FetchMatterNameListService();
        userv.getMatterNames({"clientId":clientId}).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"matterNameList":data.data.entity},()=>{ console.log(this.state.matterNameList)});
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }

    matterListDropDownChangeTrack = (selectedData) =>{
        const matterId = selectedData.target.value;
        const matterNameList = this.state.matterNameList;
        let matterName = "";
        if(matterId){

            let matterObj = findElement(matterNameList,"matterId",matterId);
            //console.log("clientObj=>>>",clientObj);

            if(matterObj){
                matterName = matterObj.objData.name;
                this.isComponentLoaded && this.setState({
                    "matter": {  
                        "id": matterId,
                        "name": matterName
                    },
                    "matterName": matterName,
                   
        
                },()=>{
                });
            }           


        }

       
        
 
    }


    custValidate = () =>{
        
        let invoiceTitleErMsg = ''; 
        let notesErMsg = ''; 
        let fileUplErMsg = '';  
        let invoiceDateErMsg = '';     
        let invoiceDueDateErMsg = '';
        let balanceDueErMsg = '';
        let invoiceNumberErMsg = '';


       let clientErMsg  ={
            "address": "",
            "clientName": "",
            "contactName": "",
            "emailAddress": "",//Optional
            "gstInNumber": "", //Optional
            "panNumber": "", //Optional
            "phoneNumber": "", //Optional
            "placeOfSupply":"",
        };

       let userPracticeDetailsErMsg = {
            "address": "",
            "emailAddress": "", //Optional
            "gstInNumber": "", //Optional
            "lawyerName": "",
            "phoneNumber": "", //Optional
            "panNumber": "", 
            "practiceName": ""           
        };
      
        let totErrCountTrack = [];
        let totClientErrCountTrack = [];
        let totUsrPractErrCountTrack = [];
        let totDescErrCountTrack = [];

        if(this.state.dueDate){
            //invoiceDueDateErMsg

           let dueDateObj =   getDateObjFromMilliseconds(this.state.dueDate); 
           let dueDateWithoutTimeObj = new Date(dueDateObj.getFullYear(),dueDateObj.getMonth(), dueDateObj.getDate());
           let dueDateObjTs = getTimestampInMillisecondsFromDateObj(dueDateWithoutTimeObj);

           let  todaysDateObj = new Date();
           let todaysDateWithoutTimeObj = new Date(todaysDateObj.getFullYear(),todaysDateObj.getMonth(), todaysDateObj.getDate());
           let todaysDateObjTs = getTimestampInMillisecondsFromDateObj(todaysDateWithoutTimeObj);

           if(dueDateObjTs < todaysDateObjTs){
            invoiceDueDateErMsg = " Due date should be greater or equal to current date."
            totErrCountTrack.push(invoiceDueDateErMsg);
           }

        }

        if((validateBlankSpace(this.state.invoiceTitle)==true)){
            invoiceTitleErMsg = "Invoice title is required.";        
            
            totErrCountTrack.push(invoiceTitleErMsg);
          }
        
        
          
          
        if(!(this.state.invoiceNumber) || (validateBlankSpace(this.state.invoiceNumber)==true)){
        invoiceNumberErMsg = "Invoice number is required.";        
        
        totErrCountTrack.push(invoiceNumberErMsg);
        }
        else if((validateSpecialCharButNotHyphenPresence(this.state.invoiceNumber)==true)){
        invoiceNumberErMsg = "Special character other than hyphen(-) is not allowed.";        
        
        totErrCountTrack.push(invoiceNumberErMsg);
        }
        
        
        // if(!(this.state.logoId)){
        //     fileUplErMsg = "Logo is required.";        
            
        //     totErrCountTrack.push(fileUplErMsg);
        //   }

        if(!(this.state.invoiceDate)){
            invoiceDateErMsg = "Invoice date is required.";        
            
            totErrCountTrack.push(invoiceDateErMsg);
          }else{
            let invDateObj =   getDateObjFromMilliseconds(this.state.invoiceDate); 
            let invDateWithoutTimeObj = new Date(invDateObj.getFullYear(),invDateObj.getMonth(), invDateObj.getDate());
            let invDateObjTs = getTimestampInMillisecondsFromDateObj(invDateWithoutTimeObj);
 
            let  todaysDateObj = new Date();
            let todaysDateWithoutTimeObj = new Date(todaysDateObj.getFullYear(),todaysDateObj.getMonth(), todaysDateObj.getDate());
            let todaysDateObjTs = getTimestampInMillisecondsFromDateObj(todaysDateWithoutTimeObj);
 
            if(invDateObjTs > todaysDateObjTs){
                invoiceDateErMsg = " Invoice date should be lesser or equal to current date."
             totErrCountTrack.push(invoiceDateErMsg);
            }

          }

        if((this.state.notes) && (this.state.notes.length>170)){
            notesErMsg = "Max 170 characters are allowed.";        
            
            totErrCountTrack.push(notesErMsg);
          }

        let dueAmount =  this.state.balanceDueDetails.dueAmount && parseFloat(this.state.balanceDueDetails.dueAmount)?(parseFloat(this.state.balanceDueDetails.dueAmount).toFixed(2)):((this.state.balanceDueDetails.dueAmount===0)?(0.00).toFixed(2):(this.state.balanceDueDetails.dueAmount===""?"":parseFloat(this.state.balanceDueDetails.dueAmount)));

        if(dueAmount<0){
            balanceDueErMsg = "Balance due cannot be negative."; 
            totErrCountTrack.push(balanceDueErMsg);
          }

        if(this.state.client){
                if(this.state.client.address!==undefined && (validateBlankSpace(this.state.client.address)==true) ){
                    clientErMsg.address = "Address is required.";
                    totClientErrCountTrack.push(clientErMsg.address);

                }

                if(this.state.client.clientName!==undefined && (validateBlankSpace(this.state.client.clientName)==true) ){
                    clientErMsg.clientName = "Client name is required.";
                    totClientErrCountTrack.push(clientErMsg.clientName);

                }

                
                if(this.state.client.contactName!==undefined && (validateBlankSpace(this.state.client.contactName)==true) ){
                    clientErMsg.contactName = "Client contact name is required.";
                    totClientErrCountTrack.push(clientErMsg.contactName);

                }

                if(this.state.client.placeOfSupply!==undefined && (validateBlankSpace(this.state.client.placeOfSupply)==true) ){
                    clientErMsg.placeOfSupply = "Place of supply is required.";
                    totClientErrCountTrack.push(clientErMsg.placeOfSupply);

                }


                //console.log("JJJJJJ=>>>",validateBlankSpace(this.state.client.panNumber),this.state.client.panNumber);
                if(this.state.client.panNumber!==undefined && (!validateBlankSpace(this.state.client.panNumber)) ){
                    if(validateSpecialCharacterPresence(this.state.client.panNumber)==true){
                        
                        clientErMsg.panNumber = "Special character is not allowed.";
                        totClientErrCountTrack.push(clientErMsg.panNumber);
                    }
                    else if((this.state.client.panNumber.length!=10)){
                        clientErMsg.panNumber = "PAN should be of length 10 character ";          
                        totClientErrCountTrack.push(clientErMsg.panNumber);
                      }
                }

                if(this.state.client.gstInNumber!==undefined && (!validateBlankSpace(this.state.client.gstInNumber)) ){

                    if(validateSpecialCharacterPresence(this.state.client.gstInNumber)==true){
                        
                        clientErMsg.gstInNumber = "Special character is not allowed.";
                        totClientErrCountTrack.push(clientErMsg.gstInNumber);
                    } 
                    else if((this.state.client.gstInNumber.length!=15)){
                        clientErMsg.gstInNumber = "GSTIN should be of length 15 character. ";          
                        totClientErrCountTrack.push(clientErMsg.gstInNumber);
                      }
                    else if(clientErMsg.panNumber==""){
                        if((this.state.client.gstInNumber).search(this.state.client.panNumber)==-1){
                            clientErMsg.gstInNumber = "GSTIN should be of proper format. "; 
                            totClientErrCountTrack.push(clientErMsg.gstInNumber);
                        }
                    }
    
                   
    
                }

                if(this.state.client.emailAddress!==undefined && (!validateBlankSpace(this.state.client.emailAddress)) ){
                                    if(validateEmail(this.state.client.emailAddress)!==true){
                                        
                                        clientErMsg.emailAddress = "Improper Email.";
                                        totClientErrCountTrack.push(clientErMsg.emailAddress);
                                    }
                                   
                                }
                
          }

        if(this.state.userPracticeDetails){
            if(this.state.userPracticeDetails.address!==undefined && (validateBlankSpace(this.state.userPracticeDetails.address)==true) ){
                userPracticeDetailsErMsg.address = "Address is required.";
                totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.address);

            }

            if(this.state.userPracticeDetails.lawyerName!==undefined && (validateBlankSpace(this.state.userPracticeDetails.lawyerName)==true) ){
                userPracticeDetailsErMsg.lawyerName = "Professional’s name is required.";
                totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.lawyerName);

            }

            if(this.state.userPracticeDetails.practiceName!==undefined && (validateBlankSpace(this.state.userPracticeDetails.practiceName)==true) ){
                userPracticeDetailsErMsg.practiceName = "Practice name is required.";
                totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.practiceName);

            }

            if(this.state.userPracticeDetails.panNumber!==undefined && (validateBlankSpace(this.state.userPracticeDetails.panNumber)==true) ){
                userPracticeDetailsErMsg.panNumber = "PAN is required.";
                totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.panNumber);

            }
            else{
                if(validateSpecialCharacterPresence(this.state.userPracticeDetails.panNumber)==true){
                    
                    userPracticeDetailsErMsg.panNumber = "Special character is not allowed.";
                    totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.panNumber);
                }
            }

            if(this.state.userPracticeDetails.gstInNumber!==undefined && (!validateBlankSpace(this.state.userPracticeDetails.gstInNumber)) ){

                if(validateSpecialCharacterPresence(this.state.userPracticeDetails.gstInNumber)==true){
                    
                    userPracticeDetailsErMsg.gstInNumber = "Special character is not allowed.";
                    totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.gstInNumber);
                } 
                else if((this.state.userPracticeDetails.gstInNumber.length!=15)){
                    userPracticeDetailsErMsg.gstInNumber = "GSTIN should be of length 15 character. ";          
                    totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.gstInNumber);
                  }
                else if(userPracticeDetailsErMsg.panNumber==""){
                    if((this.state.userPracticeDetails.gstInNumber).search(this.state.userPracticeDetails.panNumber)==-1){
                        userPracticeDetailsErMsg.gstInNumber = "GSTIN should be of proper format. "; 
                        totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.gstInNumber);
                    }
                }

            }

            if(this.state.userPracticeDetails.emailAddress!==undefined && (!validateBlankSpace(this.state.userPracticeDetails.emailAddress)) ){
                
                if(validateEmail(this.state.userPracticeDetails.emailAddress)!==true){
                    
                    userPracticeDetailsErMsg.emailAddress = "Improper Email.";
                    totUsrPractErrCountTrack.push(userPracticeDetailsErMsg.emailAddress);
                }
               
            }




          }

        let descriptions = [...this.state.descriptions];
        let descriptionsErMsg = [...this.state.descriptionsErMsg];

        if(descriptions.length>0){
            Object.keys(descriptions).map((e,i)=>{

                let descRow = {...descriptions[i]};
                let descRowEr = {
                    "amount": "",
                    "code": "",
                    "description": "",
                    "tax": ""
                    };
                
                let descErFound = false;

                // console.log("-----HERE------- descRow=>>",descRow," descRow.descriptions=>>>",descRow.descriptions," validateBlankSpace=>>>",validateBlankSpace(descRow.description));

                if(descRow && descRow.description!==undefined){
                    
                    if((validateBlankSpace(descRow.description)===true)){
                        descRowEr.description = "Description is required.";
                        descErFound = true;
                    }
                    else if((validateBlankSpace(descRow.description)===false) && (descRow.description.length>120)){

                        descRowEr.description = "Exceeded 120 characters.";
                        descErFound = true;
                    }
                    
                   
                }

                if(descRow && descRow.tax!==undefined && (validateBlankSpace(descRow.tax)===true)){
                    
                    descRowEr.tax = "Tax option is required.";
                    descErFound = true;
                 }
                
                if(descRow && descRow.amount!==undefined && (validateBlankSpace(descRow.amount)===true)){
                    
                    descRowEr.amount = "Amount is required.";
                    descErFound = true;
                 }                
                   

                if(descErFound){
                    totDescErrCountTrack.push("error found");  
                }

                descriptionsErMsg[i] = descRowEr;


            });
        }
      

        let stateErData = {
            "invoiceTitleErMsg": invoiceTitleErMsg,
            "invoiceNumberErMsg": invoiceNumberErMsg,
            "notesErMsg":notesErMsg,
            "fileUplErMsg": fileUplErMsg,
            "invoiceDateErMsg": invoiceDateErMsg,
            "invoiceDueDateErMsg": invoiceDueDateErMsg,
            "clientErMsg":clientErMsg,
            "userPracticeDetailsErMsg":userPracticeDetailsErMsg,
            "descriptionsErMsg":descriptionsErMsg,
            "balanceDueErMsg":balanceDueErMsg
            
                        
          }

          console.log(" stateErData=>>>",stateErData);
         
          this.isComponentLoaded && this.setState(stateErData,()=>{

          });
         
      
          if(totErrCountTrack.length>0 || totClientErrCountTrack.length>0 || totUsrPractErrCountTrack.length>0 ||totDescErrCountTrack.length>0 ){          
                return false;
          }
          return true;
    }


   

    openFileUploadDialog = () =>{
        this.fileUpl.current.click();
    }
    
    trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;    
        
        let chkFileValidation =  this.validateSingleFileSelected(fileObj);
        
        //this.calUploadFileService(fileObj); 
        console.log(" chkFileValidation=>>>>",chkFileValidation);
        if(chkFileValidation){
            this.calUploadFileService(fileObj);
        }else{
        
          this.fileUpl.current.value = "";
          
         
        }    
    }
  
    validateSingleFileSelected(fileObj){
        
        const totAllowedMaxFileUploadSize = 5 * 1024 * 1024; // 5 MB
        const allowedFileExt = ['jpg','jpeg','png'];        
                
        const docsData = fileObj; 
        let respdataFlag =  true;
        let stepOneStFileUplErMsg = "";
        if(docsData!==undefined && docsData.length>0){
            let totalInvalidExtAr = [];
            let totCurSize = docsData[0].size; 
            let nameFl = docsData[0].name;
            let extnReceived = nameFl.split('.').pop();
            if(allowedFileExt.indexOf(extnReceived.toLowerCase())==-1){
                totalInvalidExtAr.push(extnReceived);
            }

            if(totalInvalidExtAr.length>0){
                
                    stepOneStFileUplErMsg = "The file type that you tried to upload is not allowed.";
                    respdataFlag = false;
            }

            if( (totCurSize ) > totAllowedMaxFileUploadSize){
                //error
                respdataFlag = false;
                stepOneStFileUplErMsg = "You have crossed maximum upload file size.";                            
            }

        }else{
            respdataFlag = false;
            stepOneStFileUplErMsg = "Something went wrong. Please try again.";
        }   
  
        this.isComponentLoaded && this.setState({ "fileUplErMsg": stepOneStFileUplErMsg});
        
        return respdataFlag;
    }
  
    async calUploadFileService(fileObj){
        console.log("chk file fileObj=>>>",fileObj);


       

        let lserv = new FileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});
        
  
        let taskTypeFlag = 0;

        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj,
            "fileUploadFor":"logoOfFirstPage",
            "fileUploadedFromPageSection":"ManageInvoiceGenerator"
        };
  
  
        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log("success resp=>",data);
            let entityObj  = data.data.entity[0];
  
            let orgLogoObjData  = {"filePathObj":fileObj[0],"logoId":entityObj.id};
            let otherParamData = {"calledFrom":"calUploadFileService"};
            this.setProfileImageState(orgLogoObjData,otherParamData);
           
  
  
        }).catch((e)=>{
                ////console.log.log("server error=>",e);
        }).finally(()=>{
            this.fileUpl.current.value = "";  
            this.isComponentLoaded && this.setState({"uploadingFlag":false});
        });

        
    }

    setProfileImageState = (orgLogoObj,otherParam) =>{
        let filePath = null;

        if(otherParam.calledFrom=="calUploadFileService"){
          if(orgLogoObj!==null && orgLogoObj!==undefined){
            let file  = orgLogoObj.filePathObj;
           
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
              this.setState({
                filePath: [reader.result],
                logoId: orgLogoObj.logoId
              },()=>{
                //console.log(" after uplaod gets done=>>>>>>>>>>",this.state,orgLogoObj)
              })
            }.bind(this);
          }
        }
       

        

    }

    customDate = (defaultDate,dtKey,placeholdertxt,datefor)=>{        
           
        return (
          <DatePicker
            selected={defaultDate}
            onChange={date => {this.setCalDate(date,datefor)}}
            calendarClassName="customtaskaddclassstep1calcls"         
            customInput={<ExampleCustomInput keydata={dtKey} placeholdertxt={placeholdertxt}   />}
            dateFormat={getDateFormatForInvGenCal({"countryCode":"IN"})}
            maxDate={new Date()}
            
            
          />
        );
    }

    customDueDate = (defaultDate,dtKey,placeholdertxt,datefor)=>{        
           
        return (
          <DatePicker
            selected={defaultDate}
            onChange={date => {this.setCalDate(date,datefor)}}
            calendarClassName="customtaskaddclassstep1calcls"         
            customInput={<ExampleCustomInput keydata={dtKey} placeholdertxt={placeholdertxt}   />}
            dateFormat={getDateFormatForInvGenCal({"countryCode":"IN"})}
            minDate={new Date()}
            
          />
        );
    }


    setCalDate=(dt,dateFor)=>{            

        dt = getTimestampInMillisecondsFromDateObj(dt);
        if(dateFor=="invoiceDate"){
          this.isComponentLoaded && this.setState({"invoiceDate":dt},()=>{
            //console.log(" after dateFrom set this.state=>",this.state);
          });
  
        }
        else if(dateFor=="dueDate"){
          this.isComponentLoaded && this.setState({"dueDate":dt},()=>{
            //console.log(" after dateTo set this.state=>",this.state);
            this.isComponentLoaded && this.setState({"dueOnRceipt":false});
          });
        }
  
    }

    setInvoiceNumber(data,calledFrom){
        this.isComponentLoaded && this.setState({"invoiceNumber":data});
         


    }

    invMainCalCurrencyOptionChangeTrack = (e) =>{
        let currencyValue = e.target.value;
        let searchIn =  currencyStaticData;
        let findObj = findElement(searchIn,"key",currencyValue);
        
        let currSmallUnit = findObj!==null? findObj.objData.currSmallUnit:"";
        let curUnitSingular = findObj!==null? findObj.objData.curUnitSingular:"";
        let curUnitPlural = findObj!==null? findObj.objData.curUnitPlural:"";

        this.isComponentLoaded && this.setState({
            "currencyType": currencyValue,
            "currSmallUnit": currSmallUnit,
            "curUnitSingular": curUnitSingular,
            "curUnitPlural": curUnitPlural,

        },()=>{

            // update balance due when currency changed
            this.calculateToDisplay({"calledFrom":"invMainCalCurrencyOptionChangeTrack"});


        });
 
    }

    receiverMobileInputChangeTrack = (value, country, e, formattedValue) =>{
        //console.log("from receiverMobileInputChangeTrack value=>",value, " country=>>",country," e=>>",e, " formattedValue=>>>",formattedValue);
        
        let phoneNumberExtracted = "";
        if(country.dialCode){
            let dialCodeLen = (country.dialCode).length;
            phoneNumberExtracted = value.slice((dialCodeLen));
        }

        let clientObj =  {...this.state.client};
        clientObj.phonePlugin = value;
        clientObj.phoneNumber = phoneNumberExtracted;
        //clientObj.countryName = country.name;
        clientObj.countryDialCode = country.dialCode;
        clientObj.countryCode = country.countryCode;

        
        this.isComponentLoaded && this.setState({
               "client": clientObj

        });
    
    }

    senderMobileInputChangeTrack = (value, country, e, formattedValue) =>{
        //console.log("from senderMobileInputChangeTrack value=>",value, " country=>>",country," e=>>",e, " formattedValue=>>>",formattedValue);
        
        let phoneNumberExtracted = "";
        if(country.dialCode){
            let dialCodeLen = (country.dialCode).length;
            phoneNumberExtracted = value.slice((dialCodeLen));
        }

        let userPracticeDetailsObj =  {...this.state.userPracticeDetails};
        userPracticeDetailsObj.phonePlugin = value;
        userPracticeDetailsObj.phoneNumber = phoneNumberExtracted;
        //userPracticeDetailsObj.countryName = country.name;
        userPracticeDetailsObj.countryDialCode = country.dialCode;
        userPracticeDetailsObj.countryCode = country.countryCode;

        
        this.isComponentLoaded && this.setState({
               "userPracticeDetails": userPracticeDetailsObj

        });
    
    }

    senderDataChangeTrack = (keyName, keyValue, otherParam) =>{
      
        // Note: phoneNumber is managed in this method => senderMobileInputChangeTrack
        let calledFrom = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";      

        let userPracticeDetailsObj =  {...this.state.userPracticeDetails};
        if(keyName=="address"){
            userPracticeDetailsObj.address = keyValue;
        }       
         else if(keyName=="emailAddress"){
            userPracticeDetailsObj.emailAddress = keyValue;
        }
        else if(keyName=="gstInNumber"){
            userPracticeDetailsObj.gstInNumber = keyValue;
        }
         else if(keyName=="lawyerName"){
            userPracticeDetailsObj.lawyerName = keyValue;
        }
         else if(keyName=="panNumber"){
            userPracticeDetailsObj.panNumber = keyValue;
        }
         else if(keyName=="practiceName"){
            userPracticeDetailsObj.practiceName = keyValue;
        }
        
        this.isComponentLoaded && this.setState({
               "userPracticeDetails": userPracticeDetailsObj

        });
    
    }

    receiverDataChangeTrack = (keyName, keyValue, otherParam) =>{
      
        // Note: phoneNumber is managed in this method => senderMobileInputChangeTrack
        let calledFrom = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";      

        let clientObj =  {...this.state.client};
        if(keyName=="address"){
            clientObj.address = keyValue;
        }   
        else if(keyName=="clientName"){
            clientObj.clientName = keyValue;
        } 
        else if(keyName=="contactName"){
            clientObj.contactName = keyValue;
        } 
         else if(keyName=="emailAddress"){
            clientObj.emailAddress = keyValue;
        }
        else if(keyName=="gstInNumber"){
            clientObj.gstInNumber = keyValue;
        }         
         else if(keyName=="panNumber"){
            clientObj.panNumber = keyValue;
        }
         else if(keyName=="placeOfSupply"){
            clientObj.placeOfSupply = keyValue;
        }
        
        this.isComponentLoaded && this.setState({
               "client": clientObj

        },()=>{
                if(keyName=="clientName"){
                    if(validateBlankSpace(keyValue)){
                        let clientAddNewButtonClickFlag = false;
                        this.isComponentLoaded && this.setState({"clientAddNewButtonClickFlag":clientAddNewButtonClickFlag});
                    }
                        
                } 
            
        });
    
    }
   

    loadDescCalRows = () =>{
        let descRows = [];
        let getGstVatStaticOptionsData = getGstVatStaticOptions();

        if(this.state.descriptions && this.state.descriptions.length>0){
            Object.keys(this.state.descriptions).map((e,i)=>{
                let rowObj = (<div className="descTab_row">
                <div id={"desc-row-id-"+i} key={i} className="descTab_row_tbody">
                    <div className="descTab_col descT_box1">
                    <div className="descT_box_area">
                        <label>Description</label>
                        <div  className={`descT_box_cont descT_box_cont_textarea ${this.state.descriptionsErMsg[i].description?"form_group_error":""} `} >
                            <textarea maxLength="120" key={"description-k-"+{i}} onChange={(evn)=>{this.descBlockDataChangeTrack("description",evn.target.value,i)}} name="description" key={"desc-"+i} value={this.state.descriptions[i].description} />
                          
                           
                        <span className="error_span">
                            {this.state.descriptionsErMsg[i].description}                        
                        </span>
                        </div>
                    </div>
                    </div>
                    <div className="descTab_col descT_box2">
                    <div className="descT_box_area">
                        <label>
                        Code{" "}
                        <span className="orange_text_Label">
                            (Optional)
                        </span>
                        </label>
                        <div className="descT_box_cont descT_box_cont_normalInput ">
                        <input
                            type="text"
                            placeholder=""
                            name="code"
                            key={"code-k-"+{i}}
                            value={this.state.descriptions[i].code}
                            onChange={(evn)=>{this.descBlockDataChangeTrack("code",evn.target.value,i)}}
                            maxLength="10"                    
                            autocomplete="off"
                            
                        />
                        <span className="error_span">
                            
                        </span>
                        </div>
                    </div>
                    </div>
                    <div className="descTab_col descT_box3">
                    <div className="descT_box_area">
                        <label>GST / VAT</label>
                        <div className={`descT_box_cont descT_box_cont_select ${this.state.descriptionsErMsg[i].tax?"form_group_error":""} `} >
                        <select name="tax" key={"tax-k-"+{i}} value={this.state.descriptions[i].tax} 
                                                onChange={(evn)=>{this.descBlockDataChangeTrack("tax",evn.target.value,i)}} >
                                                    {Object.keys(getGstVatStaticOptionsData).map((e,i)=> <option key={i}  value={getGstVatStaticOptionsData[e].key}>{getGstVatStaticOptionsData[e].value}</option>) }
                        </select>
                        <span className="error_span">
                        {this.state.descriptionsErMsg[i].tax} 
                        </span>
                        </div>
                    </div>
                    </div>
                    <div className="descTab_col descT_box4">
                    <div className="descT_box_area">
                        <div className="desT_ammount_label">
                        {/* 
                                                        <select>
                                                            <option>INR</option>
                                                            <option>Dollar</option>
                                                            <option>Pound</option>
                                                        </select>
                                                        */}
                        <div className="desT_am_labl">Amount</div>
                        </div>
                        <div className={`descT_box_cont descT_box_contgrupInput ${this.state.descriptionsErMsg[i].amount?"form_group_error":""} `} >
                        <div className="desT_tab_ammountEntry_box">
                            <div className="AmuntEnt_inr">
                                {this.state.currencyType}
                            </div>
                            <div className={`desT_tabAmuntEnt_input`} >
                            <input
                                placeholder=""
                                name="amount"
                                key={"amount-k-"+{i}}
                                value={this.state.descriptions[i].amount}
                                onChange={(evn)=>{this.descBlockDataChangeTrack("amount",evn.target.value,i)}}
                                maxLength="10"                    
                                autocomplete="off"
                            />
                            </div>
                        </div>
                        <span className="error_span">
                        {this.state.descriptionsErMsg[i].amount} 
                        </span>
                        </div>
                    </div>
                    </div>
                    <div className="clearboth" />
                </div>
                <div className="clearboth" />
                </div>);

                descRows.push(rowObj);

            });
        }

        return (<>{descRows}</>);
    }

    addRowInDesc = () =>{
        let addNewDescRowEmptyObj = {
            "amount": "",
            "code": "",
            "description": "",
            "tax": ""
            };
        let descNewErRowEmptyObj = {...addNewDescRowEmptyObj};    


        console.log("prev rdata=>>>",this.state.descriptions," addNewDescRowEmptyObj=>>>>>",addNewDescRowEmptyObj);
        
        let descriptionsDataObj = [...this.state.descriptions,addNewDescRowEmptyObj];
        let descriptionsErDataObj = [...this.state.descriptionsErMsg,descNewErRowEmptyObj];

       this.isComponentLoaded && this.setState({"descriptions":descriptionsDataObj,"descriptionsErMsg":descriptionsErDataObj});
    }

    handleChange = (keyName, keyValue,  otherParam) => {
        let keyValueData =  keyValue;
        let eventName = otherParam.eventName!==undefined?otherParam.eventName:"";
        let calledFrom = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";

        if(keyName=="outOfPocketAmount"){
            if(keyValue){               
                
                if(validatePositiveInputNumber({"data":keyValue})){
                    keyValueData =  keyValue;
                }else{
                    keyValueData =  this.state.outOfPocketAmount;
                }
            }else{
                keyValueData =  keyValue;
            }
        }
        else if(keyName=="advancePaidAmount"){
            
            if(keyValueData){               
                
                if(validatePositiveInputNumber({"data":keyValue})){
                    if(this.state.totalAmount && parseFloat(this.state.totalAmount)>=keyValue){
                        keyValueData =  keyValue;
                    }else{
                        keyValueData =  this.state.advancePaidAmount;
                    }   
                    
                }else{
                    keyValueData =  this.state.advancePaidAmount;
                }
            }
            
        }
        else if(keyName=="discountAmount"){
            if(keyValue){        
                     
                
                if(validatePositiveInputNumber({"data":keyValue})){
                    let totalAmountFromState = this.state.totalAmount?this.state.totalAmount:0;
                    if(keyValue<=totalAmountFromState){
                        keyValueData =  keyValue;
                    }else{
                        keyValueData =  this.state.discountAmount;
                    }
                    
                }else{
                    keyValueData =  this.state.discountAmount;
                }
            }else{
                keyValueData =  keyValue;
            }
        }
        else if(keyName=="discountPercentage"){
            
            if(keyValueData){               
                
                if(validatePercentInputNumber({"data":keyValue})){
                    if(keyValue && keyValue<=100){
                        keyValueData =  keyValue;
                    }else{
                        keyValueData =  this.state.discountPercentage;
                    }
                    
                }else{
                    keyValueData =  this.state.discountPercentage;
                }
            }
            
        }
        else if(keyName=="tdsPercentage"){
            
            if(keyValueData){               
                
                if(validatePercentInputNumber({"data":keyValue})){
                    if(keyValue && keyValue<=100){
                        keyValueData =  keyValue;
                    }else{
                        keyValueData =  this.state.tdsPercentage;
                    }
                    
                }else{
                    keyValueData =  this.state.tdsPercentage;
                }
            }
            
        }


      

        this.isComponentLoaded &&  this.setState({[keyName]: keyValueData},()=>{
            if(keyName=="outOfPocketAmount"){
                    this.calculateToDisplay({"calledFrom":"handleChange"});
            }
            else if(keyName=="advancePaidAmount"){
                    this.calculateToDisplay({"calledFrom":"handleChange"});
            }
            else if(keyName=="discountPercentage"){
                    
                    this.changeDiscPerceAndAmountData(keyName, keyValue, {"calledFrom":"handleChange"});
                   
                    
            }
            else if(keyName=="discountAmount"){                
                    this.changeDiscPerceAndAmountData(keyName, keyValue, {"calledFrom":"handleChange"});
                   
            }
            else if(keyName=="tdsPercentage"){
                    
                    this.changeTdsPerceAndAmountData(keyName, keyValue, {"calledFrom":"handleChange"});
            
            }
            else if(keyName=="matterName"){
                if(validateBlankSpace(keyValue)){
                    let matterNameAddNewButtonClickFlag =  false;
                    this.isComponentLoaded && this.setState({"matterNameAddNewButtonClickFlag":matterNameAddNewButtonClickFlag, "matterNameList":[],"matter": { 
                        "id": 0,
                        "name": ""
                      }},()=>{
                    });

                }
            }

           





        }); 
    }

    changeDiscPerceAndAmountData = (keyName, keyValue, otherParam) =>{
        let calledFrom =  otherParam.calledFrom!==undefined?otherParam.calledFrom:"";
        
        
        
        
        if(calledFrom=="handleChange"|| calledFrom=="updateDiscountAmount"){
            let totalAmount =  this.state.totalAmount?this.state.totalAmount:0;

            let discountPercentage =  this.state.discountPercentage?this.state.discountPercentage:0;
            let discountAmount =  this.state.discountAmount?this.state.discountAmount:0;
            
            if(keyName=="discountAmount"){

                let perCal = totalAmount? parseFloat((discountAmount/totalAmount)*100):0;                
                perCal = perCal.toFixed(2);
                perCal =  parseFloat(perCal);
                this.isComponentLoaded && this.setState({"discountPercentage":perCal},()=>{
                    this.calculateToDisplay({"calledFrom":"handleChange"});
                });

                
            }

            if(keyName=="discountPercentage"){
                
                 let discCal = parseFloat((discountPercentage/100)*totalAmount);                
                 discCal = discCal.toFixed(2);
                 discCal =  parseFloat(discCal);
                 this.isComponentLoaded && this.setState({"discountAmount":discCal},()=>{
                    this.calculateToDisplay({"calledFrom":"handleChange"});
                });
            }

        }

    }

    changeTdsPerceAndAmountData(keyName, keyValue, otherParam) {
        let calledFrom =  otherParam.calledFrom!==undefined?otherParam.calledFrom:"";
        
        if(calledFrom=="handleChange"||calledFrom=="updateTdsAmount"){
            let totalAmount =  this.state.totalAmount?this.state.totalAmount:0;
            let tdsPercentage =  this.state.tdsPercentage?this.state.tdsPercentage:0;
            let tdsAmount =  this.state.tdsAmount?this.state.tdsAmount:0;
            
            // if(keyName=="tdsAmount"){                
            //     let perCal = parseFloat((tdsAmount/totalAmount)*100);                
            //     perCal = perCal.toFixed(2);
            //     perCal =  parseFloat(perCal);
            //     this.isComponentLoaded && this.setState({"tdsPercentage":perCal});                
            // }

            if(keyName=="tdsPercentage"){
                    let tdsCal = parseFloat((tdsPercentage/100)*totalAmount);                
                    tdsCal = tdsCal.toFixed(2);
                    tdsCal =  parseFloat(tdsCal);
                    this.isComponentLoaded && this.setState({"tdsAmount":tdsCal},()=>{
                        this.calculateToDisplay({"calledFrom":"handleChange"});
                    });
            }

        }

    }

    // desc block track change
    descBlockDataChangeTrack = async (keyName,keyValue,indxNumToUpd) =>{
        

        let descRowsObj =  [...this.state.descriptions];
        let rowToUpdObj =  descRowsObj[indxNumToUpd];
        if(rowToUpdObj!==undefined && rowToUpdObj!==null){
            if(keyName=="description"){
                rowToUpdObj.description =  keyValue;
            }
            if(keyName=="tax"){
                rowToUpdObj.tax =  keyValue;
            }
            if(keyName=="code"){
                rowToUpdObj.code =  keyValue;
            }
            if(keyName=="amount"){
                
                if(keyValue){
                    let chk = validatePositiveInputNumber({"data":keyValue});
                    //console.log(" chk=>>>",chk);
                    if(chk){

                        rowToUpdObj.amount =  keyValue;
                    }else{
                        rowToUpdObj.amount = rowToUpdObj.amount;
                    }
                }else{
                    rowToUpdObj.amount =  keyValue;
                }
               
                
            }

                
            descRowsObj[indxNumToUpd] = rowToUpdObj;   
            
            this.isComponentLoaded && this.setState({"descriptions":descRowsObj},()=>{
               
                // if(keyName=="tax" && keyValue=="Reverse Charge GST"){
                //     let specialNote = this.state.specialNote;
                //     this.handleChange("notes",specialNote, {"eventName":"onChange"});
                // }

                this.calculateToDisplay({"calledFrom":"descBlockDataChangeTrack"}); 
                setTimeout(()=>{
                    this.updateDiscountAmount({});
                    this.updateTdsAmount({});

                },1100);
                
               
                

            });

            

        }

    }

    updateDiscountAmount = (otherParam)=>{
        this.changeDiscPerceAndAmountData("discountPercentage", this.state.discountPercentage, {"calledFrom":"updateDiscountAmount"});
    }

    updateTdsAmount = (otherParam) =>{
        this.changeTdsPerceAndAmountData("tdsPercentage", this.state.tdsPercentage, {"calledFrom":"updateTdsAmount"});
    }



    // main calculation block method
    calculateToDisplay = (otherParam) => {
        let descRowsObj =  [...this.state.descriptions];
        let balanceDueDetailsObj = {...this.state.balanceDueDetails};
        let outOfPocketAmountData = (this.state.outOfPocketAmount!==undefined && this.state.outOfPocketAmount)? parseFloat(this.state.outOfPocketAmount) : 0;
        let advancePaidAmountData = (this.state.advancePaidAmount!==undefined && this.state.advancePaidAmount)? parseFloat(this.state.advancePaidAmount) : 0;

        let discountPercentageData = (this.state.discountPercentage!==undefined && this.state.discountPercentage)? parseFloat(this.state.discountPercentage) : 0;

        let discountAmountData = (this.state.discountAmount!==undefined && this.state.discountAmount)? parseFloat(this.state.discountAmount) : 0;

         let tdsAmountData = (this.state.tdsAmount!==undefined && this.state.tdsAmount)? parseFloat(this.state.tdsAmount) : 0;


        let totalAmount = 0;
        let goodServiceTax =0 ;
        let indirectTaxAmount  = 0;
        let reverseGstTrackFlag = false;
        Object.keys(descRowsObj).map((e,i)=>{
                    
                    let taxOption =  descRowsObj[i].tax;

                    let amountData = (descRowsObj[i].amount!==undefined && descRowsObj[i].amount)? parseFloat(descRowsObj[i].amount) : 0;                   

                    if(amountData){
                        amountData = (amountData).toFixed(2); 
                        totalAmount = parseFloat(totalAmount) + parseFloat(amountData); 
                        totalAmount = totalAmount.toFixed(2);
                        
                        totalAmount = parseFloat(totalAmount);
                    }

                    if(taxOption){
                        let goodServiceTaxCal = 0;
                        if(taxOption == "9% CGST 9% SGST"){
                             goodServiceTaxCal =  parseFloat(((18/100)*amountData).toFixed(2));
                            goodServiceTax = goodServiceTax + goodServiceTaxCal;
                        }
                        else if(taxOption == "18% IGST"){
                             goodServiceTaxCal =  parseFloat(((18/100)*amountData).toFixed(2));
                            goodServiceTax = goodServiceTax + goodServiceTaxCal;
                        }
                        else if(taxOption == "Reverse Charge GST"){
                            reverseGstTrackFlag =  true;
                        }

                        goodServiceTax = parseFloat(goodServiceTax);
                        goodServiceTax = goodServiceTax.toFixed(2);
                        goodServiceTax =  parseFloat(goodServiceTax);
                        
                    }
                    


        });

       

        let notesData = "";
        let specialNote = this.state.specialNote;
        if(reverseGstTrackFlag){
            notesData = this.state.specialNote;
        }else{
            if(this.state.notes == specialNote){
                notesData = "";
            }

        }
       

        indirectTaxAmount = goodServiceTax;
        
        outOfPocketAmountData = (outOfPocketAmountData).toFixed(2);
        outOfPocketAmountData =  parseFloat(outOfPocketAmountData);        

         
        discountAmountData = (discountAmountData).toFixed(2);
        discountAmountData =  parseFloat(discountAmountData);

        tdsAmountData = (tdsAmountData).toFixed(2);
        tdsAmountData =  parseFloat(tdsAmountData);

        let discountApplied = discountAmountData;      
        let tdsAmountApplied = tdsAmountData;
        

        // console.log(
        //     " totalAmount=>",totalAmount,
        //     " indirectTaxAmount=>",indirectTaxAmount,
        //     " outOfPocketAmountData=>",outOfPocketAmountData,
        //     " advancePaidAmountData=>",advancePaidAmountData,
        //      " discountApplied=>",discountApplied,
        //     " tdsAmountApplied=>",tdsAmountApplied
        //     );
        
        let dueAmountData =  (totalAmount + indirectTaxAmount + outOfPocketAmountData) - (advancePaidAmountData + discountApplied + tdsAmountApplied) ; 
        
        dueAmountData = dueAmountData.toFixed(2);
        //dueAmountData = parseFloat(dueAmountData);

        

        balanceDueDetailsObj.dueAmount = dueAmountData;

        let dueAmountDataWords = ""; //this.getAmountToWords(dueAmountData);
        
        // const toWords = new ToWords({
        //     localeCode: 'en-US',
        //     // converterOptions: {
        //     //    currency: false,
        //     //   ignoreDecimal: false,
        //     //   ignoreZeroCurrency: false,
        //     // }
        //   });
        // dueAmountDataWords = toWords.convert(dueAmountData);

        balanceDueDetailsObj.dueAmountInWords =  dueAmountDataWords;

        
        this.isComponentLoaded && this.setState(
            {
            "totalAmount":totalAmount.toFixed(2),
             "balanceDueDetails":balanceDueDetailsObj,
             "indirectTaxAmount": indirectTaxAmount,
             "notes": notesData
            
            },()=>{
            //console.log(" HELLO this.state=>>>>>", this.state);
        });

    }

    addCurrencyToDueAmountDispaly = (figure,otherParam) => {

        let figureSplitAr = figure.toString().split(".");
        let figureNonDecimal = 0; let figureDecimal = 0;
        if(figureSplitAr.length>0){
            figureNonDecimal = parseInt(figureSplitAr[0]);   
            figureDecimal = parseInt(figureSplitAr[1]);            
        }

       
    }

    getSplitFigureObj = (figure,otherParam) => {

        let figureSplitAr = figure.toString().split(".");        
        let figureNonDecimal = 0; let figureDecimal = 0;
        if(figureSplitAr.length>0){
            figureNonDecimal = figureSplitAr[0]!==undefined? parseInt(figureSplitAr[0]):0;   
            figureDecimal = figureSplitAr[1]!==undefined? parseInt(figureSplitAr[1]):0;            
        }

        return {"figureNonDecimal":figureNonDecimal,"figureDecimal":figureDecimal};

       
    }

    getAmountToWords = (dueAmountData,otherParam) => {
             // now split to non decimal and decimal 
        let splitFigureObj = this.getSplitFigureObj(dueAmountData);
        
        let curUnitPlural = this.state.curUnitPlural?this.state.curUnitPlural:"";
        let curUnitSingular = this.state.curUnitSingular?this.state.curUnitSingular:"";
        let currSmallUnit = this.state.currSmallUnit?this.state.currSmallUnit:"";

        let dueAmountDataWords = ""; 
        let dueAmountDataNonDeciWords = "";
        let dueAmountDataDeciWords = "";

        if(splitFigureObj.figureNonDecimal>0){
            dueAmountDataNonDeciWords = splitFigureObj.figureNonDecimal>0?this.capitalizeWords(numberWords.convert(splitFigureObj.figureNonDecimal)):"";

            if(splitFigureObj.figureNonDecimal==1){                
                dueAmountDataNonDeciWords = dueAmountDataNonDeciWords + " "+ curUnitSingular;

            }
            else if(splitFigureObj.figureNonDecimal>1){
                dueAmountDataNonDeciWords = dueAmountDataNonDeciWords + " "+ curUnitPlural;
            }
                
        }

        if(splitFigureObj.figureDecimal>0){
            dueAmountDataDeciWords = splitFigureObj.figureDecimal>0?this.capitalizeWords(numberWords.convert(splitFigureObj.figureDecimal)):"";

            dueAmountDataDeciWords = dueAmountDataDeciWords + " "+ currSmallUnit;
                
        }

        dueAmountDataWords = dueAmountDataNonDeciWords + " "+ dueAmountDataDeciWords;

        return dueAmountDataWords;

    }


    capitalizeWords(str){
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    triggerAddNewClientButton = (otherParam) => {
        let clientAddNewButtonClickFlag =  true;
        this.isComponentLoaded && this.setState({"clientAddNewButtonClickFlag":clientAddNewButtonClickFlag},()=>{
            this.commonReset({"resetFor":"resetReceiverClient"});
        });
    }

    triggerAddNewMatterButton = (otherParam) => {
        let matterNameAddNewButtonClickFlag =  true;
        this.isComponentLoaded && this.setState({"matterNameAddNewButtonClickFlag":matterNameAddNewButtonClickFlag},()=>{
            this.commonReset({"resetFor":"resetMatter"});
        });
    }



    commonReset = (otherParam) => {

        let resetFor = otherParam.resetFor!==undefined?otherParam.resetFor:"";

        switch(resetFor){
            case "resetReceiverClient":
                {
                    
                         // for client fields
                        let initialClientData = {
                            "id":"",
                            "address": "",
                            "clientName": "",
                            "contactName": "",
                            "emailAddress": "",
                            "gstInNumber": "",
                            "panNumber": "",
                            "phoneNumber": "",
                            "placeOfSupply":"",
                            "countryCode": "in",
                            "countryDialCode": "91",
                            //other
                            "phonePlugin" : "91", // for plugin internal
                            
                            
                           
                        };

                           
    
                            // for client fields error message
                            let initialClientErrData = {
                                "address": "",
                                "clientName": "",
                                "contactName": "",
                                "emailAddress": "",
                                "gstInNumber": "",
                                "panNumber": "",
                                "phoneNumber": "",
                                "placeOfSupply":"",
                            };
    
                        this.isComponentLoaded && this.setState({
                            "client":initialClientData,
                            "clientErMsg":initialClientErrData
                        });
                        break;
                }
            case "resetMatter":
                {
                    let resMatInitial =  {  
                        "id": 0,
                        "name": ""
                    };

                    this.isComponentLoaded && this.setState({
                        "matter":resMatInitial,
                        "matterName":"",
                        "matterNameErMesg":""
                    });
                    break;
                }

            default:
                break;


        }

       
        

    }
    resetClick = () => {
            this.setDataToState({"calledFrom":"resetClick"});
    }

    dueOnRceiptClick = (evn) =>{
        //evn.preventDefault();
        let checkedTrack = this.state.dueOnRceipt;
        let chngDueDateData = this.state.dueDate;
        let dueOnRceipt = !(checkedTrack);
        
        if(dueOnRceipt===true){
            chngDueDateData = null;                                                       
        }
       
       
        this.isComponentLoaded && this.setState({"dueDate":chngDueDateData,"dueOnRceipt":dueOnRceipt},()=>{
           // console.log("BB dueDate=>>>>",this.state.dueDate)
        });
    }

    render() {

        let invoiceDate = this.state.invoiceDate;
        let invoiceDateData = invoiceDate!==null?getDateObjFromMilliseconds(invoiceDate):null;

        let dueDate = this.state.dueDate;
        let dueDateData = dueDate!==null?getDateObjFromMilliseconds(dueDate):null;
        

        
        return (
            <>  
            <div  >
                {/* <Prompt
                    when={this.state.navigateDisAllowFlag}
                    message={location => `Are you sure you want to leave?`}
                /> */}
                <CustomMessageBar />
                {/* Dashboard Main Screen content area 01 start */}
                <ManageInvoiceGeneratorGenericHeader activetabname="newinvoicegeneratortab" />
                {/* Dashboard Main Screen content area 01 end */}
                {/* Dashboard Main Screen content area 02 start */}
                <div  className="kyc_section_part_02">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg InvGen_mainfromBox_bg InvGen_mainfromBox_bg_lp">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting cutom_container_inGenBg">
                        {/* ===========  Setting Page main body start =============   */}
                        {/* ===  task Listing start start === */}
                        {/* === User Setting Section / Task Listing Section Start === */}
                        {/* ============== Start =================   */}
                        {/* -----  scroll adjust for settting screens start -----   */}
                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting invGen_fromScroll_sec">
                            {/* ==============   User Setting Section Start =================   */}
                            <div className="userSetting_area_bg userSetting_area_bg_invGen">
                            {/* ==--== Invoice Generate Notification section start ==--== */}
                            <div className="InvGen_top_noti_bg">
                                <div className="kyc_top_noti_box InvGen_kyc_top_noti_box">
                                <p>
                                    All fields are mandatory except for the one’s marked{" "}
                                    <span className="orange_text_Label">(Optional)</span>
                                </p>
                                </div>
                            </div>
                            {/* ==--== Invoice Generate Notification section end ==--== */}
                            {/* ===== ** Invoice Generate main form body start =====  */}
                            <div className="invGen_mainFrom_section_bg">
                                <div className="invGen_mainFrom_box">
                                {/* ---- Inv Gen from section start ---  */}
                                {/* ---- Inv Gen from top title & switch button part start ---  */}
                                <div className="row">
                                    <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 col-invGenTitle_top">
                                    <div className="invGenTitle_top">
                                        <div className="form_block">
                                        <div className={`form__group field ${this.state.invoiceTitleErMsg?"form_group_error":""}`}>
                                            <input
                                            type="input"
                                            className="form__field"
                                            placeholder=""
                                            value={this.state.invoiceTitle}
                                            onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("invoiceTitle",evn.target.value, {"eventName":"onChange"});
                                            }}
                                            name="invoiceTitle"
                                         ref={(input) => { this.invoiceTitleRef = input; }} 
                                            />
                                            <span className="error_span">{this.state.invoiceTitleErMsg}</span>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 col-invGenTitle_top">
                                    <div className="top_filter top_filter_3">
                                        <div className="task_top_suggestions toggle_btn_suggestion InvGen_mandatoryFields_btn">
                                        {/* <span className="top_sug_left">
                                            <p>Show only mandatory fields</p>
                                            <div className="switch_btn">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round" />
                                            </label>
                                            </div>
                                            <p />
                                        </span> */}
                                        <div className="clearboth" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {/* ---- Inv Gen from top title & switch button part end ---  */}
                                {/* ---- Inv Gen from top part strat ---  */}
                                <div className="invGen_topPart_bg">
                                    <div className="invGen_topPart invGen_topPart_left">
                                    <div className="user_kyc_img_boxBbg invGen_add_logoBox">
                                        <div className="user_kyc_img">
                                        {this.state.filePath && (
                                          <img ref={this.uploadedFileRef} src={this.state.filePath}  />

                                        )}
                                        { (!(this.state.logoBase64)||!(this.state.logoMimeType)) && (this.state.filePath===null || this.state.filePath=="")  && (
                                          <img ref={this.uploadedFileRef} src={getBaseUrl()+"assets/images/user-avatar-thumb.jpg"}  />

                                        )}
                                        {!(this.state.filePath) && this.state.logoBase64 && this.state.logoMimeType && (
                                          <img ref={this.uploadedFileRef} src={"data:"+this.state.logoMimeType+";base64,"+this.state.logoBase64}  />

                                        )}

                                        
                                        
                                        <button onClick={(evn)=>{
                                          evn.preventDefault();
                                          this.openFileUploadDialog();
                                        }} className="btn_appearance_none user_kyc_imgBtn">
                                            Upload
                                        </button>
                                        <input style={{"display":"none"}} ref={this.fileUpl}  onChange={this.trackFileSelection} type="file" name="files"  />
                                        </div>
                                        <div className="invgen_logo_text">
                                        <h5>Add logo <span className="orange_text_Label">(Optional)</span>  </h5>
                                        {/* <p>(Max file size - 5mb)</p> */}
                                        <p>
                                            ( Allowed file types: PNG,JPG,JPEG | Max file size - 5 MB
                                            )
                                        </p>
                                        <span className="error_span">{this.state.fileUplErMsg}</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="invGen_topPart invGen_topPart_right">
                                    <div className="invGen_topRightFromsec">
                                        <div className="invGen_topRightFrom_row">
                                        <div className="invGen_topRightFrom_col_left">
                                            <label>Invoice Date:</label>
                                        </div>
                                        <div className="invGen_topRightFrom_col_right">
                                            <div className={`form__group field ${this.state.invoiceDateErMsg?"form_group_error":""}`}>
                                            <div
                                                className="input-group date"
                                                id="sandbox-container"
                                            >
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    {/* <input
                                                    type="text"
                                                    className="form__field customdatefld"
                                                    placeholder="DD/MM/YYYY"
                                                    defaultValue
                                                    /> */}
                                                    {this.customDate(invoiceDateData,'dtKey1','DD/MM/YYYY',"invoiceDate")}
                                                </div>
                                                </div>
                                            </div>
                                                <span className="error_span">{this.state.invoiceDateErMsg}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        {/* <div className="invGen_topRightFrom_row">
                                        <div className="invGen_topRightFrom_col_left">
                                            <label>Tax Invoice Number:</label>
                                        </div>
                                        <div className="invGen_topRightFrom_col_right">
                                            <div className="form__group field form_group_error">
                                            <div className="input_datashow">
                                                <p>{this.state.invoiceNumber!==null?this.state.invoiceNumber:"Not Generated"}</p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div> */}

                                        
                                        <div className="invGen_topRightFrom_row" >
                                                    <div className="invGen_topRightFrom_col_left">
                                                        <label>Tax Invoice Number:</label>
                                                    </div>
                                                    <div className="invGen_topRightFrom_col_right">
                                                        <div className={`form__group field ${this.state.invoiceNumberErMsg?"form_group_error":""}`}>
                                                            <input type="input" className="form__field"
                                                             placeholder="Tax Invoice Number" value={this.state.invoiceNumber!==null?this.state.invoiceNumber:""} 
                                                             name="invoiceNumber"
                                                             onChange={(evt)=>{
                                                                 evt.preventDefault();
                                                                 let invNmbData = evt.target.value;

                                                                 this.setInvoiceNumber(invNmbData,"invoiceNumber");

                                                             }}

                                                             maxLength="10"
                                                             />
                                                            <span className="error_span">
                                                            {this.state.invoiceNumberErMsg}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="clearboth"></div>
                                        </div>
                                        
                                        
                                        <div className="invGen_topRightFrom_row">
                                        <div className="invGen_topRightFrom_col_left">
                                            <label>
                                            Due Date{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        <div className="invGen_topRightFrom_col_right">
                                            <div className={`form__group field ${this.state.invoiceDueDateErMsg?"form_group_error":""}`}>
                                            <div
                                                className="input-group date"
                                                id="sandbox-container"
                                            >
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    {/* <input
                                                    type="text"
                                                    className="form__field customdatefld"
                                                    placeholder="DD/MM/YYYY"
                                                    defaultValue
                                                    /> */}

                                                    {this.customDueDate(dueDateData,'dtKey2','DD/MM/YYYY',"dueDate")}
                                                </div>
                                                </div>
                                            </div>
                                            <span className="error_span">
                                            {this.state.invoiceDueDateErMsg}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="invGen_topRightFrom_row">
                                        <div className="invGen_topRightFrom_col_left"></div>
                                        <div className="invGen_topRightFrom_col_right">
                                            <div className="form__group field form_group_error">
                                            <div className="formgroup_checkbox">
                                                <input type="checkbox" id="dueOnReceipt" name="dueOnReceipt" checked={this.state.dueOnRceipt}  onChange={this.dueOnRceiptClick} />
                                                <label htmlFor="dueOnReceipt">
                                                <p>Due on receipt</p>
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="invGen_topRightFrom_row">
                                        <div className="invGen_topRightFrom_col_left">
                                            <label>Place of Supply</label>
                                        </div>
                                        <div className="invGen_topRightFrom_col_right">
                                            <div className={`form__group field ${this.state.clientErMsg.placeOfSupply?"form_group_error":""} `}>
                                            <input
                                                type="input"
                                                className="form__field"
                                                placeholder="Place"
                                                name="placeOfSupply"
                                                value={this.state.client.placeOfSupply}
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.receiverDataChangeTrack("placeOfSupply",evn.target.value, {"eventName":"onChange"});
                                                }}
                                            />
                                            <span className="error_span">{this.state.clientErMsg.placeOfSupply}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="clearboth" />
                                </div>
                                {/* ---- Inv Gen from top part end ---  */}
                                {/* ---- Inv Gen left-right from from to part start ---  */}
                                <div className="invGen_fromTo_secBg">
                                    <div className="invGen_fromTo_sec invGen_fromTo_box_left">
                                    <div className="invGen_fromTo_sec_title">
                                        <label>From</label>
                                    </div>
                                    <div className="invGen_fromTo_sec_inputbg">
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Professional’s Name
                                            </label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.lawyerName?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="userPracticeDetailsLawyerName"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("lawyerName",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.lawyerName}
                                            />
                                            <span className="error_span">
                                                {this.state.userPracticeDetailsErMsg.lawyerName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">Practice Name</label>
                                            <div 
                                                className={`form__group field ${this.state.userPracticeDetailsErMsg.practiceName?"form_group_error":""} `}
                                            >
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="userPracticeDetailsPracticeName"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("practiceName",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.practiceName}
                                            />
                                            <span className="error_span">{this.state.userPracticeDetailsErMsg.practiceName}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">Address</label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.address?"form_group_error":""} `}>
                                            <textarea placeholder="Enter" name="userPracticeDetailsAddress"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("address",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.address}
                                                 />
                                                    
                                            <span className="error_span">{this.state.userPracticeDetailsErMsg.address}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        {/* <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Country{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className="form__group field form_group_error">
                                            <select className="customSelect form__field ">
                                                <option>Lorem ipsum dolor sit amet,</option>
                                                <option>Lorem ipsum dolor sit amet,</option>
                                                <option>Lorem ipsum dolor sit amet,</option>
                                            </select>
                                            <span className="error_span">An error occured!</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div> */}
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Email{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.emailAddress?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="userPracticeDetailsEmailAddress"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("emailAddress",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.emailAddress}
                                            />
                                            <span className="error_span">
                                            {this.state.userPracticeDetailsErMsg.emailAddress}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Phone{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.phoneNumber?"form_group_error":""} `}>
                                            {/* ====  */}
                                            {/* <div className="mobnum_inputGrp multi_input_block">
                                                <div className="mob_drop">
                                                <select>
                                                    <option>+91</option>
                                                    <option>+910</option>
                                                </select>
                                                </div>
                                                <input
                                                className="form__field mob_from_input"
                                                placeholder="Enter"
                                                type="text"
                                                />
                                                
                                            </div> */}
                                            {/* ====  */}
                                            {/* ====  */}
                                            {/* <div className="mobnum_inputGrp multi_input_block">
                                                <div className="mob_drop">
                                                <span>+91</span>
                                                </div>
                                                <input
                                                className="form__field mob_from_input"
                                                placeholder="Enter"
                                                type="text"
                                                />
                                            </div> */}
                                           
                                            <PhoneInput
                                                country={this.state.userPracticeDetails.countryCode}
                                                value={this.state.userPracticeDetails.phonePlugin}
                                                onChange={this.senderMobileInputChangeTrack}
                                                autoFormat={false}
                                                enableSearch={true}
                                                placeholder={""}
                                                disabled={false}
                                                countryCodeEditable={false}
                                                containerClass={"custom-container-class"}
                                                inputClass={"custom-input-class"}
                                                buttonClass={"custom-button-class"}
                                                dropdownClass={"custom-dropdown-class"}
                                                onlyCountries={['in']}
                                                inputProps={{
                                                    name: 'userPracticeDetailsPhonePlugin',
                                                    key: 'phonePlugin1',
                                                    id: "userPracticeDetailsPhonePlugin"
                                                   
                                                  }}
                                                

                                                />
                                            {/* ====  */}
                                            <span className="error_span">
                                            {this.state.userPracticeDetailsErMsg.phoneNumber}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_half">
                                        <div className="panel50 panel_half paddingright15 form__group_normar">
                                            <label className="form__label">
                                            GSTIN{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.gstInNumber?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="userPracticeDetailsGstInNumber"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("gstInNumber",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.gstInNumber}
                                                maxLength="15"
                                            />
                                            <span className="error_span">
                                            {this.state.userPracticeDetailsErMsg.gstInNumber}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="panel50 panel_half paddingleft15 form__group_normar">
                                            <label className="form__label">PAN</label>
                                            <div className={`form__group field ${this.state.userPracticeDetailsErMsg.panNumber?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="userPracticeDetailsPanNumber"
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.senderDataChangeTrack("panNumber",evn.target.value,{"calledFrom":"onChange"});
                                                }}
                                                value={this.state.userPracticeDetails.panNumber}
                                                maxLength="10"
                                            />
                                            <span className="error_span">
                                            {this.state.userPracticeDetailsErMsg.panNumber}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                    <div className="invGen_fromTo_sec invGen_fromTo_box_right">
                                    <div className="invGen_fromTo_sec_title">
                                        <label>To</label>
                                        <div className="invGen_fromTo_sec_title_btn">
                                        {!(this.state.clientAddNewButtonClickFlag) && (
                                            <>
                                            <button className="btn_appearance_none green_btn"
                                                onClick={(evn)=>{
                                                    evn.preventDefault();
                                                    
                                                    this.triggerAddNewClientButton({});

                                                }}
                                            >
                                                <span>Add New</span>
                                            </button>
                                            <div class="addNew_btnText">
                                                    <span>Click to add new client</span> 
                                            </div>
                                            <div className="clearboth" />
                                           
                                            
                                            </>
                                        )}

                                        </div>
                                    </div>
                                    <div className="invGen_fromTo_sec_inputbg">
                                        {/* -- input & select field ---  */}
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">Client Name</label>
                                            {/* ====  */}
                                          
                                                <div className={`form__group field ${this.state.clientErMsg.contactName?"form_group_error":""} `}>
                                                {this.state.clientAddNewButtonClickFlag && (
                                                     <input
                                                         className="form__field"
                                                         placeholder="Enter"
                                                         type="text"
                                                         name="clientName"
                                                         onChange={(evn)=>{
                                                                 evn.preventDefault();
                                                                 this.receiverDataChangeTrack("clientName",evn.target.value,{"calledFrom":"onChange"});
                                                             }}
                                                             value={this.state.client.clientName}
                                                                              
                                                             autocomplete="off"
                                                     />
                                                )}

                                            {!(this.state.clientAddNewButtonClickFlag) && (               
                                            <select className="customSelect form__field " name="client" key={"clientL-1"} value={this.state.client.id} 
                                            onChange={this.clientListDropDownChangeTrack} >
                                                <option value="">Select</option>
                                                {Object.keys(this.state.clientList).map((e,i)=> <option key={i}  value={this.state.clientList[i].id}>{this.state.clientList[i].clientName}</option>) }
                                            </select>
                                            )}

                                                     <span className="error_span">
                                                     {this.state.clientErMsg.clientName}
                                                     </span>
                                                     </div>                                                     

                                        
                                            
                                            {/* ====  */}
                                            {/* ====  */}
                                           
                                            {/* ====  */}
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        {/* -- input & select field ---  */}
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Client Contact Name
                                            </label>
                                            <div className={`form__group field ${this.state.clientErMsg.contactName?"form_group_error":""} `}>
                                                <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="clientContactName"
                                                    onChange={(evn)=>{
                                                        evn.preventDefault();
                                                        this.receiverDataChangeTrack("contactName",evn.target.value,{"calledFrom":"onChange"});
                                                    }}
                                                    value={this.state.client.contactName}
                                                    autocomplete="off"
                                                />
                                                <span className="error_span">
                                                {this.state.clientErMsg.contactName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">Address</label>
                                            <div className={`form__group field ${this.state.clientErMsg.address?"form_group_error":""} `}>
                                            <textarea placeholder="Enter" name="clientAddress"
                                                    onChange={(evn)=>{
                                                        evn.preventDefault();
                                                        this.receiverDataChangeTrack("address",evn.target.value,{"calledFrom":"onChange"});
                                                    }}
                                                    value={this.state.client.address} />
                                            <span className="error_span"> {this.state.clientErMsg.address}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        {/* <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Country{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className="form__group field form_group_error">
                                            <select className="customSelect form__field ">
                                                <option>Lorem ipsum dolor sit amet,</option>
                                                <option>Lorem ipsum dolor sit amet,</option>
                                                <option>Lorem ipsum dolor sit amet,</option>
                                            </select>
                                            <span className="error_span">An error occured!</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                         */}
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Email{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.clientErMsg.emailAddress?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="clientEmailAddress"
                                                    onChange={(evn)=>{
                                                        evn.preventDefault();
                                                        this.receiverDataChangeTrack("emailAddress",evn.target.value,{"calledFrom":"onChange"});
                                                    }}
                                                    value={this.state.client.emailAddress}
                                                    autocomplete="off"
                                            />
                                            <span className="error_span">{this.state.clientErMsg.emailAddress}</span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_full">
                                        <div className="panel100 form__group_normar">
                                            <label className="form__label">
                                            Phone{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.clientErMsg.phoneNumber?"form_group_error":""} `}>
                                            {/* ====  */}
                                            {/* <div className="mobnum_inputGrp multi_input_block">
                                                <div className="mob_drop">
                                                <select>
                                                    <option>+91</option>
                                                    <option>+910</option>
                                                </select>
                                                </div>
                                                <input
                                                className="form__field mob_from_input"
                                                placeholder="Enter"
                                                type="text"
                                                />
                                            </div>
                                             */}
                                            {/* ====  */}
                                            {/* ====  */}
                                            {/* <div className="mobnum_inputGrp multi_input_block">
                                                <div className="mob_drop">
                                                <span>+91</span>
                                                </div>
                                                <input
                                                className="form__field mob_from_input"
                                                placeholder="Enter"
                                                type="text"
                                                />
                                            </div>
                                             */}
                                            {/* ====  */}
                                            <PhoneInput
                                                country={this.state.client.countryCode}
                                                value={this.state.client.phonePlugin}
                                                onChange={this.receiverMobileInputChangeTrack}
                                                autoFormat={false}
                                                enableSearch={true}
                                                placeholder={""}
                                                disabled={false}
                                                countryCodeEditable={false}
                                                containerClass={"custom-container-class"}
                                                inputClass={"custom-input-class"}
                                                buttonClass={"custom-button-class"}
                                                dropdownClass={"custom-dropdown-class"}
                                                onlyCountries={['in']}
                                                inputProps={{
                                                    name: 'clientPhonePlugin',
                                                    key: 'phonePlugin2',
                                                    id: 'clientPhonePlugin'
                                                   
                                                  }}

                                                />
                                            <span className="error_span">
                                            {this.state.clientErMsg.phoneNumber}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_half">
                                        <div className="panel50 panel_half paddingright15 form__group_normar">
                                            <label className="form__label">
                                            GSTIN{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.clientErMsg.gstInNumber?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="clientGstInNumber"
                                                    onChange={(evn)=>{
                                                        evn.preventDefault();
                                                        this.receiverDataChangeTrack("gstInNumber",evn.target.value,{"calledFrom":"onChange"});
                                                    }}
                                                    value={this.state.client.gstInNumber}
                                                    maxLength="15"
                                            />
                                            <span className="error_span">{this.state.clientErMsg.gstInNumber}</span>
                                            </div>
                                        </div>
                                        <div className="panel50 panel_half paddingleft15 form__group_normar">
                                            <label className="form__label">
                                            PAN{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                            <div className={`form__group field ${this.state.clientErMsg.panNumber?"form_group_error":""} `}>
                                            <input
                                                className="form__field"
                                                placeholder="Enter"
                                                type="text"
                                                name="clientPanNumber"
                                                    onChange={(evn)=>{
                                                        evn.preventDefault();
                                                        this.receiverDataChangeTrack("panNumber",evn.target.value,{"calledFrom":"onChange"});
                                                    }}
                                                    value={this.state.client.panNumber}
                                                    maxLength="10"
                                                    autocomplete="off"

                                            />
                                            <span className="error_span">
                                            {this.state.clientErMsg.panNumber}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                    <div className="clearboth" />
                                </div>
                                {/* ---- Inv Gen left-right from from to part end ---  */}
                                {/* ---- Inv Gen from refarence/matter section start ---  */}
                                <div className="ref_matter_section_bg">
                                    <div className="refMat_box_sec">
                                    <div className="refMatbox">
                                        <div className="refMat_lablelBox">
                                        <div className="refMat_lablelBox_adjust">
                                            <label>
                                            Reference/Matter{" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="refMat_selInputBox">
                                        {!(this.state.matterNameAddNewButtonClickFlag) && ( 
                                        <div className="refMat_change_box refMat_change_box_select">
                                            <div className="refMat_selectBox">
                                            <div className={`custom_select_bg  ${this.state.matterNameErMsg.panNumber?"form_group_error":""} `} >
                                                
                                                                  
                                                    <select className="customSelect form__field " name="matterName" key={"matterL-1"} value={this.state.matter.id} 
                                                    onChange={this.matterListDropDownChangeTrack} >
                                                        <option value="">Select</option>
                                                        {Object.keys(this.state.matterNameList).map((e,i)=> <option key={i}  value={this.state.matterNameList[i].matterId}>{this.state.matterNameList[i].name}</option>) }
                                                    </select>
                                                   

                                                     <span className="error_span">
                                                     {this.state.matterNameErMsg}
                                                     </span>
                                            </div>
                                            </div>
                                        </div>
                                        )}
                                        {this.state.matterNameAddNewButtonClickFlag && (
                                        <div className="refMat_change_box refMat_change_box_text">
                                            <div className="refMat_inputBox">
                                            <div className={`form__group field  ${this.state.matterNameErMsg.panNumber?"form_group_error":""} `} >
                                                <input
                                                            className="form__field"
                                                            placeholder="Enter"
                                                            key={"matter-1"}
                                                            type="text"
                                                            name="matterName"
                                                            onChange={(evn)=>{
                                                                evn.preventDefault();
                                                                this.handleChange("matterName",evn.target.value, {"eventName":"onChange"});
                                                                }}
                                                                value={this.state.matterName} 
                                                                maxLength="50"
                                                                autocomplete="off"
                                                />
                                                <span className="error_span">
                                                {this.state.matterNameErMsg}
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        )}
                                        
                                        </div>
                                        
                                        <div className="clearboth" />
                                    </div>
                                    <div className="refMat_buttnSec">
                                    {!(this.state.matterNameAddNewButtonClickFlag) && (      
                                        <>                        
                                        <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.triggerAddNewMatterButton({});
                                        }} className="btn_appearance_none green_btn">
                                        <span>Add New</span>
                                        </button>
                                        <div class="addNew_btnText">
                                                    <span>Click to add new matter name</span> 
                                        </div>
                                        </>
                                    )}
                                    
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                </div>
                                {/* ---- Inv Gen from refarence/matter section end ---  */}
                                {/* ---- Inv Gen from Description Table section start ---  */}
                                <div className="descTable_secBg">
                                    {/* --- desc table start ---  */}
                                    <div className="descTable_boxBg">
                                    <div className="desc_th_sec">
                                        <div className="descTab_row">
                                        <div className="descTab_row_thade">
                                            <div className="descTab_col descT_box1">
                                            <label>Description</label>
                                            </div>
                                            <div className="descTab_col descT_box2">
                                            <label>
                                                Code{" "}
                                                <span className="orange_text_Label">
                                                (Optional)
                                                </span>
                                            </label>
                                            </div>
                                            <div className="descTab_col descT_box3">
                                            <label>GST/VAT</label>
                                            </div>
                                            <div className="descTab_col descT_box4">
                                            <div className="desT_ammount_label">
                                            <select name="currencyType" value={this.state.currencyType} 
                                            onChange={this.invMainCalCurrencyOptionChangeTrack} >
                                                {Object.keys(currencyStaticData).map((e,i)=> <option key={i}  value={currencyStaticData[e].key}>{currencyStaticData[e].value}</option>) }
                                                </select>
                                                <div className="desT_am_labl">Amount</div>
                                            </div>
                                            </div>
                                            <div className="clearboth" />
                                        </div>
                                        <div className="clearboth" />
                                        </div>
                                    </div>
                                    <div className="desc_tb_sec">
                                        {this.loadDescCalRows()}
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                    {/* --- desc table end ---  */}
                                    {/* --- row add button sec start ---  */}
                                    <div className="descT_addbtnSec">
                                    <div className="descT_add_button_box">
                                        <button onClick={(evn)=>{
                                                evn.preventDefault();
                                                this.addRowInDesc();
                                        }} className=".btn_appearance_none descT_add_btn">
                                        ADD
                                        </button>
                                    </div>
                                    </div>
                                    {/* --- row add button sec end ---  */}
                                </div>
                                {/* ---- Inv Gen from Description Table section end ---  */}
                                {/* ---- Inv Gen from SubTotal section start ---  */}
                                <div className="invGen_subTotal_secBg">
                                    <div className="invGen_subTotal_secBox">
                                    {/* -- test --  */}
                                    {/* -- test --  */}
                                    <div className="invG_subT_row invGSsubTRow_col_101">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>
                                            <b>Subtotal </b>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2 blank_col"></div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_viewAmt_box">
                                            <div className="invG_subTamt_inputbox invG_subTamt_inputbox_datashow bold_text">
                                                <span>{this.state.currencyType}</span>
                                                <span className="subAmt_num_span">{this.state.totalAmount && parseFloat(this.state.totalAmount)>0?(parseFloat(this.state.totalAmount).toFixed(2)):((this.state.totalAmount===0)?(0.00).toFixed(2):"")}
                                                </span>
                                                <div className="clearboth" />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="invG_subT_row invGSsubTRow_col_101">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>
                                            Advance Paid (-){" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2 blank_col"></div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_inputAmt_box ">
                                            <div className="invG_subTamt_inputbox">
                                                <span>{this.state.currencyType}</span>
                                                <input type="text"  name="advancePaidAmount" onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("advancePaidAmount",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                value={this.state.advancePaidAmount} 
                                                maxLength="10"                    
                                                autocomplete="off"
                                                />
                                                <div className="clearboth" />
                                            </div>
                                            <span className="error_span"></span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="invG_subT_row invGSsubTRow_col_111">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>
                                            Discount (-){" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_par_box ">
                                            <div className="invG_subPar_inputbox">
                                                <input type="text" name="discountPercentage" onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("discountPercentage",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                value={this.state.discountPercentage} 
                                                maxLength="6"                    
                                                autocomplete="off"
                                                />
                                                <span>%</span>
                                                <div className="clearboth" />
                                            </div>
                                            <span className="error_span"></span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_inputAmt_box ">
                                            <div className="invG_subTamt_inputbox">
                                                <span>{this.state.currencyType}</span>
                                                <input type="text" name="discountAmount" onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("discountAmount",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                value={this.state.discountAmount && parseFloat(this.state.discountAmount)>0?(parseFloat(this.state.discountAmount).toFixed(2)):((this.state.discountAmount===0)?(0.00).toFixed(2):"")}
                                                maxLength="10"                    
                                                autocomplete="off"
                                                />
                                                <div className="clearboth" />
                                            </div>
                                            <span className="error_span"></span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="invG_subT_row invGSsubTRow_col_101">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>Goods &amp; Service Tax - GST (+) </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2 blank_col"></div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_viewAmt_box">
                                                <div className="invG_subTamt_inputbox invG_subTamt_inputbox_datashow ">
                                                    <span>{this.state.currencyType}</span>
                                                    <span className="subAmt_num_span">{this.state.indirectTaxAmount && parseFloat(this.state.indirectTaxAmount)>0?(parseFloat(this.state.indirectTaxAmount).toFixed(2)):((this.state.indirectTaxAmount===0)?(0.00).toFixed(2):"")}</span>
                                                    <div className="clearboth" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="invG_subT_row invGSsubTRow_col_111">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>
                                            TDS (-){" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_par_box ">
                                            <div className="invG_subPar_inputbox">
                                                <input type="text" name="tdsPercentage" onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("tdsPercentage",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                value={this.state.tdsPercentage} 
                                                maxLength="6"                    
                                                autocomplete="off"
                                                />
                                                <span>%</span>
                                                <div className="clearboth" />
                                            </div>
                                            <span className="error_span"></span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_viewAmt_box">
                                            <div className="invG_subTamt_inputbox invG_subTamt_inputbox_datashow">
                                                <span>{this.state.currencyType}</span>
                                                <span className="subAmt_num_span">
                                                {this.state.tdsAmount && parseFloat(this.state.tdsAmount)>0?(parseFloat(this.state.tdsAmount).toFixed(2)):((this.state.tdsAmount===0)?(0.00).toFixed(2):"")}
                                                   
                                                </span>
                                                <div className="clearboth" />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="invG_subT_row invGSsubTRow_col_101">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <label>
                                            Out of Pocket Expenses (+){" "}
                                            <span className="orange_text_Label">(Optional)</span>
                                            </label>
                                        </div>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2 blank_col"></div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_subT_col_paddingAdjust">
                                            <div className="invG_sub_inputAmt_box ">
                                            <div className="invG_subTamt_inputbox">
                                                <span>{this.state.currencyType}</span>
                                                <input type="text"  name="outOfPocketAmount" onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("outOfPocketAmount",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                value={this.state.outOfPocketAmount}
                                                maxLength="10"                    
                                                autocomplete="off"
                                            
                                            />
                                                <div className="clearboth" />
                                            </div>
                                            <span className="error_span"></span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    </div>
                                    {/* -- Sub total Due Blue sec start --  */}
                                    <div className="invGen_subTotal_blueBox">
                                    <div className="invG_subT_row invG_subT_row_blue">
                                        <div className="invG_subT_col invG_subT_col_1">
                                        <label>
                                            <b>Balance Due</b>
                                        </label>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_2">
                                        <label>
                                            {/* <b>{this.state.balanceDueDetails.dueAmountInWords}</b> */}
                                        </label>
                                        </div>
                                        <div className="invG_subT_col invG_subT_col_3">
                                        <div className="invG_sub_viewAmt_box">
                                            <div className="invG_subTamt_inputbox invG_subTamt_inputbox_datashow bold_text">
                                            <span>{this.state.currencyType}</span>
                                            <span className="subAmt_num_span">
                                                
                                                {this.state.balanceDueDetails.dueAmount && parseFloat(this.state.balanceDueDetails.dueAmount)?(parseFloat(this.state.balanceDueDetails.dueAmount).toFixed(2)):((this.state.balanceDueDetails.dueAmount===0)?(0.00).toFixed(2):(this.state.balanceDueDetails.dueAmount===""?"":parseFloat(this.state.balanceDueDetails.dueAmount))) }
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    </div>
                                    {/* -- Sub total Due Blue sec end --  */}
                                </div>
                                {/* ---- Inv Gen from SubTotal section end ---  */}
                                {/* ---- Inv Gen from Additional Note section start ---  */}
                                <div className=" addiNote_sec_bg">
                                    <div className=" addiNote_box_sec">
                                    <div className=" addiNote_box">
                                        <div className=" addiNote_lablelBox">
                                        <div className=" addiNote_lablelBox_adjust">
                                            <label>
                                            <b>Additional Notes</b>
                                            </label>
                                        </div>
                                        </div>
                                        <div className=" addiNote_inputBox">
                                        <div className=" addiNote_box_textArea">
                                            <div className="addiNote_textArea_bg">
                                            <div className={`form__group field  ${this.state.notesErMsg?"form_group_error":""} `}>
                                                <textarea  
                                                onChange={(evn)=>{
                                                    evn.preventDefault();
                                                    this.handleChange("notes",evn.target.value, {"eventName":"onChange"});
                                                }}
                                                name="notes" placeholder="Enter" value={this.state.notes}
                                                
                                                />
                                                   
                                               
                                                <span className="error_span">
                                                {this.state.notesErMsg}
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="clearboth" />
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                </div>
                                {/* ---- Inv Gen from Additional Note section end ---  */}
                                {/* ---- Inv Gen from section end ---  */}
                                </div>
                            </div>
                            {/* ===== ** Invoice Generate main form body end =====  */}
                            </div>
                            {/* ==============   User Setting Section end =================   */}
                        </div>
                        {/* -----  scroll adjust for settting screens end -----   */}
                        {/* ============== end =================   */}
                        {/* === User Setting Section / Task Listing Section end === */}
                        {/* ===  task Listing start end === */}
                        {/* ===========  Setting Page main body end =============   */}
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                    {/* ===  DashBoard Main Body Footer Start ===  */}
                    {/* User Setting footer strat */}
                    {/* *****  Put as Dispaly: None ***** */}
                    
                    <div className="task_details_btn_area invoPay_footer kyc_footer">
                        <div className="btn_fld">
                        <div className="panel50">
                            <button disabled={this.state.saveProgressButtonClickFlag} onClick={(evn)=>{evn.preventDefault();
                                this.validateBeforeApiHit({"calledFrom":"saveProgressButton"});
                            }} className="btn_appearance_none green_btn">
                            <span>Save Progress</span>
                            </button>
                        </div>
                        <div className="panel50">
                            <div className="cancel_continue_btn">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn_appearance_none modal_text_btn"
                                onClick={(evn)=>{
                                    evn.preventDefault();
                                    this.resetClick();
                                }}
                            >
                                <span>Reset</span>
                            </button>
                            <button disabled={this.state.invPreviewButtonClickFlag} onClick={(evn)=>{evn.preventDefault();
                                this.validateBeforeApiHit({"calledFrom":"previewButton"});
                            }} className="btn_appearance_none sub_grd_btn">
                            <span>Preview</span>
                            </button>
                            </div>
                        </div>
                        
                        <div className="clearboth" />
                        </div>
                    </div>
                    {/* *****  Put as Dispaly: None ***** */}
                    {/* User Setting footer end */}
                    {/* ===  DashBoard Main Body Footer end ===  */}
                </div>
                {/* Dashboard Main Screen content area 02 end */}
                            
               
            </div>
                


            </>
        )
    }
}

export default withRouter(ManageInvoiceGenerator)
