/**
 * CustomRoutes
 * Page/Class name   : CustomRoutes
 * Author            :
 * Created Date      : 22-11-2019
 * Functionality     :
 * Purpose           : It contains front end routes
 **/
import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import MasterLayout from "../components/layout/MasterLayout";
import BeforeLoginLayout from "../components/layout/BeforeLoginLayout";
import ErrorComp from "../components/error/error";
import Signup from "../components/content/before_login/Signup";
import Signin from "../components/content/before_login/Signin";
import ForgotPassword from "../components/content/before_login/ForgotPassword";
import ResetPassword from "../components/content/before_login/ResetPassword";
import Dashboard from "../components/content/after_login/Dashboard";
import GetTask from "../components/content/after_login/GetTask";
import Kyc from "../components/content/after_login/Kyc";
import Profile from "../components/content/after_login/Profile";
import Subscription from "../components/content/after_login/Subscription";
import SubscriptionPayment from "../components/content/after_login/SubscriptionPayment";
import SpecialDevToReset from "../components/content/before_login/SpecialDevToReset";
import ManageInvoiceGenerator from "../components/content/after_login/ManageInvoiceGenerator";
import ManageInvoiceHistory from "../components/content/after_login/ManageInvoiceHistory";
import ManageInvoiceGeneratorPreview from "../components/content/after_login/ManageInvoiceGeneratorPreview";
// import DtRngPickerRnd from '../components/content/before_login/DtRngPickerRnd';
// import CountryMobileCodeRnd from '../components/content/before_login/CountryMobileCodeRnd';
import VideoCall from "../components/content/after_login/VideoCall";
import JitsiRnd from "../components/content/before_login/JitsiRnd";

// Newest1 - Start
import ProfileNew from "../components/content/after_login/ProfileNew";
// Newest1 - End

import { Redirect } from "react-router-dom";

import { commonJsFuncModule as commonJsObj } from "../common_utilities/commonjsfunc";

const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

const MasterLayoutRoute = ({ component: Component, ...rest }) => {
  // return (
  //     <Route {...rest} render={matchProps => (
  //         <MasterLayout>
  //           <Component {...matchProps} />
  //         </MasterLayout>
  //     )} />
  //   );
  if (getTokenFromLocStorage() == null) {
    return <Redirect to="/sign-in" />;
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <MasterLayout>
            <Component {...matchProps} />
          </MasterLayout>
        )}
      />
    );
  }
};

const BeforeLoginLayoutRoute = ({ component: Component, ...rest }) => {
  if (getTokenFromLocStorage() != null) {
    return <Redirect to="/get-task" />;
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <BeforeLoginLayout>
            <Component {...matchProps} />
          </BeforeLoginLayout>
        )}
      />
    );
  }
};

const NoLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <Component {...matchProps} />
        </>
      )}
    />
  );
};

// Newest1 - Start
const routeArr = [
  {
    path: "",
    component: Signup,
    layout: "BeforeLoginLayoutRoute",
  },
  {
    path: "sign-up",
    component: Signup,
    layout: "BeforeLoginLayoutRoute",
  },
  {
    path: "sign-in/:verifyCode?",
    component: Signin,
    layout: "BeforeLoginLayoutRoute",
  },
  {
    path: "specialfeaturefordev/:param1?",
    component: SpecialDevToReset,
    layout: "NoLayoutRoute",
  },
  {
    path: "videocall/:param1?",
    component: VideoCall,
    layout: "NoLayoutRoute",
  },
  {
    path: "forgot-password",
    component: ForgotPassword,
    layout: "BeforeLoginLayoutRoute",
  },
  {
    path: "reset-password/:resetCode",
    component: ResetPassword,
    layout: "BeforeLoginLayoutRoute",
  },
  // {
  //     path : 'dashboard',
  //     component: Dashboard,
  //     layout : 'MasterLayoutRoute'
  // },
  {
    path: "get-task",
    component: GetTask,
    layout: "MasterLayoutRoute",
  },
  {
    path: "kyc",
    component: Kyc,
    layout: "MasterLayoutRoute",
  },
  // {
  //     path : 'profile',
  //     component: Profile,
  //     layout : 'MasterLayoutRoute'
  // },
  {
    path: "profile",
    component: ProfileNew,
    layout: "MasterLayoutRoute",
  },
  {
    path: "subscription",
    component: Subscription,
    layout: "MasterLayoutRoute",
  },
  {
    path: "subscription-payment",
    component: SubscriptionPayment,
    layout: "MasterLayoutRoute",
  },
  {
    path: "invoice-generator/:param1?",
    component: ManageInvoiceGenerator,
    layout: "MasterLayoutRoute",
  },
  {
    path: "invoice-preview/:param1?",
    component: ManageInvoiceGeneratorPreview,
    layout: "MasterLayoutRoute",
  },
  {
    path: "invoice-history/:param1?",
    component: ManageInvoiceHistory,
    layout: "MasterLayoutRoute",
  },
  // {
  //     path : 'daterngrnd/:param1?',
  //     component: DtRngPickerRnd,
  //     layout : 'NoLayoutRoute'
  // },
  // {
  //     path : 'countrymobilecodernd/:param1?',
  //     component: CountryMobileCodeRnd,
  //     layout : 'NoLayoutRoute'
  // },

  {
    path: "jitsirnd/:param1?",
    component: JitsiRnd,
    layout: "NoLayoutRoute",
  },

  {
    path: "*",
    component: ErrorComp,
    layout: "BeforeLoginLayoutRoute",
  },
];
// Newest1 - End

const loadCustRoutes = function () {
  let routes = [];
  routeArr.forEach((item, index) => {
    if (item.layout == "MasterLayoutRoute") {
      routes.push(
        <MasterLayoutRoute
          key={index}
          path={"/" + item.path}
          exact
          component={item.component}
        />
      );
    } else if (item.layout == "BeforeLoginLayoutRoute") {
      routes.push(
        <BeforeLoginLayoutRoute
          key={index}
          path={"/" + item.path}
          exact
          component={item.component}
        />
      );
    } else if (item.layout == "NoLayoutRoute") {
      routes.push(
        <NoLayoutRoute
          key={index}
          path={"/" + item.path}
          exact
          component={item.component}
        />
      );
    }
  });

  return routes;
};

export default loadCustRoutes;
