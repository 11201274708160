/**
 * TaskInvoiceShare
 * Page/Class name   : TaskInvoiceShare
 * Author            :
 * Created Date      : 15-10-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import TaskInvoiceShareService from '../../service/TaskInvoiceShareService';
// for context
import CommonContext from '../../context/CommonContext';
//Tags
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;

class TaskInvoiceShare extends Component {
    /**
     * TaskInvoiceShare
     * Function name : constructor
     * Author        :
     * Created Date  : 15-10-2020
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {
            "showTaskInvShareModal": false,
            "tags": [],
            "invoiceInfo": null,
            "sendButtonFlag":false
            
        }    

        this.modalfortaskinvshareRef = React.createRef();
    }

    /**
     * TaskInvoiceShare
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 15-10-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * TaskInvoiceShare
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 15-10-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    handleTagChange = (tags) =>{
        //console.log(" tags==>",tags); // tags contains array of data 
        let chkNow  = this.checkWhetherEmailIsAreValid(tags);
        if(chkNow==false){
            tags = [...this.state.tags]; // set to previous states
        }

        let tagsData = tags;            
        let sendButtonFlag = (tagsData.length>0)?false:true;        

        this.isComponentLoaded && this.setState({"tags":tags,"sendButtonFlag":sendButtonFlag},()=>{
  
           let paramObj = {};           
           this.getTaskInvoiceShareModalContent(paramObj);
         
  
          
        });
    }

    checkWhetherEmailIsAreValid = (emailIdAr) => {
        let totErrTrack = [];
        if(emailIdAr.length>0){

            emailIdAr.map((eml,i)=>{
                let erMsg = "";
                if(!(eml)||(eml.length==0)){
                    erMsg = "Email-Id not provided";
                    totErrTrack.push(erMsg);
    
                  }    
                  else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(eml)==false)
                  {
                    erMsg = "Invalid email-Id has been provided";
                    totErrTrack.push(erMsg);
                    
                  }             
    
    
            });
            
        }

        if(totErrTrack.length>0){
            return false;
        }else{
            return true;
        }
    }

     /**
         * orgDetailDataObj
         * Function name : custValidate
         * Author        :
         * Created Date  : 15-10-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

    let totErrCountTrack = [];  
    let emailIdAr = this.state.tags;
    let emailNotProvidedErMsg = "";  
    let improperEmailErMsgAr = [];  

    if(emailIdAr.length>0){

        emailIdAr.map((eml,i)=>{
            let erMsg = "";
            if(!(eml)||(eml.length==0)){
                emailNotProvidedErMsg = "Email-Id not provided";
                totErrCountTrack.push(emailNotProvidedErMsg);

              }    
              else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(eml)==false)
              {
                erMsg = "Invalid email-Id has been provided";
                totErrCountTrack.push(erMsg);
                improperEmailErMsgAr.push(erMsg);
              }             


        });
        
    }else{
        
        emailNotProvidedErMsg = "Email-Id not provided";
        totErrCountTrack.push(emailNotProvidedErMsg);
    }    
  
  
    if(totErrCountTrack.length>0){ 
            let notiMsg = "";
            if(emailNotProvidedErMsg){
                notiMsg = emailNotProvidedErMsg;
            }
            else if(improperEmailErMsgAr.length>0){
                notiMsg = improperEmailErMsgAr[0];
            }

            notify(notiMsg,true,{
            "successMsg":0,
            "customWrapClass":"customerrmidlass",
            "showCloseButton": false, 

            "dismiss": {
            "duration": 1700,
            }

            });        
            return false;
      }
      return true;
    }

    callShareTaskInvoiceService = (paramObj) =>{

        const {invoiceInfo,taskOrSubTask,taskIdOrSubTaskId,lpUserId,ccUserId} = this.props.datas;
        let invoiceId = invoiceInfo.invoiceId!==undefined && invoiceInfo.invoiceId!==null?invoiceInfo.invoiceId:0;
        let toEmailAddresses = this.state.tags;
        let postData = {
            "toEmailAddresses":toEmailAddresses,
            // "ccEmailAddresses":[],
            // "bccEmailAddresses":[],
            // "subject":"",
            // "body":""
          };

        let passParam = {"invoiceId":invoiceId,
                        "taskOrSubTask":taskOrSubTask,
                        "taskIdOrSubTaskId":taskIdOrSubTaskId,
                        "lpUserId":lpUserId,
                        "ccUserId":ccUserId,
                        "postData":postData
                        };
        

        let stcservice = new TaskInvoiceShareService();
        stcservice.shareInvoice({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    respData = data.data.entity; 
                   
  
                    notify("Sent successfully",true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false, 
            
                        "dismiss": {
                        "duration": 1500,
                        }
            
                        });       
                    setTimeout(()=>{
                              this.taskInvoiceShareModalClose();   
                          
                    },1700);
                    
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });       
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getTaskInvoiceShareModalContent(paramObj);
                });            
                          
            },1500);
  
            
                       
              
          });
    }

    preCallShareTaskInvoiceService = (paramObj) =>{
        this.getTaskInvoiceShareModalContent(paramObj);
       
        // make any validation
        let chk = this.custValidate(paramObj);
        if(chk){
            this.callShareTaskInvoiceService({});
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getTaskInvoiceShareModalContent(paramObj);
            });
        }
        
        
    }

    triggerTaskShareModalOpen = (otherParam) =>{

        let invoiceInfo = this.props.invoiceInfo?this.props.invoiceInfo:null;

        this.isComponentLoaded && this.setState({"showTaskInvShareModal": false,
        "tags": [],
        "invoiceInfo": invoiceInfo,"sendButtonFlag":true},()=>{

                this.taskInvoiceShareModalOpen(otherParam);
        });


    }


    /*** Task invoice share modal starts  */
    /**
     * TaskInvoiceShare
     * Function name : conflictModalOpen
     * Author        :
     * Created Date  : 15-10-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   taskInvoiceShareModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showTaskInvShareModal:true},()=>{
      this.modalfortaskinvshareRef.current.showModal();
      this.getTaskInvoiceShareModalContent(otherParam);
    });    
      
  }

  /**
     * TaskInvoiceShare
     * Function name : conflictModalClose
     * Author        :
     * Created Date  : 15-10-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   taskInvoiceShareModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showTaskInvShareModal:false},()=>{
      this.modalfortaskinvshareRef.current.hideModal();
    });
    }

    /**
   * TaskInvoiceShare
   * Function name : getConflictModalContent
   * Author        :
   * Created Date  : 15-10-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getTaskInvoiceShareModalContent(otherParam) {  
      
      console.log(" from getTaskInvoiceShareModalContent otherParam==>",otherParam);
        
        let lawyerConflictCheckStatus = otherParam.lawyerConflictCheckStatus!==undefined?otherParam.lawyerConflictCheckStatus: "";

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Share Details 
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.taskInvoiceShareModalClose();

                }} className="close modal_close" data-dismiss="modal">
                &times;
            </button>
            </div>
            <CustomMessageBar />
            <div className="modal-body padding_top ">
              
    
            <div className="row conflctpopcontentcls  invoShare_modal_body">                
                    <>
                    <div className="conflict_popText">
                                <p>Enter the email ID’s you want to share the Invoice details to.</p>
                                <p>Type email id & hit enter to add multiple emailid's </p>
                    </div>
                    <div className="conflict_inputBox">
                    <TagsInput inputProps={{
  className: 'react-tagsinput-input',
  placeholder: 'Enter email ID'}} value={this.state.tags} onChange={this.handleTagChange} />
                      
                    </div>
                   
                    </>
                
            </div>
    
            
            </div>
            <div className="modal-footer">
                <button onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.taskInvoiceShareModalClose();

                }}  type="button"  className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                       
                <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                    this.preCallShareTaskInvoiceService(otherParam);
                });
                

                } } ><span>Send</span></button>
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalfortaskinvshareRef.current.updateStateContent(viewData);
        this.modalfortaskinvshareRef.current.showContent();
        return viewData;
    }    

    /*** Task invoice share modal related ends  */


   

    /**
     * TaskInvoiceShare
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       let dispelementype = this.props.dispelementype!==undefined?this.props.dispelementype:"button";
       return (
             <>  
             {dispelementype=="button" && (
                <button key={this.props.custkeyid?this.props.custkeyid:"k001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        this.triggerTaskShareModalOpen({});

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      <span>{this.props.buttontextname?this.props.buttontextname:"Share Invoice"}</span>
                </button>
            )}  

            {dispelementype=="anchor" && (
                <a href="" key={this.props.custkeyid?this.props.custkeyid:"k001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        this.triggerTaskShareModalOpen({});

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                     {this.props.buttontextname?this.props.buttontextname:"Share Invoice"}
                </a>
            )}              
                
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalfortaskinvshareRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showTaskInvShareModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

               


             </>
            
        );
    }

  }
  export default TaskInvoiceShare;