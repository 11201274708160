/**
 * Dashboard
 * Page/Class name   : Dashboard
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';
// import Footer from './../../share/Footer'

//Newly added



//import {notifyheaderpopup} from '../../share/Header';


class Dashboard extends Component {

    isComponentLoaded = false;
    /**
     * Dashboard
     * Function name : constructor
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Dashboard
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/

   

    componentDidMount() {
       this.isComponentLoaded = true;

       this.checkBeforeCallingOrgModal();


    }

    checkBeforeCallingOrgModal(){
        // notifyheaderpopup("openOrgDetPopup",{
        // });
        
    }

    /**
     * Dashboard
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
        this.isComponentLoaded = false;

    }

    /**
     * Dashboard
     * Function name : render
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
            <React.Fragment>
                   <div>
                        {/* Dashboard Main Screen content area start */}
                        <div className="dashboard_cont_area tasklist_cont_areabg dashboard_areabg">
                            {/* ===  DashBoard Main Body Start ===  */}
                            <div className="cutom_container">
                            <div className="tab-content">
                                <div id="dashboard" className="tab-pane fade in active">
                                <div className="dashboard_block">
                                    <div className="panel60 paddingright15">
                                    <div className="notification_area mCustomScrollbar">
                                    <Scrollbars style={{ height: 300 }}>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                        <div className="notification_block">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                        <a href="#">Know more.</a>
                                        <a href="#" className="blackcross">
                                            <img src="assets/images/balck_cross.png" />
                                        </a>
                                        </div>
                                    </Scrollbars>    
                                    </div>
                                    </div>

                                    <div className="clearboth" />
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* ===  DashBoard Main Body End ===  */}
                        </div>
                        {/* Dashboard Main Screen content area end */}
                        {/* <Footer/> */}

                    </div>
            
            </React.Fragment>
            
        );
    }



  }
  export default Dashboard;