import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

export class ReadAllNotificationService extends Component {
    constructor() {
        super();
        this.state = {
        }
        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'/rest/user/notifications/all';
    }
    async markAllAsRead(){
        this.axiosObj = axiosCustomObj({});
    
        let accToken = getTokenFromLocStorage();
            try{
                let urldata =this.apiEndPoint + '?is_read=true';
                //console.log(" ==dataObj==>",dataObj);
                let headerOptn = {
                    "Content-Type": "application/json",
                    "Authorization": accToken
                };
                let respObj = this.axiosObj.put(urldata,{}, {
                headers: headerOptn
                });
                return respObj;
            }
            catch(err){
                console.log("Some error occured===>",err); // TypeError: failed to fetch
            }
    }
}

export default ReadAllNotificationService
