import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;

export class GenerateInvoiceService extends Component {
    constructor() {
        super();
        this.state = {

        }
        
        //this.axiosObj = axios;
        this.axiosObj = null;
        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/invoice/task/';
    }
    async generateInvoice(paramObj){
        this.axiosObj = axiosCustomObj({});

        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

        try{
                let urldata =this.apiEndPoint;
                let offsetHr = offsetOfLocalDateTime.hr;
                let offsetMin = offsetOfLocalDateTime.min;

                urldata = urldata+paramObj.data.taskId+'?offset_hour='+offsetHr +'&offset_minute='+offsetMin+'&is_reverse_gst_applicable='+paramObj.data.is_reverse_gst_applicable;

                let hsn  = paramObj.data.hsn;
              //console.log("task single list api hitted with =>>",urldata);


              let headerOptn = {
                "Content-Type": "application/json",
                "Authorization": accToken
              };

              let respObj = this.axiosObj.post(urldata,{"hsn":hsn},{
              headers: headerOptn
              });
              return respObj;
      }
      catch(err){
          //console.log("Some error occured===>",err); // TypeError: failed to fetch
      }


    }
}

export default GenerateInvoiceService
