import React, { Component } from 'react';
import {Link, NavLink, withRouter, useLocation, Prompt} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import CommonContext from '../../context/CommonContext';
import FetchInvoiceDataFromInvIdService from '../../service/FetchInvoiceDataFromInvIdService';
import CustLoader from './CustLoader';
import { Scrollbars } from 'react-custom-scrollbars';
import ManageInvoiceGeneratorGenericHeader from './ManageInvoiceGeneratorGenericHeader';
import InvoiceSendEmail from './InvoiceSendEmail';

// view pdf related
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const getBaseUrl = commonJsObj.getBaseUrl;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;
const getSubscriptionActiveStatus = commonJsObj.getSubscriptionActiveStatus;

//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;


export class ManageInvoiceGeneratorPreview extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state ={
            "invoicePdfBase64":null,
            "invoiceId":0,
            "invoiceStatus":"",
            "editButtonViewFlag":true,

            "pageNumber":1,
            "numPages":null,        
            "pdfLoadErr": false,

            "invFetchRespObj": null,
            "navigateDisAllowFlag":false
        };
        this.scrollerRef = React.createRef();
        this.queryParamObj = new URLSearchParams(this.props.location.search);
    }
    componentDidMount() {
       this.isComponentLoaded =  true;

       let kycVerifiedStatus = getKycVerifiedStatus();
       let subscriptionActiveStatus = getSubscriptionActiveStatus();
       
       if(!(subscriptionActiveStatus) || !(kycVerifiedStatus)){
            this.props.history.push("/get-task");
       }
       else{

            let invId = this.queryParamObj.get("inv");
            invId = (invId) && !isNaN(invId)?invId:0;
            console.log("invId==>>",invId);


            if(invId){
                    this.isComponentLoaded && this.setState({"navigateDisAllowFlag":true},()=>{
                        this.setInvId({"invoiceId":invId});
                    });
                    
            }
         }

       

       
       


    }
    
    componentWillUnmount() {
        this.isComponentLoaded =  false;
        window.removeEventListener("beforeunload", this.onUnloadPrompt);
    }

    setInvId = (otherParam)=> {
        let invoiceId = otherParam.invoiceId!==undefined?otherParam.invoiceId:0;
        this.isComponentLoaded && this.setState({"invoiceId":invoiceId},()=>{
            this.fetchInvoiceData({"data":{"invoiceId":invoiceId},"calledFrom":"setInvId"});
        });

    }

    onUnloadPrompt = e => { // the method that will be used for both add and remove event
        e.preventDefault();
        let message = "Leave site ? \n Changes that you made may not be saved.";
        (e || window.event).returnValue = message; //Gecko + IE
        return message;
     }

     disAllowPageQuit = () =>{
        window.addEventListener("beforeunload", this.onUnloadPrompt);
     }


    fetchInvoiceData = (otherParam) =>{
        let calledFrom =  otherParam.calledFrom!==undefined?otherParam.calledFrom:"";

        let userv = new FetchInvoiceDataFromInvIdService();
        userv.fetchData(otherParam.data).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                //console.log("fetched=>>>>>>>",data.data.entity);
                let respObj =  data.data.entity;
                let invoicePdfBase64 = respObj!==null && respObj.invoicePdfBase64!==undefined?respObj.invoicePdfBase64: null;
                let invoiceStatus = respObj!==null && respObj.invoiceStatus!==undefined?respObj.invoiceStatus: null;
                let editButtonViewFlag = invoiceStatus==="GENERATED"?false:true;
                let navigateDisAllowFlag = calledFrom=="setInvId"?true:false;

                this.isComponentLoaded && this.setState(
                    {
                    "invoicePdfBase64":invoicePdfBase64,
                    "invoiceStatus":invoiceStatus,
                    "editButtonViewFlag":editButtonViewFlag,
                    "invFetchRespObj":respObj,
                    "navigateDisAllowFlag":navigateDisAllowFlag
                    
                    },()=>{
                        if(calledFrom=="clickDownload"){
                            this.props.history.push("/invoice-history/?view=2");
                            
                        }

                    });

                

                  
                
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);

            this.isComponentLoaded && this.setState(
                {                
                "navigateDisAllowFlag":false
                
                },()=>{});
        });
    }

    clickDownload = () =>{
        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;
        let downloadUrl = commonJsObj.getEndPoint()+'rest/invoice/download';

        downloadUrl = downloadUrl+"/"+this.state.invoiceId+"/?offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&auth="+accToken;

        if(downloadUrl){

            this.isComponentLoaded && this.setState({"editButtonViewFlag":false},()=>{
                let winDwnObj = window.open(downloadUrl, '_blank');
                winDwnObj.focus();
                setTimeout(()=>{
                    this.fetchInvoiceData({"data":{"invoiceId":this.state.invoiceId},"calledFrom":"clickDownload"});
                },3000);

            });
            //window.location.href = downloadUrl;
           
        }

    }
    editClicked = () =>{
           // ********* for context starts *****
           let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;   
           let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
 
           if(sharedData!==undefined && sharedData!==null){   
             
            if(setShare && sharedData){
 
                let sharedDataNewObj = {...sharedData}; 
                let otherDataNewObj = sharedDataNewObj.other?{...sharedDataNewObj.other}:{};
                if(otherDataNewObj){
                   otherDataNewObj["invoiceGeneratedPreviewGenState"]= this.state.invFetchRespObj;
                }
                
                sharedDataNewObj["actionType"]   = "INVOICE_GENERATOR_PREVIEW_EDIT";
                sharedDataNewObj["purpose"]   = "";
                sharedDataNewObj["other"]   = otherDataNewObj;     
                
                //console.log(" sharedDataNewObj=>>",sharedDataNewObj);
               
               setShare(sharedDataNewObj);   
                
                setTimeout(()=>{
                   this.props.history.push("/invoice-generator");
                },1700);
            }
        }
         //  ********* for context ends *****
 
       
    }

    sendMailStatusCallBack = () =>{
        this.isComponentLoaded && this.setState({"editButtonViewFlag":false,"navigateDisAllowFlag":false},()=>{
            this.props.history.push("/invoice-history/?view=2");
        });
    }

     // view pdf file 
     onDocumentLoadSuccess = ({ numPages }) => {
        this.isComponentLoaded && this.setState({ numPages },()=>{
          let otherParam = this.state.specificFileInfo;
          
        });
      }
  
      /**
       * SplitScreenTaskInvoiceView
       * Function name : onDocumentLoadErr
       * Author        :
       * Created Date  : 12-6-2020
       * Purpose       : callback function for load pdf error
       * Params        : 
       **/
  
      // view pdf file
      onDocumentLoadErr = ( error ) => {
       //console.log(" === error=>>",error);
       this.isComponentLoaded && this.setState({"pdfLoadErr":true},()=>{
        let otherParam = this.state.specificFileInfo;
       
       });
  
      }
  
      /**
       * SplitScreenTaskInvoiceView
       * Function name : triggerPrevNextPdfPage
       * Author        :
       * Created Date  : 12-6-2020
       * Purpose       : for viewing pdf prev next page
       * Params        : 
       **/
  
      // view pdf file
      triggerPrevNextPdfPage = (action,param) => {
  
        let pageNumber = this.state.pageNumber;
        let numPages = this.state.numPages;
        if(action=="PREV"){
  
          if(pageNumber>1){
            pageNumber = pageNumber -1;
  
          }
  
        }else if(action=="NEXT"){
          if(numPages!==null && pageNumber!=numPages){
            pageNumber = pageNumber +1;
  
          }
  
        }
        this.isComponentLoaded && this.setState({"pageNumber":pageNumber},()=>{
          let otherParam = this.state.specificFileInfo;          
        });
      }

    loadInvPreviewFooter = () => {
        return (
            <>
              <div className="task_details_btn_area invoPay_footer kyc_footer invGen_preview_footer">
                    <div className="btn_fld">
                        {(this.state.invoicePdfBase64) && (
                            <>
                            <div className="panel50">
                                {(this.state.editButtonViewFlag) && (
                                    <button onClick={(evn)=>{
                                        evn.preventDefault();
                                        this.editClicked();
                                    }} className="btn_appearance_none green_btn"><span>Edit</span></button>
                                )}
                                
                            </div>
                            <div className="panel50">
                                <div className="cancel_continue_btn">
                               

                                
                                <button onClick={(evn)=>{
                                    evn.preventDefault();
                                    this.clickDownload();
                                }} className="btn_appearance_none sub_grd_btn"><span>Download</span></button>
                                <InvoiceSendEmail textname="Email" custkeyid={"emailinvbtnlnk001"} custclassname="btn_appearance_none sub_grd_btn" otherdata={{"invoiceId":this.state.invoiceId}} callbackcustomfunc={this.sendMailStatusCallBack} />
                                {/* <button className="btn_appearance_none sub_grd_btn"><span>Email</span></button> */}
                                </div>
                            </div>
                            <div className="clearboth" />
                             </>
                         )}
                    </div>
                </div>
                         
            </>
        );
    }
      
    render() {
        const { pageNumber, numPages,pdfLoadErr } = this.state;
        return (
            <>  
                {/* <Prompt
                     when={this.state.navigateDisAllowFlag}
                    message={location => `Are you sure you want to leave?`}
                />             */}
                {/* Dashboard Main Screen content area 01 start */}
                <ManageInvoiceGeneratorGenericHeader activetabname="newinvoicepreviewtab"  />
                {/* Dashboard Main Screen content area 01 end */}
                {/* Dashboard Main Screen content area 02 start */}
                <div className="kyc_section_part_02">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg InvGen_mainfromBox_bg InvGen_mainfromBox_bg_lp">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting cutom_container_inGenBg">
                        {/* ===========  Setting Page main body start =============   */}
                        {/* ============== Start =================   */}
                        {/* -----  Invoice Generator Page main body section //  start -----   */}
                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting invGen_fromScroll_sec">
                            {/* ==============   User Setting Section Start =================   */}
                            <div className="userSetting_area_bg userSetting_area_bg_invGen userSetting_area_bg_invGen_preBg">
                            {/* === Invoive Generator Preview sec start ===  */}
                            <div className="invGen_preview_sec">
                            {this.state.invoicePdfBase64!==null && (
                                <div className="invoiceviewpage forpdfviewcustclass">
                                {/* ==== **** From BACK END Api start **** ======   */}
                                {/* <div className="okPdf">
                                    <div className="pdfpagi">
                                    <p>Page 1 of 3</p>
                                    <button className="prevClass">Previous </button>
                                    <button className="nextClass">Next</button>
                                    </div>
                                    <div className="react-pdf__Document">
                                    <div className="react-pdf__Page" style={{position: 'relative', margin: '0px auto', textAlign: 'center'}} data-page-number={1}>
                                        <img src="assets/images/canvas.png" alt style={{margin: 'auto'}} />
                                        <div className="react-pdf__Page__annotations annotationLayer" />
                                    </div>
                                    </div>
                                </div>
                                 */}

                                 
                                    {pdfLoadErr==false && (
                                       <div className="okPdf"> 
                                       {numPages>1 && (
                                       <div className="pdfpagi">
                                           <p>Page {pageNumber} of {numPages}</p>
                                           <button className="prevClass" onClick={(evn)=>{
                                             evn.preventDefault();
                                             this.triggerPrevNextPdfPage("PREV",{});
                                           }}>Previous </button>
                                           <button className="nextClass" onClick={(evn)=>{
                                             evn.preventDefault();
                                             this.triggerPrevNextPdfPage("NEXT",{});
                                           }}>Next</button>
                                       </div>
               
                                       )}   
                                       <Document
                                           file={`data:application/pdf;base64,${this.state.invoicePdfBase64!==null?this.state.invoicePdfBase64:""}`}
                                           onLoadSuccess={this.onDocumentLoadSuccess}
                                           onLoadError={this.onDocumentLoadErr}
                                           
                                         >
                                           <Page pageNumber={pageNumber} />
                                       </Document>
                                      
                                      
                                       </div>  
                 
                                    )}
                                    {pdfLoadErr==true && ( 
                                     <div className="errPdf text-center">
                                       Something went wrong, please try later.
                                     </div>
                                    )}
                                      
                                        
                                
                                {/* ==== **** From BACK END Api end  **** ======   */}
                                </div>
                                )}
                            </div>
                            {/* === Invoive Generator Preview sec end ===  */}
                            </div>
                            {/* ==============   User Setting Section end =================   */}
                        </div>
                        {/* ----- Invoice Generator Page main body section //  end -----   */}
                        {/* ============== end =================   */}
                        {/* ===========  Setting Page main body end =============   */}
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                    {/* ===  DashBoard Main Body Footer Start ===  */}
                    {/* User Setting footer strat /// Invoice Generator Privew Footer start */}
                    {this.loadInvPreviewFooter()}
                    {/* User Setting footer end /// Invoice Generator Privew Footer end */}
                    {/* ===  DashBoard Main Body Footer end ===  */}
                </div>

                {/* Dashboard Main Screen content area 02 end */}

                


            </>
        )
    }
}

export default withRouter(ManageInvoiceGeneratorPreview)
