// Newest1 - Start

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import CustLoader from "./CustLoader";

// Services
import AddProfInfoFetchKYCService from "../../service/AddProfInfoFetchKYCService";
import AddProfInfoUploadDisplayService from "../../service/AddProfInfoUploadDisplayService";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ProfileNew extends Component {
  /**
   * Profile
   * Function name : constructor
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : initializing state
   * Params        :
   **/
  constructor(props) {
    super(props);
    this.state = {
      kYCObj: null,
      loaderFlag: true,
      kYCFetchError: "",
      uploadingFlag: false,
      fileUplErrMsg: "",
      fileUplSuccessMsg: "",
      base64PDF: null,
      pageNumber: 1,
      numPages: null,
      pDFLoadErr: false,
    };
    this.fileUpl = React.createRef();
  }

  /**
   * Profile
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
    this.isComponentLoaded && this.callFetchKYCService();
  }

  /**
   * Profile
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * Profile
   * Function name : loadLoader
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : load Loader
   * Params        :
   **/
  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * Profile
   * Function name : hideLoader
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : Hide loader
   * Params        :
   **/
  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * Profile
   * Function name : showLoader
   * Author        :
   * Created Date  : 6-7-2021
   * Purpose       : showLoader
   * Params        :
   **/
  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  async callFetchKYCService() {
    let stcservice = new AddProfInfoFetchKYCService();

    let kYCFetchError = "";

    stcservice
      .fetchKYCDetails()
      .then((data) => {
        if (data && data.status === 200) {
          let kYCObj = data.data.entity;
          this.isComponentLoaded && this.setState({ kYCObj });
          this.isComponentLoaded &&
            kYCObj.additionalProfileInfoDoc &&
            kYCObj.additionalProfileInfoDoc.base64String &&
            this.setState({
              base64PDF: kYCObj.additionalProfileInfoDoc.base64String,
            });
        }
      })
      .catch((err) => {
        let errStatusCodeArr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let errRespData = err.response.data;
          if (errStatusCodeArr.indexOf(errRespData.status) !== -1) {
            kYCFetchError = errRespData.message
              ? errRespData.message
              : "Some technical problem occurred while loading the KYC.";
          }
        }
      })
      .finally(() => {
        this.hideLoader();
        this.isComponentLoaded && this.setState({ kYCFetchError });
      });
  }

  onPDFLoadErr = (error) => {
    this.isComponentLoaded && this.setState({ pDFLoadErr: true });
  };

  onPDFLoadSuccess = ({ numPages }) => {
    this.isComponentLoaded && this.setState({ numPages });
  };

  triggerPrevNextPdfPage = (action) => {
    let pageNumber = this.state.pageNumber;
    let numPages = this.state.numPages;
    if (action == "PREV") {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1;
      }
    } else if (action == "NEXT") {
      if (numPages !== null && pageNumber != numPages) {
        pageNumber = pageNumber + 1;
      }
    }
    this.isComponentLoaded && this.setState({ pageNumber });
  };

  async callUploadDisplayService(singleFileObj) {
    let uploadDisplayServ = new AddProfInfoUploadDisplayService();
    this.isComponentLoaded && this.setState({ uploadingFlag: true });

    let fileUplErrMsg = "";

    uploadDisplayServ
      .fileUploadDisplayService(singleFileObj)
      .then((data) => {
        if (data && data.status === 200) {
          let pDFObj = data.data.entity;

          let fileUplSuccessMsg = "File uploaded successfully.";

          this.isComponentLoaded &&
            this.setState({ fileUplSuccessMsg }, () => {
              setTimeout(() => {
                this.setState({ fileUplSuccessMsg: "" });
              }, 4000);
            });

          this.isComponentLoaded && this.setState({ base64PDF: pDFObj });
        }
      })
      .catch((err) => {
        let errStatusCodeArr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let errRespData = err.response.data;
          if (errStatusCodeArr.indexOf(errRespData.status) !== -1) {
            fileUplErrMsg =
              "Error: " + errRespData.message
                ? errRespData.message
                : "Some technical problem occurred.";
          }
        } else if (err.message) {
          fileUplErrMsg = "Error: " + err.message;
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ fileUplErrMsg }, () => {
            setTimeout(() => {
              this.setState({ fileUplErrMsg: "" });
            }, 4000);
          });
        this.isComponentLoaded && this.setState({ uploadingFlag: false });
        this.fileUpl.current.value = "";
      });
  }

  validateFileSelected(fileObj) {
    const maxFileUploadAllowed = 1;
    const maxFileUploadSizeAllowed = 5 * 1024 * 1024; // 5 MB
    const allowedFileExt = ["xlsx"];

    let isValidated = true;
    let fileUplErrMsg = "";

    if (fileObj) {
      if (fileObj.length > maxFileUploadAllowed) {
        // File validation for maximum number of uploads
        //error
        isValidated = false;
        fileUplErrMsg =
          "Error: Sorry, you cannot upload more than " +
          maxFileUploadAllowed +
          "  file(s).";
      } else {
        // File validations like size and extension
        let totalSize = 0;
        let totalInvalidExtArr = [];
        Object.keys(fileObj).map((k, i) => {
          totalSize = totalSize + fileObj[k].size;
          let fileName = fileObj[k].name;
          let extnReceived = fileName.split(".").pop();
          if (allowedFileExt.indexOf(extnReceived) == -1) {
            totalInvalidExtArr.push(extnReceived);
          }
        });

        if (totalSize > maxFileUploadSizeAllowed) {
          //error
          isValidated = false;
          fileUplErrMsg =
            "Error: You have crossed the maximum upload file size.";
        }

        if (totalInvalidExtArr.length > 0) {
          //error
          isValidated = false;
          fileUplErrMsg = "Error: Invalid file extention.";
        }
      }
    } else {
      //error
      isValidated = false;
      fileUplErrMsg = "Error: Something went wrong. Please try again.";
    }

    this.isComponentLoaded &&
      this.setState({ fileUplErrMsg }, () => {
        setTimeout(() => {
          this.setState({ fileUplErrMsg: "" });
        }, 4000);
      });
    return isValidated;
  }

  trackFileSelection = (e) => {
    e.preventDefault();
    let fileObj = e.target.files;
    let chkFileValidation = this.validateFileSelected(fileObj);
    if (chkFileValidation) {
      this.callUploadDisplayService(fileObj[0]);
    } else {
      this.fileUpl.current.value = "";
    }
  };

  isAnyWordInTextLongerThan25 = (text) => {
    let isLong = false;
    let words = text.split(" ");
    words.forEach((word) => {
      if (word.length >= 25) isLong = true;
    });
    return isLong;
  };

  kYCDetailsSection = () => {
    const { loaderFlag, kYCObj, kYCFetchError } = this.state;

    const base64String =
      kYCObj && kYCObj.profilePicture && kYCObj.profilePicture.base64String;
    const namePrefix = kYCObj && kYCObj.namePrefix ? kYCObj.namePrefix : "";
    const practiceOwnerName =
      kYCObj && kYCObj.practiceOwnerName ? kYCObj.practiceOwnerName : "";
    const lawFirmName = kYCObj && kYCObj.lawFirmName ? kYCObj.lawFirmName : "";
    const isLawFirmNameSameAsPracticeOwnerName =
      kYCObj && kYCObj.isLawFirmNameSameAsPracticeOwnerName
        ? kYCObj.isLawFirmNameSameAsPracticeOwnerName
        : "";
    const aboutThePractice =
      kYCObj && kYCObj.aboutThePractice ? kYCObj.aboutThePractice : "";
    const website = kYCObj && kYCObj.website ? kYCObj.website : "";
    const linkedInUrl = kYCObj && kYCObj.linkedInUrl ? kYCObj.linkedInUrl : "";
    const officialEmailAddress =
      kYCObj && kYCObj.officialEmailAddress ? kYCObj.officialEmailAddress : "";
    const officialPhoneNumber =
      kYCObj && kYCObj.officialPhoneNumber ? kYCObj.officialPhoneNumber : "";
    const graduationUniversityName =
      kYCObj && kYCObj.graduationUniversity && kYCObj.graduationUniversity.name
        ? kYCObj.graduationUniversity.name
        : null;
    const workExperience =
      kYCObj && kYCObj.workExperience ? kYCObj.workExperience : null;

    const practiceAreas =
      kYCObj && kYCObj.practiceAreas ? kYCObj.practiceAreas : [];
    let practiceAreaArr = [];
    practiceAreas.forEach((practiceObj) => {
      practiceAreaArr.push(practiceObj.practiceArea);
    });
    let practiceAreasText = "";
    if (practiceAreaArr.length > 0) {
      practiceAreasText = practiceAreaArr.toString().replaceAll(",", ", ");
    }

    const locationsActive =
      kYCObj && kYCObj.locationsActive ? kYCObj.locationsActive : [];
    let locationsActiveArr = [];
    locationsActive.forEach((locationObj) => {
      locationsActiveArr.push(
        locationObj.cityName + " (" + locationObj.state + ")"
      );
    });
    let locationsActiveText = "";
    if (locationsActiveArr.length > 0) {
      locationsActiveText = locationsActiveArr.toString().replaceAll(",", ", ");
    }

    const isKYCVerified =
      kYCObj && kYCObj.isVerified ? kYCObj.isVerified : false;

    return (
      <React.Fragment>
        {loaderFlag ? (
          this.loadLoader()
        ) : kYCObj && kYCObj.isSubmittedForKYC ? (
          <div className="newUserSection_leftBg">
            <div className="newUserSidebarBg">
              <div className="newUserSidebar_topSection">
                <div className="newUserSidebar_topImgBlock">
                  {/* <img src="assets/images/user-avatar-2.jpg" alt="" /> */}
                  <img src={`data:image/jpg;base64,${base64String}`} alt="" />
                </div>
                <div className="newUserSidebar_topTextBlock">
                  <h3 className="newUserSidebar_topTextBlock_h3">
                    {namePrefix}&nbsp;{practiceOwnerName || "NA"}
                  </h3>
                  {!isLawFirmNameSameAsPracticeOwnerName && (
                    <h4 className="newUserSidebar_topTextBlock_h4">
                      {lawFirmName || "NA"}
                    </h4>
                  )}
                </div>
                <div className="newUserSidebar_topButtonBlock">
                  <Link to="/kyc">
                    <button className="btn_appearance_none newUserSidebar_topButtonEdit">
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
              <div className="newUserSidebar_detailsSection">
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>
                    <b>KYC Details</b>
                  </h4>
                  <p>
                    {isKYCVerified === true ? (
                      <span style={{ color: "green" }}>Verified</span>
                    ) : (
                      <span style={{ color: "red" }}>Verification Pending</span>
                    )}
                  </p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>About Me</h4>
                  <p
                    style={{
                      wordBreak: this.isAnyWordInTextLongerThan25(
                        aboutThePractice
                      )
                        ? "break-all"
                        : "normal",
                    }}
                  >
                    {aboutThePractice || "NA"}
                  </p>
                </div>
                {/* <div className="newUserSidebar_detailsSecBlock">
                  <h4>Educational Qualifications</h4>
                  <p>{"Educational Qualifications" || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Website</h4>
                  <p>{website || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>LinkedIn</h4>
                  <p>{linkedInUrl || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Official Email Address</h4>
                  <p>{officialEmailAddress || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Official Phone Number</h4>
                  <p>{officialPhoneNumber || "NA"}</p>
                </div> */}
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>University/College</h4>
                  <p>{graduationUniversityName || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Work Experience (in Years)</h4>
                  <p>{workExperience || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Specialisations/Practice Areas</h4>
                  <p>{practiceAreasText || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Locations Active</h4>
                  <p>{locationsActiveText || "NA"}</p>
                </div>
                {/* <div className="newUserSidebar_detailsSecBlock">
                  <h4>Membership and Affiliations</h4>
                  <p>{"Membership and Affiliations" || "NA"}</p>
                </div> */}
              </div>
            </div>
          </div>
        ) : kYCFetchError ? (
          kYCFetchError
        ) : (
          "KYC not yet submitted by you."
        )}
      </React.Fragment>
    );
  };

  addProfInfoSection = () => {
    const { pageNumber, numPages, pDFLoadErr } = this.state;

    return (
      <div className="newUserSection_rightBg">
        <div className="newUserSection_rightBlock">
          {/* -- New User Right part - top title start --  */}
          <div className="newUserSection_rightTopTitle">
            <div className="newUserSection_rightTopTitle_left">
              <h3>Additional Profile Information</h3>
            </div>
          </div>
          {/* -- New User Right part - top title end --  */}
          {/* -- New User Right part - With Blank Message start --  */}
          {!this.state.base64PDF && (
            <div className="newUserSection_blankMsg">
              <h3>
                To increase your chances of getting tasks, please upload the
                completed Additional Profile Information template.
              </h3>
              <h3>Please use Google Sheets to edit the template.</h3>
            </div>
          )}
          {/* -- New User Right part - With Blank Message end --  */}
          {/* -- New User Right part - With API backend structurfe start --  */}
          {this.state.base64PDF && (
            <div className="newUserSection_pdfView_bg">
              {/* === Dynamic Invoice Import section (copy Canvus HTML code) -- 01 start ===    */}
              <div className="invoiceviewpage forpdfviewcustclass newUserSection_forpdfviewcustclass">
                {pDFLoadErr === false && (
                  <div className="okPdf">
                    <div className="pdfpagi">
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                      <button
                        className="prevClass"
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.triggerPrevNextPdfPage("PREV");
                        }}
                      >
                        Previous
                      </button>
                      <button
                        className="nextClass"
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.triggerPrevNextPdfPage("NEXT");
                        }}
                      >
                        Next
                      </button>
                    </div>
                    <div className="react-pdf__Document">
                      <div
                        className="react-pdf__Page"
                        style={{ position: "relative" }}
                        data-page-number="1"
                      >
                        {/* <img
                      src="assets/images/canvas.png"
                      alt=""
                    /> */}
                        <Document
                          file={`data:application/pdf;base64,${
                            this.state.base64PDF ? this.state.base64PDF : ""
                          }`}
                          onLoadSuccess={this.onPDFLoadSuccess}
                          onLoadError={this.onPDFLoadErr}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </div>
                    </div>
                  </div>
                )}
                {pDFLoadErr === true && (
                  <div className="errPdf text-center">
                    Something went wrong. Please try later.
                  </div>
                )}
              </div>
              {/* === Dynamic Invoice Import section (copy Canvus HTML code) -- 01 end ===    */}
            </div>
          )}
          {/* -- New User Right part - With API backend structurfe end --  */}
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="kyc_section_part_01">
          <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section lp_settingContAreaBg invGen_toptab_section">
            {/* ===  DashBoard Main Body Start ===  */}
            <div className="cutom_container cutom_container_userSetting">
              <div className="create_task_area userSetting_area">
                {/* ===  task Listing filter start === */}
                <div className="task_area_filterbg task_area_filterbg_lp">
                  <div className="filter_area ">
                    {/* ===  task top filter start ===  */}
                    {/* --- body top filter tab left start ---  */}
                    <div className="top_filter_left top_filter_left_lp">
                      <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                        {/* == invoice generator ul start ==   */}
                        <div className="profile_setting_ulbg pr_set_ulbg">
                          <ul>
                            <li>
                              <NavLink
                                to="/kyc"
                                className="btn_appearance_none task_ass_tabBtn"
                              >
                                <span className="task_ass_span">
                                  KYC Details
                                </span>
                              </NavLink>
                            </li>
                            <li className="active">
                              <NavLink
                                to="/profile"
                                className="btn_appearance_none task_ass_tabBtn"
                              >
                                <span className="task_ass_span">Profile</span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        {/* == invoice generator ul end ==   */}
                        <div className="clearboth"></div>
                      </div>
                    </div>
                    {/* --- body top filter tab left end ---  */}
                    {/* --- body top filter right start ---  */}
                    <div className="top_filter_right">
                      {/* ---  new profile view  page top right section button start ---   */}
                      <div className="newProViewTopBtn_topFilRightSec">
                        <div className="docList_hd_right newProViewTopBtn_btnBlock">
                          <div className="upload-btn-wrapper">
                            <label className="new_upbutton" for="upload">
                              <button className="btn_appearance_none sub_grd_btn newProViewTopBtn_style">
                                <span>Upload Template</span>
                              </button>
                            </label>
                            <input
                              ref={this.fileUpl}
                              multiple
                              onChange={this.trackFileSelection}
                              type="file"
                              name="files"
                            />
                            <div className="clearboth"></div>
                          </div>
                        </div>
                        <div className="docList_hd_right newProViewTopBtn_btnBlock">
                          <Link
                            className="btn_appearance_none green_btn newProViewTopBtn_style ancherBtn_download"
                            to="download/Additional_Profile_Information_Template.xlsx"
                            target="_blank"
                            download
                          >
                            <span>Download Template</span>
                          </Link>
                        </div>
                      </div>
                      <div className="newProViewTopBtn_btn_status">
                        {this.state.uploadingFlag && (
                          <div className="newProView_uplodingText newProView_uplodingText_fileUploding">
                            File Uploading .......
                          </div>
                        )}
                        <div className="newProView_uplodingText newProView_uplodingText_fileUpSucess">
                          {this.state.fileUplSuccessMsg}
                        </div>
                        <div className="newProView_uplodingText newProView_uplodingText_fileUpError">
                          {this.state.fileUplErrMsg}
                        </div>
                      </div>
                      {/* ---  new profile view  page top right section button end ---   */}
                    </div>
                    {/* --- body top filter right end ---  */}
                    {/* ===  task top filter end === */}
                    <div className="clearboth"></div>
                  </div>
                </div>
                {/* ===  task Listing filter end === */}
              </div>
            </div>
            {/* ===  DashBoard Main Body End ===  */}
          </div>
        </div>
        <div className="kyc_section_part_02">
          <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg newUserDetails_sectionBg ">
            {/* ===  DashBoard Main Body Start ===  */}
            <div className="cutom_container cutom_container_userSetting cutom_container_userDetailsBg">
              {/* ===========  Setting Page main body start =============   */}
              {/* ===  task Listing start start === */}

              {/* === User Setting Section / Task Listing Section Start === */}
              {/* ============== Start =================   */}

              {/* -----  scroll adjust for settting screens start -----   */}
              <div className="task_listing_scrollbg task_listing_scrollbg_proSetting userDetailsScrollSec">
                {/* ==============   User Setting Section Start =================   */}
                <div className="userSetting_area_bg userSetting_area_bg_newUserDetails">
                  {/* ==-***-== New User Details section start ==-***-== */}
                  <div className="newUserSection_areaBg newUserSection_areaBg_for_page">
                    {/* -- new User Details Left section start --  */}
                    {this.kYCDetailsSection()}
                    {/* -- new User Details Left section end --  */}
                    {/* -- new User Details Right section start --  */}
                    {this.addProfInfoSection()}
                    {/* -- new User Details Right section end --  */}
                    <div className="clearboth"></div>
                  </div>

                  {/* ==-***-== New User Details section end ==-***-== */}

                  {/* ==--== Invoice Generate Notification section start ==--== */}
                  {/* 
                            <div className="InvGen_top_noti_bg">
                                <div className="kyc_top_noti_box InvGen_kyc_top_noti_box">
                                    <p>All fields are mandatory except for the one’s marked <span className="orange_text_Label">(Optional)</span></p>
                                 </div>
                            </div>
                             */}
                  {/* ==--== Invoice Generate Notification section end ==--== */}

                  {/* ===== ** Invoice Generate main form body start =====  */}

                  {/* ===== ** Invoice Generate main form body end =====  */}
                </div>
                {/* ==============   User Setting Section end =================   */}
              </div>
              {/* -----  scroll adjust for settting screens end -----   */}

              {/* ============== end =================   */}
              {/* === User Setting Section / Task Listing Section end === */}

              {/* ===  task Listing start end === */}
              {/* ===========  Setting Page main body end =============   */}
            </div>
            {/* ===  DashBoard Main Body End ===  */}
          </div>
          {/* ===  DashBoard Main Body Footer Start ===  */}
          {/* User Setting footer strat */}
          {/* *****  Put as Dispaly: None ***** */}

          {/* 
            <div className="task_details_btn_area invoPay_footer kyc_footer">
                <div className="btn_fld">
                    <div className="panel50">
                        <button className="btn_appearance_none green_btn"><span>Save Progress</span></button>
                    </div>
                    <div className="panel50">
                        <div className="cancel_continue_btn">
                            <button type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Reset</span></button>
                            <button className="btn_appearance_none sub_grd_btn"><span>Preview</span></button>
                        </div>
                    </div>
                        <div className="clearboth"></div>
                </div>
            </div>
             */}

          {/* *****  Put as Dispaly: None ***** */}
          {/* User Setting footer end */}
          {/* ===  DashBoard Main Body Footer end ===  */}
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileNew;

// Newest1 - End
