import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import CommonContext from '../../context/CommonContext';
// import FetchKycService from '../../service/FetchKycService';
// import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
// import FetchProfileInfoService from '../../service/FetchProfileInfoService';
// import CustLoader from './CustLoader';
// import { Scrollbars } from 'react-custom-scrollbars';
const getBaseUrl = commonJsObj.getBaseUrl;
//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;

export class ManageInvoiceGeneratorGenericHeader extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state ={
            
        };
        this.scrollerRef = React.createRef();
    }
    componentDidMount() {
       this.isComponentLoaded =  true;

    }
    
    componentWillUnmount() {
        this.isComponentLoaded =  false;
    }
    render() {
        let activetabname =  this.props.activetabname!==undefined?this.props.activetabname:"";
        let disabledSectionClass = (activetabname=="newinvoicepreviewtab")?"disabled_section":"";
        return (
            <>              
                {/* Dashboard Main Screen content area 01 start */}
                <div className="kyc_section_part_01">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section lp_settingContAreaBg invGen_toptab_section">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting">
                        <div className="create_task_area userSetting_area">
                            {/* ===  task Listing filter start === */}
                            <div className="task_area_filterbg task_area_filterbg_lp">
                            <div className="filter_area ">
                                {/* ===  task top filter start ===  */}
                                {/* --- body top filter tab left start ---  */}
                                <div className="top_filter_left top_filter_left_lp">
                                <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                                    {/* == invoice generator ul start ==   */}
                                    <div className="profile_setting_ulbg pr_set_ulbg">
                                    <ul>
                                        {/* <li className="active">
                                        <button className="btn_appearance_none task_ass_tabBtn">
                                            <span className="task_ass_span">Invoice Generator</span>
                                        </button>
                                        </li>
                                        <li>
                                        <button className="btn_appearance_none task_ass_tabBtn">
                                            <span className="task_ass_span">History</span>
                                        </button>
                                        </li> */}

                                        <li className={`${(activetabname=="newinvoicepreviewtab"||activetabname=="newinvoicegeneratortab")?"active":""}`}>
                                        <button  onClick={(evn)=>{ 
                                            evn.preventDefault();
                                            this.props.history.push("/invoice-generator");
                                              }} className={`btn_appearance_none task_ass_tabBtn ${disabledSectionClass?disabledSectionClass:""}`}>
                                        <span className="task_ass_span">
                                            Invoice Generator
                                        </span>
                                        </button>
                                    </li>
                                    <li className={`${activetabname=="newinvoicehistorytab"?"active":""}`} >
                                        <button onClick={(evn)=>{ 
                                            evn.preventDefault();
                                            this.props.history.push("/invoice-history");
                                              }}  className={`btn_appearance_none task_ass_tabBtn ${disabledSectionClass?disabledSectionClass:""}`}>
                                        <span className="task_ass_span">Invoice Dashboard</span>
                                        </button>
                                    </li>
                                    </ul>
                                    </div>
                                    {/* == invoice generator ul end ==   */}
                                    <div className="clearboth" />
                                </div>
                                </div>
                                {/* --- body top filter tab left end ---  */}
                                {/* --- body top filter right start ---  */}
                                <div className="top_filter_right">
                                {/* 
                                                <div class="top_filter top_filter_3">
                                                    <div class="task_top_suggestions toggle_btn_suggestion InvGen_mandatoryFields_btn">
                                                        <span class="top_sug_left">
                                                            <p>Show only mandatory fields
                                                                <div class="switch_btn">
                                                                    <label class="switch">
                                                                        <input type="checkbox" >
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </p>
                                                        </span>
                                                        
                                                        <div class="clearboth"></div>
                                                    </div>
                                                </div> 
                                                */}
                                </div>
                                {/* --- body top filter right end ---  */}
                                {/* ===  task top filter end === */}
                                <div className="clearboth" />
                            </div>
                            </div>
                            {/* ===  task Listing filter end === */}
                        </div>
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                </div>

               {/* Dashboard Main Screen content area 01 end */}
              


            </>
        )
    }
}

export default withRouter(ManageInvoiceGeneratorGenericHeader)
