import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;

export class TakeUpTaskService extends Component {
    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/task_terms/task/';
    }
    async takeUpTask(paramObj){
        this.axiosObj = axiosCustomObj({});

        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

        try{
                let urldata =this.apiEndPoint;

                urldata = urldata+paramObj.data.taskId;

              //console.log("task single list api hitted with =>>",urldata);


              let headerOptn = {
                "Content-Type": "application/json",
                "Authorization": accToken
              };

              let respObj = this.axiosObj.put(urldata,{},{
              headers: headerOptn
              });

              return respObj;
      }
      catch(err){
          //console.log("Some error occured===>",err); // TypeError: failed to fetch
      }


    }
}

export default TakeUpTaskService
