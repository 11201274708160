import React, { Component } from "react";
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";
import CustReactBootstrapModal from "./CustReactBootstrapModal";
// for context
import CommonContext from "../../context/CommonContext";

//services
import TaskInvoiceHistoryService from "../../service/TaskInvoiceHistoryService";
import GenerateInvoiceService from "../../service/GenerateInvoiceService";
import FetchHsnCodeService from "../../service/FetchHsnCodeService";

//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";
// Newest1 - Start
import { Scrollbars } from "react-custom-scrollbars";
// Newest1 - End
import { Multiselect } from "multiselect-react-dropdown";

// For loading CustLoader
import CustLoader from "./CustLoader";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;
const validateBlankSpace = commonJsObj.validateBlankSpace;

const isReverseGstApplicableOptns = [
  { key: "Select", value: "" },
  { key: "Yes", value: "YES" },
  { key: "No", value: "NO" },
];

export class SplitScreenTaskInvoiceView extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;
  constructor(props) {
    super(props);
    this.children = props.children;
    this.state = {
      loaderFlag: true,
      taskId: "",
      invoiceInfo: [],
      downloadUrl: null,
      isPaid: false,
      showGstModal: false,

      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,

      isReverseGstApplicable: "",
      hsn: "",
      hsnAr: [],
      hsnOther: "",

      //err msg
      isReverseGstApplicableErMsg: "",
      hsnErMsg: "",
      hsnOtherErMsg: "",
      submitInvGenFlag: false,
    };
    this.scrollerRef = React.createRef();
    this.modalforGstRef = React.createRef();
    this.hsnOptnRef = React.createRef();
  }
  componentDidMount() {
    this.isComponentLoaded = true;
    let taskId = 0;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        taskId = sharedData.taskId;

        this.isComponentLoaded &&
          this.setState({ taskId: taskId }, () => {
            this.callServiceToFetchTaskInvoiceHistory(
              { taskId: this.state.taskId },
              "INVOICEVIEWCOMPONENTLOADED"
            );
          });
      }
    }
  }
  callServiceToFetchTaskInvoiceHistory = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = 1;
    paramObjData.taskId = paramObj.taskId;
    //console.log(" trackcalledfrom=>",trackcalledfrom," paramObjData=>",paramObjData);

    let stcservice = new TaskInvoiceHistoryService();
    stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            // console.log(respData,"respData");
            if (respData.invoices !== undefined && respData.invoices !== null) {
              if (respData.invoices.length > 0) {
                let invoiceInfo = respData.invoices;
                let downloadUrl = null;
                let isPaid = false;
                if (
                  invoiceInfo &&
                  invoiceInfo[0].downloadUrl !== undefined &&
                  invoiceInfo[0].downloadUrl !== null
                ) {
                  downloadUrl =
                    invoiceInfo[0].downloadUrl +
                    "&auth=" +
                    getTokenFromLocStorage();
                }

                if (
                  invoiceInfo &&
                  invoiceInfo[0].isPaid !== undefined &&
                  invoiceInfo[0].isPaid !== null
                ) {
                  isPaid = invoiceInfo[0].isPaid;
                }

                this.isComponentLoaded &&
                  this.setState(
                    {
                      invoiceInfo: invoiceInfo,
                      downloadUrl: downloadUrl,
                      isPaid: isPaid,
                    },
                    () => {
                      //console.log(" state invoiceInfo=>",this.state);
                    }
                  );
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }
  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }
  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }
  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //     let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //     if(setShare){
    //         let paramsCntxt = {
    //         "taskId":"",
    //         "actionType":"",
    //         "leftTabToActive":"",
    //         "purpose":"",
    //         "other":{}
    //         }
    //         setShare(paramsCntxt);
    //     }
    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "TASK_INVOICE_VIEW_SCREEN",
    });
  };
  gstModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showGstModal: true }, () => {
        this.modalforGstRef.current.showModal();
        this.getGstModalContent();
      });
  };
  gstModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showGstModal: false }, () => {
        this.modalforGstRef.current.hideModal();
      });
  };
  generateInvoice = (paramObjData) => {
    let service = new GenerateInvoiceService();
    service
      .generateInvoice({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respObj =
            data.data.entity !== undefined ? data.data.entity : null;
          if (respObj) {
            let invoiceInfo = [];
            invoiceInfo.push(respObj);
            let downloadUrl =
              respObj.downloadUrl !== undefined ? respObj.downloadUrl : null;
            if (downloadUrl) {
              downloadUrl = downloadUrl + "&auth=" + getTokenFromLocStorage();
            }
            this.setState(
              { invoiceInfo: invoiceInfo, downloadUrl: downloadUrl },
              () => {
                // console.log(this.state,"after create invoice")
                this.gstModalClose();
              }
            );
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";

            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,
              msgKeyData: "msgNotiBudgGenModK1",
              dismiss: {
                duration: 2500,
              },
            });
            setTimeout(() => {
              this.getGstModalContent();
            }, 1100);
          }
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ submitInvGenFlag: false }, () => {
            this.getGstModalContent();
          });
      });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.isComponentLoaded && this.setState({ numPages }, () => {});
  };
  onDocumentLoadErr = (error) => {
    //console.log(" === error=>>",error);
    this.isComponentLoaded && this.setState({ pdfLoadErr: true }, () => {});
  };
  triggerPrevNextPdfPage = (action, param) => {
    let pageNumber = this.state.pageNumber;
    let numPages = this.state.numPages;
    if (action == "PREV") {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1;
      }
    } else if (action == "NEXT") {
      if (numPages !== null && pageNumber != numPages) {
        pageNumber = pageNumber + 1;
      }
    }
    this.isComponentLoaded &&
      this.setState({ pageNumber: pageNumber }, () => {});
  };

  custValidate = () => {
    let isReverseGstApplicableErMsg = "";
    let hsnErMsg = "";
    let hsnOtherErMsg = "";

    let totErrCountTrack = [];

    if (validateBlankSpace(this.state.isReverseGstApplicable) == true) {
      isReverseGstApplicableErMsg = "IsReverse Gst Applicable or not.";

      totErrCountTrack.push(isReverseGstApplicableErMsg);
    }

    if (
      this.state.hsn == "OTHER" &&
      validateBlankSpace(this.state.hsnOther) == true
    ) {
      hsnOtherErMsg = "HSN code is required.";
      totErrCountTrack.push(hsnOtherErMsg);
    } else if (
      this.state.hsn != "OTHER" &&
      validateBlankSpace(this.state.hsn) == true
    ) {
      hsnErMsg = "HSN code is required.";
      totErrCountTrack.push(hsnErMsg);
    }

    let stateErData = {
      isReverseGstApplicableErMsg: isReverseGstApplicableErMsg,
      hsnOtherErMsg: hsnOtherErMsg,
      hsnErMsg: hsnErMsg,
    };

    this.isComponentLoaded && this.setState(stateErData);

    if (totErrCountTrack.length > 0) {
      return false;
    }
    return true;
  };

  handleChange = (e) => {
    let inputNm = e.target.name;
    let inputNmVal = e.target.value;
    if (inputNm == "hsnOther") {
      if (/^[0-9]+$/.test(inputNmVal) || inputNmVal === "") {
        this.isComponentLoaded &&
          this.setState({ [inputNm]: inputNmVal }, () => {
            this.getGstModalContent();
          });
      }
    } else {
      this.isComponentLoaded &&
        this.setState({ [inputNm]: inputNmVal }, () => {
          this.getGstModalContent();
        });
    }
  };

  onSelectHsn = (selectedList, selectedItem) => {
    //console.log("onSelectExp=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
    this.isComponentLoaded &&
      this.setState({ hsn: selectedItem.value }, () => {
        this.getGstModalContent();
      });
  };

  chkValidationBeforeProceed = () => {
    this.isComponentLoaded &&
      this.setState({ submitInvGenFlag: true }, () => {
        this.getGstModalContent();
      });
    const isValid = this.custValidate();
    console.log(" isValid==>>", isValid, this.state);
    if (isValid) {
      let paramObj = {
        taskId: this.state.taskId,
        is_reverse_gst_applicable:
          this.state.isReverseGstApplicable == "YES" ? false : true,
        hsn: this.state.hsn != "OTHER" ? this.state.hsn : this.state.hsnOther,
      };
      console.log(" paramObj===>>>>", paramObj);
      //setTimeout(()=>{this.getGstModalContent();},500);
      this.generateInvoice(paramObj);
    } else {
      this.isComponentLoaded &&
        this.setState({ submitInvGenFlag: false }, () => {
          this.getGstModalContent();
          // setTimeout(()=>{this.getGstModalContent();},500);
        });
    }
  };

  setHisnCodeAr = (respObj) => {
    if (respObj !== undefined && respObj && respObj.length > 0) {
      let hsnCodeArObj = [];
      for (let i = 0; i < respObj.length; i++) {
        hsnCodeArObj.push({
          key: respObj[i].hsnDescription,
          value: respObj[i].hsn,
        });
      }
      hsnCodeArObj.push({ key: "Other", value: "OTHER" });

      this.isComponentLoaded &&
        this.setState({ hsnAr: hsnCodeArObj }, () => {
          this.getGstModalContent();
        });
    }
  };

  callServiceToFetchHsnCodeService = (paramObj, trackcalledfrom) => {
    let paramObjData = {};

    //console.log(" trackcalledfrom=>",trackcalledfrom," paramObjData=>",paramObjData);

    let stcservice = new FetchHsnCodeService();
    stcservice
      .getHsnCodes({})
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            // console.log(respData,"respData");
            if (respData !== undefined && respData !== null) {
              this.setHisnCodeAr(respData);
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {});
  };

  getGstModalContent() {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">Invoice Details</h4>
          <button
            type="button"
            className="close modal_close"
            data-dismiss="modal"
            onClick={(evn) => {
              evn.preventDefault();
              this.gstModalClose();
            }}
          >
            ×
          </button>
        </div>
        {/* <div style={{"display": "none"}} className="alert_secction  ">
            <div className="alert alert-dismissible alert_custom alert-success">
                <p></p>
            </div>
        </div> */}
        {/* <div className="modal-body padding_top">
            <div className="popUP_text_p">
                <p>Will the reverse GST be applicable for this on Invoice?</p>
                <p className="popUp_yellow_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>
         */}
        <CustomMessageBar keydata={"msgNotiBudgGenModK1"} />
        <div className="modal-body padding_top modalBody_invoiceDetails">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h4>
                {/* Will the reverse GST mechanism be applicable for this invoice? */}
                Should GST be added to the billing amount?
              </h4>
              <h5 class="modal_hsn_h5_smallText">
                Please note that if you click "Yes" to this question, 18% GST
                will be added to the billing amount in this invoice, which will
                be collected by you from the task creator
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div
                className={`modal_input modal_select ${
                  this.state.isReverseGstApplicableErMsg
                    ? "form_group_error"
                    : ""
                }`}
              >
                <select
                  className="selectpicker dropup"
                  name="isReverseGstApplicable"
                  value={this.state.isReverseGstApplicable}
                  onChange={this.handleChange}
                >
                  {Object.keys(isReverseGstApplicableOptns).map((e, i) => (
                    <option
                      key={i}
                      value={isReverseGstApplicableOptns[e].value}
                    >
                      {isReverseGstApplicableOptns[e].key}
                    </option>
                  ))}
                </select>
                <span className="error_span">
                  {this.state.isReverseGstApplicableErMsg}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h4>Select the type of service with HSN code</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div
                className={`modal_input modal_select custom_single_select ${
                  this.state.hsnErMsg ? "form_group_error" : ""
                }`}
              >
                <label className="form_label_modal">
                  <b>Select HSN Code</b>
                </label>
                {/* <select name="hsn" value={this.state.hsn}
                    onChange={this.handleChange}  className="selectpicker dropup"  >
                            <option  key={"hsnkeyselect"} value={""} >Select</option>
                        {
                                                        Object.keys(this.state.hsnAr).map((e, i) => (
                                                            <option key={i} value={this.state.hsnAr[e].value} > {this.state.hsnAr[e].key}</option>
                                                        ))
                                                         } 
                            <option key={"hsnkeyother"} value={"OTHER"} >Other</option>
                        </select> */}
                <div className={"custom_single_select_box"}>
                  <Multiselect
                    options={this.state.hsnAr} // Options to display in the dropdown
                    displayValue="key"
                    singleSelect
                    onSelect={this.onSelectHsn}
                    ref={this.hsnOptnRef}
                    placeholder={"Select"}
                  />
                </div>
                <span className="error_span">{this.state.hsnErMsg}</span>
              </div>
            </div>
          </div>
          <div className="row">
            {/* == will open after the condition ==  */}
            <div className="col-md-6 col-sm-12 col-xs-12">
              {this.state.hsn == "OTHER" && (
                <div
                  className={`modal_input modal_select ${
                    this.state.hsnOtherErMsg ? "form_group_error" : ""
                  }`}
                >
                  <label className="form_label_modal">
                    <b>Enter HSN Code</b>
                  </label>
                  <input
                    name="hsnOther"
                    value={this.state.hsnOther}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Eg - 54326"
                  />
                  <span className="error_span">{this.state.hsnOtherErMsg}</span>
                </div>
              )}
            </div>
            {/* == will open after the condition ==  */}
          </div>

          <div className="popUP_text_p">
            <p className="popUp_yellow_text">
              If you need help with selecting an option please contact your tax
              consultant. By clicking on 'Submit', you agree that Flywork will
              collect the invoice amount on your behalf.
            </p>
          </div>
        </div>

        <div className="modal-footer">
          {/* <button className="btn_appearance_none green_btn" onClick={(e)=>{ e.preventDefault();
                    let paramObj = {"taskId":this.state.taskId,"is_reverse_gst_applicable":true}
                    this.generateInvoice(paramObj);
                } }><span>Yes</span></button>
            <button className="btn_appearance_none green_btn" onClick={(e)=>{ e.preventDefault();
                    let paramObj = {"taskId":this.state.taskId,"is_reverse_gst_applicable":false}
                    this.generateInvoice(paramObj);
                } }><span>No</span></button> */}
          <button
            onClick={(evn) => {
              evn.preventDefault();
              this.gstModalClose();
            }}
            type="button"
            className="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            disabled={this.state.submitInvGenFlag}
            onClick={(evn) => {
              evn.preventDefault();
              this.chkValidationBeforeProceed();
            }}
            className="btn_appearance_none green_btn"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    );

    this.modalforGstRef.current.updateStateContent(viewData);
    this.modalforGstRef.current.showContent();
    return viewData;
  }
  loadTheContent = () => {
    const { pageNumber, numPages, pdfLoadErr } = this.state;
    let downloadUrl = this.state.downloadUrl;
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    //console.log(" sharedData=>>",sharedData);

    return (
      <>
        {/**** Dashboard Slide Split Right - screen star  ***/}
        <div
          className={`splitscreen_area ${
            forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""
          }`}
        >
          {/* Newest1 - Start */}

          {/* <div className="slideScreen_bg slideScreen_Chatbg"> */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* right slide section top start */}
              {this.state.invoiceInfo.length == 0 && (
                <div className="SlideS_topbg flexbox-item slide_chat_topbg splitInvoiceTop">
                  <div className="slide_top_titlebg">
                    <div className="slideChat_head">
                      <div className="slideChat_panel">
                        <h5>Invoices</h5>
                      </div>
                      <div className="invo_top_btnbg">
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();
                            if (setShare && sharedData) {
                              // invoiceViewedPaidStatus is injected into context from SplitscreenTaskInvoice page if the invoice voice is unpaid

                              sharedData.leftTabToActive = "INVOICE";
                              sharedData.purpose = "VIEW_INVOICE_LIST";
                              sharedData.other.triggeredFrom = "VIEW_INVOICE";
                              setShare(sharedData);
                              sharedData.other.invoiceViewedPaidStatus =
                                this.state.isPaid;
                              sharedData.other.invoiceInfo =
                                this.state.invoiceInfo;
                              setShare(sharedData);
                            }
                          }}
                          className="btn_appearance_none greenText_btn"
                        >
                          Invoice history
                        </button>
                      </div>
                      <div className="clearboth"></div>
                    </div>
                    <button
                      className="btn_appearance_none slideTop_cross_btn cross"
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.closeSplit({
                          trigerredFrom: "TASK_INVOICE_VIEW_SCREEN",
                        });
                      }}
                    >
                      <img
                        src={getBaseUrl() + "assets/images/red_cross.png"}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              )}
              {this.state.invoiceInfo.length > 0 && (
                <div className="SlideS_topbg flexbox-item slide_chat_topbg splitInvoiceTop">
                  <div className="slide_top_titlebg">
                    <div className="slideChat_head">
                      <div className="slideChat_panel">
                        <h5>Invoices</h5>
                      </div>
                      <div className="invo_top_btnbg">
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();
                            if (setShare && sharedData) {
                              // invoiceViewedPaidStatus is injected into context from SplitscreenTaskInvoice page if the invoice voice is unpaid

                              sharedData.leftTabToActive = "INVOICE";
                              sharedData.purpose = "VIEW_INVOICE_LIST";
                              sharedData.other.triggeredFrom = "VIEW_INVOICE";
                              setShare(sharedData);
                              sharedData.other.invoiceViewedPaidStatus =
                                this.state.isPaid;
                              sharedData.other.invoiceInfo =
                                this.state.invoiceInfo;
                              setShare(sharedData);
                            }
                          }}
                          className="btn_appearance_none greenText_btn"
                        >
                          Invoice history
                        </button>
                      </div>
                      <div className="clearboth"></div>
                    </div>
                    <button
                      className="btn_appearance_none slideTop_cross_btn cross"
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.closeSplit({
                          trigerredFrom: "TASK_INVOICE_VIEW_SCREEN",
                        });
                      }}
                    >
                      <img
                        src={getBaseUrl() + "assets/images/red_cross.png"}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              )}
              {/* right slide section top end */}
              {/* right slide section Middlie  start */}
              {this.state.invoiceInfo.length == 0 && (
                <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_invoicePdfView">
                  <div className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView">
                    <div className="s_midbox_section s_midbox_section_inv">
                      {/*   sprint - 6 invoice main structure start =*/}
                      {/* invoice import body start  */}
                      <div className="invoice_importbg">
                        <div className="invo_listBox_bg invo_listBox_bg_nodata">
                          <div className="invo_list_whiteBox invo_list_grayBox">
                            {/*=   invoice list No Data start =*/}
                            <div className=" text-center ">
                              {this.state.loaderFlag ? this.loadLoader() : null}
                            </div>
                            <div className="invoice_no_data_bg">
                              <h3>
                                Click on “Generate Invoice” button below to
                                generate an invoice.
                              </h3>
                            </div>

                            {/*=   invoice list No Data  end =*/}
                          </div>
                        </div>
                      </div>
                      {/* invoice import body end  */}
                      {/*=   sprint - 6 invoice main structure end =*/}
                    </div>
                  </div>
                </div>
              )}

              {this.state.invoiceInfo.length > 0 && (
                <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_invoicePdfView">
                  <div
                    ref={this.scrollerRef}
                    className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView"
                  >
                    {/* <Scrollbars
                      ref={this.scrollerRef}
                      style={{ height: 675 }}
                      autoHeightMin={675}
                      renderView={(props) => (
                        <div
                          {...props}
                          className="view invoiceviewscreenscrollclass"
                        />
                      )}
                      onScrollStop={this.trackScroll}
                    > */}
                    <div className="s_midbox_section s_midbox_section_inv">
                      <div className="invoice_importbg">
                        {!this.state.isPaid && (
                          <div className="top_msgBg">
                            <div className="allert_messg">
                              Invoice sent successfully to the organisation
                            </div>
                          </div>
                        )}

                        <div
                          className={`invoice_import_box ${
                            !this.state.isPaid
                              ? "invoice_import_box_top_msg"
                              : ""
                          }`}
                        >
                          {/* <embed src="assets/images/sample-pdf-01.pdf" width="100%" height="auto" /> */}
                          <div className="invoiceviewpage forpdfviewcustclass">
                            {pdfLoadErr == false && (
                              <div className="okPdf">
                                {numPages > 1 && (
                                  <div className="pdfpagi">
                                    <p>
                                      Page {pageNumber} of {numPages}
                                    </p>
                                    <button
                                      className="prevClass"
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        this.triggerPrevNextPdfPage("PREV", {});
                                      }}
                                    >
                                      Previous
                                    </button>
                                    <button
                                      className="nextClass"
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        this.triggerPrevNextPdfPage("NEXT", {});
                                      }}
                                    >
                                      Next
                                    </button>
                                  </div>
                                )}
                                <Document
                                  file={`data:application/pdf;base64,${
                                    this.state.invoiceInfo[0].base64 !==
                                      undefined &&
                                    this.state.invoiceInfo[0].base64 !== null
                                      ? this.state.invoiceInfo[0].base64
                                      : ""
                                  }`}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                  onLoadError={this.onDocumentLoadErr}
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                              </div>
                            )}
                            {pdfLoadErr == true && (
                              <div className="errPdf text-center">
                                Something went wrong, please try later.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                </div>
              )}
              {/* right slide section Middlie  end */}

              {/* right slide section Footer  start */}
              {this.state.invoiceInfo.length == 0 && (
                <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer">
                  <div className="chat_bottom_left inv_bottom_left">
                    <button
                      className="btn_appearance_none sub_grd_btn"
                      onClick={(evnt) => {
                        evnt.preventDefault();
                        this.isComponentLoaded &&
                          this.setState(
                            {
                              isReverseGstApplicable: "",
                              hsn: "",
                              hsnAr: [],
                              hsnOther: "",

                              //err msg
                              isReverseGstApplicableErMsg: "",
                              hsnErMsg: "",
                              hsnOtherErMsg: "",
                            },
                            () => {
                              this.gstModalOpen();
                              setTimeout(() => {
                                this.callServiceToFetchHsnCodeService(
                                  {},
                                  "GENERATE_INVOICE_BUTTON_CLICK"
                                );
                              }, 1000);
                            }
                          );
                      }}
                    >
                      <span>Generate Invoice</span>
                    </button>
                  </div>
                </div>
              )}
              {this.state.invoiceInfo.length > 0 && (
                <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer">
                  <div className="chat_bottom_left inv_bottom_left">
                    <button
                      className="btn_appearance_none sub_grd_btn"
                      onClick={(evn) => {
                        evn.preventDefault();
                        if (downloadUrl) {
                          let winDwnObj = window.open(downloadUrl, "_blank");
                          winDwnObj.focus();
                          //window.location.href = downloadUrl;
                        }
                      }}
                    >
                      <span>Download PDF</span>
                    </button>
                  </div>
                </div>
              )}
              {/* right slide section Footer  end */}
            </div>
          </div>

          {/* Newest1 - End */}
        </div>
        {/* ==== ********* Dashboard Slide Split Right - screen end ==== ********* */}
      </>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.loadTheContent()}
        <CustReactBootstrapModal
          ref={this.modalforGstRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showGstModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg  "
          loaderbodytitle="Organisation"
        />
      </React.Fragment>
    );
  }
}

export default SplitScreenTaskInvoiceView;
