/**
 * commonstaticoptions
 * Page/Class name   : commonstaticoptions
 * Author            :
 * Created Date      : 6-10-2020
 * Functionality     : commonStaticOptionsJsFuncModule
 * Purpose           : module containing function to return static options
**/
export const commonStaticOptionsJsFuncModule = {
   

    getStaticCurrencies: (otherParam) => {
       return [
        {"key":"INR","value":"INR","currSmallUnit":"Paisa","curUnitSingular":"Rupee","curUnitPlural":"Rupees"},
        {"key":"EUR","value":"Euro","currSmallUnit":"Cent","curUnitSingular":"Euro","curUnitPlural":"Euros"},
        {"key":"USD","value":"Dollar","currSmallUnit":"Cent","curUnitSingular":"Dollar","curUnitPlural":"Dollars"}
    ];
    

    },
    getStaticExperiences: (otherParam) => {
       return [
        {"key":"1-4","value":"1-4"},
        {"key":"5-10","value":"5-10"},
        {"key":"11-15","value":"11-15"},
        {"key":"16-25","value":"16-25"},
        {"key":"26-50","value":"26-50"},
        {"key":"50+","value":"50+"},
      ];

    },
    getStaticOrgSize: (otherParam) => {
       return [
        {"size":{"key":"","value":"Organization Size"}},
        {"size":{"key":"1-4 persons","value":"1-4 persons"}},
        {"size":{"key":"5-19 persons","value":"5-19 persons"}},
        {"size":{"key":"20-99 persons","value":"20-99 persons"}},
        {"size":{"key":"100-999 persons","value":"100-999 persons"}},
        {"size":{"key":"1000+ persons","value":"1000+ persons"}},
      ];

    },
    getStaticTeamSize: (otherParam) => {
       return  [
        {"size":{"key":"","value":"Department Size"}},
        {"size":{"key":"1-4 persons","value":"1-4 persons"}},
        {"size":{"key":"5-19 persons","value":"5-19 persons"}},
        {"size":{"key":"20-99 persons","value":"20-99 persons"}},
        {"size":{"key":"100-999 persons","value":"100-999 persons"}},
        {"size":{"key":"1000+ persons","value":"1000+ persons"}},
      ];

    },
    getAdminRoleStaticOtions: (otherParam) => {
       return  [
        {"isAdmin":{"key":"","value":"Role"}},
        {"isAdmin":{"key": true,"value": "Admin"}},
        {"isAdmin":{"key": false,"value": "Team Member"}},
    
        
      ];

    },
    getCommonAllowedFileExt: function(dataObj){
      let allowedFileExt =  ['jpg','jpeg','png','doc','docx','txt','pdf','xlsx','xlsx','ppt','mp3','mp4'];
      return allowedFileExt;
      
    },
    getSplitScreenDocTabFilterOptions: function(dataObj){
      let filterOPtions =  [
        {"key":"Select","value":""},
        {"key":"Uploaded by me","value":"UPLOADED_BY_ME"},
        {"key":"Uploaded by task creator","value":"UPLOADED_BY_OTHERS"},
      
      ];
      return filterOPtions;
      
    },
   getGstVatStaticOptions: function(dataObj){
      let filterOPtions =  [
        {"key":"","value":"Select"},    
        {"key":"9% CGST 9% SGST","value":"9% CGST 9% SGST"},    
        {"key":"18% IGST","value":"18% IGST"},  
        {"key":"Reverse Charge GST","value":"Reverse Charge GST"},  
        {"key":"No GST","value":"No GST"},
      
      ];
      return filterOPtions;
      
    },
    getManageInvHistoryMainFilterOptions: function(dataObj){
      let filterOPtions =  [
        {"key":"1","value":"Grow Invoices"},    
        {"key":"2","value":"Manage Invoices"},    
      
      
      ];
      return filterOPtions;
      
    },
    getPaidUnpaidOptions: function(dataObj){
      let filterOPtions =  [
        {"key":"","value":"Status"},   
        {"key":"PAID","value":"Paid"},    
        {"key":"UNPAID","value":"Unpaid"},    
      
      
      ];
      return filterOPtions;
      
    },

    


};

