import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;

export class AddLogTimeService extends Component {
    constructor() {
        super();
        this.state = {
  
        }
        //this.axiosObj = axios;
        this.axiosObj = null;
  
        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/task_log';
      }
      async addTimeLog(paramObj){
        this.axiosObj = axiosCustomObj({});
        
          //console.log(paramObj)
        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
  
        try{
              let urldata =this.apiEndPoint;


              let offsetHr = offsetOfLocalDateTime.hr;
              let offsetMin = offsetOfLocalDateTime.min;

              let data  = paramObj.data;
              let taskId = data.taskId;
              let minutes = data.minutes?data.minutes:0;
              let dateForWhichTheTimeHasBeenLogged = data.dateForWhichTheTimeHasBeenLogged;
              let description = data.description?data.description:'';

              let params={
                minutes:minutes,
                dateForWhichTheTimeHasBeenLogged:dateForWhichTheTimeHasBeenLogged,
                description:description,
              }

              urldata =urldata + "/task/"+taskId+"?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin;
              //console.log("task log list api hitted with =>>",urldata);
  
              let headerOptn = {  
                "Content-Type": "application/json",
                "Authorization": accToken
              };          
  
              let respObj = this.axiosObj.post(urldata,params,{
              headers: headerOptn
              });
  
              return respObj;
      }
      catch(err){
          //console.log("Some error occured===>",err); // TypeError: failed to fetch
      }
  
  
      }
}

export default AddLogTimeService
