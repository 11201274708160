/**
 * CustLoader
 * Page/Class name   : CustLoader
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';



class CustLoader extends Component {
    /**
     * CustLoader
     * Function name : constructor
     * Author        :
     * Created Date  : 1-11-2019
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {
            loaderText: 'Loading...',
            className: 'col-md-12 text-center loader'
        }    
    }

    /**
     * Dashboard
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 1-11-2019
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        
    }

    /**
     * Dashboard
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 1-11-2019
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }

    /**
     * Dashboard
     * Function name : getClassName
     * Author        :
     * Created Date  : 1-11-2019
     * Purpose       : lifecycle method
     * Params        : 
    **/
     getClassName() {
           let c = this.state.className +" " +this.props.className;
           return c;
     }

    /**
     * Dashboard
     * Function name : render
     * Author        :
     * Created Date  : 1-11-2019
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>
                <div  className={this.props.className ? this.getClassName() : this.state.className}>
                    <i className="fa fa-circle-o-notch fa-spin customSpinner"></i>
                   {this.props.loaderText ? <p>{this.props.loaderText}</p> : ''}  
                </div>
             </>
            
        );
    }

  }
  export default CustLoader;