/**
 * FeedbackCommon
 * Page/Class name   : FeedbackCommon
 * Author            :
 * Created Date      : 25-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import FeedbackAddService from '../../service/FeedbackAddService';
import FeedbackFileUploadService from '../../service/FeedbackFileUploadService';

// for context
import CommonContext from '../../context/CommonContext';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';


const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;

const stateVarDefData  = {
    "showCustomModal": false,
    "uploadingFlag": false,
    "taskFiles":[],

    "sendButtonFlag":false,
    "feedback":"",
    "feedbackType":"GQ", // BR for Bug Report, GQ for General Query and NF for Feature Request.
    "attachments":[],

    //error messages
    "feedbackErMsg":"",
    "fileUplErMsg":"",
    
};

class FeedbackCommon extends Component {
    /**
     * FeedbackCommon
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = stateVarDefData;

        this.modalcustomRef = React.createRef();
        this.fileUpl = React.createRef();
    }

    /**
     * FeedbackCommon
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * FeedbackCommon
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    handleTagChange = (tags) =>{
        //console.log(" tags==>",tags); // tags contains array of data 
        this.isComponentLoaded && this.setState({tags},()=>{
  
           let paramObj = {};
            // let paramObj = {
            // "lawyerUserIdForConflChk":this.state.lawyerUserIdForConflChk,
            // "lawyerNameForConflChk": this.state.lawyerNameForConflChk,
            // "taskId":this.state.taskId,
            // "lawyerConflictCheckStatus":this.state.lawyerConflictCheckStatus
            // };
            this.getTaskInvoiceShareModalContent(paramObj);
         
  
          
        });
    }

    setFeedbackType = (paramObj) => {
        this.isComponentLoaded && this.setState({"feedbackType":paramObj.feedbackType});
    }

    handleChange = (e) => {
        this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value},()=>{
            this.getCustomModalContent({});
        }); 
      }

     /**
         * FeedbackCommon
         * Function name : custValidate
         * Author        :
         * Created Date  : 25-6-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

    let totErrCountTrack = [];  
    
    let feedbackErMsg = "";  
    

    if(!(this.state.feedback)||(this.state.feedback.length==0)|| (validateBlankSpace(this.state.feedback)==true)){
        feedbackErMsg = "Feedback is required.";        
        
        totErrCountTrack.push(feedbackErMsg);
      }else{
        //   if(this.state.feedback.length>60000){
        //     feedbackErMsg = "Feedback can be up to 60000 characters.";  
        //     totErrCountTrack.push(feedbackErMsg);   
        //   }
      } 
  
      let stateErData = {
        "feedbackErMsg": feedbackErMsg
      }
     
      this.isComponentLoaded && this.setState(stateErData);
  
    if(totErrCountTrack.length>0){ 

            // notify(notiMsg,true,{
            // "successMsg":0,
            // "customWrapClass":"customerrmidlass",
            // "showCloseButton": false, 

            // "dismiss": {
            // "duration": 1700,
            // }

            // });        
            return false;
      }
      return true;
    }

    callFeedbackService = (paramObj) =>{

        let taskFiles = this.state.taskFiles;
        let taskDocIdsAr = [];
        if(taskFiles.length>0){

            Object.keys(taskFiles).map((e,i)=>{
                taskDocIdsAr.push(taskFiles[e].id);
            });           

        }
        
        let passParam ={
            "postData":{
                "feedback":this.state.feedback,
                "feedbackType":this.state.feedbackType,
                "attachments":taskDocIdsAr
            }
         };
        

        let stcservice = new FeedbackAddService();
        stcservice.sendFeedback({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    respData = data.data.entity; 
                   
  
                    notify(respData,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "msgKeyData": "msgNotiK1",
                        "showCloseButton": false, 
            
                        "dismiss": {
                        "duration": 1700,
                        }
            
                        });       
                    setTimeout(()=>{
                              this.triggerCustomModalClose();   
                          
                    },1900);
                    
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "msgKeyData": "msgNotiK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });       
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getCustomModalContent({});
                });            
                          
            },1500);
  
            
                       
              
          });
    }

    preCallOfFeedbackService = (paramObj) =>{
        this.getCustomModalContent({});
       
        // make any validation
        let chk = this.custValidate(paramObj);
        if(chk){
            this.callFeedbackService({});
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getCustomModalContent({});
            });
        }
        
        
    }

    checkToOpenModal = (otherParam) =>{

        this.isComponentLoaded && this.setState(stateVarDefData,()=>{
            this.triggerCustomModalOpen(otherParam);

        });

        


    }

    removeDocOnClick(indx){
        let valuesData = [...this.state.taskFiles]; 
                      
                  valuesData.splice(indx,1);        
                  this.isComponentLoaded &&  this.setState({"taskFiles":valuesData},()=>{
                    this.getCustomModalContent({});
                  });
    }

    openFileUploadDialog = () =>{
        this.fileUpl.current.click();
      }
    
      trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;
  
        let chkFileValidation =  this.validateFileSelected(fileObj);
  
        //this.calUploadFileService(fileObj);
        if(chkFileValidation){
            this.isComponentLoaded && this.setState({"uploadingFlag":true},()=>{
                this.getCustomModalContent({});
                setTimeout(()=>{
                    this.calUploadFileService(fileObj);
                },2000);
                
            });
            
            
        }else{
          this.fileUpl.current.value = "";
          this.getCustomModalContent({});
        }
    }
  
    validateFileSelected(fileObj){
        const maxFileUploadAllowed = 100000000;
        const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
        const allowedFileExt = getCommonAllowedFileExt();
  
        const taskFiles = this.state.taskFiles;        
        const docsData = taskFiles;
        let respdataFlag =  true;
        let stepOneStFileUplErMsg = "";
        if(docsData!==undefined){
            if(docsData.length==maxFileUploadAllowed){
                //error
                respdataFlag = false;
                stepOneStFileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
                
            }else{
                if(docsData.length < maxFileUploadAllowed){
                    let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                    if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                        // check file validations like size and extension
                        let totPrevSize  = 0; 
                        Object.keys(docsData).map((k,i)=>{
                            totPrevSize = totPrevSize + docsData[k].size;                 
                        });
                        ////console.log.log("Total attempted size=>",totSize);
  
                        let totCurSize  = 0; let totalInvalidExtAr = [];
                        Object.keys(fileObj).map((k,i)=>{
                            totCurSize = totCurSize + fileObj[k].size;
                            let nameFl = fileObj[k].name;
                            let extnReceived = nameFl.split('.').pop();
                            if(allowedFileExt.indexOf(extnReceived)==-1){
                                totalInvalidExtAr.push(extnReceived);
                            }  
                        });
  
                        ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                        if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                            //error
                            respdataFlag = false;
                            stepOneStFileUplErMsg = "You have crossed total maximum upload file size.";
                        }
  
                        
                        if(totalInvalidExtAr.length>0){
                            //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                            //error
                            stepOneStFileUplErMsg = "This file type is not allowed.";
                            respdataFlag = false;
                        }
                        
  
                    }else{
                        //error
                        respdataFlag = false;
                        stepOneStFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
  
                    }
    
                }
            }
        }else{
            respdataFlag = false;
            stepOneStFileUplErMsg = "Something went wrong. Please try again.";
        }
  
        this.isComponentLoaded && this.setState({ "fileUplErMsg": stepOneStFileUplErMsg},()=>{
            //console.log(" stepOneStFileUplErMsg=>>",stepOneStFileUplErMsg);
            if(stepOneStFileUplErMsg){
                notify(stepOneStFileUplErMsg,true,{
                    "successMsg":0,
                    "customWrapClass":"customerrmidlass",
                    "showCloseButton": false, 
                    "msgKeyData": "msgNotiK1",
                    "dismiss": {
                    "duration": 1700,
                    }
        
                    }); 
            }
               
            this.getCustomModalContent({});

        });
        return respdataFlag;
    }
  
    async calUploadFileService(fileObj){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new FeedbackFileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});
  
  
        let taskTypeFlag = 0;
  
        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj
       };
  
        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity;       
  
            let prevDoc = [...this.state.taskFiles];
            let docs = entityObj;
            Object.keys(docs).map(function(key, index) { 
            // convert server response of file size from mb to bytes 
            let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
            prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
            });
            this.isComponentLoaded &&  this.setState({"taskFiles":prevDoc});
  
  
        }).catch((e)=>{
                //console.log.log("server error=>",e);
        }).finally(()=>{
            this.fileUpl.current.value = "";
            this.isComponentLoaded && this.setState({"uploadingFlag":false},()=>{
                this.getCustomModalContent({});
            });
        });
    }


    /*** Feedback modal starts  */
    /**
     * FeedbackCommon
     * Function name : triggerCustomModalOpen
     * Author        :
     * Created Date  : 25-6-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   triggerCustomModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showCustomModal:true},()=>{
      this.modalcustomRef.current.showModal();
      this.getCustomModalContent({});
    });    
      
  }

  /**
     * FeedbackCommon
     * Function name : triggerCustomModalClose
     * Author        :
     * Created Date  : 25-6-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   triggerCustomModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showCustomModal:false},()=>{
      this.modalcustomRef.current.hideModal();
    });
    }

    /**
   * FeedbackCommon
   * Function name : getCustomModalContent
   * Author        :
   * Created Date  : 25-6-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getCustomModalContent(otherParam) {  
        
      

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Feedback Form
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>


            </div>
            <CustomMessageBar keydata={"msgNotiK1"} />
            <div className="modal-body padding_top">
                <div className="modal_body_row_bg invoPay_modal_body">
                    <div className="popUP_text_p">
                    <p>
                        We highly value your feedback. Please provide your feedback below after
                        clicking on relevant options
                    </p>
                    </div>
                    <div className="modal_radio_btnBg">
                    <div className="modal_radio_btn_sec">
                        <div className="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            className="custom-control-input"
                            id="customRadio1"
                            name="feedbackType"
                            value={"GQ"}
                            checked={this.state.feedbackType=="GQ"}
                            onChange={this.handleChange}
                            

                        />
                        <label className="custom-control-label" htmlFor="customRadio1">
                            General questions
                        </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            className="custom-control-input"
                            id="customRadio2"
                            name="feedbackType"
                            value={"NF"}
                            checked={this.state.feedbackType=="NF"}
                            onChange={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor="customRadio2">
                            Feature request
                        </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            className="custom-control-input"
                            id="customRadio3"
                            name="example1"
                            name="feedbackType"
                            value={"BR"}
                            checked={this.state.feedbackType=="BR"}
                            onChange={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor="customRadio3">
                            Bug report
                        </label>
                        </div>
                    </div>
                    {/* <span className="error_span">Error Meassages Show Here</span> */}
                    </div>
                    <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className={`modal_input form__group field ${ this.state.feedbackErMsg?'form_group_error':''}`} >
                        <textarea
                            placeholder="Enter description "
                            
                            name="feedback"
                            value={this.state.feedback}
                            onChange={this.handleChange}
                        />
                        {/* <span className="extra_fdbk_info">*Upto 60000 characters</span> */}
                        <span className="error_span">{this.state.feedbackErMsg}</span>
                        </div>
                    </div>
                    </div>
                    <div className="modal_attach_section">
                        <div className="modal_attach_btn_sec">
                            <div className="modal_attach_btn">
                            <button onClick={(evn)=>{
                                evn.preventDefault();
                                this.openFileUploadDialog();

                            }}  className="btn_appearance_none greenText_btn">
                                <img src="assets/images/path.png" className="mCS_img_loaded" />
                                Attach files
                            </button>
                            <input style={{"display":"none"}} ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                            </div>
                        <span className="error_span"></span>
                        </div>
                        <div className="modal_attach_files">
                            <div className="custom_fileup_name">
                                {/* loop starts */}
                                {/* <div className="upfile_name_box">
                                    <span className="upfile_name">Sample-File-Name dfdfd</span>
                                    <button className="upfile_name_cross btn_appearance_none">
                                    <img src="assets/images/error.png" className="mCS_img_loaded" />
                                    </button>
                                </div>
                                 */}
                                {this.state.uploadingFlag && (
                                  <div>Uploading...</div>
                                  )}
                               
                                  {Object.keys(this.state.taskFiles).length > 0 &&
                                    Object.keys(this.state.taskFiles).map((k, i) => (
                                        <div key={i} className="upfile_name_box">
                                          <span className="upfile_name">{this.state.taskFiles[k].name}</span>
                                          <button onClick={(e)=>{
                                              e.preventDefault();

                                              this.removeDocOnClick(i);
                                              
                                              }} className="upfile_name_cross btn_appearance_none">
                                              <img src={getBaseUrl()+"assets/images/error.png"} />
                                          </button>
                                                    
                                                </div>

                                              ))
                                          }
                                
                                {/* loop ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal-footer">
                <button onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }}  type="button"  className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                       
                <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                    this.preCallOfFeedbackService(otherParam);
                });
                

                } } ><span>Send</span></button>
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalcustomRef.current.updateStateContent(viewData);
        this.modalcustomRef.current.showContent();
        return viewData;
    }    

    /*** Feedback modal related ends  */


   

    /**
     * FeedbackCommon
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <a href="" key={this.props.custkeyid?this.props.custkeyid:"menufdbklink001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        this.checkToOpenModal({});

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      {this.props.textname?this.props.textname:"Feedback"}
                </a>
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalcustomRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showCustomModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

               


             </>
            
        );
    }

  }
  export default FeedbackCommon;