import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;

export class ChatReplyService extends Component {
    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/chats/chat';
    }
    async makeChatReply(paramObj){
        this.axiosObj = axiosCustomObj({});

        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
  
        try{
                let urldata =this.apiEndPoint;

                // const defaultPageSize = getMaxPageSize();
                // let pageNum = paramObj.pageNum?paramObj.pageNum:1;
                // let pageSize = paramObj.pageSize?paramObj.pageSize:defaultPageSize;
  
                let offsetHr = offsetOfLocalDateTime.hr;
                let offsetMin = offsetOfLocalDateTime.min;

                let data  = paramObj.data;
                let ccUserId = data.ccUserId?data.ccUserId:0;


                let taskId = data.taskId?data.taskId:0;
                // let taskOrSubTask = data.taskOrSubTask?data.taskOrSubTask:"";
                let chatOfBudgetOrDueDate =data.chatType?data.chatType:"";
  
                let chatReplyData = data.chatReplyData?data.chatReplyData:{};
  
                //   if(taskOrSubTask=="STASK"){
                //     urldata = urldata+"/chat_with/"+lpUserId+"/task/"+taskIdOrSubTaskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin;
                //   }
                //   else if(taskOrSubTask=="SWSTASK"){
                //     urldata = urldata+"/chat_with/"+lpUserId+"/sub_task/"+taskIdOrSubTaskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin;
                //   }
                urldata = urldata+"/chat_with/"+ccUserId+"/task/"+taskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin;

              //console.log("task single list api hitted with =>>",urldata);

  
              let headerOptn = {
                "Content-Type": "application/json",
                "Authorization": accToken
              };          
  
              let respObj = this.axiosObj.post(urldata,chatReplyData,{
              headers: headerOptn
              });
  
              return respObj;
      }
      catch(err){
         // console.log("Some error occured===>",err); // TypeError: failed to fetch
      }


    }
}

export default ChatReplyService
