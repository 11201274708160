/**
 * NotiEmitTrack
 * Page/Class name   : NotiEmitTrack
 * Author            :
 * Created Date      : 25-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

import ee from 'event-emitter';

// for context
import CommonContext from '../../context/CommonContext';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;


const emitter = new ee();

export const commonnotitrack = (optionName,otherParam) => {
    emitter.emit('commonnotitrack', optionName, otherParam);
}



class NotiEmitTrack extends Component {
    /**
     * NotiEmitTrack
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {};

         // for event emitter 
         emitter.on('commonnotitrack',(optionName,otherParam)=>{        
             //let tabToActive = otherParam.tabToActive;
             //let trigerredFrom = otherParam.trigerredFrom;
            switch(optionName){
              case "TASK_ASSIGNED":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                  this.props.callbackofbellnotimsgclick(otherParam);
                }                    
                //this.props.callbackfunctaskassigned();
                break;
              case "NEW_TASK_REQUEST":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);    
                }
                //this.props.callbackfuncnewtaskreq();
                break;
              case "TASK_OFFERED":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);    
                }
                //this.props.callbackfuncnewtaskreq();
                break;
              case "NEGOTIATION":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);
                }
                break; 
              case "NEW_COMMENT":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);
                }
                break; 
              case "SINGLE_TASK_OFFER_ACCEPTED_SUCCESS":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);    
                }
                //this.props.callbackfuncnewtaskreq();
                break;
              case "SINGLE_TASK_OFFER_DECLINE_SUCCESS":
                if(this.props.callbackofbellnotimsgclick!==undefined){
                this.props.callbackofbellnotimsgclick(otherParam);    
                }
                //this.props.callbackfuncnewtaskreq();
                break;
              case "CLOSE_SPLITSCREEN":   
                let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
                let triggerCallback =  otherParam.triggerCallback!==undefined? otherParam.triggerCallback: false; 
                let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
                
                if(triggerCallback){
                  if(this.props.callbackofbellnotimsgclick!==undefined){
                    this.props.callbackofbellnotimsgclick(otherParam); 
                  }
                }else{
                     //********* for context starts *****
                    let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;  
                    
                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                    if(sharedData!==undefined && sharedData!==null){         

                        if(sharedData.actionType!== undefined && sharedData.actionType=="SPLITSCREEN" ){

                            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                            if(setShare){ 
                            let paramsCntxt = sharedData;
                            paramsCntxt["taskId"] = "";
                            paramsCntxt["actionType"] = "";
                            paramsCntxt["leftTabToActive"] = "";
                            paramsCntxt["purpose"] = "";
                            paramsCntxt["other"] = {};
                            setShare(paramsCntxt);                              
                            }

                            
                        }
                    }
                    //********* for context ends *****    
                }       
                break;
              case "SCROLL_TO_MAIN_HEADER":                  
                if(this.props.callbackofbellnotimsgclick!==undefined){
                    this.props.callbackofbellnotimsgclick(otherParam);
                }                    
                break;
              
                
                
            
            }          

        });

       
    }

    /**
     * NotiEmitTrack
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * NotiEmitTrack
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    /**
     * NotiEmitTrack
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
              
             </>
            
        );
    }

  }
  export default NotiEmitTrack;