// Newest1 - Start

import { Component } from "react";
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
//import axios from 'axios';
import { axiosCustomObj } from "../customisedthirdparty/AxiosCustom";
import { commonJsFuncModule as commonJsObj } from "../../common_utilities/commonjsfunc";

const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;

/**
 * MonitorFreeFlowVidMeetService
 * Page/Class name   : MonitorFreeFlowVidMeetService
 * Author            :
 * Created Date      : 10-6-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount
 * Purpose           : api service page
 **/
class MonitorFreeFlowVidMeetService extends Component {
  /**
   * MonitorFreeFlowVidMeetService
   * Function name : constructor
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : initializing state
   * Params        :
   **/
  constructor() {
    super();
    this.state = {};

    //this.axiosObj = axios;
    this.axiosObj = null;

    this.apiEndPoint = commonJsObj.getEndPoint() + "rest/video_call/task/";
    this.scheduleApiEndPoint =
      commonJsObj.getEndPoint() + "rest/video_call/schedule/task/";
  }

  /**
   * MonitorFreeFlowVidMeetService
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : life cycle method
   * Params        :
   **/
  componentDidMount() {}

  /**
   * MonitorFreeFlowVidMeetService
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : life cycle method
   * Params        :
   **/
  componentWillUnmount() {}

  /**
   * MonitorFreeFlowVidMeetService
   * Function name : startVidMeet
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : Service to start video meet immediately
   * Params        : paramObj
   **/
  async startVidMeet(paramObj) {
    this.axiosObj = axiosCustomObj({});
    let accToken = getTokenFromLocStorage();
    try {
      let urldata = this.apiEndPoint;

      urldata = urldata + paramObj.taskId;

      let headerOptn = {
        "Content-Type": "application/json",
        Authorization: accToken,
      };

      let respObj = this.axiosObj.post(
        urldata,
        {},
        {
          headers: headerOptn,
        }
      );

      return respObj;
    } catch (err) {
      // console.log("Some error occured===>", err); // TypeError: failed to fetch
    }
  }

  /**
   * MonitorFreeFlowVidMeetService
   * Function name : declineVidMeet
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : Service to decline video meet
   * Params        : paramObj
   **/
  async declineVidMeet(paramObj) {
    this.axiosObj = axiosCustomObj({});
    let accToken = getTokenFromLocStorage();
    try {
      let urldata = this.apiEndPoint;

      urldata = urldata + paramObj.taskId;

      let headerOptn = {
        "Content-Type": "application/json",
        Authorization: accToken,
      };

      let respObj = this.axiosObj.delete(urldata, {
        headers: headerOptn,
        data: {},
      });

      return respObj;
    } catch (err) {
      // console.log("Some error occured===>", err); // TypeError: failed to fetch
    }
  }

  /**
   * MonitorFreeFlowVidMeetService
   * Function name : scheduleVidMeet
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : Service to schedule video meet later
   * Params        : paramObj
   **/
  async scheduleVidMeet(paramObj) {
    this.axiosObj = axiosCustomObj({});

    let accToken = getTokenFromLocStorage();
    let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

    try {
      let urldata = this.scheduleApiEndPoint;

      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;

      let taskId = paramObj.taskId;
      let startDate = paramObj.startDate;
      let endDate = paramObj.endDate;

      urldata =
        urldata +
        taskId +
        "?from=" +
        startDate +
        "&to=" +
        endDate +
        "&offset_hour=" +
        offsetHr +
        "&offset_minute=" +
        offsetMin;

      let headerOptn = {
        "Content-Type": "application/json",
        Authorization: accToken,
      };

      let respObj = this.axiosObj.post(
        urldata,
        {},
        {
          headers: headerOptn,
        }
      );

      return respObj;
    } catch (err) {
      // console.log("Some error occured===>", err); // TypeError: failed to fetch
    }
  }
}

export default MonitorFreeFlowVidMeetService;

// Newest1 - End
