import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
export class EmailVerficationService extends Component {
    constructor() {
        super();
        this.state = {
  
        }
  
        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/noauth/lp/verify';
    }
    async getVerifyResp(paramObj){
        this.axiosObj = axiosCustomObj({});

        try{
            let urldata =this.apiEndPoint;
            urldata = urldata+"/"+paramObj.verifyCode;
            //console.log("task single list api hitted with =>>",urldata);
            let headerOptn = {
                "Content-Type": "application/json"
            };

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        //console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
    }
}

export default EmailVerficationService
