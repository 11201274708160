// Newest1 - Start

import { Component } from "react";
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
//import axios from 'axios';
import { axiosCustomObj } from "../customisedthirdparty/AxiosCustom";
import { commonJsFuncModule as commonJsObj } from "../../common_utilities/commonjsfunc";

const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;

/**
 * AddProfInfoUploadDisplayService
 * Page/Class name   : AddProfInfoUploadDisplayService
 * Author            :
 * Created Date      : 5-7-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount
 * Purpose           : api service page
 **/
class AddProfInfoUploadDisplayService extends Component {
  /**
   * AddProfInfoUploadDisplayService
   * Function name : constructor
   * Author        :
   * Created Date  : 5-7-2021
   * Purpose       : initializing state
   * Params        :
   **/
  constructor() {
    super();
    this.state = {};

    //this.axiosObj = axios;
    this.axiosObj = null;

    this.uploadDisplayApiEndPoint =
      commonJsObj.getEndPoint() + "rest/kyc/lp_profile";
  }

  /**
   * AddProfInfoUploadDisplayService
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 5-7-2021
   * Purpose       : life cycle method
   * Params        :
   **/
  componentDidMount() {}

  /**
   * AddProfInfoUploadDisplayService
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 5-7-2021
   * Purpose       : life cycle method
   * Params        :
   **/
  componentWillUnmount() {}

  /**
   * AddProfInfoUploadDisplayService
   * Function name : fileUploadDisplayService
   * Author        :
   * Created Date  : 5-7-2021
   * Purpose       : Service to upload the additional profile information of the Professional
   *                 (on the profile view screen of the professional) and generate PDF file to display on the profile view
   * Params        : fileObj
   **/
  async fileUploadDisplayService(fileObj) {
    this.axiosObj = axiosCustomObj({});

    const accToken = getTokenFromLocStorage();
    const offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

    const formData = new FormData();

    try {
      let urldata = this.uploadDisplayApiEndPoint;

      const offsetHr = offsetOfLocalDateTime.hr;
      const offsetMin = offsetOfLocalDateTime.min;

      const file = fileObj ? fileObj : null;
      const fileName = fileObj && fileObj.name ? fileObj.name : null;

      urldata =
        urldata + "?offset_hour=" + offsetHr + "&offset_minute=" + offsetMin;

      formData.append("file", file, fileName);

      // const headerOptn = {
      //   "Content-Type": "application/json",
      //   Authorization: accToken,
      // };

      // const headerOptn = {
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   Authorization: accToken,
      // };

      const headerOptn = {
        "Content-Type": "multipart/form-data",
        Authorization: accToken,
      };

      // const headerOptn = {
      //   "Content-Type":
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   Authorization: accToken,
      // };

      // const headerOptn = {
      //   Authorization: accToken,
      // };

      let respObj = this.axiosObj.post(urldata, formData, {
        headers: headerOptn,
      });

      return respObj;
    } catch (err) {
      console.log("Some error occured===>", err); // TypeError: failed to fetch
    }
  }
}

export default AddProfInfoUploadDisplayService;

// Newest1 - End
