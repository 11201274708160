/**
 * SplitScreenMonitorTaskChat
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

// for context
import CommonContext from "../../context/CommonContext";
// scroll
import { Scrollbars } from "react-custom-scrollbars";

// Modal
import CustReactBootstrapModal from "./CustReactBootstrapModal";
// Message
import CustomMessageBar from "../../messagebar/CustomMessageBar";

//services
import ChatListOfFreeFlowMonitorService from "../../service/ChatListOfFreeFlowMonitorService";
import ChatMonitorFreeFLowCommentService from "../../service/ChatMonitorFreeFLowCommentService";
import MonitorFreeFlowFileUploadService from "../../service/MonitorFreeFlowFileUploadService";
// Newest1 - Start
import MonitorFreeFlowVidMeetService from "../../service/MonitorFreeFlowVidMeetService";

import ScheduleDateRangeMod from "../../customisedthirdparty/DateRangeTodayOnwardsMod";
import { notify } from "../../messagebar/CustomMessageBar";
// Newest1 - End

import CustDate from "../../../common_utilities/CustDate";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import { commonStaticOptionsJsFuncModule as commonStaticJsObj } from "../../../common_utilities/commonstaticoptions";

// For loading CustLoader
import CustLoader from "./CustLoader";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// import {LoadSockjs} from '../../customisedthirdparty/LoadSockjs';
// import SockJS from 'sockjs-client';

import { commonnotitrack } from "./NotiEmitTrack";
import NotiEmitTrack from "./NotiEmitTrack";

// Newest1 - Start
import { throttle, debounce } from "throttle-debounce";
// Newest1 - End

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;

const getCcUserId = commonJsObj.getCcUserId;
const getBaseUrl = commonJsObj.getBaseUrl;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getCurrentUserId = commonJsObj.getCurrentUserId;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;

// websocket url

//let  sockUrl = "/ws?token="+getTokenFromLocStorage();

// subscription url to receive notification
// let subscUrl1 = "/topic/notification."+getCurrentUserId();
// var Stomp = require('stompjs');

class SplitScreenMonitorTaskChat extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  //
  clientsocket = null;

  /**
   * SplitScreenSingleTaskDetails
   * Function name : constructor
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = {
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      showFileUploadModal: false,

      agreedBudget: "",
      agreedBudgetCurrency: "",
      agreedDueDateMillis: "",
      lpUserId: 0,
      ccUserId: 0,
      taskId: 0,
      ccUserFirstName: "",
      organisationDetails: null,
      taskFiles: [],
      uploadingFlag: false,
      postReplyFrom: "SENDBUTTON", //"SENDBUTTON" or "DOCUMENTMODAL"
      sendButtonClickFlag: false,
      docModalIagreeButtonClickFlag: false,
      chatMessage: "",
      notificationIdAr: [],
      lastCommentId: 0,
      scrollToNewNotiMsgFlag: false,
      taskType: "",
      chatMessageErMsg: "",

      // Newest1 - Start
      showVidMeetModal: false,
      vidMeetLink: "",
      showVidAcceptModal: false,
      showDatePickModal: false,
      startDate: null,
      endDate: null,
      // Newest1 - End
    };

    this.modalforfileupldRef = React.createRef();
    this.scrollerRef = React.createRef();
    this.fileUpl = React.createRef();
    // Newest1 - Start
    this.modalforvidmeetRef = React.createRef();
    this.modalforvidacceptRef = React.createRef();
    this.modalfordatepickRef = React.createRef();
    this.slowDownScrollRelHit = debounce(500, this.trackScrollNewFunc);
    // Newest1 - End
  }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    // set list data to blank state
    this.showLoader();
    setTimeout(() => {
      let taskId = 0;
      let lpUserId = 0;
      let ccUserId = 0;
      let ccUserFirstName = "";
      let triggeredFrom = "";
      let agreedBudget = "";
      let agreedBudgetCurrency = "";
      let agreedDueDateMillis = "";
      let organisationDetails = null;

      let taskType = "";

      //********* for context starts *****
      let sharedData =
        this.context !== undefined && this.context.share !== undefined
          ? this.context.share
          : null;
      if (
        sharedData !== undefined &&
        sharedData !== null &&
        sharedData.actionType !== undefined
      ) {
        if (sharedData.other !== undefined && sharedData.other !== null) {
          triggeredFrom =
            sharedData.other.triggeredFrom !== undefined
              ? sharedData.other.triggeredFrom
              : "";
          //*** when user comes by clicking on monitor icon from split screen left menu starts

          //console.log(" sharedData.other==>",sharedData.other);

          if (
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            let taskResp = sharedData.other.taskResp;

            taskId =
              taskResp.id !== undefined && taskResp.id !== null
                ? taskResp.id
                : 0;

            agreedBudget =
              taskResp.agreedBudget !== undefined &&
              taskResp.agreedBudget !== null
                ? taskResp.agreedBudget
                : "";

            agreedBudgetCurrency =
              taskResp.agreedBudgetCurrency !== undefined &&
              taskResp.agreedBudgetCurrency !== null
                ? taskResp.agreedBudgetCurrency
                : "";

            agreedDueDateMillis =
              taskResp.agreedDueDateMillis !== undefined &&
              taskResp.agreedDueDateMillis !== null
                ? taskResp.agreedDueDateMillis
                : "";

            ccUserId = sharedData.other.taskResp.owner.userId;
            ccUserFirstName = sharedData.other.taskResp.owner.firstName;

            organisationDetails =
              taskResp !== null &&
              taskResp.organisationDetails !== undefined &&
              taskResp.organisationDetails !== null
                ? taskResp.organisationDetails
                : null;

            taskType =
              taskResp.taskType !== undefined && taskResp.taskType !== null
                ? taskResp.taskType
                : "";
          }

          lpUserId = getCurrentUserId();
        }
      }

      // call list api
      let paramObj = {
        pageNum: 1,
        pageNumArTrack: [],
        listData: [],
        totalNumberOfPages: 1,
        taskId: taskId,
        lpUserId: lpUserId,
        ccUserId: ccUserId,
        agreedBudget: agreedBudget,
        agreedBudgetCurrency: agreedBudgetCurrency,
        agreedDueDateMillis: agreedDueDateMillis,
        taskFiles: [],
        uploadingFlag: false,
        postReplyFrom: "SENDBUTTON",
        sendButtonClickFlag: false,
        docModalIagreeButtonClickFlag: false,
        chatMessage: "",
        ccUserFirstName: ccUserFirstName,
        organisationDetails: organisationDetails,
        taskType: taskType,
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.callServiceToFetchFreeFlowChatRespList(
            paramObj,
            "MONITORFFCHATCOMPONENTLOADED"
          );
        });
    }, 1005);

    // socket
    /*
        sockUrl = "/ws?token="+getTokenFromLocStorage();
        subscUrl1 = "/topic/notification."+getCurrentUserId();
       
        let ws = new SockJS(sockUrl);
        this.clientsocket  = Stomp.over(ws);
        
        const ref = this;
        this.clientsocket.connect( {}, function (){
          //console.log("==Yes connected ==");
          // now subscribe to
          this.subscribe(subscUrl1, (message) =>{
            // called when the client receives a STOMP message from the server
           ref.callbackSubscUrl1(message);

          });


        });
        */
    //

    // Newest1 - Start
    setTimeout(
      () => {
        this.scrollerRef &&
          this.scrollerRef.current !== null &&
          this.scrollerRef.current.addEventListener(
            "scroll",
            this.slowDownScrollRelHit
          );
      },

      5000
    );
    // Newest1 - End
  }

  /*
    callbackSubscUrl1 = (message) =>{
      // called when the client receives a STOMP message from the server
      if (message.body) {
        //console.log("=====got message with body =>>>",message.body)

        let jsonWsResp = (message.body!==undefined && message.body!==null)?JSON.parse(message.body):null;
        let webSocketTaskInfo = null;
        let webSocketNotificationInfo = null;
        let webSocketCommentInfo = null;
        let notificationId = 0;
        let taskIdFromNoti = 0;
        //taskOwner
        let ccUserIdFromNoti = 0;

        if(jsonWsResp!==null){

           webSocketTaskInfo = (jsonWsResp.webSocketTaskInfo!==undefined && jsonWsResp.webSocketTaskInfo!==null)?jsonWsResp.webSocketTaskInfo:null;

           webSocketNotificationInfo = (jsonWsResp.webSocketNotificationInfo!==undefined && jsonWsResp.webSocketNotificationInfo!==null)?jsonWsResp.webSocketNotificationInfo:null;



           webSocketCommentInfo = (jsonWsResp.webSocketCommentInfo!==undefined && jsonWsResp.webSocketCommentInfo!==null)?jsonWsResp.webSocketCommentInfo:null;


           //console.log("==paresed==>> webSocketTaskInfo=>",webSocketTaskInfo," webSocketNotificationInfo==>>",webSocketNotificationInfo, " webSocketCommentInfo==>>",webSocketCommentInfo," typeof(webSocketNotificationInfo=>)",typeof(webSocketNotificationInfo));

          if(webSocketNotificationInfo && webSocketNotificationInfo.userNotificationType!==undefined && webSocketNotificationInfo.userNotificationType=="NEW_COMMENT" ){
            // NEW_COMMENT means for free flow monitor chat

           

            notificationId = webSocketNotificationInfo.notificationId!==undefined?webSocketNotificationInfo.notificationId:0;

            taskIdFromNoti = webSocketTaskInfo.taskId!==undefined?webSocketTaskInfo.taskId:0;

            ccUserIdFromNoti = (webSocketTaskInfo.taskOwner!==undefined && webSocketTaskInfo.taskOwner!==null && webSocketTaskInfo.taskOwner.id!==undefined)?webSocketTaskInfo.taskOwner.id:0;


            let chknotificationIdExist = this.state.notificationIdAr.indexOf(notificationId)==-1? false: true;

            //console.log(" this.state.ccUserId==>",this.state.ccUserId," ccUserIdFromNoti==>",ccUserIdFromNoti, " chknotificationIdExist==>",chknotificationIdExist," taskIdFromNoti=>",taskIdFromNoti," this.state.taskId==>",this.state.taskId);
            // if the notification is intended for logged in cc user's task
            if((this.state.ccUserId==ccUserIdFromNoti) && (this.state.taskId==taskIdFromNoti) && (chknotificationIdExist==false) ){

              // if not null then push the chat reply from  cc
              if(webSocketCommentInfo){

                let pushOtherParam= {};
                pushOtherParam.cmntFrom = "FROM_NOTIFICATION";
                pushOtherParam.commentResp = webSocketCommentInfo;
                pushOtherParam.notificationId = notificationId;
                this.pushCommentToList(pushOtherParam);

              }




            }






          }


        }

      }
    }
    */

  pushCommentToList = (other) => {
    let setParam = {};
    let cmntFrom = other.cmntFrom; // FROM_NOTIFICATION or FROM_COMMENT_POST
    let commentResp = other.commentResp;
    let notifiSenderId =
      other.notifiSenderId !== undefined && other.notifiSenderId
        ? other.notifiSenderId
        : 0;

    let notificationId =
      other.notificationId !== undefined ? other.notificationId : 0;

    if (commentResp !== null) {
      // Newest1 - Start
      let prevRecords = [...this.state.listData];
      prevRecords.push(commentResp);
      let updatedRecords = prevRecords;

      let prevNotificationIdAr = [...this.state.notificationIdAr];
      prevNotificationIdAr.push(notificationId);
      let updatedNotiFiIdAr = prevNotificationIdAr;

      let scrollToNewNotiMsgFlag = true;
      let scobj = this.scrollerRef.current;

      // let scrollTop = scobj.getValues().scrollTop;
      // let scrollHeight = scobj.getScrollHeight();
      // let clientHeight = scobj.getClientHeight();

      let scrollTop = 0;
      let scrollHeight = 0;
      let clientHeight = 0;

      if (scobj !== null) {
        scrollTop = scobj.scrollTop;
        scrollHeight = scobj.scrollHeight;
        clientHeight = scobj.clientHeight;
      }

      // console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop, "(scrollHeight-clientHeight)==>",(scrollHeight-clientHeight)," cmntFrom==>",cmntFrom);

      if (cmntFrom == "FROM_NOTIFICATION") {
        // if user is at the bottom so no need to show new msg arrived
        if (scrollHeight - clientHeight == scrollTop) {
          scrollToNewNotiMsgFlag = false;
        }
      } else if (
        cmntFrom == "FROM_COMMENT_POST" ||
        cmntFrom === "FROM_VIDEO_CALL_SERVICES"
      ) {
        if (cmntFrom !== "FROM_VIDEO_CALL_SERVICES") {
          // to remove any txt from message text box
          setParam.chatMessage = "";
        }

        // if scroller is in extreme bottom then no need of noti. message
        if (scrollHeight - clientHeight == scrollTop) {
          scrollToNewNotiMsgFlag = false;
        }

        // if logged in user has posted the message then no need of noti. message
        if (
          commentResp.ownerDetails !== undefined &&
          commentResp.ownerDetails !== null
        ) {
          if (
            commentResp.ownerDetails.userId !== undefined &&
            commentResp.ownerDetails.userId == getCcUserId()
          ) {
            scrollToNewNotiMsgFlag = false;
            // newly added
            this.scrollToBottom();
          }
        }
      }

      //console.log(" scrollToNewNotiMsgFlag==>",scrollToNewNotiMsgFlag);
      setParam.listData = updatedRecords;
      setParam.notificationIdAr = updatedNotiFiIdAr;
      setParam.scrollToNewNotiMsgFlag = scrollToNewNotiMsgFlag;
      setParam.taskFiles = [];

      //console.log(" ===== setParam==>",setParam,"before set this.state.scrollToNewNotiMsgFlag==>",this.state.scrollToNewNotiMsgFlag);

      this.isComponentLoaded &&
        this.setState(setParam, () => {
          //console.log("after set this.state.scrollToNewNotiMsgFlag==>",this.state.scrollToNewNotiMsgFlag);
        });
      // Newest1 - End
    }
  };

  trackMonitorChatListOnRealTimeNoti = (paramObj) => {
    //console.log(" ===== REaltime track paramObj=>>>>>>",paramObj);
    let trigerredFrom =
      paramObj.trigerredFrom !== undefined ? paramObj.trigerredFrom : "";
    let notiType =
      paramObj.notiType !== undefined && paramObj.notiType
        ? paramObj.notiType
        : "";
    let taskId =
      paramObj.taskId !== undefined && paramObj.taskId ? paramObj.taskId : 0;
    let notificationId =
      paramObj.notificationId !== undefined && paramObj.notificationId
        ? paramObj.notificationId
        : 0;
    let notificationIdAr = [...this.state.notificationIdAr];

    // if single task and single task id matches with noti task id
    if (this.state.taskId == taskId) {
      if (trigerredFrom == "REAL_TIME_NOTI_TRACK") {
        if (notiType == "NEW_COMMENT") {
          let commentResp =
            paramObj.commentResp !== undefined && paramObj.commentResp
              ? paramObj.commentResp
              : null;
          if (commentResp && notificationId) {
            let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
            if (chkNotiIdPres == -1) {
              notificationIdAr.push(notificationId);

              this.isComponentLoaded &&
                this.setState({ notificationIdAr: notificationIdAr }, () => {
                  paramObj["cmntFrom"] = "FROM_NOTIFICATION";
                  this.pushCommentToList(paramObj);
                });
            }
          }
        }
      }
    }
  };

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;

    // Newest1 - Start
    this.scrollerRef.current.removeEventListener(
      "scroll",
      this.slowDownScrollRelHit
    );
    // Newest1 - End
  }

  componentDidUpdate(prevProps) {}

  setCalDate = (dt) => {
    ////console.log.log("selected date=>>",dt);
    let dtTs = getTimestampInMillisecondsFromDateObj(dt);
    //let dttOfset = getOffsetOfLocalDateTime(dt);
    this.isComponentLoaded && this.setState({ dueDateInput: dtTs });
  };

  callServiceToFetchFreeFlowChatRespList = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;

    paramObjData.taskId = paramObj.taskId;
    paramObjData.lpUserId = paramObj.lpUserId;
    paramObjData.lastCommentId = this.state.lastCommentId;

    let pageNumArTrack = [...this.state.pageNumArTrack];

    // restrict repeated pagenum related api call
    if (pageNumArTrack.indexOf(paramObjData.pageNum) > -1) {
      //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
      return false;
    } else {
      pageNumArTrack.push(paramObjData.pageNum);
      this.isComponentLoaded &&
        this.setState({ pageNumArTrack: pageNumArTrack });
    }

    let stcservice = new ChatListOfFreeFlowMonitorService();
    stcservice
      .getChatListOfFreeFlow({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (respData.comments !== undefined && respData.comments !== null) {
              if (respData.comments.length > 0) {
                // set list data to state
                let prevRecords = [...this.state.listData];
                let currRecords = respData.comments.reverse();
                let concatenateRecords = [];
                let lastCommentId = 0;
                if (trackcalledfrom == "MONITORFFCHATSCROLLUP") {
                  concatenateRecords = currRecords.concat(prevRecords);

                  //console.log(" trackcalledfrom=>",trackcalledfrom," currRecords=>",currRecords, " prevRecords=>",prevRecords," concatenateRecords=>",concatenateRecords);
                } else {
                  concatenateRecords = prevRecords.concat(currRecords);
                }

                lastCommentId = concatenateRecords[0]
                  ? concatenateRecords[0].commentId
                  : 0;

                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: concatenateRecords,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      lastCommentId: lastCommentId,
                    },
                    () => {
                      // make scroller scroll down for this scenario
                      let scobj = this.scrollerRef.current;
                      if (trackcalledfrom == "MONITORFFCHATSCROLLUP") {
                        // Newest1 - Start
                        // let scrollToTop = scobj.getValues().scrollTop + 150;
                        // scobj.scrollTop(scrollToTop);
                        // Newest1 - End
                      } else {
                        // Newest1 - Start
                        // scobj.scrollToBottom();
                        this.scrollToBottom();
                        // Newest1 - End
                      }
                    }
                  );
              }

              // let scobj = this.scrollerRef.current;
              // let scrollToTop = (scobj.getValues().scrollTop) -10;
              // scobj.scrollTop(scrollToTop);
              // scobj.hideTracksWhenNotNeeded = true
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  closeSplit = (objParam) => {
    // //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    // //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "MONITOR_TASK_CHAT_LIST",
    });
  };

  /**
   * TaskListOfSingleTask
   * Function name : loadLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Load loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * TaskListOfSingleTask
   * Function name : hideLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * TaskListOfSingleTask
   * Function name : showLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }
  reloadDataOfChatList = () => {
    // call list api
    let paramObj = {
      pageNum: 1,
      pageNumArTrack: [],
      listData: [],
      totalNumberOfPages: 1,
      taskId: this.state.taskId,
      lpUserId: this.state.lpUserId,
      ccUserId: this.state.ccUserId,
      agreedBudget: this.state.agreedBudget,
      agreedBudgetCurrency: this.state.agreedBudgetCurrency,
      agreedDueDateMillis: this.state.agreedDueDateMillis,
      taskFiles: [],
      uploadingFlag: false,
      postReplyFrom: "SENDBUTTON",
      sendButtonClickFlag: false,
      docModalIagreeButtonClickFlag: false,
      chatMessage: "",
      notificationIdAr: [],
      lastCommentId: 0,
      scrollToNewNotiMsgFlag: false,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.showLoader();

        setTimeout(() => {
          // Newest1 - Start
          // let scobj = this.scrollerRef.current;
          // scobj.scrollToBottom();
          // Newest1 - End

          this.callServiceToFetchFreeFlowChatRespList(
            paramObj,
            "RELOADMONITORFFCHAT"
          );
        }, 900);
      });
  };

  // Newest1 - Start
  // trackScroll = () => {
  //   let scobj = this.scrollerRef.current;
  //   let scrollTop = scobj.getValues().scrollTop;
  //   let scrollHeight = scobj.getScrollHeight();
  //   let clientHeight = scobj.getClientHeight();

  //   let prevPageNum = this.state.pageNum;
  //   let totalNumberOfPages = this.state.totalNumberOfPages;

  //   //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

  //   // if scrolled up to extreme point
  //   if (scrollTop == 0 && prevPageNum < totalNumberOfPages) {
  //     this.showLoader();

  //     setTimeout(() => {
  //       // call list api
  //       let pageNumData = prevPageNum + 1;
  //       let paramObj = {
  //         pageNum: pageNumData,
  //         taskId: this.state.taskId,
  //         lpUserId: this.state.lpUserId,
  //         ccUserId: this.state.ccUserId,
  //       };
  //       //let paramObj = {"pageNum":pageNumData,"taskId":this.state.taskId};

  //       this.isComponentLoaded &&
  //         this.setState({ pageNum: pageNumData }, () => {
  //           this.callServiceToFetchFreeFlowChatRespList(
  //             paramObj,
  //             "MONITORFFCHATSCROLLUP"
  //           );
  //         });
  //     }, 900);
  //   }
  // };
  // Newest1 - End

  chkBeforeSend = (otherParam) => {
    let taskId = this.state.taskId;
    let lpUserId = this.state.lpUserId;

    let postReplyFrom = otherParam.postReplyFrom;

    let chatReplyData = {
      comment: "",
      attachmentIds: [],
    };

    if (postReplyFrom == "SENDBUTTON") {
      chatReplyData.comment = this.state.chatMessage;
    } else if (postReplyFrom == "DOCUMENTMODAL") {
      this.isComponentLoaded &&
        this.setState({ docModalIagreeButtonClickFlag: true }, () => {
          this.getFileUploadModalContent({});
        });
      let attachmentIdsAr = [];

      if (this.state.taskFiles.length > 0) {
        Object.keys(this.state.taskFiles).map((i, e) => {
          attachmentIdsAr.push(this.state.taskFiles[i].id);
        });
      }
      chatReplyData.comment = this.state.chatMessage;
      chatReplyData.attachmentIds = attachmentIdsAr;
    }

    let paramToPost = {
      data: {
        chatReplyData: chatReplyData,
        taskId: taskId,
        lpUserId: lpUserId,
      },
    };
    //console.log("**** from chkBeforeSendNego this.state now =>",this.state,"paramToPost==>",paramToPost);
    if (postReplyFrom == "SENDBUTTON") {
      if (!chatReplyData.comment) {
        this.isComponentLoaded &&
          this.setState({ chatMessageErMsg: "No message entered" });
        return false;
      } else {
        this.isComponentLoaded &&
          this.setState(
            { sendButtonClickFlag: true, chatMessageErMsg: "" },
            () => {
              setTimeout(() => {
                // send reply to lp user
                this.sendReplyToChat(paramToPost, otherParam);
              }, 1900);
            }
          );
      }
    } else if (postReplyFrom == "DOCUMENTMODAL") {
      let taskFilesData = this.state.taskFiles;
      if (taskFilesData.length == 0) {
        // close the image upload modal
        this.fileUploadModalClose();
        return false;
      }

      this.isComponentLoaded &&
        this.setState({ docModalIagreeButtonClickFlag: true }, () => {
          setTimeout(() => {
            // send reply to lp user
            this.sendReplyToChat(paramToPost, otherParam);
          }, 1900);
        });
    }
  };

  sendReplyToChat = (paramToPost, otherData) => {
    let postReplyFrom = otherData.postReplyFrom;
    if (postReplyFrom == "DOCUMENTMODAL") {
      this.getFileUploadModalContent({});
    }

    let stcservice = new ChatMonitorFreeFLowCommentService();
    stcservice
      .makeChatReply(paramToPost)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;

            if (postReplyFrom == "DOCUMENTMODAL") {
              // close the modal
              this.fileUploadModalClose();
            }

            setTimeout(() => {
              // reload chat list
              // this.reloadDataOfChatList();

              let pushOtherParam = {};
              pushOtherParam.cmntFrom = "FROM_COMMENT_POST";
              pushOtherParam.commentResp = respData;
              this.pushCommentToList(pushOtherParam);
            }, 1100);
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        let postReplyFrom = otherData.postReplyFrom;

        if (postReplyFrom == "SENDBUTTON") {
          this.isComponentLoaded &&
            this.setState({ sendButtonClickFlag: false });
        } else {
          this.isComponentLoaded &&
            this.setState({ docModalIagreeButtonClickFlag: false });
        }
      });
  };

  triggerCancel = () => {
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    if (setShare && sharedData) {
      // assuming other shared context data has not been changed by other functionality  then this code should work
      sharedData.leftTabToActive = "TASKDETAILS";
      sharedData.purpose = "VIEW";
      setShare(sharedData);
    }
  };

  loadfooterOfFreeFlowChat = () => {
    return (
      <>
        {/* === right slide section Footer  start ===  */}
        {/* Newest1 - Start */}
        {this.state.taskType != "CLOSED_TASK" && (
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_monitorTask">
            {/* Newest1 - End */}
            <div className="chat_bottom_left">
              {/* <p>Select a response from above to continue</p> */}
              <div className="chat_bottom_inputBg">
                {/*  FileUp Message input start */}
                <div className="chatFoot_inputGrp chatFoot_textMsg_grp">
                  <div className="cht_input_grp">
                    <div
                      className={`fileUpMsg_input ${
                        this.state.chatMessageErMsg ? "form_group_error" : ""
                      } `}
                    >
                      {/* <input type="text" value={this.state.chatMessage} onChange={(evn)=>{ evn.preventDefault(); this.setStatesForChat("chatMessage",evn.target.value,{}) }} placeholder="Enter message here" /> */}
                      <textarea
                        type="text"
                        value={this.state.chatMessage}
                        onChange={(evn) => {
                          evn.preventDefault();
                          this.setStatesForChat(
                            "chatMessage",
                            evn.target.value,
                            {}
                          );
                        }}
                        placeholder="Enter message here"
                      />
                      <span className="error_span">
                        {this.state.chatMessageErMsg}
                      </span>
                    </div>
                  </div>
                </div>
                {/*  FileUp Message input end */}
              </div>
            </div>

            <div className="s_footer_btnbg">
              {this.state.uploadingFlag && <div>Uploading...</div>}
              <div className="chatFoot_upBtn">
                <div className="upload-btn-wrapper">
                  <label className="new_upbutton" htmlFor="upload">
                    <button className="uploadbtn">
                      <img
                        src={getBaseUrl() + "assets/images/path.png"}
                        alt=""
                      />
                    </button>
                  </label>
                  <input
                    ref={this.fileUpl}
                    multiple
                    onClick={this.trackFileControlOnClick}
                    onChange={this.trackFileSelection}
                    type="file"
                    name="files"
                  />
                  <div className="clearboth" />
                </div>
              </div>
              <button
                onClick={(evn) => {
                  evn.preventDefault();
                  this.triggerCancel();
                }}
                className="btn_appearance_none blank_btn"
              >
                <span>Cancel</span>
              </button>
              <button
                disabled={this.state.sendButtonClickFlag}
                onClick={(evn) => {
                  evn.preventDefault();
                  this.chkBeforeSend({ postReplyFrom: "SENDBUTTON" });
                }}
                className="btn_appearance_none sub_grd_btn"
              >
                <span>Send</span>
              </button>
            </div>
          </div>
        )}
        {/* === right slide section Footer  end ===  */}
      </>
    );
  };

  scrollDownToNewMessages = (other) => {
    this.isComponentLoaded &&
      this.setState({ scrollToNewNotiMsgFlag: false }, () => {
        // scroll bottom
        setTimeout(() => {
          // Newest1 - Start
          // let scobj = this.scrollerRef.current;
          // scobj.scrollToBottom();

          this.scrollToBottom();
          // Newest1 - End
        }, 1200);
      });
  };

  // Newest1 - Start

  scrollToBottom = () => {
    //console.log(" ***** called scrollToBottom *****");
    this.scrollerRef.current.scrollTop = this.scrollerRef.current.scrollHeight;
  };

  fetchMoreData = () => {
    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    // if scrolled up to extreme point
    if (prevPageNum < totalNumberOfPages) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = {
          pageNum: pageNumData,
          taskId: this.state.taskId,
          lpUserId: this.state.lpUserId,
          ccUserId: this.state.ccUserId,
        };
        //let paramObj = {"pageNum":pageNumData,"taskId":this.state.taskId};

        this.isComponentLoaded &&
          this.setState({ pageNum: pageNumData }, () => {
            this.callServiceToFetchFreeFlowChatRespList(
              paramObj,
              "MONITORFFCHATSCROLLUP"
            );
          });
      }, 900);
    }
  };

  trackScrollNewFunc = () => {
    if (this.scrollerRef !== null && this.scrollerRef.current.scrollTop == 0) {
      //this.scrollerRef.current.scrollTop = 75;
      this.fetchMoreData();
    }
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : declineVidAcceptService
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To decline vidAccept service
   * Params        :
   **/
  async declineVidAcceptService() {
    let vidMeetServ = new MonitorFreeFlowVidMeetService();

    let paramObjData = {
      taskId: this.state.taskId,
    };

    vidMeetServ
      .declineVidMeet(paramObjData)
      .then((data) => {
        if (data.status === 200) {
          //console.log("success resp=>",data);
          let entityObj = data.data.entity;
          // let message = data.data.message;
          // console.log(message + ": " + entityObj);
          let notiMsg = "Declined successfully.";

          notify(notiMsg, true, {
            successMsg: 1,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            dismiss: {
              duration: 1000,
            },
          });

          setTimeout(() => {
            this.vidAcceptModalClose();

            let pushOtherParam = {};
            pushOtherParam.cmntFrom = "FROM_VIDEO_CALL_SERVICES";
            pushOtherParam.commentResp = entityObj;
            this.pushCommentToList(pushOtherParam);
          }, 1150);
        }
      })
      .catch((err) => {
        //console.log("server error=>",err);
        if (err.response && err.response.data) {
          let errRespData = err.response.data;
          let errMsg = errRespData.message
            ? errRespData.message
            : "Some technical problem occurred.";
          let notiMsg = errMsg;

          notify(notiMsg, true, {
            successMsg: 0,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            dismiss: {
              duration: 2500,
            },
          });
        }
      })
      .finally(() => {
        //console.log("finally");
      });
  }

  /*** Video Accept Modal starts  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : vidAcceptModalClose
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To close vidAccept modal
   * Params        :
   **/
  vidAcceptModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showVidAcceptModal: false }, () => {
        this.modalforvidacceptRef.current.hideModal();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : openVidAcceptLink
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To open vidAcceptLink in a new tab
   * Params        : vidAcceptLink
   **/
  openVidAcceptLink = (vidAcceptLink) => {
    if (vidAcceptLink) {
      const newWindow = window.open(
        vidAcceptLink,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    } else {
      // console.log("vidAcceptLink is unavailable!");
    }
    this.vidAcceptModalClose();
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : vidAcceptModalDecline
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To decline (API) vidAccept modal
   * Params        :
   **/
  vidAcceptModalDecline = () => {
    this.isComponentLoaded &&
      this.setState({ showVidAcceptModal: false }, () => {
        this.declineVidAcceptService();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : getVidAcceptModalContent
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : Get vidAccept modal view with data
   * Params        : indivData
   **/
  getVidAcceptModalContent = (indivData) => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            Join video meet
            {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.vidAcceptModalClose();
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <CustomMessageBar />
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              You are about to join a video meet with&nbsp;
              {indivData.commentedBy}. Are you sure you want to continue?
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.vidAcceptModalDecline();
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Decline</span>
          </button>
          <button
            type="button"
            className="btn btn-primary modal_btn_save"
            onClick={(e) => {
              e.preventDefault();
              this.openVidAcceptLink(indivData.videoCallDetails.videoLink);
            }}
            data-dismiss="modal"
          >
            <span>Join</span>
          </button>
        </div>
      </div>
    );

    this.modalforvidacceptRef.current.updateStateContent(viewData);
    this.modalforvidacceptRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : vidAcceptModalOpen
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To open vidAccept modal
   * Params        : indivData
   **/
  vidAcceptModalOpen = (indivData) => {
    this.isComponentLoaded &&
      this.setState({ showVidAcceptModal: true }, () => {
        this.modalforvidacceptRef.current.showModal();
        this.getVidAcceptModalContent(indivData);
      });
  };

  /*** Video Accept Modal ends  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : modifyComment
   * Author        :
   * Created Date  : 14-6-2021
   * Purpose       : To modify comment (list)
   * Params        : indivData
   **/
  modifyComment = (indivData) => {
    let comment = indivData.comment;
    if (
      indivData.videoCallDetails &&
      indivData.videoCallDetails.videoLink.length > 0
    ) {
      let endingTerm = " Meeting Link.";
      comment = comment.replace(endingTerm, "");
      return (
        <p>
          <p>{comment}</p>
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              indivData.ownerDetails.userId.toString() === this.state.lpUserId
                ? this.openVidAcceptLink(indivData.videoCallDetails.videoLink)
                : this.vidAcceptModalOpen(indivData);
            }}
          >
            {indivData.videoCallDetails.videoLink}
          </a>
        </p>
      );
    }
    return <p>{comment}</p>;
  };

  // Newest1 - End

  loadFreeFlowChatList = () => {
    //console.log(" ****** this.state==>",this.state);
    return (
      <>
        {/* Newest1 - Start */}
        <div
          ref={this.scrollerRef}
          className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_monitorTask itemsContainer"
        >
          {/* <Scrollbars
            ref={this.scrollerRef}
            style={{ height: 775 }}
            autoHeightMin={775}
            renderView={(props) => (
              <div
                {...props}
                className="view monitorfreeflowchatlistscrollclass"
              />
            )}
            onScrollStop={this.trackScroll}
          > */}
          {/* Newest1 - End */}
          <div className="s_midbox_section s_midbox_section_chat">
            {/* ====   sprint - 4 chat structure start ====  */}
            {/* chat mid body start  */}
            <div className="chatTab_sec">
              <div className="chatBody_sec">
                <div className="chatBody_ulBg">
                  <div className=" text-center ">
                    {this.state.loaderFlag ? this.loadLoader() : null}
                  </div>
                  <ul>
                    {this.state.listData.length > 0 &&
                      Object.keys(this.state.listData).map((e, i) => (
                        <li key={i}>
                          <div
                            className={`chatBody_box ${
                              this.state.listData[i].ownerDetails.userId ==
                              this.state.lpUserId
                                ? "chatBody_box_gray"
                                : "chatBody_box_green"
                            }`}
                          >
                            <div className="chatbody_text">
                              {/* ownr=={this.state.listData[i].ownerDetails.userId}
                          loggedUser=={this.state.ccUserId} */}
                              {/* Newest1 - Start */}
                              {/* <p>{this.state.listData[i].comment}</p> */}
                              {this.modifyComment(this.state.listData[i])}
                              {/* Newest1 - End */}
                              <div>
                                {this.state.listData[i].attachedFiles !==
                                  undefined &&
                                  this.state.listData[i].attachedFiles !==
                                    null &&
                                  this.state.listData[i].attachedFiles.length >
                                    0 && <h5>Attachment</h5>}
                                <div className="chat_fileAttach">
                                  <ul>
                                    {Object.keys(
                                      this.state.listData[i].attachedFiles
                                    ).map((ee, ii) => {
                                      return this.state.listData[i]
                                        .attachedFiles[ii].isRemoved ? (
                                        <li key={i.toString() + ii.toString()}>
                                          <div className="chat_fileName">
                                            {
                                              this.state.listData[i]
                                                .attachedFiles[ii].name
                                            }
                                            &nbsp; (
                                            {ii >= 0 && ii < 9
                                              ? ("0" + (ii + 1)).toString()
                                              : ii + 1}
                                            )
                                          </div>
                                          <div className="chat_fileDownload">
                                            Deleted
                                          </div>
                                        </li>
                                      ) : (
                                        <li key={i.toString() + ii.toString()}>
                                          <div className="chat_fileName">
                                            {
                                              this.state.listData[i]
                                                .attachedFiles[ii].name
                                            }
                                            &nbsp; (
                                            {ii >= 0 && ii < 9
                                              ? ("0" + (ii + 1)).toString()
                                              : ii + 1}
                                            )
                                          </div>
                                          <div className="chat_fileDownload">
                                            <a
                                              href={
                                                this.state.listData[i]
                                                  .attachedFiles[ii]
                                                  .downloadAddress +
                                                "&auth=" +
                                                getTokenFromLocStorage()
                                              }
                                              download={true}
                                            >
                                              Download
                                            </a>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                              <div className="monichatdatetime">
                                {this.state.listData[i].commentedAt !==
                                  undefined &&
                                this.state.listData[i].commentedAt !== null
                                  ? CustDate.custFormatDate(
                                      new Date(
                                        this.state.listData[i].commentedAt
                                      ),
                                      6
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>

                  {/* <div className="chat_refrash_btn">
                    <button onClick={(evn)=>{evn.preventDefault();
                       // reload chat list
                      this.reloadDataOfChatList();
                    }} className={`btn_appearance_none rf_btn ${this.state.loaderFlag==true?"active":""}` } >
                      <i className="fa fa-refresh" aria-hidden="true" />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            {/* chat mid body end  */}
            {/* ====   sprint - 4 chat structure end ====  */}
          </div>
          {/* Newest1 - Start */}
          {/* </Scrollbars> */}
          {/* Newest1 - End */}
        </div>

        {/* ==  chant bottom msg new start ==  */}
        <div className="chat_bottom_msg_new_bg">
          {/* <div className="chat_bottom_msg_new error_chat_msg">
          <p>Error Message show here</p>
        </div> */}

          {this.state.scrollToNewNotiMsgFlag && (
            <div
              onClick={(evn) => {
                evn.preventDefault();
                this.scrollDownToNewMessages({});
              }}
              className="chat_bottom_msg_new"
            >
              <p>New message received</p>
            </div>
          )}
        </div>
        {/* ==  chant bottom msg new start ==  */}

        {this.state.scrollToNewNotiMsgFlag && (
          <audio autoPlay hidden>
            <source src={getBaseUrl() + "notify.mp3"} type="audio/mpeg" />
          </audio>
        )}
      </>
    );
  };

  // Newest1 - Start

  /**
   * SplitScreenMonitorTaskChat
   * Function name : scheduleVidMeetService
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : To schedule vidMeet later service
   * Params        :
   **/
  async scheduleVidMeetService() {
    let vidMeetServ = new MonitorFreeFlowVidMeetService();

    let paramObjData = {
      taskId: this.state.taskId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    vidMeetServ
      .scheduleVidMeet(paramObjData)
      .then((data) => {
        if (data.status === 200) {
          //console.log("success resp=>",data);
          let entityObj = data.data.entity;
          // let message = data.data.message;
          // console.log(message + ": " + entityObj);
          let notiMsg = "Scheduled successfully.";

          notify(notiMsg, true, {
            successMsg: 1,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            dismiss: {
              duration: 1000,
            },
          });

          setTimeout(() => {
            this.datePickModalClose();

            let pushOtherParam = {};
            pushOtherParam.cmntFrom = "FROM_VIDEO_CALL_SERVICES";
            pushOtherParam.commentResp = entityObj;
            this.pushCommentToList(pushOtherParam);
          }, 1150);
        }
      })
      .catch((err) => {
        //console.log("server error=>",err);
        if (err.response && err.response.data) {
          let errRespData = err.response.data;
          let errMsg = errRespData.message
            ? errRespData.message
            : "Some technical problem occurred.";
          let notiMsg = errMsg;

          notify(notiMsg, true, {
            successMsg: 0,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            dismiss: {
              duration: 2500,
            },
          });
        }
      })
      .finally(() => {
        //console.log("finally");
      });
  }

  /**
   * SplitScreenMonitorTaskChat
   * Function name : scheduleDateRange
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : To store & then fix (API) the scheduled date range
   * Params        : startDate, endDate
   **/
  scheduleDateRange = (startDate, endDate) => {
    this.isComponentLoaded &&
      this.setState({ startDate, endDate }, () => {
        this.scheduleVidMeetService();
      });
  };

  /*** Date Pick Modal starts  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : datePickModalClose
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : To close datePick modal
   * Params        :
   **/
  datePickModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showDatePickModal: false }, () => {
        this.modalfordatepickRef.current.hideModal();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : getDatePickModalContent
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : Get datePick modal view with data
   * Params        :
   **/
  getDatePickModalContent = () => {
    let viewData = (
      <ScheduleDateRangeMod
        modalHeaderTitle="Schedule Video Meet"
        modalBodyInfo={
          <React.Fragment>
            Select the start and end dates to schedule.&nbsp;You can select a
            slot with a maximum duration of 1 hour.
          </React.Fragment>
        }
        submitButtonText="Schedule"
        getDateRange={this.scheduleDateRange}
        closingFunction={this.datePickModalClose}
      />
    );

    this.modalfordatepickRef.current.updateStateContent(viewData);
    this.modalfordatepickRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : datePickModalOpen
   * Author        :
   * Created Date  : 15-6-2021
   * Purpose       : To open datePick modal
   * Params        :
   **/
  datePickModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showDatePickModal: true }, () => {
        this.modalfordatepickRef.current.showModal();
        this.getDatePickModalContent();
      });
  };

  /*** Date Pick Modal ends  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : openVidMeetLink
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : To open vidMeetLink in a new tab
   * Params        :
   **/
  openVidMeetLink = () => {
    let meetURL = this.state.vidMeetLink;
    if (meetURL) {
      const newWindow = window.open(meetURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      // console.log("vidMeetLink is unavailable!");
    }
    this.vidMeetModalClose();
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : setOpenVidMeetLink
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : To set vidMeetLink state & open vidMeetLink
   * Params        : videoLink
   **/
  setOpenVidMeetLink = (videoLink) => {
    this.isComponentLoaded &&
      this.setState({ vidMeetLink: videoLink }, () => {
        this.openVidMeetLink();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : callVidMeetService
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : To access vidMeet service
   * Params        :
   **/
  async callVidMeetService() {
    let vidMeetServ = new MonitorFreeFlowVidMeetService();

    let paramObjData = {
      taskId: this.state.taskId,
    };

    vidMeetServ
      .startVidMeet(paramObjData)
      .then((data) => {
        if (data.status === 200) {
          //console.log("success resp=>",data);
          let entityObj = data.data.entity;
          let videoLink = entityObj.videoCallDetails.videoLink;
          // let message = data.data.message;
          // console.log(message + ": " + entityObj);

          this.setOpenVidMeetLink(videoLink);

          let pushOtherParam = {};
          pushOtherParam.cmntFrom = "FROM_VIDEO_CALL_SERVICES";
          pushOtherParam.commentResp = entityObj;
          this.pushCommentToList(pushOtherParam);
        }
      })
      .catch((err) => {
        //console.log("server error=>",err);
        if (err.response && err.response.data) {
          let errRespData = err.response.data;
          let errMsg = errRespData.message
            ? errRespData.message
            : "Some technical problem occurred.";
          let notiMsg = errMsg;

          notify(notiMsg, true, {
            successMsg: 0,
            customWrapClass: "customerrmidlass",
            showCloseButton: false,
            dismiss: {
              duration: 2500,
            },
          });
        }
      })
      .finally(() => {
        //console.log("finally");
      });
  }

  /*** Video Meet Modal starts  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : vidMeetModalClose
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : To close vidMeet modal
   * Params        :
   **/
  vidMeetModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showVidMeetModal: false }, () => {
        this.modalforvidmeetRef.current.hideModal();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : getVidMeetModalContent
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : Get vidMeet modal view with data
   * Params        :
   **/
  getVidMeetModalContent = () => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            Join video meet
            {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.vidMeetModalClose();
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <CustomMessageBar />
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              You are about to start a video meet. Are you sure you want to
              continue?
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.vidMeetModalClose();
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn btn-primary modal_btn_save"
            onClick={(e) => {
              e.preventDefault();
              this.callVidMeetService();
            }}
            data-dismiss="modal"
          >
            <span>Join</span>
          </button>
        </div>
      </div>
    );

    this.modalforvidmeetRef.current.updateStateContent(viewData);
    this.modalforvidmeetRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : vidMeetModalOpen
   * Author        :
   * Created Date  : 10-6-2021
   * Purpose       : To open vidMeet modal
   * Params        :
   **/
  vidMeetModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showVidMeetModal: true }, () => {
        this.modalforvidmeetRef.current.showModal();
        this.getVidMeetModalContent();
      });
  };

  /*** Video Meet Modal ends  */

  // Newest1 - End

  loadTheContent = () => {
    let agreedDueDateMillis = "";

    let forSplitScrnCls2Inject = "";
    let datasPassForAssignObj = {};
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    agreedDueDateMillis = this.state.agreedDueDateMillis
      ? CustDate.custFormatDate(new Date(this.state.agreedDueDateMillis), 3)
      : "";

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area 
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          {/* Newest1 - Start */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            {/* Newest1 - End */}
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item slide_chat_topbg slide_chat_topbg_lp">
                {/* Newest1 - Start */}
                <div className="slide_top_titlebg">
                  {/* <h4>Task Mailbox</h4> */}
                  {/* == Chat page header moniter task -- Video Icon Block start == */}
                  <div className="slideChat_head">
                    {/* -- title text with image drop down start --  */}
                    <div className="titleText_withImageDropdown titleText_withImageDropdown_rightVideoBtn">
                      <span className="titleText_withImageDropdown_textSpan">
                        Task communications with
                      </span>
                      <img
                        className="titleText_withImage"
                        src={
                          this.state.organisationDetails !== null &&
                          this.state.organisationDetails.orgLogo !==
                            undefined &&
                          this.state.organisationDetails.orgLogo !== null
                            ? this.state.organisationDetails.orgLogo
                            : getBaseUrl() +
                              "assets/images/user-avatar-thumb.png"
                        }
                        alt=""
                      />
                      <button
                        className="btn_appearance_none titleText_withImageDropdown_btn pointer_none"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {this.state.organisationDetails !== null &&
                        this.state.organisationDetails.organisationName !==
                          undefined &&
                        this.state.organisationDetails.organisationName !== null
                          ? this.state.organisationDetails.organisationName
                          : ""}
                      </button>
                      {/* --- optional dropdown for title image text start ---   */}
                      {/* --- optional dropdown for title image text end ---     */}
                    </div>
                    {/* -- title text with image drop down end --  */}
                    {/* -- title text with video button start --  */}
                    <div className="chatHead_calInv_btnBg chatHead_rightNewButton_sec">
                      <button
                        className="btn_appearance_none chatVideoDropBtn"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            getBaseUrl() + "assets/images/chat-video-icon.png"
                          }
                          alt=""
                        />
                      </button>
                      <div className="dropdown-menu video_chat_btn">
                        <div className="videoChatBtn_dropDown">
                          <ul>
                            <li>
                              <a
                                href=""
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.vidMeetModalOpen();
                                }}
                              >
                                Start now
                              </a>
                            </li>
                            <li>
                              <a
                                href=""
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.datePickModalOpen();
                                }}
                              >
                                Schedule later
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* -- title text with video button end --  */}
                    <div className="clearboth"></div>
                  </div>
                  {/* == Chat page header moniter task -- Video Icon Block end == */}

                  <button
                    className="btn_appearance_none slideTop_cross_btn cross"
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "MONITOR_TASK_CHAT_LIST",
                      });
                    }}
                  >
                    <img
                      src={getBaseUrl() + "assets/images/red_cross.png"}
                      alt=""
                    />
                  </button>
                  <div className="clearboth"></div>
                </div>
                {/* Newest1 - End */}
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              {/* Newest1 - Start */}
              <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_monitorTask">
                {/* Newest1 - End */}
                <div className="chatBody_topSec">
                  <div className="chatBody_top">
                    <p className="chatBody_top_p">
                      Agreed Budget -
                      <b>
                        {this.state.agreedBudgetCurrency +
                          " " +
                          this.state.agreedBudget}
                      </b>
                    </p>
                    <p className="chatBody_top_p">
                      Agreed Due Date - <b>{agreedDueDateMillis}</b>
                    </p>
                  </div>
                </div>

                {this.loadFreeFlowChatList()}
              </div>
              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}
              {this.loadfooterOfFreeFlowChat()}

              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  removeDocOnClick(i) {
    let otherParam = { action: "remove" };
    this.setStatesForChat("uplDocuments", i, otherParam);
  }

  trackFileControlOnClick = (e) => {
    this.isComponentLoaded && this.setState({ chatMessageErMsg: "" });
  };

  trackFileSelection = (e) => {
    e.preventDefault();
    let fileObj = e.target.files;

    let chkFileValidation = this.validateFileSelected(fileObj);

    //this.calUploadFileService(fileObj);
    if (chkFileValidation) {
      this.isComponentLoaded &&
        this.setState({ taskFiles: [] }, () => {
          this.calUploadFileService(fileObj);
        });
    } else {
      this.fileUpl.current.value = "";
    }
  };

  validateFileSelected(fileObj) {
    const maxFileUploadAllowed = 5;
    const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
    const allowedFileExt = getCommonAllowedFileExt();

    const taskFiles = this.state.taskFiles;
    const docsData = taskFiles;
    let respdataFlag = true;
    let stepOneStFileUplErMsg = "";
    if (docsData !== undefined) {
      if (docsData.length == maxFileUploadAllowed) {
        //error
        respdataFlag = false;
        stepOneStFileUplErMsg =
          "Sorry you cannot upload more than " +
          maxFileUploadAllowed +
          "  files in whole.";
      } else {
        if (docsData.length < maxFileUploadAllowed) {
          let nowtotAllowedCntOfFileUpl =
            maxFileUploadAllowed - docsData.length;
          if (fileObj.length <= nowtotAllowedCntOfFileUpl) {
            // check file validations like size and extension
            let totPrevSize = 0;
            Object.keys(docsData).map((k, i) => {
              totPrevSize = totPrevSize + docsData[k].size;
            });
            ////console.log.log("Total attempted size=>",totSize);

            let totCurSize = 0;
            let totalInvalidExtAr = [];
            Object.keys(fileObj).map((k, i) => {
              totCurSize = totCurSize + fileObj[k].size;
              let nameFl = fileObj[k].name;
              let extnReceived = nameFl.split(".").pop();
              if (allowedFileExt.indexOf(extnReceived) == -1) {
                totalInvalidExtAr.push(extnReceived);
              }
            });

            ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
            if (totCurSize + totPrevSize > totAllowedMaxFileUploadSize) {
              //error
              respdataFlag = false;
              stepOneStFileUplErMsg =
                "You have crossed total maximum upload file size.";
            }

            /*
                      if(totalInvalidExtAr.length>0){
                          //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                          //error
                          respdataFlag = false;
                      }
                      */
          } else {
            //error
            respdataFlag = false;
            stepOneStFileUplErMsg =
              "You cannot upload more than " +
              maxFileUploadAllowed +
              " files in total (It includes previously uploaded files if any).";
          }
        }
      }
    } else {
      respdataFlag = false;
      stepOneStFileUplErMsg = "Something went wrong. Please try again.";
    }

    this.isComponentLoaded &&
      this.setState({ fileUplErMsg: stepOneStFileUplErMsg });
    return respdataFlag;
  }

  async calUploadFileService(fileObj) {
    ////console.log.log("chk file fileObj=>>>",fileObj);
    let lserv = new MonitorFreeFlowFileUploadService();
    this.isComponentLoaded && this.setState({ uploadingFlag: true });

    let paramObjData = {
      data: fileObj,
      taskId: this.state.taskId,
    };

    lserv
      .uploadFiletoServer(paramObjData)
      .then((data) => {
        //this.setStepOneStDocuments();
        //console.log.log("success resp=>",data);
        let entityObj = data.data.entity;

        let otherParam = { action: "push" };
        this.setStatesForChat("uplDocuments", entityObj, otherParam);
        setTimeout(() => {
          this.fileUploadModalOpen({ postReplyFrom: this.state.postReplyFrom });
        }, 1550);
      })
      .catch((e) => {
        //console.log.log("server error=>",e);
      })
      .finally(() => {
        this.fileUpl.current.value = "";
        this.isComponentLoaded && this.setState({ uploadingFlag: false });
      });
  }

  setStatesForChat = (stateKeyName, stateKeyValue, otherParam) => {
    switch (stateKeyName) {
      case "uplDocuments":
        if (otherParam.action == "push") {
          let prevDoc = [...this.state.taskFiles];
          let docs = stateKeyValue;
          Object.keys(docs).map(function (key, index) {
            // convert server response of file size from mb to bytes
            let fileSize = docs[key].fileSize
              ? docs[key].fileSize * 1024 * 1024
              : 0;
            prevDoc.push({
              id: docs[key].id,
              name: docs[key].name,
              size: fileSize,
            });
          });
          this.isComponentLoaded &&
            this.setState({ taskFiles: prevDoc }, () => {
              this.getFileUploadModalContent({});
            });
        } else if (otherParam.action == "remove") {
          let valuesData = [...this.state.taskFiles];
          let indx = stateKeyValue;
          valuesData.splice(indx, 1);
          this.isComponentLoaded &&
            this.setState({ taskFiles: valuesData }, () => {
              this.getFileUploadModalContent({});
              if (valuesData.length == 0) {
                this.fileUploadModalClose();
              }
            });
        }
        break;
      case "chatMessage":
        this.isComponentLoaded && this.setState({ chatMessage: stateKeyValue });
        break;
    }
  };

  /*** File Upload Modal  starts  */
  /**
   * SplitScreenMonitorTaskChat
   * Function name : fileUploadModalOpen
   * Author        :
   * Created Date  : 8-5-2020
   * Purpose       : To open fileUpload modal
   * Params        :
   **/
  fileUploadModalOpen = (otherParam) => {
    this.isComponentLoaded &&
      this.setState({ showFileUploadModal: true }, () => {
        this.modalforfileupldRef.current.showModal();
        this.getFileUploadModalContent({});
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : fileUploadModalClose
   * Author        :
   * Created Date  : 8-5-2020
   * Purpose       : To close organisationDetailModal
   * Params        :
   **/
  fileUploadModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showFileUploadModal: false }, () => {
        this.modalforfileupldRef.current.hideModal();
      });
  };

  /**
   * SplitScreenMonitorTaskChat
   * Function name : getFileUploadModalContent
   * Author        :
   * Created Date  : 8-5-2020
   * Purpose       : Get modal view with data
   * Params        :
   **/
  getFileUploadModalContent(otherParam) {
    // otherParam can be sent as {} as current req. doesnt any data but if needed tricklily need to pass required data for that param from wherver it has been called
    let taskFiles = this.state.taskFiles;
    let taskFilesCnt = 0;

    if (taskFiles) {
      taskFilesCnt = taskFiles ? taskFiles.length : 0;
    }

    let viewData = (
      <div className="dash_modal">
        <>
          <div className="modal-header">
            <h4 className="modal-title modal_title_tool">
              Upload Documents
              {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
            </h4>
            <button
              type="button"
              onClick={() => {
                this.fileUploadModalClose();
              }}
              className="close modal_close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <CustomMessageBar />
          <div className="modal-body padding_top">
            <div className="fileUp_modalBox">
              <p>
                You are about the upload the following documents. The
                professional will be notified. Are you sure you want to
                continue?
              </p>
              <ul>
                {Object.keys(taskFiles).length > 0 &&
                  Object.keys(taskFiles).map((k, i) => (
                    <li key={i}>
                      <div className="popFile_name">
                        <div
                          className="popFile_nameText"
                          data-tooltip={`${taskFiles[k].name}`}
                        >
                          <span className="popFile_nameText_span">
                            {taskFiles[k].name}&nbsp;(
                            {i >= 0 && i < 9
                              ? ("0" + (i + 1)).toString()
                              : i + 1}
                            )&nbsp;
                          </span>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.removeDocOnClick(i);
                          }}
                          className="btn_appearance_none fileUp_delBtn"
                        >
                          <img
                            src={getBaseUrl() + "assets/images/delete.png"}
                            className="mCS_img_loaded"
                          />
                        </button>
                      </div>
                    </li>
                  ))}
                {/* <li>
                        <div className="popFile_name">
                          <div className="popFile_nameText" data-tooltip="Task documents (01)">
                            <span className="popFile_nameText_span">Task documents (01)</span>
                          </div>
                          <button className="btn_appearance_none fileUp_delBtn">
                            <img src="assets/images/delete.png" className="mCS_img_loaded" />
                          </button>
                        </div>
                      </li> */}
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button
              disabled={this.state.docModalIagreeButtonClickFlag}
              type="button"
              className="btn btn-primary modal_btn_save"
              onClick={(e) => {
                e.preventDefault();

                this.chkBeforeSend({ postReplyFrom: "DOCUMENTMODAL" });
              }}
              data-dismiss="modal"
            >
              <span>I Agree</span>
            </button>
          </div>
        </>
      </div>
    );

    this.modalforfileupldRef.current.updateStateContent(viewData);
    this.modalforfileupldRef.current.showContent();
    return viewData;
  }

  /*** File Upload Modal ends  */

  /**
   * SplitScreenMonitorTaskChat
   * Function name : render
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        <NotiEmitTrack
          callbackofbellnotimsgclick={this.trackMonitorChatListOnRealTimeNoti}
        />
        {this.loadTheContent()}

        {/* File Upload Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforfileupldRef}
          backdrop={false}
          backdropClassName="custom_modal_class "
          animation={false}
          show={this.state.showFileUploadModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered  freeflowfileupldpopupcls"
          loaderbodytitle="Organisation"
        />
        {/* File Upload Modal end */}

        {/* Newest1 - Start */}

        {/* Video Meet Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforvidmeetRef}
          backdrop={false}
          backdropClassName="custom_modal_class "
          animation={false}
          show={this.state.showVidMeetModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered videoMeet_modalBG"
          loaderbodytitle="Organisation"
        />
        {/* Video Meet Modal end */}

        {/* Video Accept Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforvidacceptRef}
          backdrop={false}
          backdropClassName="custom_modal_class "
          animation={false}
          show={this.state.showVidAcceptModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered videoAccept_modalBG"
          loaderbodytitle="Organisation"
        />
        {/* Video Accept Modal end */}

        {/* Date Pick Modal start */}
        <CustReactBootstrapModal
          ref={this.modalfordatepickRef}
          backdrop={false}
          backdropClassName="custom_modal_class "
          animation={false}
          show={this.state.showDatePickModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered datePick_modalBG datePick_modalBG_videoChat"
          loaderbodytitle="Organisation"
        />
        {/* Date Pick Modal end */}

        {/* Newest1 - End */}
      </React.Fragment>
    );
  }
}
export default SplitScreenMonitorTaskChat;
