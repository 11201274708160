/**
 * RequestDemoCommon
 * Page/Class name   : RequestDemoCommon
 * Author            :
 * Created Date      : 29-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import RequestDemoService from '../../service/RequestDemoService';


// for context
import CommonContext from '../../context/CommonContext';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;

const stateVarDefData  = {
    "showCustomModal": false,  
    "sendButtonFlag":false,
    "showCustomConfModal": false,
  
    //error messages
    "feedbackErMsg":"",

    
};

class RequestDemoCommon extends Component {
    /**
     * RequestDemoCommon
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = stateVarDefData;

        this.modalcustomRef = React.createRef();
        this.modalcustomConfRef = React.createRef();
        
    }

    /**
     * RequestDemoCommon
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * RequestDemoCommon
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    handleTagChange = (tags) =>{
        //console.log(" tags==>",tags); // tags contains array of data 
        this.isComponentLoaded && this.setState({tags},()=>{
  
           let paramObj = {};
            // let paramObj = {
            // "lawyerUserIdForConflChk":this.state.lawyerUserIdForConflChk,
            // "lawyerNameForConflChk": this.state.lawyerNameForConflChk,
            // "taskId":this.state.taskId,
            // "lawyerConflictCheckStatus":this.state.lawyerConflictCheckStatus
            // };
            this.getTaskInvoiceShareModalContent(paramObj);
         
  
          
        });
    }

    setFeedbackType = (paramObj) => {
        this.isComponentLoaded && this.setState({"feedbackType":paramObj.feedbackType});
    }

    handleChange = (e) => {
        this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value},()=>{
            this.getCustomModalContent({});
        }); 
      }

     /**
         * RequestDemoCommon
         * Function name : custValidate
         * Author        :
         * Created Date  : 29-6-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

    let totErrCountTrack = [];
    let feedbackErMsg = "";  let stateErData = {
        "feedbackErMsg": feedbackErMsg
    }
     
    this.isComponentLoaded && this.setState(stateErData);
  
    if(totErrCountTrack.length>0){ 
            return false;
      }
      return true;
    }

    callRequestDemoService = (paramObj) =>{

        let passParam ={
            "postData":{
                        "demoDateSelected":null,
                        "emailAddress":null,
                        "phoneNumber":null,
                        "countryCode":null
            }
         };
        

        let stcservice = new RequestDemoService();
        stcservice.sendDemoRequest({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    respData = data.data.entity; 
                    // open popup now
                    this.checkToOpenModal({});
  
                    // notify(respData,true,{
                    //     "successMsg":1,
                    //     "customWrapClass":"customerrmidlass",
                    //     "msgKeyData": "reqMsgNotiK1",
                    //     "showCloseButton": false, 
            
                    //     "dismiss": {
                    //     "duration": 1700,
                    //     }
            
                    //     });       
                    // setTimeout(()=>{
                    //           this.triggerCustomModalClose();   
                          
                    // },1900);
                    
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "msgKeyData": "msgNotiK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });       
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getCustomModalContent({});
                });            
                          
            },1500);
  
            
                       
              
          });
    }

    preCallOfRequestDemoService = (paramObj) =>{
        this.getCustomModalContent({});
       
        // make any validation
        let chk = this.custValidate();
        if(chk){
            this.triggerCustomConfModalClose();
            setTimeout(()=>{
                this.callRequestDemoService({});
            },1000);
            
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getCustomConfModalContent({});
            });
        }
        
        
    }

    checkToOpenModal = (otherParam) =>{

        this.isComponentLoaded && this.setState(stateVarDefData,()=>{
            this.triggerCustomModalOpen(otherParam);

        });

        


    }

    
    
    trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;
  
        let chkFileValidation =  this.validateFileSelected(fileObj);
  
        //this.calUploadFileService(fileObj);
        if(chkFileValidation){
            this.isComponentLoaded && this.setState({"uploadingFlag":true},()=>{
                this.getCustomModalContent({});
                setTimeout(()=>{
                    this.calUploadFileService(fileObj);
                },2000);
                
            });
            
            
        }else{
          this.fileUpl.current.value = "";
          this.getCustomModalContent({});
        }
    }
  
   
  


    /*** Request demo modal starts  */
    /**
     * RequestDemoCommon
     * Function name : triggerCustomModalOpen
     * Author        :
     * Created Date  : 29-6-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   triggerCustomModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showCustomModal:true},()=>{
      this.modalcustomRef.current.showModal();
      this.getCustomModalContent({});
    });    
      
  }

  /**
     * RequestDemoCommon
     * Function name : triggerCustomModalClose
     * Author        :
     * Created Date  : 29-6-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   triggerCustomModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showCustomModal:false},()=>{
      this.modalcustomRef.current.hideModal();
    });
    }

    /**
   * RequestDemoCommon
   * Function name : getCustomModalContent
   * Author        :
   * Created Date  : 29-6-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getCustomModalContent(otherParam) {  
        
      

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Request Demo
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar keydata={"reqMsgNotiK1"} />
            <div className="modal-body padding_top">
                    <div className="popUP_text_p">
                        <p>
                        We have received your request for a demo and will get back to you shortly. Please check your registered email for further details


                        </p>
                    </div>
            </div>
            
            <div className="modal-footer">
                <button onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }}  type="button"  className="btn btn-primary modal_btn_save"><span>Close</span></button>
                       
                {/* <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                   
                });
                

                } } ><span>Send</span></button> */}
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalcustomRef.current.updateStateContent(viewData);
        this.modalcustomRef.current.showContent();
        return viewData;
    }    

    /*** Request demo modal related ends  */

    /*** Request demo confirm modal starts  */

    /**
     * RequestDemoCommon
     * Function name : triggerCustomModalConfOpen
     * Author        :
     * Created Date  : 29-6-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   triggerCustomModalConfOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showCustomConfModal:true},()=>{
      this.modalcustomConfRef.current.showModal();
      this.getCustomConfModalContent({});
    });    
      
  }

  /**
     * RequestDemoCommon
     * Function name : triggerCustomConfModalClose
     * Author        :
     * Created Date  : 29-6-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   triggerCustomConfModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showCustomConfModal:false},()=>{
      this.modalcustomConfRef.current.hideModal();
    });
    }

    /**
   * RequestDemoCommon
   * Function name : getCustomConfModalContent
   * Author        :
   * Created Date  : 29-6-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getCustomConfModalContent(otherParam) {  
        
      

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Request Demo
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomConfModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar keydata={"reqMsgNotiK1"} />
            <div className="modal-body padding_top">
                    <div className="popUP_text_p">
                        <p>
                        Are you sure you want to send the request?


                        </p>
                    </div>
            </div>
            
            <div className="modal-footer">
                
                       
                <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                this.preCallOfRequestDemoService({});

                } } ><span>Confirm</span></button> 
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalcustomConfRef.current.updateStateContent(viewData);
        this.modalcustomConfRef.current.showContent();
        return viewData;
    }    

    /*** Request demo confirm modal ends  */
   

    /**
     * RequestDemoCommon
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <a href="" key={this.props.custkeyid?this.props.custkeyid:"reqdemolink001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        
                        this.triggerCustomModalConfOpen();

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      {this.props.textname?this.props.textname:"Feedback"}
                </a>
               

                {/* Requet demo modal start */}
                <CustReactBootstrapModal ref={this.modalcustomRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showCustomModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* Requet demo modal end */}

                {/* Requet demo modal start */}
                <CustReactBootstrapModal ref={this.modalcustomConfRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showCustomConfModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* Requet demo modal end */}



               


             </>
            
        );
    }

  }
  export default RequestDemoCommon;