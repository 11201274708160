import React, { Component } from 'react'
// for context
import CommonContext from '../../context/CommonContext';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
// import { Scrollbars } from 'react-custom-scrollbars';
import  CustDate  from '../../../common_utilities/CustDate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TaskDetailsService from '../../service/TaskDetailsService';

import UpdateBudgetAndDuedateService from '../../service/UpdateBudgetAndDuedateService';
import UpdateService from '../../service/UpdateService';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';


// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from './NotiEmitTrack';

// AcceptTaskOfferButton
import AcceptTaskOfferButton from "./AcceptTaskOfferButton";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getBaseUrl = commonJsObj.getBaseUrl;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getFileNameOnly = commonJsObj.getFileNameOnly;
const getStringInitials = commonJsObj.getStringInitials;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;
const emailIdPresenceCheck = commonJsObj.emailIdPresenceCheck;
const phonePresenceCheck = commonJsObj.phonePresenceCheck;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;
const getUserName = commonJsObj.getUserName;
const getCurrentUserId = commonJsObj.getCurrentUserId;

const stateStaticData = {
    "taskId": "",
    "taskName": "",
    "description": "",
    "practiceAreas": [],
    "locations": [],
    "taskCategory": null,
    "categoryType": null,
    "budgetCurrency": "",
    "budget": "",
    "assignedTo": "",
    "notificationIdAr":[],
    "owner":"",
    "organisationDetails":null,
    "dueDateMillis": "",
    "taskFiles": [],
    "subTasks":[],
    "isUnApprovedEditPresent":false,
    "agreedBudget":'',
    "agreedBudgetCurrency":'',

    "budgetNegotiationStatus":"",
    "budgetNegotiateButtonClick":false,
    "newBudgetNegotiateAmount":'',
    "budgetErrMsg":'',

    "dueDateNegotiationStatus":"",
    "dueDateNegotiateButtonClick":false,
    "newDueDate":'',
    "offsetHour": null,
    "offsetMinute":null,
    "dueDateErrMsg":'',
    "showSendReponse":0,

    "editedDescription": "",
    "editedBudgetCurrency": "",
    "editedBudget": "",
    "editedDueDateMillis": "",

    "budgetUpdationDescription":"",
    "dueDateUpdationDescription":"",
    "budgetDueDateCommonMsg":"",
    "budgetDueDateCommonMsgErr":"",

    //

    "taskDescription":"",
    "ccOrganisationName": "",
    "lpUserName":"",
    "taskType":"",
    "taskOfferedAt":"",
    "taskOfferedAtMillis":null,
    "ccUserId":"",
    "lpUserId":"",
    "professionalAgreedDetailsForTask":null,
    "isTaskOfferReceived":false

  };
//   const ExampleCustomInput = ({ value, onClick }) => (
//     <input
//     type="text"
//     className="form__field customdatefld"
//     placeholder="Task Due Date (Optional)"
//     defaultValue={value}
//     onClick={onClick}
//     />
//   );
export class SplitScreenPartTaskDetails extends Component {
    isComponentLoaded =  false;
    static contextType = CommonContext;
    constructor(props) {
        super(props);
        this.children = props.children;
        this.state = stateStaticData;

        this.taskdetailcontentHead = React.createRef();
        this.accptbtntskdetlref = React.createRef();

    }
    componentDidMount() {
        this.isComponentLoaded = true;

        

        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

            if(sharedData.actionType=="SPLITSCREEN" ){
              let respData = sharedData.other.taskResp;
  
              this.setStateData(respData,"componentDidMount");

            }
        }
        //console.log(sharedData,"sharedData")
        //console.log(this.state,"state 83");
        //********* for context ends *****
  
    }

    async getTaskDetailData(param){

        let respData = null;
        let lservdrf = new TaskDetailsService();
        await lservdrf.getTaskDetail(param).then(data =>{

          respData  = data.data.entity;
  
  
  
        }).catch((err)=>{

          let errStatusCodeAr = [400,500]; // allowed error status code
          if(err.response && err.response.data){
              let erRespData = err.response.data;
              if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                  let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                  //console.log(" errMsg on task fetch info==>",errMsg);
                  // close split screen
                  //this.closeSplit();

          }
          }
  

        }).finally(()=>{
  
        });

        return respData;
    }

    taskOfferPopupButtonCallback = (data) =>{

        setTimeout(()=>{
            window.scrollTo(0,  this.taskdetailcontentHead.current.offsetTop);
          },2500);
    
          
          setTimeout(()=>{
            this.callAfterEngagementPopopClose(data);
          },4000);
          
       
    }

    callAfterEngagementPopopClose = (data) =>{
        let activity =  data.activity!==undefined?data.activity:"";
        if(activity=="SINGLE_TASK_OFFER_ACCEPTED_SUCCESS"){
              // show green success message 
              notify("Offer accepted successfully!!",true,{
                "successMsg":1,
                "customWrapClass":"customerrmidlass alert_section_top_zero",
                "msgKeyData": "taskdetNotiK1",
                "showCloseButton": false, 
    
                "dismiss": {
                "duration": 4700,
                }
    
                }); 

                // since task has been accepted so make the tab assigned active
                // commonnotitrack("SINGLE_TASK_OFFER_ACCEPTED_SUCCESS",{
                //     "notiType": "SINGLE_TASK_OFFER_ACCEPTED_SUCCESS",
                //     "triggerCallback":false,
                //     "trigerredFrom":"SINGLE_TASK_DETAILS",
                    
                //    });


              this.reloadTaskDetails({});
        }
        else if(activity=="SINGLE_TASK_OFFER_DECLINE_SUCCESS"){
                // show green success message 
              notify("Offer declined successfully!!",true,{
                "successMsg":1,
                "customWrapClass":"customerrmidlass alert_section_top_zero",
                "msgKeyData": "taskdetNotiK1",
                "showCloseButton": false, 
    
                "dismiss": {
                "duration": 1700,
                }
    
                }); 

                // since task has been accepted so make the tab assigned active
                // commonnotitrack("SINGLE_TASK_OFFER_DECLINE_SUCCESS",{
                //     "notiType": "SINGLE_TASK_OFFER_DECLINE_SUCCESS",
                //     "triggerCallback":false,
                //     "trigerredFrom":"SINGLE_TASK_DETAILS",
                    
                //    });



              this.reloadTaskDetails({});
        }

    }

    taskDetailScreeenRealTimeNotiTrack = (paramObj)=>{    
      
        
      let trigerredFrom = paramObj.trigerredFrom!==undefined?paramObj.trigerredFrom:"";
      if(trigerredFrom=="REAL_TIME_NOTI_TRACK"){
  
          let notiType =   (paramObj.notiType!==undefined && paramObj.notiType) ? paramObj.notiType:"";
          let taskId =  (paramObj.taskId!==undefined && paramObj.taskId) ? paramObj.taskId:0;
          let notificationId =  (paramObj.notificationId!==undefined && paramObj.notificationId) ? paramObj.notificationId:0

          let notificationIdAr = [...this.state.notificationIdAr];

          let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
            if(chkNotiIdPres==-1){
                notificationIdAr.push(notificationIdAr);
                this.isComponentLoaded && this.setState({"notificationIdAr":notificationIdAr},()=>{
                    if(notiType=="TASK_OFFERED"){
  
                        if((this.state.taskId==taskId)){
                          this.reloadTaskDetails({});
                        }
                        
                      }
                });

            }
         
            
          
            
  
        }
        
       
  
    }


    checkPriorForceClickOnAcceptTaskOffer = () =>{
      
            if(this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE" && (this.state.isTaskOfferReceived) && (this.state.taskType=="OFFER_RECEIVED")){
                this.accptbtntskdetlref && this.accptbtntskdetlref.current!==null &&  this.accptbtntskdetlref.current.forcefullyTriggerMainButtonClick();
            }                
       
    }


    reloadTaskDetails = async () =>{

         // context share
         let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

         let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

        if(setShare && sharedData){
            let paramObj1 = {
                "data":{
                "taskId":this.state.taskId
                }
            };

            // update only taskResp in context
            let taskDetResp = await this.getTaskDetailData(paramObj1);
            sharedData.other.taskResp = taskDetResp;
            setShare(sharedData);

            this.setStateData(taskDetResp,"reloadTaskDetails");
        }
    }

    setStateData = (entityObj,calledFrom) =>{


        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context. share:null;   
        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;


        let paramData = stateStaticData;

        if(entityObj!==undefined && entityObj!==null){
            paramData.taskId = entityObj.id;
            paramData.taskName = entityObj.taskName;
            paramData.description = entityObj.description;
            paramData.practiceAreas = entityObj.practiceAreas;
            paramData.locations = entityObj.locations;
            paramData.taskCategory = entityObj.taskCategory;
            paramData.categoryType = entityObj.categoryType;
            paramData.budgetCurrency = entityObj.budgetCurrency;
            paramData.budget = entityObj.budget;
            paramData.assignedTo = entityObj.assignedTo;
            paramData.owner = entityObj.owner;
            paramData.organisationDetails = entityObj.organisationDetails;
            paramData.dueDateMillis = entityObj.dueDateMillis;
            paramData.taskFiles = entityObj.taskFiles;
            paramData.subTasks = entityObj.subTasks;
            paramData.isUnApprovedEditPresent = entityObj.isUnApprovedEditPresent;
            paramData.budgetNegotiationStatus = entityObj.budgetNegotiationStatus;
            paramData.dueDateNegotiationStatus = entityObj.dueDateNegotiationStatus;
            paramData.newDueDate = entityObj.dueDateMillis;
            paramData.agreedBudget = entityObj.agreedBudget;
            paramData.agreedBudgetCurrency = entityObj.agreedBudgetCurrency;
            //console.log(entityObj.budgetNegotiationStatus,entityObj.dueDateNegotiationStatus)
            if(entityObj.budgetNegotiationStatus=="NOT_INITIATED" && entityObj.dueDateNegotiationStatus=="NOT_INITIATED" && entityObj.assignedTo==null){
               // console.log("tt")
                paramData.showSendReponse=1;
            } else if(entityObj.budgetNegotiationStatus=="NOT_INITIATED" && entityObj.dueDateNegotiationStatus=="AGREE" && entityObj.dueDateMillis==null  && entityObj.assignedTo==null){
                paramData.showSendReponse=1;
            }else {
               // console.log("ff")
                paramData.showSendReponse=0;
            }
            paramData.editedDescription = entityObj.editedDescription!==undefined?entityObj.editedDescription:"";
            paramData.editedBudgetCurrency = entityObj.editedBudgetCurrency!==undefined?entityObj.editedBudgetCurrency:"";
            paramData.editedBudget = entityObj.editedBudget!==undefined?entityObj.editedBudget:"";
            paramData.editedDueDateMillis = entityObj.editedDueDateMillis!==undefined?entityObj.editedDueDateMillis:"";

            if(calledFrom=="componentDidMount"||calledFrom=="reloadTaskDetails"){
                let taskResp = entityObj;
                paramData.taskDescription = taskResp.description!==undefined?taskResp.description:"";
                paramData.ccOrganisationName = (taskResp.organisationDetails && taskResp.organisationDetails.organisationName!==undefined)?taskResp.organisationDetails.organisationName:"";
                paramData.taskType = (taskResp.taskType && taskResp.taskType!==undefined)?taskResp.taskType:"";
                paramData.taskOfferedAt = (taskResp.taskOfferedAt && taskResp.taskOfferedAt!==undefined)?taskResp.taskOfferedAt:"";
                paramData.taskOfferedAtMillis = (taskResp.taskOfferedAtMillis && taskResp.taskOfferedAtMillis!==undefined)?taskResp.taskOfferedAtMillis:null;
                paramData.professionalAgreedDetailsForTask = (taskResp.professionalAgreedDetailsForTask && taskResp.professionalAgreedDetailsForTask!==undefined)?taskResp.professionalAgreedDetailsForTask:null;
                paramData.isTaskOfferReceived = (taskResp.isTaskOfferReceived && taskResp.isTaskOfferReceived!==undefined)?taskResp.isTaskOfferReceived:false;
                paramData.ccUserId = (taskResp.isTaskOfferReceived && taskResp.owner!==undefined && taskResp.owner!==null  && taskResp.owner.userId!==undefined )?taskResp.owner.userId:0;
                paramData.lpUserId = getCurrentUserId();
            }

           

          //  console.log(" HELLO paramData=>>>",paramData, " entityObj=>>>",entityObj);
          this.isComponentLoaded && this.setState(paramData,()=>{
              //console.log(this.state,"state");
              //
             
              if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){ 
                  
                let bellActionToDo = sharedData.other && sharedData.other.actionToDo?sharedData.other.actionToDo:null;
                if( (bellActionToDo!==null) && (bellActionToDo.trigerredFrom!==undefined && bellActionToDo.action=="OPEN_ACCEPT_TASK_OFFER_POPUP") && (bellActionToDo.trigerredFrom!==undefined && bellActionToDo.trigerredFrom=="BELL_NOTIFICATION_LIST") &&  (bellActionToDo.taskId!==undefined && bellActionToDo.taskId==this.state.taskId) ){
                    
                    this.checkPriorForceClickOnAcceptTaskOffer();
                    setTimeout(()=>{
                            // Now unset key invoiceGeneratedPreviewGenState from context
                            if(setShare && sharedData){
        
                                let sharedDataNewObj = {...sharedData}; 
                                let otherDataNewObj = sharedDataNewObj.other?{...sharedDataNewObj.other}:{};
                                if(otherDataNewObj && otherDataNewObj.bellActionToDo){
                                        delete(otherDataNewObj.bellActionToDo);
                                }                                
                              
                                sharedDataNewObj["other"]   = otherDataNewObj;                   
                                setShare(sharedDataNewObj); 

                        }
                    },1700);

                }

              }
                   

          });
                
        }else{
            this.closeSplit({"trigerredFrom":"SINGLE_TASK_DETAILS"});
        }
  
    }

    closeSplit = (objParam)=>{
            //********* for context starts *****
            // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
            // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
            //     if(sharedData.actionType=="SPLITSCREEN" ){
            //         let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
            //         if(setShare){
            //             let paramsCntxt = {
            //             "taskId":"",
            //             "actionType":"",
            //             "leftTabToActive":"",
            //             "purpose":"",
            //             "other":{}
            //             }
            //             setShare(paramsCntxt);
            //         }
            //     }
            // }
            //********* for context ends *****
            

            commonnotitrack("CLOSE_SPLITSCREEN",{
                "notiType": "CLOSE_SPLITSCREEN",
                "triggerCallback":false,
                "trigerredFrom":"SINGLE_TASK_DETAILS",
                
               });
    
    }

    customDate = (defaultDate)=>{
       // console.log(this.state)
        return (
          <DatePicker
            selected={ defaultDate}
            onChange={date => {this.setCalDate(date)}}
            calendarClassName="customtaskaddclassstep1calcls"
            //customInput={<ExampleCustomInput />}
            className="form__field customdatefld"
            placeholder="Task Due Date (Optional)"
            minDate={new Date()}
            dateFormat={getDateFormatForCal({"countryCode":"IN"})}
          />
        );
    }
    setCalDate=(dt)=>{
        dt= dt!=null ? dt:new Date();
        let dtTs = getTimestampInMillisecondsFromDateObj(dt);
        let dttOfset = getOffsetOfLocalDateTime(dt);
        //console.log(dtTs)
        let otherParam1 = {};
        this.setStatesForSTEdit("dueDate",dtTs,otherParam1);
        let otherParam2 = {};
        this.setStatesForSTEdit("timeOffset",dttOfset,otherParam2);
    }

    setStatesForSTEdit = (stateKeyName,stateKeyValue, otherParam) =>{

        switch(stateKeyName){
            case "dueDate":
                let dateData = stateKeyValue;
                this.setState({"newDueDate":dateData});
                break;
            case "timeOffset":
                let offsetDataObj = stateKeyValue;
                this.setState({"offsetHour": offsetDataObj.hr,
                "offsetMinute": offsetDataObj.min});
                break;
        }
    }

   

    sendResponse = () =>{
        // let rexp = /(^[0-9]+.?[0-9]{0,2}$)/g;
        // console.log(rexp.test(this.state.newBudgetNegotiateAmount))

        //console.log(this.state);
        let paramObj=null;
        if(this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE"){
            //console.log(1)
                paramObj={
                    "taskId":this.state.taskId,
                    "budget":this.state.budget,
                    "budgetCurrency":this.state.budgetCurrency,
                    //"dueDate":this.state.dueDateMillis?this.state.dueDateMillis:getTimestampInMillisecondsFromDateObj(new Date()),
                    "dueDate":this.state.dueDateMillis?this.state.dueDateMillis:null,
                    "budgetChatStatus":"AGREE",
                    "dueDateChatStatus":"AGREE"
                }
        } else if (this.state.budgetNegotiationStatus=="NEGOTIATE" && this.state.dueDateNegotiationStatus=="AGREE"){
            //console.log(2)
            let priceRegex =/(^[0-9]+.?[0-9]{0,2}$)/g;
            let regexBlankSpace = /^ *$/;
            if(this.state.newBudgetNegotiateAmount.length==0 && (regexBlankSpace.test(this.state.newBudgetNegotiateAmount)==true)){
                    this.setState({budgetErrMsg:"Budget is required."})
            }else if (priceRegex.test(this.state.newBudgetNegotiateAmount)==false){
                    this.setState({budgetErrMsg:"Only price values are accepted."})
            } else {
               // console.log(2)
                paramObj={
                    "taskId":this.state.taskId,
                    "budget":this.state.newBudgetNegotiateAmount,
                    "budgetCurrency":this.state.budgetCurrency,
                    //"dueDate":this.state.dueDateMillis? this.state.dueDateMillis : getTimestampInMillisecondsFromDateObj(new Date()),
                    "dueDate":this.state.dueDateMillis? this.state.dueDateMillis : null,
                    "budgetChatStatus":"NEGOTIATE",
                    "dueDateChatStatus":"AGREE"
                }
            }
        } else if(this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="NEGOTIATE") {
            let regexBlankSpace = /^ *$/;
            let priceRegex =/(^[0-9]+.?[0-9]{0,2}$)/g;
            if(this.state.newDueDate.length==0 && (regexBlankSpace.test(this.state.newDueDate)==true)){
                this.setState({dueDateErrMsg:"Due date is required."})
            } else {
                //console.log(3)
                paramObj={
                    "taskId":this.state.taskId,
                    "budget":this.state.budget,
                    "budgetCurrency":this.state.budgetCurrency,
                    //"dueDate":this.state.newDueDate? this.state.newDueDate :getTimestampInMillisecondsFromDateObj(new Date()),
                    "dueDate":this.state.newDueDate? this.state.newDueDate :null,
                    "budgetChatStatus":"AGREE",
                    "dueDateChatStatus":"NEGOTIATE"
                }
            }
        } else if(this.state.budgetNegotiationStatus=="NEGOTIATE" && this.state.dueDateNegotiationStatus=="NEGOTIATE") {
            //console.log(4)
            let regexBlankSpace = /^ *$/;
            let priceRegex =/(^[0-9]+.?[0-9]{0,2}$)/g;
            if(this.state.newBudgetNegotiateAmount.length==0 && (regexBlankSpace.test(this.state.newBudgetNegotiateAmount)==true)){
                this.setState({budgetErrMsg:"Budget is required."})
            } else if (priceRegex.test(this.state.newBudgetNegotiateAmount)==false){
                    this.setState({budgetErrMsg:"Only price values are accepted."})
            } else if(this.state.newDueDate.length==0 && (regexBlankSpace.test(this.state.newDueDate)==true)){
                this.setState({dueDateErrMsg:"Due date is required."})
            }            
            // else if(emailIdPresenceCheck(this.state.budgetDueDateCommonMsg)){
            //     let budgetDueDateCommonMsgErr = "Message cannot contain emailid.";
            //     this.setState({"budgetDueDateCommonMsgErr":budgetDueDateCommonMsgErr});               

            // }
            // else if(phonePresenceCheck(this.state.budgetDueDateCommonMsg)){
            //     let budgetDueDateCommonMsgErr = "Message cannot contain phone number.";
            //     this.setState({"budgetDueDateCommonMsgErr":budgetDueDateCommonMsgErr});               

            // }
            else if(this.state.budgetDueDateCommonMsg && this.state.budgetDueDateCommonMsg.length>120){
                let budgetDueDateCommonMsgErr = "Message cannot be more than 120 characters.";
                this.setState({"budgetDueDateCommonMsgErr":budgetDueDateCommonMsgErr});               

            }
            else {
                this.setState({"budgetDueDateCommonMsgErr":""});
                paramObj={
                    "taskId":this.state.taskId,
                    "budget":this.state.newBudgetNegotiateAmount,
                    "budgetCurrency":this.state.budgetCurrency,
                    //"dueDate":this.state.newDueDate? this.state.newDueDate:getTimestampInMillisecondsFromDateObj(new Date()),
                    "dueDate":this.state.newDueDate? this.state.newDueDate:null,
                    "budgetChatStatus":"NEGOTIATE",
                    "dueDateChatStatus":"NEGOTIATE",
                    "budgetUpdationDescription":this.state.budgetDueDateCommonMsg,
                    "dueDateUpdationDescription":this.state.budgetDueDateCommonMsg,
                }
            }
        }
        console.log(" paramObj=>>>",paramObj);
        if(paramObj!==null){
            let service = new UpdateBudgetAndDuedateService();
            service.updateTask(paramObj).then(data =>{
                if(data.status==200){

                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
                    let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
                    if(setShare && sharedData){
                        sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
                        sharedData.purpose = "VIEW_RESPONSE_LIST";
                        setShare(sharedData);
                    }
                }
            }).catch((err)=>{
                    let errStatusCodeAr = [400,500]; // allowed error status code
                    if(err.response && err.response.data){
                        let erRespData = err.response.data;
                        if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                            let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                            //console.log.log(" errMsg on task creation==>",errMsg);

                            let notiMsg = errMsg;
                            notify(notiMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "showCloseButton": false,
                            "msgKeyData": "taskdetNotiK1",

                            "dismiss": {
                            "duration": 2500,
                            }

                            });

                    }
                    }
            }).finally(()=>{
                // this.isComponentLoaded &&  this.setState({"continueDisabled":false},()=>{
                //   this.getUpdateConfirmModalContent();
                // });
            });
        }


    }

    updateTask= ()=>{
        let paramObj={"taskId":this.state.taskId,};

        let service = new UpdateService();
        service.updateTask(paramObj).then(data =>{
            if(data.status==200){

                let respData  = data.data.entity;                
                let notiSuccMsg = "Congratulations. You have accepted the task edit by the task creator. You can see the edited details in the edited field.";

                        notify(notiSuccMsg,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,
                        "msgKeyData": "taskdetNotiK1",

                        "dismiss": {
                        "duration": 2500,
                        }

                        });  
                this.setStateData(respData,"updateTask");              
                   

                // let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
                // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
                // if(setShare && sharedData){
                //     sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
                //     sharedData.purpose = "VIEW_RESPONSE_LIST";
                //     setShare(sharedData);
                // }
            }
        }).catch((err)=>{
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log.log(" errMsg on task creation==>",errMsg);

                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,
                        "msgKeyData": "taskdetNotiK1",

                        "dismiss": {
                        "duration": 2500,
                        }

                        });

                }
                }
        }).finally(()=>{
            // this.isComponentLoaded &&  this.setState({"continueDisabled":false},()=>{
            //   this.getUpdateConfirmModalContent();
            // });
        });
    }

    trackChangeOfNewNegoMessageBox = (paramObj) =>{
        let keyName = paramObj.keyName?paramObj.keyName:"";
        let keyValue = paramObj.keyValue?paramObj.keyValue:"";

        if(keyName=="budgetDueDateCommonMsg"){
            this.isComponentLoaded && this.setState({"budgetDueDateCommonMsg":keyValue});

        }


    }

    loadTheContent = ()=>{
        let forSplitScrnCls2Inject = "";
        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

            if(sharedData.actionType=="SPLITSCREEN" ){
              forSplitScrnCls2Inject = "margin0"
            }
        }
        //********* for context ends ******//
        let practiceAreas = this.state.practiceAreas;
        let practiceAreasMoreTxt = ""; let practiceAreaName = "";

        if(practiceAreas && (practiceAreas.length ==1)){
            practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";


        }
        else if(practiceAreas && practiceAreas.length>1){
            let practiceAreasCnt = practiceAreas.length;
            practiceAreasCnt =  practiceAreasCnt-1;
            practiceAreasMoreTxt = "+"+practiceAreasCnt+" more";

            practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";
        }

        let locations = this.state.locations;
        let locationsMoreTxt = ""; let locationsName = "";

        if(locations && (locations.length ==1)){
            locationsName = locations[0]?locations[0].city:"";


        }
        else if(locations && locations.length>1){
            let locCnt = locations.length;
            locCnt =  locCnt-1;
            locationsMoreTxt = "+"+locCnt+" more";

            locationsName = locations[0]?locations[0].city:"";
        }

        // let owner = this.state.owner;
        // let ownerName = "None";
        // if(owner!==undefined && owner!==null){
        //     let fNm = owner.firstName!==undefined && owner.firstName!==null?owner.firstName:"";
        //     let lNm = owner.lastName!==undefined && owner.lastName!==null?owner.lastName:"";
        //     ownerName = fNm+" "+lNm;

        // }

        let owner = this.state.organisationDetails;
        //console.log(" HOLA =====>>>",owner)
        let ownerName = "None";
        if(owner!==undefined && owner!==null){    
            ownerName =  owner.organisationName!==undefined && owner.organisationName?owner.organisationName:"";

        }


        let budgetData = "";
        let budget = this.state.agreedBudget?this.state.agreedBudget: this.state.budget;
        let budgetCurrency = this.state.agreedBudgetCurrency?this.state.agreedBudgetCurrency:this.state.budgetCurrency;

        if(budget && budgetCurrency){
            budgetData = budgetCurrency+" "+budget;
        }



        let budgetEditFlag = false; // for both budget & currency
        let budgetOnlyEditFlag = false; // for  budget only
        let budgetCurrOnlyEditFlag = false; // for currency only
        let budgetDataEditted = "";
        let budgetDataOnlyEdit = "";
        let budgetCurrDataOnlyEdit = "";
        if(this.state.isUnApprovedEditPresent==true && (this.state.editedBudget||this.state.editedBudgetCurrency) ){
            budgetEditFlag = true;

        if( this.state.editedBudget && this.state.editedBudgetCurrency){
            budgetDataEditted = this.state.editedBudgetCurrency+" "+this.state.editedBudget;

            budgetOnlyEditFlag = true;
            budgetCurrOnlyEditFlag = true;

            budgetDataOnlyEdit = this.state.editedBudget;
            budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;


            }
            else if(this.state.editedBudget && !(this.state.editedBudgetCurrency)){
            budgetDataEditted = budgetCurrency+" "+this.state.editedBudget;

            budgetOnlyEditFlag = true;
            budgetCurrOnlyEditFlag = false;

            budgetDataOnlyEdit = this.state.editedBudget;
            budgetCurrDataOnlyEdit = "";
            }
            else if(!(this.state.editedBudget) && this.state.editedBudgetCurrency){
            budgetDataEditted = this.state.editedBudgetCurrency +" "+budget;

            budgetOnlyEditFlag = false;
            budgetCurrOnlyEditFlag = true;
            
            budgetDataOnlyEdit = "" ;
            budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;
            }

        }


        let dueDateData = this.state.dueDateMillis ?CustDate.custFormatDate((new Date(this.state.dueDateMillis)),3): "No Due Date ";

        let editedDueDateFlag = false;
        let editedDueDate = "None"; let editedDueDateMillis = null;
        //editedDueDateMillis
        if(this.state.isUnApprovedEditPresent==true && this.state.editedDueDateMillis ){
         editedDueDateFlag = true;
         editedDueDate = this.state.editedDueDateMillis ?CustDate.custFormatDate((new Date(this.state.editedDueDateMillis)),3): "No Due Date";
         editedDueDateMillis = this.state.editedDueDateMillis ?this.state.editedDueDateMillis:null;

        }


        let taskFiles = this.state.taskFiles;
        let taskFilesCnt = 0;

        if(taskFiles){
            taskFilesCnt = taskFiles?taskFiles.length:0;
        }
        const defaultDateData = this.state.newDueDate;//new Date();
        //console.log(defaultDateData,"defaultDate")
        const defaultDate = defaultDateData!==null?getDateObjFromMilliseconds(defaultDateData):new Date();
        //console.log(defaultDate,"defaultDate")
        console.log(this.state)
        return (
          <>
            {/* Dashboard Slide Split Right - screen start */}
            <div className={`splitscreen_area
                            ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                            }>
            <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
                <SplitScreenLeftMenu />
                <div className="slideScreen_rightbg flexbox-parent">

                    {/* right slide section top start */}
                    <div ref={this.taskdetailcontentHead} className="SlideS_topbg flexbox-item">
                                <div className="slide_top_titlebg">
                                    <h4>Task Details</h4>
                                    <button className="btn_appearance_none slideTop_cross_btn cross" onClick={(evn)=>{
                                        evn.preventDefault();
                                        this.closeSplit({"trigerredFrom":"SINGLE_TASK_DETAILS"});
                                    }}><img src={getBaseUrl()+"assets/images/red_cross.png"} alt=""/></button>
                                </div>
                    </div>
                    <CustomMessageBar keydata={"taskdetNotiK1"} />
                    {/* right slide section top end */}
                    {/* right slide section Middlie  start */}
                    <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_taskDetails">

                        <div className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_taskDetails">
                        {/* <Scrollbars
                              style={{ height: 700 }}
                              autoHeightMin={700}
                              renderView={props => <div {...props} className="view taskdetailnosbtskscrollcustomclass"/>}
                          > */}
                        <div className="s_midbox_section s_midbox_section_taskEdit_new">

                            {this.state.isUnApprovedEditPresent && <div className="top_msgBg">
                                <div className="allert_messg">
                                    Task edit awaiting confirmation from the Professional
                                </div>
                            </div>}

                            <div className="s_midbox">
                                {/***  slidebox start ***/}
                                <div className="scroll_maintask_bg">
                                    <div className="enter_task_box sub_task_addbg right_TaskBox_new">
                                        
                                        {/*** task title new design start **/}
                                        <div className="task_title_head text-right">
                                            <h5>Task Number - {this.state.taskId}</h5>
                                        </div>
                                        {/*** task title new design end **/}

                                        {/*** enter task box start **/}
                                        <div className="main_form_box main_form_box_lp">

                                            <div className="form_block form_block_half form_block_half_lp">
                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Task Name</b>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="input_datashow">
                                                            <p>{this.state.taskName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Created by</b>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="form__group field">
                                                            <div className="input_datashow">
                                                            {/* <p className={`${ownerName=="None"?"":"assTo_green"}`}>{ownerName}</p> */}
                                                            {/* <p>{ownerName}</p> */}
                                                                <div className="onClick_userDetails onClick_userName">
                                                                    <div className="ass_user_name">
                                                                        <button className="btn_appearance_none assignUser_nameBtn" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                        {ownerName}
                                                                        </button>
                                                                        <div className="dropdown-menu user_popDetails_block" aria-labelledby="dropdownMenuButton" x-placement="top-start" style={{"position": "absolute", "willChange": "transform", "top": "0px", "left": "0px", "transform": "translate3d(0px, -143px, 0px)"}}>
                                                                            <div className="user_popBox">
                                                                                <div className="u_popBox_sec user_popBox_top">
                                                                                    <div className="user_popBox_topLeft">
                                                                                        <h4>{ownerName}</h4>
                                                                                        <div className="userRating_block">
                                                                                            {/* <div className="ass_userRating">
                                                                                                <i className="fa fa-star" aria-hidden="true"></i>  {(this.state.organisationDetails && this.state.organisationDetails.rating)?this.state.organisationDetails.rating:0}
                                                                                            </div>
                                                                                            <div className="ass_userExp">
                                                                                                Experience - {(this.state.organisationDetails && this.state.organisationDetails.experience)?this.state.organisationDetails.experience:0} years
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="user_popBox_topRight">
                                                                                        {/* <!-- <img src="assets/images/pic.png" alt=""> --> */}
                                                                                        {/* <div className="user_name_text"><span>AM</span></div> */}
                                                                                        {(owner && owner.orgLogo!==null)?( <img src={owner.orgLogo} alt=""/>) : (<div className="user_name_text"><span>{getStringInitials(ownerName)}</span></div>)}
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="u_popBox_sec user_popBox_location">
                                                                                    <h5>Locations</h5>
                                                                                    <ul>
                                                                                        <li>Lorem ipsum dolor sit</li>
                                                                                        <li>Loremipsumdolorsit</li>
                                                                                        <li>Loremipsum dolorsit</li>
                                                                                        <li>Loremipsum dolorsit</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="u_popBox_sec user_popBox_pracArea">
                                                                                    <h5>Practice areas</h5>
                                                                                    <ul>
                                                                                        <li>Lorem ipsum dolor sit</li>
                                                                                        <li>Loremipsumdolorsit</li>
                                                                                        <li>Loremipsum dolorsit</li>
                                                                                        <li>Loremipsum dolorsit</li>
                                                                                    </ul>
                                                                                </div> */}
                                                                                <div className="u_popBox_sec user_popBox_about">
                                                                                    <h5>About</h5>
                                                                                    <p>{(this.state.organisationDetails && this.state.organisationDetails.aboutOrganisation)?this.state.organisationDetails.aboutOrganisation:""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="clearboth"></div>
                                            </div>

                                            <div className="form_block form_block_half form_block_half_lp">
                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Practice area</b>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="form__group field">
                                                            <div className="input_datashow">
                                                            { practiceAreaName && (
                                                                <span className="dataShow_green">
                                                                    {practiceAreaName}
                                                                </span>
                                                            )}
                                                             <span className="dataShow_more">{practiceAreasMoreTxt}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel50 panel_half paddingleft15 form__group_normar ">
                                                    <label htmlFor="name" className="form__label"><b>Location</b>
                                                        &nbsp;
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="input_datashow">
                                                        {locationsName && (
                                                        <span className="dataShow_green">
                                                        {locationsName}
                                                        </span>)}
                                                        <span className="dataShow_more">{locationsMoreTxt}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearboth"></div>
                                            </div>

                                            <div className="form_block form_block_half form_block_half_lp">
                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Category of task</b>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="input_datashow">
                                                            <p>{this.state.taskCategory && this.state.taskCategory.category?this.state.taskCategory.category: "" }</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Type of task</b>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="form__group field">
                                                            <div className="input_datashow">
                                                                <p>{this.state.categoryType && this.state.categoryType.categoryType?this.state.categoryType.categoryType: "" }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="clearboth"></div>
                                            </div>

                                            <div className="form_block form_block_half form_block_half_lp formBlock_fullLp_edit">
                                                <div className=" panel_half form__group_normar">
                                                    <label htmlFor="name" className="form__label"><b>Task description</b>
                                                        <div className="lable_popDetails_bg">
                                                            {this.state.isUnApprovedEditPresent &&  this.state.editedDescription &&<button className="btn_appearance_none lable_popBtn">Edited</button>}
                                                            <div className="lable_pop_details">
                                                                <div className="lable_pop_detailsTop">
                                                                    <h5>Previous</h5>
                                                                    <div className="clearboth"></div>
                                                                </div>
                                                                <p>{this.state.description}</p>
                                                                {/* <p className="dtp">
                                                                    <span>16:33pm</span> | <span>01 May 2020</span>
                                                                </p> */}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div className="form__group field">
                                                        <div className="form__group_desEdit">
                                                            <div className="input_datashow">
                                                            {(this.state.isUnApprovedEditPresent==true && this.state.editedDescription)?this.state.editedDescription:this.state.description}
                                                            </div>
                                                            {/* {this.state.isUnApprovedEditPresent && <div className="desEdit_btnSec">
                                                                <button className="btn_appearance_none green_btn" ><span>Accept</span></button>
                                                                <button className="btn_appearance_none sub_grd_btn"><span>Discuss</span></button>
                                                            </div>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearboth"></div>
                                            </div>

                                            {/*** Lp FromBlock half **/}
                                            <div className="form_block form_block_half form_block_half_lp formBlock_halfLp_edit">

                                                {/*** LP New Sec -- Budget Start **/}
                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                    <div className="form_groupLp_taskBox"> 

                                                        <label htmlFor="name" className="form__label"><b>Budget</b>
                                                            {budgetEditFlag   && <div className="lable_popDetails_bg">
                                                                <button className="btn_appearance_none lable_popBtn">Edited</button>
                                                                <div className="lable_pop_details">
                                                                    <div className="lable_pop_detailsTop">
                                                                        <h5>Previous</h5>
                                                                        <div className="clearboth"></div>
                                                                    </div>
                                                                    <p>{budgetData}</p>
                                                                    {/* <p className="dtp">
                                                                        <span>16:33pm</span> | <span>01 May 2020</span>
                                                                    </p> */}
                                                                </div>
                                                            </div>}
                                                            {this.state.budgetNegotiationStatus =="AGREE" && <div className="label_right_stat">Agreed</div>}
                                                            <div className="clearboth"></div>
                                                        </label>

                                                        <div className="form__group field form__group_datashow">
                                                            <div className="input_datashow">
                                                                <p>{budgetEditFlag?budgetDataEditted:budgetData } <span className="dataShhow_inr"></span></p>
                                                            </div>
                                                        </div>

                                                        {this.state.budgetNegotiateButtonClick && <div className="form__group field">
                                                            <div className="price_input_grup_bg">
                                                                <div className={`price_input_grup ${this.state.budgetErrMsg?"form_group_error":""}`}>

                                                                    {/* <img className="price_img_icon" src="assets/images/rupee-icon.png" alt=""/> */}
                                                                        {this.state.budgetCurrency=="INR" && (
                                                                        <img src="assets/images/rupee-icon.png" className="price_img_icon" />
                                                                        )}

                                                                        {this.state.budgetCurrency=="USD" && (
                                                                        <img src="assets/images/dollar-icon.png" className="price_img_icon" />
                                                                        )}

                                                                        {this.state.budgetCurrency=="EUR" && (
                                                                        <img src="assets/images/pound-icon.png"  className="price_img_icon" />
                                                                        )}
                                                                    <input type="text" className="form__field" placeholder="Enter amount"
                                                                    maxLength="10"
                                                                    value={this.state.newBudgetNegotiateAmount} onChange={(e)=>{

                                                                        let budgetInputData = e.target.value;
                                                                        if(budgetInputData){
                                                                          if((validatePositiveInputNumber({"data":e.target.value}))){              
                                                                            budgetInputData = e.target.value;
                                                                                       
                                                                          }
                                                                          else{
                                                                            budgetInputData = this.state.newBudgetNegotiateAmount; 
                                                                          }
                                                                        }  

                                                                        this.setState({newBudgetNegotiateAmount:budgetInputData})
                                                                    }}/>
                                                                </div>
                                                                {/* <span className="error_span">Please enter a minimum budget of INR
                                                                    40000 for this Task.</span> */}
                                                                    <span className="error_span">
                                                                        {this.state.budgetErrMsg}
                                                                    </span>
                                                            </div>
                                                        </div>}

                                                        <div className="form__group field">
                                                            <div className="budg_cancel_btnSec">
                                                                {this.state.budgetNegotiateButtonClick && <button className="btn_appearance_none btn modal_text_btn" onClick={(evnt)=>{
                                                                            evnt.preventDefault();
                                                                            this.setState({budgetNegotiateButtonClick:false,newBudgetNegotiateAmount:"",budgetNegotiationStatus:""})
                                                                        }}>Cancel</button>}
                                                            </div>
                                                        </div>

                                                        <div className="form__group field">
                                                            <div className="form__group field">
                                                                {this.state.showSendReponse==1 && <div className="agg_neg_btnSec">
                                                                        {this.state.budgetNegotiationStatus !="AGREE" && !this.state.budgetNegotiateButtonClick &&<button className="btn_appearance_none green_btn" onClick={(e)=>{
                                                                            e.preventDefault();
                                                                            this.setState({budgetNegotiationStatus:"AGREE"})
                                                                        }}><span>Agree</span></button>}

                                                                        { !this.state.budgetNegotiateButtonClick && <button className="btn_appearance_none sub_grd_btn" onClick={(evnt)=>{
                                                                            evnt.preventDefault();
                                                                            this.setState({budgetNegotiateButtonClick:true,budgetNegotiationStatus:"NEGOTIATE"},()=>{//console.log(this.state)
                                                                            })
                                                                        }}><span>Negotiate</span></button>}
                                                                </div>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/*** LP New Sec -- Budget End **/}

                                                {/*** LP New Sec -- Due Date Start **/}
                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                    <div className="form_groupLp_taskBox"> 

                                                        <label htmlFor="name" className="form__label"><b>Due date</b>
                                                            {this.state.isUnApprovedEditPresent && this.state.editedDueDateMillis && <div className="lable_popDetails_bg">
                                                                <button className="btn_appearance_none lable_popBtn">Edited</button>
                                                                <div className="lable_pop_details">
                                                                    <div className="lable_pop_detailsTop">
                                                                        <h5>Previous</h5>
                                                                        <div className="clearboth"></div>
                                                                    </div>
                                                                    <p>{dueDateData}</p>
                                                                    {/* <p className="dtp">
                                                                        <span>16:33pm</span> | <span>01 May 2020</span>
                                                                    </p> */}
                                                                </div>
                                                            </div>}
                                                            {this.state.dueDateNegotiationStatus == "AGREE" &&<div className="label_right_stat">Agreed</div>}
                                                            <div className="clearboth"></div>
                                                        </label>

                                                        <div className="form__group field form__group_datashow">
                                                            <div className="input_datashow">
                                                                {/* <p>{this.state.dueDateMillis ? CustDate.custFormatDate((new Date(this.state.dueDateMillis)),3) :"No Due Date"}</p> */}
                                                                <p>{editedDueDateFlag?editedDueDate:dueDateData}</p>
                                                            </div>
                                                        </div>

                                                       {this.state.dueDateNegotiateButtonClick && <div className={`form__group field ${this.state.dueDateErrMsg?"form_group_error":""}`}>
                                                            <div className="input-group date" id="sandbox-container">
                                                                {/* <input type="text" className="form__field customdatefld" placeholder="23 March 2020"/> */}
                                                                { this.customDate(defaultDate)}
                                                            </div>
                                                            <span className="error_span" > {this.state.dueDateErrMsg}</span>
                                                        </div>}

                                                        <div className="form__group field">
                                                            <div className="budg_cancel_btnSec">
                                                            {this.state.dueDateNegotiateButtonClick && <button className="btn_appearance_none btn modal_text_btn" onClick={(evnt)=>{
                                                                            evnt.preventDefault();
                                                                            this.setState({dueDateNegotiateButtonClick:false,dueDateNegotiationStatus:""})
                                                                        }}>Cancel</button>}
                                                            </div>
                                                        </div>

                                                        <div className="form__group field">
                                                            <div className="form__group field">
                                                                {this.state.showSendReponse ==1 && <div className="agg_neg_btnSec">
                                                                        { this.state.dueDateNegotiationStatus!="AGREE" && !this.state.dueDateNegotiateButtonClick &&<button className="btn_appearance_none green_btn" onClick={(e)=>{
                                                                            this.setState({dueDateNegotiationStatus:"AGREE"})
                                                                        }}><span>Agree</span></button>}
                                                                        { !this.state.dueDateNegotiateButtonClick && this.state.dueDateMillis && <button className="btn_appearance_none sub_grd_btn" onClick={(evnt)=>{
                                                                            evnt.preventDefault();
                                                                            this.setState({dueDateNegotiateButtonClick:true,dueDateNegotiationStatus:"NEGOTIATE"},()=>{//console.log(this.state)
                                                                            })
                                                                        }}><span>Negotiate</span></button>}
                                                                </div>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/*** LP New Sec -- Budget End**/}
                                                
                                                <div className="clearboth"></div>
                                            </div>
                                            {/*** Lp FromBlock half **/}

                                            {/* ====  renego new optional textarea field addition start ====  */}
                                           

                                            {(this.state.budgetNegotiateButtonClick || this.state.dueDateNegotiateButtonClick) && (
                                            <div className="form_block form_block_half form_block_half_lp formBlock_halfLp_edit">
                                                <div className={`lp_taskDetails_addiTextAreaField ${this.state.budgetDueDateCommonMsgErr?"form_group_error":""}`}>
                                                    <textarea name="budgetDueDateCommonMsg" placeholder="(Optional) Enter message upto 120 characters only, Sharing of phone no. or personal details will disqualify you for the platform" value={this.state.budgetDueDateCommonMsg} onChange={(ev)=>{ev.preventDefault();
                                                    this.trackChangeOfNewNegoMessageBox({"keyName":"budgetDueDateCommonMsg","keyValue":ev.target.value})
                                                    }} />
                                                    <span className="error_span">{this.state.budgetDueDateCommonMsgErr}</span>
                                                </div>
                                            </div>
                                            
                                            ) }
                                            
                                            {/* ====  renego new optional textarea field addition end ====  */}

                                            <div className="form_block form_block_half form_block_half_lp">
                                                <div className=" panel_half form__group_normar">
                                                    <div className="s_form_fileup_bg s_form_fileup_bg_lp">
                                                        <h5 className="sform_attachment_sec_title">Attached documents ({taskFilesCnt})</h5>

                                                        {this.state.taskFiles.length>0 && ( this.state.taskFiles.map((el, i) => <div className="sform_attachment_sec" key={i}>
                                                        <div className="s_attachment">{getFileNameOnly(el.name)}</div>
                                                        <div className="s_attachment_btnbg">
                                                            <div className="s_att_btn s_att_btn_view">
                                                                <a href ={el.downloadAddress+'&auth='+getTokenFromLocStorage()}  className="btn_appearance_none s_view_btn">View</a>
                                                            </div>
                                                        </div>
                                                        </div>))}
                                                        {/* <div className="s_attachment_btnbg">
                                                            <div className="s_att_btn s_att_btn_view">
                                                                <button
                                                                onClick={(evn)=>{evn.preventDefault();
                                                                let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                                                                let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

                                                                if(setShare && sharedData){
                                                                    sharedData.leftTabToActive = "DOCUMENTS";
                                                                    sharedData.purpose = "VIEW_DOC_LIST";
                                                                    setShare(sharedData);
                                                                }

                                                                }}
                                                                className="btn_appearance_none s_view_btn">
                                                                View
                                                                </button>
                                                            </div>
                                                        </div> */}



                                                    </div>
                                                </div>
                                                <div className="clearboth"></div>
                                            </div>

                                        </div>
                                        {/*** enter task box end **/}
                                        {/*** main from box button section start **/}
                                        {/*** main from box button section end **/}
                                        {/*** enter sub task box start **/}
                                        {/*** enter sub task box end **/}
                                    </div>
                                </div>
                                {/***  slidebox end **/}
                            </div>

                        </div>

                        {/* </Scrollbars> */}
                        </div>

                    </div>

                    {/* right slide section Middlie  end */}
                    {/* right slide section Footer  start */}
                    <div className="SlideS_bottombg flexbox-item ">
                        <div className="s_footer_btnbg">
                           {this.state.showSendReponse ==1 && ((this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE") ||
                           (this.state.budgetNegotiationStatus=="NEGOTIATE" && this.state.dueDateNegotiationStatus=="AGREE" && this.state.newBudgetNegotiateAmount!="") ||
                           (this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="NEGOTIATE" && this.state.newDueDate!="" && this.state.dueDateMillis!=this.state.newDueDate) ||
                           (this.state.budgetNegotiationStatus=="NEGOTIATE"&& this.state.dueDateNegotiationStatus=="NEGOTIATE" && this.state.newBudgetNegotiateAmount!="" && this.state.newDueDate!="" && this.state.dueDateMillis!=this.state.newDueDate)) && <button className="btn_appearance_none sub_grd_btn" onClick={(e)=>{
                                e.preventDefault();
                                this.sendResponse();
                            }}><span>Send Response</span></button>}
                            {this.state.isUnApprovedEditPresent && <button className="btn_appearance_none green_btn" onClick={(e)=>{e.preventDefault();
                            this.updateTask();}}><span>Accept</span></button>}

                                {(this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE" && (this.state.isTaskOfferReceived) && (this.state.taskType=="OFFER_RECEIVED") && (this.state.assignedTo===null)) && (
                            <AcceptTaskOfferButton ref={this.accptbtntskdetlref} custclassname="btn_appearance_none sub_grd_btn" controltype="BUTTON" 
                                controlnametext="View Task Offer"
                                callbackfunc={this.taskOfferPopupButtonCallback} datas={  {
                                  "taskOrSubTask":"STASK",
                                  "taskIdOrSubTaskId":this.state.taskId,
                                  "taskOrSubTaskName":this.state.taskName,
                                  "taskDesc":this.state.taskDescription,
                                  "lpUserId":this.state.lpUserId,
                                  "ccUserId":this.state.ccUserId,
                                  "lpUserName":getUserName(),
                                  "organisationName":this.state.ccOrganisationName,
                                  "agreedBudget":((this.state.professionalAgreedDetailsForTask!==undefined && this.state.professionalAgreedDetailsForTask!==null && this.state.professionalAgreedDetailsForTask.agreedBudget!==undefined && this.state.professionalAgreedDetailsForTask.agreedBudget!==null)?this.state.professionalAgreedDetailsForTask.agreedBudget:null),
                                  "agreedBudgetCurrency":((this.state.professionalAgreedDetailsForTask!==undefined  && this.state.professionalAgreedDetailsForTask!==null && this.state.professionalAgreedDetailsForTask.agreedBudgetCurrency!==undefined && this.state.professionalAgreedDetailsForTask.agreedBudgetCurrency!==null)?this.state.professionalAgreedDetailsForTask.agreedBudgetCurrency:null),
                                  "agreedDueDate":((this.state.professionalAgreedDetailsForTask!==undefined  && this.state.professionalAgreedDetailsForTask!==null && this.state.professionalAgreedDetailsForTask.agreedDueDate!==undefined && this.state.professionalAgreedDetailsForTask.agreedDueDate!==null)?this.state.professionalAgreedDetailsForTask.agreedDueDate:null),
                                  "taskOfferedAt": ((this.state.taskOfferedAt!==undefined && this.state.taskOfferedAt!==null)?this.state.taskOfferedAt:"")
          
                                
                                } } />

                                )}
                        </div>
                    </div>
                    {/* right slide section Footer  end */}

                </div>
            </div>
            
            </div>
            {/* Dashboard Slide Split Right - screen end*/}
          </>
        );
    }
    render() {
        return (
            <>
                <NotiEmitTrack key={"splitstd001"} callbackofbellnotimsgclick={this.taskDetailScreeenRealTimeNotiTrack}  />
                {this.loadTheContent()}
            </>
        )
    }
}

export default SplitScreenPartTaskDetails
