/**
 * InvoiceSharePopup
 * Page/Class name   : InvoiceSharePopup
 * Author            :
 * Created Date      : 23-12-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy, ReactDOM} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import InvoiceSharePopupService from '../../service/InvoiceSharePopupService';
//import SendInvoiceMailFileUploadService from '../../service/SendInvoiceMailFileUploadService';

//Tags
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

// for context
import CommonContext from '../../context/CommonContext';

import {commonnotitrack} from "./NotiEmitTrack";


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';


const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;
const validateEmail = commonJsObj.validateEmail;

const stateVarDefData = {

    "toEmailAddresses": [],
    "ccEmailAddresses": [],
    "bccEmailAddresses": [],
    "subject":"",
    "body":"",  
    "fileIds":[],
    // other 
    "sendButtonFlag":true,
    "showCustomModal": false,
    "uploadingFlag": false,
    "attachedFiles":[],
    "invoiceId":0,
    "attachFileCheckbox":false,
    

    //error messages
    "bodyErMsg":"",
    "subjectErMsg":"",
    "fileUplErMsg":"",
    "toEmailNotProvidedErMsg":""
    
};


class InvoiceSharePopup extends Component {
    /**
     * InvoiceSharePopup
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = stateVarDefData;  

        this.modalcustomRef = React.createRef();
        this.fileUpl = React.createRef();
        this.popupcustheaderdiv = React.createRef();
    }

    /**
     * InvoiceSharePopup
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;
        let invoiceId = this.props.otherdata!==undefined && this.props.otherdata && this.props.otherdata.invoiceId!==undefined?this.props.otherdata.invoiceId:0;
        this.isComponentLoaded && this.setState({"invoiceId":invoiceId});

        
        
    }

    /**
     * InvoiceSharePopup
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    static getDerivedStateFromProps(props, state) {

        if(props.otherdata.invoiceId!== state.invoiceId ){
            let otherdata = props.otherdata;
            return {
                "invoiceId":(otherdata.invoiceId!==undefined?otherdata.invoiceId:0),
                "attachedFiles":(otherdata.attachedFiles!==undefined?otherdata.attachedFiles:[]),
                "subject":(otherdata.subject!==undefined?otherdata.subject:""),
                "body":(otherdata.body!==undefined?otherdata.body:""),

                
            }

        }
    
        // Return null to indicate no change to state.
        return null;
      }

    handleToEmailChange = (tags) =>{
        //console.log(" tags==>",tags); // tags contains array of data 
        let chkNow  = this.checkWhetherEmailIsAreValid(tags);
        if(chkNow==false){
            tags = [...this.state.toEmailAddresses]; // set to previous states
        }

        let tagsData = tags;            
        let sendButtonFlag = (tagsData.length>0)?false:true;        

        this.isComponentLoaded && this.setState({"toEmailAddresses":tags,"sendButtonFlag":sendButtonFlag},()=>{
  
            this.getCustomModalContent({});
         
  
          
        });
    }
    handleCcEmailChange = (tags) =>{
        //console.log(" tags==>",tags); // tags contains array of data 
        let chkNow  = this.checkWhetherEmailIsAreValid(tags);
        if(chkNow==false){
            tags = [...this.state.ccEmailAddresses]; // set to previous states
        }

        let tagsData = tags;            
        //let sendButtonFlag = (tagsData.length>0)?false:true;        

        this.isComponentLoaded && this.setState(
            {
                "ccEmailAddresses":tags,
                // "sendButtonFlag":sendButtonFlag
            },()=>{
  
            this.getCustomModalContent({});
         
  
          
        });
    }

    checkWhetherEmailIsAreValid = (emailIdAr) => {
        let totErrTrack = [];
        if(emailIdAr.length>0){

            emailIdAr.map((eml,i)=>{
                let erMsg = "";
                if(!(eml)||(eml.length==0)){
                    erMsg = "Email-Id not provided.";
                    totErrTrack.push(erMsg);
    
                  }    
                  else if (validateEmail(eml)==false)
                  {
                    erMsg = "Invalid email-Id has been provided.";
                    totErrTrack.push(erMsg);
                    
                  }             
    
    
            });
            
        }

        if(totErrTrack.length>0){
            return false;
        }else{
            return true;
        }
    }

   

    handleChange = (e) => {
        let ctrlValue = e.target.value;
        if(e.target.name=="attachFileCheckbox"){
            ctrlValue = (this.state.attachFileCheckbox)?false:true;
            
        }
        this.isComponentLoaded &&  this.setState({[e.target.name]: ctrlValue},()=>{
            this.getCustomModalContent({});
        }); 
      }

     /**
         * InvoiceSharePopup
         * Function name : custValidate
         * Author        :
         * Created Date  : 23-12-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

    let totErrCountTrack = [];  

    let toEmailAddressesAr = this.state.toEmailAddresses;   
    let ccEmailAddresses = this.state.ccEmailAddresses;
    let improperToEmailErMsgAr = []; 
    let improperCcEmailErMsgAr = []; 

    let bodyErMsg = "";
    let subjectErMsg = "";  
    let toEmailNotProvidedErMsg = "";  
    let ccEmailNotProvidedErMsg = "";  
    

    if(!(this.state.body)||(this.state.body.length==0)|| (validateBlankSpace(this.state.body)==true)){
       // bodyErMsg = "Body is required.";
       // totErrCountTrack.push(bodyErMsg);
      }

      if(!(this.state.subject)||(this.state.subject.length==0)|| (validateBlankSpace(this.state.subject)==true)){
        subjectErMsg = "Subject is required.";        
        
        totErrCountTrack.push(subjectErMsg);
      } 

       

        if(toEmailAddressesAr.length>0){

                toEmailAddressesAr.map((eml,i)=>{
                    let erMsg = "";
                    if (validateEmail(eml)===false)
                    {
                        erMsg = "Invalid email-Id has been provided";
                        improperToEmailErMsgAr.push(erMsg);
                    }             


                });

                if(improperToEmailErMsgAr.length>0){
                    toEmailNotProvidedErMsg = improperToEmailErMsgAr[0];
                    totErrCountTrack.push(toEmailNotProvidedErMsg);
                }
        
        }else{
        
        toEmailNotProvidedErMsg = "Email-Id not provided.";
        totErrCountTrack.push(toEmailNotProvidedErMsg);
        }

        // if(ccEmailAddresses.length>0){

        //     ccEmailAddresses.map((eml,i)=>{
        //             let erMsg = "";
        //             if (validateEmail(eml)==false)
        //             {
        //                 erMsg = "Invalid email-Id has been provided.";
        //                 improperCcEmailErMsgAr.push(erMsg);
        //             }             


        //         });

        //         if(improperCcEmailErMsgAr.length>0){
        //             ccEmailNotProvidedErMsg = improperCcEmailErMsgAr[0];
        //             totErrCountTrack.push(ccEmailNotProvidedErMsg);
        //         }
        
        // }else{
        
        //     ccEmailNotProvidedErMsg = "Email-Id not provided.";
        //     totErrCountTrack.push(ccEmailNotProvidedErMsg);
        // }
    
    
    
  
      let stateErData = {
        "bodyErMsg": bodyErMsg,
        "subjectErMsg":subjectErMsg,
        "toEmailNotProvidedErMsg":toEmailNotProvidedErMsg,
        "ccEmailNotProvidedErMsg":ccEmailNotProvidedErMsg,
      }
      
     
      this.isComponentLoaded && this.setState(stateErData);
  
    if(totErrCountTrack.length>0){ 

            // notify(notiMsg,true,{
            // "successMsg":0,
            // "customWrapClass":"customerrmidlass",
            // "showCloseButton": false, 

            // "dismiss": {
            // "duration": 1700,
            // }

            // });        
            return false;
      }
      return true;
    }

    callSendMailService = (paramObj) =>{
       
        let attachedFiles = this.state.attachedFiles;
        let taskDocIdsAr = [];
        if((this.state.attachFileCheckbox) && attachedFiles.length>0){

            Object.keys(attachedFiles).map((e,i)=>{
                taskDocIdsAr.push(attachedFiles[e].id);
            });           

        }
        
        let passParam ={
            "postData":{
                "toEmailAddresses":this.state.toEmailAddresses,
                "ccEmailAddresses":this.state.ccEmailAddresses,
                "bccEmailAddresses":this.state.bccEmailAddresses,
                "subject":this.state.subject,
                "body":this.state.body,                
                "fileIds":taskDocIdsAr
            },
            "invoiceId": this.state.invoiceId
         };
        
         console.log(" passParam=>>",passParam);
        let stcservice = new InvoiceSharePopupService();
        stcservice.sendMail({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                         

                    respData = data.data.entity;                    
  
                    notify(respData,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "msgKeyData": "invsharepopupmailmsgNotiK1",
                        "showCloseButton": false, 
            
                        "dismiss": {
                        "duration": 1900,
                        }
            
                        }); 
                
                        setTimeout(()=>{
                              //this.props.callbackcustomfunc();
                              this.triggerCustomModalClose();   
                          
                        },3000);
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "msgKeyData": "invsharepopupmailmsgNotiK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });       
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getCustomModalContent({});
                });            
                          
            },1200);
  
              
            
                       
              
          });
    }

    preCallOfSendMailService = (paramObj) =>{
     
        // make any validation
        let chk = this.custValidate(paramObj);
        if(chk){
            this.getCustomModalContent({});
            setTimeout(()=>{
                this.callSendMailService({});
            },1900);
            
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getCustomModalContent({});
            });
        }
        
        
    }

    checkToOpenModal = (otherParam) =>{

        this.isComponentLoaded && this.setState(stateVarDefData,()=>{
            this.triggerCustomModalOpen(otherParam);

        });
    }

    removeDocOnClick(indx){
        let valuesData = [...this.state.attachedFiles]; 
                      
                  valuesData.splice(indx,1);        
                  this.isComponentLoaded &&  this.setState({"attachedFiles":valuesData},()=>{
                    this.getCustomModalContent({});
                  });
    }

    openFileUploadDialog = () =>{
        if(this.fileUpl && this.fileUpl.current){
            this.fileUpl.current.click();
        } 
        
      }
    
      trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;
  
        let chkFileValidation =  this.validateFileSelected(fileObj);
  
        //this.calUploadFileService(fileObj);
        if(chkFileValidation){
            this.isComponentLoaded && this.setState({"uploadingFlag":true},()=>{
                this.getCustomModalContent({});
                setTimeout(()=>{
                    this.calUploadFileService(fileObj);
                },2000);
                
            });
            
            
        }else{
            if(this.fileUpl && this.fileUpl.current){
                this.fileUpl.current.value = "";
                this.getCustomModalContent({});
            } 
          
          
        }
    }
  
    /*
    validateFileSelected(fileObj){
        const maxFileUploadAllowed = 100000000;
        const totAllowedMaxFileUploadSize = 20 * 1024 * 1024; // 20 MB
        const allowedFileExt = getCommonAllowedFileExt();
  
        const attachedFiles = this.state.attachedFiles;        
        const docsData = attachedFiles;
        let respdataFlag =  true;
        let fileUplErMsg = "";
        if(docsData!==undefined){
            if(docsData.length==maxFileUploadAllowed){
                //error
                respdataFlag = false;
                fileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
                
            }else{
                if(docsData.length < maxFileUploadAllowed){
                    let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                    if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                        // check file validations like size and extension
                        let totPrevSize  = 0; 
                        Object.keys(docsData).map((k,i)=>{
                            totPrevSize = totPrevSize + docsData[k].size;                 
                        });
                        ////console.log.log("Total attempted size=>",totSize);
  
                        let totCurSize  = 0; let totalInvalidExtAr = [];
                        Object.keys(fileObj).map((k,i)=>{
                            totCurSize = totCurSize + fileObj[k].size;
                            let nameFl = fileObj[k].name;
                            let extnReceived = nameFl.split('.').pop();
                            if(allowedFileExt.indexOf(extnReceived)==-1){
                                totalInvalidExtAr.push(extnReceived);
                            }  
                        });
  
                        ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                        if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                            //error
                            respdataFlag = false;
                            fileUplErMsg = "You have crossed total maximum upload file size.";
                        }
  
                        
                        if(totalInvalidExtAr.length>0){
                            //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                            //error
                            fileUplErMsg = "Please attach allowed file type.";
                            respdataFlag = false;
                        }
                        
  
                    }else{
                        //error
                        respdataFlag = false;
                        fileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
  
                    }
    
                }
            }
        }else{
            respdataFlag = false;
            fileUplErMsg = "Something went wrong. Please try again.";
        }
  
        this.isComponentLoaded && this.setState({ "fileUplErMsg": fileUplErMsg},()=>{
           
               
            this.getCustomModalContent({});

        });
        return respdataFlag;
    }
  
    async calUploadFileService(fileObj){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new SendInvoiceMailFileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});
  
  
        let taskTypeFlag = 0;
  
        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj
        };
  
        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity;       
  
            let prevDoc = [...this.state.attachedFiles];
            let docs = entityObj;
            Object.keys(docs).map(function(key, index) { 
            // convert server response of file size from mb to bytes 
            let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
            prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
            });
            this.isComponentLoaded &&  this.setState({"attachedFiles":prevDoc});
  
  
        }).catch((e)=>{
                //console.log.log("server error=>",e);
        }).finally(()=>{
            if(this.fileUpl && this.fileUpl.current){
                this.fileUpl.current.value = "";
            } 
            this.isComponentLoaded && this.setState({"uploadingFlag":false},()=>{
                this.getCustomModalContent({});
            });
        });
    }

    */

    /*** Feedback modal starts  */
    /**
     * InvoiceSharePopup
     * Function name : triggerCustomModalOpen
     * Author        :
     * Created Date  : 23-12-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   triggerCustomModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showCustomModal:true},()=>{
      this.modalcustomRef.current.showModal();
      this.getCustomModalContent({});
    });    
      
  }

  /**
     * InvoiceSharePopup
     * Function name : triggerCustomModalClose
     * Author        :
     * Created Date  : 23-12-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   triggerCustomModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showCustomModal:false},()=>{
      this.modalcustomRef.current.hideModal();
    });
    }

    /**
   * InvoiceSharePopup
   * Function name : getCustomModalContent
   * Author        :
   * Created Date  : 23-12-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getCustomModalContent(otherParam) {  
        

    let viewData1 = <div ref={this.popupcustheaderdiv} className="dash_modal ">
                    <div className="modal-header">
                        <h4 className="modal-title modal_title_tool">Share Invoice</h4>
                        <button type="button" onClick={
                                            (evn)=>{
                                                evn.preventDefault();
                                            this.triggerCustomModalClose();

                                        }} className="close modal_close" data-dismiss="modal">×</button>
                    </div>
                    <CustomMessageBar keydata={"invsharepopupmailmsgNotiK1"} />
                    <div className="modal-body padding_top">
                        <div className="modal_body_row_bg invoPay_modal_body invGen_modal_body">
                            <div className="popUP_text_p">
                            <p className="popUp_yellow_text"><span><i className="fa fa-info-circle" aria-hidden="true" /></span> Type email id &amp; hit enter to add multiple email id’s.</p>
                            </div>
                            <div className="invGen_sendMail_popForm">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
                <div className={`modal_input form__group field ${ this.state.toEmailNotProvidedErMsg?'form_group_error':''}`} >
                            <label className="form__label">To</label>
                            {/* <input type="input" className="form__field" placeholder="Enter email ID" /> */}
                            <div className="custom_tag_wrapper">
                                <TagsInput inputProps={{
                                className: 'react-tagsinput-input ',
                                placeholder: 'Enter email ID'}} value={this.state.toEmailAddresses} onChange={this.handleToEmailChange} />
                        
                            </div>
                            <span className="error_span">{this.state.toEmailNotProvidedErMsg}</span>
                            </div>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className={`modal_input form__group field ${ this.state.ccEmailNotProvidedErMsg?'form_group_error':''}`}>
                            <label className="form__label">CC</label>
                            {/* <input type="input" className="form__field" placeholder="Enter email ID" /> */}
                            <div className="custom_tag_wrapper">
                                <TagsInput inputProps={{
                                    className: 'react-tagsinput-input ',
                                    placeholder: 'Enter email ID'}} value={this.state.ccEmailAddresses} onChange={this.handleCcEmailChange} />
                            </div>
                            <span className="error_span">{this.state.ccEmailNotProvidedErMsg}</span>
                    </div>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className={`modal_input form__group field ${ this.state.subjectErMsg?'form_group_error':''}`}>
                            <label className="form__label">Subject</label>
                            <input name="subject"
                                        value={this.state.subject}
                                        onChange={this.handleChange} type="input" className="form__field" placeholder="Enter" name="subject" />
                            <span className="error_span">{this.state.subjectErMsg}</span>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className={`modal_input form__group field ${ this.state.bodyErMsg?'form_group_error':''}`} >
                        <label className="form__label">Message Body (Optional)</label>
                        <textarea type="input" className="form__field" placeholder="Enter"  name="body"
                                    value={this.state.body}
                                    onChange={this.handleChange} />
                      
                        <span className="error_span">{this.state.bodyErMsg}</span>
                        </div>
                    </div>
      </div>
    </div>
                            {/* === InvH attach file check box ===  */}
                            {/* <div className="modal_attach_section">
                                <div className="modal_attach_btn_sec">
                                        <div className="modal_attach_btn">
                                    
                                    <button onClick={(evn)=>{
                                                    evn.preventDefault();
                                                    this.openFileUploadDialog();

                                                }}  className="btn_appearance_none greenText_btn">
                                                    <img src={getBaseUrl()+"assets/images/path.png"} className="mCS_img_loaded" />
                                                    Attach files
                                    </button>
                                    <input style={{"display":"none"}} ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                                    <p>(Allowed File Types: JPG,JPEG,PNG,DOC,DOCX,TXT,PDF,XLSX,PPT,MP3,MP4)</p>
                                    <p>(Total maximum upload file size: 20 MB)</p>
                                </div>
                                        <span className="error_span">{this.state.fileUplErMsg}</span>
                                </div>
                                <div className="modal_attach_files">
                        <div className="custom_fileup_name">
                     

                        {this.state.uploadingFlag && (
                                        <div>Uploading...</div>
                                        )}
                                    
                                        {Object.keys(this.state.attachedFiles).length > 0 &&
                                            Object.keys(this.state.attachedFiles).map((k, i) => (
                                                <div key={i} className="upfile_name_box">
                                                <span className="upfile_name">{this.state.attachedFiles[k].name}</span>
                                                <button onClick={(e)=>{
                                                    e.preventDefault();

                                                    this.removeDocOnClick(i);
                                                    
                                                    }} className="upfile_name_cross btn_appearance_none">
                                                    <img src={getBaseUrl()+"assets/images/error.png"} />
                                                </button>
                                                            
                                                        </div>

                                                    ))
                                                }
                        
                    </div>
                    </div>
                            </div> */}
                            <div className="modal_attach_section modal_attach_checkBoxsec">
                            <label className="invh_chkbox_sec">
                                Attach Files
                                <input type="checkbox" name="attachFileCheckbox" checked={this.state.attachFileCheckbox} onChange={this.handleChange} />
                                <span className="checkmark" />
                            </label>
                            </div>
                            {/* === InvH attach file check box ===  */}
                    </div>
                    </div>
                    <div className="modal-footer">
                            <button onClick={
                                (evn)=>{
                                    evn.preventDefault();
                                this.triggerCustomModalClose();

                            }}  type="button"  className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                                
                            <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                            this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                                this.preCallOfSendMailService(otherParam);
                            });
                            

                            } } ><span>Send</span></button>
                    </div>
                </div>;
    
        this.modalcustomRef.current.updateStateContent(viewData1);
        this.modalcustomRef.current.showContent();
        return viewData1;
    }    

    /*** Feedback modal related ends  */


   

    /**
     * InvoiceSharePopup
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>    
                

             {this.props.controltype=="BUTTON" && (
                  <button key={this.props.keydata!==undefined?this.props.keydata:"invhistoryshareinvpdf"} onClick={(evn)=>{
                    evn.preventDefault();
                    //console.log(" from listing page==>"," datas=>>",this.props.datas);
                    let stateParams = {"toEmailAddresses": [],
                    "ccEmailAddresses": [],
                    "bccEmailAddresses": [],
                    // "subject":"",
                    // "body":"",  
                    "fileIds":[],
                    // other 
                    "sendButtonFlag":true,
                    "showCustomModal": false,
                    "uploadingFlag": false,
                    //"attachedFiles":[],
                    //"invoiceId":0,
                    "attachFileCheckbox":false,

                    //error messages
                    "bodyErMsg":"",
                    "subjectErMsg":"",
                    "fileUplErMsg":"",
                    "toEmailNotProvidedErMsg":""};

                    this.isComponentLoaded && this.setState({
                        stateParams
                    },()=>{
                        this.checkToOpenModal({});
                    });
                    

                  }} className={this.props.customclassname?this.props.customclassname:""}>{this.props.labeltext?this.props.labeltext:""}</button>

             )}                
             {this.props.controltype=="ANCHOR" && (
                  <a href="" key={this.props.keydata!==undefined?this.props.keydata:"invhistoryshareinvpdf"} onClick={(evn)=>{
                    evn.preventDefault();
                    //console.log(" from listing page==>"," datas=>>",this.props.datas);
                    let stateParams = {"toEmailAddresses": [],
                    "ccEmailAddresses": [],
                    "bccEmailAddresses": [],
                    // "subject":"",
                    // "body":"",  
                    "fileIds":[],
                    // other 
                    "sendButtonFlag":true,
                    "showCustomModal": false,
                    "uploadingFlag": false,
                    //"attachedFiles":[],
                    //"invoiceId":0,
                    "attachFileCheckbox":false,

                    //error messages
                    "bodyErMsg":"",
                    "subjectErMsg":"",
                    "fileUplErMsg":"",
                    "toEmailNotProvidedErMsg":""};

                    this.isComponentLoaded && this.setState({
                        stateParams
                    },()=>{
                        this.checkToOpenModal({});
                    });
                    

                  }} className={this.props.customclassname?this.props.customclassname:""}>{this.props.labeltext?this.props.labeltext:""}</a>

             )}         
               
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalcustomRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showCustomModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="modal-dialog custom_modal_lg modal-lg modal-dialog-centered modal_invHis_sendMail"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

               


             </>
            
        );
    }

  }
  export default InvoiceSharePopup;