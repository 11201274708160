import React, { Component } from 'react'

//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
export class FetchKycService extends Component {
    constructor() {
        super();
        this.state = {
  
        }
  
        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/kyc/lp';
    }
    async getKycDetails(){
        this.axiosObj = axiosCustomObj({});
        
        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
  
        try{
              let offsetHr = offsetOfLocalDateTime.hr;
              let offsetMin = offsetOfLocalDateTime.min;
              let urldata =this.apiEndPoint;
  
              urldata = urldata+"?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin;
  
  
              let headerOptn = {
                "Content-Type": "application/json",
                "Authorization": accToken
              };
  
              let respObj = this.axiosObj.get(urldata,{
              headers: headerOptn
              });

              return respObj;
        }
        catch(err){
            //console.log("Some error occured===>",err); // TypeError: failed to fetch
        }
    }
}

export default FetchKycService
