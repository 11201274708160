/**  
 * TaskTimeLogListService
 * Page/Class name   : TaskTimeLogListService
 * Author            :
 * Created Date      : 19-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class TaskTimeLogListService extends Component {

    
    /**  
     * TaskTimeLogListService
     * Function name : constructor
     * Author        :
     * Created Date  : 19-5-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {

      }

      //this.axiosObj = axios;
      this.axiosObj = null;

      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/time/report';
    }

    /**  
     * TaskTimeLogListService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 19-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * TaskTimeLogListService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 19-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * TaskTimeLogListService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 19-5-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getTimeLogList(paramObj){
      this.axiosObj = axiosCustomObj({});
        
      let accToken = getTokenFromLocStorage();
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint;

            const defaultPageSize = getMaxPageSize();


            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;

            let data  = paramObj.data;
            let pageNum = data.pageNum?data.pageNum:1;
            let pageSize = data.pageSize?data.pageSize:defaultPageSize;
            let taskId = data.taskId?data.taskId:0;

            let sortOn = data.sortOn?data.sortOn:"";
            let sortDir = data.sortDir?data.sortDir:"";

            let dateFrom = data.dateFrom?data.dateFrom:"";
            let dateTo = data.dateTo?data.dateTo:"";
            
            let sortStr = "";
            if(sortOn!="" && sortDir!=""){
              sortStr = "&sort_on="+sortOn+"&sort_dir="+sortDir;
            }

            let dateFiltStr = "";
            if(dateFrom!="" && dateTo!=""){
              dateFiltStr = "&from="+dateFrom+"&to="+dateTo;
            }

            // if(taskOrSubTask=="STASK"){
            //   urldata = urldata+"/task/"+taskIdOrSubTaskId+"/?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+dateFiltStr+sortStr;
            // }
            // else if(taskOrSubTask=="SWSTASK"){
            //   urldata = urldata+"/sub_task/"+taskIdOrSubTaskId+"/?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+dateFiltStr+sortStr;
            // }
            urldata = urldata+"/task/"+taskId+"/?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+dateFiltStr+sortStr;
            //console.log("task log list api hitted with =>>",urldata);

            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        //console.log("Some error occured===>",err); // TypeError: failed to fetch
    }


    }

    
}
export default TaskTimeLogListService;