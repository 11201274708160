/**
 * DeleteDocService
 * Page/Class name   : DeleteDocService
 * Author            :
 * Created Date      : 15-05-2020
 * Functionality     : deleteDoc
 * Purpose           : api service page
**/

import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;


export class DeleteDocService extends Component {
   /**
     * DeleteDocService
     * Function name : constructor
     * Author        :
     * Created Date  : 15-05-2020
     * Purpose       : initializing state
     * Params        :
    **/
   constructor() {
    super();
    this.state = {
        
    }

    //this.axiosObj = axios;
    this.axiosObj = null;

    this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/documents/task';
  }

  /**  
   * DeleteDocService
   * Function name : deleteDoc
   * Author        :
   * Created Date  : 15-05-2020
   * Purpose       : delete doc
   * Params        : paramObj
  **/
  async deleteDoc(paramObj){
    this.axiosObj = axiosCustomObj({});
    
    let accToken = getTokenFromLocStorage();     
    try{
          let urldata =this.apiEndPoint+'/'+paramObj.taskId+'/doc/'+paramObj.docId;



          let headerOptn2 = {  
            
            "Content-Type": "application/json",
            "Authorization": accToken
          };            

          let respObj = this.axiosObj({
            method: 'DELETE',
            url: urldata,
            headers: headerOptn2
          });


          return respObj;


          
  }
  catch(err){
     // console.log("Some error occured===>",err); // TypeError: failed to fetch
  }
    

  }

}

export default DeleteDocService
