import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import  CustDate  from '../../../common_utilities/CustDate';
//services
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
import SubscriptionService from '../../service/SubscriptionService';

// for context
import CommonContext from '../../context/CommonContext';
// For loading CustLoader
import CustLoader from './CustLoader';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

const getBaseUrl = commonJsObj.getBaseUrl;
const custEncode = commonJsObj.custEncode;
const getFormattedAmount = commonJsObj.getFormattedAmount;
const setAccessObj = commonJsObj.setAccessObj;

export class Subscription extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props)
        //check commit 
        this.state = {
            isSubscriptionActive:false,
            payableAmount:'25000',
            promocodeErrMsg:false,
            promocodeSuccessMsg:false,
            subscriptionEndDate:''
        }
        this.promocodeRef = React.createRef();
    }
    componentDidMount(){
        this.isComponentLoaded=true;
        this.fetchLoggedinUserInfoDetails();
    }
    fetchLoggedinUserInfoDetails=()=>{
        let loginuserserv = new LoggedinUserInfoService();
        loginuserserv.getLoggedInUserInfo({}).then(data =>{
            if(data.status==200){
                let respObj  = data.data.entity;
                let isSubscriptionActive = false;
                let isKycVerified = false;
                
                let subscriptionEndDate = null;
                if(respObj!==undefined){
                   // console.log(data.data.entity.isSubscriptionActive)
                        isSubscriptionActive = respObj.isSubscriptionActive!==undefined && respObj.isSubscriptionActive!==null?respObj.isSubscriptionActive:false;
                        isKycVerified = respObj.isKycVerified!==undefined && respObj.isKycVerified!==null?respObj.isKycVerified:false;
                       
                        subscriptionEndDate = respObj.subscriptionEndDate!==undefined && respObj.subscriptionEndDate!==null?respObj.subscriptionEndDate:null;

                        setAccessObj({"key":"isSubscriptionActive","keyValue":isSubscriptionActive});
                        setAccessObj({"key":"isKycVerified","keyValue":isKycVerified});
               
                    this.setState({"isSubscriptionActive":isSubscriptionActive, "subscriptionEndDate": subscriptionEndDate},()=>{
                        //console.log(this.state)
                       
                    })
                }
            }
         }).catch(err=>{
            //console.log("Some server related tech problem occured",err);

        });
    }

    componentWillUnmount() {
        this.isComponentLoaded =  false;
    }
    loadLoader() {
        return (
            <CustLoader classNameName={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }

    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    showLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
    applyPromoCode = ()=>{
        // console.log(this.promocodeRef.current.value);
        let regexBlankSpace = /^ *$/;
        if(!(this.promocodeRef.current.value)||(this.promocodeRef.current.value.length==0)||(regexBlankSpace.test(this.promocodeRef.current.value)==true)){
            this.setState({promocodeErrMsg:'Promo Code is required', promocodeSuccessMsg:''});
        } else{
            let params= {
                "shouldEmailTheLink":false,
                "shouldSMSTheLink":false,
                "promoCode":this.promocodeRef.current.value,
            }
            let subscriptionserv = new SubscriptionService();
            subscriptionserv.generatePaymentLink(params).then(data =>{
                if(data.status==200){
                    if(data.data.entity!==undefined){
                    console.log(data.data.entity)
                    this.setState({payableAmount:data.data.entity.payableAmount,promocodeErrMsg:''});
                    if(data.data.entity.promoCodeApplied!==null){
                        this.setState({promocodeSuccessMsg:'Promo code applied successfully',promocodeErrMsg:''})
                    }
                    }
                }
            }).catch(err=>{
                console.log(err.response.data.message);
                this.setState({promocodeErrMsg:err.response.data.message, promocodeSuccessMsg:''});
                //console.log("Some server related tech problem occured",err);
            });
        }

    }
    subscribe = ()=>{
        let params= {
            "shouldEmailTheLink":false,
	        "shouldSMSTheLink":false,
	        "promoCode":this.promocodeRef.current.value,
        }
        let subscriptionserv = new SubscriptionService();
        subscriptionserv.generatePaymentLink(params).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                  console.log(data.data.entity)
                    //   this.setState({payableAmount:data.data.entity.payableAmount,promocodeErrMsg:''});
                    //   if(data.data.entity.promoCodeApplied!==null){
                    //       this.setState({promocodeSuccessMsg:'Promo code applied successfully'})
                    //   }
                    let subscriptionData={
                        discountPercentage: custEncode(data.data.entity.discountPercentage),
                        originalAmount: custEncode(data.data.entity.originalAmount),
                        payableAmount: custEncode(data.data.entity.payableAmount),
                        paymentLink: custEncode(data.data.entity.paymentLink),
                        promoAmount: custEncode(data.data.entity.promoAmount),
                        promoCodeApplied: custEncode(data.data.entity.promoCodeApplied),
                    };
                    localStorage.setItem("subscriptionData", JSON.stringify(subscriptionData));
                    this.props.history.push("/subscription-payment");
                }
            }
         }).catch(err=>{
            console.log(err.response.data.message);
            this.setState({promocodeErrMsg:err.response.data.message, promocodeSuccessMsg:''});
            //console.log("Some server related tech problem occured",err);
        });
    }
    render() {
        return (
            <>
                {/* Dashboard Main Screen content area 01 start */}
                    <div className="kyc_section_part_01">
                        <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section lp_settingContAreaBg">
                            {/* ===  DashBoard Main Body Start ===  */}
                            <div className="cutom_container cutom_container_userSetting">
                                <div className="create_task_area userSetting_area">

                                    {/* ===  task Listing filter start === */}
                                    <div className="task_area_filterbg task_area_filterbg_lp">
                                        <div className="filter_area ">
                                            {/* ===  task top filter start ===  */}
                                            {/* --- body top filter tab left start ---  */}
                                            <div className="top_filter_left top_filter_left_lp">
                                                <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                                                    {/* == lp task ul start ==   */}
                                                    {/* == lp task ul end ==   */}
                                                    {/* == profile setting ul start ==   */}
                                                    {/* == profile setting ul end ==   */}
                                                    {/* == application setting ul start ==   */}
                                                    <div className="profile_setting_ulbg app_set_ulbg">
                                                        <ul>
                                                            <li className="active">
                                                                <button className="btn_appearance_none task_ass_tabBtn">
                                                                    <span className="task_ass_span">Subscription</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button className="btn_appearance_none task_ass_tabBtn pointer_none">
                                                                    <span className="task_ass_span">User Roles</span>
                                                                    <span className="comming_soon">Coming soon</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button className="btn_appearance_none task_ass_tabBtn pointer_none">
                                                                    <span className="task_ass_span">Application Preferences</span>
                                                                    <span className="comming_soon">Coming soon</span>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* == application setting ul end ==   */}
                                                    {/* == profile setting ul start ==   */}
                                                    {/* == profile setting ul end ==   */}
                                                    <div className="clearboth"></div>
                                                </div>
                                            </div>
                                            {/* --- body top filter tab left end ---  */}
                                            {/* --- body top filter right start ---  */}
                                            {/* --- body top filter right end ---  */}
                                            {/* ===  task top filter end === */}
                                            <div className="clearboth"></div>
                                        </div>
                                    </div>
                                    {/* ===  task Listing filter end === */}

                                    {/* ===========  Setting Page main body start =============   */}
                                    {/* ===  task Listing start start === */}
                                    {/* ===  task Listing start end === */}
                                    {/* ===========  Setting Page main body end =============   */}

                                </div>
                            </div>
                            {/* ===  DashBoard Main Body End ===  */}
                        </div>
                    </div>
                    {/* Dashboard Main Screen content area 01 end */}
                    {/* Dashboard Main Screen content area 02 start */}
                    <div className="kyc_section_part_02">
                        <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg">
                            {/* ===  DashBoard Main Body Start ===  */}
                            <div className="cutom_container cutom_container_userSetting">
                                <div className="create_task_area userSetting_area">
                                    {/* ===  task Listing filter start === */}
                                    {/* ===  task Listing filter end === */}
                                    {/* ===========  Setting Page main body start =============   */}
                                    {/* ===  task Listing start start === */}
                                    <div className="task_area userSetting_task_area">
                                        {/* === User Setting Section / Task Listing Section Start === */}
                                        {/* ============== Start =================   */}
                                        <div className="userSetting_section">
                                            {/* -----  scroll adjust for settting screens start -----   */}
                                            <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                                                {/* ==============   User Setting Section Start =================   */}
                                                <div className="userSetting_area_bg">

                                                    {/* ==--== Kyc Top Notification section start ==--== */}
                                                    {/* ==--== Kyc Top Notification section end ==--== */}

                                                    {/* ===== ** LP User KYC page body start =====  */}
                                                    <div className="userSetting_area user_page_area">
                                                        <div className="userSetting_scroll">
                                                            {/* ====  Subscription start ===  */}
                                                            <div className="subscription_sec_bg subscription_bg_lp">
                                                                <div className="subscription_title">
                                                                    <h4>Subscription</h4>
                                                                </div>
                                                                <div className="subscription_mid_block">
                                                                    <div className="subs_block_topStatus">
                                                                        {this.state.isSubscriptionActive &&
                                                                        <h4 className="greenTik_text greenTik_blackText">
                                                                            <span className="subGreenTik">
                                                                                <img src="assets/images/green_tick.png" alt=""/>
                                                                            </span>
                                                                            <span className="subGreenTik_text">
                                                                                Subscribed
                                                                            </span>
                                                                        </h4>
                                                                        }
                                                                    </div>
                                                                    {/* == subscription blue box start ==  */}
                                                                    <div className="subs_blueBox_bg">
                                                                        <div className="subsBlueBox_top">
                                                                            <h5>Elite</h5>
                                                                        </div>
                                                                        <div className="subsBlueBox_ammount_block">
                                                                            <div className="subsBlueBox_ammount">
                                                                                <img src="assets/images/sub_rupee_icon.png" alt=""/>
                                                                                <span> {getFormattedAmount({"dataToFormat":this.state.payableAmount,"formatType":1})}/ year</span>
                                                                            </div>
                                                                            <h5>(inclusive of GST)</h5>
                                                                        </div>
                                                                        <div className="subsBlueBox_list_block">
                                                                            <ul>
                                                                                <li>
                                                                                    <h4 className="greenTik_text greenTik_whiteText">
                                                                                        <span className="subGreenTik_text">
                                                                                            Get Tasks
                                                                                        </span>
                                                                                    </h4>
                                                                                </li>
                                                                                <li>
                                                                                    <h4 className="greenTik_text greenTik_whiteText">
                                                                                        <span className="subGreenTik_text">
                                                                                            Full access to knowledge centre
                                                                                        </span>
                                                                                    </h4>
                                                                                </li>
                                                                                {/*                                                                     
                                                                                <li>
                                                                                    <h4 className="greenTik_text greenTik_whiteText">
                                                                                        <span className="subGreenTik_text">
                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                        </span>
                                                                                    </h4>
                                                                                </li> 
                                                                                */}
                                                                            </ul>
                                                                        </div>
                                                                        {this.state.subscriptionEndDate && <div className="subsBlueBox_validity_block">
                                                                            <div className="subsBlueBox_validity_box">
                                                                                <p>Current plan valid till {this.state.subscriptionEndDate ? CustDate.custFormatDate((new Date(this.state.subscriptionEndDate)),7) :"None"}</p>
                                                                            </div>
                                                                        </div>}
                                                                        {!this.state.isSubscriptionActive && <div className="subsBlueBox_promoCode_block">
                                                                            <div className="panel100 form__group_normar">
                                                                                <label htmlFor="name" className="form__label"><b>Have a promo code?</b>&nbsp;
                                                                                    <div className="clearboth"></div>
                                                                                </label>
                                                                                <div className={`form__group field ${this.state.promocodeErrMsg?'form_group_error':''} ${this.state.promocodeSuccessMsg?'form_group_success':''}`}>
                                                                                    <div className="multi_promo_input_block">
                                                                                        <input className="promo_input" placeholder="Enter promo code" type="text" ref={this.promocodeRef}/>
                                                                                        <button className="btn_appearance_none greenText_btn promo_input_btn" onClick={()=>{this.applyPromoCode()}}>Apply</button>
                                                                                        <div className="clearboth"></div>
                                                                                    </div>
                                                                                    <span className="error_span">{this.state.promocodeErrMsg}</span>
                                                                                    <span className="success_span">{this.state.promocodeSuccessMsg}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                        {!this.state.isSubscriptionActive && <div className="subsBlueBox_button_block">
                                                                            <button className="btn_appearance_none sub_grd_btn" onClick={()=>{this.subscribe()}}><span>Subscribe</span></button>
                                                                        </div>}
                                                                    </div>
                                                                    {/* == subscription blue box start ==  */}
                                                                </div>
                                                            </div>
                                                            {/* ====  Subscription end ===  */}
                                                            {/* ====  User KYC start ===  */}

                                                            {/* ====  User KYC end ===  */}
                                                        </div>
                                                    </div>
                                                    {/* ===== ** LP User KYC page body end =====  */}

                                                </div>
                                                {/* ==============   User Setting Section end =================   */}
                                            </div>
                                            {/* -----  scroll adjust for settting screens end -----   */}
                                        </div>
                                        {/* ============== end =================   */}
                                        {/* === User Setting Section / Task Listing Section end === */}
                                    </div>
                                    {/* ===  task Listing start end === */}
                                    {/* ===========  Setting Page main body end =============   */}
                                </div>
                            </div>
                            {/* ===  DashBoard Main Body End ===  */}
                        </div>
                        {/* ===  DashBoard Main Body Footer Start ===  */}
                        {/* User Setting footer strat */}
                        {/* *****  Put as Dispaly: None ***** */}
                        <div className="task_details_btn_area invoPay_footer kyc_footer" style={{"display": "none"}}>
                            <div className="btn_fld">
                                <div className="panel50">
                                    <button className="btn_appearance_none green_btn"><span>Save as draft</span></button>
                                </div>
                                <div className="panel50">
                                    <div className="cancel_continue_btn">
                                        <button type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                                        <button className="btn_appearance_none sub_grd_btn"><span>Submit</span></button>
                                    </div>
                                </div>
                                    <div className="clearboth"></div>
                            </div>
                        </div>
                        {/* *****  Put as Dispaly: None ***** */}
                        {/* User Setting footer end */}
                        {/* ===  DashBoard Main Body Footer end ===  */}
                    </div>
                    {/* Dashboard Main Screen content area 02 end */}
            </>
        )
    }
}

export default Subscription
