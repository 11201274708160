/** 
 * Error
 * Page/Class name   : Error
 * Author            :
 * Created Date      : 23-10-2019
 * Functionality     : ErorComp
 * Purpose           : render error component
**/
import React from 'react';
import '../../custom_resources/css/custom.css';

const ErorComp = () => {

	return (
		<div className="content text-center notfoundPage">
            <div><h1>OOPS!</h1></div>
        </div>
	);
}
export default ErorComp;