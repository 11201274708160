/** 
 * Error
 * Page/Class name   : Error
 * Author            :
 * Created Date      : 23-10-2019
 * Functionality     : ErorComp
 * Purpose           : render error component
**/
import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import ee from 'event-emitter';
//import '../../custom_resources/css/custom.css';

import { commonJsFuncModule as commonJsObj } from '../../common_utilities/commonjsfunc';

const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;

const emitter = new ee();

export const notifytoapiresptrack = (optionName,otherParam) => {
    emitter.emit('apiRespStatusReceiver', optionName, otherParam);
}
class ApiResponseStatusTrackCustom extends Component {


	isComponentLoaded = false;

	constructor(props) {
		super(props);
		this.state = {
			"errMsg" : "",
			"errStatusCode" : 0,
		}
		// for event emitter 
		emitter.on('apiRespStatusReceiver',(optionName,otherParam)=>{        
			//console.log(" GOT apiRespStatusReceiver ======",optionName,otherParam);
			switch(optionName){
			  case 401:              
				//this.setErrorStatusMessage(otherParam);
				removeTokenFromLocStorage();
				setTimeout(()=>{
					this.props.history.push("/sign-in");
				},1200);  
				
				break;
			  case 403:              
				//this.setErrorStatusMessage(otherParam);
				//removeTokenFromLocStorage();  
				//this.props.history.push("/sign-in");
				break;
			  case 400:              
				//this.setErrorStatusMessage(otherParam);
				break;
			  case 500:
				//this.setErrorStatusMessage(otherParam);
				break;
			  default:
				//this.setErrorStatusMessage(otherParam);
				break;

			}          
  
		});



	}

	componentDidMount() {

		this.isComponentLoaded =  true;    
	}

	componentWillUnmount() {  
        this.isComponentLoaded =  false;
        
    }   

	setErrorStatusMessage = (otherParam) =>{
		let errMsg = otherParam.errMsg!==undefined && otherParam.errMsg!==null?otherParam.errMsg:"";
		let errStatusCode = otherParam.errStatusCode!==undefined && otherParam.errStatusCode!==null?otherParam.errStatusCode:0;
		this.isComponentLoaded && this.setState({"errMsg":errMsg,"errStatusCode":errStatusCode});

	}


	render() { 

		return (
					<>
					{this.state.errMsg  && (
						<div className="text-center">
							<p>{this.state.errMsg}</p>
						</div>
					)}
					</>
			
			);
	}


}

export default withRouter(ApiResponseStatusTrackCustom);