/**
 * CustDate
 * Page/Class name   : CustDate
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering custom moment formated date
**/
import React, { Component} from 'react';
//import Moment from 'moment';
import Moment from "moment-timezone";

const CustDate =  {
    
    custFormatDate: (dateObj,formatOptn, otherParam) =>{
            let prxyLocTz = "Asia/Calcutta";
            let manualDefTz = otherParam!==undefined && otherParam.manualDefTz!==undefined?otherParam.manualDefTz:"";
            let format= otherParam!==undefined && otherParam.format!==undefined?otherParam.format:"";
            let local= otherParam!==undefined && otherParam.local!==undefined?otherParam.local:"en";
            
            let defTz = "";
            if(manualDefTz){
                    defTz = manualDefTz;
            }
            else if(!(manualDefTz) && Moment.tz!==undefined && Moment.tz!==null && Moment.tz.guess() ){
                defTz = Moment.tz.guess();
                
            }else{
                defTz = prxyLocTz;
            }
            // Intl.DateTimeFormat().resolvedOptions().timeZone;
                
            Moment.locale(local);
            Moment.tz.setDefault(defTz);
            if(formatOptn == 1){
                return Moment(dateObj).format('Do MMM YYYY');
            }
            else if(formatOptn == 2){
                return Moment(dateObj).format('MM/DD/YYYY');
            }
            else if(formatOptn == 3){
                return Moment(dateObj).format('DD MMM YYYY');
            }
            else if(formatOptn == 4){
                return Moment(dateObj).format('h:mm a | DD MMM YYYY');
            }
            else if(formatOptn == 5){
                return Moment(dateObj).format('DD/MMM/YYYY');
            }
            else if(formatOptn == 6){
                //return Moment(dateObj).format('DD MMM YYYY h:mm a');
                return Moment(dateObj).format("DD MMM YYYY h:mm a z");
            } else if(formatOptn == 7){
                return Moment(dateObj).format('Do MMMM YYYY');
            } 
            else if(formatOptn == 8){
                return Moment(dateObj).format('DD/MMM/YYYY');
            } 
            else if(formatOptn == 9){
                
                return Moment(dateObj).format("DD MMM YYYY, h:mm A z");
            }
            else if(formatOptn == 'ts'){
                
                return Moment(dateObj).format('x');
            }
            else if(formatOptn == 'mom'){ 
                // newly added on 3/11/2020
                
                return Moment(dateObj);
            } 
            else if(formatOptn === null && dateObj===null){
                // newly added on 3/11/2020
                return Moment;
            }

            else{
                if(formatOptn==0 && format!=='' ){
                    return Moment(dateObj).format(format);
                }            
            }
            return '';
        }
}
  

export default CustDate;