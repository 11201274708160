/**
 * FeedbackAssistant
 * Page/Class name   : CustLoader
 * Author            :
 * Created Date      : 17-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';



class FeedbackAssistant extends Component {
    /**
     * FeedbackAssistant
     * Function name : constructor
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {
        }    
    }

    /**
     * FeedbackAssistant
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        
    }

    /**
     * FeedbackAssistant
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }

   
    /**
     * FeedbackAssistant
     * Function name : render
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>
                 <button onClick={(evn)=>{ evn.preventDefault();}} className="btn_appearance_none talkToAssist_btn pointer_none">
                                Talk to your assistant
                                <span className="comming_soon">Coming soon</span>
                 </button>
             </>
            
        );
    }

  }
  export default FeedbackAssistant;