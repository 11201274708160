

// load google map library
import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const getGoogleApiKey = commonJsObj.getGoogleApiKey;

export const LoadGoogleMap = {

        loadGoogleMapLibrary: (callback)=>{
          const existingScript = document.getElementById('googleMaps');
          if(!existingScript){

                  var aScript = document.createElement('script');
                  //let keyData = "AIzaSyA54_XSZfXNulyFOyzCXtLF_fbd9RPV1Fk";
                  let keyData = getGoogleApiKey();

                  aScript.type = 'text/javascript';
                  aScript.src = "https://maps.googleapis.com/maps/api/js?key="+keyData+"&libraries=places";

                  aScript.id= "googleMaps";
                  document.body.appendChild(aScript);
                  

                  aScript.onload = () => {
                  if (callback) callback();
                  };

          }
          
          // if (existingScript && callback) callback();


        }
      };
