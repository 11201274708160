import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
export class ForgotPasswordService extends Component {
    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/noauth/password';
    }
    async sendResetLink(paramObj){
        this.axiosObj = axiosCustomObj({});
        
        let urldata =this.apiEndPoint+'?email_id='+paramObj.emailId;
        try{
              //let headerOptn = { 'Content-Type': 'application/json','accept': '*/*' };
              let headerOptn = { 'Access-Control-Allow-Origin':'*','Content-Type': 'application/json' };
              let respObj = this.axiosObj({
              method: 'DELETE',
              url: urldata,
              data: paramObj,
              headerOptn
              });

              return respObj;
      }
      catch(err){
          //console.log("Some error occured===>",err); // TypeError: failed to fetch
      }


    }
}

export default ForgotPasswordService
