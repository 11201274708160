import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;
export class FetchMatterNameListService extends Component {
    constructor() {
        super();
        this.state = {
        }
        //this.axiosObj = axios;
        this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/matter/matters';
    }
    async getMatterNames(paramObj){
            this.axiosObj = axiosCustomObj({});

            let accToken = getTokenFromLocStorage();
            //console.log(accToken,"accToken")
            try{
               
                let urldata =this.apiEndPoint;     
                
                let clientId = paramObj.clientId!==undefined?paramObj.clientId:0; 
                
                urldata  = urldata + "?client_id="+clientId;                

                let headerOptn = {
                    "Content-Type": "application/json",
                    "Authorization": accToken
                };

                let respObj = this.axiosObj.post(urldata,{},{
                headers: headerOptn
                });

                return respObj;
        }
        catch(err){
        // console.log("Some error occured===>",err); // TypeError: failed to fetch
        }
    }
}

export default FetchMatterNameListService
