/**  
 * SideBar
 * Page/Class name   : SideBar
 * Author            :
 * Created Date      : 12-2-2020
 * Functionality     : SideBar
 * Purpose           : load SideBar component
**/
import React from 'react';
import {Link, NavLink} from 'react-router-dom';

//custom css file
import '../../custom_resources/css/custom.css';
import '../../custom_resources/css/custom_responsive.css';

function SideBar(){
    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <div className="user-panel">
                    <div className="pull-left image"> &nbsp;
                        {/*<img src="img/user2-160x160.jpg" className="img-circle" alt="User Image" />*/}
                    </div>
                    <div className="pull-left info">
                        {/*
                        <p>Alexander Pierce</p>
                        <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
                        */}
                    </div>
                </div>            
                <ul className="sidebar-menu" data-widget="tree">
                    <li className="header">MAIN NAVIGATION</li>
                    <li>                        
                        <NavLink to="/dashboard" activeClassName="activeNavLinkCust" >
                        <i className="fa fa-bars"></i><span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li>                        
                        <NavLink to="/tweet-map" activeClassName="activeNavLinkCust" >
                        <i className="fa fa-map-marker"></i><span>Tweet Map</span>
                        </NavLink>
                    </li>                       
                    <li>
                        <NavLink to="/word-cloud" activeClassName="activeNavLinkCust" >
                        <i className="fa fa-tag"></i><span> Word Cloud</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/top-tweets" activeClassName="activeNavLinkCust" >
                        <i className="fa fa-twitter"></i><span> Top Tweets</span>
                        </NavLink>
                    </li>
                                            
                </ul>
                </section>
            </aside> 
        )
    }

              
export default SideBar;
