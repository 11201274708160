import React, { Component } from "react";
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
import ChatListOfNegotiationService from "../../service/ChatListOfNegotiationService";
import ChatReplyService from "../../service/ChatReplyService";
import TakeUpTaskService from "../../service/TakeUpTaskService";
import TaskDetailsService from "../../service/TaskDetailsService";

import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import CustomMessageBar from "../../messagebar/CustomMessageBar";
import { notify } from "../../messagebar/CustomMessageBar";

import CustReactBootstrapModal from "./CustReactBootstrapModal";
// for context
import CommonContext from "../../context/CommonContext";
// scroll
import { Scrollbars } from "react-custom-scrollbars";
// For loading CustLoader
import CustLoader from "./CustLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustDate from "../../../common_utilities/CustDate";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

// AcceptTaskOfferButton
import AcceptTaskOfferButton from "./AcceptTaskOfferButton";

// Newest
import { throttle, debounce } from "throttle-debounce";

const getCurrentUserId = commonJsObj.getCurrentUserId;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
const getBaseUrl = commonJsObj.getBaseUrl;
const getSiteName = commonJsObj.getSiteName;
const getUserName = commonJsObj.getUserName;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;
const findElement = commonJsObj.findElement;
const validateBlankSpace = commonJsObj.validateBlankSpace;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;
const emailIdPresenceCheck = commonJsObj.emailIdPresenceCheck;
const phonePresenceCheck = commonJsObj.phonePresenceCheck;

const ExampleCustomInput = ({ value, onClick }) => (
  <input
    type="text"
    className="form__field customdatefld"
    placeholder="Select Due Date"
    defaultValue={value}
    onClick={onClick}
    readOnly={true}
  />
);
export class SplitScreenProfessionalsInterested extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;
  constructor(props) {
    super(props);
    this.children = props.children;
    this.state = {
      pageNum: 1,
      totalNumberOfPages: 1,
      listData: [],
      loaderFlag: true,
      negoOn: 0, // 0=> on budget, 1=> on date
      taskId: "",
      taskName: "",
      taskDescription: "",
      ccOrganisationName: "",
      lpUserName: "",
      taskType: "",
      taskOfferedAt: "",
      taskOfferedAtMillis: null,

      ccUserId: "",
      lpUserId: "",
      negoOnBudgetSend: false, // for budget
      negoBudgetClickFlag: false, // for budget

      negoOnDateSend: false, // for date
      negoDateClickFlag: false, // for budget

      isTermsAcceptedByLP: false,
      isTaskOfferReceived: false,
      assignedTo: null,

      proposedBudget: "",
      proposedBudgetCurrency: "",
      proposedDueDate: "",
      budgetNegotiationStatus: "",
      dueDateNegotiationStatus: "",
      conclusionMessage: "",

      // user latest chat record
      userLatestChatRecord: null,
      // budget input
      budgetInput: "",
      // Due date input
      dueDateInput: null,
      showEngagementModal: false,
      taskResp: null,
      agreedBudgetCurrency: "",
      agreedBudget: "",
      agreedDueDate: "",
      // for REJECT
      previousNegotiatedBudgetCurrency: "",
      previousNegotiatedBudget: "",
      previousNegotiatedDueDate: "",
      //
      notificationIdAr: [],
      lastCommentId: 0,
      scrollToNewNotiMsgFlag: false,
      tabDisFlag: true,

      // new messsage control related
      budgetUpdationDescription: "",
      dueDateUpdationDescription: "",
      budgetDueDateCommonMsg: "",
      budgetDueDateCommonMsgErr: "",

      budgetErMsg: "",
      dueDateErMsg: "",
    };
    this.scrollerRef = React.createRef();
    this.modalforEngagetentRef = React.createRef();
    this.negochatcontentHead = React.createRef();

    // Newest
    this.slowDownScrollRelHit = debounce(500, this.trackScrollNewFunc);
  }

  // testHigh = ()=>{
  //   let hG = ["7","INR", "8", "BAL"];
  //   let strTxt = "hi Mr 777 INR 7 is low INR 8 go 7";
  //   let strHashTxt = "hi Mr 777 INR 7 is low INR 8 go 7";

  //   let strTxtObj = "";

  //   let highLightKeyPos = [];
  //   let partsFoundObj = [];

  //   hG.map((part)=>{

  //     let regex = new RegExp(`(${part})`, 'gi');

  //     let result = [];
  //       while( result = regex.exec(strTxt)) {

  //         let keyObject = <b onClick={()=>{}} key={"k"+result.index} >{ part }</b>;

  //        let splitItAr =  strHashTxt.split(part);
  //        strHashTxt = splitItAr.join("####");

  //        let objPush = {"keyName":part, "keyObject":keyObject,"positionNumber":result.index};

  //         partsFoundObj.push(objPush);

  //         }

  //   });

  //   console.log("oM===>>>",partsFoundObj,strHashTxt);
  //   partsFoundObj = partsFoundObj.sort(sortArrayOfJsonByKey('positionNumber','ASC'));
  //   console.log("partsFoundObj===>>>",partsFoundObj);

  // }

  componentDidMount() {
    //this.testHigh();

    this.isComponentLoaded = true;
    let taskId = 0;
    let ccUserId = 0;
    let taskDescription = "";
    let taskName = "";
    let ccOrganisationName = "";
    let taskType = ""; //OFFER_RECEIVED
    let taskOfferedAt = "";
    let taskOfferedAtMillis = null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        taskId = sharedData.taskId !== undefined ? sharedData.taskId : 0;

        let taskResp =
          sharedData.other !== undefined &&
          sharedData.other &&
          sharedData.other.taskResp
            ? sharedData.other.taskResp
            : null;

        taskName = taskResp.taskName !== undefined ? taskResp.taskName : "";
        taskDescription =
          taskResp.description !== undefined ? taskResp.description : "";
        ccOrganisationName =
          taskResp.organisationDetails &&
          taskResp.organisationDetails.organisationName !== undefined
            ? taskResp.organisationDetails.organisationName
            : "";
        taskType =
          taskResp.taskType && taskResp.taskType !== undefined
            ? taskResp.taskType
            : "";
        taskOfferedAt =
          taskResp.taskOfferedAt && taskResp.taskOfferedAt !== undefined
            ? taskResp.taskOfferedAt
            : "";
        taskOfferedAtMillis =
          taskResp.taskOfferedAtMillis &&
          taskResp.taskOfferedAtMillis !== undefined
            ? taskResp.taskOfferedAtMillis
            : null;

        ccUserId = sharedData.other.taskResp.owner.userId;
        let paramObj = {
          taskId: taskId,
          taskName: taskName,
          taskDescription: taskDescription,
          ccOrganisationName: ccOrganisationName,
          ccUserId: ccUserId,
          lpUserId: getCurrentUserId(),
          lpUserName: getUserName(),
          pageNum: this.state.pageNum,
          negoOn: 0,
        };

        this.isComponentLoaded &&
          this.setState(paramObj, () => {
            this.chooseNegoTab("BUDGET", {});
            //this.callServiceToFetchNegoChatRespList(paramObj);

            setTimeout(() => {
              // this.callServiceToFetchNegoChatRespList({pageNum:this.state.pageNum,taskId:this.state.taskId,ccUserId:this.state.ccUserId,negoOn:0});
            }, 500);
          });

        // Newest
        setTimeout(() => {
          this.scrollerRef &&
            this.scrollerRef.current !== null &&
            this.scrollerRef.current.addEventListener(
              "scroll",
              this.slowDownScrollRelHit
            );
        }, 5000);
      }
    }
  }

  // Newest
  componentWillUnmount() {
    this.isComponentLoaded = false;

    this.scrollerRef.current.removeEventListener(
      "scroll",
      this.slowDownScrollRelHit
    );
  }

  // Newest
  trackScrollNewFunc = () => {
    if (this.scrollerRef !== null && this.scrollerRef.current.scrollTop == 0) {
      //this.scrollerRef.current.scrollTop = 75;
      this.fetchMoreData();
    }
  };

  maketabactiveforbellnotirelnegochat = (paramObj) => {
    let tabToActive =
      paramObj.tabToActive !== undefined ? paramObj.tabToActive : "";
    let trigerredFrom =
      paramObj.trigerredFrom !== undefined ? paramObj.trigerredFrom : "";

    //console.log(" fom prof nego maketabactiveforbellnotirelnegochat otherParam====>>>>>>>>>>>",otherParam,tabToActive);

    if (trigerredFrom == "BELL_NOTIFICATION_LIST") {
      if (tabToActive == "BUDGET") {
        this.chooseNegoTab("BUDGET", {});
      } else if (tabToActive == "DATE") {
        this.chooseNegoTab("DATE", {});
      }
    } else if (trigerredFrom == "REAL_TIME_NOTI_TRACK") {
      let notiType =
        paramObj.notiType !== undefined && paramObj.notiType
          ? paramObj.notiType
          : "";
      let taskId =
        paramObj.taskId !== undefined && paramObj.taskId ? paramObj.taskId : 0;
      let notificationId =
        paramObj.notificationId !== undefined && paramObj.notificationId
          ? paramObj.notificationId
          : 0;
      let notifiSenderId =
        paramObj.notifiSenderId !== undefined && paramObj.notifiSenderId
          ? paramObj.notifiSenderId
          : 0;
      let notificationIdAr = [...this.state.notificationIdAr];

      if (notiType == "NEGOTIATION") {
        let chat =
          paramObj.chat !== undefined && paramObj.chat ? paramObj.chat : null;
        if (this.state.taskId == taskId) {
          if (chat && notificationId) {
            let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
            if (chat.budget && chat.budgetCurrency && this.state.negoOn == 0) {
              // if currently budget tab is active & noti is received on budget
              if (chkNotiIdPres == -1) {
                notificationIdAr.push(notificationId);
                this.isComponentLoaded &&
                  this.setState({ notificationIdAr: notificationIdAr }, () => {
                    paramObj["calledFrom"] = "REAL_TIME_NOTIFICATION_TRACK";
                    this.pushChatToList(paramObj);
                  });
              }
            } else if (
              chat.dueDate &&
              chat.dueDateInMillis &&
              this.state.negoOn == 1
            ) {
              // if currently due date tab is active & noti is received on due date
              if (chkNotiIdPres == -1) {
                notificationIdAr.push(notificationId);
                this.isComponentLoaded &&
                  this.setState({ notificationIdAr: notificationIdAr }, () => {
                    paramObj["calledFrom"] = "REAL_TIME_NOTIFICATION_TRACK";
                    this.pushChatToList(paramObj);
                  });
              }
            }
          }
        }
      } else if (notiType == "TASK_OFFERED") {
        let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
        if (chkNotiIdPres == -1) {
          notificationIdAr.push(notificationId);
          this.isComponentLoaded &&
            this.setState({ notificationIdAr: notificationIdAr }, () => {
              if (this.state.taskId == taskId) {
                if (this.state.negoOn == 0) {
                  this.chooseNegoTab("BUDGET", {});
                } else if (this.state.negoOn == 1) {
                  this.chooseNegoTab("DATE", {});
                }
              }
            });
        }
      }
    }
  };

  pushChatToList = (paramObj) => {
    let chat =
      paramObj.chat !== undefined && paramObj.chat ? paramObj.chat : null;
    let budgetNegotiationStatus =
      paramObj.budgetNegotiationStatus !== undefined &&
      paramObj.budgetNegotiationStatus
        ? paramObj.budgetNegotiationStatus
        : "";
    let dueDateNegotiationStatus =
      paramObj.dueDateNegotiationStatus !== undefined &&
      paramObj.dueDateNegotiationStatus
        ? paramObj.dueDateNegotiationStatus
        : "";
    let conclusionMessage =
      paramObj.conclusionMessage !== undefined && paramObj.conclusionMessage
        ? paramObj.conclusionMessage
        : "";

    let previousNegotiatedBudget =
      paramObj.previousNegotiatedBudget !== undefined &&
      paramObj.previousNegotiatedBudget !== null
        ? paramObj.previousNegotiatedBudget
        : null;
    let previousNegotiatedBudgetCurrency =
      paramObj.previousNegotiatedBudgetCurrency !== undefined &&
      paramObj.previousNegotiatedBudgetCurrency !== null
        ? paramObj.previousNegotiatedBudgetCurrency
        : null;
    let previousNegotiatedDueDate =
      paramObj.previousNegotiatedDueDate !== undefined &&
      paramObj.previousNegotiatedDueDate !== null
        ? paramObj.previousNegotiatedDueDate
        : null;

    // let budgetNegotiationStatus =  (paramObj.budgetNegotiationStatus!==undefined && paramObj.budgetNegotiationStatus!==null) ? paramObj.budgetNegotiationStatus:null;
    // let dueDateNegotiationStatus =  (paramObj.dueDateNegotiationStatus!==undefined && paramObj.dueDateNegotiationStatus!==null) ? paramObj.dueDateNegotiationStatus:null;

    let agreedBudget =
      paramObj.agreedBudget !== undefined && paramObj.agreedBudget !== null
        ? paramObj.agreedBudget
        : null;
    let agreedBudgetCurrency =
      paramObj.agreedBudgetCurrency !== undefined &&
      paramObj.agreedBudgetCurrency !== null
        ? paramObj.agreedBudgetCurrency
        : null;
    let agreedDueDate =
      paramObj.agreedDueDate !== undefined && paramObj.agreedDueDate !== null
        ? paramObj.agreedDueDate
        : null;

    //let notiType =   (paramObj.notiType!==undefined && paramObj.notiType) ? paramObj.notiType:"";
    let calledFrom =
      paramObj.calledFrom !== undefined && paramObj.calledFrom
        ? paramObj.calledFrom
        : "";

    let prevData = [...this.state.listData];

    let fndObj = findElement(prevData, "id", chat.id);
    if (fndObj === null) {
      prevData.push(chat);
      let updatedRecNow = prevData;

      let scrollToNewNotiMsgFlag = true;
      let scobj = this.scrollerRef.current;
      let scrollTop = 0;
      let scrollHeight = 0;
      let clientHeight = 0;

      if (scobj !== null) {
        // Newest
        // scrollTop = scobj.getValues().scrollTop;
        // scrollHeight = scobj.getScrollHeight();
        // clientHeight = scobj.getClientHeight();

        // Newest
        scrollTop = scobj.scrollTop;
        scrollHeight = scobj.scrollHeight;
        clientHeight = scobj.clientHeight;
      }

      if (calledFrom == "REAL_TIME_NOTIFICATION_TRACK") {
        // if user is at the bottom so no need to show new msg arrived
        //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop, "(scrollHeight-clientHeight)==>",(scrollHeight-clientHeight));

        if (scrollHeight - clientHeight == scrollTop) {
          scrollToNewNotiMsgFlag = false;
        }
      } else if (calledFrom == "NEGOTIATION_POSTED") {
        scrollToNewNotiMsgFlag = false;

        // Newest
        this.scrollToBottom();
      }

      //console.log(" hello pushChatToList  prevData=>>",prevData, " updatedRecNow==>>>",updatedRecNow);

      this.isComponentLoaded &&
        this.setState(
          {
            listData: updatedRecNow,
            scrollToNewNotiMsgFlag: scrollToNewNotiMsgFlag,
            negoBudgetClickFlag: false,
            negoDateClickFlag: false,
            lpUserLatestChatRecord: null,
            budgetInput: "",
            dueDateInput: null,
            previousNegotiatedBudget: previousNegotiatedBudget,
            previousNegotiatedBudgetCurrency: previousNegotiatedBudgetCurrency,
            previousNegotiatedDueDate: previousNegotiatedDueDate,
            budgetNegotiationStatus: budgetNegotiationStatus,
            dueDateNegotiationStatus: dueDateNegotiationStatus,
            agreedBudget: agreedBudget,
            agreedBudgetCurrency: agreedBudgetCurrency,
            agreedDueDate: agreedDueDate,
            conclusionMessage: conclusionMessage,
          },
          () => {
            //console.log("****** real time updated state listData=>>>>",this.state.listData," chat=>>",chat);
          }
        );
    }
  };

  async getTaskDetailData(param) {
    let respData = null;
    let lservdrf = new TaskDetailsService();
    await lservdrf
      .getTaskDetail(param)
      .then((data) => {
        respData = data.data.entity;
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(" errMsg on task fetch info==>", errMsg);
            // close split screen
            //this.closeSplit();
          }
        }
      })
      .finally(() => {});

    return respData;
  }
  customDate = (defaultDate) => {
    return (
      <DatePicker
        selected={defaultDate}
        onChange={(date) => {
          this.setCalDate(date);
        }}
        calendarClassName="customtaskaddclassstep1calcls"
        customInput={<ExampleCustomInput />}
        minDate={new Date()}
        dateFormat={getDateFormatForCal({ countryCode: "IN" })}
      />
    );
  };

  setCalDate = (dt) => {
    ////console.log.log("selected date=>>",dt);
    let dtTs = getTimestampInMillisecondsFromDateObj(dt);
    //let dttOfset = getOffsetOfLocalDateTime(dt);
    this.isComponentLoaded && this.setState({ dueDateInput: dtTs });
  };

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }
  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }
  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case

    if (
      text !== undefined &&
      text !== null &&
      highlight !== undefined &&
      highlight !== null &&
      highlight.length > 0
    ) {
      let textData = text;
      let textDataHash = text;
      let partsHighlighted = [];
      let partsFoundObj = [];

      highlight.map((part) => {
        let regex = new RegExp(`(${part})`, "gi");

        let result = [];
        // since  highlight key can appear multiple place in the string if atleast one match found then enter in this block
        while ((result = regex.exec(textData))) {
          // construct the bold wrapped html object
          let keyObject = (
            <b onClick={() => {}} key={"k" + result.index}>
              {part}
            </b>
          );

          // create hash string when match is found and update it
          let splitItAr = textDataHash.split(part);
          textDataHash = splitItAr.join("#####");
          let objPush = {
            keyName: part,
            keyObject: keyObject,
            positionNumber: result.index,
          };
          // check whether positionNumber is already used or not if used then check whether it the data is part of another big part data then remove that position no. related element from the array and push the parent string data and if positionNumber has not been used then push it

          let fndObj = findElement(
            partsFoundObj,
            "positionNumber",
            result.index
          );
          if (fndObj === null) {
            partsFoundObj.push(objPush);
          } else {
            let foundKeyNameData = fndObj.objData.keyName;
            // foundKeyNameData is already present in the array and  it is substring  of part let s say 12 is present in the array and is found that it is part of 12.5

            if (part.indexOf(foundKeyNameData) > -1) {
              partsFoundObj.splice(fndObj.indKey, 1);
              partsFoundObj.push(objPush);
            }
          }
        }
      });

      // sort the part found array object on positionNumber so that it can be used in the text as it appears
      //console.log(" 1 textData===>",textData, " partsFoundObj==>>",partsFoundObj)
      partsFoundObj = partsFoundObj.sort(
        sortArrayOfJsonByKey("positionNumber", "ASC")
      );
      //console.log("11 textData===>",textData, " partsFoundObj==>>",partsFoundObj)
      let expldStringHash = [];
      let lenpartsFoundObj = partsFoundObj.length;
      expldStringHash = textDataHash.split("#####");
      //console.log("2 textDataHash===>",textDataHash);
      let trackObjInjCn = 0;
      // now inject those object into a fresh array with other text
      if (expldStringHash && expldStringHash.length > 0) {
        expldStringHash.map((e, i) => {
          // push 1st exploded string into the array to be displayed
          partsHighlighted.push(expldStringHash[i]);
          if (trackObjInjCn < lenpartsFoundObj) {
            // push dynamic bold object created into the array to be displayed
            partsHighlighted.push(partsFoundObj[trackObjInjCn].keyObject);
            trackObjInjCn = trackObjInjCn + 1;
          }
        });
      }

      return <>{partsHighlighted}</>;
    } else if (
      text !== undefined &&
      text !== null &&
      highlight !== undefined &&
      highlight !== null &&
      highlight.length == 0
    ) {
      return text;
    } else {
      return "";
    }
  };

  callServiceToFetchNegoChatRespList = (params, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = params.pageNum;
    paramObjData.taskId = params.taskId;
    paramObjData.ccUserId = params.ccUserId;
    paramObjData.chatType = params.negoOn == 0 ? "BUDGET" : "DUE_DATE";
    paramObjData.lastCommentId = this.state.lastCommentId;

    let stcservice = new ChatListOfNegotiationService();
    stcservice
      .getChatListOfNegotiation({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            //console.log(respData)
            if (respData.chats !== undefined) {
              /*
                        if(respData.chats!==null && respData.chats.length > 0){
                            // set list data to state
                            let prevRecords = [...this.state.listData];
                            let currRecords = (respData.chats).reverse();

                            // let lll = []; let ddd =  respData.chats[0];
                            // let currRecords = [ddd];
                            // console.log(" currRecords==>",currRecords );

                           
                            let concatenateRecords = currRecords;//prevRecords.concat(currRecords);

                            let updtStateKeys1 = {
                            "listData":concatenateRecords,
                            //"totalNumberOfPages":respData.totalNumberOfPages,
                            "proposedBudget":(respData.proposedBudget!==undefined?respData.proposedBudget:""),
                            "proposedBudgetCurrency":(respData.budgetCurrency!==undefined?respData.budgetCurrency:""),
                            "proposedDueDate":(respData.proposedDueDate!==undefined?respData.proposedDueDate:""),
                            "budgetNegotiationStatus":(respData.budgetNegotiationStatus!==undefined?respData.budgetNegotiationStatus:""),
                            "dueDateNegotiationStatus": (respData.dueDateNegotiationStatus!==undefined?respData.dueDateNegotiationStatus:""),
                            "conclusionMessage": (respData.conclusionMessage!==undefined?respData.conclusionMessage:""),
                            "isTermsAcceptedByLP":(respData.isTermsAcceptedByLP!==undefined?respData.isTermsAcceptedByLP:false),
                            "agreedBudgetCurrency":(respData.agreedBudgetCurrency!==undefined?respData.agreedBudgetCurrency:""),
                            "agreedBudget":(respData.agreedBudget!==undefined?respData.agreedBudget:""),
                            "agreedDueDate":(respData.agreedDueDate!==undefined?respData.agreedDueDate:null),
                            "previousNegotiatedBudgetCurrency":(respData.previousNegotiatedBudgetCurrency!==undefined?respData.previousNegotiatedBudgetCurrency:null),
                            "previousNegotiatedBudget":(respData.previousNegotiatedBudget!==undefined?respData.previousNegotiatedBudget:null),
                            "previousNegotiatedDueDate":(respData.previousNegotiatedDueDate!==undefined?respData.previousNegotiatedDueDate:null),

                            };

                            this.isComponentLoaded && this.setState(updtStateKeys1,()=>{
                              //console.log(" 1=>>>>>>",updtStateKeys1,this.state.conclusionMessage,"conclusionMessage");
                            });
                        }else{

                        let updtStateKeys2 = {
                            "listData":[],
                            //"totalNumberOfPages":respData.totalNumberOfPages,
                            "proposedBudget":(respData.proposedBudget!==undefined?respData.proposedBudget:""),
                            "proposedBudgetCurrency":(respData.budgetCurrency!==undefined?respData.budgetCurrency:""),
                            "proposedDueDate":(respData.proposedDueDate!==undefined?respData.proposedDueDate:""),
                            "budgetNegotiationStatus":(respData.budgetNegotiationStatus!==undefined?respData.budgetNegotiationStatus:""),
                            "dueDateNegotiationStatus": (respData.dueDateNegotiationStatus!==undefined?respData.dueDateNegotiationStatus:""),
                            "conclusionMessage": (respData.conclusionMessage!==undefined?respData.conclusionMessage:""),
                            "isTermsAcceptedByLP":(respData.isTermsAcceptedByLP!==undefined?respData.isTermsAcceptedByLP:false),
                            "agreedBudgetCurrency":(respData.agreedBudgetCurrency!==undefined?respData.agreedBudgetCurrency:""),
                            "agreedBudget":(respData.agreedBudget!==undefined?respData.agreedBudget:""),
                            "agreedDueDate":(respData.agreedDueDate!==undefined?respData.agreedDueDate:null),
                            "previousNegotiatedBudgetCurrency":(respData.previousNegotiatedBudgetCurrency!==undefined?respData.previousNegotiatedBudgetCurrency:null),
                            "previousNegotiatedBudget":(respData.previousNegotiatedBudget!==undefined?respData.previousNegotiatedBudget:null),
                            "previousNegotiatedDueDate":(respData.previousNegotiatedDueDate!==undefined?respData.previousNegotiatedDueDate:null),
                          }
                          console.log(" this.state.listData===>",this.state.listData,this.state.listData.length);
                            if(this.state.listData.length==0){
                            this.isComponentLoaded && this.setState(updtStateKeys2,()=>{
                              //console.log(" 11 =>>>>>>",updtStateKeys2,this.state.conclusionMessage,"conclusionMessage");
                            });
                            }
                        }
                        */

              if (respData.chats.length > 0) {
                let prevRecords = [...this.state.listData];
                let currRecords = respData.chats.reverse();
                let concatenateRecords = [];
                let lastCommentId = 0;
                if (trackcalledfrom == "NEGOCHATSCROLLUP") {
                  concatenateRecords = currRecords.concat(prevRecords);
                } else {
                  concatenateRecords = prevRecords.concat(currRecords);

                  // Newest
                  this.scrollToBottom();
                }

                lastCommentId = concatenateRecords[0]
                  ? concatenateRecords[0].id
                  : 0;

                let updtStateKeys1 = {
                  listData: concatenateRecords,
                  totalNumberOfPages: respData.totalNumberOfPages,
                  proposedBudget:
                    respData.proposedBudget !== undefined
                      ? respData.proposedBudget
                      : "",
                  proposedBudgetCurrency:
                    respData.budgetCurrency !== undefined
                      ? respData.budgetCurrency
                      : "",
                  proposedDueDate:
                    respData.proposedDueDate !== undefined
                      ? respData.proposedDueDate
                      : "",
                  budgetNegotiationStatus:
                    respData.budgetNegotiationStatus !== undefined
                      ? respData.budgetNegotiationStatus
                      : "",
                  dueDateNegotiationStatus:
                    respData.dueDateNegotiationStatus !== undefined
                      ? respData.dueDateNegotiationStatus
                      : "",
                  conclusionMessage:
                    respData.conclusionMessage !== undefined
                      ? respData.conclusionMessage
                      : "",
                  isTermsAcceptedByLP:
                    respData.isTermsAcceptedByLP !== undefined
                      ? respData.isTermsAcceptedByLP
                      : false,
                  isTaskOfferReceived:
                    respData.isTaskOfferReceived !== undefined
                      ? respData.isTaskOfferReceived
                      : false,
                  assignedTo:
                    respData.assignedTo !== undefined
                      ? respData.assignedTo
                      : null,
                  lastCommentId: lastCommentId,
                  previousNegotiatedBudgetCurrency:
                    respData.previousNegotiatedBudgetCurrency !== undefined
                      ? respData.previousNegotiatedBudgetCurrency
                      : null,
                  previousNegotiatedBudget:
                    respData.previousNegotiatedBudget !== undefined
                      ? respData.previousNegotiatedBudget
                      : null,
                  previousNegotiatedDueDate:
                    respData.previousNegotiatedDueDate !== undefined
                      ? respData.previousNegotiatedDueDate
                      : null,
                  agreedBudgetCurrency:
                    respData.agreedBudgetCurrency !== undefined
                      ? respData.agreedBudgetCurrency
                      : null,
                  agreedBudget:
                    respData.agreedBudget !== undefined
                      ? respData.agreedBudget
                      : null,
                  agreedDueDate:
                    respData.agreedDueDate !== undefined
                      ? respData.agreedDueDate
                      : null,
                  taskType:
                    respData.taskType !== undefined ? respData.taskType : "",
                  taskOfferedAt:
                    respData.taskOfferedAt !== undefined
                      ? respData.taskOfferedAt
                      : "",
                  taskOfferedAtMillis:
                    respData.taskOfferedAtMillis !== undefined
                      ? respData.taskOfferedAtMillis
                      : null,
                };

                this.isComponentLoaded &&
                  this.setState(updtStateKeys1, () => {
                    // Newest
                    // make scroller scroll down for this scenario
                    // let scobj = this.scrollerRef.current;
                    if (trackcalledfrom == "NEGOCHATSCROLLUP") {
                      //let scrollToTop = (scobj.getValues().scrollTop) +150;
                      //scobj.scrollTop(scrollToTop);
                    } else {
                      //scobj.scrollToBottom();
                      //newly added
                      this.scrollToBottom();
                    }
                  });
              }
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
        this.enableTab({});
      });
  };

  disableTab = (paramObj) => {
    this.isComponentLoaded && this.setState({ tabDisFlag: true });
  };

  enableTab = (paramObj) => {
    this.isComponentLoaded && this.setState({ tabDisFlag: false });
  };

  trackScroll = () => {
    let scobj = this.scrollerRef.current;
    let scrollTop = scobj.getValues().scrollTop;
    let scrollHeight = scobj.getScrollHeight();
    let clientHeight = scobj.getClientHeight();

    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    // if scrolled up to extreme point
    if (scrollTop == 0 && prevPageNum < totalNumberOfPages) {
      this.showLoader();
      this.disableTab();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = {
          pageNum: pageNumData,
          taskId: this.state.taskId,
          lpUserId: this.state.lpUserId,
          ccUserId: this.state.ccUserId,
          negoOn: this.state.negoOn,
        };

        this.isComponentLoaded &&
          this.setState(paramObj, () => {
            this.callServiceToFetchNegoChatRespList(
              paramObj,
              "NEGOCHATSCROLLUP"
            );
          });
      }, 900);
    }
  };

  // Newest
  fetchMoreData = () => {
    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    // if scrolled up to extreme point
    if (prevPageNum < totalNumberOfPages) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = {
          pageNum: pageNumData,
          taskId: this.state.taskId,
          lpUserId: this.state.lpUserId,
          ccUserId: this.state.ccUserId,
          negoOn: this.state.negoOn,
        };

        this.isComponentLoaded &&
          this.setState(paramObj, () => {
            this.callServiceToFetchNegoChatRespList(
              paramObj,
              "NEGOCHATSCROLLUP"
            );
          });
      }, 900);
    }
  };

  chooseNegoTab = (tabOptn, otherParam) => {
    if (tabOptn == "BUDGET") {
      this.triggerBudgetTab({ triggeredFrom: "chooseNegoTab" });
    } else if (tabOptn == "DATE") {
      this.triggerDueDateTab({ triggeredFrom: "chooseNegoTab" });
    }
  };

  triggerBudgetTab = (paramObj) => {
    let stateData = { ...this.state };
    stateData.negoOn = 0;
    stateData.negoOnBudgetSend = false;
    stateData.budgetDueDateCommonMsg = "";
    stateData.budgetDueDateCommonMsgErr = "";
    stateData.budgetErMsg = "";

    this.isComponentLoaded &&
      this.setState(stateData, () => {
        // reload chat list
        this.reloadDataOfChatList();
      });
  };

  triggerDueDateTab = (paramObj) => {
    let stateData = { ...this.state };
    stateData.negoOn = 1;
    stateData.negoOnDateSend = false;
    stateData.budgetDueDateCommonMsg = "";
    stateData.budgetDueDateCommonMsgErr = "";
    stateData.dueDateErMsg = "";

    this.isComponentLoaded &&
      this.setState(stateData, () => {
        // reload chat list
        this.reloadDataOfChatList();
      });
  };

  reloadDataOfChatList = () => {
    // call list api

    let paramObj = {
      listData: [],
      pageNum: 1,
      pageNumArTrack: [],
      totalNumberOfPages: 1,
      taskId: this.state.taskId,
      ccUserId: this.state.ccUserId,
      negoOn: this.state.negoOn,
      negoBudgetClickFlag: false,
      negoDateClickFlag: false,
      userLatestChatRecord: null,
      budgetInput: "",
      dueDateInput: null,
      notificationIdAr: [],
      lastCommentId: 0,
      scrollToNewNotiMsgFlag: false,
      tabDisFlag: true,
      isTaskOfferReceived: false,
      assignedTo: this.state.assignedTo,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.showLoader();

        setTimeout(() => {
          this.callServiceToFetchNegoChatRespList(
            paramObj,
            "*****called from reloadChatList**** "
          );
        }, 900);
      });
  };

  nowPerformAction = (keyOptn, otherParam) => {
    //console.log(keyOptn,"keyOptn",otherParam,"otherParam")
    let keyAction = otherParam.keyAction ? otherParam.keyAction : "";

    if (keyOptn == "BUDGET") {
      switch (keyAction) {
        case "NEGOTIATE":
          let userLatestChatRecord1 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;

          this.isComponentLoaded &&
            this.setState(
              {
                negoBudgetClickFlag: true,
                userLatestChatRecord: userLatestChatRecord1,
              },
              () => {
                // console.log("now from nowPerformAction for BUDGET NEGOTIATE state=>>",this.state);
              }
            );
          break;
        case "AGREE":
          let userLatestChatRecord2 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;

          this.isComponentLoaded &&
            this.setState(
              {
                userLatestChatRecord: userLatestChatRecord2,
              },
              () => {
                // console.log("now from nowPerformAction for BUDGET AGREE state=>>",this.state);

                this.chkBeforeSend({ for: "BUDGET", action: "AGREE" });
              }
            );
          break;
        case "REJECT":
          let userLatestChatRecord3 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;

          this.isComponentLoaded &&
            this.setState(
              {
                userLatestChatRecord: userLatestChatRecord3,
              },
              () => {
                // console.log("now from nowPerformAction for BUDGET REJECT state=>>",this.state);
                this.chkBeforeSend({ for: "BUDGET", action: "REJECT" });
              }
            );
          break;
      }
    } else if (keyOptn == "DUE_DATE") {
      switch (keyAction) {
        case "NEGOTIATE":
          let userLatestChatRecord11 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;
          this.isComponentLoaded &&
            this.setState(
              {
                negoDateClickFlag: true,
                userLatestChatRecord: userLatestChatRecord11,
              },
              () => {
                //console.log("now from nowPerformAction for DUE_DATE NEGOTIATE state=>>",this.state);
              }
            );
          break;
        case "AGREE":
          let userLatestChatRecord22 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;
          this.isComponentLoaded &&
            this.setState(
              {
                userLatestChatRecord: userLatestChatRecord22,
              },
              () => {
                //console.log("now from nowPerformAction for DUE_DATE AGREE state=>>",this.state);
                this.chkBeforeSend({ for: "DUE_DATE", action: "AGREE" });
              }
            );
          break;
        case "REJECT":
          let userLatestChatRecord33 = otherParam.userLatestChatRecord
            ? otherParam.userLatestChatRecord
            : null;
          this.isComponentLoaded &&
            this.setState(
              {
                userLatestChatRecord: userLatestChatRecord33,
              },
              () => {
                //console.log("now from nowPerformAction for DUE_DATE REJECT state=>>",this.state);
                this.chkBeforeSend({ for: "DUE_DATE", action: "REJECT" });
              }
            );
          break;
      }
    } else if (keyOptn == "BUDGETINPUTCHANGE") {
      let budgetInputData = otherParam.budgetInput;
      if (budgetInputData) {
        if (validatePositiveInputNumber({ data: otherParam.budgetInput })) {
          budgetInputData = otherParam.budgetInput;
        } else {
          budgetInputData = this.state.budgetInput;
        }
      }
      this.isComponentLoaded && this.setState({ budgetInput: budgetInputData });
    }
  };

  trackChangeOfNewNegoMessageBox = (paramObj) => {
    let keyName = paramObj.keyName ? paramObj.keyName : "";
    let keyValue = paramObj.keyValue ? paramObj.keyValue : "";

    if (keyName == "budgetDueDateCommonMsg") {
      this.isComponentLoaded &&
        this.setState({ budgetDueDateCommonMsg: keyValue });
    }
  };

  custValidate = (paramObj) => {
    let forData = paramObj.for ? paramObj.for : ""; // BUDGET DUE_DATE
    let budgetDueDateCommonMsgErr = "";
    let budgetErMsg = "";
    let dueDateErMsg = "";
    let errTrackAr = [];

    if (this.state.budgetDueDateCommonMsg) {
      //   if(emailIdPresenceCheck(this.state.budgetDueDateCommonMsg)){
      //     budgetDueDateCommonMsgErr = "Message cannot contain emailid.";
      //     errTrackAr.push(budgetDueDateCommonMsgErr);

      //  }
      //  else if(phonePresenceCheck(this.state.budgetDueDateCommonMsg)){
      //    budgetDueDateCommonMsgErr = "Message cannot contain phone number.";
      //    errTrackAr.push(budgetDueDateCommonMsgErr);

      //  }
      if (
        this.state.budgetDueDateCommonMsg &&
        this.state.budgetDueDateCommonMsg.length > 120
      ) {
        budgetDueDateCommonMsgErr =
          "Message cannot be more than 120 characters.";
        errTrackAr.push(budgetDueDateCommonMsgErr);
      }
    }

    if (forData == "BUDGET") {
      let budget = paramObj.budget;
      let tt = validatePositiveInputNumber({ data: budget });

      if (validateBlankSpace(budget)) {
        budgetErMsg = "Budget is required.";
        errTrackAr.push(budgetErMsg);
      } else if (!validatePositiveInputNumber({ data: budget })) {
        budgetErMsg = "Budget should be postive number.";
        errTrackAr.push(budgetErMsg);
      }
    } else if (forData == "DUE_DATE") {
      let dueDate = paramObj.dueDate;
      if (!dueDate || validateBlankSpace(dueDate) == true) {
        dueDateErMsg = "Due date is required.";
        errTrackAr.push(dueDateErMsg);
      }
    }

    this.setState({
      budgetDueDateCommonMsgErr: budgetDueDateCommonMsgErr,
      budgetErMsg: budgetErMsg,
      dueDateErMsg: dueDateErMsg,
    });
    if (errTrackAr.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  chkBeforeSend = (otherParam) => {
    let ccLastReplyStatus =
      this.state.userLatestChatRecord && this.state.userLatestChatRecord.status
        ? this.state.userLatestChatRecord.status
        : "";

    if (otherParam.for == "BUDGET") {
      let previousChatId =
        this.state.userLatestChatRecord && this.state.userLatestChatRecord.id
          ? this.state.userLatestChatRecord.id
          : 0;

      let budgetCurrency =
        this.state.userLatestChatRecord &&
        this.state.userLatestChatRecord.budgetCurrency
          ? this.state.userLatestChatRecord.budgetCurrency
          : "";
      let budgetInput = "";

      if (otherParam.action == "NEGOTIATE") {
        budgetInput = this.state.budgetInput;

        // check for validation
        let chkvalid = this.custValidate({
          for: "BUDGET",
          budget: budgetInput,
        });
        if (!chkvalid) {
          return false;
        }
      } else {
        if (ccLastReplyStatus == "REJECT") {
          budgetInput = this.state.previousNegotiatedBudget;
          budgetCurrency = this.state.previousNegotiatedBudgetCurrency;
        } else {
          budgetInput =
            this.state.userLatestChatRecord &&
            this.state.userLatestChatRecord.budget
              ? this.state.userLatestChatRecord.budget
              : "";
        }
      }

      //let taskOrSubTask = this.state.taskOrSubTask;
      let taskId = this.state.taskId;
      let ccUserId = this.state.ccUserId;
      let chatType = this.state.negoOn == 0 ? "BUDGET" : "DUE_DATE";
      let budgetUpdationDescription = this.state.budgetDueDateCommonMsg
        ? this.state.budgetDueDateCommonMsg
        : "";

      let chatReplyData = {
        status: otherParam.action,
        previousChatId: previousChatId,
        budget: budgetInput,
        budgetCurrency: budgetCurrency,
        budgetUpdationDescription: budgetUpdationDescription,
      };

      let paramToPost = {
        data: {
          chatReplyData: chatReplyData,
          //"taskOrSubTask":taskOrSubTask,
          taskId: taskId,
          ccUserId: ccUserId,
          chatType: chatType,
        },
      };
      // console.log("**** from chkBeforeSendNego this.state now =>",this.state,"paramToPost==>",paramToPost);

      this.isComponentLoaded &&
        this.setState(
          {
            negoOnBudgetSend: true,
            budgetInput: "",
            dueDateInput: null,
            budgetErMsg: "",
            dueDateErMsg: "",
            budgetDueDateCommonMsg: "",
            budgetDueDateCommonMsgErr: "",
          },
          () => {
            setTimeout(() => {
              // send reply to lp user
              this.sendReplyToChat(paramToPost, otherParam);
            }, 1900);
          }
        );
    } else if (otherParam.for == "DUE_DATE") {
      let previousChatId =
        this.state.userLatestChatRecord && this.state.userLatestChatRecord.id
          ? this.state.userLatestChatRecord.id
          : 0;

      let dueDateInput = "";
      if (otherParam.action == "NEGOTIATE") {
        dueDateInput = this.state.dueDateInput;
        // check for validation
        let chkvalid = this.custValidate({
          for: "DUE_DATE",
          dueDate: dueDateInput,
        });
        if (!chkvalid) {
          return false;
        }
      } else {
        if (ccLastReplyStatus == "REJECT") {
          dueDateInput = this.state.previousNegotiatedDueDate;
        } else {
          dueDateInput =
            this.state.userLatestChatRecord &&
            this.state.userLatestChatRecord.dueDateInMillis
              ? this.state.userLatestChatRecord.dueDateInMillis
              : "";
        }
      }

      //let taskOrSubTask = this.state.taskOrSubTask;
      let taskId = this.state.taskId;
      let ccUserId = this.state.ccUserId;
      let chatType = this.state.negoOn == 0 ? "BUDGET" : "DUE_DATE";
      let budgetUpdationDescription = this.state.budgetDueDateCommonMsg
        ? this.state.budgetDueDateCommonMsg
        : "";

      let chatReplyData = {
        status: otherParam.action,
        previousChatId: previousChatId,
        dueDate: dueDateInput,
        dueDateUpdationDescription: budgetUpdationDescription,
      };

      let paramToPost = {
        data: {
          chatReplyData: chatReplyData,
          //"taskOrSubTask":taskOrSubTask,
          taskId: taskId,
          ccUserId: ccUserId,
          chatType: chatType,
        },
      };
      // console.log("**** from chkBeforeSendNego this.state now =>",this.state,"paramToPost==>",paramToPost);

      this.isComponentLoaded &&
        this.setState(
          {
            negoOnDateSend: true,
            budgetInput: "",
            dueDateInput: null,
            budgetErMsg: "",
            dueDateErMsg: "",
            budgetDueDateCommonMsg: "",
            budgetDueDateCommonMsgErr: "",
          },
          () => {
            setTimeout(() => {
              // send reply to lp user
              this.sendReplyToChat(paramToPost, otherParam);
            }, 1900);
          }
        );
    }
  };

  sendReplyToChat = (paramToPost, otherData) => {
    // if(otherData.for=="BUDGET"){
    //   this.isComponentLoaded && this.setState({"negoOnBudgetSend":true});
    // }else{
    //   this.isComponentLoaded && this.setState({"negoOnDateSend":true});
    // }

    let stcservice = new ChatReplyService();
    stcservice
      .makeChatReply(paramToPost)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            let chat =
              respData.chat !== undefined && respData.chat
                ? respData.chat
                : null;
            let previousNegotiatedBudget =
              respData.previousNegotiatedBudget !== undefined &&
              respData.previousNegotiatedBudget !== null
                ? respData.previousNegotiatedBudget
                : null;
            let previousNegotiatedBudgetCurrency =
              respData.previousNegotiatedBudgetCurrency !== undefined &&
              respData.previousNegotiatedBudgetCurrency !== null
                ? respData.previousNegotiatedBudgetCurrency
                : null;
            let previousNegotiatedDueDate =
              respData.previousNegotiatedDueDate !== undefined &&
              respData.previousNegotiatedDueDate !== null
                ? respData.previousNegotiatedDueDate
                : null;
            // let budgetNegotiationStatus =  (respData.budgetNegotiationStatus!==undefined && respData.budgetNegotiationStatus!==null) ? respData.budgetNegotiationStatus:null;
            // let dueDateNegotiationStatus =  (respData.dueDateNegotiationStatus!==undefined && respData.dueDateNegotiationStatus!==null) ? respData.dueDateNegotiationStatus:null;

            let agreedBudget =
              respData.agreedBudget !== undefined &&
              respData.agreedBudget !== null
                ? respData.agreedBudget
                : null;
            let agreedBudgetCurrency =
              respData.agreedBudgetCurrency !== undefined &&
              respData.agreedBudgetCurrency !== null
                ? respData.agreedBudgetCurrency
                : null;
            let agreedDueDate =
              respData.agreedDueDate !== undefined &&
              respData.agreedDueDate !== null
                ? respData.agreedDueDate
                : null;

            let budgetNegotiationStatus =
              respData.budgetNegotiationStatus !== undefined &&
              respData.budgetNegotiationStatus
                ? respData.budgetNegotiationStatus
                : "";
            let dueDateNegotiationStatus =
              respData.dueDateNegotiationStatus !== undefined &&
              respData.dueDateNegotiationStatus
                ? respData.dueDateNegotiationStatus
                : "";
            let conclusionMessage =
              respData.conclusionMessage !== undefined &&
              respData.conclusionMessage
                ? respData.conclusionMessage
                : "";

            // reload chat list
            //this.reloadDataOfChatList();
            this.pushChatToList({
              calledFrom: "NEGOTIATION_POSTED",
              chat: chat,
              previousNegotiatedBudget: previousNegotiatedBudget,
              previousNegotiatedBudgetCurrency:
                previousNegotiatedBudgetCurrency,
              previousNegotiatedDueDate: previousNegotiatedDueDate,
              budgetNegotiationStatus: budgetNegotiationStatus,
              dueDateNegotiationStatus: dueDateNegotiationStatus,
              agreedBudget: agreedBudget,
              agreedBudgetCurrency: agreedBudgetCurrency,
              agreedDueDate: agreedDueDate,
              conclusionMessage: conclusionMessage,
            });
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log(errMsg);

            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass for budgetduedatenegomsgclass",
              showCloseButton: false,

              dismiss: {
                duration: 2500,
              },
            });
          }
        }
      })
      .finally(() => {
        if (otherData.for == "BUDGET") {
          this.isComponentLoaded && this.setState({ negoOnBudgetSend: false });
        } else {
          this.isComponentLoaded && this.setState({ negoOnDateSend: false });
        }
      });
  };

  callBackBtn = () => {
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    if (setShare && sharedData) {
      sharedData.leftTabToActive = "TASKDETAILS";
      sharedData.purpose = "VIEW";
      setShare(sharedData);
    }
  };

  loadChatListOfDateNego = () => {
    return (
      <>
        {/* Newest */}
        <div
          ref={this.scrollerRef}
          className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_budgetDueDate"
        >
          {/* Newest */}
          {/* <Scrollbars
                ref={this.scrollerRef}
                style={{ height: 675 }}
                autoHeightMin={675}
                renderView={(props) => (
                  <div {...props} className="view duedatenegochatlistclass" />
                )}
                onScrollStop={this.trackScroll}
          > */}
          <div className="s_midbox_section s_midbox_section_chat">
            {/* ====   sprint - 4 chat structure start ====  */}
            {/* chat mid body start  */}
            <div className="chatTab_sec">
              <div className="chatBody_sec">
                <div className="chatBody_ulBg">
                  <div className=" text-center ">
                    {this.state.loaderFlag ? this.loadLoader() : null}
                  </div>
                  <ul>
                    {this.state.listData.length > 0 &&
                      Object.keys(this.state.listData).map((e, i) => (
                        <li key={i}>
                          <div
                            className={`chatBody_box ${
                              this.state.listData[i].fromUserId ==
                              this.state.lpUserId
                                ? "chatBody_box_gray"
                                : "chatBody_box_green"
                            }`}
                          >
                            <div className="chatbody_text">
                              <div className="chatbody_text_newNego">
                                <p>
                                  {this.getHighlightedText(
                                    this.state.listData[i].message,
                                    this.state.listData[i].highlightContents
                                  )}
                                </p>
                              </div>
                              {this.state.listData[i]
                                .dueDateUpdationDescription && (
                                <div className="chatbody_text_newNego_descp_p">
                                  <p>
                                    {this.state.listData[i]
                                      .dueDateUpdationDescription
                                      ? this.state.listData[i]
                                          .dueDateUpdationDescription
                                      : ""}
                                  </p>
                                </div>
                              )}
                              <div className="monichatdatetime">
                                {this.state.listData[i].createdAt !==
                                  undefined &&
                                this.state.listData[i].createdAt !== null
                                  ? CustDate.custFormatDate(
                                      new Date(
                                        this.state.listData[i].createdAt
                                      ),
                                      6
                                    )
                                  : ""}
                              </div>
                            </div>
                            <div className="chatBody_btn_sec">
                              {(this.state.listData[i].status == "NEGOTIATE" ||
                                this.state.listData[i].status == "REJECT") &&
                                this.state.listData[i].fromUserId !=
                                  this.state.lpUserId &&
                                this.state.listData.length - 1 == i && (
                                  <>
                                    <button
                                      disabled={this.state.negoOnDateSend}
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "DUE_DATE";
                                        let otherParam = {
                                          keyAction: "AGREE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_agree"
                                    >
                                      Agree
                                    </button>
                                    <button
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "DUE_DATE";
                                        let otherParam = {
                                          keyAction: "NEGOTIATE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_nego"
                                    >
                                      Negotiate
                                    </button>
                                  </>
                                )}
                              {this.state.listData[i].fromUserId ==
                                this.state.lpUserId &&
                                this.state.listData.length - 1 == i &&
                                this.state.listData.length - 1 == i &&
                                this.state.dueDateNegotiationStatus ==
                                  "NEGOTIATE" && (
                                  <>
                                    <button
                                      disabled={this.state.negoOnDateSend}
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "DUE_DATE";
                                        let otherParam = {
                                          keyAction: "NEGOTIATE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_reNegotiate"
                                    >
                                      Update Due Date
                                    </button>
                                  </>
                                )}
                              {/* <button disabled={this.state.negoOnDateSend} onClick={(evn)=>{
                              evn.preventDefault();
                              let keyOptn = "DUE_DATE";
                              let otherParam = {"keyAction":"REJECT","userLatestChatRecord":this.state.listData[i]};
                              this.nowPerformAction(keyOptn,otherParam);
                            }} className="btn_appearance_none chatBtn_reject">
                              Reject
                            </button> */}
                              <div className="clearboth" />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  {/* <div className="chat_refrash_btn">
                      <button onClick={(evn)=>{
                        evn.preventDefault();
                        
                        this.reloadDataOfChatList();
                        
                      }} className={`btn_appearance_none rf_btn ${this.state.loaderFlag==true?"active":""}` }>
                        <i className="fa fa-refresh" aria-hidden="true" />
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
            {/* chat mid body end  */}
            {/* ====   sprint - 4 chat structure end ====  */}
          </div>
          {/* </Scrollbars> */}
        </div>
        {this.state.conclusionMessage !== undefined &&
          this.state.conclusionMessage !== null &&
          this.state.conclusionMessage !== "" && (
            <div className="chat_bottom_msg_new_bg">
              <div className="chat_bottom_msg_new">
                <p>{this.state.conclusionMessage}</p>
              </div>
            </div>
          )}
        {this.state.scrollToNewNotiMsgFlag && (
          <>
            <div className="chat_bottom_msg_new_bg">
              <div
                onClick={(evn) => {
                  evn.preventDefault();
                  this.scrollDownToNewMessages({});
                }}
                className="chat_bottom_msg_new"
              >
                <p>New message received</p>
              </div>
            </div>
            <audio autoPlay hidden>
              <source src={getBaseUrl() + "notify.mp3"} type="audio/mpeg" />
            </audio>
          </>
        )}
      </>
    );
  };

  loadChatListOfBudgetNego = () => {
    return (
      <>
        {/* Newest */}
        <div
          ref={this.scrollerRef}
          className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_budgetDueDate"
        >
          {/* Newest */}
          {/* <Scrollbars
                ref={this.scrollerRef}
                style={{ height: 675 }}
                autoHeightMin={675}
                renderView={(props) => (
                  <div {...props} className="view budgetnegochatlistclass" />
                )}
                onScrollStop={this.trackScroll}
          > */}
          <div className="s_midbox_section s_midbox_section_chat">
            {/* ====   sprint - 4 chat structure start ====  */}
            {/* chat mid body start  */}
            <div className="chatTab_sec">
              <div className="chatBody_sec">
                <div className="chatBody_ulBg">
                  <div className=" text-center ">
                    {this.state.loaderFlag ? this.loadLoader() : null}
                  </div>
                  <ul>
                    {this.state.listData.length > 0 &&
                      Object.keys(this.state.listData).map((e, i) => (
                        <li key={i}>
                          <div
                            className={`chatBody_box ${
                              this.state.listData[i].fromUserId ==
                              this.state.lpUserId
                                ? "chatBody_box_gray"
                                : "chatBody_box_green"
                            }`}
                          >
                            <div className="chatbody_text">
                              <div className="chatbody_text_newNego">
                                <p>
                                  {this.getHighlightedText(
                                    this.state.listData[i].message,
                                    this.state.listData[i].highlightContents
                                  )}
                                </p>
                              </div>
                              {this.state.listData[i]
                                .budgetUpdationDescription && (
                                <div className="chatbody_text_newNego_descp_p">
                                  <p>
                                    {this.state.listData[i]
                                      .budgetUpdationDescription
                                      ? this.state.listData[i]
                                          .budgetUpdationDescription
                                      : ""}
                                  </p>
                                </div>
                              )}
                              <div className="monichatdatetime">
                                {this.state.listData[i].createdAt !==
                                  undefined &&
                                this.state.listData[i].createdAt !== null
                                  ? CustDate.custFormatDate(
                                      new Date(
                                        this.state.listData[i].createdAt
                                      ),
                                      6
                                    )
                                  : ""}
                              </div>
                            </div>

                            <div className="chatBody_btn_sec">
                              {(this.state.listData[i].status == "NEGOTIATE" ||
                                this.state.listData[i].status == "REJECT") &&
                                this.state.listData[i].fromUserId !=
                                  this.state.lpUserId &&
                                this.state.listData.length - 1 == i && (
                                  <>
                                    <button
                                      disabled={this.state.negoOnBudgetSend}
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "BUDGET";
                                        let otherParam = {
                                          keyAction: "AGREE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_agree"
                                    >
                                      Agree
                                    </button>
                                    <button
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "BUDGET";
                                        let otherParam = {
                                          keyAction: "NEGOTIATE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_nego"
                                    >
                                      Negotiate
                                    </button>
                                    {/* <button disabled={this.state.negoOnBudgetSend} onClick={(evn)=>{
                        evn.preventDefault();
                        let keyOptn = "BUDGET";
                        let otherParam = {"keyAction":"REJECT","userLatestChatRecord":this.state.listData[i]};
                        this.nowPerformAction(keyOptn,otherParam);
                        }} className="btn_appearance_none chatBtn_reject">
                        Reject
                        </button> */}
                                  </>
                                )}

                              {this.state.listData[i].fromUserId ==
                                this.state.lpUserId &&
                                this.state.listData.length - 1 == i &&
                                this.state.budgetNegotiationStatus ==
                                  "NEGOTIATE" && (
                                  <>
                                    <button
                                      disabled={this.state.negoOnBudgetSend}
                                      onClick={(evn) => {
                                        evn.preventDefault();
                                        let keyOptn = "BUDGET";
                                        let otherParam = {
                                          keyAction: "NEGOTIATE",
                                          userLatestChatRecord:
                                            this.state.listData[i],
                                        };
                                        this.nowPerformAction(
                                          keyOptn,
                                          otherParam
                                        );
                                      }}
                                      className="btn_appearance_none chatBtn_reNegotiate"
                                    >
                                      Update Budget
                                    </button>
                                  </>
                                )}
                              <div className="clearboth" />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  {/* <div className="chat_refrash_btn">
                <button onClick={(evn)=>{
                    evn.preventDefault();
                    this.reloadDataOfChatList();
                }} className={`btn_appearance_none rf_btn ${this.state.loaderFlag==true?"active":""}` }>
                    <i className="fa fa-refresh" aria-hidden="true" />
                </button>
                </div> */}
                </div>
              </div>
            </div>
            {/* chat mid body end  */}
            {/* ====   sprint - 4 chat structure end ====  */}
          </div>
          {/* </Scrollbars> */}
        </div>
        {this.state.conclusionMessage !== undefined &&
          this.state.conclusionMessage !== null &&
          this.state.conclusionMessage !== "" && (
            <div className="chat_bottom_msg_new_bg">
              <div className="chat_bottom_msg_new">
                <p>{this.state.conclusionMessage}</p>
              </div>
            </div>
          )}
        {this.state.scrollToNewNotiMsgFlag && (
          <>
            <div className="chat_bottom_msg_new_bg">
              <div
                onClick={(evn) => {
                  evn.preventDefault();
                  this.scrollDownToNewMessages({});
                }}
                className="chat_bottom_msg_new"
              >
                <p>New message received</p>
              </div>
            </div>
            <audio autoPlay hidden>
              <source src={getBaseUrl() + "notify.mp3"} type="audio/mpeg" />
            </audio>
          </>
        )}
      </>
    );
  };

  loadfooterOfBudgetNego = () => {
    return (
      <>
        <CustomMessageBar />
        {this.state.negoBudgetClickFlag == false && (
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
            <div className="chat_bottom_left">
              {this.state.assignedTo === null &&
                this.state.budgetNegotiationStatus != "AGREE" && (
                  <p>Select a response from above to continue</p>
                )}
            </div>
          </div>
        )}

        {this.state.negoBudgetClickFlag == true && (
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
            <div className="chat_bottom_left">
              <div className="chat_bottom_inputBg">
                {/*  budget input grup start */}

                <div className="chatFoot_inputGrp chatFoot_textMsg_grp_for_reNego">
                  <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_budget">
                    <div
                      className={`cht_input_grp_reNego_block ${
                        this.state.budgetErMsg ? "form_group_error" : ""
                      } `}
                    >
                      {this.state.proposedBudgetCurrency == "INR" && (
                        <img src="assets/images/rupee-icon.png" />
                      )}

                      {this.state.proposedBudgetCurrency == "USD" && (
                        <img src="assets/images/dollar-icon.png" />
                      )}

                      {this.state.proposedBudgetCurrency == "EUR" && (
                        <img src="assets/images/pound-icon.png" />
                      )}

                      <input
                        type="text"
                        onChange={(evn) => {
                          evn.preventDefault();
                          let budgetInputData = evn.target.value;
                          this.nowPerformAction("BUDGETINPUTCHANGE", {
                            budgetInput: budgetInputData,
                          });
                        }}
                        maxLength="10"
                        value={this.state.budgetInput}
                        placeholder="Enter new amount to negotitate"
                      />
                      <span className="top_sug_left">
                        <span
                          className="input_tooltip btn_tooltip"
                          aria-label="Budget Amount"
                          data-balloon-pos="up"
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </span>
                    </div>
                    <span className="error_span">{this.state.budgetErMsg}</span>
                  </div>
                  <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_msg">
                    <div
                      className={`fileUpMsg_input ${
                        this.state.budgetDueDateCommonMsgErr
                          ? "form_group_error"
                          : ""
                      } `}
                    >
                      <div className="cht_input_grp_reNego_block form_group_error---">
                        <textarea
                          name="budgetDueDateCommonMsg"
                          value={this.state.budgetDueDateCommonMsg}
                          onChange={(ev) => {
                            ev.preventDefault();
                            this.trackChangeOfNewNegoMessageBox({
                              keyName: "budgetDueDateCommonMsg",
                              keyValue: ev.target.value,
                            });
                          }}
                          placeholder="(Optional) Enter message upto 120 characters only, Sharing of phone no. or personal details will disqualify you for the platform"
                        />
                        <span className="top_sug_left">
                          <span
                            className="input_tooltip btn_tooltip"
                            aria-label="Optional Message"
                            data-balloon-pos="up"
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </span>
                      </div>
                      <span className="error_span">
                        {this.state.budgetDueDateCommonMsgErr}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {/*  budget input grup end */}
              </div>
            </div>
            <div className="s_footer_btnbg">
              <div className="chatFoot_upBtn">
                {/* <div className="upload-btn-wrapper">
                      <label className="new_upbutton" htmlFor="upload">
                        <button className="uploadbtn">
                          <img src="assets/images/path.png" alt />
                        </button>
                      </label>
                      <input type="file" name="myfile" />
                      <div className="clearboth" />
                    </div> */}
              </div>
              <button
                onClick={(evn) => {
                  evn.preventDefault();
                  this.callBackBtn();
                }}
                className="btn_appearance_none blank_btn"
              >
                <span>Cancel</span>
              </button>
              <button
                disabled={this.state.negoOnBudgetSend}
                onClick={(evn) => {
                  evn.preventDefault();
                  this.chkBeforeSend({ for: "BUDGET", action: "NEGOTIATE" });
                }}
                className="btn_appearance_none sub_grd_btn"
              >
                <span>Send</span>
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  loadfooterOfDateNego = () => {
    const defaultDateData = this.state.dueDateInput; //new Date();
    const defaultDate =
      defaultDateData !== null
        ? getDateObjFromMilliseconds(defaultDateData)
        : null;

    return (
      <>
        <CustomMessageBar />
        {this.state.negoDateClickFlag == false && (
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
            <div className="chat_bottom_left">
              {this.state.assignedTo === null &&
                this.state.dueDateNegotiationStatus !== "AGREE" && (
                  <p>Select a response from above to continue</p>
                )}
            </div>
          </div>
        )}
        {this.state.negoDateClickFlag == true && (
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
            <div className="chat_bottom_left">
              {/* <p>Select a response from above to continue</p> */}
              <div className="chat_bottom_inputBg">
                {/*  budget input grup start */}
                {/*  budget input grup start */}
                {/*  date input grup start */}

                <div className="chatFoot_inputGrp chatFoot_textMsg_grp_for_reNego">
                  <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_dueDate">
                    <div
                      className={`cht_input_grp_reNego_block ${
                        this.state.dueDateErMsg ? "form_group_error" : ""
                      } `}
                    >
                      <div className="cht_btm_icon">
                        <img src="assets/images/calendar-black.png" alt="" />
                      </div>
                      <div className="cht_input_grp cht_input_grp_date">
                        <div className="date" id="sandbox-container">
                          {this.customDate(defaultDate)}
                          {/* <input type="text" placeholder="Select Due Date" /> */}
                        </div>
                      </div>

                      <span className="top_sug_left">
                        <span
                          className="input_tooltip btn_tooltip"
                          aria-label="Due Date"
                          data-balloon-pos="up"
                        >
                          <i className="fa fa-question-circle" />
                        </span>
                      </span>
                    </div>
                    <span className="error_span">
                      {this.state.dueDateErMsg}
                    </span>
                  </div>
                  <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_msg">
                    <div
                      className={`fileUpMsg_input ${
                        this.state.budgetDueDateCommonMsgErr
                          ? "form_group_error"
                          : ""
                      } `}
                    >
                      <div className="cht_input_grp_reNego_block form_group_error---">
                        <textarea
                          name="budgetDueDateCommonMsg"
                          value={this.state.budgetDueDateCommonMsg}
                          onChange={(ev) => {
                            ev.preventDefault();
                            this.trackChangeOfNewNegoMessageBox({
                              keyName: "budgetDueDateCommonMsg",
                              keyValue: ev.target.value,
                            });
                          }}
                          placeholder="(Optional) Enter message upto 120 characters only, Sharing of phone no. or personal details will disqualify you for the platform"
                        />
                        <span className="top_sug_left">
                          <span
                            className="input_tooltip btn_tooltip"
                            aria-label="Optional Message"
                            data-balloon-pos="up"
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </span>
                      </div>
                      <span className="error_span">
                        {this.state.budgetDueDateCommonMsgErr}{" "}
                      </span>
                    </div>
                  </div>
                </div>

                {/*  date input grup end */}
              </div>
            </div>
            <div className="s_footer_btnbg">
              <button
                onClick={(evn) => {
                  evn.preventDefault();
                  this.callBackBtn();
                }}
                className="btn_appearance_none blank_btn"
              >
                <span>Cancel</span>
              </button>
              <button
                disabled={this.state.negoOnDateSend}
                onClick={(evn) => {
                  evn.preventDefault();
                  this.chkBeforeSend({ for: "DUE_DATE", action: "NEGOTIATE" });
                }}
                className="btn_appearance_none sub_grd_btn"
              >
                <span>Send</span>
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  // Newest
  scrollToBottom = () => {
    //console.log(" ***** called scrollToBottom *****");

    this.scrollerRef.current.scrollTop = this.scrollerRef.current.scrollHeight;
  };

  scrollDownToNewMessages = (other) => {
    this.isComponentLoaded &&
      this.setState({ scrollToNewNotiMsgFlag: false }, () => {
        // scroll bottom
        setTimeout(() => {
          // Newest
          // let scobj = this.scrollerRef.current;
          // scobj.scrollToBottom();

          // Newest
          this.scrollToBottom();
        }, 1200);
      });
  };

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //     let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //     if(setShare){
    //         let paramsCntxt = {
    //         "taskId":"",
    //         "actionType":"",
    //         "leftTabToActive":"",
    //         "purpose":"",
    //         "other":{}
    //         }
    //         setShare(paramsCntxt);
    //     }
    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "PROFESSIONALS_INTERSESTED_LIST",
    });
  };

  // engagementLetterModalOpen = async (otherParam)=>{
  //    let taskId  = otherParam.taskId;
  //    let paramObj1 = {
  //       "data":{
  //       "taskId":taskId
  //     }
  //     };
  //     // set latest task details resp data
  //     let taskResp = await this.getTaskDetailData(paramObj1);

  //     this.isComponentLoaded &&  this.setState({"showEngagementModal":true,"taskResp":taskResp},()=>{

  //         //console.log("11 soumik=>>>> taskResp=>>>",this.state.taskResp);
  //         this.modalforEngagetentRef.current.showModal();
  //         this.getEngagementModalContent(otherParam);

  //     });

  // }
  // engagementLetterClose = ()=>{
  //     this.isComponentLoaded && this.setState({showEngagementModal:false},()=>{
  //       this.modalforEngagetentRef.current.hideModal();
  //     });
  // };

  // takeUpTask = (paramObjData)=>{

  //     let service = new TakeUpTaskService();
  //     service.takeUpTask({"data":paramObjData}).then(data =>{
  //         if(data.status==200){
  //           //console.log(data)
  //         }
  //     }).catch((err)=>{

  //             let errStatusCodeAr = [400,500]; // allowed error status code
  //             if(err.response && err.response.data){
  //                 let erRespData = err.response.data;
  //                 if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
  //                     let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
  //                     //console.log(errMsg);
  //                     let notiMsg = errMsg;
  //                         notify(notiMsg,true,{
  //                         "successMsg":0,
  //                         "customWrapClass":"customerrmidlass",
  //                         "showCloseButton": false,

  //                         "dismiss": {
  //                         "duration": 2500,
  //                         }

  //                     });

  //             }
  //             }
  //     }).finally(()=>{
  //         this.engagementLetterClose();
  //         this.reloadDataOfChatList();

  //     });

  // }
  // getEngagementModalContent(otherParam) {
  //   //console.log(" eng otherParam==>",otherParam," state==>", this.state);
  //   //console.log("22 soumik=>>>> taskResp=>>>",this.state.taskResp);
  //   let taskResp = this.state.taskResp;

  //   let ownerName = "";
  //   let organisationName = "";
  //   let lpUserName = otherParam.lpUserName!==undefined?otherParam.lpUserName: "";
  //   let taskName = "";
  //   let lpUserInterestedData = otherParam.lpUserInterestedData!==undefined?otherParam.lpUserInterestedData: null;

  //   let agreedBudget = "";
  //   let agreedBudgetCurrency = "";
  //   let agreedDueDate = "";
  //   let termsAcceptedOn = "";

  // if(taskResp!==null){
  //       let owner = taskResp.owner!==undefined && taskResp.owner!==null? taskResp.owner : null;

  //       let ownFnm = owner.firstName!==undefined && owner.lastName!==null? owner.firstName : "";
  //       let ownLnm = owner.lastName!==undefined && owner.lastName!==null? owner.lastName : "";

  //       ownerName = ownFnm+" "+ ownLnm;

  //       organisationName = taskResp.organisationName!==undefined && taskResp.organisationName!==null? taskResp.organisationName : "";

  //       taskName = taskResp.taskName!==undefined && taskResp.taskName!==null? taskResp.taskName : "";

  //       agreedBudget = this.state.agreedBudget!==undefined && this.state.agreedBudget!==null?this.state.agreedBudget:"";

  //       agreedBudgetCurrency = this.state.agreedBudgetCurrency!==undefined && this.state.agreedBudgetCurrency!==null?this.state.agreedBudgetCurrency:"";

  //       agreedDueDate = this.state.agreedDueDate!==undefined&& this.state.agreedDueDate!==null?this.state.agreedDueDate:"No Due Date";

  //   }

  //     let viewData = <div className="dash_modal">
  //     <>
  //         <div className="modal-header">
  //             <h4 className="modal-title modal_title_tool"> Engagement Letter
  //             {/*<div className="input_tooltip_bg">
  //                     <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
  //                     <i className="fa fa-question-circle"></i>
  //                     </span>
  //                     </div>*/}
  //             </h4>
  //             <button type="button" onClick={
  //                 ()=>{
  //                 this.engagementLetterClose();

  //             }} className="close modal_close" data-dismiss="modal"> <img src={getBaseUrl()+"assets/images/red_cross.png"} alt=""/></button>
  //         </div>
  //         <CustomMessageBar />
  //         <div className="modal-body padding_top">

  //         <div className="engagementPop_p">
  //         <div className="engaLtr_title">
  //         A PROFESSIONAL ENGAGEMENT POWERED BY {(getSiteName().toUpperCase())}
  //             </div>
  //             <div className="engaLtr_contBlock">
  //                 <div className="engaLtr_half">
  //                 Organisation: <b>{organisationName}</b>
  //                 </div>
  //                 <div className="engaLtr_half">
  //                 {" "}
  //                 Professional: <b>{getUserName()}</b>
  //                 </div>
  //             </div>
  //             <div className="engaLtr_contBlock">
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     Congratulations on agreeing to complete the task <b>"{taskName}"</b>
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     The Professional shall complete the Task using the {getSiteName()} Platform by{" "}
  //                     <b>{agreedDueDate}.</b>
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     The Professional shall generate an invoice using the {getSiteName()} Platform.
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     The Organisation shall pay the invoice amount <b>{agreedBudgetCurrency} {agreedBudget}.</b>
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     The Professional hereby grants a non-exclusive, perpetual, world-wide
  //                     license to the Organisation to use the intellectual property created in
  //                     the deliverable of the Task.
  //                 </p>
  //                 <p>
  //                     The Professional shall be liable under applicable laws for the
  //                     deliverable under the Task or the part thereof including but not limited
  //                     to professional liability.
  //                 </p>
  //                 <p>
  //                     The Professional and the Organisation hereby agree that, {getSiteName()} will
  //                     only facilitate the workflow between the Organisation and the
  //                     Professional and shall not be responsible for accuracy and quality of
  //                     the Professional’s services rendered herein or for the payment
  //                     obligations of the Organisation.
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     The terms and conditions of use for the <a href={(evn)=>{ evn.preventDefault();}}>Professional</a> and
  //                     the <a href={(evn)=>{ evn.preventDefault();}}>Organisation</a> are hereby incorporated by reference
  //                     (the <b>“Terms and Conditions”</b>). Words and expressions used, but not
  //                     defined in this engagement letter shall have the meanings as assigned to
  //                     them in the Terms and Conditions.
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_full">
  //                 <p>
  //                     Any dispute arising out of this engagement letter shall be submitted to
  //                     an online dispute resolution mechanism provided by {getSiteName()}.
  //                 </p>
  //                 <p>
  //                     This engagement letter shall be construed and interpreted in accordance
  //                     with the laws of Republic of India.
  //                 </p>
  //                 </div>
  //                 <div className="engaLtr_tableLayout">
  //                 <div className="engaLtr_tableLayout_half engaLtr_tableLayout_left">
  //                     <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_sign">
  //                     Signed by the Organisation
  //                     </div>
  //                     <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_date">
  //                     &nbsp;
  //                     </div>
  //                 </div>
  //                 <div className="engaLtr_tableLayout_half engaLtr_tableLayout_right">
  //                     <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_sign">
  //                     Signed by the Professional
  //                     </div>
  //                     <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_date">
  //                     Date - {CustDate.custFormatDate((new Date()),3)}
  //                     </div>
  //                 </div>
  //                 <div className="clearboth" />
  //                 </div>
  //             </div>
  //             <div className="clearboth" />

  //         </div>
  //         </div>
  //         <div className="modal-footer">
  //         <span className="top_sug_left">
  //             <span className="input_tooltip btn_tooltip" aria-label="You are signing this engagement letter offering to take up the task if assigned to you by the Organization. This engagement letter shall become effective only after the Organization assigns the task to you. You will be notified if the Organization assigns the task to you." data-balloon-pos="up">
  //             <i className="fa fa-question-circle" />
  //             </span>
  //         </span>
  //         <button type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
  //                 let paramObj = {"taskId":otherParam.taskId}
  //                 this.takeUpTask(paramObj);
  //             } } data-dismiss="modal"><span>I Agree</span></button>
  //         </div>
  //     </>
  // </div>;

  //     this.modalforEngagetentRef.current.updateStateContent(viewData);
  //     this.modalforEngagetentRef.current.showContent();
  //     return viewData;
  // }

  loadTheContent = () => {
    let taskName = "";
    //let clientName = "";
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }

      if (
        sharedData.other !== undefined &&
        sharedData.other.taskResp !== undefined &&
        sharedData.other.taskResp.taskName !== undefined
      ) {
        taskName = sharedData.other.taskResp.taskName
          ? sharedData.other.taskResp.taskName
          : "";
        //clientName = sharedData.other.taskResp.owner.firstName?  sharedData.other.taskResp.owner.firstName : " "+ ' ' + sharedData.other.taskResp.taskName?sharedData.other.taskResp.owner.lastName:" ";
      }
    }
    //********* for context ends *****

    //console.log(" sharedData=>>",sharedData);

    return (
      <>
        {/**** Dashboard Slide Split Right - screen star  ***/}
        <div
          className={`splitscreen_area ${
            forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""
          }`}
        >
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />

            <div
              ref={this.negochatcontentHead}
              className="slideScreen_rightbg flexbox-parent"
            >
              {/****** right slide section top start *******/}
              <div className="SlideS_topbg flexbox-item slide_chat_topbg slide_chat_topbg_lp">
                <div className="slide_top_titlebg">
                  {/**** <h4>Professionals Interested</h4> ****/}
                  <div className="slideChat_head">
                    {/*
                                                <div className="slideChat_panel">
                                                    <button className="btn_appearance_none chatHeadBack"><span>Back</span></button>
                                                </div>
                                                */}
                    <div className="slideChat_panel">
                      <h5 className="chatHeadUser">
                        {this.state.ccOrganisationName}
                      </h5>
                      {/* <p>Monitor Task (Conversation with <a href="javascript:void(0);">Yashwanth Kuznetsov</a> )</p> */}
                    </div>
                    <div className="slideChat_panel">
                      {/* <button className="btn_appearance_none sub_grd_btn chatHeadAssign"><span>**</span></button> */}
                    </div>
                  </div>
                  <button
                    className="btn_appearance_none slideTop_cross_btn cross"
                    onClick={(evn) => {
                      evn.preventDefault();
                      //
                      this.closeSplit({
                        trigerredFrom: "PROFESSIONALS_INTERSESTED_LIST",
                      });
                    }}
                  >
                    <img src="assets/images/red_cross.png" alt="" />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              <CustomMessageBar keydata={"negotiationchatNotiK1"} />
              <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_budgetDueDate">
                {/*
                                <div className="chatTab_topText_sec">
                                    <h4 className="chatTab_top_text">A simple sub-task 01</h4>
                                </div>
                                */}

                <div className="chatBody_topSec">
                  <div className="chatTab_top_btnBG">
                    <button
                      disabled={this.state.tabDisFlag}
                      onClick={(env) => {
                        env.preventDefault();
                        this.chooseNegoTab("BUDGET", {});
                      }}
                      className={`btn_appearance_none chatTabBtn ${
                        this.state.negoOn == 0 ? "active" : ""
                      }`}
                    >
                      Budget
                      {/* <span className="chatTab_new">New</span> */}
                    </button>
                    <button
                      disabled={this.state.tabDisFlag}
                      onClick={(env) => {
                        env.preventDefault();
                        this.chooseNegoTab("DATE", {});
                      }}
                      className={`btn_appearance_none chatTabBtn ${
                        this.state.negoOn == 1 ? "active" : ""
                      }`}
                    >
                      Due Date
                      {/* <span className="chatTab_new">New</span> */}
                    </button>
                    <div className="clearboth"></div>
                  </div>
                  <div className="chatBody_top">
                    <p className="chatBody_top_p">
                      Proposed Budget -{" "}
                      <b>
                        {this.state.proposedBudgetCurrency +
                          " " +
                          this.state.proposedBudget}
                      </b>
                      <span className="nego_ChatScreen_tickSpan">
                        &nbsp;
                        {this.state.budgetNegotiationStatus == "AGREE" ? (
                          <img
                            src={getBaseUrl() + "/assets/images/green_tick.png"}
                          />
                        ) : (
                          <img
                            src={
                              getBaseUrl() +
                              "/assets/images/orange-negotiate.png"
                            }
                          />
                        )}
                      </span>
                    </p>
                    <p className="chatBody_top_p">
                      Proposed Due Date -{" "}
                      <b>
                        {this.state.proposedDueDate
                          ? this.state.proposedDueDate
                          : "No due date"}
                      </b>
                      <span className="nego_ChatScreen_tickSpan">
                        &nbsp;
                        {this.state.dueDateNegotiationStatus == "AGREE" ? (
                          <img
                            src={getBaseUrl() + "/assets/images/green_tick.png"}
                          />
                        ) : (
                          <img
                            src={
                              getBaseUrl() +
                              "/assets/images/orange-negotiate.png"
                            }
                          />
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                {/* s_midbox_scroll_adjust */}
                {this.state.negoOn == 0
                  ? this.loadChatListOfBudgetNego()
                  : this.loadChatListOfDateNego()}
              </div>
              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}
              {/* SlideS_bottombg */}
              {/* {this.state.negoOn==0 ? this.loadfooterOfBudgetNego(): this.loadfooterOfDateNego()} */}

              {this.state.budgetNegotiationStatus == "AGREE" &&
              this.state.dueDateNegotiationStatus == "AGREE" &&
              this.state.isTaskOfferReceived &&
              this.state.taskType == "OFFER_RECEIVED" &&
              this.state.assignedTo === null ? (
                <div class="SlideS_bottombg flexbox-item Slide_chatBottomBg">
                  <div class="chat_bottom_left"></div>
                  <div className="s_footer_btnbg">
                    <AcceptTaskOfferButton
                      custclassname="btn_appearance_none sub_grd_btn"
                      controltype="BUTTON"
                      controlnametext="View Task Offer"
                      callbackfunc={this.taskOfferPopupButtonCallback}
                      datas={{
                        taskOrSubTask: "STASK",
                        taskIdOrSubTaskId: this.state.taskId,
                        taskOrSubTaskName: this.state.taskName,
                        taskDesc: this.state.taskDescription,
                        lpUserId: this.state.lpUserId,
                        ccUserId: this.state.ccUserId,
                        lpUserName: this.state.lpUserName,
                        organisationName: this.state.ccOrganisationName,
                        agreedBudget:
                          this.state.agreedBudget !== undefined &&
                          this.state.agreedBudget !== null
                            ? this.state.agreedBudget
                            : null,
                        agreedBudgetCurrency:
                          this.state.agreedBudgetCurrency !== undefined &&
                          this.state.agreedBudgetCurrency !== null
                            ? this.state.agreedBudgetCurrency
                            : null,
                        agreedDueDate:
                          this.state.agreedDueDate !== undefined &&
                          this.state.agreedDueDate !== null
                            ? this.state.agreedDueDate
                            : null,
                        taskOfferedAt:
                          this.state.taskOfferedAt !== undefined &&
                          this.state.taskOfferedAt !== null
                            ? this.state.taskOfferedAt
                            : "",
                      }}
                    />
                  </div>
                </div>
              ) : this.state.negoOn == 0 ? (
                this.loadfooterOfBudgetNego()
              ) : (
                this.loadfooterOfDateNego()
              )}

              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>
        {/* ==== ********* Dashboard Slide Split Right - screen end ==== ********* */}
      </>
    );
  };

  taskOfferPopupButtonCallback = (data) => {
    setTimeout(() => {
      window.scrollTo(0, this.negochatcontentHead.current.offsetTop);
    }, 2500);

    setTimeout(() => {
      this.callAfterEngagementPopopClose(data);
    }, 4000);
  };

  callAfterEngagementPopopClose = async (data) => {
    let activity = data.activity !== undefined ? data.activity : "";
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    if (activity == "SINGLE_TASK_OFFER_ACCEPTED_SUCCESS") {
      // show green success message
      notify("Offer accepted successfully!!", true, {
        successMsg: 1,
        customWrapClass: "customerrmidlass alert_section_top_zero",
        msgKeyData: "negotiationchatNotiK1",
        showCloseButton: false,

        dismiss: {
          duration: 4700,
        },
      });

      // since task has been accepted so make the tab assigned active
      //  commonnotitrack("SINGLE_TASK_OFFER_ACCEPTED_SUCCESS",{
      //   "notiType": "SINGLE_TASK_OFFER_ACCEPTED_SUCCESS",
      //   "triggerCallback":true,
      //   "trigerredFrom":"PROFESSIONALS_INTERSESTED_LIST",

      //  });

      if (setShare && sharedData) {
        let paramObj1 = {
          data: {
            taskId: this.state.taskId,
          },
        };

        // update only taskResp in context
        let taskDetResp = await this.getTaskDetailData(paramObj1);
        sharedData.other.taskResp = taskDetResp;
        setShare(sharedData);

        if (this.state.negoOn == 0) {
          this.chooseNegoTab("BUDGET", {});
        } else if (this.state.negoOn == 1) {
          this.chooseNegoTab("DATE", {});
        }
      }
    } else if (activity == "SINGLE_TASK_OFFER_DECLINE_SUCCESS") {
      // show green success message
      notify("Offer declined successfully!!", true, {
        successMsg: 1,
        customWrapClass: "customerrmidlass alert_section_top_zero",
        msgKeyData: "negotiationchatNotiK1",
        showCloseButton: false,

        dismiss: {
          duration: 4700,
        },
      });

      // since task has been accepted so make the tab assigned active
      // commonnotitrack("SINGLE_TASK_OFFER_DECLINE_SUCCESS",{
      //   "notiType": "SINGLE_TASK_OFFER_DECLINE_SUCCESS",
      //   "triggerCallback":true,
      //   "trigerredFrom":"PROFESSIONALS_INTERSESTED_LIST",

      //  });

      if (setShare && sharedData) {
        let paramObj1 = {
          data: {
            taskId: this.state.taskId,
          },
        };

        // update only taskResp in context
        let taskDetResp = await this.getTaskDetailData(paramObj1);
        sharedData.other.taskResp = taskDetResp;
        setShare(sharedData);

        if (this.state.negoOn == 0) {
          this.chooseNegoTab("BUDGET", {});
        } else if (this.state.negoOn == 1) {
          this.chooseNegoTab("DATE", {});
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotiEmitTrack
          key={"splitsnegocht001"}
          callbackofbellnotimsgclick={this.maketabactiveforbellnotirelnegochat}
        />
        {this.loadTheContent()}
        <CustReactBootstrapModal
          ref={this.modalforEngagetentRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showEngagementModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg engageagreepopupcls engagementLetter_modal_bg"
          loaderbodytitle="Organisation"
        />
      </React.Fragment>
    );
  }
}

export default SplitScreenProfessionalsInterested;
