import React, { Component } from 'react'

import SplitScreenPartTaskDetails from './../content/after_login/SplitScreenPartTaskDetails'
import SplitScreenProfessionalsInterested from '../content/after_login/SplitScreenProfessionalsInterested';
import SplitScreenTaskDocuments from '../content/after_login/SplitScreenTaskDocuments';
import SplitScreenPreAssingmentTaskDocuments from '../content/after_login/SplitScreenPreAssingmentTaskDocuments';
import SplitScreenTimeLogs from '../content/after_login/SplitScreenTimeLogs';
import SplitScreenMonitorTaskChat from '../content/after_login/SplitScreenMonitorTaskChat';
import SplitScreenTaskInvoiceView from '../content/after_login/SplitScreenTaskInvoiceView';
import SplitScreenTaskInvoiceHistory from '../content/after_login/SplitScreenTaskInvoiceHistory';
// for context
import CommonContext from '../context/CommonContext';

export class SplitScreenPart extends Component {
    // for context
    static contextType = CommonContext;
    constructor(props) {
        super(props);
        this.children = props.children;
  
        this.state = {
          leftTabToActive:"",
          purpose: "",
          taskId:0,
          // task details view related
        }
    }

    componentDidMount() {
    }
    componentWillUnmount() {

    }
    render() {
        const share = {};

        let paramObjStatus ={
        leftTabToActive:"",
        purpose: "",
        taskId:0,
        }

        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

            if(sharedData.actionType=="SPLITSCREEN" ){
            paramObjStatus.taskId = sharedData.taskId;
            paramObjStatus.leftTabToActive = sharedData.leftTabToActive;
            paramObjStatus.purpose = sharedData.purpose;
            }
        }
        //********* for context ends *****
        let {leftTabToActive,purpose,taskId} = paramObjStatus;
        switch (leftTabToActive) {
            case "TASKDETAILS":
                return (<SplitScreenPartTaskDetails />);
            break;
            case "PROFESSIONALSINTERESTED":
                return (<SplitScreenProfessionalsInterested />);
            break;
            case "MONITORTASK": 
                return (<SplitScreenMonitorTaskChat />);
            break;
            case "DOCUMENTS":
                if(purpose=="SHOW_PRE_ASSINGMENT_DOC"){
                  return(<SplitScreenPreAssingmentTaskDocuments />)
                } else if(purpose=="VIEW_DOC_LIST") {
                  return (<SplitScreenTaskDocuments />);
                }
            break;
            case "TIMELOGS":
                return (<SplitScreenTimeLogs />);
            break;
            case "INVOICE":
            if(purpose=="VIEW_INVOICE"){
              return(<SplitScreenTaskInvoiceView />);
            } else if(purpose=="VIEW_INVOICE_LIST") {
              return (<SplitScreenTaskInvoiceHistory />);
            }
            break;
            default:
                return (<></>);
            break;
        }
    }
}

export default SplitScreenPart
