import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

import CommonContext from '../../context/CommonContext';
import ManageInvoiceListService from '../../service/ManageInvoiceListService';
import InvHistoryRowEditSaveService from '../../service/InvHistoryRowEditSaveService';

import ManageInvoiceGeneratorGenericHeader from './ManageInvoiceGeneratorGenericHeader';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import {notify} from '../../messagebar/CustomMessageBar';
import CustLoader from './CustLoader';

import  CustDate  from '../../../common_utilities/CustDate';
import InvoicePdfViewOnPopup from './InvoicePdfViewOnPopup';
import InvoiceSharePopup from './InvoiceSharePopup';
import RequestPaymentPopup from './RequestPaymentPopup';

import { Scrollbars } from 'react-custom-scrollbars';

import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from 'react-paginate';


const getBaseUrl = commonJsObj.getBaseUrl;
const getManageInvHistoryMainFilterOptions = commonStaticJsObj.getManageInvHistoryMainFilterOptions;
const manageInvHistoryMainFilterOptions = getManageInvHistoryMainFilterOptions();
const getPaidUnpaidOptions = commonStaticJsObj.getPaidUnpaidOptions;
const paidUnpaidOptions = getPaidUnpaidOptions();
const getDateFormatForInvGenCal = commonJsObj.getDateFormatForInvGenCal;
const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
//const getKycVerifiedStatus = commonJsObj.getKycVerifiedStatus;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;
const getUserName = commonJsObj.getUserName;



const ExampleCustomInput = ({ value, onClick, keydata, placeholdertxt, ctrlname }) => (    
    <input
    type="text"
    className="form__field customdatefld"
    placeholder={placeholdertxt}
    defaultValue={value}
    onClick={onClick}
    key={keydata}
    readOnly={true}
    name={ctrlname}
    id={ctrlname}
    value={value}

    />
  );

const rowHeaderObj = [
    {"keyName":"checkboxExtremeLeft","keyOrder":1,"visibleStatus":true,"label":"","apiRespData":"NO"},
    {"keyName":"generatedDate","keyOrder":2,"visibleStatus":true,"label":"Generation date","apiRespData":"YES"},
    {"keyName":"clientDetails","keyOrder":3,"visibleStatus":true,"label":"Client name","apiRespData":"YES"},
    {"keyName":"matterDetails","keyOrder":4,"visibleStatus":true,"label":"Reference / Matter name","apiRespData":"YES"},
    {"keyName":"amount","keyOrder":5,"visibleStatus":true,"label":"Invoice amount","apiRespData":"YES"},
    {"keyName":"isPaid","keyOrder":6,"visibleStatus":true,"label":"Paid Status","apiRespData":"YES"},
    {"keyName":"dueDate","keyOrder":7,"visibleStatus":true,"label":"Due date","apiRespData":"YES"},
    {"keyName":"paidDate","keyOrder":8,"visibleStatus":true,"label":"Paid date","apiRespData":"YES"},
    {"keyName":"additionalFiles","keyOrder":9,"visibleStatus":true,"label":"Additional files","apiRespData":"YES"},
    {"keyName":"invoiceNumber","keyOrder":10,"visibleStatus":true,"label":"Invoice actions","apiRespData":"YES"},
    {"keyName":"notes","keyOrder":11,"visibleStatus":false,"label":"Notes","apiRespData":"YES"},
    {"keyName":"id","keyOrder":12,"visibleStatus":false,"label":"Id","apiRespData":"YES"},
    {"keyName":"actions","keyOrder":13,"visibleStatus":true,"label":"Actions","apiRespData":"NO"},

];

export class ManageInvoiceHistory extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props);
        this.state ={
                "pageNum": 1,
                "invoiceList":[],
                "invoiceListM":[],
                "aggregateAmountBilled": 0,
                "aggregateAmountDue": 0,
                "totalNumberOfClients": 0,
                "totalInvoicesRaised": 0,
                "totalInvoicesPaid": 0,
                "totalInvoicesDue": 0,
                "totalNumberOfPages": 0,

                // other
                "searchText":"",
                "searchTextPre":"",
                "sortOn":"",
                "sortDir":"",
                "view":2, // 1 or 2 => 1=> Grow Invoices, 2=> Manage Invoices,
                "paidStatus":"",
                "loaderFlag":false,
            
        };
        this.scrollerRef = React.createRef();
        this.downloadTableUrl = commonJsObj.getEndPoint()+'rest/invoice/download_table';
        this.downloadAdditionalAttachmentsUrl = commonJsObj.getEndPoint()+'rest/invoice/download_attachments';
        this.downloadInvoiceUrl = commonJsObj.getEndPoint()+'rest/invoice/download_invoice';
        this.queryParamObj = new URLSearchParams(this.props.location.search)
    }
    componentDidMount() {
       this.isComponentLoaded =  true;
       console.log("******* React.version==>",React.version);


       let view = this.queryParamObj.get("view");
       view = (view) && !isNaN(view)?view:2;
       console.log("view==>>",view);


       if(view){
        this.loadInvHistoryFromBeginning({"view":view});
       }

       

    }
    
    componentWillUnmount() {
        this.isComponentLoaded =  false;
    }

    customDueDate = (defaultDate,dtKey,placeholdertxt,datefor,nameData,otherParam)=>{        
           
        return (
          <DatePicker
            
            selected={defaultDate}
            onChange={date => {this.setCalDate(date,datefor,otherParam)}}
            calendarClassName="customtaskaddclassstep1calcls"         
            customInput={<ExampleCustomInput keydata={dtKey} placeholdertxt={placeholdertxt} ctrlname = {nameData}  />}
            dateFormat={getDateFormatForInvGenCal({"countryCode":"IN"})}
            
            
            
            
          />
        );
    }

    customPaidDate = (defaultDate,dtKey,placeholdertxt,datefor,nameData,otherParam)=>{        
           
        return (
          <DatePicker
            
            selected={defaultDate}
            onChange={date => {this.setCalDate(date,datefor,otherParam)}}
            calendarClassName="customtaskaddclassstep1calcls"         
            customInput={<ExampleCustomInput keydata={dtKey} placeholdertxt={placeholdertxt} ctrlname = {nameData}  />}
            dateFormat={getDateFormatForInvGenCal({"countryCode":"IN"})}
            
            
            
          />
        );
    }

   

    setCalDate=(dt,dateFor,otherParam)=>{            

        dt = getTimestampInMillisecondsFromDateObj(dt);
        let forTrackChange = otherParam.forTrackChange;
        if(dateFor=="paidDate" && forTrackChange){
            forTrackChange["keyValueEditted"] = dt;
            this.trackChangeInRow(forTrackChange);
       
        }
        else if(dateFor=="dueDate" && forTrackChange){
            forTrackChange["keyValueEditted"] = dt;
            this.trackChangeInRow(forTrackChange);
       
            
        }
  
    }

    loadInvHistoryFromBeginning = (otherParam) =>{

        this.showLoader();

        let thisRef=  this;
        setTimeout(()=>{
            this.isComponentLoaded && this.setState({
                "pageNum": 1,
                "invoiceList":[],
                "aggregateAmountBilled": 0,
                "aggregateAmountDue": 0,
                "totalNumberOfClients": 0,
                "totalInvoicesRaised": 0,
                "totalInvoicesPaid": 0,
                "totalInvoicesDue": 0,
                "totalNumberOfPages": 0,
    
                // other
                "searchText":"",
                "searchTextPre":"",
                "view":otherParam && otherParam.view!==undefined?otherParam.view : this.state.view,
                "paidStatus":"",
                "sortOn":"",
                "sortDir":"",
                
    
            }, ()=>{
                let serviceParam = {};
                serviceParam["data"]={
                                        "view":this.state.view,
                                        "pageNum":this.state.pageNum,
                                        } ;
               
                serviceParam["calledFrom"] =    "loadInvHistoryFromBeginning";                          
                this.fetchInvList(serviceParam);
    
            });
        },1500);
        
        
            
    }

    fetchInvList = (otherParam) =>{      
         
        let passParam = {"data":otherParam.data?otherParam.data:{}};
        let search = otherParam.data && otherParam.data.searchText?otherParam.data.searchText:"";
        let calledFrom = otherParam.calledFrom!==undefined && otherParam.calledFrom?otherParam.calledFrom:"";
        

        let userv = new ManageInvoiceListService();
        userv.getList(passParam).then(dataObj =>{
        if(dataObj.status==200){
            if(dataObj.data.entity!==undefined){
                     
                
                let respObj =  dataObj.data.entity;
                let invoiceList = (respObj && respObj.invoiceList!==undefined)?respObj.invoiceList:[];
                let aggregateAmountBilled = (respObj && respObj.aggregateAmountBilled!==undefined)?respObj.aggregateAmountBilled:0;
                let aggregateAmountDue = (respObj && respObj.aggregateAmountDue!==undefined)?respObj.aggregateAmountDue:0;
                let totalNumberOfClients = (respObj && respObj.totalNumberOfClients!==undefined)?respObj.totalNumberOfClients:0;
                let totalInvoicesRaised = (respObj && respObj.totalInvoicesRaised!==undefined)?respObj.totalInvoicesRaised:0;
                let totalInvoicesPaid = (respObj && respObj.totalInvoicesPaid!==undefined)?respObj.totalInvoicesPaid:0;
                let totalInvoicesDue = (respObj && respObj.totalInvoicesDue!==undefined)?respObj.totalInvoicesDue:0;
                let totalNumberOfPages = (respObj && respObj.totalNumberOfPages!==undefined)?respObj.totalNumberOfPages:0;

                let setStateData = {};
                
                if(search || calledFrom=="" ){

                    // when there is search 
                    setStateData["invoiceListM"]  = invoiceList;                   
                    setStateData["totalNumberOfPages"]  = totalNumberOfPages;
                    setStateData["invoiceList"]  = this.setKeyRelatedData(invoiceList,{});
                   
                   
                   


                    
                }else{
                    
                     // when there is no search  
                     setStateData["invoiceListM"]  = invoiceList;
                     setStateData["aggregateAmountBilled"]  = aggregateAmountBilled;
                     setStateData["aggregateAmountDue"]  = aggregateAmountDue;
                     setStateData["totalNumberOfClients"]  = totalNumberOfClients;
                     setStateData["totalInvoicesRaised"]  = totalInvoicesRaised;
                     setStateData["totalInvoicesPaid"]  = totalInvoicesPaid;
                     setStateData["totalInvoicesDue"]  = totalInvoicesDue;
                     setStateData["totalNumberOfPages"]  = totalNumberOfPages;
                     setStateData["invoiceList"]  = this.setKeyRelatedData(invoiceList,{});
                     
                    

                }
                console.log(" 2==== setStateData==>",setStateData);
                this.isComponentLoaded && this.setState(setStateData);



                
            }
        }
        }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    // console.log(errMsg);

                    // notify(errMsg,true,{
                    //     "successMsg":0,
                    //     "customWrapClass":"customsuccclass text-center",
                    //     "showCloseButton": false,                     
                    //     "dismiss": {
                    //     "duration": 1800,
                    //     }
              
                    // });       
                                     
            }
            } 
            
        }).finally(()=>{
            this.hideLoader();
            //this.isComponentLoaded && this.setState({"invPreviewButtonClickFlag": false});
        });
    }
    
    callSaveEdit = (otherParam) =>{ 
        
        // console.log(" from callSaveEdit otherParam=>>>",otherParam);
        // return false;

        let bulkOrSingleUpdate = otherParam.bulkOrSingleUpdate!==undefined?otherParam.bulkOrSingleUpdate:"";
        let individualRow = otherParam.individualRow!==undefined?otherParam.individualRow:null;
        let individualRowIndex = otherParam.individualRowIndex!==undefined?otherParam.individualRowIndex:null;
         
        let passParam = {"data":{
                                  "postData":  otherParam.postData?otherParam.postData:{}
                                }
                        };       
      
        let userv = new InvHistoryRowEditSaveService();
        userv.saveEdit(passParam).then(dataObj =>{
        if(dataObj.status==200){
            if(dataObj.data.entity!==undefined){

            
                if(bulkOrSingleUpdate=="SINGLE"){ 

                    this.hideEditSaveInProgress({                        
                        "individualRow":individualRow,
                        "individualRowIndex":individualRowIndex,
                    }); 
                }
                     
                
              notify("Updated successfully!",true,{
                        "successMsg":1,
                        "customWrapClass":"customsuccclass text-center",
                        "showCloseButton": false,                     
                        "dismiss": {
                        "duration": 1800,
                        }
              
                    });       



                
            }
        }
        }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    // console.log(errMsg);

                    notify(errMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customsuccclass text-center",
                        "showCloseButton": false,                     
                        "dismiss": {
                        "duration": 1800,
                        }
              
                    });       
                                     
            }
            } 
            
        }).finally(()=>{
            if(bulkOrSingleUpdate=="SINGLE"){ 

                this.hideEditSaveInProgress({                        
                    "individualRow":individualRow,
                    "individualRowIndex":individualRowIndex,
                }); 
            }
        });
    }

    bringBackToPenIconForSingleRow = (otherParam) => {        
        let individualRow = otherParam.individualRow;
        let individualRowIndex = otherParam.individualRowIndex;
        let invoiceList = [...this.state.invoiceList];       

        individualRow.openToEdit = false;
        invoiceList[individualRowIndex] = individualRow;

        this.isComponentLoaded && this.setState({"invoiceList":invoiceList});
    }

    setKeyRelatedData = (listObj,otherParam) =>{
        let listAr = [];
        
        Object.keys(listObj).map((e,i)=>{
            let rowObj = {
                   
                    "openToEdit": false,
                    "editSaveInProgress": false,
                    "elements": [],
                    "invoiceId":0,
                    "invoicePdfBase64":null,
                    "additionalFiles":[]


            };

            rowObj["invoiceId"] = listObj[i].id!==undefined && listObj[i].id?listObj[i].id:0;
            rowObj["invoicePdfBase64"] = listObj[i].invoicePdfBase64!==undefined && listObj[i].invoicePdfBase64?listObj[i].invoicePdfBase64:null;
            rowObj["additionalFiles"] = listObj[i].additionalFiles!==undefined && listObj[i].invoicePdfBase64?listObj[i].additionalFiles:[];

            let cellObjAr = []; 
            for(let cnt=0; cnt<rowHeaderObj.length; cnt++){
                
                if(rowHeaderObj[cnt].apiRespData=="YES" ){
                    if( rowHeaderObj[cnt].keyOrder==(cnt+1) && listObj[i][rowHeaderObj[cnt].keyName]!==undefined){

                                let cellObj1 = {
                                    "keyName":rowHeaderObj[cnt].keyName,
                                    "keyValueUnEditted":listObj[i][rowHeaderObj[cnt].keyName],
                                    "keyValueEditted":listObj[i][rowHeaderObj[cnt].keyName],
                                    "visibleStatus":rowHeaderObj[cnt].visibleStatus ,
                                    "keyOrder":rowHeaderObj[cnt].keyOrder,
                                    "apiRespData":rowHeaderObj[cnt].apiRespData,
                                    "errMsg":""
                                };
                                cellObjAr.push(cellObj1);
                            }
                }
                else if(rowHeaderObj[cnt].apiRespData=="NO"){
                    let cellObj2 = {
                        "keyName":rowHeaderObj[cnt].keyName,
                        "keyValueUnEditted":"",
                        "keyValueEditted":"",
                        "visibleStatus":rowHeaderObj[cnt].visibleStatus ,
                        "keyOrder":rowHeaderObj[cnt].keyOrder,
                        "apiRespData":rowHeaderObj[cnt].apiRespData,
                        "errMsg":""
                    };
                    cellObjAr.push(cellObj2);
                }

                
                }

                rowObj.elements=(cellObjAr);
                listAr.push(rowObj);
        });

        //console.log(" listAr in setKeyRelatedData====>>>",listAr," listObj=>>>",listObj);
        return listAr;
    }

    loadHeaderKeys = () => {
        let columnAr = [];
        {Object.keys(rowHeaderObj).map((e,i)=>{

            let columnObj = <></>;
            if(rowHeaderObj[i].keyName=="checkboxExtremeLeft"){
                columnObj = (
                    <th>
                    <div className="table_chk_box">
                        <label className="invh_chkbox_sec">
                        <input type="checkbox"  />
                        <span className="checkmark" />
                        </label>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="generatedDate"){
                columnObj = (
                <th>
                    <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                    <div className={`th_btn `}>
                        <button onClick={(evn)=>{
                            evn.preventDefault();                            
                            this.setSorting({"sortOn":"GENERATION_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="GENERATION_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="clientDetails"){
                columnObj = (
                    <th>
                        <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                        <div className="th_btn">
                            <button onClick={(evn)=>{
                                evn.preventDefault();                           
                                this.setSorting({"sortOn":"CLIENT"});
                            
                            }} className={`btn_appearance_none th_normal ${this.state.sortOn=="CLIENT" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                            <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                            <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                            </button>
                        </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="matterDetails"){
                columnObj = (
                    <th>
                    <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"MATTER"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="MATTER" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="amount" && rowHeaderObj[i].visibleStatus!==false){
                columnObj = (
                    <th>
                    <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"INVOICE_AMOUNT"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="INVOICE_AMOUNT" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="isPaid"){
                columnObj = (
                    <th>
                    <div className="table_drop_box tableTH_drop_box">
                        <div className="form__group field ">
                        <select name="paidStatus" value={this.state.paidStatus} onChange={(evn)=>{
                                evn.preventDefault();
                                this.handleChange("paidStatus",evn.target.value,{});
                        }} className="customSelect form__field">
                        {Object.keys(paidUnpaidOptions).map((e,i)=> <option key={i}  value={paidUnpaidOptions[e].key}>{paidUnpaidOptions[e].value}</option>) }
                        </select>
                        <span className="error_span"></span>
                        </div>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="dueDate"){
                columnObj = (
                    <th>
                    <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"DUE_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="DUE_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="paidDate"){
                columnObj = (
                    <th>
                    <div className="tabeTh_data">{rowHeaderObj[i].label}</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"PAID_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="PAID_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="additionalFiles"){
                columnObj = (
                    <th>{rowHeaderObj[i].label}</th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="invoiceNumber"){
                columnObj = (
                    <th>{rowHeaderObj[i].label}</th>
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="notes" && rowHeaderObj[i].visibleStatus===true){
                columnObj = (
                    <th >{rowHeaderObj[i].label}</th>
                    
                );

                columnAr.push(columnObj);

            }
            else if(rowHeaderObj[i].keyName=="id" && rowHeaderObj[i].visibleStatus===true){
                columnObj = (
                    <th >{rowHeaderObj[i].label}</th>
                    
                );

                columnAr.push(columnObj);

            }
            
            else if(rowHeaderObj[i].keyName=="actions"){
                columnObj = (
                    <th className="invH_lastTH_action">{rowHeaderObj[i].label}</th>
                    
                );

                columnAr.push(columnObj);

            }
           
            
        

        })}

        return(<>{columnAr}</>);

    }

    loadTableHeadRow = () =>{
        return (
                <tr>
                    {/* 
                    <th>
                    <div className="table_chk_box">
                        <label className="invh_chkbox_sec">
                        <input type="checkbox"  />
                        <span className="checkmark" />
                        </label>
                    </div>
                    </th>

                     <th>
                    <div className="tabeTh_data">Generation date</div>
                    <div className={`th_btn `}>
                        <button onClick={(evn)=>{
                            evn.preventDefault();                            
                            this.setSorting({"sortOn":"GENERATION_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="GENERATION_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                    <th>
                    <div className="tabeTh_data">Client name</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"CLIENT"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="CLIENT" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                    <th>
                    <div className="tabeTh_data">Reference / Matter name</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"MATTER"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="MATTER" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                    <th>
                    <div className="tabeTh_data">Invoice amount</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"INVOICE_AMOUNT"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="INVOICE_AMOUNT" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                    <th>
                    <div className="table_drop_box tableTH_drop_box">
                        <div className="form__group field ">
                        <select name="paidStatus" value={this.state.paidStatus} onChange={(evn)=>{
                                evn.preventDefault();
                                this.handleChange("paidStatus",evn.target.value,{});
                        }} className="customSelect form__field">
                        {Object.keys(paidUnpaidOptions).map((e,i)=> <option key={i}  value={paidUnpaidOptions[e].key}>{paidUnpaidOptions[e].value}</option>) }
                        </select>
                        <span className="error_span"></span>
                        </div>
                    </div>
                    </th>
                    <th>
                    <div className="tabeTh_data">Due date</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"DUE_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="DUE_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                    <th>
                    <div className="tabeTh_data">Paid date</div>
                    <div className="th_btn">
                        <button onClick={(evn)=>{
                            evn.preventDefault();                           
                            this.setSorting({"sortOn":"PAID_DATE"});
                        
                        }} className={`btn_appearance_none th_normal ${this.state.sortOn=="PAID_DATE" && (this.state.sortDir=="DESC"?"th_des":(this.state.sortDir=="ASC"?"th_ase":""))}`}>
                        <span className="uparrow"><i className="fa fa-long-arrow-up" aria-hidden="true" /></span>
                        <span className="downarrow"><i className="fa fa-long-arrow-down" aria-hidden="true" /></span>
                        </button>
                    </div>
                    </th>
                   
                    <th>Additional files</th>
                    <th>Invoice actions</th>
                     <th className="invH_lastTH_action">Actions</th>
                     */}
                    {this.loadHeaderKeys()}
                    
                    

                </tr>
        );
    }

    

    loadListRow = () => {

        //console.log(" HOLA invoiceList =>>>> ",this.state.invoiceList);
        let lpName = getUserName();
        lpName = lpName?lpName:"";
        const paidStatusOptForRow = [{"key":"PAID","value":"Paid"},{"key":"UNPAID","value":"Unpaid"}]
        let listAr = [];
        Object.keys(this.state.invoiceList).map((e,i)=>{

            
            let rowObjAr = [];
            let openToEdit = this.state.invoiceList[i].openToEdit!==undefined?this.state.invoiceList[i].openToEdit:false;
            let editSaveInProgress = this.state.invoiceList[i].editSaveInProgress!==undefined?this.state.invoiceList[i].editSaveInProgress:false;
            let invoiceId = (this.state.invoiceList[i].invoiceId!==undefined && this.state.invoiceList[i].invoiceId)?this.state.invoiceList[i].invoiceId:0;
            let invoicePdfBase64 = this.state.invoiceList[i].invoicePdfBase64!==undefined?this.state.invoiceList[i].invoicePdfBase64:null;
            let additionalFiles = (this.state.invoiceList[i].additionalFiles!==undefined && this.state.invoiceList[i].additionalFiles)?this.state.invoiceList[i].additionalFiles:[];

            let individualRow = this.state.invoiceList[i];
            

            individualRow.elements!==undefined && individualRow.elements && Object.keys(individualRow.elements).map((ee,ii)=>{
           
                //construct each row's elements together
                let cellElemObj = individualRow.elements[ii];
                // console.log("***** i=>>>>",i,"ii=>>>",ii," cellElemObj=>>>",this.state.invoiceListM[i].elements[ii]);
                
                let columnAr  = [];
                let columnObj = <></>;
                

                    if(cellElemObj.keyName=="checkboxExtremeLeft"){
                            
                            columnObj = (
                                <td>
                                <div className="table_chk_box">
                                    <label className="invh_chkbox_sec">
                                    <input type="checkbox"  />
                                    <span className="checkmark" />
                                    </label>
                                </div>
                                </td>
                            );
                        
                            columnAr.push(columnObj);

                        }
                    if(cellElemObj.keyName=="generatedDate"){
                        columnObj = (
                            <td>
                            <div className="invH_td_dataBlock">
                                {/* <p>02/Oct/2020</p> */}
                                <p>{cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?CustDate.custFormatDate((new Date(cellElemObj.keyValueEditted)),8):"NA"}</p>

                            </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }
                    if(cellElemObj.keyName=="clientDetails"){
                        columnObj = (
                            <td>
                            <div className="invH_td_dataBlock">
                            <div className="block-ellipsis invH_td_dataBlock_maxChar">
                            <p>
                                {(cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null && cellElemObj.keyValueEditted.name!==undefined && cellElemObj.keyValueEditted.name)?((cellElemObj.keyValueEditted.name).length>36?(cellElemObj.keyValueEditted.name).substring(0,35)+"...":cellElemObj.keyValueEditted.name):""} 
                            </p>
                            </div>
                        </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }

                    if(cellElemObj.keyName=="matterDetails"){
                        columnObj = (
                            <td>
                                {((this.state.view==1) || (this.state.view==2 && !(openToEdit))) && (
                                <div className="invH_td_dataBlock">
                            
                                    <div className="block-ellipsis invH_td_dataBlock_maxChar">
                                    <p>
                                        {(cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null && cellElemObj.keyValueEditted.name!==undefined && cellElemObj.keyValueEditted.name)?cellElemObj.keyValueEditted.name:""} 
                                    </p>
                                    </div>
                                </div>
                                )}
                                {(this.state.view==2 && (openToEdit)) && (
                                <div className="invH_td_dataEditBlock">
                                    <div className="invH_td_editblock">
                                    <div className="form__group field ">
                                    <textarea onChange={(evn)=>{evn.preventDefault();
                                    this.trackChangeInRow({"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view,"keyName":"matterDetails","keyValueEditted":evn.target.value,"elementIndex":ii});
                                    
                                    }} maxLength="50" value={(cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null && cellElemObj.keyValueEditted.name!==undefined && cellElemObj.keyValueEditted.name)?cellElemObj.keyValueEditted.name:""}  placeholder="Enter"  />
                                    <span className="error_span"></span>
                                    </div>
                                    </div>
                                </div>
                                )}
                        </td>
                        );

                        columnAr.push(columnObj);

                    }
                    
                    if(cellElemObj.keyName=="amount" && cellElemObj.visibleStatus!==false  ){
                        columnObj = (
                            <td>
                        <div className="invH_td_dataBlock">
                            <div className="invH_td_dataBlock_maxChar">
                            <p>INR {cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted?cellElemObj.keyValueEditted:0}</p>
                        
                            </div>
                        </div>
                        </td>
                        );

                        columnAr.push(columnObj);

                    }
                    
                    if(cellElemObj.keyName=="isPaid"){
                        let isPaidData =  cellElemObj.keyValueEditted?"PAID": "UNPAID";
                        columnObj = (
                            <td>
                             {!(openToEdit) && (  
                                <div className="invH_td_dataBlock">
                                {(isPaidData==="PAID") && (
                                    <div className="invH_td_contTag invH_td_contTag_green">
                                    <p>Paid</p>
                                    </div>
                                )}
            
                                {(isPaidData!=="PAID") && (
                                    <div className="invH_td_contTag invH_td_contTag_red">
                                    <p>Unpaid</p>
                                    </div>
                                )}
                            
                                
                            </div>
                             )}
                              {(openToEdit) && (  
                                <div className="invH_td_dataEditBlock">
                                    <div className="table_drop_box tableTD_drop_box">
                                        <div className="form__group field">
                                            <select onChange={(evn)=>{evn.preventDefault();
                                    this.trackChangeInRow({"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view,"keyName":"isPaid","keyValueEditted":evn.target.value,"elementIndex":ii});
                                    
                                    }} value={isPaidData} className="customSelect form__field">
                                            
                                            {Object.keys(paidStatusOptForRow).map((e,i)=> <option key={i}  value={paidStatusOptForRow[e].key}>{paidStatusOptForRow[e].value}</option>) }
                                       
                                            </select>
                                        <span className="error_span"></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            
                            </td>
                        );

                        columnAr.push(columnObj);

                    }
                    if(cellElemObj.keyName=="dueDate"){
                        let dueDateData = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null? cellElemObj.keyValueEditted : null;                        

                        columnObj = (
                            <td>
                            {!(openToEdit) && (    
                            <div className="invH_td_dataBlock">
                            
                                <p>{cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?CustDate.custFormatDate((new Date(cellElemObj.keyValueEditted)),8):"NA"}</p>
                            </div>
                            )}
                            {(openToEdit) && (   
                            <div className="invH_td_dataEditBlock">
                                <div className="invH_td_date_grp">
                                    {/* form_group_error */}
                                    <div className="form__group field ">
                                        <div className="input-group date" >
                                        {/* <input type="text" className="form__field customdatefld" placeholder="Start Date" /> */}
                                        {this.customDueDate(dueDateData,("dueDateKey"+i),"DD/MM/YYYY","dueDate",("dueDate"+i),{"forTrackChange":{"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view,"keyName":"dueDate","keyValueEditted":null,"elementIndex":ii}})}
                                        </div>
                                    <span className="error_span"></span>
                                    </div>
                                </div>
                            </div>
                            )}

                            </td>
                        );

                        columnAr.push(columnObj);

                    }

                    if(cellElemObj.keyName=="paidDate"){

                        let paidDateData = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null? cellElemObj.keyValueEditted : null;                        

                        columnObj = (
                            <td>
                               

                            {!(openToEdit) && (    
                                <div className="invH_td_dataBlock">                            
                                    <p>{cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?CustDate.custFormatDate((new Date(cellElemObj.keyValueEditted)),8):"NA"}
                                    </p>
                                </div>
                            )}
                            {(openToEdit) && (   
                                <div className="invH_td_dataEditBlock">
                                    <div className="invH_td_date_grp">
                                         {/* form_group_error */}
                                        <div className="form__group field ">
                                            <div className="input-group date" >
                                            {/* <input type="text" className="form__field customdatefld" placeholder="Start Date" /> */}
                                            {this.customPaidDate(paidDateData,("paidDateKey"+i),"DD/MM/YYYY","paidDate",("paidDate"+i),{"forTrackChange":{"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view,"keyName":"paidDate","keyValueEditted":null,"elementIndex":ii}})}
                                            </div>
                                        <span className="error_span"></span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            </td>
                        );

                        columnAr.push(columnObj);

                    }
                    if(cellElemObj.keyName=="additionalFiles"){
                        let addiFileCount = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted?cellElemObj.keyValueEditted.length:0;
                        columnObj = (
                            <td>
                        <div className="invH_td_greenBtn_block">
                            <div className="invH_td_greenTxt">
                            {addiFileCount}
                            </div>
                            <div className="invH_td_greenBtn">
                            <button onClick={(evn)=>{evn.preventDefault();
                                    this.triggerDownloadAdditionalAttachments({"invoiceId":invoiceId});
                                    
                                    }}  className={`btn_appearance_none invH_td_greenBtnImg ${(addiFileCount==0)?"not_clickable":""}`}>
                                <img src={getBaseUrl()+"/assets/images/icon-cloud-download-green.png"}  />
                            </button>
                            </div>
                        </div>
                        </td>
                        );

                        columnAr.push(columnObj);

                    }
                    if(cellElemObj.keyName=="invoiceNumber"){
                        columnObj = (
                            <td>
                                <div className="invH_td_greenBtn_block">
                                <div className="invH_td_greenTxt">
                                {cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted?cellElemObj.keyValueEditted:""}
                                </div>
                                <div className="invH_td_greenBtn">
                                <button className="btn_appearance_none invH_td_greenBtnImg" type="button" data-toggle="dropdown">
                                    <img src={getBaseUrl()+"/assets/images/icon-cloud-reload-green.png"}  />
                                </button>
                                <div className="dropdown-menu invH_greenBtn_dropMenu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                    <li>
                                        <InvoicePdfViewOnPopup calledfrom="manageinvoicehistory" customclassname={"dropdown-item"} controltype={"ANCHOR"} keydata={"invhistoryvpdf"+i} labeltext={"View Invoice"} data={{"invoiceId":invoiceId,"invoicePdfBase64":invoicePdfBase64}} />
                                        {/* <a className="dropdown-item" href="">View Invoice</a> */}
                                    </li>
                                    <li><a  onClick={(evn)=>{evn.preventDefault();
                                    this.triggerInvoiceDownload({"invoiceId":invoiceId});
                                    
                                    }} className="dropdown-item" href="">Download Invoice</a></li>
                                    <li>
                                        <InvoiceSharePopup calledfrom="manageinvoicehistory" customclassname={"dropdown-item"} controltype={"ANCHOR"} keydata={"invhistoryshareinvpdf"+i} labeltext={"Share Invoice"} otherdata={{"invoiceId":invoiceId,"attachedFiles":additionalFiles,"subject":lpName+" has shared an invoice","body":""}} callbackcustomfunc={()=>{}}  />
                                        {/* <a className="dropdown-item" href="">Share Invoice</a> */}
                                    </li>
                                    <li>
                                        {/* <button className="btn_appearance_none invH_greenBtn_dropMenu_liBtn">Payment</button> */}
                                        <RequestPaymentPopup calledfrom="manageinvoicehistory" customclassname={"btn_appearance_none invH_greenBtn_dropMenu_liBtn"} controltype={"BUTTON"} keydata={"invhistoryshareinvpdf"+i} labeltext={"Request for Payment"} otherdata={{"invoiceId":invoiceId,"attachedFiles":additionalFiles,"subject":lpName+" has sent a payment request","body":""}} callbackcustomfunc={()=>{}}  />
                                    </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }

                    if(cellElemObj.keyName=="notes" && cellElemObj.visibleStatus===true){
                        columnObj = (
                            <td>
                            <div className="invH_td_dataBlock">
                            <div className="block-ellipsis invH_td_dataBlock_maxChar">
                            <p>
                                {(cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null )?cellElemObj.keyValueEditted:""} 
                            </p>
                            </div>
                        </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }
                    if(cellElemObj.keyName=="id" && cellElemObj.visibleStatus===true){
                        columnObj = (
                            <td>
                            <div className="invH_td_dataBlock">
                            <div className="block-ellipsis invH_td_dataBlock_maxChar">
                            <p>
                                {(cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null )?cellElemObj.keyValueEditted:""} 
                            </p>
                            </div>
                        </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }

                    if(cellElemObj.keyName=="actions"){
                        columnObj = (
                            <td className="invH_lastTD_action">
                            <div className="invh_last_td">
                                <div className="invh_last_td_left">
                                {(openToEdit) && (
                                    <div className="invH_actionTd invH_actionTd_edit">
                                        <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.callToSave({"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view,"bulkOrSingleUpdate":"SINGLE"});

                                        }} className={`btn_appearance_none ${editSaveInProgress?"disabled_section":""}`}>
                                        
                                        {(editSaveInProgress) && (
                                            <img class={"invhisroweditprogressimgclass"} src={getBaseUrl()+"/assets/images/processing.gif"}  />
                                        ) }
                                        {!(editSaveInProgress) && ( <>
                                           <img src={getBaseUrl()+"/assets/images/icon-save-green.png"}  />
                                           <span>Save</span>
                                           </>
                                        ) }
                                        
                                        </button>
                                    </div>
                                )}
                                {!(openToEdit) && (
                                    <div className="invH_actionTd invH_actionTd_edit">
                                        <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.callOpenToEdit({"individualRow":individualRow,"individualRowIndex":i,"view":this.state.view});
                                            
                                        }} className="btn_appearance_none">

                                        <img src={getBaseUrl()+"/assets/images/icon-edit-pencil.png"}  />

                                        
                                        
                                        </button>
                                    </div>
                                )}
                                </div>
                                <div className="invh_last_td_right">
                                <div className="invH_actionTd invH_actionTd_edit">
                                    <button  className="btn_appearance_none invH_actionTdBtn_dot3 not_clickable" type="button" data-toggle="dropdown">
                                    <i className="fa fa-ellipsis-h" aria-hidden="true" />
                                    </button>
                                    <div className="dropdown-menu invH_dotBtn_dropMenu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        <li><a className="dropdown-item" href="#">test</a></li>
                                        <li><a className="dropdown-item" href="#">test</a></li>
                                        <li><button className="btn_appearance_none">Payment</button></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="clearboth" />
                            </div>
                            </td>
                        );

                        columnAr.push(columnObj);

                    }
            
                
                    rowObjAr.push(columnAr);
                

                
           

             });

             listAr.push(<tr>{rowObjAr}</tr>);

        });

    return (<>{listAr}</>);

        


    }

    callOpenToEdit = (other) =>{

        //console.log(" from callOpenToEdit other=>>>>>",other);

        let individualRow = other.individualRow;
        let individualRowIndex = other.individualRowIndex;
        //let view = other.view;

        let invoiceList = [...this.state.invoiceList];       

        individualRow.openToEdit = true;
        invoiceList[individualRowIndex] = individualRow;

        this.isComponentLoaded && this.setState({"invoiceList":invoiceList});
    }

    showEditSaveInProgress = (other) =>{

        //console.log(" from callOpenToEdit other=>>>>>",other);

        let individualRow = other.individualRow;
        let individualRowIndex = other.individualRowIndex;
        //let view = other.view;

        let invoiceList = [...this.state.invoiceList];       

        individualRow.editSaveInProgress = true;
        invoiceList[individualRowIndex] = individualRow;

        this.isComponentLoaded && this.setState({"invoiceList":invoiceList});
    }

    hideEditSaveInProgress = (other) =>{

        //console.log(" from callOpenToEdit other=>>>>>",other);

        let individualRow = other.individualRow;
        let individualRowIndex = other.individualRowIndex;
        //let view = other.view;

        let invoiceList = [...this.state.invoiceList];       

        
        individualRow.editSaveInProgress = false;
        invoiceList[individualRowIndex] = individualRow;

        this.isComponentLoaded && this.setState({"invoiceList":invoiceList},()=>{

            this.bringBackToPenIconForSingleRow({                        
                "individualRow":individualRow,
                "individualRowIndex":individualRowIndex,
            });
          
        });
    }

    callToSave = (other) =>{
        //console.log(" from callOpenToEdit other=>>>>>",other);
        
        let view = other.view;
        let bulkOrSingleUpdate = other.bulkOrSingleUpdate;
        

        if(bulkOrSingleUpdate=="SINGLE"){

            let individualRow = other.individualRow;
            let individualRowIndex = other.individualRowIndex;

            let otherParamSingle = [{...individualRow}]
            let chkValidate =  this.custValidate(otherParamSingle);
            if(chkValidate){
                let arrangeDataToSave = this.getDataArrangedToSave({"data":otherParamSingle});
                console.log(" arrangeDataToSave=>>",arrangeDataToSave);

                this.showEditSaveInProgress({                        
                    "individualRow":individualRow,
                    "individualRowIndex":individualRowIndex,
                });    

                setTimeout(()=>{
                    this.callSaveEdit({"postData":arrangeDataToSave,...other});
                },1500);
                

            }

        }else{

        }


       
    }

    trackChangeInRow = (other) =>{
        console.log(" from callOpenToEdit other=>>>>>",other);
        //return false;
        let individualRow = other.individualRow;
        let individualRowIndex = other.individualRowIndex;
        let elementIndex = other.elementIndex;
        let keyName = other.keyName;
        let keyValueEditted = other.keyValueEditted;

        let view = other.view;
        let invoiceList = [...this.state.invoiceList];       

        let elements =  [...individualRow.elements]; // all elements in that row
        let elementIndexObj = {...elements[elementIndex]}; // specfic element of that row
        
        console.log(" from callOpenToEdit elementIndexObj.keyName=>>>>>",elementIndexObj.keyName, " keyName=>>",keyName);
        if(elementIndexObj.keyName == keyName ){

            if(keyName == "matterDetails"){
                
                let matterDetails = {};
                let sourceKeyValueToModify = elementIndexObj["keyValueEditted"];
                if(sourceKeyValueToModify){
                    matterDetails = {...sourceKeyValueToModify};
                    matterDetails["name"] = keyValueEditted;
                }else{
                     matterDetails = {
                        "clientId": null,
                        "description": null,
                        "matterId": null,
                        "name": keyValueEditted,
                        "newName": null} ;
                }
                elementIndexObj["keyValueEditted"] = matterDetails; // set data of specfic element
            }
            else if(keyName == "dueDate"){
               
                elementIndexObj["keyValueEditted"] = keyValueEditted; // set data of specfic element
                
            }            
            else if(keyName == "paidDate"){                
               
                elementIndexObj["keyValueEditted"] = keyValueEditted; // set data of specfic element
                
            }
            else if(keyName == "isPaid"){                
               
                elementIndexObj["keyValueEditted"] = (keyValueEditted=="PAID")?true:false; // set data of specfic element
                
            }
            elements[elementIndex] = elementIndexObj;
            individualRow["elements"] = elements;               
            invoiceList[individualRowIndex] = individualRow;               
            this.isComponentLoaded && this.setState({"invoiceList":invoiceList});
           
        }

        



        
    }

    custValidate = (otherParam) => {

        return true;

    }

    getDataArrangedToSave(otherParam) {
       

        let rowsArrangedToPost = [];

        let dataToIterrate = otherParam.data!==undefined && otherParam.data?otherParam.data:[];

        Object.keys(dataToIterrate).map((e,i)=>{
            let postDataParam = {
                "id":null,
                "matterName":null,
                "dueDate":null,
                "paidDate":null,
                "isPaid":false,
                "notes":null
            };

            let elementToIterrate = dataToIterrate[i].elements;
            Object.keys(elementToIterrate).map((ee,ii)=>{

                   
                    let cellElemObj = elementToIterrate[ii];
                    if(cellElemObj.keyName=="id"){
                         postDataParam["id"] = cellElemObj.keyValueEditted;
                    }

                    if(cellElemObj.keyName=="matterDetails"){
                        postDataParam["matterName"] = (cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null && cellElemObj.keyValueEditted.name!==undefined && cellElemObj.keyValueEditted.name)?cellElemObj.keyValueEditted.name:"";
                    }

                    if(cellElemObj.keyName=="dueDate"){
                    postDataParam["dueDate"] = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?cellElemObj.keyValueEditted:null;
                    }

                    if(cellElemObj.keyName=="paidDate"){
                        postDataParam["paidDate"] = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?cellElemObj.keyValueEditted:null;
                    }

                    if(cellElemObj.keyName=="isPaid"){
                        postDataParam["isPaid"] = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?cellElemObj.keyValueEditted:false;
                    }

                    if(cellElemObj.keyName=="notes"){
                        postDataParam["notes"] = cellElemObj.keyValueEditted!==undefined && cellElemObj.keyValueEditted!==null?cellElemObj.keyValueEditted:null;
                    }


                   




            
            });

            rowsArrangedToPost.push(postDataParam);

        });

       

        return rowsArrangedToPost;

    }

    handlePageClick = (data) => {
        let pageNum = data.selected + 1;
        
        this.isComponentLoaded && this.setState({"pageNum":pageNum,"invoiceList":[]},()=>{
            this.showLoader();

            let serviceParam = {};
            serviceParam["data"]={
                                    "view":this.state.view,
                                    "pageNum":pageNum,
                                    "searchText":this.state.searchText, 
                                    "searchTextPre":this.state.searchTextPre,                                   
                                    "paidStatus":this.state.paidStatus,
                                    "sortOn":this.state.sortOn,
                                    "sortDir":this.state.sortDir,
                                    
                                    } ;
                                    
            serviceParam["calledFrom"]=   "handlePageClick";                      
            this.fetchInvList(serviceParam);

        });
     
     
       };
    

    loadPagination = () =>{
        return (<div className="invH_pagination_sec">
        {/* <ul className="pagination">
            <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
            <li className="page-item "><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link " href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">Next</a></li>
        </ul> */}
         <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.totalNumberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    forcePage={(this.state.pageNum-1)}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={''}
                                    activeClassName={'active'}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}

                                    />
        </div>);
    }

    handleChange = (keyName,keyValue,otherParam) => {
            this.isComponentLoaded && this.setState({[keyName]:keyValue},()=>{

                    if(keyName=="paidStatus"){
                            this.callPaidSearchService({});
                    }
                    else if(keyName=="view"){
                        this.loadInvHistoryFromBeginning({});
                    }


            });

    }

    callPaidSearchService = (otherParam) =>{
        // if(!(this.state.paidStatus)){
        //     return false;
        // }

        let pageNum =  1;
        
        this.isComponentLoaded && this.setState({
            "pageNum":pageNum,
            "invoiceList":[],
            "sortOn":"",
            "sortDir":""
        },()=>{
            this.showLoader();

            let serviceParam = {};
            serviceParam["data"]={
                            "view":this.state.view,
                            "pageNum":pageNum,
                            "searchText": this.state.searchText,
                            "searchTextPre": this.state.searchTextPre,
                            "sortOn":this.state.sortOn,
                            "sortDir":this.state.sortDir,
                            "paidStatus":this.state.paidStatus
                                    
                                    } ;
            serviceParam["calledFrom"]=   "callPaidSearchService";   
            this.fetchInvList(serviceParam);

        });
    }

    callSearchService = (otherParam) =>{
        if(!(this.state.searchTextPre)){
            return false;
        }

        let searchText = this.state.searchTextPre;

        let pageNum =  1;
        
        this.isComponentLoaded && this.setState({
            "pageNum":pageNum,
            "searchText":searchText,
            "invoiceList":[],
            "sortOn":"",
            "sortDir":""},()=>{
            this.showLoader();

            let serviceParam = {};
            serviceParam["data"]={
                                    "view":this.state.view,
                                    "pageNum":pageNum,
                                    "searchText": this.state.searchText,
                                    "sortOn":this.state.sortOn,
                                    "sortDir":this.state.sortDir,
                                    "paidStatus":this.state.paidStatus
                                    
                                    } ;
            serviceParam["calledFrom"]=   "callSearchService";   
            this.fetchInvList(serviceParam);

        });
    }

    resetSearch = () =>{
        let pageNum =  1;

        // if(!(this.state.searchText)){
        //     return false;
        // }
        
        this.isComponentLoaded && this.setState({"pageNum":pageNum,"invoiceList":[],"searchText":"","searchTextPre":"","paidStatus":"",
        "sortOn":"",
        "sortDir":""},()=>{
            this.showLoader();

            let serviceParam = {};
            serviceParam["data"]={
                                    "view":this.state.view,
                                    "pageNum":pageNum,
                                    "searchText": this.state.searchText,  
                                    "paidStatus":this.state.paidStatus,
                                    "sortOn":this.state.sortOn,
                                    "sortDir":this.state.sortDir,
                                    
                                    } ;
            serviceParam["calledFrom"] =    "resetSearch";                    
            this.fetchInvList(serviceParam);

        });
    }

    setSorting = (sortObj) =>{
        let sortOn = sortObj.sortOn!==undefined?sortObj.sortOn:"";
        let sortDir ="";

        if(sortOn==this.state.sortOn){
            sortDir = this.state.sortDir && this.state.sortDir=="DESC"?"ASC":"DESC";
        }else{
            sortDir = "DESC";
        }

        this.callForSorting({"sortOn":sortOn,"sortDir":sortDir});
    }

    callForSorting = (otherParam) =>{
        let sortOn = otherParam.sortOn!==undefined?otherParam.sortOn:"";
        let sortDir = otherParam.sortDir!==undefined?otherParam.sortDir:"";
        if(!(sortOn)||!(sortDir)){
            return false;

        }

        let pageNum =  1;
        
        this.isComponentLoaded && this.setState({"pageNum":pageNum,"invoiceList":[], "sortOn":sortOn,
        "sortDir":sortDir},()=>{
            this.showLoader();

            let serviceParam = {};
            serviceParam["data"]={
                                    "view":this.state.view,
                                    "pageNum":pageNum,
                                    "searchText": this.state.searchText,
                                    "sortOn":sortOn,
                                    "sortDir":sortDir,
                                    "paidStatus":this.state.paidStatus,
                                    
                                    
                                    } ;
            serviceParam["calledFrom"]=   "callForSorting";   
            this.fetchInvList(serviceParam);

        });
    }

    triggerDownloadTableFeature = () =>{
        let downloadTableUrl =  this.downloadTableUrl;
        let accToken = getTokenFromLocStorage();   
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;
        const defaultPageSize = getMaxPageSize();

        let pageNum = this.state.pageNum;
        let pageSize = defaultPageSize;
        
        let view = this.state.view;
        let paidStatus = this.state.paidStatus;
        let sortDir = this.state.sortDir;
        let sortOn = this.state.sortOn;

       

        downloadTableUrl = downloadTableUrl+"?view="+view+"&auth="+accToken;

        if(paidStatus){
            downloadTableUrl = downloadTableUrl+"&paid_status="+paidStatus;
        }

        if(sortOn && sortDir){
            downloadTableUrl = downloadTableUrl+"&sort_on="+sortOn+"&sort_dir="+sortDir;
        }
        

        downloadTableUrl = downloadTableUrl+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize;

        if(this.state.invoiceList.length>0){
            let winDwnObj = window.open(downloadTableUrl, '_blank');
            winDwnObj.focus(); 
        }
        


    }

    triggerDownloadAdditionalAttachments = (otherParam) =>{
        
        let downloadUrl =  this.downloadAdditionalAttachmentsUrl;
        let accToken = getTokenFromLocStorage();   
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;  
        
        let invoiceId = otherParam.invoiceId!==undefined?otherParam.invoiceId:0;
        

        downloadUrl = downloadUrl+"/"+invoiceId+"?offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&auth="+accToken;

        if(this.state.invoiceList.length>0){
            let winDwnObj = window.open(downloadUrl, '_blank');
            winDwnObj.focus(); 
        }  

    }

    triggerInvoiceDownload = (otherParam) =>{
        
        let downloadUrl =  this.downloadInvoiceUrl;
        let accToken = getTokenFromLocStorage();   
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;  
        
        let invoiceId = otherParam.invoiceId!==undefined?otherParam.invoiceId:0;
        

        downloadUrl = downloadUrl+"/"+invoiceId+"?offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&auth="+accToken;

        if(this.state.invoiceList.length>0){
            let winDwnObj = window.open(downloadUrl, '_blank');
            winDwnObj.focus(); 
        }  

    }

    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
    }
    showLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
    loadLoader() {
        return (
            <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }

   

    render() {
        return (
            <>  
                <CustomMessageBar />            
                {/* Dashboard Main Screen content area 01 start */}
                <ManageInvoiceGeneratorGenericHeader activetabname="newinvoicehistorytab" />
                {/* Dashboard Main Screen content area 01 end */}
                {/* Dashboard Main Screen content area 02 start */}
                <div className="kyc_section_part_02">
                    <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section InvHis_main_bg">
                        {/* ===  DashBoard Main Body Start ===  */}
                        <div className="cutom_container cutom_container_userSetting cutom_container_inHisBg">
                        {/* ===========  Setting Page main body start =============   */}
                        {/* === User Setting Section /** / Invoice History Section Start === */}
                        {/* ============== Start =================   */}
                        {/* -----  scroll adjust for settting screens start (Invoice History Page) -----   */}
                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting invHis_fromScroll_sec">
                            {/* ==============   User Setting Section (InvHis) Start =================   */}
                            <div className="userSetting_area_bg userSetting_area_bg_invHis">
                            {/* ==-*-== Invoice History filter & Block section start ==-*-== */}
                            <div className="invHis_top_filter_block_bg">
                                {/* ==== Invoice History filter section end ==== */}
                                <div className="invHis_topFilter_sec">
                                {/* -- invH top filter left start --  */}
                                <div className="invHis_topFil_left">
                                    <div className="invH_filter_box_bg">
                                    <div className="form__group field ">
                                        <select name="view" value={this.state.view} onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("view",evn.target.value,{});

                                        }} className="customSelect form__field">
                                        {Object.keys(manageInvHistoryMainFilterOptions).map((e,i)=> <option key={i}  value={manageInvHistoryMainFilterOptions[e].key}>{manageInvHistoryMainFilterOptions[e].value}</option>) }
                                        </select>
                                        <span className="error_span"></span>
                                    </div>
                                    </div>
                                </div>
                                {/* -- invH top filter left end --  */}
                                {/* -- invH top filter right start --  */}
                                {/* -- invH top filter right start --  */}
                                <div className="clearboth" />
                                </div>
                                {/* ==== Invoice History filter section end ==== */}
                            </div>
                            {/* ==== Invoice History Number Block section start ==== */}
                            <div className="invHis_blockSec">
                                <div className="invHis_block_row">
                                <div className="invHis_block_box invHis_block_box_1">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <span>Total amount billed</span>
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                        <h5><sup>INR</sup> {this.state.aggregateAmountBilled?(parseFloat(this.state.aggregateAmountBilled)).toFixed(2):0}</h5>
                                    </div>
                                </div>
                                <div className="invHis_block_box invHis_block_box_2">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <img src={getBaseUrl()+"assets/images/icon-red-flag.png"} className="invh_bimg" alt />
                                        <span>Total amount due</span>
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                    <h5><sup>INR</sup> 
                                    {this.state.aggregateAmountDue?(parseFloat(this.state.aggregateAmountDue)).toFixed(2):0}</h5>
                                    </div>
                                </div>
                                <div className="invHis_block_box invHis_block_box_3">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <span>{this.state.view==2?"No. of clients":"No. of organizations"}</span>
                                        
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                    <h5>{this.state.totalNumberOfClients}</h5>
                                    </div>
                                </div>
                                <div className="invHis_block_box invHis_block_box_4">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <span>No. of invoices raised</span>
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                    <h5>{this.state.totalInvoicesRaised}</h5>
                                    </div>
                                </div>
                                <div className="invHis_block_box invHis_block_box_5">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <span>No. of invoices Paid</span>
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                    <h5>{this.state.totalInvoicesPaid}</h5>
                                    </div>
                                </div>
                                <div className="invHis_block_box invHis_block_box_6">
                                    <div className="invH_blockBox_top">
                                    <p>
                                        <img src={getBaseUrl()+"assets/images/icon-red-flag.png"} className="invh_bimg" alt />
                                        <span>No. of invoices Due</span>
                                    </p>
                                    </div>
                                    <div className="InvH_blockBox_bottom">
                                    <h5>{this.state.totalInvoicesDue}</h5>
                                    </div>
                                </div>
                                <div className="clearboth" />
                                </div>
                            </div>
                            {/* ==== Invoice History Number Block section end ==== */}
                            {/* ==-*-== Invoice History filter & Block section end ==-*-== */}
                            {/* ===== ** Invoice history main table body start =====  */}
                            <div className="invHis_mainTable_section_bg">
                                <div className="invHis_mainTable_box">
                                {/* ---- Inv His Responsive Table section start ---  */}
                                {/* == InvH table top sec start ==  */}
                                <div className="invh_tableTop_sec">
                                    {/* invh_tableTop_left start */}
                                    <div className="invh_tableTop_left">
                                    <div className="invh_tableTop_leftLabel">
                                        <label>Invoice List</label>
                                    </div>
                                    <div className="invh_tableTop_left_group">
                                        <div className="form__group field ">
                                        <div className="input-group input_group_search">
                                            <input type="text" className="form-control" placeholder="Search for client, matter name or invoice no." onChange={(evn)=>{
                                                evn.preventDefault();
                                                this.handleChange("searchTextPre",evn.target.value,{});
                                            }} 
                                                value={this.state.searchTextPre}
                                                />
                                            <div className="input-group-append">
                                            <button onClick={(evn)=>{
                                                    evn.preventDefault();
                                                    this.callSearchService({});

                                            }} className="btn btn-secondary btn_appearance_none inputGroup_searchBtn" type="button">
                                                <i className="fa fa-search" />
                                            </button>
                                            <button onClick={(evn)=>{
                                                evn.preventDefault();
                                                this.resetSearch();
                                            }} className="btn btn-secondary btn_appearance_none inputGroup_searchResetBtn" type="reset">
                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                            </div>
                                        </div>
                                        <span className="error_span"></span>
                                        </div>
                                    </div>
                                    <div className="clearboth" />
                                    </div>
                                    {/* invh_tableTop_left end */}
                                    {/* invh_tableTop_right start */}
                                    <div className="invh_tableTop_right">
                                    <div className="invh_tableTop_right_box">
                                        <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.triggerDownloadTableFeature();
                                        }} className="btn_appearance_none green_btn"><span>Download Table</span></button>
                                    </div>
                                    </div>
                                    {/* invh_tableTop_right end */}
                                    <div className="clearboth" />
                                </div>
                                {/* == InvH table top sec end ==  */}
                                {/* == InvH table body start ==  */}
                                <div className="invH_table_box invH_dataTable_bg">
                                    <div className="table-responsive">
                                    {/* ====   Table start  ====   */}
                                    <table id="example" className="table table-bordered invH_table" style={{width: '100%'}}>
                                        <thead>
                                            {this.loadTableHeadRow()}
                                        </thead>
                                        <tbody>
                                        {/* TR Start all filed */}
                                        {this.loadListRow()}
                                        {/* TR End all filed */}
                                       
                                        </tbody>
                                    </table>
                                    {/* ====   Table end  ====   */}
                                    </div>
                                    {/* ====== No Data & Loader DIV Start ======   */}
                                    <div className="table_nodataLoader_bg">
                                    <div className="invH_loader"> 
                                    {this.state.loaderFlag ? this.loadLoader() : null}
                                    </div>
                                    {!(this.state.loaderFlag) && (this.state.invoiceList.length==0) && (
                                        <div className="invH_nodata">
                                        <h3>No data found</h3>
                                    </div>

                                    )}
                                    
                                    </div>
                                    {/* ====== No Data & Loader DIV End ======   */}
                                    {/* ====== InvH Pagination DIV Start ======   */}
                                    {((this.state.totalNumberOfPages!==null) && this.state.totalNumberOfPages>1) && this.loadPagination()}
                                   
                                    {/* ====== InvH Pagination DIV End ======   */}
                                </div>
                                {/* == InvH table body start ==  */}
                                {/* ---- Inv His Responsive Table section end ---  */}
                                </div>
                            </div>
                            {/* ===== ** Invoice history main table body end =====  */}
                            </div>
                            {/* ==============   User Setting Section (InvHis) end =================   */}
                        </div>
                        {/* -----  scroll adjust for settting screens end  (Invoice History Page) -----   */}
                        {/* ============== end =================   */}
                        {/* === User Setting Section /** / Invoice History Section end  === */}
                        {/* ===========  Setting Page main body end =============   */}
                        </div>
                        {/* ===  DashBoard Main Body End ===  */}
                    </div>
                    {/* ===  DashBoard Main Body Footer Start ===  */}
                    {/* User Setting footer strat */}
                    {/* *****  Put as Dispaly: None ***** */}
                    <div className="task_details_btn_area invoPay_footer kyc_footer invH_footer" style={{display: 'none'}}>
                            <div className="btn_fld">
                            <div className="panel50">
                                <button className="btn_appearance_none green_btn"><span>Save Progress</span></button>
                            </div>
                            <div className="panel50">
                                <div className="cancel_continue_btn">
                                <button type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Reset</span></button>
                                <button className="btn_appearance_none sub_grd_btn"><span>Preview</span></button>
                                </div>
                            </div>
                            <div className="clearboth" />
                            </div>
                    </div>
                    {/* *****  Put as Dispaly: None ***** */}
                    {/* User Setting footer end */}
                    {/* ===  DashBoard Main Body Footer end ===  */}
                </div>


                {/* Dashboard Main Screen content area 02 end */}

                


            </>
        )
    }
}

export default ManageInvoiceHistory
