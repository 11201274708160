import React, { Component } from 'react'
import {Link, NavLink, withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';

// for context
import CommonContext from '../../context/CommonContext';
// For loading CustLoader
import CustLoader from './CustLoader';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import {commonnotitrack} from "./NotiEmitTrack";

const getBaseUrl = commonJsObj.getBaseUrl;
const getSubscriptionPaybleAmount = commonJsObj.getSubscriptionPaybleAmount;
const getSubscriptionPaymentLink = commonJsObj.getSubscriptionPaymentLink;
const getSubscriptionOriginalAmount = commonJsObj.getSubscriptionOriginalAmount;
const getFormattedAmount = commonJsObj.getFormattedAmount;

export class SubscriptionPayment extends Component {
    static contextType = CommonContext;
    isComponentLoaded = false;
    constructor(props) {
        super(props)

        this.state = {
        }


    }
    componentDidMount(){
        commonnotitrack("SCROLL_TO_MAIN_HEADER",{
            "notiType": "SCROLL_TO_MAIN_HEADER",
            "triggerCallback":true,
            "trigerredFrom":"SUBSCRIPTION_PAYMENT_FLOW"
           });
        console.log(getSubscriptionPaybleAmount());
        console.log(getSubscriptionPaymentLink());
        console.log(getSubscriptionOriginalAmount());
    }
    loadLoader() {
        return (
            <CustLoader classNameNameName={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }

    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    showLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
    render() {
        return (
            <>
                <div className="wrapper_bg">
                    {/* ==== *********  Dashboard Left / Full Section start *********====  */}
                    <div className="wrapper">
                    {/* ==**== Payment Method screen start ==**==   */}
                        <div className="payment_method_PagrBg">
                            {/* Invoice Payment task header start */}
                            <div className="task_header invoPay_header task_header_withTopHeader">
                                <div className="taskstep_back_bg invoPay_backBg">
                                    <NavLink to="/subscription">
                                        <button><img className="back_img_icon" src="assets/images/path-back-white.png"/>Back</button>
                                    </NavLink>
                                    <h4><span>Subscription Level 02</span></h4>
                                </div>
                            </div>
                            {/* Invoice Payment task header end */}
                            {/* Invoice Payment alert section start */}
                            <div className="alert_secction invoiceAleart_section">
                                {/* <div className="alert alert-dismissible alert_custom alert-success">
                                    <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                                <div className="alert alert-dismissible alert_custom alert-danger">
                                    <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                    <p>Oops!! An error has occured</p>
                                </div> */}
                            </div>
                            {/* Invoice Payment alert section start */}

                            {/* Invoice Payment main body start */}
                            {/* task body area start */}
                            <div className="invoPay_mainBody_bg">
                                <div className="cutom_container cutom_container_withTopHeader">

                                    {/* Invoice Payment method Body strat */}
                                    <div className="invoPay_main_Section min_height_700">
                                        <div className="invoPay_mainBody">
                                            {/* == Payment Method left side bar start == */}
                                                <div className="invoPay_left_bg">
                                                    <div className="invoPay_left_nav">
                                                        {/* == left navigation start ==  */}
                                                        <nav className="navbar navbar-expand-lg navbar-light invo_navbar">
                                                            <div className="pay_side_heading">
                                                                <h3 className="navbar-brand" href="#">Choose payment method</h3>
                                                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                                <span className="navbar-toggler-icon"></span>
                                                                </button>
                                                            </div>
                                                        
                                                            <div className="collapse navbar-collapse invoPay_navbar" id="navbarSupportedContent">
                                                                <ul className="navbar-nav">
                                                                    <li className="nav-item active">
                                                                        <a className="nav-link" href="#">
                                                                            <span className="pay_li_text">Make online payment</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link pointer_none" href="#">
                                                                            <span className="pay_li_text">Record offline payment</span>
                                                                            <span className="comming_soon">Coming soon</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </nav>
                                                        {/* == left navigation end ==  */}
                                                    </div>
                                                </div>
                                            {/* == Payment Method left side bar end == */}
                                            {/* == Payment Method right ssection start == */}
                                                <div className="invoPay_right_bg">
                                                    <div className="invoPay_right_section">
                                                        {/* -- pay details left start -- */}
                                                        <div className="pay_details_bg">
                                                            <div className="subscriptionPaymentDetails">
                                                                <div className="pay_det_sec pay_det_color">
                                                                    <div className="pay_det_sec_p">
                                                                        <p>Supports Net Banking, Online NEFT/RTGS/IMPS, Debit Cards, Credit Cards</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        {/* -- pay details left end --  */}
                                                        {/* -- pay details right start --  */}
                                                        <div className="payment_block">
                                                            <div className="payment_gray_box payment_gray_box_subscription">
                                                                <div className="pay_row_adjust">
                                                                    <div className="pay_row">
                                                                        <div className="pay_row_l">Subscription amount inclusive of GST</div>
                                                                        <div className="pay_row_r">INR {getFormattedAmount({"dataToFormat":getSubscriptionOriginalAmount(),"formatType":1})}</div>
                                                                        <div className="clearboth"></div>
                                                                    </div>
                                                                    <div className="pay_row">
                                                                        <div className="pay_row_l">Discount applied</div>
                                                                        <div className="pay_row_r"> {getFormattedAmount({"dataToFormat":parseInt(getSubscriptionOriginalAmount())- parseInt(getSubscriptionPaybleAmount()),"formatType":1})}</div>
                                                                        <div className="clearboth"></div>
                                                                    </div>
                                                                    {/* <div className="pay_row">
                                                                        <div className="pay_row_l">Dummy Text</div>
                                                                        <div className="pay_row_r">Dummy Text</div>
                                                                        <div className="clearboth"></div>
                                                                    </div> */}
                                                                </div>
                                                                <div className="pay_row_total">
                                                                    <div className="pay_row">
                                                                        <div className="pay_row_l">Total</div>
                                                                        <div className="pay_row_r">INR {getFormattedAmount({"dataToFormat":getSubscriptionPaybleAmount(),"formatType":1})}</div>
                                                                        <div className="clearboth"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="pay_row_button">
                                                                    <a href={getSubscriptionPaymentLink()} target="_blank"><button className="sub_grd_btn"><span>Complete Payment</span></button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* -- pay details right end --  */}
                                                        <div className="clearboth"></div>
                                                    </div>
                                                </div>
                                            {/* == Payment Method right ssection end == */}
                                            <div className="clearboth"></div>
                                        </div>
                                    </div>
                                    {/* Invoice Payment method Body end */}

                                    {/* Invoice Payment method footer strat */}
                                    <div className="task_details_btn_area invoPay_footer" style={{"display": "none"}}>
                                        <div className="btn_fld">
                                            <div className="panel50">
                                                <a href="#" className="btn_txt">Save as Draft</a>
                                                <button className="btn_appearance_none green_btn"><span>Edit</span></button>
                                            </div>
                                            <div className="panel50">
                                                <div className="cancel_continue_btn">
                                                    <a href="#" className="btn_txt">Cancel</a>
                                                    <button className="btn_appearance_none sub_grd_btn disabled_section"><span>Continue</span></button>
                                                </div>
                                            </div>
                                            <div className="clearboth"></div>
                                        </div>
                                    </div>
                                    {/* Invoice Payment method footer end */}

                                </div>
                            </div>
                            {/* task body area end */}
                            {/* Invoice Payment main body end */}
                        </div>
                    {/* ==**== Payment Method screen end ==**==   */}
                    </div>
                    {/* ==== *********  Dashboard Left / Full Section end *********====  */}
                </div>
            </>
        )
    }
}

export default SubscriptionPayment
