/**
 * BellNotificationList
 * Page/Class name   : BellNotificationList
 * Author            :
 * Created Date      : 3-7-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
 **/
import React, { Component, Suspense, lazy } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { withRouter } from "react-router-dom";
import "../../../custom_resources/css/custom.css";
import CustDate from "../../../common_utilities/CustDate";
import { FetchNotificationService } from "../../service/FetchNotificationService";
import { ReadNotificationService } from "../../service/ReadNotificationService";
import { DeleteNotificationService } from "../../service/DeleteNotificationService";
import { ReadAllNotificationService } from "../../service/ReadAllNotificationService";
import { DeleteAllNotificationService } from "../../service/DeleteAllNotificationService";

import TaskDetailsService from "../../service/TaskDetailsService";
// scroll
import { Scrollbars } from "react-custom-scrollbars";

// Services
import LogoutService from "../../service/LogoutService";
// for context
import CommonContext from "../../context/CommonContext";

// common noti track
//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import SockJS from "sockjs-client";

const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;
const setAccessObj = commonJsObj.setAccessObj;
const getCurrentUserId = commonJsObj.getCurrentUserId;
let sockUrl = "/ws?token=" + getTokenFromLocStorage();
let subscUrl1 = "/topic/notification." + getCurrentUserId();
var Stomp = require("stompjs");

class BellNotificationList extends Component {
  /**
   * BellNotificationList
   * Function name : constructor
   * Author        :
   * Created Date  :
   * Purpose       : initializing state
   * Params        : props
   **/

  isComponentLoaded = false;

  // for context
  static contextType = CommonContext;

  constructor(props) {
    super(props);
    this.state = {
      notiListOpenFlag: false,
      bellButtonFlag: false,

      pageNum: 1,
      notificationList: [],
      totalNumberOfPages: 1,
      totalUnreadNotificationCount: 0,
    };

    this.menuDropDownDivRef = React.createRef();
    this.menuDropDownDivOrigRef = React.createRef();
    this.scrollerRef = React.createRef();
  }

  /**
   * BellNotificationList
   * Function name : componentDidMount
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
    this.fetchNotifications();
    sockUrl = "/ws?token=" + getTokenFromLocStorage();
    subscUrl1 = "/topic/notification." + getCurrentUserId();
    let ws = new SockJS(sockUrl);
    this.clientsocket = Stomp.over(ws);
    const ref = this;
    this.clientsocket.connect({}, function () {
      console.log("==Yes connected ==");
      // now subscribe to
      this.subscribe(subscUrl1, (message) => {
        // called when the client receives a STOMP message from the server
        ref.callbackSubscUrl1(message);
      });
    });
  }
  callbackSubscUrl1 = (message) => {
    // called when the client receives a STOMP message from the server
    if (message.body) {
      console.log("=====got message with body =>>>", message.body);
      let currentUnreadNotificationCount =
        parseInt(this.state.totalUnreadNotificationCount) + 1;
      this.setState({
        totalUnreadNotificationCount: currentUnreadNotificationCount,
      });
      this.closeNotiListOnBlur();
      let jsonResponse =
        message.body !== undefined && message.body !== null
          ? JSON.parse(message.body)
          : null;

      let webSocketTaskInfo = null;
      let webSocketNotificationInfo = null;
      let webSocketCommentInfo = null;
      let webSocketNegotiationChatInfo = null;
      let notificationId = 0;
      let notificationTriggeredBy = null;

      if (jsonResponse !== null) {
        webSocketTaskInfo =
          jsonResponse.webSocketTaskInfo !== undefined &&
          jsonResponse.webSocketTaskInfo !== null
            ? jsonResponse.webSocketTaskInfo
            : null;

        webSocketNotificationInfo =
          jsonResponse.webSocketNotificationInfo !== undefined &&
          jsonResponse.webSocketNotificationInfo !== null
            ? jsonResponse.webSocketNotificationInfo
            : null;

        webSocketNegotiationChatInfo =
          jsonResponse.webSocketNegotiationChatInfo !== undefined &&
          jsonResponse.webSocketNegotiationChatInfo !== null
            ? jsonResponse.webSocketNegotiationChatInfo
            : null;

        webSocketCommentInfo =
          jsonResponse.webSocketCommentInfo !== undefined &&
          jsonResponse.webSocketCommentInfo !== null
            ? jsonResponse.webSocketCommentInfo
            : null;

        notificationId =
          webSocketNotificationInfo.notificationId !== undefined
            ? webSocketNotificationInfo.notificationId
            : 0;

        notificationTriggeredBy =
          webSocketNotificationInfo !== null &&
          webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
          webSocketNotificationInfo.notificationTriggeredBy !== null
            ? webSocketNotificationInfo.notificationTriggeredBy
            : null;

        let notifiSenderId =
          notificationTriggeredBy !== null &&
          notificationTriggeredBy.id !== undefined &&
          notificationTriggeredBy.id !== null
            ? notificationTriggeredBy.id
            : 0;

        if (
          jsonResponse.webSocketNotificationInfo.userNotificationType ===
          "SUBSCRIPTION_SUCCESS"
        ) {
          setAccessObj({ key: "isSubscriptionActive", keyValue: true });
          setTimeout(() => {
            this.props.history.push("/subscription");
          }, 1100);
        } else if (
          jsonResponse.webSocketNotificationInfo.userNotificationType ===
          "SUBSCRIPTION_FAILURE"
        ) {
          setAccessObj({ key: "isSubscriptionActive", keyValue: false });
          setTimeout(() => {
            this.props.history.push("/subscription");
          }, 1100);
        } else if (
          jsonResponse.webSocketNotificationInfo.userNotificationType ===
          "SUBSCRIPTION_WAIVER"
        ) {
          setAccessObj({ key: "isSubscriptionActive", keyValue: true });
          setTimeout(() => {
            this.props.history.push("/subscription");
          }, 1100);
        } else if (
          webSocketNotificationInfo.userNotificationType === "TASK_ASSIGNED"
        ) {
          this.closeSplit();
          setTimeout(() => {
            commonnotitrack("TASK_ASSIGNED", {
              trigerredFrom: "REAL_TIME_NOTI_TRACK",
              notiType: "TASK_ASSIGNED",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
            });
          }, 1100);
        } else if (
          webSocketNotificationInfo.userNotificationType === "TASK_OFFERED"
        ) {
          //this.closeSplit();
          setTimeout(() => {
            commonnotitrack("TASK_OFFERED", {
              trigerredFrom: "REAL_TIME_NOTI_TRACK",
              notiType: "TASK_OFFERED",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
            });
          }, 1100);
        } else if (
          webSocketNotificationInfo.userNotificationType === "NEGOTIATION"
        ) {
          let budgetNegotiationStatus =
            webSocketNegotiationChatInfo &&
            webSocketNegotiationChatInfo.budgetNegotiationStatus !== undefined
              ? webSocketNegotiationChatInfo.budgetNegotiationStatus
              : "";

          let dueDateNegotiationStatus =
            webSocketNegotiationChatInfo &&
            webSocketNegotiationChatInfo.dueDateNegotiationStatus !== undefined
              ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
              : "";

          setTimeout(() => {
            //
            commonnotitrack("NEGOTIATION", {
              trigerredFrom: "REAL_TIME_NOTI_TRACK",
              notiType: "NEGOTIATION",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
              chat:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.chat !== undefined &&
                webSocketNegotiationChatInfo.chat
                  ? webSocketNegotiationChatInfo.chat
                  : null,
              budgetNegotiationStatus: budgetNegotiationStatus,
              dueDateNegotiationStatus: dueDateNegotiationStatus,
              previousNegotiatedBudget:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.previousNegotiatedBudget !==
                  undefined &&
                webSocketNegotiationChatInfo.previousNegotiatedBudget !== null
                  ? webSocketNegotiationChatInfo.previousNegotiatedBudget
                  : null,
              previousNegotiatedBudgetCurrency:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.previousNegotiatedBudgetCurrency !==
                  undefined &&
                webSocketNegotiationChatInfo.previousNegotiatedBudgetCurrency !==
                  null
                  ? webSocketNegotiationChatInfo.previousNegotiatedBudgetCurrency
                  : null,
              previousNegotiatedDueDate:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.previousNegotiatedDueDate !==
                  undefined &&
                webSocketNegotiationChatInfo.previousNegotiatedDueDate !== null
                  ? webSocketNegotiationChatInfo.previousNegotiatedDueDate
                  : null,
              agreedBudget:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.agreedBudget !== undefined &&
                webSocketNegotiationChatInfo.agreedBudget !== null
                  ? webSocketNegotiationChatInfo.agreedBudget
                  : null,
              agreedBudgetCurrency:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.agreedBudgetCurrency !==
                  undefined &&
                webSocketNegotiationChatInfo.agreedBudgetCurrency !== null
                  ? webSocketNegotiationChatInfo.agreedBudgetCurrency
                  : null,
              agreedDueDate:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.agreedDueDate !== undefined &&
                webSocketNegotiationChatInfo.agreedDueDate !== null
                  ? webSocketNegotiationChatInfo.agreedDueDate
                  : null,
              conclusionMessage:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.conclusionMessage !== undefined &&
                webSocketNegotiationChatInfo.conclusionMessage
                  ? webSocketNegotiationChatInfo.conclusionMessage
                  : "",
            });

            //
          }, 1100);
        } else if (
          webSocketNotificationInfo.userNotificationType === "NEW_COMMENT"
        ) {
          setTimeout(() => {
            //
            commonnotitrack("NEW_COMMENT", {
              trigerredFrom: "REAL_TIME_NOTI_TRACK",
              notiType: "NEW_COMMENT",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
              commentResp: webSocketCommentInfo,
            });

            //
          }, 1100);
        } else if (
          jsonResponse.webSocketNotificationInfo.userNotificationType === "KYC"
        ) {
          setAccessObj({ key: "isKycVerified", keyValue: true });
        }
      }
    }
  };
  getTaskDetailData = (param) => {
    let paramObjData = param.paramObjData;
    let paramsCntxt = param.paramsCntxt;
    let userNotificationType = param.userNotificationType;
    let notiObj = param.notiObj;

    let lservdrf = new TaskDetailsService();
    lservdrf
      .getTaskDetail(paramObjData)
      .then((data) => {
        let entityObj = data.data.entity;
        // console.log(" sdfsdfs");
        // context share
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        if (setShare) {
          paramsCntxt["other"]["taskResp"] = entityObj;
          // console.log(" paramsCntxt===>>",paramsCntxt);
          this.props.history.push("/get-task");

          // for task list tab selection
          if (userNotificationType == "NEW_TASK_REQUEST") {
            setTimeout(() => {
              commonnotitrack("NEW_TASK_REQUEST", {
                tabToActive: "NEW_TASK_REQUEST",
                trigerredFrom: "BELL_NOTIFICATION_LIST",
                notiType: "NEW_TASK_REQUEST",
              });
            }, 1000);
          } else if (userNotificationType == "TASK_OFFERED") {
          }
          // else{
          //   commonnotitrack("TASK_ASSIGNED",{"tabToActive":"TASK_ASSIGNED",
          //   "trigerredFrom": "BELL_NOTIFICATION_LIST",
          //   "notiType": "TASK_ASSIGNED",
          //   });
          // }

          setTimeout(() => {
            setShare(param.paramsCntxt);
          }, 2000);

          setTimeout(() => {
            if (userNotificationType == "NEGOTIATION") {
              let webSocketNegotiationChatInfo =
                notiObj.webSocketNegotiationChatInfo !== undefined &&
                notiObj.webSocketNegotiationChatInfo !== null
                  ? notiObj.webSocketNegotiationChatInfo
                  : null;

              let budgetNegotiationStatus =
                webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                  undefined &&
                webSocketNegotiationChatInfo.budgetNegotiationStatus !== null
                  ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                  : "";

              let dueDateNegotiationStatus =
                webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                  undefined &&
                webSocketNegotiationChatInfo.dueDateNegotiationStatus !== null
                  ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                  : "";

              let openNegoTab = "BUDGET";
              //console.log(" after clicking nego msg from bell budgetNegotiationStatus=>",budgetNegotiationStatus," dueDateNegotiationStatus===>>>>",dueDateNegotiationStatus)

              if (
                budgetNegotiationStatus == "NEGOTIATE" &&
                dueDateNegotiationStatus == "NEGOTIATE"
              ) {
                openNegoTab = "BUDGET";
              } else if (
                budgetNegotiationStatus == "NEGOTIATE" &&
                dueDateNegotiationStatus == "AGREE"
              ) {
                openNegoTab = "BUDGET";
              } else if (
                budgetNegotiationStatus == "AGREE" &&
                dueDateNegotiationStatus == "NEGOTIATE"
              ) {
                openNegoTab = "DATE";
              } else if (
                budgetNegotiationStatus == "AGREE" &&
                dueDateNegotiationStatus == "AGREE"
              ) {
                openNegoTab = "BUDGET";
              }

              commonnotitrack("NEGOTIATION", {
                tabToActive: openNegoTab,
                trigerredFrom: "BELL_NOTIFICATION_LIST",
              });
            }
          }, 2200);
        }
      })
      .catch((e) => {
        //console.log("server error=>",e);
      })
      .finally(() => {});
  };
  closeSplit = () => {
    /*
          //********* for context starts *****
          let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
          if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
              if(sharedData.actionType=="SPLITSCREEN" ){
                  let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
                  if(setShare){
                      let paramsCntxt = {
                      "taskId":"",
                      "actionType":"",
                      "leftTabToActive":"",
                      "purpose":"",
                      "other":{}
                      }
                      setShare(paramsCntxt);
                  }
              }
          }
          //********* for context ends *****
          */
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "BELL_NOTIFICATION_MENU",
    });
  };

  fetchNotifications = () => {
    let notierv = new FetchNotificationService();
    notierv
      .getNotifications({ pageNum: this.state.pageNum })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            let prevRecords = [...this.state.notificationList];
            let currRecords = respData.notifications;
            let concatenateRecords = prevRecords.concat(currRecords);
            //console.log(data.data.entity)
            this.setState(
              {
                notificationList: concatenateRecords,
                totalNumberOfPages: respData.totalNumberOfPages,
                totalUnreadNotificationCount:
                  respData.totalUnreadNotificationCount,
              },
              () => {
                //console.log(this.state.notificationList)
              }
            );
          }
        }
      })
      .catch((err) => {
        //console.log("Some server related tech problem occured",err);
      });
  };

  /**
   * BellNotificationList
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  callLogoutService = (paramObj) => {
    let lservdrf = new LogoutService();
    let paramObjData = {};
    lservdrf
      .doLogout(paramObjData)
      .then((data) => {
        // remove token from browser
        removeTokenFromLocStorage();
        // got login page
        this.props.history.push("/signin");
      })
      .catch((e) => {
        console.log("server error=>", e);
      })
      .finally(() => {});
  };

  /**
   * BellNotificationList
   * Function name : callLogOut
   * Author        :
   * Created Date  : 3-7-2020
   * Purpose       : simple log out functionality
   * Params        : paramObj
   **/

  toggleNotiList = (paramObj) => {
    let notiListOpenFlag = this.state.notiListOpenFlag ? false : true;
    //console.log(" prev=>notiListOpenFlag=>",this.state.notiListOpenFlag);
    this.isComponentLoaded &&
      this.setState({ notiListOpenFlag: notiListOpenFlag }, () => {
        //console.log(" now=>notiListOpenFlag=>",notiListOpenFlag);
        if (notiListOpenFlag) {
          this.menuDropDownDivRef.current.focus();
          setTimeout(() => {
            this.isComponentLoaded && this.setState({ bellButtonFlag: false });
          }, 500);
        }
      });
  };

  closeNotiListOnBlur = (paramObj) => {
    // its motive is to close the list when mouse is clicked outsidedropdown list
    let notiListOpenFlag = false;
    if (this.state.notiListOpenFlag !== false) {
      this.isComponentLoaded &&
        this.setState({ notiListOpenFlag: notiListOpenFlag }, () => {});
    }
  };

  componentDidUpdate(prevProps, prevState) {
    //this.menuDropDownDivRef.current.focus();
  }

  /**
   * BellNotificationList
   * Function name : render
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/

  trackScroll = () => {
    let scobj = this.scrollerRef.current;
    let scrollTop = scobj.getValues().scrollTop;
    let scrollHeight = scobj.getScrollHeight();
    let clientHeight = scobj.getClientHeight();

    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    if (
      scrollHeight - clientHeight >= scrollTop &&
      prevPageNum < totalNumberOfPages
    ) {
      //this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = { pageNum: pageNumData };
        this.setState(
          (prevState) => ({ pageNum: prevState.pageNum + 1 }),
          () => {
            this.fetchNotifications("***called from scroll bar***");
          }
        );
      }, 900);
    }
  };

  // Newest1 - Start

  /**
   * BellNotificationList
   * Function name : openVidLink
   * Author        :
   * Created Date  : 18-6-2021
   * Purpose       : To open vidLink in a new tab
   * Params        : vidLink
   **/
  openVidLink = (vidLink) => {
    if (vidLink) {
      const newWindow = window.open(vidLink, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      // console.log("vidLink is unavailable!");
    }
  };

  // Newest1 - End

  clickNotification = (el) => {
    // Newest1 - Start

    let videoLink =
      el.webSocketCommentInfo !== null &&
      el.webSocketCommentInfo.videoCallDetails !== undefined &&
      el.webSocketCommentInfo.videoCallDetails !== null &&
      el.webSocketCommentInfo.videoCallDetails.videoLink !== undefined &&
      el.webSocketCommentInfo.videoCallDetails.videoLink !== null
        ? el.webSocketCommentInfo.videoCallDetails.videoLink
        : null;

    if (videoLink) this.openVidLink(videoLink);

    // Newest1 - End

    //console.log(el," el.webSocketNotificationInfo.userNotificationType=>>",el.webSocketNotificationInfo.userNotificationType);
    if (el.webSocketNotificationInfo.userNotificationType === "KYC") {
      this.props.history.push("/kyc");
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEW_TASK_REQUEST"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEGOTIATION"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "PROFESSIONALSINTERESTED",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_ASSIGNED"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEW_COMMENT"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "MONITORTASK",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TIMESHEET_UPDATE_REQUEST"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TIMELOGS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_DOCUMENT"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "DOCUMENTS",
        purpose: "VIEW_DOC_LIST",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "INVOICE_PAYMENT_INITIATION"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "INVOICE",
        purpose: "VIEW_INVOICE",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "INVOICE_PAID"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "INVOICE",
        purpose: "VIEW_INVOICE",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TASK_EDIT_PRE_ASSIGN"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TASK_EDIT_POST_ASSIGN"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_CLOSED"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {},
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_SUCCESS"
    ) {
      this.props.history.push("/subscription");
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_FAILURE"
    ) {
      this.props.history.push("/subscription");
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_WAIVER"
    ) {
      this.props.history.push("/subscription");
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_OFFERED"
    ) {
      this.closeSplit();
      let paramsCntxt = {};
      paramsCntxt = {
        taskId: el.webSocketTaskInfo.taskId,
        actionType: "SPLITSCREEN",
        leftTabToActive: "TASKDETAILS",
        purpose: "",
        other: {
          actionToDo: {
            taskId: el.webSocketTaskInfo.taskId,
            trigerredFrom: "BELL_NOTIFICATION_LIST",
            action: "OPEN_ACCEPT_TASK_OFFER_POPUP",
          },
        },
      };
      let paramObjData = { data: { taskId: el.webSocketTaskInfo.taskId } };
      let param = {
        paramObjData: paramObjData,
        paramsCntxt: paramsCntxt,
        userNotificationType: el.webSocketNotificationInfo.userNotificationType,
        notiObj: el,
      };
      this.getTaskDetailData(param);
    }

    if (!el.webSocketNotificationInfo.isRead) {
      this.callReadNotification(el.webSocketNotificationInfo.notificationId);
    }
  };
  callReadNotification = (id) => {
    let readNotificationserv = new ReadNotificationService();
    readNotificationserv
      .markAsRead({ ids: [id] })
      .then((data) => {
        if (data.status == 200) {
          console.log("success");
          let currentUnreadNotificationCount =
            parseInt(this.state.totalUnreadNotificationCount) - 1;
          this.setState({
            totalUnreadNotificationCount: currentUnreadNotificationCount,
          });
        }
      })
      .catch((err) => {
        //console.log("Some server related tech problem occured",err);
      });
  };
  readAllNotification = () => {
    let readAllNotificationserv = new ReadAllNotificationService();
    readAllNotificationserv
      .markAllAsRead()
      .then((data) => {
        if (data.status == 200) {
          console.log("success");
          this.setState({ totalUnreadNotificationCount: 0 });
        }
      })
      .catch((err) => {
        //console.log("Some server related tech problem occured",err);
      });
  };
  clearNotification = (el) => {
    let deleteNotificationserv = new DeleteNotificationService();
    deleteNotificationserv
      .deleteNotification({
        ids: [el.webSocketNotificationInfo.notificationId],
      })
      .then((data) => {
        if (data.status == 200) {
          console.log("successfully deleted");
          if (!el.webSocketNotificationInfo.isRead) {
            let currentUnreadNotificationCount =
              parseInt(this.state.totalUnreadNotificationCount) - 1;
            this.setState({
              totalUnreadNotificationCount: currentUnreadNotificationCount,
            });
          }
        }
      })
      .catch((err) => {
        //console.log("Some server related tech problem occured",err);
      });
  };
  clearAllNotification = (el) => {
    let deleteAllNotificationserv = new DeleteAllNotificationService();
    deleteAllNotificationserv
      .deleteAllNotification()
      .then((data) => {
        if (data.status == 200) {
          console.log("successfully deleted");
          this.setState({ totalUnreadNotificationCount: 0 });
        }
      })
      .catch((err) => {
        //console.log("Some server related tech problem occured",err);
      });
  };

  generateMsg = (el) => {
    if (el.webSocketNotificationInfo.userNotificationType === "KYC") {
      return "Your KYC has been successfully updated and approved by Admin. You can now start using the platform!";
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEW_TASK_REQUEST"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          A new task has been created by&nbsp;
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;for the Task&nbsp;<b>"{taskName}"</b>&nbsp;for which you
          qualify. Send in your offer before it is too late!
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEGOTIATION"
    ) {
      if (el.webSocketNegotiationChatInfo.chat.status === "NEGOTIATE") {
        let taskName = "";
        if (el.webSocketTaskInfo.taskName.length > 10) {
          taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
        } else {
          taskName = el.webSocketTaskInfo.taskName;
        }

        if (el.webSocketNegotiationChatInfo.chat.budget) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has sent a new negotiation for Budget for Task:&nbsp;
              <b>"{taskName}"</b>
            </>
          );
        } else if (el.webSocketNegotiationChatInfo.chat.dueDate) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has sent a new negotiation for Due Date for Task::&nbsp;
              <b>"{taskName}"</b>
            </>
          );
        }
      } else if (el.webSocketNegotiationChatInfo.chat.status === "AGREE") {
        let taskName = "";
        if (el.webSocketTaskInfo.taskName.length > 10) {
          taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
        } else {
          taskName = el.webSocketTaskInfo.taskName;
        }

        if (el.webSocketNegotiationChatInfo.chat.budget) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has agreed to Budget for Task:&nbsp;<b>"{taskName}"</b>
            </>
          );
        } else if (el.webSocketNegotiationChatInfo.chat.dueDate) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has agreed to Due Date for Task:&nbsp;<b>"{taskName}"</b>
            </>
          );
        }
      } else if (el.webSocketNegotiationChatInfo.chat.status === "REJECT") {
        let taskName = "";
        if (el.webSocketTaskInfo.taskName.length > 10) {
          taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
        } else {
          taskName = el.webSocketTaskInfo.taskName;
        }

        if (el.webSocketNegotiationChatInfo.chat.budget) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has rejected your Budget for Task:&nbsp;<b>"{taskName}"</b>
              &nbsp;Try putting in a new one!
            </>
          );
        } else if (el.webSocketNegotiationChatInfo.chat.dueDate) {
          return (
            <>
              <b>
                {el.webSocketOrganisationProfileInfo
                  ? el.webSocketOrganisationProfileInfo.newOrganisationName
                  : ""}
              </b>
              &nbsp;has rejected your Due Date for Task:&nbsp;
              <b>"{taskName}"</b>Try putting in a new one!
            </>
          );
        }
      }
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_ASSIGNED"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          Congratulation!&nbsp;
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has signed the engagement letter and assigned you the
          Task:&nbsp;
          <b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "NEW_COMMENT"
    ) {
      // Newest1 - Start
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      if (
        el.webSocketCommentInfo !== null &&
        el.webSocketCommentInfo.videoCallDetails !== undefined &&
        el.webSocketCommentInfo.videoCallDetails !== null &&
        el.webSocketCommentInfo.videoCallDetails.videoLink !== undefined &&
        el.webSocketCommentInfo.videoCallDetails.videoLink !== null &&
        el.webSocketCommentInfo.videoCallDetails.videoLink.length > 0
      ) {
        let comment =
          el.webSocketCommentInfo.comment !== undefined &&
          el.webSocketCommentInfo.comment !== null
            ? el.webSocketCommentInfo.comment
            : "";

        let videoLink = el.webSocketCommentInfo.videoCallDetails.videoLink;

        let endingTerm = "Meeting Link.";

        if (videoLink) {
          if (comment.endsWith(endingTerm)) {
            comment = comment.replace(endingTerm, videoLink);
          } else {
            comment = (
              <React.Fragment>
                {comment}
                <br />
                {videoLink}
              </React.Fragment>
            );
          }
        }

        let newComment = (
          <React.Fragment>
            You have received a new message for Task:&nbsp;
            <b>"{taskName}"</b>
            &nbsp;by&nbsp;
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            :&nbsp;
            <b>{comment}</b>
          </React.Fragment>
        );

        return <>{newComment}</>;
      } else if (el.webSocketCommentInfo.attachedFiles.length > 0) {
        return (
          <>
            You have received a new message for Task:&nbsp;<b>"{taskName}"</b>
            &nbsp;by&nbsp;
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
          </>
        );
      } else {
        let msg = el.webSocketCommentInfo.comment;

        return (
          <>
            You have received a new message for Task:&nbsp;<b>"{taskName}"</b>
            &nbsp;by&nbsp;
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            : <b>{msg}</b>
          </>
        );
      }
      // Newest1 - End
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TIMESHEET_UPDATE_REQUEST"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has requested you to update timesheet for Task:&nbsp;
          <b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_DOCUMENT"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has sent a new document for Task:&nbsp;<b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "INVOICE_PAYMENT_INITIATION"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          You have initiated payment for the Invoice No.&nbsp;
          <b>{el.webSocketNotificationInfo.notificationId}</b> by&nbsp;
          <b>
            {el.webSocketNotificationInfo.notificationTriggeredBy.firstName}
          </b>
          &nbsp;for Task:&nbsp;<b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TASK_EDIT_PRE_ASSIGN"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }

      if (el.websocketTaskEditInfo) {
        return (
          <>
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            &nbsp;has edited the&nbsp;
            <b>{el.websocketTaskEditInfo.editedFields.toString()}</b>&nbsp;of
            the Task:&nbsp;<b>"{taskName}"</b>
          </>
        );
      } else {
        return (
          <>
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            &nbsp;has edited Task:&nbsp;<b>"{taskName}"</b>
          </>
        );
      }
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "TASK_EDIT_POST_ASSIGN"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      if (el.websocketTaskEditInfo) {
        return (
          <>
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            &nbsp;has edited the&nbsp;
            <b>{el.websocketTaskEditInfo.editedFields.toString()}</b>&nbsp;of
            the Task:&nbsp;<b>"{taskName}"</b>
          </>
        );
      } else {
        return (
          <>
            <b>
              {el.webSocketOrganisationProfileInfo
                ? el.webSocketOrganisationProfileInfo.newOrganisationName
                : ""}
            </b>
            &nbsp;has edited Task:&nbsp;<b>"{taskName}"</b>
          </>
        );
      }
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "INVOICE_PAID"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          Congratulations!&nbsp;
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has cleared the invoice for Task:&nbsp;<b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_CLOSED"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          You have completed the Task and the&nbsp;
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has successfully closed the Task:&nbsp;<b>"{taskName}"</b>
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_SUCCESS"
    ) {
      return (
        <>
          Your payment of INR&nbsp;
          <b>{el.webSocketSubscriptionInfo.subscriptionAmount}</b>&nbsp;has been
          received and your subscription is upgraded to Elite.
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_FAILURE"
    ) {
      return (
        <>
          Your payment of INR&nbsp;
          <b>{el.webSocketSubscriptionInfo.subscriptionAmount}</b>&nbsp;has been
          failure.
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType ===
      "SUBSCRIPTION_WAIVER"
    ) {
      return (
        <>
          Congratulations! You have received a subscription waiver and your
          subscription is upgraded to Elite.
        </>
      );
    } else if (
      el.webSocketNotificationInfo.userNotificationType === "TASK_OFFERED"
    ) {
      let taskName = "";
      if (el.webSocketTaskInfo.taskName.length > 10) {
        taskName = el.webSocketTaskInfo.taskName.substr(0, 10);
      } else {
        taskName = el.webSocketTaskInfo.taskName;
      }
      return (
        <>
          <b>
            {el.webSocketOrganisationProfileInfo
              ? el.webSocketOrganisationProfileInfo.newOrganisationName
              : ""}
          </b>
          &nbsp;has offered you to take up a task&nbsp;<b>"{taskName}"</b>
        </>
      );
    }
  };

  render() {
    return (
      <>
        <div className="notification_sec_bg">
          <button
            disabled={this.state.bellButtonFlag}
            className="dropdown-toggle btn_appearance_none bell_drop_btn"
            type="button"
            //  data-toggle= "dropdown"

            onClick={(evn) => {
              evn.preventDefault();
              let curnotiListOpenFlag = this.state.notiListOpenFlag;
              if (curnotiListOpenFlag == false) {
                // before opening list change bellButtonFlag to true
                this.isComponentLoaded &&
                  this.setState({ bellButtonFlag: true });
              }
              this.toggleNotiList({});
              this.setState(
                { notificationList: [], totalNumberOfPages: 1, pageNum: 1 },
                () => {
                  this.fetchNotifications();
                }
              );
            }}
          >
            <div className="notification_icon">
              <img src={getBaseUrl() + "assets/images/alarm.png"} />
              {this.state.totalUnreadNotificationCount > 0 && (
                <div className="left_mainHeader_notiTag">
                  {this.state.totalUnreadNotificationCount}
                </div>
              )}
            </div>
          </button>
          <div
            ref={this.menuDropDownDivOrigRef}
            className={`dropdown-menu dropdown-menu-right notification_drop_menu ${
              this.state.notiListOpenFlag ? "show" : ""
            }`}
          >
            <input
              style={{ opacity: "0", position: "absolute" }}
              type="checkbox"
              key={"trackingchkbxfornotilist1"}
              ref={this.menuDropDownDivRef}
              onBlur={(evn) => {
                evn.preventDefault();
                setTimeout(() => {
                  console.log(" blur also called");
                  this.closeNotiListOnBlur({});
                }, 300);
              }}
            />
            <div className="notifi_drop_boxBg">
              <div className="notifi_drop_header">
                <div className="notiDrop_head_btn">
                  {this.state.notificationList.length > 0 &&
                    this.state.totalUnreadNotificationCount !== null &&
                    this.state.totalUnreadNotificationCount > 0 && (
                      <button
                        className="btn_appearance_none greenText_btn notiDrop_headBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          this.readAllNotification();
                        }}
                      >
                        Mark all as read
                      </button>
                    )}
                </div>
              </div>

              <div className="notifi_drop_body">
                <div className="notifi_drop_scroll_sec mCustomScrollbar height_400">
                  <Scrollbars
                    ref={this.scrollerRef}
                    style={{ height: 400 }}
                    autoHeightMin={400}
                    renderView={(props) => (
                      <div
                        {...props}
                        className="view bellnotilistscrollcustomclass"
                      />
                    )}
                    onScrollStop={this.trackScroll}
                  >
                    {/* === notifi drio scroll ===  */}
                    <ul>
                      {this.state.notificationList.length > 0 &&
                        this.state.notificationList.map((el, i) => {
                          let imageUrl =
                            getBaseUrl() + "assets/images/noti_info.png";
                          let title = "";
                          let msg = "";

                          if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "KYC"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_info.png";
                            title = "KYC approved by admin";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "NEW_TASK_REQUEST"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_task.png";
                            title = "New Task Request";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "NEGOTIATION"
                          ) {
                            imageUrl =
                              getBaseUrl() +
                              "assets/images/noti_negotiation.png";
                            title = "New Negotiation msg";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "TASK_ASSIGNED"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_task.png";
                            title = "New Task Assigned";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "NEW_COMMENT"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_comment.png";
                            title = "New chat Message ";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType ===
                            "TIMESHEET_UPDATE_REQUEST"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_timesheet.png";
                            title = "Timesheet update request.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "TASK_DOCUMENT"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_documents.png";
                            title = "Timesheet update request.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType ===
                            "INVOICE_PAYMENT_INITIATION"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_wallet.png";
                            title = "Invoice payment initiation.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "TASK_EDIT_PRE_ASSIGN"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_task.png";
                            title = "Task edit pre assingnment.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "TASK_EDIT_POST_ASSIGN"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_task.png";
                            title = "Task edit post assingnment.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "INVOICE_PAID"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_wallet.png";
                            title = "Invoice payment paid.";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "SUBSCRIPTION_SUCCESS"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_info.png";
                            title = "Subscription Successfully";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "SUBSCRIPTION_FAILURE"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_info.png";
                            title = "Subscription Failure";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "SUBSCRIPTION_WAIVER"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_info.png";
                            title = "Subscription Waiver";
                          } else if (
                            el.webSocketNotificationInfo
                              .userNotificationType === "TASK_OFFERED"
                          ) {
                            imageUrl =
                              getBaseUrl() + "assets/images/noti_task.png";
                            title = "Task Offered";
                          }

                          return (
                            <li key={i}>
                              <div
                                className={`notifi_li_box ${
                                  el.webSocketNotificationInfo.isRead
                                    ? "notifi_read"
                                    : "notifi_unread"
                                }`}
                              >
                                <div className="notifi_close">
                                  <button
                                    className="btn_appearance_none notiDrop_footerBtn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.clearNotification(el);
                                    }}
                                  >
                                    <img
                                      src={
                                        getBaseUrl() +
                                        "assets/images/balck_cross.png"
                                      }
                                    />
                                  </button>
                                </div>
                                <div className="notifi_cont_box">
                                  <div className="notifi_left_icon">
                                    <img src={imageUrl} />
                                  </div>
                                  <div className="notifi_main_text">
                                    {/* <p className="noti_title_text">
                                                    <a href="" onClick={()=>{this.clickNotification(el)}}>
                                                      <b>{title}</b>
                                                    </a>
                                                  </p> */}
                                    <p className="noti_task_text">
                                      <a
                                        href=""
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.clickNotification(el);
                                        }}
                                      >
                                        {this.generateMsg(el)}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="notifi_bottom_text">24 Sep 2020 9:45am IST</div> */}
                                <div className="notifi_bottom_text">
                                  {CustDate.custFormatDate(
                                    new Date(
                                      el.webSocketNotificationInfo.notificationTime
                                    ),
                                    6
                                  )}
                                </div>
                                <div className="clearboth" />
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                    {/* === notifi drio scroll ===  */}
                  </Scrollbars>

                  <div className="clearboth" />
                </div>
              </div>

              <div className="notifi_drop_footer">
                {this.state.notificationList.length > 0 && (
                  <button
                    className="btn_appearance_none notiDrop_footerBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.clearAllNotification();
                    }}
                  >
                    Clear all messages
                  </button>
                )}
              </div>

              <div className="clearboth" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(BellNotificationList);
