import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
//services
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
import FetchKycService from '../../service/FetchKycService';
import FileUploadService from '../../service/FileUploadService';
import FetchPracticeAreaService from '../../service/FetchPracticeAreaService';
import FetchUniversitiesService from '../../service/FetchUniversitiesService';
import FetchRegistrationStateService from '../../service/FetchRegistrationStateService';
import UpdateKycService from '../../service/UpdateKycService';
import SaveKycService from '../../service/SaveKycService';
// for context
import CommonContext from '../../context/CommonContext';
// For loading CustLoader
import CustLoader from './CustLoader';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import {LoadGoogleMap} from '../../customisedthirdparty/LoadGoogleMap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// If you want to use the provided css
import 'react-google-places-autocomplete/dist/assets/index.css';
import { Scrollbars } from 'react-custom-scrollbars';

import  CustDate  from '../../../common_utilities/CustDate';

import CustomAutoComplete from '../../customisedthirdparty/CustomAutoComplete';

const getUserEmail = commonJsObj.getUserEmail;
const getBaseUrl = commonJsObj.getBaseUrl;
const findElement = commonJsObj.findElement;
const setAccessObj = commonJsObj.setAccessObj;
const setProfilePicture = commonJsObj.setProfilePicture;

const isTheYearBigger = date => {
    let curDate = new Date();
    let curDateYear =  curDate.getFullYear();
    curDateYear = parseInt(curDateYear);

    let iteraYear = date.getFullYear();
    iteraYear = parseInt(iteraYear);


    return iteraYear<=curDateYear;
  };
 
export class Kyc extends Component {
    static contextType = CommonContext;

    isComponentLoaded = false;
    constructor(props) {
        super(props);

        this.state = {
            kycVerifiedStatus:true,

            professionalType:"",
            practiceType:"",
            practiceOwnerName:"",
            namePrefix:"",
            isLawFirmNameSameAsPracticeOwner:false,
            lawFirmName:"",
            hasWebsiteUrl:"Yes",
            website:"",
            hasLinkedInUrl:"Yes",
            linkedInUrl:"",
            officialEmailAddress:'',
            officialPhoneNumber:"",
            officeCity:"",
            officeCityName:"",
            registeredPracticeType:"",
            regPracticeTypeOption:"",
            registeredPracticeDocId:"",
            registeredPracticeDoc:"",
            practiceAreaIds:[],
            practiceAreas:[],
            locationsActive:[],
            aboutThePractice:"",
            profilePictureId:"",
            profilePicture:"",

            universityOptions:[],
            graduationUniversityId:"",
            graduationYear:"",
            isBarRegistered:'false',
            barRegistrationNumber:"",
            registrationStatesOptions:[],
            barRegisteredStateId:"",
            barRegisteredState:"",
            barCertificateId:"",
            barCertificate:"",
            graduationCertificateId:"",
            graduationCertificate:"",
            resumeDocId:"",
            resumeDoc:"",
            referenceEmailAddresses:[],
            referenceEmailAddressOne:"",
            referenceEmailAddressTwo:"",
            workExperience:"",

            isBillingContactNameSameAsPracticeOwner:false,
            billingContactName:"",
            isBillingEmailAddressSameAsPracticeEmailId:false,
            billingEmailAddress:"",
            billingAddress:"",
            pan:"",
            panDocId:"",
            panDoc:"",
            isGSTRegistered:"Yes",
            gstin:"",
            gstinDocId:"",
            gstinDoc:"",
            beneficiaryName:"",
            bankAccountNumber:"",
            bankName:'',
            branchName:'',
            bankAccountType:'',
            ifsc:"",

            practiceTypeErrMsg:"",
            practiceOwnerNameErrMsg:"",
            lawFirmNameErrMsg:"",
            websiteErrMsg:"",
            linkedInUrlErrMsg:"",
            officialPhoneNumberErrMsg:"",
            officeCityErrMsg:"",
            registeredPracticeTypeErrMsg:"",
            regPracticeTypeOptionErrMsg:"",
            registeredPracticeDocIdErrMsg:"",
            registeredPracticeDocIdUplodingFlag:false,
            practiceAreaIdsErrMsg:'',
            practiceAreaOptions:[],
            locationsActiveErrMsg:'',
            locationActiveDefaultName:"",
            aboutThePracticeErrMsg:'',
            profilePictureErrMsg:'',
            profilePictureIdUploadingFlag:false,



            graduationUniversityIdErrMsg:'',
            graduationYearErrMsg:'',
            graduationYearForCalender:'',
            isBarRegisteredErrMsg:'',
            barRegistrationNumberErrMsg:'',
            barRegisteredStateIdErrMsg:'',
            barCertificateIdErrMsg:'',
            barCertificateIdUploadingFlag:false,
            graduationCertificateIdErrMsg:'',
            graduationCertificateIdUploadingFlag:false,
            resumeDocIdErrMsg:'',
            resumeDocIdUploadingFlag: false,
            referenceEmailAddressOneErrMsg:'',
            referenceEmailAddressTwoErrMsg:'',
            workExperienceErrMsg:"",

            billingContactNameErrMsg:'',
            billingEmailAddressErrMsg:'',
            billingAddressErrMsg:'',
            panErrMsg:'',
            panDocErrMsg:'',
            panDocUploadingFlag:false,
            isGSTRegisteredErrMsg:'',
            gstinErrMsg:'',
            gstinDocErrMsg:'',
            gstinDocUploadingFlag:'',
            beneficiaryNameErrMsg: '',
            bankAccountNumberErrMsg: '',
            bankNameErrMsg:'',
            branchNameErrMsg:'',
            bankAccountTypeErrMsg:'',
            ifscErrMsg:'',

            isSubmittedForKYC:false,
            isVerified: false,

            //newly added
            fetchedSuggestedUniversity: [],
            graduationUniversityName: '',
            aboutYourPracErCategFlag:false,                
            aboutPracOwnErCategFlag:false,
            billDetailErCategFlag:false,
        }
        this.officeCityRef = React.createRef();
        this.registeredPracticeDocIdRef = React.createRef();
        this.locationCityRef = React.createRef();
        this.profilePictureIdRef = React.createRef();
        this.barCertificateIdRef = React.createRef();
        this.graduationCertificateIdRef =React.createRef();
        this.resumeDocIdRef = React.createRef();
        this.panDocRef = React.createRef();
        this.gstinDocRef = React.createRef();
        this.scrollerRef = React.createRef();
        this.refDivTop = React.createRef();

        //newly added
        this.customUnivAutoCompRel = React.createRef();
    }

    componentDidMount() {
                

        LoadGoogleMap.loadGoogleMapLibrary();
        this.fetchLoggedinUserInfoDetails();
        this.showLoader();
        this.fetchPracticeAreas();
        this.fetchUniversities();
        this.fetchRegistrationState();
        this.callServiceToFetchKycDetails();
        this.isComponentLoaded=true;
    }
    componentWillUnmount() {
        this.isComponentLoaded =  false;
    }
    fetchLoggedinUserInfoDetails=()=>{
        let loginuserserv = new LoggedinUserInfoService();
        loginuserserv.getLoggedInUserInfo({}).then(data =>{
            if(data.status==200){
                let respObj  = data.data.entity;
                

                if(respObj!==undefined){
                    let isSubscriptionActive = false;
                    let isKycVerified = false;
                    let profilePicture = null;

                    isSubscriptionActive = respObj.isSubscriptionActive!==undefined && respObj.isSubscriptionActive!==null?respObj.isSubscriptionActive:false;
                    isKycVerified = respObj.isKycVerified!==undefined && respObj.isKycVerified!==null?respObj.isKycVerified:false;

                    profilePicture = respObj.profilePicture!==undefined && respObj.profilePicture!==null?respObj.profilePicture:null;
                   // console.log(data.data.entity.isKycVerified)
                   setAccessObj({"key":"isSubscriptionActive","keyValue":isSubscriptionActive});
                   setAccessObj({"key":"isKycVerified","keyValue":isKycVerified});
                   setProfilePicture({"profilePicture":profilePicture});
                    this.setState({kycVerifiedStatus:data.data.entity.isKycVerified},()=>{
                        //console.log(this.state)
                    })
                }
            }
         }).catch(err=>{
            //console.log("Some server related tech problem occured",err);

        });
    }
    fetchPracticeAreas= () =>{
        let paserv = new FetchPracticeAreaService();
        paserv.getPracticeAreas().then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"practiceAreaOptions":data.data.entity},()=>{});
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }
    fetchRegistrationState= () =>{
        let paserv = new FetchRegistrationStateService();
        paserv.getRegistrationState().then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"registrationStatesOptions":data.data.entity},()=>{});
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }
    fetchUniversities = () =>{
        let userv = new FetchUniversitiesService();
        userv.getUniversities({}).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"universityOptions":data.data.entity},()=>{ console.log(this.state.universityOptions)});
            }
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
    }

    // newly added for university auto complete
    getUniversityData = (inputValue) => {
        let userv = new FetchUniversitiesService();
        userv.getUniversities({"search":inputValue}).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                let suggestedUniversity = data.data.entity;
                this.isComponentLoaded && this.setState({"fetchedSuggestedUniversity":suggestedUniversity},()=>{
                    this.customUnivAutoCompRel.current.updateSuggestedData(suggestedUniversity);
            });
            }
        }
        }).catch((e)=>{
            this.customUnivAutoCompRel.current.updateSuggestedData([]);
            //console.log.log(" Something went wrong with api server=>",e);
        });

    }
    // newly added for university auto complete
    universitySelected = (selectedData) => {
       console.log(" ******* univer selectedData==>>>>",selectedData);
       if(selectedData!==undefined && selectedData!==null && selectedData.id!==undefined ){
        this.isComponentLoaded && this.setState({"graduationUniversityId":selectedData.id});
       }

    }

    callServiceToFetchKycDetails = ()=>{
        let service = new FetchKycService();
        service.getKycDetails().then(data =>{
            if(data.status==200){
                let respData ={};
                let selectedPracticeAreas=[];
                let selectedPracticeAreasIds=[];
                if(data.data.entity!==undefined){

                    respData  = data.data.entity;
                    console.log(respData);
                    // if(respData.practiceAreas.length>0){
                    //     // selectedPracticeAreas= respData.practiceAreas.map((el)=>{
                    //     //     return {id:el.practiceId,value:el.practiceArea}
                    //     // })
                    // }
                    if(respData!==undefined){
                        console.log(respData.practiceAreas)
                        if(respData.practiceAreas && respData.practiceAreas.length>0){
                            respData.practiceAreas.map((el)=>{
                                el.id=el.practiceId;
                                el.value = el.practiceArea;
                                selectedPracticeAreas.push(el);
                                selectedPracticeAreasIds.push(el.practiceId);
                            })
                        }


                         let isBarRegisteredStatus=respData.isBarRegistered? respData.isBarRegistered.toString() : 'false';

                        this.setState({
                            professionalType: respData.professionalType,
                            practiceType: respData.practiceType,
                            practiceOwnerName:respData.practiceOwnerName,
                            namePrefix: respData.namePrefix,
                            lawFirmName: respData.lawFirmName,
                            website: respData.website,
                            linkedInUrl: respData.linkedInUrl,
                            hasLinkedInUrl: respData.linkedInUrl?"Yes":"No",
                            officialEmailAddress: respData.officialEmailAddress?respData.officialEmailAddress:getUserEmail(),
                            officialPhoneNumber: respData.officialPhoneNumber,
                            officeCity: respData.officeCity,
                            officeCityName: respData.officeCity?respData.officeCity.cityName:'',
                            registeredPracticeType: respData.registeredPracticeType!="Registered" ? 'INDEPENDENT_ADVOCATE' : 'REGISTERED',
                            regPracticeTypeOption: respData.regPracticeTypeOption ? respData.regPracticeTypeOption : '',
                            registeredPracticeDoc: respData.registeredPracticeDoc ? respData.registeredPracticeDoc : '',
                            registeredPracticeDocId: respData.registeredPracticeDoc ? respData.registeredPracticeDoc.id : '',
                            practiceAreaIds:selectedPracticeAreasIds,
                            practiceAreas: selectedPracticeAreas,
                            locationsActive:  respData.locationsActive ? respData.locationsActive : [],
                            aboutThePractice: respData.aboutThePractice ? respData.aboutThePractice : '',
                            profilePicture: respData.profilePicture ? respData.profilePicture : '',
                            profilePictureId: respData.profilePicture ? respData.profilePicture.id : '',

                            graduationUniversityId: respData.graduationUniversity ? respData.graduationUniversity.id : '',
                            graduationYear: respData.graduationYear ? respData.graduationYear : new Date().getFullYear(),
                            graduationYearForCalender: respData.graduationYear ? new Date(respData.graduationYear.toString()): new Date(),
                            isBarRegistered: isBarRegisteredStatus,
                            barRegistrationNumber: respData.barRegistrationNumber ? respData.barRegistrationNumber : '',
                            barRegisteredStateId: respData.barRegisteredState ? respData.barRegisteredState.id : '',
                            barCertificate: respData.barCertificate ? respData.barCertificate : '',
                            barCertificateId: respData.barCertificate ? respData.barCertificate.id : '',
                            graduationCertificate: respData.graduationCertificate ? respData.graduationCertificate: '',
                            graduationCertificateId: respData.graduationCertificate ? respData.graduationCertificate.id: '',
                            referenceEmailAddresses: respData.referenceEmailAddresses ?respData.referenceEmailAddresses:[],
                            referenceEmailAddressOne: respData.referenceEmailAddresses && respData.referenceEmailAddresses.length>0 ? respData.referenceEmailAddresses[0]:'',
                            referenceEmailAddressTwo: respData.referenceEmailAddresses && respData.referenceEmailAddresses.length>1 ? respData.referenceEmailAddresses[1]:'',
                            workExperience:respData.workExperience ? respData.workExperience: '',

                            billingContactName: respData.billingContactName ? respData.billingContactName : '',
                            billingEmailAddress: respData.billingEmailAddress ? respData.billingEmailAddress : '',
                            billingAddress: respData.billingAddress ? respData.billingAddress : '',
                            pan: respData.pan ? respData.pan : '',
                            panDoc: respData.panDoc ? respData.panDoc : '',
                            panDocId: respData.panDoc ? respData.panDoc.id : '',
                            isGSTRegistered: respData.gstin? 'Yes':'No',
                            gstin: respData.gstin ? respData.gstin : '',
                            gstinDoc: respData.gstinDoc ? respData.gstinDoc : '',
                            gstinDocId: respData.gstinDoc ? respData.gstinDoc.id : '',
                            beneficiaryName: respData.beneficiaryName ? respData.beneficiaryName : '',
                            bankAccountNumber: respData.bankAccountNumber ? respData.bankAccountNumber : '',
                            bankName: respData.bankName ? respData.bankName : '',
                            branchName: respData.branchName ? respData.branchName : '',
                            bankAccountType: respData.bankAccountType ? respData.bankAccountType : 'SAVINGS',
                            ifsc: respData.ifsc ? respData.ifsc : '',
                            isSubmittedForKYC : respData.isSubmittedForKYC? respData.isSubmittedForKYC : false,
                            isVerified : respData.isVerified? respData.isVerified : false,
                            kycVerifiedStatus : respData.isVerified? respData.isVerified : false,
                            
                            graduationUniversityName: respData.graduationUniversity ? respData.graduationUniversity.name : '',
                            fetchedSuggestedUniversity:[],
                            aboutYourPracErCategFlag:false,                
                            aboutPracOwnErCategFlag:false,
                            billDetailErCategFlag:false,
                            resumeDoc: (respData.resumeDoc!==undefined && respData.resumeDoc) ? respData.resumeDoc : null,
                            resumeDocId: (respData.resumeDoc!==undefined && respData.resumeDoc && respData.resumeDoc.id!==undefined) ? respData.resumeDoc.id : ''
                        
                        },()=>{
                            console.log(this.state);
                            this.customUnivAutoCompRel.current.setDefaultTextBoxValue(this.state.graduationUniversityName);
                        })
                    }
                }
            }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);

                }
                }
        }).finally(()=>{
            this.hideLoader();

        });
    }

    loadLoader() {
        return (
            <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }

    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    showLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
    handleChange = (e) => {
        this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value});
    }
    lawFirmNameCheck = (e)=>{
        console.log(e.target.checked);
        if(e.target.checked){
            this.setState({lawFirmName:this.state.practiceOwnerName,isLawFirmNameSameAsPracticeOwner:true})
        }else{
            this.setState({lawFirmName:'',isLawFirmNameSameAsPracticeOwner:false})
        }
    }
    billingContactPersonNameCheck = (e)=>{
        console.log(e.target.checked);
        if(e.target.checked){
            this.setState({billingContactName:this.state.practiceOwnerName,isBillingContactNameSameAsPracticeOwner:true})
        }else{
            this.setState({billingContactName:'',isBillingContactNameSameAsPracticeOwner:false})
        }
    }
    billingEmailAddressCheck = (e)=>{
        console.log(e.target.checked);
        if(e.target.checked){
            this.setState({billingEmailAddress:this.state.officialEmailAddress,isBillingEmailAddressSameAsPracticeEmailId:true})
        }else{
            this.setState({billingEmailAddress:'',isBillingEmailAddressSameAsPracticeEmailId:false})
        }
    }

    getLatLongFromResult(place){
        //console.log("got this",place.description);
        //this.officeCityRef.current.state.value='';

        let placeIdData =   place.place_id?place.place_id:'';
        let refThis = this;
        geocodeByPlaceId(placeIdData)
        .then(results => {
            
            let cityObj = refThis.parsePlaceData(results[0]);
            
            if(cityObj && cityObj.cityName!==undefined && cityObj.cityName!==null ){
                let prevLocations= [];
                prevLocations=this.state.locationsActive;

                let placeId  = cityObj.placeId;
                let fndObjPlcId = findElement(prevLocations,"placeId",placeId); 

                if(prevLocations && prevLocations.length<5 && fndObjPlcId===null){
                    prevLocations.push(cityObj);
                    console.log(" prevLocations=>>>");
                }
                
               this.isComponentLoaded && this.setState({locationsActive:prevLocations,officeCity:cityObj,officeCityName:cityObj.cityName},()=>{
                    //console.log(this.state.locationsActive)
                });
                
            }else{
                
                this.officeCityRef.current.state.value = "";
                setTimeout(()=>{  
                    this.isComponentLoaded && this.setState({officeCityName:""},()=>{
                        
                    });
                },1200);
                
            }

            
        })
        .catch(error => console.error(error));


        //   geocodeByAddress(place.description)
        //   .then(results => getLatLng(results[0]))
        //   .then(({ lat, lng }) => {
        //     //console.log(lat,lng,place)
        //     let city ={
        //         city:place.terms[0]?place.terms[0].value:'',
        //         cityName:place.terms[0]?place.terms[0].value:'',
        //         placeId:place.place_id?place.place_id:'',
        //         latitude:lat?lat:'',
        //         longitude:lng?lng:'',
        //         state:place.terms[1]?place.terms[1].value:'',
        //         country:place.terms[2]?place.terms[2].value:''
        //     }
        //     let prevLocations= [];
        //     prevLocations=this.state.locationsActive;
        //     // //console.log(prevLocations,city);
        //     prevLocations.push(city);
        //     // //console.log(prevLocations)
        //     this.setState({locationsActive:prevLocations},()=>{
        //         //console.log(this.state.locationsActive)

        //     });
        //     this.setState({officeCity:city});
        //   }
        //   );
    }
    onLocationSelect(place) {

       

        
        
        let placeIdData =   place.place_id?place.place_id:'';
        let refThis = this;
        
        geocodeByPlaceId(placeIdData)
        .then(results => {
            refThis.locationCityRef.current.state.value='';
           
            let cityObj = refThis.parsePlaceData(results[0]);
            let prevLocations= [];
            prevLocations=this.state.locationsActive;

            let placeId  = cityObj.placeId;
            let fndObjPlcId = findElement(prevLocations,"placeId",placeId); 
            if(cityObj.cityName!==null && fndObjPlcId===null){               
    
                    prevLocations.push(cityObj);
                    // //console.log(prevLocations)
                    this.setState({locationsActive:prevLocations},()=>{
                        //console.log(" now locationsActive==>>",this.state.locationsActive);
        
                    });
            }
           
            
            //
        })
        .catch(error => console.error(error)).finally(()=>{
            refThis.locationCityRef.current.state.value='';
            
        });

        // geocodeByAddress(place.description)
        // .then(results => getLatLng(results[0]))
        // .then(({ lat, lng }) => {
        //     ////console.log(lat,lng,place,"onLocationSelect");

        //     let city ={
        //         city:place.terms[0]?place.terms[0].value:'',
        //         cityName:place.terms[0]?place.terms[0].value:'',
        //         placeId:place.place_id?place.place_id:'',
        //         latitude:lat?lat:'',
        //         longitude:lng?lng:'',
        //         state:place.terms[1]?place.terms[1].value:'',
        //         country:place.terms[2]?place.terms[2].value:''
        //     }
            
        //     if(!this.state.locationsActive.find(el=>el.placeId==city.placeId) ){
        //         let prevLocations= [];
        //         prevLocations=this.state.locationsActive;
        //         // //console.log(prevLocations,city);
        //         prevLocations.push(city);
        //         // //console.log(prevLocations)
        //         this.setState({locationsActive:prevLocations},()=>{
        //             //console.log(" now locationsActive==>>",this.state.locationsActive);

        //         });
        //     }
        //     ////console.log(this.locationCityRef.current.state.value)
        //     this.locationCityRef.current.state.value='';
        //   }
        // );
    }
    parsePlaceData = (place) => {
        let lat, lng, addrSel, placeName, placeId =''; 
        let country, state, city =null;  
        if(place.geometry!==undefined ){
          const plcGeom = place.geometry;
          if(plcGeom.location!==undefined){
            lat = plcGeom.location ? plcGeom.location.lat():'';
            lng = plcGeom.location ? plcGeom.location.lng():'';
          }      
        }
        
        addrSel  = place.formatted_address!==undefined?place.formatted_address:"";
        placeName  = place.name!==undefined?place.name:"";
        placeId = place.place_id!==undefined?place.place_id:"";

        if(place.address_components!==undefined){
        let addrComp = place.address_components;
        //console.log(" 1 addrComp====>>",addrComp);
        for(let i = 0; i < addrComp.length; ++i)
        {
            var typ = addrComp[i].types;
            if(this.compIsType(typ, 'administrative_area_level_1'))
                state = addrComp[i].long_name; //store the state
            else if(this.compIsType(typ, 'locality'))
                city = addrComp[i].long_name; //store the city
            else if(this.compIsType(typ, 'country'))
                country = addrComp[i].long_name; //store the country        

            //we can break early if we find all three data
            if(state != null && city != null && country != null) break;
        }

        let cityObj ={
            "city":city,
            "cityName":city,
            "placeId":placeId,
            "latitude":lat,
            "longitude":lng,
            "state":state,
            "country":country
        }

        return cityObj;
        



      
     



    }
        
        
    }

    compIsType(t, s) { 
        for(let z = 0; z < t.length; ++z) 
           if(t[z] == s)
              return true;
     
        return false;
     }
    removeLocation(city){
        //console.log(city);
        let prevLocations =this.state.locationsActive;
        prevLocations = prevLocations.filter((el) => el.placeId!=city);
        this.setState({locationsActive:prevLocations})
    }
    trackFileSelection = (e,type) =>{
        e.preventDefault();
        ////console.log(type);
        let fileObj = e.target.files;

        let chkFileValidation =  this.validateFileSelected(fileObj,type);

        //this.calUploadFileService(fileObj);
        if(chkFileValidation){
            this.calUploadFileService(fileObj,type);
        }else{
            if(type=="registeredPracticeDoc"){
                this.registeredPracticeDocIdRef.current.value = "";
            }else if (type == "profilePicture") {
                this.profilePictureIdRef.current.value = "";
            } else if(type == "barCertificate"){
                this.barCertificateIdRef.current.value = "";
            } else if(type == "graduationCertificate"){
                this.graduationCertificateIdRef.current.value = "";
            } else if(type == "resumeDoc"){
                this.resumeDocIdRef.current.value = "";
            } else if(type == "panDoc"){
                this.panDocRef.current.value = "";
            } else if(type == "gstinDoc"){
                this.gstinDocRef.current.value = "";
            }
        }
    }
    validateFileSelected(fileObj,type){
        const maxFileUploadAllowed = 1;
        const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
        let allowedFileExt =[];
        if(type=="profilePicture"){
            allowedFileExt = ['jpg','jpeg','png'];
        } else {
            allowedFileExt = ['jpg','jpeg','png','doc','docx','txt','pdf'];
        }


        let respdataFlag =  true;
        let fileUplErMsg = "";

        let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed ;
        if(fileObj.length <= nowtotAllowedCntOfFileUpl){
            let totCurSize  = 0; let totalInvalidExtAr = [];
            Object.keys(fileObj).map((k,i)=>{
                totCurSize = totCurSize + fileObj[k].size;
                let nameFl = fileObj[k].name;
                let extnReceived = nameFl.split('.').pop();
                if(allowedFileExt.indexOf(extnReceived)==-1){
                    totalInvalidExtAr.push(extnReceived);
                    respdataFlag = false;
                    fileUplErMsg = "File Type not supported.";
                }
            });

            if( (totCurSize) > totAllowedMaxFileUploadSize){
                //error
                respdataFlag = false;
                fileUplErMsg = "You have crossed total maximum upload file size.";
            }

        }else{
            //error
            respdataFlag = false;
            fileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";

        }
        if(type=="registeredPracticeDoc"){
            this.isComponentLoaded && this.setState({ registeredPracticeDocIdErrMsg: fileUplErMsg});
        }else if (type == "profilePicture") {
            this.isComponentLoaded && this.setState({ profilePictureErrMsg: fileUplErMsg});
        } else if(type == "barCertificate"){
            this.isComponentLoaded && this.setState({ barCertificateIdErrMsg: fileUplErMsg});
        } else if(type == "graduationCertificate"){
            this.isComponentLoaded && this.setState({ graduationCertificateIdErrMsg: fileUplErMsg});
        } else if(type == "resumeDoc"){
            this.isComponentLoaded && this.setState({ resumeDocIdErrMsg: fileUplErMsg});
        } else if(type == "panDoc"){
            this.isComponentLoaded && this.setState({ panDocErrMsg: fileUplErMsg});
        } else if(type == "gstinDoc"){
            this.isComponentLoaded && this.setState({ gstinDocErrMsg: fileUplErMsg});
        }

        return respdataFlag;
    }
    async calUploadFileService(fileObj,type){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new FileUploadService();
        if(type=='registeredPracticeDoc'){
            this.isComponentLoaded && this.setState({registeredPracticeDocIdUplodingFlag:true});
        } else if (type == "profilePicture"){
            this.isComponentLoaded && this.setState({profilePictureIdUploadingFlag:true});
        } else if (type == "barCertificate"){
            this.isComponentLoaded && this.setState({barCertificateIdUploadingFlag:true});
        } else if (type == "graduationCertificate"){
            this.isComponentLoaded && this.setState({graduationCertificateIdUploadingFlag:true});
        } else if (type == "resumeDoc"){
            this.isComponentLoaded && this.setState({resumeDocIdUploadingFlag:true});
        } else if (type == "panDoc"){
            this.isComponentLoaded && this.setState({panDocUploadingFlag:true});
        } else if (type == "gstinDoc"){
            this.isComponentLoaded && this.setState({gstinDocUploadingFlag:true});
        }



        let taskTypeFlag = 0;

        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj,
            "fileUploadFor":type,
            "fileUploadedFromPageSection":"KYC"
       };
  
        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity[0];
            //console.log(entityObj)
            if(type=="registeredPracticeDoc"){
                this.setState({registeredPracticeDocId:entityObj.id,registeredPracticeDoc:entityObj});
            } else if(type == "profilePicture"){
                this.setState({profilePictureId:entityObj.id,profilePicture:entityObj});
            } else if(type == "barCertificate"){
                this.setState({barCertificateId:entityObj.id,barCertificate:entityObj});
            } else if(type == "graduationCertificate"){
                this.setState({graduationCertificateId:entityObj.id,graduationCertificate:entityObj});
            } else if(type == "resumeDoc"){
                this.setState({resumeDocId:entityObj.id,resumeDoc:entityObj});
            } else if(type == "panDoc"){
                this.setState({panDocId:entityObj.id,panDoc:entityObj});
            } else if(type == "gstinDoc"){
                this.setState({gstinDocId:entityObj.id,gstinDoc:entityObj});
            }

        }).catch((err)=>{
                //console.log.log("server error=>",e);
                let errStatusCodeAr = [400,500,403]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log.log(" errMsg on task creation==>",errMsg);

                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,

                        "dismiss": {
                        "duration": 2500,
                        }

                        });
                    }
                }
        }).finally(()=>{
            if(type=="registeredPracticeDoc"){
                this.registeredPracticeDocIdRef.current.value = "";
                this.isComponentLoaded && this.setState({"registeredPracticeDocIdUplodingFlag":false});
            } else if(type=="profilePicture" ) {
                this.profilePictureIdRef.current.value = "";
                this.isComponentLoaded && this.setState({"profilePictureIdUploadingFlag":false});
            } else if(type=="barCertificate" ) {
                this.barCertificateIdRef.current.value = "";
                this.isComponentLoaded && this.setState({"barCertificateIdUploadingFlag":false});
            } else if(type=="graduationCertificate" ) {
                this.graduationCertificateIdRef.current.value = "";
                this.isComponentLoaded && this.setState({"graduationCertificateIdUploadingFlag":false});
            } else if(type=="resumeDoc" ) {
                this.resumeDocIdRef.current.value = "";
                this.isComponentLoaded && this.setState({"resumeDocIdUploadingFlag":false});
            } else if(type=="panDoc" ) {
                this.panDocRef.current.value = "";
                this.isComponentLoaded && this.setState({"panDocUploadingFlag":false});
            } else if(type=="gstinDoc" ) {
                this.gstinDocRef.current.value = "";
                this.isComponentLoaded && this.setState({"gstinDocUploadingFlag":false});
            }

        });
    }
    removeFile(type){
        if(type=="registeredPracticeDoc"){
            this.setState({registeredPracticeDocId:null,registeredPracticeDoc:''});
        } else if( type=="profilePicture" ){
            this.setState({profilePictureId:null,profilePicture:''});
        }else if(type=="barCertificate" ) {
            this.setState({barCertificateId:null,barCertificate:''});
        } else if(type=="graduationCertificate" ) {
            this.setState({graduationCertificateId:null,graduationCertificate:''});
        } else if(type=="resumeDoc" ) {
            this.setState({resumeDocId:null,resumeDoc:''});
        } else if(type=="panDoc" ) {
            this.setState({panDocId:null,panDoc:''});
        } else if(type=="gstinDoc" ) {
            this.setState({gstinDocId:null,gstinDoc:''});
        }
    }
    onSelectPracticeArea=(selectedList, selectedItem)=>{
        //console.log("onSelectPracticeArea=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
        let prevPracticeAreaIds =this.state.practiceAreaIds;
        prevPracticeAreaIds.push(selectedItem.id);
        this.setState({practiceAreas:selectedList, practiceAreaIds:prevPracticeAreaIds})
    }
    onRemovePracticeArea = (selectedList, removedItem) => {
        //console.log("from remove selectedList==>",selectedList,removedItem);
        let prevPracticeAreaIds =this.state.practiceAreaIds;
        prevPracticeAreaIds = prevPracticeAreaIds.filter(el => el!=removedItem.id);
        this.setState({practiceAreas:selectedList, practiceAreaIds:prevPracticeAreaIds})
    }
    graduationYearChange = date => {
        ////console.log(date)
        this.setState({
            graduationYearForCalender: date
        });
        let selectedDate = new Date(date);
        var selectedYear = selectedDate.getFullYear();
        //console.log(selectedYear);
        this.setState({graduationYear:selectedYear});
    };
    custDraftValidate = () =>{
        let officialPhoneNumberErrMsg='';
        let aboutThePracticeErrMsg = "";
        let barRegistrationNumberErrMsg = "";
        let panErrMsg="";
        let ifscErrMsg="";
        let referenceEmailAddressOneErrMsg=" ";
        let referenceEmailAddressTwoErrMsg = "";

        let regexBlankSpace = /^ *$/;
        let regexPhoneNumber = /^\d{10}$/;
        let regexPanNumber = /^[a-zA-Z0-9]{10}$/;
        let regexIfscNumber = /^[a-zA-Z0-9]{11}$/;

        let totErrCountTrack = [];
        if((this.state.officialPhoneNumber=="")|| (regexPhoneNumber.test(this.state.officialPhoneNumber)==true) || (this.state.officialPhoneNumber ==null)){
            this.isComponentLoaded &&  this.setState({ "officialPhoneNumberErrMsg":''});
        } else{
            officialPhoneNumberErrMsg = "Pnone Number must be 10 digit & numeric";
            this.isComponentLoaded && this.setState({ "officialPhoneNumberErrMsg": officialPhoneNumberErrMsg});
            totErrCountTrack.push(officialPhoneNumberErrMsg);
        }

        if((this.state.aboutThePractice=="")|| ((this.state.aboutThePractice.split(" ").length<=50))){
            this.isComponentLoaded &&  this.setState({ "aboutThePracticeErrMsg":''});
        } else{
            aboutThePracticeErrMsg = "Must be less than 50 words";
            this.isComponentLoaded && this.setState({ "aboutThePracticeErrMsg": aboutThePracticeErrMsg});
            totErrCountTrack.push(aboutThePracticeErrMsg);
        }
        if((this.state.isBarRegistered=='true')&& (this.state.barRegistrationNumber == "")){
            barRegistrationNumberErrMsg = "Please provide your bar registration number or please do not mark you are bar admitted";
            this.isComponentLoaded && this.setState({ "barRegistrationNumberErrMsg": barRegistrationNumberErrMsg});
            totErrCountTrack.push(barRegistrationNumberErrMsg);
        } else{
            this.isComponentLoaded &&  this.setState({ "barRegistrationNumberErrMsg":''});
        }
        if((this.state.pan=="")|| (regexPanNumber.test(this.state.pan)==true)){
            this.isComponentLoaded &&  this.setState({ "panErrMsg":''});
        } else{
            panErrMsg = "Pan Number must be 10 digit";
            this.isComponentLoaded && this.setState({ "panErrMsg": panErrMsg});
            totErrCountTrack.push(panErrMsg);
        }
        if((this.state.ifsc=="")|| (regexIfscNumber.test(this.state.ifsc)==true)){
            this.isComponentLoaded &&  this.setState({ "ifscErrMsg":''});
        } else{
            ifscErrMsg = "IFSC Number must be 11 digit";
            this.isComponentLoaded && this.setState({ "ifscErrMsg": ifscErrMsg});
            totErrCountTrack.push(ifscErrMsg);
        }
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.referenceEmailAddressOne)==false) && ((this.state.referenceEmailAddressOne.length!=0)|| (this.state.referenceEmailAddressOne) ||(regexBlankSpace.test(this.state.referenceEmailAddressOne)==false) )) {
            referenceEmailAddressOneErrMsg = "Provide a proper Email-ID.";
            this.isComponentLoaded && this.setState({ "referenceEmailAddressOneErrMsg": referenceEmailAddressOneErrMsg});
            totErrCountTrack.push(referenceEmailAddressOneErrMsg);
        } else {
            this.isComponentLoaded && this.setState({"referenceEmailAddressOneErrMsg":""})
        }
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.referenceEmailAddressTwo)==false) && ((this.state.referenceEmailAddressTwo.length!=0)|| (this.state.referenceEmailAddressTwo) ||(regexBlankSpace.test(this.state.referenceEmailAddressTwo)==false) )) {
            referenceEmailAddressTwoErrMsg = "Provide a proper Email-ID.";
            this.isComponentLoaded && this.setState({ "referenceEmailAddressTwoErrMsg": referenceEmailAddressTwoErrMsg});
            totErrCountTrack.push(referenceEmailAddressTwoErrMsg);
        } else {
            this.isComponentLoaded && this.setState({"referenceEmailAddressTwoErrMsg":""})
        }

        //console.log(this.state)
        //console.log(totErrCountTrack)
        if(totErrCountTrack.length>0){
            return false;
        }

        return true;
    }
    saveDraft = (e)=>{
        e.preventDefault();

        const isValid = this.custDraftValidate();
        if(isValid){

            // this.officeCityRef.current.state.value='';
            ////console.log(this.officeCityRef.current.state.value)
            let refEmailAddress=[];
            //console.log(this.state.referenceEmailAddressOne);
            //console.log(this.state.referenceEmailAddressTwo);
            if(this.state.referenceEmailAddressOne){

                refEmailAddress.push(this.state.referenceEmailAddressOne);
            }
            if(this.state.referenceEmailAddressTwo){

                refEmailAddress.push(this.state.referenceEmailAddressTwo);
            }
            ////console.log(refEmailAddress)
            this.setState({referenceEmailAddresses:refEmailAddress},()=>{
            this.drftKyc();
            });
            ////console.log(this.state)
        }else{
            window.scrollTo(0, this.refDivTop.current.offsetTop);
            let notiMsg = "Please check the fields, there are some improper data";
            notify(notiMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "showCloseButton": false,
    
                            "dismiss": {
                            "duration": 3000,
                            }
    
                    });
        }

    }

    drftKyc(){
        let service = new UpdateKycService();
        service.updateKyc(this.state).then(data =>{
            if(data.status==200){
                let notiMsg = "Your KYC details have been saved. Please complete all fields and click on ‘Submit’ for verification.";
                        notify(notiMsg,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,

                        "dismiss": {
                        "duration": 1000,
                        }

                });
                this.callServiceToFetchKycDetails()
            }
        }).catch((err)=>{

                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log(errMsg);
                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,

                        "dismiss": {
                        "duration": 2500,
                        }

                        });
                }
                }
        }).finally(()=>{

        });
    }

    custValidate= () =>{
        let practiceTypeErrMsg ="";
        let practiceOwnerNameErrMsg = "";
        let lawFirmNameErrMsg = "";
        let websiteErrMsg = '';
        let linkedInUrlErrMsg = '';
        let officialPhoneNumberErrMsg='';
        let officeCityErrMsg = '';
        let registeredPracticeTypeErrMsg = '';
        let regPracticeTypeOptionErrMsg = '';
        let registeredPracticeDocIdErrMsg = "";
        let practiceAreaIdsErrMsg ="";
        let locationsActiveErrMsg = "";
        let aboutThePracticeErrMsg = "";
        let profilePictureErrMsg = "";
        let graduationUniversityIdErrMsg = "";
        let graduationYearErrMsg = "";
        let isBarRegisteredErrMsg = "";
        let barRegistrationNumberErrMsg = "";
        let barRegisteredStateIdErrMsg = '';
        let barCertificateIdErrMsg='';
        let graduationCertificateIdErrMsg = '';
        let billingContactNameErrMsg = '';
        let billingEmailAddressErrMsg = '';
        let billingAddressErrMsg = '';
        let panErrMsg = '';
        let panDocErrMsg = '';
        let gstinErrMsg = '';
        let gstinDocErrMsg = '';
        let beneficiaryNameErrMsg = '';
        let bankAccountNumberErrMsg= '';
        let bankNameErrMsg='';
        let branchNameErrMsg='';
        let bankAccountTypeErrMsg='';
        let ifscErrMsg='';
        let workExperienceErrMsg='';
        let referenceEmailAddressOneErrMsg=" ";
        let referenceEmailAddressTwoErrMsg = "";
        let resumeDocIdErrMsg = "";

        let regexBlankSpace = /^ *$/;
        let regexPhoneNumber = /^\d{10}$/;
        let regexPanNumber = /^[a-zA-Z0-9]{10}$/;
        let regexIfscNumber = /^[a-zA-Z0-9]{11}$/;

        let aboutYourPracErCategFlag = false;
        let aboutPracOwnErCategFlag = false;
        let billDetailErCategFlag = false;
        


        let totErrCountTrack = [];

        if(!(this.state.practiceType)||(this.state.practiceType.length==0)||(regexBlankSpace.test(this.state.practiceType)==true)){
            practiceTypeErrMsg = "Practice Type is required.";
            this.isComponentLoaded && this.setState({ "practiceTypeErrMsg": practiceTypeErrMsg});
            totErrCountTrack.push(practiceTypeErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "practiceTypeErrMsg":''});
        }
        if(!(this.state.practiceOwnerName)||(this.state.practiceOwnerName.length==0)||(regexBlankSpace.test(this.state.practiceOwnerName)==true)){
            practiceOwnerNameErrMsg = "Practice Type is required.";
            this.isComponentLoaded && this.setState({ "practiceOwnerNameErrMsg": practiceOwnerNameErrMsg});
            totErrCountTrack.push(practiceOwnerNameErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "practiceOwnerNameErrMsg":''});
        }
        if(!(this.state.lawFirmName)||(this.state.lawFirmName.length==0)||(regexBlankSpace.test(this.state.lawFirmName)==true)){
            lawFirmNameErrMsg = "Law firm name is required.";
            this.isComponentLoaded && this.setState({ "lawFirmNameErrMsg": lawFirmNameErrMsg});
            totErrCountTrack.push(lawFirmNameErrMsg);
            aboutYourPracErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "lawFirmNameErrMsg":''});
        }
        if((this.state.hasWebsiteUrl=="Yes") && (!(this.state.website)||(this.state.website.length==0)||(regexBlankSpace.test(this.state.website)==true))){
            websiteErrMsg = "Website url is required.";
            this.isComponentLoaded && this.setState({ "websiteErrMsg": websiteErrMsg});
            totErrCountTrack.push(websiteErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "websiteErrMsg":''});
        }
        if((this.state.hasLinkedInUrl=="Yes") && (!(this.state.linkedInUrl)||(this.state.linkedInUrl.length==0)||(regexBlankSpace.test(this.state.linkedInUrl)==true))){
            linkedInUrlErrMsg = "Linked url url is required.";
            this.isComponentLoaded && this.setState({ "linkedInUrlErrMsg": linkedInUrlErrMsg});
            totErrCountTrack.push(linkedInUrlErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "linkedInUrlErrMsg":''});
        }
        if((this.state.officialPhoneNumber!="")&& (regexPhoneNumber.test(this.state.officialPhoneNumber)==true)){
            this.isComponentLoaded &&  this.setState({ "officialPhoneNumberErrMsg":''});
        } else if ((!(this.state.officialPhoneNumber)||(this.state.officialPhoneNumber.length==0)||(regexBlankSpace.test(this.state.officialPhoneNumber)==true))){
            officialPhoneNumberErrMsg = "Pnone Number must be required";
            this.isComponentLoaded && this.setState({ "officialPhoneNumberErrMsg": officialPhoneNumberErrMsg});
            totErrCountTrack.push(officialPhoneNumberErrMsg);
            aboutYourPracErCategFlag = true;
        }else{
            officialPhoneNumberErrMsg = "Pnone Number must be 10 digit & numeric";
            this.isComponentLoaded && this.setState({ "officialPhoneNumberErrMsg": officialPhoneNumberErrMsg});
            totErrCountTrack.push(officialPhoneNumberErrMsg);
            aboutYourPracErCategFlag = true;
        }

        if(!(this.state.officeCity)||(this.state.officeCity.length=='')){
            officeCityErrMsg = "Office city is required.";
            this.isComponentLoaded && this.setState({ "officeCityErrMsg": officeCityErrMsg});
            totErrCountTrack.push(officeCityErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "officeCityErrMsg":''});
        }

        if(!(this.state.registeredPracticeType)||(this.state.registeredPracticeType.length==0)||(regexBlankSpace.test(this.state.registeredPracticeType)==true)){
            registeredPracticeTypeErrMsg = "Registered Practice Type is required.";
            this.isComponentLoaded && this.setState({ "registeredPracticeTypeErrMsg": registeredPracticeTypeErrMsg});
            totErrCountTrack.push(registeredPracticeTypeErrMsg);
        } else{
            this.isComponentLoaded &&  this.setState({ "registeredPracticeTypeErrMsg":''});
        }
        if((this.state.registeredPracticeType=='REGISTERED') && (!(this.state.regPracticeTypeOption)||(this.state.regPracticeTypeOption.length==0)||(regexBlankSpace.test(this.state.regPracticeTypeOption)==true))){
            regPracticeTypeOptionErrMsg = "Type of registration is required.";
            this.isComponentLoaded && this.setState({ "regPracticeTypeOptionErrMsg": regPracticeTypeOptionErrMsg});
            totErrCountTrack.push(regPracticeTypeOptionErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "regPracticeTypeOptionErrMsg":''});
        }

        if((this.state.registeredPracticeType=='REGISTERED') && (!(this.state.registeredPracticeDoc)||(this.state.registeredPracticeDoc.length==0)||(regexBlankSpace.test(this.state.registeredPracticeDoc)==true))){
            registeredPracticeDocIdErrMsg = "Registration or Incorporation Document Proof is required.";
            this.isComponentLoaded && this.setState({ "registeredPracticeDocIdErrMsg": registeredPracticeDocIdErrMsg});
            totErrCountTrack.push(registeredPracticeDocIdErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "registeredPracticeDocIdErrMsg":''});
        }

        if((!(this.state.practiceAreaIds)||(this.state.practiceAreaIds.length==0)||(regexBlankSpace.test(this.state.practiceAreaIds)==true))){
            practiceAreaIdsErrMsg = "Practice areas are required.";
            this.isComponentLoaded && this.setState({ "practiceAreaIdsErrMsg": practiceAreaIdsErrMsg});
            totErrCountTrack.push(practiceAreaIdsErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "practiceAreaIdsErrMsg":''});
        }

        if((!(this.state.locationsActive)||(this.state.locationsActive.length==0)||(regexBlankSpace.test(this.state.locationsActive)==true))){
            locationsActiveErrMsg = "Locations are required.";
            this.isComponentLoaded && this.setState({ "locationsActiveErrMsg": locationsActiveErrMsg});
            totErrCountTrack.push(locationsActiveErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "locationsActiveErrMsg":''});
        }

        if(((!(this.state.aboutThePractice)||(this.state.aboutThePractice.length==0)||(regexBlankSpace.test(this.state.aboutThePractice)==true)))){
            aboutThePracticeErrMsg = "About your practice is required ";
            this.isComponentLoaded && this.setState({ "aboutThePracticeErrMsg": aboutThePracticeErrMsg});
            totErrCountTrack.push(aboutThePracticeErrMsg);
            aboutYourPracErCategFlag = true;
        } else if((this.state.aboutThePractice.split(" ").length>50)){
            aboutThePracticeErrMsg = "About your practice within 50 words";
            this.isComponentLoaded && this.setState({ "aboutThePracticeErrMsg": aboutThePracticeErrMsg});
            totErrCountTrack.push(aboutThePracticeErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "aboutThePracticeErrMsg":''});
        }

        if((!(this.state.profilePicture)||(this.state.profilePicture.length==0)||(regexBlankSpace.test(this.state.profilePicture)==true))){
            profilePictureErrMsg = "Profile picture is required.";
            this.isComponentLoaded && this.setState({ "profilePictureErrMsg": profilePictureErrMsg});
            totErrCountTrack.push(profilePictureErrMsg);
            aboutYourPracErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "profilePictureErrMsg":''});
        }

        if((!(this.state.graduationUniversityId)||(this.state.graduationUniversityId.length==0)||(regexBlankSpace.test(this.state.graduationUniversityId)==true))){
            graduationUniversityIdErrMsg = "University/Law School is required.";
            this.isComponentLoaded && this.setState({ "graduationUniversityIdErrMsg": graduationUniversityIdErrMsg});
            totErrCountTrack.push(graduationUniversityIdErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "graduationUniversityIdErrMsg":''});
        }

        if((!(this.state.graduationYear)||(this.state.graduationYear.length==0)||(regexBlankSpace.test(this.state.graduationYear)==true))){
            graduationYearErrMsg = "Graduation year is required.";
            this.isComponentLoaded && this.setState({ "graduationYearErrMsg": graduationYearErrMsg});
            totErrCountTrack.push(graduationYearErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "graduationYearErrMsg":''});
        }

        if((!(this.state.isBarRegistered)||(this.state.isBarRegistered.length==0)||(regexBlankSpace.test(this.state.isBarRegistered)==true))){
            isBarRegisteredErrMsg = "Registered with any Bar is required.";
            this.isComponentLoaded && this.setState({ "isBarRegisteredErrMsg": isBarRegisteredErrMsg});
            totErrCountTrack.push(isBarRegisteredErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "isBarRegisteredErrMsg":''});
        }

        if((this.state.isBarRegistered=="true")&&(!(this.state.barRegistrationNumber)||(this.state.barRegistrationNumber.length==0)||(regexBlankSpace.test(this.state.barRegistrationNumber)==true))){
            barRegistrationNumberErrMsg = "Bar registration number is required.";
            this.isComponentLoaded && this.setState({ "barRegistrationNumberErrMsg": barRegistrationNumberErrMsg});
            totErrCountTrack.push(barRegistrationNumberErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "barRegistrationNumberErrMsg":''});
        }

        if((this.state.isBarRegistered=="true")&&(!(this.state.barRegisteredStateId)||(this.state.barRegisteredStateId.length==0)||(regexBlankSpace.test(this.state.barRegisteredStateId)==true))){
            barRegisteredStateIdErrMsg = "Registration state is required.";
            this.isComponentLoaded && this.setState({ "barRegisteredStateIdErrMsg": barRegisteredStateIdErrMsg});
            totErrCountTrack.push(barRegisteredStateIdErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "barRegisteredStateIdErrMsg":''});
        }

        if((this.state.isBarRegistered=="true")&& (!(this.state.barCertificate)||(this.state.barCertificate.length==0)||(regexBlankSpace.test(this.state.barCertificate)==true))){
            barCertificateIdErrMsg = " Bar certificate is required.";
            this.isComponentLoaded && this.setState({ "barCertificateIdErrMsg": barCertificateIdErrMsg});
            totErrCountTrack.push(barCertificateIdErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "barCertificateIdErrMsg":''});
        }

        if((this.state.isBarRegistered=="false")&& (!(this.state.graduationCertificate)||(this.state.graduationCertificate.length==0)||(regexBlankSpace.test(this.state.graduationCertificate)==true))){
            graduationCertificateIdErrMsg = " Graduation certificate is required.";
            this.isComponentLoaded && this.setState({ "graduationCertificateIdErrMsg": graduationCertificateIdErrMsg});
            totErrCountTrack.push(graduationCertificateIdErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "graduationCertificateIdErrMsg":''});
        }

        if(!(this.state.workExperience)||(this.state.workExperience.length==0)||(regexBlankSpace.test(this.state.workExperience)==true)){
            workExperienceErrMsg = "Work Experience is required.";
            this.isComponentLoaded && this.setState({ "workExperienceErrMsg": workExperienceErrMsg});
            totErrCountTrack.push(workExperienceErrMsg);
            aboutPracOwnErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "workExperienceErrMsg":''});
        }
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.referenceEmailAddressOne)==false) && ((this.state.referenceEmailAddressOne.length!=0)|| (this.state.referenceEmailAddressOne) ||(regexBlankSpace.test(this.state.referenceEmailAddressOne)==false) )) {
            referenceEmailAddressOneErrMsg = "Provide a proper Email-ID.";
            this.isComponentLoaded && this.setState({ "referenceEmailAddressOneErrMsg": referenceEmailAddressOneErrMsg});
            totErrCountTrack.push(referenceEmailAddressOneErrMsg);
            aboutPracOwnErCategFlag = true;
        } else {
            this.isComponentLoaded && this.setState({"referenceEmailAddressOneErrMsg":""})
        }
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.referenceEmailAddressTwo)==false) && ((this.state.referenceEmailAddressTwo.length!=0)|| (this.state.referenceEmailAddressTwo) ||(regexBlankSpace.test(this.state.referenceEmailAddressTwo)==false) )) {
            referenceEmailAddressTwoErrMsg = "Provide a proper Email-ID.";
            this.isComponentLoaded && this.setState({ "referenceEmailAddressTwoErrMsg": referenceEmailAddressTwoErrMsg});
            totErrCountTrack.push(referenceEmailAddressTwoErrMsg);
            aboutPracOwnErCategFlag = true;
        } else {
            this.isComponentLoaded && this.setState({"referenceEmailAddressTwoErrMsg":""})
        }

        if(!(this.state.resumeDocId)){
          
            resumeDocIdErrMsg = "Resume is required.";
            this.isComponentLoaded && this.setState({ "resumeDocIdErrMsg": resumeDocIdErrMsg});
            totErrCountTrack.push(resumeDocIdErrMsg);
            aboutPracOwnErCategFlag = true;
        } else {
            this.isComponentLoaded && this.setState({"resumeDocIdErrMsg":""})
        }

        if(!(this.state.billingContactName)||(this.state.billingContactName.length==0)||(regexBlankSpace.test(this.state.billingContactName)==true)){
            billingContactNameErrMsg = "Billing contact person is required.";
            this.isComponentLoaded && this.setState({ "billingContactNameErrMsg": billingContactNameErrMsg});
            totErrCountTrack.push(billingContactNameErrMsg);
            billDetailErCategFlag = true;
            
        } else {
            this.isComponentLoaded &&  this.setState({ "billingContactNameErrMsg":''});
        }

        if(!(this.state.billingContactName)||(this.state.billingContactName.length==0)||(regexBlankSpace.test(this.state.billingContactName)==true)){
            billingContactNameErrMsg = "Billing contact person is required.";
            this.isComponentLoaded && this.setState({ "billingContactNameErrMsg": billingContactNameErrMsg});
            totErrCountTrack.push(billingContactNameErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "billingContactNameErrMsg":''});
        }
        if(!(this.state.billingEmailAddress)||(this.state.billingEmailAddress.length==0)||(regexBlankSpace.test(this.state.billingEmailAddress)==true)){
            billingEmailAddressErrMsg = "Billing email ID is required.";
            this.isComponentLoaded && this.setState({ "billingEmailAddressErrMsg": billingEmailAddressErrMsg});
            totErrCountTrack.push(billingEmailAddressErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "billingEmailAddressErrMsg":''});
        }
        if(!(this.state.billingEmailAddress)||(this.state.billingEmailAddress.length==0)||(regexBlankSpace.test(this.state.billingEmailAddress)==true)){
            billingEmailAddressErrMsg = "Billing email ID is required.";
            this.isComponentLoaded && this.setState({ "billingEmailAddressErrMsg": billingEmailAddressErrMsg});
            totErrCountTrack.push(billingEmailAddressErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "billingEmailAddressErrMsg":''});
        }
        if(!(this.state.billingAddress)||(this.state.billingAddress.length==0)||(regexBlankSpace.test(this.state.billingAddress)==true)){
            billingAddressErrMsg = "Billing address ID is required.";
            this.isComponentLoaded && this.setState({ "billingAddressErrMsg": billingAddressErrMsg});
            totErrCountTrack.push(billingAddressErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "billingAddressErrMsg":''});
        }

        if(!(this.state.pan)||(this.state.pan.length==0)||(regexBlankSpace.test(this.state.pan)==true)){
            panErrMsg = "PAN No is required.";
            this.isComponentLoaded && this.setState({ "panErrMsg": panErrMsg});
            totErrCountTrack.push(panErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "panErrMsg":''});
        }

        if((this.state.pan!="")&& (regexPanNumber.test(this.state.pan)==true)){
            this.isComponentLoaded &&  this.setState({ "panErrMsg":''});
        } else if ((!(this.state.pan)||(this.state.pan.length==0)||(regexBlankSpace.test(this.state.pan)==true))){
            panErrMsg = "Pan No. must be required";
            this.isComponentLoaded && this.setState({ "panErrMsg": panErrMsg});
            totErrCountTrack.push(panErrMsg);
            billDetailErCategFlag = true;
        }else{
            panErrMsg = "Pan No must be 10 digit";
            this.isComponentLoaded && this.setState({ "panErrMsg": panErrMsg});
            totErrCountTrack.push(panErrMsg);
            billDetailErCategFlag = true;
        }

        if((!(this.state.panDoc)||(this.state.panDoc.length==0)||(regexBlankSpace.test(this.state.panDoc)==true))){
            panDocErrMsg = "Pan Doc is required.";
            this.isComponentLoaded && this.setState({ "panDocErrMsg": panDocErrMsg});
            totErrCountTrack.push(panDocErrMsg);
            billDetailErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "panDocErrMsg":''});
        }

        if((this.state.isGSTRegistered=='Yes') && (!(this.state.gstin)||(this.state.gstin.length==0)||(regexBlankSpace.test(this.state.gstin)==true))){
            gstinErrMsg = "Pan Doc is required.";
            this.isComponentLoaded && this.setState({ "gstinErrMsg": gstinErrMsg});
            totErrCountTrack.push(gstinErrMsg);
            billDetailErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "gstinErrMsg":''});
        }

        if((this.state.isGSTRegistered=='Yes') && (!(this.state.gstinDoc)||(this.state.gstinDoc.length==0)||(regexBlankSpace.test(this.state.gstinDoc)==true))){
            gstinDocErrMsg = "GSTIN Registration certificate is required.";
            this.isComponentLoaded && this.setState({ "gstinDocErrMsg": gstinDocErrMsg});
            totErrCountTrack.push(gstinDocErrMsg);
            billDetailErCategFlag = true;
        } else{
            this.isComponentLoaded &&  this.setState({ "gstinDocErrMsg":''});
        }

        if(!(this.state.beneficiaryName)||(this.state.beneficiaryName.length==0)||(regexBlankSpace.test(this.state.beneficiaryName)==true)){
            beneficiaryNameErrMsg = "Benificiary name is required.";
            this.isComponentLoaded && this.setState({ "beneficiaryNameErrMsg": beneficiaryNameErrMsg});
            totErrCountTrack.push(beneficiaryNameErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "beneficiaryNameErrMsg":''});
        }
        if(!(this.state.bankAccountNumber)||(this.state.bankAccountNumber.length==0)||(regexBlankSpace.test(this.state.bankAccountNumber)==true)){
            bankAccountNumberErrMsg = "Account number is required.";
            this.isComponentLoaded && this.setState({ "bankAccountNumberErrMsg": bankAccountNumberErrMsg});
            totErrCountTrack.push(bankAccountNumberErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "bankAccountNumberErrMsg":''});
        }


        if(!(this.state.bankName)||(this.state.bankName.length==0)||(regexBlankSpace.test(this.state.bankName)==true)){
            bankNameErrMsg = "Bank name is required.";
            this.isComponentLoaded && this.setState({ "bankNameErrMsg": bankNameErrMsg});
            totErrCountTrack.push(bankNameErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "bankNameErrMsg":''});
        }

        if(!(this.state.branchName)||(this.state.branchName.length==0)||(regexBlankSpace.test(this.state.branchName)==true)){
            branchNameErrMsg = "Branch name is required.";
            this.isComponentLoaded && this.setState({ "branchNameErrMsg": branchNameErrMsg});
            totErrCountTrack.push(branchNameErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "branchNameErrMsg":''});
        }

        if(!(this.state.bankAccountType)||(this.state.bankAccountType.length==0)||(regexBlankSpace.test(this.state.bankAccountType)==true)){
            bankAccountTypeErrMsg = "Type of account is required.";
            this.isComponentLoaded && this.setState({ "bankAccountTypeErrMsg": bankAccountTypeErrMsg});
            totErrCountTrack.push(bankAccountTypeErrMsg);
            billDetailErCategFlag = true;
        } else {
            this.isComponentLoaded &&  this.setState({ "bankAccountTypeErrMsg":''});
        }

        if((this.state.ifsc!="")&& (regexIfscNumber.test(this.state.ifsc)==true)){
            this.isComponentLoaded &&  this.setState({ "ifscErrMsg":''});
        } else if ((!(this.state.ifsc)||(this.state.ifsc.length==0)||(regexBlankSpace.test(this.state.ifsc)==true))){
            ifscErrMsg = "IFSC No. must be required";
            this.isComponentLoaded && this.setState({ "ifscErrMsg": ifscErrMsg});
            totErrCountTrack.push(ifscErrMsg);
            billDetailErCategFlag = true;
        }else{
            ifscErrMsg = "IFSC No must be 11 digit";
            this.isComponentLoaded && this.setState({ "ifscErrMsg": ifscErrMsg});
            totErrCountTrack.push(ifscErrMsg);
            billDetailErCategFlag = true;
        }
        
       
        //console.log(totErrCountTrack);
        this.isComponentLoaded && this.setState(
            {
                "aboutYourPracErCategFlag":aboutYourPracErCategFlag,                
                "aboutPracOwnErCategFlag":aboutPracOwnErCategFlag,
                "billDetailErCategFlag":billDetailErCategFlag,
            
            })
        if(totErrCountTrack.length>0){
            
            return false;
        }
    
        return true;

    }
    saveKycData = (e)=>{
        e.preventDefault();

        const isValid = this.custValidate();
        //console.log("+++++++++++ state=>>",this.state," isValid=>",isValid);
        //console.log(isValid);
        //return false;
        if(isValid){

            // this.officeCityRef.current.state.value='';
            ////console.log(this.officeCityRef.current.state.value)
            let refEmailAddress=[];
            //console.log(this.state.referenceEmailAddressOne);
            //console.log(this.state.referenceEmailAddressTwo);
            if(this.state.referenceEmailAddressOne){

                refEmailAddress.push(this.state.referenceEmailAddressOne);
            }
            if(this.state.referenceEmailAddressTwo){

                refEmailAddress.push(this.state.referenceEmailAddressTwo);
            }
            ////console.log(refEmailAddress)
            this.setState({referenceEmailAddresses:refEmailAddress},()=>{
            this.saveKyc();
            });
            ////console.log(this.state)
        }else{
            
            window.scrollTo(0, this.refDivTop.current.offsetTop);
            let notiMsg = "Please check the fields, there are some improper data";
            notify(notiMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "showCloseButton": false,
    
                            "dismiss": {
                            "duration": 3000,
                            }
    
                    });
        }
    }
    saveKyc(){
        let service = new SaveKycService();
        service.saveKyc(this.state).then(data =>{
            if(data.status==200){
                
                setAccessObj({"key":"isKycVerified","keyValue":false});
                let notiMsg = "Submitted successfully";
                        notify(notiMsg,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,

                        "dismiss": {
                        "duration": 1000,
                        }

                });
                this.callServiceToFetchKycDetails()
            }
        }).catch((err)=>{

                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);
                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,
                        "dismiss": {
                            "duration": 2500,
                        }

                        });
                }
                }
        }).finally(()=>{

        });
    }

    render() {
        return (
            <>

                    <div ref={this.refDivTop} className="kyc_section_part_01">
                        <div className="kycSectionAlertMsgBg">
                        <CustomMessageBar />
                        </div>

                        <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section lp_settingContAreaBg">

                            <div className="cutom_container cutom_container_userSetting">
                                <div className="create_task_area userSetting_area">


                                    <div className="task_area_filterbg task_area_filterbg_lp">
                                        <div className="filter_area ">

                                            <div className="top_filter_left top_filter_left_lp">
                                                <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">

                                                    <div className="profile_setting_ulbg pr_set_ulbg">
                                                        <ul>
                                                            <li className="active">
                                                                <NavLink to="/kyc" className="btn_appearance_none task_ass_tabBtn">
                                                                    <span className="task_ass_span">KYC Details</span>
                                                                </NavLink >
                                                            </li>
                                                            <li >
                                                                <NavLink  to="/profile" className="btn_appearance_none task_ass_tabBtn">
                                                                    <span className="task_ass_span">Profile</span>
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="clearboth"></div>
                                                </div>
                                            </div>

                                            <div className="clearboth"></div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                    <div className=" text-center ">
                        {this.state.loaderFlag ? this.loadLoader() : null}
                    </div>
                    <div className="kyc_section_part_02">
                        <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section lp_settingContAreaBg">

                            <div className="cutom_container cutom_container_userSetting">
                                <div className="create_task_area userSetting_area">

                                    <div className="task_area userSetting_task_area">

                                        <div className="userSetting_section">

                                            <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">

                                                <div className="userSetting_area_bg">


                                                    {!this.state.kycVerifiedStatus && <div className="kyc_top_noti_bg">
                                                        <div className="kyc_top_noti_box">
                                                            <p><b>Verification Pending.</b> You will only be able to view open tasks that you may be eligible for, once your account verification is complete</p>
                                                        </div>
                                                    </div>}

                                                    <div className="userSetting_area user_page_area">
                                                        <div className="userSetting_scroll">

                                                            <div className="user_kyc_bg">

                                                                
                                                                <div className="accordian_container">
                                                                    
                                                                    {/* <div className="react_use_set_scroll user_scroll mCustomScrollbar height_550"> */}
                                                                    {/* <Scrollbars
                                                                        ref={this.scrollerRef}
                                                                        style={{ height: 520 }}
                                                                        //autoHeightMin={520}
                                                                        renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                                                        > */}

                                                                        <div id="accordion" className="accordion kyc_accordion_sec">
                                                                            <div className="card mb-0 ">

                                                                                <div className="kyc_accor_block">
                                                                                    <div className="card-header collapsed kyc_accor_title" data-toggle="collapse" href="#collapse1">
                                                                                        <a className="card-title">
                                                                                            About Your Practice
                                                                                          {this.state.aboutYourPracErCategFlag && (
                                                                                              <span className="acco_title_errorIcon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                                                          ) }  
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse1" className="card-body collapse kyc_accor_fromBg" data-parent="#accordion" >

                                                                                        <div className="s_midbox s_midbox_kyc">
                                                                                            <div className="main_form_box">


                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Type of professional</b></label>
                                                                                                        <div className="form__group field">
                                                                                                            <div className="input_datashow">
                                                                                                                <p>{this.state.professionalType == "LAWYER"? "Lawyer":"Non Lawyer"}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Type of practice</b></label>
                                                                                                        <div className={`form__group field ${ this.state.practiceTypeErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={ this.handleChange } name="practiceType" id="practiceType" value={this.state.practiceType? this.state.practiceType:""}>
                                                                                                                <option value="">Select</option>
                                                                                                                <option value="Professional" >Professional</option>
                                                                                                                <option value="Law Firm">Law Firm</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.practiceTypeErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Full Name of Practice Owner or <br/> Partner of Firm</b></label>
                                                                                                        <div className={`form__group field ${ this.state.practiceOwnerNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="practiceOwnerName" id='practiceOwnerName' onChange={ this.handleChange } value={this.state.practiceOwnerName?this.state.practiceOwnerName:''}/>
                                                                                                            <span className="error_span">{this.state.practiceOwnerNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Do you want to add a Prefix <br/> to your name (Optional)</b></label>
                                                                                                        <div className="form__group field ">
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="namePrefix" id='namePrefix' onChange={ this.handleChange } value={this.state.namePrefix?this.state.namePrefix:''}/>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Practice / Law Firm Name</b></label>
                                                                                                        <div className="form__group field">
                                                                                                            <div className="form_block_custom_checkBox">
                                                                                                                <label className="custom_check_label">Same as Practice Owner
                                                                                                                    <input type="checkbox" onChange={this.lawFirmNameCheck} checked={this.state.isLawFirmNameSameAsPracticeOwner}/>
                                                                                                                    <span className="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </div>
                                                                                                    {!this.state.isLawFirmNameSameAsPracticeOwner && <div className="panel50 panel_half paddingleft15 form__group_normar" >
                                                                                                        <label htmlFor="name" className="form__label form__label_blank">&nbsp;</label>
                                                                                                        <div className={`form__group field ${ this.state.lawFirmNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="lawFirmName" id='lawFirmName' onChange={ this.handleChange } value={this.state.lawFirmName?this.state.lawFirmName:''} />
                                                                                                            <span className="error_span">{this.state.lawFirmNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Website</b></label>
                                                                                                        <div className="form__group field ">
                                                                                                            <select className="selectpicker dropup form__field" onChange={ (e)=>{this.setState({hasWebsiteUrl:e.target.value})} } name="hasWebsiteUrl" id="hasWebsiteUrl" value={this.state.hasWebsiteUrl? this.state.hasWebsiteUrl:""}>
                                                                                                                <option value="Yes">Yes</option>
                                                                                                                <option value="No">No</option>
                                                                                                            </select>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.hasWebsiteUrl=='Yes' && <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Enter website URL</b></label>
                                                                                                        <div className={`form__group field ${ this.state.websiteErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="website" id='website' onChange={ this.handleChange } value={this.state.website?this.state.website:''}/>
                                                                                                            <span className="error_span">{this.state.websiteErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>LinkedIn profile</b></label>
                                                                                                        <div className="form__group field ">
                                                                                                            <select className="selectpicker dropup form__field" onChange={ (e)=>{this.setState({hasLinkedInUrl:e.target.value})} } name="hasLinkedInUrl" id="hasLinkedInUrl" value={this.state.hasLinkedInUrl=="Yes"? "Yes":"No"}>
                                                                                                                <option value="Yes">Yes</option>
                                                                                                                <option value="No">No</option>
                                                                                                            </select>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.hasLinkedInUrl=='Yes' && <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Enter LinkedIn profile URL</b></label>
                                                                                                        <div className={`form__group field ${ this.state.linkedInUrlErrMsg?"form_group_error": ""}`}>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="linkedInUrl" id='linkedInUrl' onChange={ this.handleChange } value={this.state.linkedInUrl?this.state.linkedInUrl:''}/>
                                                                                                            <span className="error_span">{this.state.linkedInUrlErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Official email ID</b></label>
                                                                                                        <div className="form__group field">
                                                                                                            <div className="input_datashow">
                                                                                                                <p>{this.state.officialEmailAddress}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Official mobile number</b></label>
                                                                                                        <div className="form__group field mobileNo_iput ">
                                                                                                            <div className={`multi_input_block ${ this.state.officialPhoneNumberErrMsg?"form_group_error": ""}` }>
                                                                                                                <span className="multi_input_span">+91</span>
                                                                                                                <input className="multi_form__field" placeholder="" type="text" name="officialPhoneNumber" id='officialPhoneNumber' onChange={ this.handleChange } value={this.state.officialPhoneNumber? this.state.officialPhoneNumber:''}/>
                                                                                                            </div><span className="error_span">{this.state.officialPhoneNumberErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>City of Office</b></label>
                                                                                                        <div className={`form__group field ${ this.state.officeCityErrMsg?"form_group_error": ""}` }>

                                                                                                            <GooglePlacesAutocomplete autocompletionRequest={{
    types: ['(cities)' ]
  }} inputClassName="form__field" onSelect={( info ) => {
                                                                                                                this.getLatLongFromResult(info)
                                                                                                                }} placeholder={"Type active cities/towns"} initialValue={this.state.officeCityName?this.state.officeCityName:''} ref={this.officeCityRef}/>

                                                                                                            <span className="error_span">{this.state.officeCityErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Registration status</b></label>
                                                                                                        <div className={`form__group field ${ this.state.registeredPracticeTypeErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={ (e)=>{this.setState({registeredPracticeType:e.target.value})} } name="registeredPracticeType" id="registeredPracticeType" value={this.state.registeredPracticeType}>
                                                                                                                <option value="">Select</option>
                                                                                                                <option value="INDEPENDENT_ADVOCATE" >Independent Advocate</option>
                                                                                                                <option value="REGISTERED" >Registered</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.registeredPracticeTypeErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {(this.state.registeredPracticeType=='REGISTERED' ) && <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Type of registration</b></label>
                                                                                                        <div className={`form__group field ${ this.state.regPracticeTypeOptionErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={ (e)=>{this.setState({regPracticeTypeOption:e.target.value})} } name="regPracticeTypeOption" id="regPracticeTypeOption" value={this.state.regPracticeTypeOption? this.state.regPracticeTypeOption:""}>
                                                                                                                <option value="">Select</option>
                                                                                                                <option value="Registered Sole Proprietorship">Registered Sole Proprietorship</option>
                                                                                                                <option value="Unregistered Partnership">Unregistered Partnership</option>
                                                                                                                <option value="Registered Partnership">Registered Partnership</option>
                                                                                                                <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
                                                                                                                <option value="Private Limited Company">Private Limited Company</option>
                                                                                                                <option value="Other">Other</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.regPracticeTypeOptionErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                {(this.state.registeredPracticeType=='REGISTERED') && <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">

                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.registeredPracticeDocIdErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>Upload Practice Registration or Incorporation Document Proof </p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn" ><span>{this.state.registeredPracticeDocIdUplodingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file" ref={this.registeredPracticeDocIdRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'registeredPracticeDoc')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.registeredPracticeDocIdErrMsg}</span>
                                                                                                            {this.state.registeredPracticeDoc && <div className="kyc_upFile_listblock">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box"><span className="upfile_name">{this.state.registeredPracticeDoc.name}</span>
                                                                                                                        <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('registeredPracticeDoc')}}>
                                                                                                                            <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>}

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Practice Areas (upto 10)</b></label>

                                                                                                        <div className="form__group field" >
                                                                                                        <div className={`multiselectouterdivcls ${ (this.state.practiceAreaOptions.length==0)|| (this.state.practiceAreas.length==10)?"disabled disabled_without_dim":""} ${this.state.practiceAreaIdsErrMsg?"form_group_error":""}`}>
                                                                                                            <Multiselect
                                                                                                                options={this.state.practiceAreaOptions} // Options to display in the dropdown
                                                                                                                selectedValues={this.state.practiceAreas} // Preselected value to persist in dropdown
                                                                                                                onSelect={this.onSelectPracticeArea} // Function will trigger on select event
                                                                                                                onRemove={this.onRemovePracticeArea} // Function will trigger on remove event
                                                                                                                displayValue="value" // Property name to display in the dropdown options
                                                                                                                selectionLimit="10" 
                                                                                                            />
                                                                                                        </div>
                                                                                                        <span className="error_span">{this.state.practiceAreaIdsErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Locations Active (upto 5)</b></label>
                                                                                                        <div className={`form__group field ${ this.state.locationsActiveErrMsg?"form_group_error": ""}` }>


                                                                                                            <GooglePlacesAutocomplete idPrefix={"locactivegoog"} initialValue={this.state.locationActiveDefaultName}  autocompletionRequest={{
    types: ['(cities)' ]
  }}
 placeholder={"Type active cities/towns"}   inputClassName="form__field" onSelect={( info ) => {
    //this.locationCityRef.current.state.value='';
                                                                                                     this.onLocationSelect(info)
                                                                                                                }}  ref={this.locationCityRef} disabled={this.state.locationsActive.length>=5?true:false}/>

                                                                                                            <span className="error_span">{this.state.locationsActiveErrMsg}</span>
                                                                                                           <div className="kyc_upFile_listblock" >
                                                                                                                <div className="custom_fileup_name" >
                                                                                                                {this.state.locationsActive.length>0 && this.state.locationsActive.map((el,i)=><div className="upfile_name_box" key={el.placeId}><span className="upfile_name">{el.cityName}</span>
                                                                                                                        <button className="upfile_name_cross btn_appearance_none" >
                                                                                                                            <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded" onClick={(e)=>{this.removeLocation(el.placeId)}}/>
                                                                                                                        </button>
                                                                                                                    </div>)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>About Your Practice (50 words)</b></label>
                                                                                                        <div className={`form__group field ${ this.state.aboutThePracticeErrMsg?"form_group_error": ""}` }>
                                                                                                            <textarea placeholder="Enter description"  name="aboutThePractice" id='aboutThePractice' onChange={ this.handleChange } value={this.state.aboutThePractice? this.state.aboutThePractice:''}></textarea>
                                                                                                            <span className="error_span">{this.state.aboutThePracticeErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.profilePictureErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>Upload profile photo</p>
                                                                                                                    <p>Note: Face needs to be clearly visible, professional photo is preferred</p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.profilePictureIdUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file"  ref={this.profilePictureIdRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'profilePicture')}}/>
                                                                                                                            
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.profilePictureErrMsg}</span>
                                                                                                            {this.state.profilePicture && <div className="kyc_upFile_listblock">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box"><span className="upfile_name">{this.state.profilePicture.name}</span>
                                                                                                                    <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('profilePicture')}}>
                                                                                                                        <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>
                    

                                                                                            </div>
                                                                                        </div>    

                                                                                    </div>
                                                                                </div>

                                                                                <div className="kyc_accor_block">
                                                                                    <div className="card-header collapsed kyc_accor_title" data-toggle="collapse" href="#collapse2">
                                                                                        <a className="card-title">
                                                                                            About the Practice Owner
                                                                                            {this.state.aboutPracOwnErCategFlag && (
                                                                                              <span className="acco_title_errorIcon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                                                          ) }
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse2" className="card-body collapse kyc_accor_fromBg" data-parent="#accordion" >

                                                                                        <div className="s_midbox s_midbox_kyc">
                                                                                            <div className="main_form_box">

                                                                                                
                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>University/Law School</b></label>
                                                                                                        <div className={`form__group field kyc_autocomp_div ${ this.state.graduationUniversityIdErrMsg?"form_group_error": ""}` }>
                                                                                                            {/* <select className="selectpicker dropup form__field" onChange={this.handleChange } name="graduationUniversityId" id="graduationUniversityId" value={this.state.graduationUniversityId? this.state.graduationUniversityId:""}>
                                                                                                                <option value="" >Select</option>
                                                                                                            {this.state.universityOptions.length>0 && this.state.universityOptions.map((el)=><option value={el.id} key={el.id}>{el.name}</option>) }

                                                                                                            </select> */}
                                                                                                                
                                                                                                            {/* newly added auto comp starts */}
                                                                                                            <CustomAutoComplete ref={this.customUnivAutoCompRel} cuselemtkeydata={"univercustautocomp"} showselectedontextbox={"YES"}  callservicefordata={this.getUniversityData} optionselected={this.universitySelected} customclass={"genericcustautocompparentclass"} placeholdertext={"Search University"} defaulttext={this.state.graduationUniversityName}  />
                                                                                                            {/* newly added auto comp ends */}
                                                                                                            <span className="error_span">{this.state.graduationUniversityIdErrMsg}</span>
                                                                                                            
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar" >
                                                                                                        <label htmlFor="name" className="form__label"><b>Graduation Year</b></label>
                                                                                                        <div className={`form__group field custdatePickerFld ${ this.state.graduationYearErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="input-group date" id="sandbox-container">

                                                                                                                <DatePicker className="form__field customdatefld" selected={this.state.graduationYearForCalender} 
    maxDate={new Date()} 
    
    
 
 
  onChange={this.graduationYearChange} showYearPicker dateFormat="yyyy"/>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.graduationYearErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Registered with any Bar?</b></label>
                                                                                                        <div className={`form__group field ${ this.state.isBarRegisteredErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={this.handleChange } name="isBarRegistered" id="isBarRegistered" value={this.state.isBarRegistered} >
                                                                                                                <option value="">Select</option>
                                                                                                                <option value='true'>Yes</option>
                                                                                                                <option value='false'>No</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.isBarRegisteredErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.isBarRegistered == 'true' && <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Bar Registration Number</b></label>
                                                                                                        <div className={`form__group field ${ this.state.barRegistrationNumberErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={this.handleChange } name="barRegistrationNumber" id="barRegistrationNumber" value={this.state.barRegistrationNumber}/>
                                                                                                            <span className="error_span">{this.state.barRegistrationNumberErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                {this.state.isBarRegistered == 'true' && <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Registration State</b></label>
                                                                                                        <div className={`form__group field ${ this.state.barRegisteredStateIdErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={this.handleChange } name="barRegisteredStateId" id="barRegisteredStateId" value={this.state.barRegisteredStateId? this.state.barRegisteredStateId:""}>
                                                                                                                <option value="">Select</option>
                                                                                                                {this.state.registrationStatesOptions.length>0 && this.state.registrationStatesOptions.map((el)=><option value={el.id} key={el.id}>{el.name}</option>) }
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.barRegisteredStateIdErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>}

                                                                                                {this.state.isBarRegistered == 'true' && <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.barCertificateIdErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>Upload Bar Certificate</p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.barCertificateIdUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file"  ref={this.barCertificateIdRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'barCertificate')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.barCertificateIdErrMsg}</span>
                                                                                                            {this.state.barCertificate && <div className="kyc_upFile_listblock ">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box">
                                                                                                                        <span className="upfile_name">{this.state.barCertificate.name}</span>
                                                                                                                        <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('barCertificate')}}>
                                                                                                                            <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                        </button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>}

                                                                                                {this.state.isBarRegistered == 'false' && <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.graduationCertificateIdErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>Upload Graduation Certificate</p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.graduationCertificateIdUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file" ref={this.graduationCertificateIdRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'graduationCertificate')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.graduationCertificateIdErrMsg}</span>
                                                                                                            {this.state.graduationCertificate &&<div className="kyc_upFile_listblock ">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box">
                                                                                                                        <span className="upfile_name">{this.state.graduationCertificate.name}</span>
                                                                                                                        <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('graduationCertificate')}}>
                                                                                                                            <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                        </button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>}

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.resumeDocIdErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>
                                                                                                                    Upload resume                                                                                                      {/*  (Optional) */}

                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.resumeDocIdUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file" ref={this.resumeDocIdRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'resumeDoc')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.resumeDocIdErrMsg}</span>
                                                                                                            {this.state.resumeDoc && <div className="kyc_upFile_listblock">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box"><span className="upfile_name">{this.state.resumeDoc.name}</span>
                                                                                                                    <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('resumeDoc')}}>
                                                                                                                        <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                    </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Work Experience</b></label>
                                                                                                        <div className={`form__group field ${ this.state.workExperienceErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={this.handleChange } name="workExperience" id="workExperience" value={this.state.workExperience? this.state.workExperience:""}>
                                                                                                                <option value="">Select</option>
                                                                                                                <option value="1-4">1-4</option>
                                                                                                                <option value="5-10">5-10</option>
                                                                                                                <option value="11-15">11-15</option>
                                                                                                                <option value="16-25">16-25</option>
                                                                                                                <option value="26-50">26-50</option>
                                                                                                                <option value="50+">50+</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.workExperienceErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Reference 01 email (Optional)</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.referenceEmailAddressOneErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={this.handleChange } name="referenceEmailAddressOne" id="referenceEmailAddressOne" value={this.state.referenceEmailAddressOne? this.state.referenceEmailAddressOne:""}/>
                                                                                                            <span className="error_span">{this.state.referenceEmailAddressOneErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Reference 02 email (Optional)</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.referenceEmailAddressTwoErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={this.handleChange } name="referenceEmailAddressTwo" id="referenceEmailAddressTwo" value={this.state.referenceEmailAddressTwo? this.state.referenceEmailAddressTwo:""}/>
                                                                                                            <span className="error_span">{this.state.referenceEmailAddressTwoErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>    

                                                                                    </div>
                                                                                </div>

                                                                                <div className="kyc_accor_block">
                                                                                    <div className="card-header collapsed kyc_accor_title" data-toggle="collapse" href="#collapse3">
                                                                                        <a className="card-title">
                                                                                            Billing Details
                                                                                            {this.state.billDetailErCategFlag && (
                                                                                              <span className="acco_title_errorIcon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                                                                                          ) }
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse3" className="card-body collapse kyc_accor_fromBg" data-parent="#accordion" >

                                                                                        <div className="s_midbox s_midbox_kyc">
                                                                                            <div className="main_form_box">


                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Billing contact person</b></label>
                                                                                                        <div className="form__group field form_group_error">
                                                                                                            <div className="form_block_custom_checkBox">
                                                                                                                <label className="custom_check_label">Same as Practice Owner
                                                                                                                    <input type="checkbox" onChange={this.billingContactPersonNameCheck} checked={this.state.isBillingContactNameSameAsPracticeOwner} />
                                                                                                                    <span className="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {!this.state.isBillingContactNameSameAsPracticeOwner && <div className="panel50 panel_half paddingleft15 form__group_normar" >
                                                                                                        <label htmlFor="name" className="form__label form__label_blank">&nbsp;</label>
                                                                                                        <div className={`form__group field ${ this.state.billingContactNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="billingContactName" id='billingContactName' onChange={ this.handleChange } value={this.state.billingContactName?this.state.billingContactName:''} />
                                                                                                            <span className="error_span">{this.state.billingContactNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Billing email ID</b></label>
                                                                                                        <div className="form__group field form_group_error">
                                                                                                            <div className="form_block_custom_checkBox">
                                                                                                                <label className="custom_check_label">Same as practice email ID
                                                                                                                    <input type="checkbox" onChange={this.billingEmailAddressCheck} checked={this.state.isBillingEmailAddressSameAsPracticeEmailId}/>
                                                                                                                    <span className="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {!this.state.isBillingEmailAddressSameAsPracticeEmailId && <div className="panel50 panel_half paddingleft15 form__group_normar" >
                                                                                                        <label htmlFor="name" className="form__label form__label_blank">&nbsp;</label>
                                                                                                        <div className={`form__group field ${ this.state.billingEmailAddressErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="billingEmailAddress" id='billingEmailAddress' onChange={ this.handleChange } value={this.state.billingEmailAddress?this.state.billingEmailAddress:''} />
                                                                                                            <span className="error_span">{this.state.billingEmailAddressErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Billing Address</b></label>
                                                                                                        <div className={`form__group field ${ this.state.billingAddressErrMsg?"form_group_error": ""}` }>
                                                                                                            <textarea placeholder="Enter" name="billingAddress" id='billingAddress' onChange={ this.handleChange } value={this.state.billingAddress?this.state.billingAddress:''}></textarea>
                                                                                                            <span className="error_span">{this.state.billingAddressErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>PAN No.</b></label>
                                                                                                        <div className={`form__group field ${ this.state.panErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" name="pan" id='pan' onChange={ this.handleChange } value={this.state.pan?this.state.pan:''}/>
                                                                                                            <span className="error_span">{this.state.panErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.panDocErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>Upload scanned copy of PAN card</p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.panDocUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file" ref={this.panDocRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'panDoc')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.panDocErrMsg}</span>
                                                                                                            {this.state.panDoc && <div className="kyc_upFile_listblock">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box"><span className="upfile_name">{this.state.panDoc.name}</span>
                                                                                                                    <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('panDoc')}}>
                                                                                                                        <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>GST registered?</b></label>
                                                                                                        <div className={`form__group field ${ this.state.isBarRegisteredErrMsg?"form_group_error": ""}` }>

                                                                                                            <select className="selectpicker dropup form__field" onChange={ this.handleChange } name="isGSTRegistered" id="isGSTRegistered" value={this.state.isGSTRegistered=="Yes"? "Yes":"No"}>
                                                                                                                <option value="Yes" >Yes</option>
                                                                                                                <option value="No">No</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.isBarRegisteredErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.isGSTRegistered=='Yes' && <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>GSTIN Number</b></label>
                                                                                                        <div className={`form__group field ${ this.state.gstinErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={ this.handleChange } name="gstin" id="gstin" value={this.state.gstin? this.state.gstin:""}/>
                                                                                                            <span className="error_span">{this.state.gstinErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                {this.state.isGSTRegistered=='Yes' && <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.gstinDocErrMsg?"form_group_error": ""}` }>
                                                                                                            <div className="kyc_file_up">
                                                                                                                <div className="kyc_file_up_left">
                                                                                                                    <p>GSTIN  Registration certificate</p>
                                                                                                                </div>
                                                                                                                <div className="kyc_file_up_right">
                                                                                                                    <div className="kyc_file_up_btn">
                                                                                                                        <div className="upload-btn-wrapper">
                                                                                                                            <label className="new_upbutton" htmlFor="upload">
                                                                                                                                <button className="btn_appearance_none green_btn"><span>{this.state.gstinDocUploadingFlag? 'Uploading...':'Upload'}</span></button>
                                                                                                                            </label>
                                                                                                                            <input type="file" ref={this.gstinDocRef} name="myfile" onChange={(e)=>{this.trackFileSelection(e,'gstinDoc')}}/>
                                                                                                                            <div className="clearboth"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="error_span">{this.state.gstinDocErrMsg}</span>
                                                                                                            {this.state.gstinDoc && <div className="kyc_upFile_listblock">
                                                                                                                <div className="custom_fileup_name">
                                                                                                                    <div className="upfile_name_box">
                                                                                                                        <span className="upfile_name">{this.state.gstinDoc.name}</span>
                                                                                                                        <button className="upfile_name_cross btn_appearance_none" onClick={()=>{this.removeFile('gstinDoc')}}>
                                                                                                                            <img src={getBaseUrl()+"assets/images/error.png"} className="mCS_img_loaded"/>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>}
                                                                                                            <hr/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>}

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Benificiary name</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.beneficiaryNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={ this.handleChange } name="beneficiaryName" id="beneficiaryName" value={this.state.beneficiaryName? this.state.beneficiaryName:""}/>
                                                                                                            <span className="error_span">{this.state.beneficiaryNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Account number</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.bankAccountNumberErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={ this.handleChange } name="bankAccountNumber" id="bankAccountNumber" value={this.state.bankAccountNumber? this.state.bankAccountNumber:""}/>
                                                                                                            <span className="error_span">{this.state.bankAccountNumberErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>
                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Bank Name</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.bankNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={ this.handleChange } name="bankName" id="bankName" value={this.state.bankName? this.state.bankName:""}/>
                                                                                                            <span className="error_span">{this.state.bankNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_half">
                                                                                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Branch name</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.branchNameErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text"  onChange={ this.handleChange } name="branchName" id="branchName" value={this.state.branchName? this.state.branchName:""}/>
                                                                                                            <span className="error_span">{this.state.branchNameErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>Type of account</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.bankAccountTypeErrMsg?"form_group_error": ""}` }>
                                                                                                            <select className="selectpicker dropup form__field" onChange={ this.handleChange } name="bankAccountType" id="bankAccountType" value={this.state.bankAccountType? this.state.bankAccountType:""}>
                                                                                                                <option value="SAVINGS">Savings</option>
                                                                                                                <option value="CURRENT">Current</option>
                                                                                                            </select>
                                                                                                            <span className="error_span">{this.state.bankAccountTypeErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>

                                                                                                <div className="form_block form_block_full">
                                                                                                    <div className="panel100 form__group_normar">
                                                                                                        <label htmlFor="name" className="form__label"><b>IFSC</b></label>
                                                                                                        <div className={`form__group field form_fileUpload_group ${ this.state.ifscErrMsg?"form_group_error": ""}` }>
                                                                                                            <input className="form__field" placeholder="Enter" type="text" onChange={ this.handleChange } name="ifsc" id="ifsc" value={this.state.ifsc? this.state.ifsc:""}/>
                                                                                                            <span className="error_span">{this.state.ifscErrMsg}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="clearboth"></div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>    

                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    {/* </Scrollbars> */}
                                                                    {/* </div> */}
                                                                </div>



                                                            </div> 

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        {((this.state.isSubmittedForKYC === false)||(this.state.isSubmittedForKYC === true && this.state.isVerified === true)) && <div className="task_details_btn_area invoPay_footer kyc_footer">
                            <div className="btn_fld">
                                <div className="panel50">
                                    <button className="btn_appearance_none green_btn" onClick={this.saveDraft}><span>Save Progress</span></button>
                                </div>
                                <div className="panel50">
                                    <div className="cancel_continue_btn">
                                        <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.props.history.push("/get-task");
                                        }} type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                                        <button className="btn_appearance_none sub_grd_btn" onClick={this.saveKycData}><span>Submit</span></button>
                                    </div>
                                </div>
                                    <div className="clearboth"></div>
                            </div>
                        </div>}

                    </div>

            </>

        )
    }
}

export default withRouter(Kyc)
