import React, { Component } from 'react'
// import Footer from './../../share/Footer'

import TaskListService from '../../service/TaskListService';
import TaskDetailsService from '../../service/TaskDetailsService';
// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

// For loading CustLoader
import CustLoader from './CustLoader';
import  CustDate  from '../../../common_utilities/CustDate';
// scroll
import { Scrollbars } from 'react-custom-scrollbars';

// for context
import CommonContext from '../../context/CommonContext';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';

import FeedbackAssistant from './FeedbackAssistant';
import TaskStatusCodeComponent from './TaskStatusCodeComponent';


// new scroll
import InfiniteScroll from 'react-infinite-scroll-component';


// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from './NotiEmitTrack';

const getBaseUrl = commonJsObj.getBaseUrl;
const getStringInitials = commonJsObj.getStringInitials;
const setAccessObj = commonJsObj.setAccessObj;
const setProfilePicture = commonJsObj.setProfilePicture;

// const selectedSortOptn = {"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"};

// let sortOptionsTaskRequest = [
//     {"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
//     {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
//     {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
//     {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
//     {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
//     {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},
// ];

// let sortOptionsTaskAssignToMe = [
//     {"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
//     {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
//     // {"sortOn":"ASSIGNED_DATE","value":"Assigned Date - Newest First","sortDir":"DESC"},
//     // {"sortOn":"ASSIGNED_DATE","value":"Assigned Date - Oldest First","sortDir":"ASC"},
//     {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
//     {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
//     {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
//     {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},
// ];

const selecetedFilterOption ={"filterType":"ALL","value":"All"};

const filterOptionTaskRequest = [
    {"filterType":"ALL","value":"All"},
    {"filterType":"TO_ASSIGN","value":"To be assigned"},
    {"filterType":"ASSIGNED_TO_OTHER","value":"Assigned to other professional"}
];
const filterOptionTaskAssignToMe = [
    {"filterType":"ALL","value":"All"},
];
export class GetTask extends Component {
    // for context
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            "listData": [],
            "pageNum": 1,
           // "pageSize":4,
            "totalNumberOfPages": 1,
            "loaderFlag": true,
            "taskId": 0, // to use for view edit delete
            "showDeleteModal": false, // for delete modal
            "kind":"TASK_REQUEST",
            "filterType":"ALL",
            "sortOn":"CREATED_DATE",
            "sortDir":"DESC",
            "sortOptions":[{"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
                {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
                {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
                {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
                {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
                {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},],
            "selectedSortOptn" : {"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
           "showList":false,
          };
        this.scrollerRef = React.createRef();
    }
    /**
     * TaskListOfSingleTask
     * Function name : loadLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : Load loader
     * Params        :
     **/

    loadLoader() {
        return (
            <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }
  
    /**
     * TaskListOfSingleTask
     * Function name : hideLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : Hide loader
     * Params        :
     **/

    hideLoader() {
         this.setState({ loaderFlag: false });
    }

    /**
     * TaskListOfSingleTask
     * Function name : showLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : showLoader
     * Params        :
     **/

    showLoader() {
         this.setState({ loaderFlag: true });
    }

    componentDidMount() {
        this.showLoader();

        //this.callServiceToFetchList("*****called from componentDidMount**** ");
        this.triggerTaskRequestList();
        this.fetchLoggedinUserInfoDetails();
    }
     componentWillUnmount() {
        this.closeSplit({"triggeredFrom":"GET_TASKS"});
    }
    fetchLoggedinUserInfoDetails=()=>{
        let loginuserserv = new LoggedinUserInfoService();
        loginuserserv.getLoggedInUserInfo({}).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                   // console.log(data.data.entity.isKycVerified)
                    // this.setState({kycVerifiedStatus:data.data.entity.isKycVerified, subscriptionStatus: data.data.entity.isSubscriptionActive},()=>{
                    //     console.log(this.state)
                    // })

                    let respObj  = data.data.entity;                    

                    if(respObj){
                        let isSubscriptionActive = false;
                        let isKycVerified = false;
                        let profilePicture = null;

                        isSubscriptionActive = respObj.isSubscriptionActive!==undefined && respObj.isSubscriptionActive!==null?respObj.isSubscriptionActive:false;
                        isKycVerified = respObj.isKycVerified!==undefined && respObj.isKycVerified!==null?respObj.isKycVerified:false;
                        profilePicture = respObj.profilePicture!==undefined && respObj.profilePicture!==null?respObj.profilePicture:null;

                        setAccessObj({"key":"isSubscriptionActive","keyValue":isSubscriptionActive});
                        setAccessObj({"key":"isKycVerified","keyValue":isKycVerified});
                        setProfilePicture({"profilePicture":profilePicture});
                        if(isKycVerified && isSubscriptionActive){
                            this.setState({showList:true},()=>{console.log(this.state)})
                        }

                    }

                   

                    
                }
            }
         }).catch(err=>{
            //console.log("Some server related tech problem occured",err);

        });
    }
    callServiceToFetchList = (trackcalledfrom)=>{
      
        //console.log(trackcalledfrom);


        let paramObjData = {};
        paramObjData.pageNum = this.state.pageNum;
        paramObjData.kind = this.state.kind;
        paramObjData.filterType = this.state.filterType;
        paramObjData.pageSize = this.state.pageSize;
        paramObjData.sortOn = this.state.sortOn;
        paramObjData.sortDir = this.state.sortDir;

        let stcservice = new TaskListService();
        stcservice.getTaskList(paramObjData).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){

                    respData  = data.data.entity;
                   // console.log(respData,"respData");
                    if(respData.taskDetails!==undefined){
                        if(respData.taskDetails.length > 0){

                            // console.log("dsddsd")
                            // set list data to state
                            let prevRecords = [...this.state.listData];
                            let currRecords = respData.taskDetails;
                            let concatenateRecords = prevRecords.concat(currRecords);
                            this.setState({"listData":concatenateRecords,"totalNumberOfPages":respData.totalNumberOfPages},()=>{
                               // console.log(this.state,"state")
                            });
                        }else{

                            if(this.state.listData.length==0){
                                this.setState({"listData":[],"totalNumberOfPages":respData.totalNumberOfPages,"pageNum":1});
                            }
                        }
                    }
            }
        }
        }).catch((err)=>{

                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log(errMsg);

                }
                }
        }).finally(()=>{

            this.setState({"tabDisableFlag":false},()=>{
                this.hideLoader();
            });
            

        });
    }

    callSetChangedSortOption = (filtObj)=>{


        let scobj = this.scrollerRef.current;
        scobj.scrollToTop();
        this.state.selectedSortOptn.sortOn=filtObj.sortOn;
        this.state.selectedSortOptn.sortDir=filtObj.sortDir;
        this.state.selectedSortOptn.value=filtObj.value;
        // console.log(filtObj,this.state.selectedSortOptn)
        this.showLoader();
        this.setState({"listData":[],"pageNum":1,"totalNumberOfPages":1,"sortOn":filtObj.sortOn,"sortDir":filtObj.sortDir},()=>{
            this.callServiceToFetchList("*****called from callSetChangedSortOption **** ");
        })

    }
    callSetChangedFilterOption = (filtObj)=>{
        //console.log(filtObj)
        let scobj = this.scrollerRef.current;
        scobj.scrollToTop();
        selecetedFilterOption.filterType=filtObj.filterType;
        selecetedFilterOption.value=filtObj.value;
        this.state.selectedSortOptn.sortOn="CREATED_DATE";
        this.state.selectedSortOptn.sortDir="DESC";
        this.state.selectedSortOptn.value="Create Date - Newest First";
        //console.log("dd",filtObj.filterType)
        if(filtObj.filterType=="ASSIGNED_TO_OTHER"){

            this.setState({
                sortOptions:[{"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
                {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
                {"sortOn":"ASSIGNED_DATE","value":"Assigned Date - Newest First","sortDir":"DESC"},
                {"sortOn":"ASSIGNED_DATE","value":"Assigned Date - Oldest First","sortDir":"ASC"},
                ]
            })
        } else {
            this.setState({
                sortOptions:[{"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
                {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
                {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
                {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
                {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
                {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},]
            })
        }

        this.showLoader();
        this.setState({"listData":[],"pageNum":1,"totalNumberOfPages":1,"filterType":filtObj.filterType,"sortOn":this.state.selectedSortOptn.sortOn,"sortDir":this.state.selectedSortOptn.sortDir},()=>{
            this.callServiceToFetchList("*****called from callSetChangedFilterOption **** ");
        })
    }

    getTaskDetailData = (param)=>{
        // let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
        // setShare(param.paramsCntxt);


        let paramObjData = param.paramObjData;
        let paramsCntxt = param.paramsCntxt;
        let lservdrf = new TaskDetailsService();
        lservdrf.getTaskDetail(paramObjData).then(data =>{

            let entityObj  = data.data.entity;
           // console.log(" sdfsdfs");
            // context share
            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
            if(setShare){
                    paramsCntxt["other"]["taskResp"]= entityObj;
                    // console.log(" paramsCntxt===>>",paramsCntxt);
                    setShare(param.paramsCntxt);

            }
        }).catch((e)=>{
            //console.log("server error=>",e);
        }).finally(()=>{

        });
    }
    
    closeSplit = (objParam)=>{
            // //********* for context starts *****
            // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
            // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
            //     if(sharedData.actionType=="SPLITSCREEN" ){
            //         let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
            //         if(setShare){
            //             let paramsCntxt = {
            //             "taskId":"",
            //             "actionType":"",
            //             "leftTabToActive":"",
            //             "purpose":"",
            //             "other":{}
            //             }
            //             setShare(paramsCntxt);
            //         }
            //     }
            // }
            // //********* for context ends *****
            commonnotitrack("CLOSE_SPLITSCREEN",{
                "notiType": "CLOSE_SPLITSCREEN",
                "triggerCallback":false,
                "trigerredFrom":"GET_TASKS",
                
               });
    }



    // trackScroll = ()=>{

    //         let scobj = this.scrollerRef.current;
    //         let scrollTop = scobj.getValues().scrollTop;
    //         let scrollHeight = scobj.getScrollHeight();
    //         let clientHeight = scobj.getClientHeight();

    //         let prevPageNum = this.state.pageNum;
    //         let totalNumberOfPages = this.state.totalNumberOfPages;

    //         //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    //         if((scrollHeight-clientHeight)>=scrollTop && prevPageNum < totalNumberOfPages){

    //         this.showLoader();

    //         setTimeout(()=>{
    //             // call list api
    //             let pageNumData = prevPageNum + 1;
    //             let paramObj = {"pageNum":pageNumData};
    //             this.setState((prevState)=>({ pageNum: prevState.pageNum+1 }),()=>{
    //                 this.callServiceToFetchList("***called from scroll bar***");
    //               })},900);


    //         }

    // }

    fetchMoreData = () =>{
        let prevPageNum = this.state.pageNum;
        let totalNumberOfPages = this.state.totalNumberOfPages;

        //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

        if(prevPageNum < totalNumberOfPages){

        this.showLoader();

        setTimeout(()=>{
            // call list api
            let pageNumData = prevPageNum + 1;
            let paramObj = {"pageNum":pageNumData};
            this.setState((prevState)=>({ pageNum: prevState.pageNum+1 }),()=>{
                this.callServiceToFetchList("***called from scroll bar***");
              })},900);


        }

    }
    reloadTaskAssignedToMe = () =>{
        //this.closeSplit();
        setTimeout(()=>{this.triggerAssignedToMe();},1200);
        
    }

    reloadTaskRequestList = () =>{
        //this.closeSplit();
        setTimeout(()=>{this.triggerTaskRequestList();},1200);
        
    }

    maketabactiveforbellnotireltonewreqrassign = (otherParam)=>{
        let tabToActive = otherParam.tabToActive!==undefined?otherParam.tabToActive:"";
        let notiType = otherParam.notiType!==undefined?otherParam.notiType:"";
        let trigerredFrom = otherParam.trigerredFrom!==undefined?otherParam.trigerredFrom:"";

        if(trigerredFrom=="BELL_NOTIFICATION_LIST"){
            if(tabToActive=="NEW_TASK_REQUEST"){
                this.reloadTaskRequestList();
            }            
            else if(tabToActive=="TASK_ASSIGNED"){
                this.reloadTaskAssignedToMe();
            }
        }
        else if(trigerredFrom=="SINGLE_TASK_DETAILS" || trigerredFrom=="PROFESSIONALS_INTERSESTED_LIST" ){
            if(notiType=="SINGLE_TASK_OFFER_ACCEPTED_SUCCESS" || notiType=="SINGLE_TASK_OFFER_DECLINE_SUCCESS"){
                this.reloadTaskAssignedToMe();
            }
            

        }
        
    }
    

    triggerAssignedToMe = ()=>{
        this.state.selectedSortOptn.sortOn="CREATED_DATE";
        this.state.selectedSortOptn.sortDir="DESC";
        this.state.selectedSortOptn.value="Create Date - Newest First";

        selecetedFilterOption.filterType="ALL";
        selecetedFilterOption.value = "All";
        this.setState({
            sortOptions:[{"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
            {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
            {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
            {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
            {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
            {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},]
        })
        let paramObj={ "listData": [],
        "pageNum": 1,
        "totalNumberOfPages": 1,
        "loaderFlag": true,
        "taskId": 0, // to use for view edit delete
        "showDeleteModal": false, // for delete modal
        "kind":"ASSIGNED_TO_ME",
        "filterType":"ALL",
        "sortOn":this.state.selectedSortOptn.sortOn,
        "sortDir":this.state.selectedSortOptn.sortDir,
        "tabDisableFlag":true
    };
        this.setState(paramObj,()=>{
            this.callServiceToFetchList(" ****called rfom triggerAssignedToMe **");
            //this.closeSplit();
        })
    }

    triggerTaskRequestList = ()=>{
        
        this.state.selectedSortOptn.sortOn="CREATED_DATE";
        this.state.selectedSortOptn.sortDir="DESC";
        this.state.selectedSortOptn.value="Create Date - Newest First";

        selecetedFilterOption.filterType="ALL";
        selecetedFilterOption.value = "All";
        this.setState({
            sortOptions:[{"sortOn":"CREATED_DATE","value":"Create Date - Newest First","sortDir":"DESC"},
            {"sortOn":"CREATED_DATE","value":"Create Date - Oldest First","sortDir":"ASC"},
            {"sortOn":"DUE_DATE","value":"Due Date - Newest First","sortDir":"DESC"},
            {"sortOn":"DUE_DATE","value":"Due Date - Oldest First","sortDir":"ASC"},
            {"sortOn":"BUDGET","value":"Budget - Highest to Lowest","sortDir":"DESC"},
            {"sortOn":"BUDGET","value":"Budget - Lowest to Highest","sortDir":"ASC"},]
        })
        let paramObj={ "listData": [],
        "pageNum": 1,
        "totalNumberOfPages": 1,
        "loaderFlag": true,
        "taskId": 0, // to use for view edit delete
        "showDeleteModal": false, // for delete modal
        "kind":"TASK_REQUEST",
        "filterType":"ALL",
        "sortOn":this.state.selectedSortOptn.sortOn,
        "sortDir":this.state.selectedSortOptn.sortDir,
        "tabDisableFlag": true
    };
        this.setState(paramObj,()=>{
            this.callServiceToFetchList(" ****called rfom triggerTaskRequestList **");
            //this.closeSplit();
        });
    }

    render() {
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        let taskIdForSplitScreen = 0;
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
            if(sharedData.actionType=="SPLITSCREEN")
            {
            taskIdForSplitScreen = sharedData.taskId!==undefined?sharedData.taskId:0;
            }

        }
        
        return (
            <React.Fragment>
                <NotiEmitTrack key={"gettasklistpage001"}  callbackofbellnotimsgclick={this.maketabactiveforbellnotireltonewreqrassign} />
                {/* Dashboard Main Screen content area start */}
                    <div className="dashboard_cont_area tasklist_cont_areabg">
                        {/*  DashBoard Main Body Start */}
                        <div className="cutom_container">
                            <div className="tab-content">

                                {!this.state.showList && (
                                         <div className="tab-pane fade in active">
                                         <div className="create_task_area create_task_area_heightAdjustment create_task_area_onlyMsg">
                                             
                                                 <div className="task_area_filterbg task_area_filterbg_lp">
                                                     <div className="filter_area ">
                                                        <div className="top_filter_left top_filter_left_task_msgBG">
                                                             <div className="top_filter_left_task_msg">
                                                                 <p>Welcome to Flywork.io you can start using the full application of the platform and receive tasks once you complete your KYC & subscription</p>
                                                                 <div className="top_filter_left_task_msg_btn">
                                                                     <button className="btn_appearance_none sub_grd_btn" onClick={(e)=>{e.preventDefault(); this.props.history.push("/kyc")}}><span>Lets go!</span></button>
                                                                 </div>
                                                             </div>
                                                         </div>
     
                                                        
                                                         
                                                         <div className="clearboth"></div>
                                                     </div>
                                                 </div>
                                                 
     
                                             
     
                                         </div>                                   
                                     </div>
     

                                )}
                                {this.state.showList && (
                                <div className="tab-pane fade in active">
                                    <div className="create_task_area create_task_area_heightAdjustment">
                                        

                                            {/* task Listing filter start */}
                                            <div className="task_area_filterbg task_area_filterbg_lp">
                                                <div className="filter_area ">
                                                    

                                                    <div className="top_filter_left top_filter_left_lp">
                                                        <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                                                        <div className="profile_setting_ulbg lp_set_ulbg">

                                                            <ul>

                                                                <li className={this.state.kind==`TASK_REQUEST`? `active`: ``}>

                                                                    <button disabled={this.state.tabDisableFlag} className='btn_appearance_none task_ass_tabBtn'  onClick={(evnt)=>{
                                                                        evnt.preventDefault();
                                                                        this.closeSplit({"triggeredFrom":"GET_TASKS"});
                                                                        this.triggerTaskRequestList();
                                                                    }}>
                                                                        <span className="task_ass_span">Task  Requests</span>

                                                                    </button>

                                                                </li>

                                                                <li  className={this.state.kind==`ASSIGNED_TO_ME` ? `active`:``}>

                                                                    <button disabled={this.state.tabDisableFlag}  className='btn_appearance_none task_ass_tabBtn' onClick={(evnt)=>{
                                                                        evnt.preventDefault();
                                                                        this.closeSplit({"triggeredFrom":"GET_TASKS"});
                                                                        this.triggerAssignedToMe();
                                                                    }} >
                                                                        <span className="task_ass_span">Assigned to me</span>

                                                                    </button>

                                                                </li>

                                                            </ul>

                                                        </div>
                                                        <div className="clearboth"></div>
                                                    </div>
                                                    </div>

                                                    <div className="top_filter_right">
                                                    <div className="top_filter top_filter_1">
                                                        <span className="span_title">Filter</span>
                                                        <button className="btn btn-secondary dropdown-toggle btn_appearance_none" type="button" data-toggle="dropdown" >{selecetedFilterOption && selecetedFilterOption.value}</button>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <ul>
                                                            {/* <li><a href="#" className="dropdown-item active">Saved Darft</a></li>
                                                            <li><a href="#" className="dropdown-item">To Be Assigned</a></li>
                                                            <li><a href="#"className="dropdown-item">Ongoing</a></li>
                                                            <li><a href="#"className="dropdown-item">Invoice Due</a></li>
                                                            <li><a href="#"className="dropdown-item">Invoice Paid</a></li>
                                                            <li><a href="#"className="dropdown-item">Closed</a></li> */}
                                                            {
                                                                this.state.kind=="TASK_REQUEST" && Object.keys(filterOptionTaskRequest).map((e,i)=> <li key={i}>
                                                                    <a href="" onClick={(ev)=>{
                                                                            ev.preventDefault();

                                                                            let filtObj = filterOptionTaskRequest[i];
                                                                            this.callSetChangedFilterOption(filtObj);


                                                                    }} className={`dropdown-item ${(selecetedFilterOption && selecetedFilterOption.filterType==filterOptionTaskRequest[i].filterType)?"active":""}`}>
                                                                        {filterOptionTaskRequest[i].value}
                                                                    </a>
                                                                    </li>)
                                                                }
                                                                {
                                                                this.state.kind=="ASSIGNED_TO_ME" && Object.keys(filterOptionTaskAssignToMe).map((e,i)=> <li key={i}>
                                                                    <a href="" onClick={(ev)=>{
                                                                            ev.preventDefault();

                                                                            let filtObj = filterOptionTaskAssignToMe[i];
                                                                            this.callSetChangedFilterOption(filtObj);


                                                                    }} className={`dropdown-item ${(selecetedFilterOption && selecetedFilterOption.sortOn==filterOptionTaskAssignToMe[i].filterType) ?"active":""}`}>
                                                                        {filterOptionTaskAssignToMe[i].value}
                                                                    </a>
                                                                    </li>)
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="top_filter top_filter_2">
                                                        <div className="dropdown">
                                                            <span className="span_title">Sort</span>
                                                            <button className="btn btn-secondary dropdown-toggle btn_appearance_none" type="button" data-toggle="dropdown">
                                                                {/* Create Date - Newest First */}
                                                                {this.state.selectedSortOptn && this.state.selectedSortOptn.value}
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <ul>
                                                                {
                                                                Object.keys(this.state.sortOptions).map((e,i)=> <li key={i}>
                                                                    <a href="" onClick={(ev)=>{
                                                                            ev.preventDefault();

                                                                            let filtObj = this.state.sortOptions[i];
                                                                            this.callSetChangedSortOption(filtObj);


                                                                    }} className={`dropdown-item ${(this.state.selectedSortOptn && this.state.selectedSortOptn.sortOn==this.state.sortOptions[i].sortOn)&&(this.state.selectedSortOptn.sortDir==this.state.sortOptions[i].sortDir)?"active":""}`}>
                                                                        {this.state.sortOptions[i].value}
                                                                    </a>
                                                                    </li>)
                                                                }


                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                  
                                                    {/*  task top filter end*/}
                                                    <div className="clearboth"></div>
                                                </div>
                                            </div>
                                            {/*  task Listing filter end */}
                                            {/*  task Listing start start */}
                                           <div className="task_area task_area_lp">

                                                {/*  Task Listing Section Start */}

                                                <div className="task_listing_scrollbg task_listing_scrollbg_lp">

                                                    <div ref={this.scrollerRef}  id="scrollableDiv"
                                                                                style={{
                                                                                
                                                                                flexDirection: 'column',
                                                                                }} className="task_listing_scroll_react mScroll_auto new_autoHeightAdjust new_autoHeightAdjust_leftTaskDominous">
                                                        {/* <Scrollbars
                                                            ref={this.scrollerRef}
                                                            style={{ height: 550 }}
                                                            autoHeightMin={550}
                                                            renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                                            onScrollStop={this.trackScroll}
                                                        > */}
                                                        <InfiniteScroll
                                                                            dataLength={this.state.listData.length} //This is important field to render the next data
                                                                            next={this.fetchMoreData}
                                                                            hasMore={true}
                                                                            
                                                                            // loader={<h4>Loading...</h4>}
                                                                            scrollableTarget="scrollableDiv"
                                                                            inverse={false}
                                                                            
                                                                            >
                                                            <div className="task_listing_area task_listing_area_lp">
                                                                <ul>
                                                                { this.state.listData.length>0 && (   Object.keys(this.state.listData).map((el, i) =>{
                                                                    let totalUnreadNegotiationMessages = this.state.listData[el].totalUnreadNegotiationMessages!==undefined?this.state.listData[el].totalUnreadNegotiationMessages:0;

                                                                    let totalUnreadComments = this.state.listData[el].totalUnreadComments!==undefined?this.state.listData[el].totalUnreadComments:0;
                                                        
                                                                    let totalNewTimeLogs = this.state.listData[el].totalNewTimeLogs!==undefined?this.state.listData[el].totalNewTimeLogs:0;

                                                                    let totalNewDocsUploaded = this.state.listData[el].totalNewDocsUploaded!==undefined?this.state.listData[el].totalNewDocsUploaded:0;
                                                        
                                                                    let totTaskListRelMsgCnt = 0;
                                                                    if(totalUnreadNegotiationMessages>0){
                                                                    ++totTaskListRelMsgCnt;
                                                                    }
                                                                    if(totalUnreadComments>0){
                                                                    ++totTaskListRelMsgCnt;
                                                                    }
                                                        
                                                                    if(totalNewTimeLogs>0){
                                                                    ++totTaskListRelMsgCnt;
                                                                    }
                                                        
                                                                    if(totalNewDocsUploaded>0){
                                                                    ++totTaskListRelMsgCnt;
                                                                    }
                                                                    return (<li key={i} className={`tasklist_li tasklist_li_lp multitask_list_li ${(this.state.listData[i].taskType!="SAVED_DRAFT" && (taskIdForSplitScreen!=this.state.listData[i].taskId))?"":" active_dominosBlock "} `} >
                                                                        <div className="task_listing_block task_listing_block_multi">
                                                                            <div className="task_popup_bg">
                                                                                <div className="task_box">
                                                                                    {/* tasktop right */}
                                                                                    <div className="assign_img task_status_top">

                                                                                        <div className="dropdown tasktop_statusbtn">
                                                                                            <button className="btn_appearance_none taskStatus_btn" type="button"  data-toggle="dropdown">
                                                                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                            </button>
                                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                                <div className="taskstat_dropmenu">
                                                                                                    <ul>
                                                                                                        {/* <li><a href="#">Delete Request</a></li> */}
                                                                                                        <li><a className={"pointer_none disabled_section"} href="" onClick={(evnt)=>{
                                                                                                            evnt.preventDefault();
                                                                                                            /*
                                                                                                                this.closeSplit({"triggeredFrom":"GET_TASKS"});
                                                                                                                // to open right side panel to view
                                                                                                                // set taskid in context starts
                                                                                                                // open right side panel to edit
                                                                                                                let paramsCntxt = {};
                                                                                                                paramsCntxt = {
                                                                                                                "taskId":this.state.listData[el].taskId,
                                                                                                                "actionType":"SPLITSCREEN",
                                                                                                                "leftTabToActive":"TASKDETAILS",
                                                                                                                "purpose":"",
                                                                                                                "other":{}
                                                                                                                }

                                                                                                                let paramObjData = {"data":{"taskId":this.state.listData[el].taskId}};
                                                                                                                let param = {"paramObjData":paramObjData,"paramsCntxt":paramsCntxt}

                                                                                                                this.getTaskDetailData(param);
                                                                                                            */
                                                                                                                // set taskid in context ends
                                                                                                        }}>Archive</a></li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="taskstatus_color">
                                                                                            {/* <span className="taskstat_clr_span taskstat_clr_gray"></span> */}

                                                                                            {/* <span className={`taskstat_clr_span
                                                                                                ${this.state.listData[el].taskKind=="DRAFTS"?"taskstat_clr_gray":""}
                                                                                                ${this.state.listData[el].taskKind=="TO_ASSIGN"?"taskstat_clr_orange":""}
                                                                                                ${(this.state.listData[el].taskKind=="ASSIGNED"||this.state.listData[el].taskKind=="INVOICE_DUE"||this.state.listData[el].taskKind=="INVOICE_PAID")?"taskstat_clr_green":""}
                                                                                                ${this.state.listData[el].taskKind=="CLOSED"?"taskstat_clr_blue":""}
                                                                                                ` } /> */}


                                                                                                <span className={`taskstat_clr_span 
                                                                                                                            ${this.state.listData[el].taskType=="UNAVAILABLE"?"taskstat_clr_gray":""}
                                                                                                                            ${this.state.listData[el].taskType=="OFFER_DECLINED"?"taskstat_clr_gray_declinedBy_lp":""}
                                                                                                                            
                                                                                                                            ${this.state.listData[el].taskType=="OPEN_TASK"?"taskstat_clr_sky":""}
                                                                                                                            ${(this.state.listData[el].taskType=="OFFER_RECEIVED")?"taskstat_clr_orange":""}
                                                                                                                            ${(this.state.listData[el].taskType=="ASSIGNED_TO_ME" )?"taskstat_clr_green":""}
                                                                                                                            ${(this.state.listData[el].taskType=="INVOICE_GENERATED" )?"taskstat_clr_red":""}
                                                                                                                            ${(this.state.listData[el].taskType=="INVOICE_PAID")?"taskstat_clr_deepGreen":""} 
                                                                                                                            ${(this.state.listData[el].taskType=="CLOSED_TASK")?"taskstat_clr_blue":""}           

                                                                                                                        ` }> 
                                                                                                                        <span className="taskstat_clr_spanName">
                                                                                                                            {this.state.listData[el].taskType=="UNAVAILABLE"?"Unavailable":""}
                                                                                                                            {this.state.listData[el].taskType=="OPEN_TASK"?"Open Task":""}
                                                                                                                            {(this.state.listData[el].taskType=="OFFER_RECEIVED")?"Offer Received":""}
                                                                                                                            {(this.state.listData[el].taskType=="ASSIGNED_TO_ME"   )?"Task Assigned":""}
                                                                                                                            {(this.state.listData[el].taskType=="INVOICE_GENERATED" )?"Invoice Generated":""}
                                                                                                                            {(this.state.listData[el].taskType=="INVOICE_PAID")?"Invoice Paid":""}
                                                                                                                            {(this.state.listData[el].taskType=="CLOSED_TASK")?"Closed Task":""}
                                                                                                                            {this.state.listData[el].taskType=="OFFER_DECLINED"?"Offer Declined":""}                   
                                                                                                                        </span>
                                                                                                                    
                                                                                                                    </span>
                                                                                            </div>
                                                                                        <div className="taskstatus_msg"><p>Messages ({totTaskListRelMsgCnt})</p></div>
                                                                                        {(totTaskListRelMsgCnt>0) && <div className="taskstatus_color taskstatus_col_new"><span className="taskstat_clr_span_new">New</span></div>}
                                                                                        
                                                                                        {/* {this.state.listData[el].taskType == "INVOICE_GENERATED" && <div className="taskstatus_flag"><a href="#"><img src={getBaseUrl()+"assets/images/invoice_due_flag.png"} alt=""/></a></div>}
                                                                                        */}
                                                                                        <div className="clearboth"></div>
                                                                                    </div>
                                                                                    {/* tasktop right */}

                                                                                    <div className="panel60 task_des">
                                                                                        <div className="task_left_desc">
                                                                                        <h1>{this.state.listData[el].practiceAreas && this.state.listData[el].practiceAreas.length>0?this.state.listData[el].practiceAreas[0].practiceArea:""}</h1>
                                                                                        <h2>{this.state.listData[el].taskName}</h2>

                                                                                        </div>
                                                                                        <div className="task_assigned">
                                                                                        {this.state.selectedSortOptn.sortOn=="DUE_DATE" &&(<h3>Due Date - {this.state.listData[el].dueDateMillis ?CustDate.custFormatDate((new Date(this.state.listData[el].dueDateMillis)),3): "None"}</h3>)}

                                                                                        {this.state.selectedSortOptn.sortOn=="CREATED_DATE" &&( <h3>Created Date - {this.state.listData[el].createdAtMillis ?CustDate.custFormatDate((new Date(this.state.listData[el].createdAtMillis)),3): "None"}</h3>)}

                                                                                        {(this.state.selectedSortOptn.sortOn=="BUDGET" )&&( <h3>Created Date - {this.state.listData[el].createdAtMillis ?CustDate.custFormatDate((new Date(this.state.listData[el].createdAtMillis)),3): "None"}</h3>)}

                                                                                        {(this.state.selectedSortOptn.sortOn=="ASSIGNED_DATE" )&&( <h3>Created Date - {this.state.listData[el].createdAtMillis ?CustDate.custFormatDate((new Date(this.state.listData[el].createdAtMillis)),3): "None"}</h3>)}
                                                                                            <div className="assign_to_block">
                                                                                                <span className="t_as_span">Created by</span>
                                                                                                <span className="t_as_ul">
                                                                                                    <ul>
                                                                                                        {/* <li><img src={getBaseUrl()+"assets/images/pic.png"} alt=""/></li>
                                                                                                        <li><div className="user_name_text"><span>AM</span></div></li>
                                                                                                        <li><span className="t_as_ul_text">+ 1 more</span></li> */}
                                                                                                        {/* {this.state.listData[el].taskOwner.profilePictureURL!=null? <li><img src={this.state.listData[el].taskOwner.profilePictureURL} alt=""/></li> : (<li><div className="user_name_text"><span>{getStringInitials(this.state.listData[el].taskOwner.firstName+' '+this.state.listData[el].taskOwner.lastName)}</span></div></li>)} */}
                                                                                                        <li>
                                                                                                        <div className="onClick_userDetails onClick_userImg">
                                                                                                            <div className="ass_user_name">
                                                                                                                <button className="btn_appearance_none assignUser_nameBtn" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                                                    {/* <img src="assets/images/user-avatar-landscape.jpg" alt=""/> */}
                                                                                                                    {this.state.listData[el].organisationBasicDetails.orgLogo!=null? <img src={this.state.listData[el].organisationBasicDetails.orgLogo} alt=""/> : (<div className="user_name_text"><span>{getStringInitials(this.state.listData[el].organisationBasicDetails.organisationName)}</span></div>)}
                                                                                                                </button>
                                                                                                                <div className="dropdown-menu user_popDetails_block" aria-labelledby="dropdownMenuButton" x-placement="top-start" style={{"position": "absolute", "will-change": "transform", "top": "0px", "left": "0px", "transform": "translate3d(0px, -143px, 0px)"}}>
                                                                                                                    <div className="user_popBox">
                                                                                                                        <div className="u_popBox_sec user_popBox_top">
                                                                                                                            <div className="user_popBox_topLeft">
                                                                                                                                <h4>{this.state.listData[el].organisationBasicDetails.organisationName}</h4>
                                                                                                                                <div className="userRating_block">
                                                                                                                                    {/* <div className="ass_userRating">
                                                                                                                                        <i className="fa fa-star" aria-hidden="true"></i>  {0}
                                                                                                                                    </div>
                                                                                                                                    <div className="ass_userExp">
                                                                                                                                        Experience - {0} years
                                                                                                                                    </div> */}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="user_popBox_topRight">
                                                                                                                            {this.state.listData[el].organisationBasicDetails.orgLogo!=null? <img src={this.state.listData[el].organisationBasicDetails.orgLogo} alt=""/> : (<div className="user_name_text"><span>{getStringInitials(this.state.listData[el].organisationBasicDetails.organisationName)}</span></div>)}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {/* <div className="u_popBox_sec user_popBox_location">
                                                                                                                            <h5>Locations</h5>
                                                                                                                            <ul>
                                                                                                                                <li>Lorem ipsum dolor sit</li>
                                                                                                                                <li>Loremipsumdolorsit</li>
                                                                                                                                <li>Loremipsum dolorsit</li>
                                                                                                                                <li>Loremipsum dolorsit</li>
                                                                                                                            </ul>
                                                                                                                        </div> */}
                                                                                                                        {/* <div className="u_popBox_sec user_popBox_pracArea">
                                                                                                                            <h5>Practice areas</h5>
                                                                                                                            <ul>
                                                                                                                                <li>Lorem ipsum dolor sit</li>
                                                                                                                                <li>Loremipsumdolorsit</li>
                                                                                                                                <li>Loremipsum dolorsit</li>
                                                                                                                                <li>Loremipsum dolorsit</li>
                                                                                                                            </ul>
                                                                                                                        </div> */}
                                                                                                                        <div className="u_popBox_sec user_popBox_about">
                                                                                                                            <h5>About</h5>
                                                                                                                            <p>{this.state.listData[el].organisationBasicDetails.aboutOrganisation}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </span>
                                                                                                </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="panel40 task_date">
                                                                                        <div className="taskfun_btnbg">
                                                                                        {(taskIdForSplitScreen!=this.state.listData[el].taskId) && <button disabled={this.state.listData[el].taskType=="UNAVAILABLE"?true:false} className={`btn_appearance_none task_func_btn task_popup ${this.state.listData[el].taskType=="UNAVAILABLE"?" pointer_none ":""}`} onClick={(evnt)=>{
                                                                                                evnt.preventDefault();
                                                                                                    this.closeSplit({"triggeredFrom":"GET_TASKS"});
                                                                                                    // to open right side panel to view
                                                                                                    // set taskid in context starts
                                                                                                    // open right side panel to edit
                                                                                                    let paramsCntxt = {};
                                                                                                    paramsCntxt = {
                                                                                                    "taskId":this.state.listData[el].taskId,
                                                                                                    "actionType":"SPLITSCREEN",
                                                                                                    "leftTabToActive": this.state.kind=="ASSIGNED_TO_ME"?"MONITORTASK":"TASKDETAILS",
                                                                                                    "purpose":"",
                                                                                                    "other":{}
                                                                                                    }

                                                                                                    let paramObjData = {"data":{"taskId":this.state.listData[el].taskId}};
                                                                                                    let param = {"paramObjData":paramObjData,"paramsCntxt":paramsCntxt}

                                                                                                    this.getTaskDetailData(param);
                                                                                                    // set taskid in context ends
                                                                                            }}>View Task</button>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="clearboth"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                )}))}
                                                                </ul>

                                                                {
                                                                    this.state.loaderFlag==false && this.state.listData.length==0 && (
                                                                    <div className=" text-center no records found">
                                                                        No records found
                                                                    </div>
                                                                    )
                                                                }
                                                                <div className=" text-center ">
                                                                    {this.state.loaderFlag ? this.loadLoader() : null}
                                                                </div>

                                                            </div>
                                                        
                                                        </InfiniteScroll>

                                                        {/* </Scrollbars> */}
                                                    </div>


                                                </div>

                                                {/*  Task Listing Section end */}
                                            </div>
                                            
                                            {/*  task Listing start end */}

                                        

                                    </div>                                   
                                </div>
                                )}


                            </div>
                        </div>
                        {/*  DashBoard Main Body End */}

                    </div>
                {/* Dashboard Main Screen content area end */}
                 {/* ===  DashBoard Main Body Footer Start ===  */}
                 
                <div className="dash_main_footerbg bottomStickFooter bottomStickFooter_leftPart">
                    <div className="dash_main_footer">
                        <div className="dash_foot_leftbg dash_foot_leftbg_lp">
                            <div className="taskstatus_bg">

                                {/* new Task Status */}
                                <TaskStatusCodeComponent />
                               {/*  new Task Status */}

                            </div>
                        </div>
                        <div className="dash_foot_rightbg">
                            <FeedbackAssistant />
                        </div>
                        <div className="clearboth"></div>
                    </div>
                </div>
               
                {/* ===  DashBoard Main Body Footer Start ===  */}
                {/* Confirm delete modal start */}
                    <CustReactBootstrapModal ref={this.modalfortaskdelRef}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showDeleteModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
                {/* Confirm delete modal end */}
                {/* <Footer/> */}

            </React.Fragment>
        )
    }
}

export default GetTask
