
import {Component } from 'react';

//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class ChatListOfNegotiationService extends Component {

    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
        this.axiosObj = null;
        
        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/chats/chat';
    }

    async getChatListOfNegotiation(paramObj){
      this.axiosObj = axiosCustomObj({});

      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            const defaultPageSize = getMaxPageSize();

            let urldata =this.apiEndPoint;
            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;

            let data  = paramObj.data;
            let ccUserId = data.ccUserId?data.ccUserId:0;
            

            let taskId = data.taskId?data.taskId:0;
           // let taskOrSubTask = data.taskOrSubTask?data.taskOrSubTask:"";
            let chatOfBudgetOrDueDate =data.chatType?data.chatType:"";
            let lastCommentId = data.lastCommentId?data.lastCommentId:0;
            let pageNum = data.pageNum?data.pageNum:1;
            let pageSize = data.pageSize?data.pageSize:defaultPageSize;

            urldata = urldata+"/chat_with/"+ccUserId+"/task/"+taskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+"&last_chat_id="+lastCommentId;

            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
       // console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default ChatListOfNegotiationService;