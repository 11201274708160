/** 
 * Signin
 * Page/Class name   : Signin
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : Signin
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';
import PropTypes from 'prop-types';


import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";

import EmailVerficationService from '../../service/EmailVerficationService';
import UserLoginService from '../../service/UserLoginService';
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
// for notification
// import { store } from 'react-notifications-component';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {notify} from '../../messagebar/CustomMessageBar';


const custEncode = commonJsObj.custEncode;
const removeUserInfoBeforeLogin = commonJsObj.removeUserInfoBeforeLogin;

class Signin extends Component {

  isComponentLoaded = false;

  

  /**
   * Signin
   * Function name : constructor
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);  
      
      this.state = {
        emailId: '',
        emailIdErMsg: '',
        password:'',
        passwordErMsg:'',
        loaderFlag: false,
        tokenRespData:{},
        loggedInUserInfoResp: {},
      }

  }

  /**
     * Signin
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/
  
  componentDidMount() {
      this.isComponentLoaded = true;
      //console.log(this.props.match.params.verifyCode)
      //console.log(this.props)
      let verifyCode =this.props.match.params.verifyCode;
      if(verifyCode!=null && verifyCode!="undefined" && verifyCode!=""){
        let lserv = new EmailVerficationService();
          lserv.getVerifyResp({verifyCode:verifyCode}).then(data =>{
              if(data.status==200){
                  //set emailId to ls for otp


                  let succMsg = "Your account has been activated.  Please login.";
                  notify(succMsg,true,{
                    'successMsg':1,
                    'customWrapClass':'customsuccclass'});
                  }
          }).catch(err=>{
            let errStatusCodeAr = [400,500,401]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    notify(errMsg,true,{
                    'successMsg':0,
                    'customWrapClass':'customerrclass'});
              }
            }
      
          }).finally(()=>{
            setTimeout(()=>{
              this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
                });
            },1500);

          });
      }

      
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }

  
  /**
     * Signin
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

  /**
   * Signin
   * Function name : showLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to show loader
   * Params        : 
  **/
  showLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: true});
  }

  /**
   * Signin
   * Function name : hideLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to hide loader
   * Params        : 
  **/
  hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
  }

  /**
   * Signin
   * Function name : loadLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to load loader
   * Params        : 
  **/
  loadLoader(){
      return (
                <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
      );
  }

  /**
   * Signin
   * Function name : handleChange
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to handle change event
   * Params        : 
  **/
  handleChange = (e) => {
    this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value},()=>{//console.log(this.state)
    });
  }

   /**
   * Signin
   * Function name : custValidate
   * Author        :
   * Created Date  : 12-2-2020
   * Purpose       : validate data before submit
   * Params        : 
  **/
  custValidate = ()=>{
    
    let emailIdErMsg = '';
    let passwordErMsg = '';
    let totErrCountTrack = [];
    let regexBlankSpace = /^ *$/;

    if(!(this.state.emailId)||(this.state.emailId.length==0)|| (regexBlankSpace.test(this.state.emailId)==true)){
      emailIdErMsg = "Email-ID is required.";
      this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
      totErrCountTrack.push(emailIdErMsg);
    }
    else if(!(this.state.password)||(this.state.password.length==0)){
      passwordErMsg = "Password is required.";
      this.isComponentLoaded && this.setState({ "passwordErMsg": passwordErMsg});
      totErrCountTrack.push(passwordErMsg);
    }
    else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailId)==false)
    {
        emailIdErMsg = "Provide a proper Email-ID.";
        this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
        totErrCountTrack.push(emailIdErMsg);
    }

    if(totErrCountTrack.length>0){
      return false;
    }     

    return true;
  }

  handleClick = (event) => {
    event.preventDefault();

    this.isComponentLoaded && this.setState({loaderFlag:true});
    const isValid = this.custValidate();
    if(isValid){
      // let userInfo = {
      //   ltoken: custEncode("Bearer  eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiRUxJVEUiLCJpZCI6NDYsInN1YiI6ImxwMUBscC5jb20iLCJleHAiOjE1OTIzMDY3ODd9.qDIV8awjN0I8kdeVvzwmTT90N8BgBPKgOfQ7xxI3UFk"),
      //   expires_at: custEncode(1592306787000),
      //   refresh_token: custEncode("0b6d8bfd-2e42-40d4-8295-8b24ffc6398f"),
      //   role: custEncode("ELITE"),
      //   userId: custEncode(46),
      //   userName: custEncode("userName"),
      //   userEmailId: custEncode("lp1@lp.com"),
      //   organisationName: custEncode(""),
      //   organisationMaxCapacity: custEncode(""),
      //   organisationCurrentCapacity : custEncode(""),
      //   shouldShowAddUserPopUp : custEncode(""),
      //   shouldShowOrganisationPopUp : custEncode(""),
      //   isProfileUpdated : custEncode(false),
      // };
      // localStorage.setItem("laluser", JSON.stringify(userInfo));
      // this.props.history.push("/get-task");





      const creds = { emailId: this.state.emailId, password: this.state.password };
      let lserv = new UserLoginService();
      lserv.getLoginResp(creds).then(data =>{
          if(data.status==200){
            this.isComponentLoaded &&  this.setState({tokenRespData:data.data});
            //**** set token and user info and redirect to dashboard page ****
            let userInfo = {
                ltoken: custEncode(this.state.tokenRespData.access_token),
                expires_at: custEncode(this.state.tokenRespData.expires_at),
                refresh_token: custEncode(this.state.tokenRespData.refresh_token),
                role: custEncode(this.state.tokenRespData.role),
                userId: custEncode(this.state.tokenRespData.userId),

              };
              localStorage.setItem("laluser", JSON.stringify(userInfo));

              setTimeout(()=>{
                
                let luiserv = new LoggedinUserInfoService();
                luiserv.getLoggedInUserInfo({}).then(data =>{

                  this.isComponentLoaded &&  this.setState({loggedInUserInfoResp:data.data.entity});

                  userInfo.accountStatus = custEncode(this.state.loggedInUserInfoResp.accountStatus);
                  userInfo.userEmailId = custEncode(this.state.emailId);
                  //userInfo.isKycVerified = custEncode(this.state.loggedInUserInfoResp.isKycVerified);
                  userInfo.isOrganisationDetailsProvided = custEncode(this.state.loggedInUserInfoResp.isOrganisationDetailsProvided);

                  //userInfo.isOrganisationKycVerified = custEncode(this.state.loggedInUserInfoResp.isOrganisationKycVerified);

                  userInfo.isProfileUpdated = custEncode(this.state.loggedInUserInfoResp.isProfileUpdated);

                  //userInfo.isSubscriptionActive = custEncode(this.state.loggedInUserInfoResp.isSubscriptionActive);
                  userInfo.lastActiveOn = custEncode(this.state.loggedInUserInfoResp.lastActiveOn);

                  userInfo.lastLogin = custEncode(this.state.loggedInUserInfoResp.lastLogin);

                  userInfo.mobileNumber = custEncode(this.state.loggedInUserInfoResp.mobileNumber);

                  userInfo.organisationDetails = custEncode(this.state.loggedInUserInfoResp.organisationDetails);
                  userInfo.organisationName = custEncode(this.state.loggedInUserInfoResp.organisationName);
                  userInfo.profilePercentage = custEncode(this.state.loggedInUserInfoResp.profilePercentage);
                  userInfo.registeredOn = custEncode(this.state.loggedInUserInfoResp.registeredOn);
                  userInfo.role = custEncode(this.state.loggedInUserInfoResp.role);
                  userInfo.shouldShowAddUserPopUp = custEncode(this.state.loggedInUserInfoResp.shouldShowAddUserPopUp);
                  userInfo.shouldShowOrganisationPopUp = custEncode(this.state.loggedInUserInfoResp.shouldShowOrganisationPopUp);
                  userInfo.spaceUsed = custEncode(this.state.loggedInUserInfoResp.spaceUsed);
                  userInfo.userId = custEncode(this.state.loggedInUserInfoResp.userId);
                  userInfo.userName = custEncode(this.state.loggedInUserInfoResp.userName);

                  userInfo.lawFirmName = custEncode(this.state.loggedInUserInfoResp.lawFirmName);
                  userInfo.profilePicture = custEncode(this.state.loggedInUserInfoResp.profilePicture);

                  let isSubscriptionActive = (this.state.loggedInUserInfoResp.isSubscriptionActive!==undefined && this.state.loggedInUserInfoResp.isSubscriptionActive!==null)?this.state.loggedInUserInfoResp.isSubscriptionActive: false;

                  let isKycVerified = (this.state.loggedInUserInfoResp.isKycVerified!==undefined && this.state.loggedInUserInfoResp.isKycVerified!==null)?this.state.loggedInUserInfoResp.isKycVerified: false;


                  let accessObj = {
                    "isSubscriptionActive":isSubscriptionActive,
                    "isKycVerified":isKycVerified,
                    

                  };

                  accessObj  = custEncode(JSON.stringify(accessObj));
                  userInfo.accessObj = accessObj;

                  localStorage.setItem("laluser", JSON.stringify(userInfo));

                  let succMsg = "Success";
                    notify(succMsg,true,{
                    'successMsg':1,
                    'customWrapClass':'customsuccclass'});
                    removeUserInfoBeforeLogin();
                   setTimeout(()=>{
                        this.props.history.push("/get-task");
                    },1200);


                }).catch(err=>{
                    //console.log("Some server related tech problem occured",err);
                });

              },250);
            }

      }).catch(err=>{

        let errStatusCodeAr = [400,500,401]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                notify(errMsg,true,{
                'successMsg':0,
                'customWrapClass':'customerrclass'});
          }
        }

      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
             // this.props.history.push("/get-task");
            });
        },1500);

      });
    }else{
      this.isComponentLoaded && this.setState({loaderFlag:false});
    }

  }

  /**
   * Signin
   * Function name : render
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){   
    
      return (
       <>
          <div className="signup_form_area lp_signup_form_area">
                  <div className="sign_up_top">
                    <h1>Sign in</h1>
                    <div className="subheading">
                      Enter your credentials below to sign in
                    </div>
                  </div>
                  <div className="form_area">
                    {/*===  form start === */}
                    <form>
                      <div className="form_block">
                        <div className="panel100 paddingleftright15 tooltip_input_bg">
                          <div className={`form__group field  ${ this.state.emailIdErMsg?'form_group_error':''}`}>
                            <input
                              type="input"
                              className="form__field"
                              placeholder="Email ID"
                              name="emailId"
                              id="emailId"       

                              ref='emailId' 
                              onChange={ this.handleChange }
                              onKeyDown={(evn)=>{
                                if(evn.keyCode==13){
                                evn.preventDefault();
                                } 
                              }}
                            />
                            <label htmlFor="name" className="form__label">
                            Email ID
                            </label>
                            <span className="error_span">{this.state.emailIdErMsg}</span>
                          </div>
                          <div className="input_tooltip_bg">
                            <span
                              className="input_tooltip"
                              aria-label="Please enter the same email ID which you used to sign up on the platform."
                              data-balloon-pos="down"
                            >
                              <i className="fa fa-question-circle" />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form_block">
                        <div className="panel100 paddingleftright15 tooltip_input_bg">
                          <div className={`form__group field  ${ this.state.passwordErMsg?'form_group_error':''}`}>
                            <input
                              type="password"
                              className="form__field"
                              placeholder="Password"
                              name="password"
                              id="password"

                              ref='password'
                              onChange={ this.handleChange }
                              onKeyDown={(evn)=>{
                                if(evn.keyCode==13){
                                evn.preventDefault();
                                } 
                              }}
                            />
                            <label htmlFor="name" className="form__label">
                              Password
                            </label>
                            <span className="error_span">{this.state.passwordErMsg}</span>
                          </div>
                          {/* <div className="input_tooltip_bg">
                            <span
                              className="input_tooltip"
                              aria-label="Password"
                              data-balloon-pos="down"
                            >
                              <i className="fa fa-question-circle" />
                            </span>
                          </div> */}
                        </div>
                      </div>
                      <div className="form_block form_block_link form_block_linkLeft">
                          <div className="paddingleftright15">
                              <a href="" onClick={()=>{this.props.history.push("/forgot-password");}}>Forgot Password?</a>
                          </div>
                      </div>
                      <div className="form_block">
                        <div className="panel100 paddingleftright15 button_ip_panel">          
                          <button disabled={this.state.loaderFlag} onClick={(event) => this.handleClick(event)}   type="button" className="btn btn-primary sub_grd_btn">
                            <span>Sign in</span>
                          </button>
                        </div>
                      </div>
                    </form>
                    {/*===  form end === */}
                    {/* ===  login bottm === */}
                    <div className="login_page_bottom">
                      <div className="log_bottm_sec">
                        <p>Don’t have an account?</p>
                        <div className="log_bottom_btn">
                          <button onClick={()=>{this.props.history.push("/sign-up");}}  className="log_page_btn">
                            <span>Sign up</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* ===  login bottm === */}
                  </div>
                </div>         
       </>
      
      );  
  }



 

}

export default withRouter(Signin);