/** 
 * Signup
 * Page/Class name   : Signup
 * Author            :
 * Created Date      : 6-2-2020
 * Functionality     : Signup
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';
import UserSignupService from '../../service/UserSignupService';
// import {custEncode} from  '../../../common_utilities/commonjsfunc';
import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';


import {notify} from '../../messagebar/CustomMessageBar';




const custEncode = commonJsObj.custEncode;
const setUserInfoBeforeLogin = commonJsObj.setUserInfoBeforeLogin;
const getBaseUrl = commonJsObj.getBaseUrl;
const getBaseUrlOfCc = commonJsObj.getBaseUrlOfCc;

const initialSate = {
  tokenRespData: {},
  loaderFlag: false,

  typeOfProfessional:'',
  firstName : '',
  lastName : '',
  emailId: '',
  password: '',
  confirmPassword:'',
  isTermsAndConditionsAccepted: false,

  typeOfProfessionalErMsg:'',
  firstNameErMsg : '',
  lastNameErMsg : '',
  emailIdErMsg: '',
  passwordErMsg: '',
  confirmPasswordErMsg:'',
  isTermsAndConditionsAcceptedErMsg: ''
};



class Signup extends Component {

  isComponentLoaded = false;

  /**
   * Signup
   * Function name : constructor
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);
      this.state = initialSate;
      this.signupButtonInput = React.createRef();
  }

  /**
     * Signup
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 6-2-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        :
    **/

  componentWillMount() {
      //LoadGoogleMap.loadGoogleMapLibrary();


  }
  componentDidMount() {
      this.isComponentLoaded = true;
      // notify("Great work done!",true,{
      //   'successMsg':1,
      //   'customWrapClass':'soumikcls2'
      // });
  }




  /**
     * Signup
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 6-2-2020
     * Purpose       : life cycle method
     * Params        :
    **/
   componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * Signup
   * Function name : showLoader
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : Used to show loader
   * Params        :
  **/
  showLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: true});
  }

  /**
   * Signup
   * Function name : hideLoader
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : Used to hide loader
   * Params        :
  **/
  hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
  }

  /**
   * Login
   * Function name : loadLoader
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : Used to load loader
   * Params        : 
  **/
  loadLoader(){
      return (
                <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
      );
  }

  /**
   * Signup
   * Function name : render
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : lifecycle method
   * Params        :
  **/
  render(){
      return (
       <>
          <div className="signup_form_area lp_signup_form_area">
            {/*  LP Sign UP area Start */}
            <div className="sign_up_top">
                <h1>Sign up</h1>
                <div className="subheading">Enter your details</div>
            </div>
            <div className="form_area">
                {/*  form start */}
                <form>
                    <div className="form_block field ">
                        <div className="panel100 paddingleftright15 tooltip_input_bg">
                            <label htmlFor="name" className="form__label"><b>Type of account</b> &nbsp;</label>
                            <div className={`form__group field  ${ this.state.typeOfProfessionalErMsg?'form_group_error':''}`}>
                                <select className="selectpicker dropup form__field" data-header="Organisation Size"  name="typeOfProfessional"
                                id="typeOfProfessional"
                                onChange={ this.handleChange }>
                                    <option value=""> Type of account</option>
                                    <option value="LAWYER">Lawyer</option>
                                    <option value="NON_LAWYER">Non Lawyer</option>
                                </select>
                                <span className="error_span">{this.state.typeOfProfessionalErMsg}</span>
                            </div>
                        </div>
                        <div className="clearboth"></div>
                    </div>

                    <div className="form_block form_block_half">
                        <div className="panel50 panel_half paddingleftright15">
                            <div className={`form__group field  ${ this.state.firstNameErMsg?'form_group_error':''}`}>
                                <input type="input" className="form__field" placeholder="First name" name="firstName"
                                    id='firstName'  onChange={ this.handleChange } />
                                <label htmlFor="name" className="form__label">First name</label>
                                <span className="error_span">{this.state.firstNameErMsg}</span>
                            </div>
                        </div>
                        <div className="panel50 panel_half paddingleftright15">
                            <div className={`form__group field  ${ this.state.lastNameErMsg?'form_group_error':''}`}>
                                <input type="input" className="form__field" placeholder="Last name" name="lastName"
                                    id='lastName'  onChange={ this.handleChange } />
                                <label htmlFor="name" className="form__label">Last name</label>
                                <span className="error_span">{this.state.lastNameErMsg}</span>
                            </div>
                        </div>
                        <div className="clearboth"></div>
                    </div>

                    <div className="form_block">
                        <div className="panel100 paddingleftright15 tooltip_input_bg">

                            <div className={`form__group field  ${ this.state.emailIdErMsg?'form_group_error':''}`}>
                                <input type="input" className="form__field" placeholder="Email address"
                                    name="emailId" id='emailId'  onChange={ this.handleChange } />
                                <label htmlFor="name" className="form__label">Email Id</label>
                                <span className="error_span">{this.state.emailIdErMsg}</span>
                            </div>
                            <div className="input_tooltip_bg">
                                <span className="input_tooltip"
                                    aria-label="Please enter your professional or work related email address."
                                    data-balloon-pos="down">
                                    <i className="fa fa-question-circle"></i>
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className="form_block form_block_half">
                        <div className="panel50 panel_half paddingleftright15">
                            <div className={`form__group field  ${ this.state.passwordErMsg?'form_group_error':''}`}>
                                <input type="password" className="form__field" placeholder="Enter password" name="password"
                                    id='password' onChange={ this.handleChange } />
                                <label htmlFor="name" className="form__label">Enter password</label>
                                <span className="error_span">{this.state.passwordErMsg}</span>
                            </div>
                            <div className="input_tooltip_bg">
                                <span className="input_tooltip"
                                    aria-label="Passwords must be include atleast 1 capital letter, 1 small letter,1 number, 1 special character => !@#$%^&* and atleast 8 characters long
                                    "
                                    data-balloon-pos="down">
                                    <i className="fa fa-question-circle"></i>
                                </span>
                            </div>

                        </div>
                        <div className="panel50 panel_half paddingleftright15">
                            <div className={`form__group field  ${ this.state.confirmPasswordErMsg?'form_group_error':''}`}>
                                <input type="password" className="form__field" placeholder="Confirm password" name="confirmPassword"
                                    id='confirmPassword' onChange={ this.handleChange } />
                                <label htmlFor="name" className="form__label">Confirm password</label>
                                <span className="error_span">{this.state.confirmPasswordErMsg}</span>
                            </div>
                        </div>
                        <div className="clearboth"></div>
                    </div>

                    <div className="form_block">
                        <div className="panel100 paddingleftright15">
                            <div className="form__group field">

                                <div className={`formgroup_checkbox  ${ this.state.isTermsAndConditionsAcceptedErMsg?'chkbxaggreeercls':''}`}>
                                    <input onChange={this.handleCheck} type="checkbox" id="isTermsAndConditionsAccepted" name="isTermsAndConditionsAccepted" />
                                    <label htmlFor="isTermsAndConditionsAccepted">
                                        <p>I agree to <a href={getBaseUrl()+"terms_privacy/terms_professional.pdf"} target="_blank" >terms & conditions</a> and <a href={getBaseUrl()+"terms_privacy/privacy.pdf"} target="_blank">privacy
                                                policy</a></p>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="form_block">
                        <div className="panel100 paddingleftright15 button_ip_panel">
                            <button type="button" className="btn btn-primary sub_grd_btn" ref={this.signupButtonInput} onClick={this.callSignup}><span>Create Account</span></button>
                        </div>
                    </div>

                </form>
                {/*  form end */}
                {/* login bottm */}
                <div className="login_page_bottom">
                    <div className="log_bottm_sec">
                        <p>Have an account already?</p>
                        <div className="log_bottom_btn">
                            <button disabled={this.state.loaderFlag}  onClick={()=>{this.props.history.push("/sign-in");}}href="" className="log_page_btn"><span>Sign in</span></button>
                        </div>
                    </div>
                </div>
                {/* login bottm */}

            </div>
            {/*  LP Sign UP area end */}
        </div>
       </>
      );  
  }

  handleCheck =  (e) => {

  this.isComponentLoaded &&  this.setState({isTermsAndConditionsAccepted: !this.state.isTermsAndConditionsAccepted},()=>{
    //console.log(this.state.isTermsAndConditionsAccepted);
  });
}

  /**
   * Signup
   * Function name : custValidate
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : validate data before submit
   * Params        : 
  **/
 custValidate = ()=>{

    let typeOfProfessionalErMsg = '';
    let firstNameErMsg = '';
    let lastNameErMsg = '';
    let emailIdErMsg = '';
    let passwordErMsg = '';
    let confirmPasswordErMsg = '';
    let isTermsAndConditionsAcceptedErMsg = '';

    let regexBlankSpace = /^ *$/;
    let passwordRegex =/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
    let totErrCountTrack = [];
    
    if(!(this.state.typeOfProfessional)||(this.state.typeOfProfessional.length==0)||(regexBlankSpace.test(this.state.typeOfProfessional)==true)){
      typeOfProfessionalErMsg = "Type of professional is required.";
      this.isComponentLoaded && this.setState({ "typeOfProfessionalErMsg": typeOfProfessionalErMsg});
      totErrCountTrack.push(typeOfProfessionalErMsg);
    }
    else{
      this.isComponentLoaded &&  this.setState({ "typeOfProfessionalErMsg":''});
    }

    if(!(this.state.firstName)||(this.state.firstName.length==0)||(regexBlankSpace.test(this.state.firstName)==true)){
      firstNameErMsg = "First name is required.";
      this.isComponentLoaded && this.setState({ "firstNameErMsg": firstNameErMsg});
      totErrCountTrack.push(firstNameErMsg);
    }
    else{
      this.isComponentLoaded &&  this.setState({ "firstNameErMsg":''});
    }

    if(!(this.state.lastName)||(this.state.lastName.length==0)||(regexBlankSpace.test(this.state.lastName)==true)){
      lastNameErMsg = "Last name is required.";        
      this.isComponentLoaded && this.setState({ "lastNameErMsg": lastNameErMsg});
      totErrCountTrack.push(lastNameErMsg);
    }    
    else{
      this.isComponentLoaded &&  this.setState({ "lastNameErMsg":''});
    }

    if(!(this.state.password)||(this.state.password.length==0)){
      passwordErMsg = "Password is required.";
      this.isComponentLoaded && this.setState({ "passwordErMsg": passwordErMsg});
      totErrCountTrack.push(passwordErMsg);
    } else if((passwordRegex.test(this.state.password)==false)){
      passwordErMsg = "Passwords must be include atleast 1 capital letter, 1 small letter,1 number, 1 special character => !@#$%^&* and atleast 8 characters long";
      this.isComponentLoaded && this.setState({ "passwordErMsg": passwordErMsg});
      totErrCountTrack.push(passwordErMsg);
    }
    else{
      this.isComponentLoaded &&  this.setState({ "passwordErMsg":''});
    }

    if(!(this.state.confirmPassword)||(this.state.confirmPassword.length==0)||(regexBlankSpace.test(this.state.confirmPassword)==true) || (this.state.password!=this.state.confirmPassword)){
      confirmPasswordErMsg = "Confirm Password needs to be the same as Password";
      this.isComponentLoaded && this.setState({ "confirmPasswordErMsg": confirmPasswordErMsg});
      totErrCountTrack.push(confirmPasswordErMsg);
    }
    else{
      this.isComponentLoaded &&  this.setState({ "confirmPasswordErMsg":''});
    }

    if(!(this.state.emailId)||(this.state.emailId.length==0)){
      emailIdErMsg = "Email-ID is required.";
      this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
      totErrCountTrack.push(emailIdErMsg);
    }
    else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailId)==false)
    {
        emailIdErMsg = "Provide a proper Email-ID.";
        this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
        totErrCountTrack.push(emailIdErMsg);
    }else{
      this.isComponentLoaded &&  this.setState({ "emailIdErMsg":''});
    }

    // else{
    //       // check this emailid domain are allowed or not
    //       let splitEmailAr = (this.state.emailId).split("@");
    //       let foundInvalidEmailDomain = false;

    //       if(splitEmailAr.length>1){
    //         let emlDomain = splitEmailAr[1];
    //         if(checkInvalidEmailIdDomain(emlDomain)==true){
    //             foundInvalidEmailDomain = true;
    //         }
    //       }

    //       if(foundInvalidEmailDomain){
    //         emailIdErMsg = "Personal email addresses are not allowed.";
    //         this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
    //         totErrCountTrack.push(emailIdErMsg);
    //       }
    //       else{
    //         this.isComponentLoaded &&  this.setState({ "emailIdErMsg":''});
    //       }
    // }

    if(!(this.state.isTermsAndConditionsAccepted)){
      isTermsAndConditionsAcceptedErMsg = "You need to agree with Terms & Condition.";    this.isComponentLoaded && this.setState({ "isTermsAndConditionsAcceptedErMsg": isTermsAndConditionsAcceptedErMsg});
      totErrCountTrack.push(isTermsAndConditionsAcceptedErMsg);
    }
    else{
      this.isComponentLoaded &&  this.setState({ "isTermsAndConditionsAcceptedErMsg":''});
    }
    
    if(totErrCountTrack.length>0){
      return false;
    }     

    return true;
}



/**
 * Signup
 * Function name : callLogin
 * Author        :
 * Created Date  : 10-2-2020
 * Purpose       : Used to call serice getLoginResp function
 * Params        : 
**/
callSignup = (e) => {
  e.preventDefault();
  this.isComponentLoaded && this.setState({loaderFlag:true});
  //console.log(this.state)
  const isValid = this.custValidate();

  if(isValid){

    const creds = {
      "emailId": this.state.emailId,
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "typeOfProfessional": this.state.typeOfProfessional,
      "password": this.state.password,
      "confirmPassword": this.state.confirmPassword,
      "isTermsAndConditionsAccepted": this.state.isTermsAndConditionsAccepted
    }
      let lserv = new UserSignupService();
      lserv.getSignupResp(creds).then(data =>{
          if(data.status==200){
              //set emailId to ls for otp
              let userInfoLocBl = {
                bl_emailid: custEncode(this.state.emailId),
              };
              setUserInfoBeforeLogin(userInfoLocBl);

              let succMsg = "A verification email has been sent to your email ID. Click on the link in the email to verify & login.";
              notify(succMsg,true,{
                'successMsg':1,
                'customWrapClass':'customsuccclass'});
                // setTimeout(()=>{
                //     this.props.history.push("/signin-otp");
                // },900);
                this.setState({typeOfProfessional:'',
                firstName : '',
                lastName : '',
                emailId: '',
                password: '',
                confirmPassword:'',
                isTermsAndConditionsAccepted: false})
          }
      }).catch(err=>{
        let errStatusCodeAr = [400,500,401]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                notify(errMsg,true,{
                'successMsg':0,
                'customWrapClass':'customerrclass'});
          }
        }
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
            });
        },1500);

      });

  }else{
    this.isComponentLoaded && this.setState({loaderFlag:false});
  }

}

/**
 * Signup
 * Function name : handleChange
 * Author        :
 * Created Date  : 10-2-2020
 * Purpose       : Used to handle change event
 * Params        :
**/
handleChange = (e) => {
  this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
}

/**
 * Signup
 * Function name : handleKeyDown
 * Author        :
 * Created Date  : 10-2-2020
 * Purpose       : Used to handle key down event
 * Params        : 
**/
handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    this.loginButtonInput.current.click();
  }
}

}

export default withRouter(Signup);
