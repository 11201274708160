import React, { Component } from 'react'


import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';

import ForgotPasswordService from '../../service/ForgotPasswordService';

import {notify} from '../../messagebar/CustomMessageBar';


  
const initialSate = {
    loaderFlag: false,
    emailId: '',
    emailIdErMsg: '',
};


export class ForgotPassword extends Component {
    isComponentLoaded = false;


    constructor(props) {
        super(props);
        this.state = initialSate;
        this.forgotButtonInput = React.createRef();
    }
  

    componentWillMount() {


        
    }
    componentDidMount() {
        this.isComponentLoaded = true;

    }
  

     componentWillUnmount() {
      this.isComponentLoaded = false; 
    }
  

    showLoader(){
      this.isComponentLoaded && this.setState({loaderFlag: true});
    }
  

    hideLoader(){
      this.isComponentLoaded && this.setState({loaderFlag: false});
    }
  

    loadLoader(){
        return (
                  <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
        );
    }
    render(){
        return (
         <>
            <div className="signup_form_area lp_signup_form_area">
                    <div className="sign_up_top">
                        <h1>Forgot password?</h1>
                        <div className="subheading">Please enter your registered email ID to receive the reset link</div>
                    </div>
                    <div className="form_area">
                        <form>

                            <div className="form_block">
                                <div className="panel100 paddingleftright15 tooltip_input_bg">

                                    <div className={`form__group field  ${ this.state.emailIdErMsg?'form_group_error':''}`}>
                                        <input type="input" className="form__field" placeholder="Email ID"
                                             name="emailId" id='emailId'  onChange={ this.handleChange } />
                                        <label htmlFor="name" className="form__label">Email ID</label>
                                        <span className="error_span">{this.state.emailIdErMsg}</span>
                                    </div>
                                    {/* <div className="input_tooltip_bg">
                                        <span className="input_tooltip"
                                            aria-label="Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text"
                                            data-balloon-pos="down">
                                            <i className="fa fa-question-circle"></i>
                                        </span>
                                    </div> */}

                                </div>
                            </div>

                            <div className="form_block">
                                <div className="panel100 paddingleftright15 button_ip_panel">
                                    <button type="button" className="btn btn-primary sub_grd_btn"  ref={this.forgotButtonInput} onClick={this.callForgotPassword}><span>Send Reset Link</span></button>
                                </div>
                            </div>

                        </form>


                        <div className="login_page_bottom">
                            <div className="log_bottm_sec">
                                <p>Don't have an account?</p>
                                <div className="log_bottom_btn">
                                    <button className="log_page_btn" onClick={()=>{this.props.history.push("/sign-up");}}><span>Sign up</span></button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
         </>
        );  
    }



    custValidate = ()=>{
          let emailIdErMsg = '';
          let regexBlankSpace = /^ *$/;
          let totErrCountTrack = [];
          if(!(this.state.emailId)||(this.state.emailId.length==0)){
            emailIdErMsg = "Email-ID is required.";
            this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
            totErrCountTrack.push(emailIdErMsg);
          }
          else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailId)==false)
          {
              emailIdErMsg = "Provide a proper Email-ID.";
              this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
              totErrCountTrack.push(emailIdErMsg);
          } else{
            this.isComponentLoaded &&  this.setState({ "emailIdErMsg":''});
          }
          if(totErrCountTrack.length>0){
            return false;
          }     
        
          return true;
      }
      


      

    callForgotPassword = (e) => {
        e.preventDefault();
        this.isComponentLoaded && this.setState({loaderFlag:true});
        //console.log(this.state)
        const isValid = this.custValidate();
      
        if(isValid){
      
          const creds = {
            "emailId": this.state.emailId,
          }
            let lserv = new ForgotPasswordService();
            lserv.sendResetLink(creds).then(data =>{
                if(data.status==200){


                    let succMsg = `Reset password link sent to ${this.state.emailId}` ;
                    notify(succMsg,true,{
                      'successMsg':1,
                      'customWrapClass':'customsuccclass'});
                      // setTimeout(()=>{
                      //     this.props.history.push("/signin-otp");
                      // },900);
                }
            }).catch(err=>{
              let errStatusCodeAr = [400,500,401]; // allowed error status code
              if(err.response && err.response.data){
                  let erRespData = err.response.data;
                  if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                      let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                      notify(errMsg,true,{
                      'successMsg':0,
                      'customWrapClass':'customerrclass'});
                }
              }
        
            }).finally(()=>{
              setTimeout(()=>{
                this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
                  });
              },1500);

            });
      
        }else{
          this.isComponentLoaded && this.setState({loaderFlag:false});
        }
      
      }
      

      handleChange = (e) => {
        this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
      }
      


}

export default ForgotPassword
