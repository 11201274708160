import React, { Component } from 'react'

const CommonContext = React.createContext()

class CommonProvider extends Component {

 isComponentLoaded = false;

  // Context state
  state = {
    share: {},
  }

  // Method to update state
  setShare = share => {
    this.isComponentLoaded && this.setState(prevState => ({ share }),()=>{
        //console.log("called===>",this.state.share);
    })
  }
  
  componentDidMount(){
        this.isComponentLoaded = true;
        this.setShare(this.props.value);

  }

  componentWillUnmount(){
    this.isComponentLoaded = false;
  }

  render() {
    //  console.log("this.state==>",this.state," this.props==>",this.props);
    const { children } = this.props;
    const { share } = this.state;
    const { setShare } = this;

    return (
      <CommonContext.Provider
        value={{
            share,
            setShare,
        }}
      >
        {children}
      </CommonContext.Provider>
    )
  }
}

export default CommonContext

export { CommonProvider }
