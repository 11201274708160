/** 
 * VideoCall
 * Page/Class name   : VideoCall
 * Author            :
 * Created Date      : 20-5-2021
 * Functionality     : VideoCall
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';

import { withRouter } from "react-router-dom";


class VideoCall extends Component {

  isComponentLoaded = false;

  

  /**
   * VideoCall
   * Function name : constructor
   * Author        :
   * Created Date  : 20-5-2021
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);  
      
      this.state = {
        "vurl":""
      };
      this.queryParamObj = new URLSearchParams(this.props.location.search);
    
  }

  /**
     * VideoCall
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 20-5-2021
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/
  
  componentDidMount() {
      this.isComponentLoaded = true; 
      let vurl = this.queryParamObj.get("vurl");    


      if(vurl){
              this.isComponentLoaded && this.setState({"vurl":vurl},()=>{
                 
              });
              
      }
     
      
  }


  
  /**
     * VideoCall
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 20-5-2021
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

 
  

  /**
   * VideoCall
   * Function name : render
   * Author        :
   * Created Date  : 20-5-2021
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){   
    
      return (
       <> {this.state.vurl && (
        <iframe
        src={this.state.vurl}
      />
       )}
        
       
          </>
      
      );  
  }




 

}

export default withRouter(VideoCall);