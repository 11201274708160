/**  
 * OrgDetailSaveService
 * Page/Class name   : OrgDetailSaveService
 * Author            :
 * Created Date      : 17-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;


class LoggedinUserInfoService extends Component {

    
    /**  
     * OrgDetailSaveService
     * Function name : constructor
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {

      }

      //this.axiosObj = axios;
      this.axiosObj = null;

      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/user/user_info';
    }

    /**
     * OrgDetailSaveService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * OrgDetailSaveService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * OrgDetailSaveService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getLoggedInUserInfo(paramObj){
      this.axiosObj = axiosCustomObj({});
      
      let accToken = getTokenFromLocStorage();
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;
      try{
            let urldata =this.apiEndPoint+'?offset_hour='+offsetHr +'&offset_minute='+offsetMin;
            let headerOptn = {
                                "Content-Type": "application/json",
                                "Authorization": accToken
                              };

            let respObj = this.axiosObj.get(urldata, {
              headers: headerOptn
            });

            return respObj;
    }
    catch(err){
       // console.log("Some error occured===>",err); // TypeError: failed to fetch
    }


    }

    
}
export default LoggedinUserInfoService;