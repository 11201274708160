/**  
 * DeclineTaskOfferService
 * Page/Class name   : DeclineTaskOfferService
 * Author            :
 * Created Date      : 8-4-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class DeclineTaskOfferService extends Component {

    
    /**  
     * DeclineTaskOfferService
     * Function name : constructor
     * Author        :
     * Created Date  : 8-4-2021
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/task_request';
    }

    /**  
     * DraftTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 8-4-2021
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * CreateTaskService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 8-4-2021
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * CreateTaskService
     * Function name : declineTaskOffer
     * Author        :
     * Created Date  : 8-4-2021
     * Purpose       : decline Task Offer 
     * Params        : paramObj
    **/
    async declineTaskOffer(paramObj){
      //console.log(" paramObj to save draft in service==>", paramObj);
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;
            //console.log(" ==dataObj==>",dataObj);

            let dataObj = paramObj.data;  

            let taskId = dataObj && dataObj.taskId!==undefined?dataObj.taskId:0;
            urldata = urldata + "/"+taskId;
            
            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };     
            
            
            let respObj = this.axiosObj.delete(urldata, {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: accToken
              },
              data:{}
            });
           

            return respObj;


            
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default DeclineTaskOfferService;